import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toggleNewAutomationModal } from "@redux/components/index.actions";
import { } from '@redux/files/index.actions';
import logoSm from '@assets/images/company-identity/logo-square.jpeg';

import { useTranslation } from "react-i18next";
import FeatherIcons from "feather-icons-react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import apiInstance from "@clients/api.client";
import AutomationCustomFieldArea, { TCustomComponent } from "@components/AutomationCustomFieldArea";

const MapStateToProps = (state: any) => ({
    components: state.components,
    contracts: state.contracts,
    companies: state.companies,
    cdn: state.files.cdn,
    uploadedFiles: state.files.newProcessUploadedFiles
});

const MapActionsToProps = {
    toggleNewAutomationModal: toggleNewAutomationModal
};


const CustomFields: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();

    const [customFields, setCustomFields] = useState<any>([]);
    const [selectedCustomField, setSelectedCustomField] = useState<any>(undefined);

    useEffect(() => {
        apiInstance.get(`/custom-fields/${props?.companies?.company?._id}`).then((res: any) => {
            setCustomFields(res?.data?.node?.contractsCustomFields || [])
            console.log('res?.data?.none?.contractsCustomFields', res?.data?.node?.contractsCustomFields)
        })
    }, [])

    const [ruleDetails, setRuleDetails] = useState<any>({
        selectedValue: '',
    });

    useEffect(() => {
        props?.setRuleDetails(ruleDetails)
    }, [ruleDetails])

    return (
        <>
            <div className="custom-fields">
                <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
                    <label style={{
                        width: '80px',
                        fontSize: '12px'
                    }} htmlFor="">{t('AUTOMATION.FIELD')}:</label>
                    <span style={{
                        width: '100%',
                        flex: 1,
                    }}>
                        <Select
                            styles={
                                {
                                    control: (base, state) => ({
                                        ...base,
                                        flex: 1,
                                        width: '100%',
                                        '&:hover': {
                                            borderColor: '#2c42ff'
                                        },
                                        border: '1px solid #ced4da',
                                        boxShadow: 'none',
                                        '&:focus': {
                                            borderColor: '#2c42ff',
                                            boxShadow: 'none'
                                        }
                                    })
                                }
                            }
                            options={(customFields.filter((c: any) => {

                                if (c?.CustomComponent != 'Formula') {
                                    return c
                                }

                            }) || [])?.map((item: any) => ({
                                value: item._id,
                                label: item.title,
                                data: item
                            }))}
                            onChange={(e: any) => {
                                setSelectedCustomField(e)
                                setRuleDetails({
                                    ...ruleDetails,
                                    selectedField: {
                                        _id: e?.data?._id,
                                        title: e?.data?.title,
                                        key: e?.data?.key,
                                        CustomComponent: e?.data?.CustomComponent,
                                        extraProps: e?.data?.extraProps,
                                        existOptionValues: e?.data?.existOptionValues,
                                        OptionValues: e?.data?.OptionValues?.map((optionValue: any) => {
                                            return {
                                                _id: optionValue._id,
                                                key: optionValue.key,
                                                title: optionValue.title,
                                                alias: optionValue.alias,
                                                value: optionValue.value,
                                            }
                                        })
                                    }
                                })
                            }}
                        />
                    </span>
                </div>
                <div
                    style={{
                        marginTop: '10px'
                    }}
                    className="custom-fields-condition d-flex align-items-center justify-content-between gap-1">
                    <label style={{
                        width: '80px',
                        fontSize: '12px'

                    }} htmlFor="">{t('AUTOMATION.CONDITION')}:</label>
                    <span style={{
                        width: '100%',
                        flex: 1,
                    }}>
                        <Select
                            styles={
                                {
                                    control: (base, state) => ({
                                        ...base,
                                        flex: 1,
                                        width: '100%',
                                        '&:hover': {
                                            borderColor: '#2c42ff'
                                        },
                                        border: '1px solid #ced4da',
                                        boxShadow: 'none',
                                        '&:focus': {
                                            borderColor: '#2c42ff',
                                            boxShadow: 'none'
                                        }
                                    })
                                }
                            }
                            options={(props?.conditions || [])?.map((item: any) => ({
                                value: item.value,
                                label: item.label,
                            }))}
                            onChange={(e: any) => {
                                setRuleDetails({
                                    ...ruleDetails,
                                    selectedCondition: e
                                })
                                props?.setConditions(e)
                            }}
                        />
                    </span>
                </div>
                <div className="custom-fields-value">
                    <AutomationCustomFieldArea >

                        {
                            selectedCustomField?.data?.CustomComponent && (() => {

                                const inputType: TCustomComponent = selectedCustomField?.data?.CustomComponent;

                                if (selectedCustomField?.data?.existOptionValues === true) {

                                    return React.createElement(AutomationCustomFieldArea[inputType], {
                                        size: 12,
                                        name: selectedCustomField?.data?._id,
                                        label: t('AUTOMATION.VALUE'),
                                        value: {
                                            value: '',
                                            label: '',
                                        },
                                        onChange: (event: any) => setRuleDetails({
                                            ...ruleDetails,
                                            selectedValue: event?.opt

                                        }),
                                        require: false,
                                        data: selectedCustomField?.data,
                                        style: {
                                            formGroups: {
                                                display: 'flex',
                                                gap: '5px',
                                                width: '100%',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                            }
                                        }
                                    })
                                } else {

                                    return React.createElement(AutomationCustomFieldArea[inputType], {
                                        key: 'cf',
                                        size: 12,
                                        name: selectedCustomField?.data?._id,
                                        label: t('AUTOMATION.VALUE'),
                                        value: "",
                                        onInput: (event: any) => setRuleDetails({
                                            ...ruleDetails,
                                            selectedValue: event.target.value

                                        }),
                                        require: false,
                                        data: selectedCustomField?.data,
                                        style: {
                                            formGroups: {
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                gap: '5px',
                                                width: '100%'
                                            }
                                        }
                                    })

                                }
                            })()
                        }



                    </AutomationCustomFieldArea>
                </div>
            </div >
        </>
    );
};

export default connect(MapStateToProps, MapActionsToProps)(CustomFields);
