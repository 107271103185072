import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FeatherIcons from 'feather-icons-react'
import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate } from 'slate-react'
import {
    Editor,
    Transforms,
    createEditor,
    Descendant,
    Element as SlateElement,
    Node,
    Range,
    Path,
    Point
} from 'slate'
import { withHistory, HistoryEditor } from 'slate-history'
import { Button as Button2, ButtonGroup, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Button, Toolbar } from './components/'
import { Dropdown } from 'react-bootstrap'
import { addTableColumn, addTableRow } from './utils/table.utils'

/* ToolbarIcons */
import { MdFormatBold, MdList } from "react-icons/md";
import { MdFormatItalic } from "react-icons/md";
import { MdFormatUnderlined } from "react-icons/md";
import { MdCode } from "react-icons/md";
import { MdFormatQuote } from "react-icons/md";
import { MdFormatListNumbered } from "react-icons/md";
import { MdFormatListBulleted } from "react-icons/md";
import { MdFormatAlignLeft } from "react-icons/md";
import { MdFormatAlignCenter } from "react-icons/md";
import { MdFormatAlignRight } from "react-icons/md";
import { MdFormatAlignJustify } from "react-icons/md";
import { LuHighlighter } from "react-icons/lu";
import { RxFontSize } from "react-icons/rx";
import { TbPageBreak } from "react-icons/tb";

import './index.scss'
import { BiFontColor, BiFontFamily } from 'react-icons/bi'
import { RiIndentDecrease, RiIndentIncrease } from 'react-icons/ri'
import { BsPlusLg, BsTable } from 'react-icons/bs'


import {
    toggleBlock,
    toggleMark,
    isBlockActive,
    isMarkActive,
    withCustomFields,
    withClauses,
    Element,
    Leaf,
    BlockButton,
    MarkButton,
    findActiveMark,
    withDefaultFields,

} from '@components/SlateEditor/utils/editor.utils'

import {
    HOTKEYS,
    LIST_TYPES,
    TEXT_ALIGN_TYPES,
    TEST_CUSTOM_FIELDS,
    TEST_CLAUSES,
    HIGHLIGHT_COLORS,
    FONT_COLORS,
    FONT_SIZES,
    FONT_FAMILIES
} from '@components/SlateEditor/constants/index.constant'
import { FaChevronDown } from 'react-icons/fa'
import _ from 'lodash'
import apiInstance from '@clients/api.client'
import { toast } from 'react-toastify'
import FillFormCustomFieldArea from '@components/FillFormCustomFieldArea'
import isUrl from 'is-url'
import ElementStyle from './components/Toolbar/ElementStyle'
import FontFamily from './components/Toolbar/FontFamily'
import FontSize from './components/Toolbar/FontSize'
import FontColor from './components/Toolbar/FontColor'
import Indent from './components/Toolbar/Indent'
import Lists from './components/Toolbar/Lists'
import Align from './components/Toolbar/Align'
import ImageElement from './components/Toolbar/ImageElement'
import TableElement from './components/Toolbar/TableElement'
import PageBreakElement from './components/Toolbar/PageBreakElement'
import HistoryElement from './components/Toolbar/HistoryElement'
import SmartFieldsElement from './components/Toolbar/SmartFieldsElement'
import ExportDocxElement from './components/Toolbar/ExportDocxElement'

const withProtectedHeaderDeletion = (editor: any) => {
    const { deleteBackward } = editor

    editor.deleteBackward = (unit: any) => {
        const { selection } = editor

        if (selection && Range.isCollapsed(selection)) {
            const [match]: any = Editor.nodes(editor, {
                match: (n: any) => n.type === 'header',
            })

            if (match) {
                const [clause, path] = match
                const [start] = Editor.edges(editor, path)
                if (Point.equals(selection.anchor, start)) {
                    return
                }
            }
        }

        deleteBackward(unit)
    }

    return editor
}

const SlateEditor = (props: any) => {
    const [target, setTarget] = useState<any>()
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withDefaultFields(withProtectedHeaderDeletion(withClauses(withCustomFields(withHistory(withReact(createEditor())))))), [])
    const headerEditor = useMemo(() => withCustomFields(withDefaultFields(withHistory(withReact(createEditor())))), [])
    const footerEditor = useMemo(() => withCustomFields(withDefaultFields(withHistory(withReact(createEditor())))), [])

    /* Saving */

    const debounceFn = useCallback(_.debounce(handleDebounceSavingFn, 1000), []);

    function handleDebounceSavingFn(version_id: string, data: any) {

        if (props?.setEditFormLoading) props?.setEditFormLoading(true)

        apiInstance.put(`/editor/template/version/${version_id}`, {
            editor_id: props?.data?.editorDetail?._id,
            fragment: data
        }).then((res) => {
            if (props?.setEditFormLoading) props?.setEditFormLoading(false)
        }).catch((err) => {
            if (props?.setEditFormLoading) props?.setEditFormLoading(false)
        })
    }

    /* Saving */

    const [activeEditor, setActiveEditor] = useState<any>('content')

    useEffect(() => {
        console.log('customFields', props?.customFields)
    }, [props?.customFields])

    const [content, setContent] = useState<any>(JSON.parse(props?.content || '[]'))
    const [header, setHeader] = useState<any>(JSON.parse(props?.header || '[]'))
    const [footer, setFooter] = useState<any>(JSON.parse(props?.footer || '[]'))

    const [initialValue, setInitialValue] = useState<Descendant[]>(useMemo(
        () =>
            JSON.parse(props?.content as any) || [
                {
                    type: 'paragraph',
                    children: [{ text: 'A line of text in a paragraph.' }],
                },
            ],
        []
    ))

    const [headerInitialValue, setHeaderInitialValue] = useState<Descendant[]>(useMemo(
        () =>
            JSON.parse(props?.header as any) || [
                {
                    type: 'paragraph',
                    children: [{ text: 'A line of text in a paragraph.' }],
                },
            ],
        []
    ))

    const [footerInitialValue, setFooterInitialValue] = useState<Descendant[]>(useMemo(
        () =>
            JSON.parse(props?.footer as any) || [
                {
                    type: 'paragraph',
                    children: [{ text: 'A line of text in a paragraph.' }],
                },
            ],
        []
    ))



    const updateCustomField = (editor: any, fieldId: any, newText: any) => {
        const nodes: any = Editor.nodes(editor, {
            at: [],
            match: (n: any) => !Editor.isEditor(n) && n.type === 'custom-field' && n.field === fieldId
        });


        console.log('nodes', nodes)
        for (const [node, path] of nodes) {
            Transforms.setNodes(editor, { children: [{ text: "" }], labelValue: newText }, { at: path });
        }
    };

    const addHighlight = (editor: any, color: any) => {
        // Kullanıcı bir metin seçimi yapmışsa, bu seçime renk uygula
        if (editor.selection && !Range.isCollapsed(editor.selection)) {
            // Seçili metne arka plan rengi markı ekleyin
            Editor.addMark(editor, 'backgroundColor', color);
        }
    };

    const addColor = (editor: any, color: any) => {
        // Kullanıcı bir metin seçimi yapmışsa, bu seçime renk uygula
        if (editor.selection && !Range.isCollapsed(editor.selection)) {
            // Seçili metne arka plan rengi markı ekleyin
            Editor.addMark(editor, 'color', color);
        }
    }

    const changeFontSize = (editor: any, size: any) => {
        // Kullanıcı bir metin seçimi yapmışsa, bu seçime renk uygula
        if (editor.selection && !Range.isCollapsed(editor.selection)) {
            // Seçili metne arka plan rengi markı ekleyin
            Editor.addMark(editor, 'fontSize', size);
        }
    }
    const changeFontFamily = (editor: any, font: any) => {
        // Kullanıcı bir metin seçimi yapmışsa, bu seçime renk uygula
        if (editor.selection && !Range.isCollapsed(editor.selection)) {
            // Seçili metne arka plan rengi markı ekleyin
            Editor.addMark(editor, 'fontFamily', font);
        }
    }



    const increaseIndent = (editor: any) => {
        // Seçili aralıktaki tüm düğümleri al
        const nodes = Array.from(Editor.nodes(editor, {
            at: editor.selection,
            match: (n: any) => !Editor.isEditor(n) && Editor.isBlock(editor, n),
        }));

        // Her düğüm için marginLeft'i artır
        for (const [node, path] of nodes) {

            const customNode = node as any;

            const currentStyle: any = customNode.style || {};
            const currentMargin = parseInt(currentStyle.marginLeft || 0, 10);
            const newMargin = `${currentMargin + 10}px`; // Her tıklandığında 10px artır

            // Yeni stil ile düğümü güncelle
            Transforms.setNodes(
                editor,
                { style: { ...currentStyle, marginLeft: newMargin } },
                { at: path }
            );
        }
    };
    const decreaseIndent = (editor: any) => {
        // Seçili aralıktaki tüm düğümleri al
        const nodes = Array.from(Editor.nodes(editor, {
            at: editor.selection,
            match: (n: any) => !Editor.isEditor(n) && Editor.isBlock(editor, n),
        }));

        // Her düğüm için marginLeft'i artır
        for (const [node, path] of nodes) {

            const customNode = node as any;

            const currentStyle: any = customNode.style || {};
            const currentMargin = parseInt(currentStyle.marginLeft || 0, 10);
            const newMargin = `${currentMargin - 10}px`; // Her tıklandığında 10px artır

            if (currentMargin <= 0) return;

            // Yeni stil ile düğümü güncelle
            Transforms.setNodes(
                editor,
                { style: { ...currentStyle, marginLeft: newMargin } },
                { at: path }
            );
        }
    };

    const isColorActive = (editor: any, color: any) => {
        const marks: any = Editor.marks(editor);
        // "backgroundColor" markının mevcut değerini kontrol eder
        // ve verilen renk değeri ile karşılaştırır
        return marks?.backgroundColor === color;
    };
    const whichColorActive = (editor: Editor): string | null => {
        const marks: any = Editor.marks(editor);
        // "backgroundColor" markının mevcut değerini alır
        // Eğer bu mark mevcutsa, onun değerini (rengi) döner
        // Yoksa, null döner
        return marks?.backgroundColor || null;
    }



    const [activeBgColor, setActiveBgColor] = useState<string | null>(null);
    const [activeColor, setActiveColor] = useState<string | null>(null);
    const [activeFontSize, setActiveFontSize] = useState<string | null>('14');
    const [activeStyle, setActiveStyle] = useState<string | null>('paragraph');
    const [activeFont, setActiveFont] = useState<string | null>('Arial');

    // Editördeki değişiklikleri işleyen onChange fonksiyonu
    const onChange = (value: any) => {
        const isAstChange = editor.operations.some(
            (op: any) => 'set_selection' !== op.type
        )
        if (isAstChange) {
            // Save the value to Local Storage.
            const editorContent = JSON.stringify(value)
            localStorage.setItem('content', editorContent)
            setContent(JSON.parse(editorContent))

            if (props?.autoSave !== false) debounceFn(props?.data?.lastVersion?._id, editorContent);
            if (props?.setData) {

                props?.setData({
                    ...props?.data, lastVersion: {
                        ...props?.data?.lastVersion,
                        fragment: editorContent
                    }
                })
            }


        }

        // Aktif arka plan rengini güncelle
        const marks: any = Editor.marks(editor);
        const color: any = marks?.color || null;
        const bgColor: any = marks?.backgroundColor || null;
        const fontSize: any = marks?.fontSize || '14';
        const fontFamily: any = marks?.fontFamily || 'Arial';
        setActiveStyle(findActiveMark(editor) || 'paragraph');
        setActiveBgColor(bgColor);
        setActiveColor(color);
        setActiveFontSize(fontSize);
        setActiveFont(fontFamily);

    };

    const onChangeHeader = (value: any) => {
        const isAstChange = headerEditor.operations.some(
            (op: any) => 'set_selection' !== op.type
        )
        if (isAstChange) {
            // Save the value to Local Storage.
            const editorContent = JSON.stringify(value)
            localStorage.setItem('content', editorContent)
            setContent(JSON.parse(editorContent))
            console.log('props', props)
            if (props?.autoSave !== false) debounceFn(props?.data?.lastHeaderVersion?._id, editorContent);

            if (props?.setData) {

                props?.setData({
                    ...props?.data, lastHeaderVersion: {
                        ...props?.data?.lastHeaderVersion,
                        fragment: editorContent
                    }
                })
            }

        }

        // Aktif arka plan rengini güncelle
        const marks: any = Editor.marks(headerEditor);
        const color: any = marks?.color || null;
        const bgColor: any = marks?.backgroundColor || null;
        const fontSize: any = marks?.fontSize || '10pt';
        const fontFamily: any = marks?.fontFamily || 'Arial';
        setActiveStyle(findActiveMark(headerEditor) || 'paragraph');
        setActiveBgColor(bgColor);
        setActiveColor(color);
        setActiveFontSize(fontSize);
        setActiveFont(fontFamily);

    };
    const onChangeFooter = (value: any) => {
        const isAstChange = footerEditor.operations.some(
            (op: any) => 'set_selection' !== op.type
        )
        if (isAstChange) {
            // Save the value to Local Storage.
            const editorContent = JSON.stringify(value)
            localStorage.setItem('content', editorContent)
            setContent(JSON.parse(editorContent))
            console.log('props', props)
            if (props?.autoSave !== false) debounceFn(props?.data?.lastFooterVersion?._id, editorContent);
            if (props?.setData) {

                props?.setData({
                    ...props?.data, lastFooterVersion: {
                        ...props?.data?.lastFooterVersion,
                        fragment: editorContent
                    }
                })
            }

        }

        // Aktif arka plan rengini güncelle
        const marks: any = Editor.marks(footerEditor);
        const color: any = marks?.color || null;
        const bgColor: any = marks?.backgroundColor || null;
        const fontSize: any = marks?.fontSize || '14';
        const fontFamily: any = marks?.fontFamily || 'Arial';
        setActiveStyle(findActiveMark(footerEditor) || 'paragraph');
        setActiveBgColor(bgColor);
        setActiveColor(color);
        setActiveFontSize(fontSize);
        setActiveFont(fontFamily);

    };



    const isImageUrl = (url: any) => {
        if (!url) return false
        if (!isUrl(url)) return false
        const ext: any = new URL(url).pathname.split('.').pop()
        return true
    }

    const insertImage = (editor: any, url: any) => {
        const text = { text: '' }
        const image: any = { type: 'image', url, children: [text] }
        Transforms.insertNodes(editor, image)
        Transforms.insertNodes(editor, {
            type: 'paragraph',
            children: [{ text: '' }],
        })
    }



    useEffect(() => {

    }, [props?.data?.customFields])


    return (
        <div className='slate-wrapper'>
            {props?.fillForm && (

                <div className="slate-form">
                    {props?.data?.customFields?.length > 0 ? (
                        <div className="input-custom-fields">
                            {props?.data?.customFields?.length > 0 && (
                                <FillFormCustomFieldArea>
                                    {(props?.data?.customFields || []).map((e: any, index: number) => {

                                        console.log('e', e);

                                        enum FieldTypes {
                                            Text = "Text",
                                            TextArea = "TextArea",
                                            Number = "Number",
                                            Date = "Date",
                                            Time = "Time",
                                            Phone = "Phone",
                                            Currency = "Currency",
                                            SingleSelect = "SingleSelect",
                                            Formula = "Formula",
                                            // ... diğer tipleri buraya ekleyin.
                                        }

                                        let fieldType: FieldTypes = e?.CustomComponent as FieldTypes;

                                        if (e?.existOptionValues === true) {
                                            return React.createElement(FillFormCustomFieldArea[fieldType], {
                                                size: 12,
                                                name: e?._id,
                                                label: e?.title,
                                                className: 'form-control form-control-xl',

                                                onChange: (event: any) => {
                                                    console.log('event.target.value', event)
                                                    updateCustomField(editor, e?._id, event?.opt?.value)
                                                    updateCustomField(headerEditor, e?._id, event?.opt?.value)
                                                    updateCustomField(footerEditor, e?._id, event?.opt?.value)
                                                    props?.documentCustomFieldsSetter(e?._id, event?.opt?.value)
                                                },
                                                require: e?.isOptional,
                                                data: e
                                            })
                                        } else {

                                            if (e?.CustomComponent === 'Formula') {
                                                return null;
                                            }
                                            else {
                                                return React.createElement(FillFormCustomFieldArea[fieldType], {
                                                    key: e?._id + 'cf',
                                                    size: 12,
                                                    name: e?._id,
                                                    className: 'form-control form-control-xl',
                                                    label: e?.title,
                                                    value: '',
                                                    onInput: (event: any) => {
                                                        updateCustomField(editor, e?._id, event?.target?.value)
                                                        updateCustomField(headerEditor, e?._id, event?.target?.value)
                                                        updateCustomField(footerEditor, e?._id, event?.target?.value)
                                                        props?.documentCustomFieldsSetter(e?._id, event?.target?.value)
                                                    },
                                                    require: e?.isOptional,
                                                    data: e
                                                })
                                            }

                                        }


                                    })}

                                </FillFormCustomFieldArea>)}
                        </div>) : (<>
                            <span

                                style={{
                                    padding: '8px',
                                    display: 'block',
                                    textAlign: 'center'

                                }}>
                                There is no custom field defined in the document
                            </span>
                        </>)}

                </div>
            )}

            <div
                className="slate w-100">

                <Slate

                    onChange={(value: any) => {
                        onChange(value)
                        const isAstChange = editor.operations.some(
                            (op: any) => 'set_selection' !== op.type
                        )
                        if (isAstChange) {
                            console.log('value', value)

                            // Save the value to Local Storage.
                            const editorContent = JSON.stringify(value)
                            localStorage.setItem('content', editorContent)
                            setContent(JSON.parse(editorContent))
                        }
                    }}

                    editor={editor} initialValue={initialValue}>
                    {(String(activeEditor) === 'content' && props?.toolbar) && <div className='toolbar'>
                        <Toolbar
                            className='custom-slate-toolbar'
                        >
                            <ElementStyle activeStyle={activeStyle} />
                            <FontFamily activeFont={activeFont} changeFontFamily={changeFontFamily} setActiveFont={setActiveFont} />
                            <FontSize activeFontSize={activeFontSize} changeFontSize={changeFontSize} setActiveFontSize={setActiveFontSize} />
                            <MarkButton format="bold" icon="bold" specialIcon={(<MdFormatBold size={18} />)} />
                            <MarkButton format="italic" icon="italic" specialIcon={(<MdFormatItalic size={18} />)} />
                            <MarkButton format="underline" icon="underline" specialIcon={(<MdFormatUnderlined size={18} />)} />
                            <MarkButton format="code" icon="code" specialIcon={(<MdCode size={18} />)} />
                            <FontColor activeColor={activeColor} addColor={addColor} setActiveColor={setActiveColor} />
                            <Indent increaseIndent={increaseIndent} decreaseIndent={decreaseIndent} />
                            <BlockButton format="block-quote" icon="format_quote" specialIcon={(<MdFormatQuote size={18} />)} />
                            <Lists />
                            <Align />
                            <ImageElement />
                            <TableElement target={target} />
                            <PageBreakElement target={target} />
                            <HistoryElement />
                            <SmartFieldsElement target={target} defaultFields={props?.defaultFields} customFields={props?.customFields} />
                            <ExportDocxElement exportDocx={props?.exportDocx} />
                        </Toolbar>
                    </div>}
                    {
                        props?.header ? (
                            <Slate editor={headerEditor} initialValue={headerInitialValue} onChange={(value: any) => {
                                onChangeHeader(value)
                                setHeaderInitialValue(value)
                            }
                            }>
                                {(String(activeEditor) === 'header' && props?.toolbar) && <div className='toolbar'>
                                    <Toolbar
                                        className='custom-slate-toolbar'
                                    >
                                        <ElementStyle activeStyle={activeStyle} />
                                        <FontFamily activeFont={activeFont} changeFontFamily={changeFontFamily} setActiveFont={setActiveFont} />
                                        <FontSize activeFontSize={activeFontSize} changeFontSize={changeFontSize} setActiveFontSize={setActiveFontSize} />
                                        <MarkButton format="bold" icon="bold" specialIcon={(<MdFormatBold size={18} />)} />
                                        <MarkButton format="italic" icon="italic" specialIcon={(<MdFormatItalic size={18} />)} />
                                        <MarkButton format="underline" icon="underline" specialIcon={(<MdFormatUnderlined size={18} />)} />
                                        <MarkButton format="code" icon="code" specialIcon={(<MdCode size={18} />)} />
                                        <FontColor activeColor={activeColor} addColor={addColor} setActiveColor={setActiveColor} />
                                        <Indent increaseIndent={increaseIndent} decreaseIndent={decreaseIndent} />
                                        <Align />
                                        <ImageElement />

                                        <HistoryElement />
                                        <SmartFieldsElement target={target} defaultFields={props?.defaultFields} customFields={props?.customFields} />


                                    </Toolbar>
                                </div>}
                                <Editable
                                    readOnly={props?.readOnly || false}
                                    onFocus={(event: any) => {
                                        setActiveEditor('header');
                                    }}
                                    className='custom-slate-editor bd-bottom'
                                    style={{}}
                                    renderElement={renderElement}
                                    renderLeaf={renderLeaf}

                                    spellCheck

                                    onKeyDown={(event: any) => {

                                        for (const hotkey in HOTKEYS) {
                                            if (isHotkey(hotkey, event as any)) {
                                                event.preventDefault()
                                                const mark = HOTKEYS[hotkey]
                                                toggleMark(headerEditor, mark)
                                            }
                                        }
                                    }}
                                />
                            </Slate>
                        ) : (
                            <div className='hf-placeholder bd-bottom'>

                                <Button2 variant='link' onClick={() => {
                                    props?.addHeader()
                                }}>Add Header</Button2>

                            </div>
                        )
                    }



                    <Editable
                        readOnly={props?.readOnly || false}
                        className='custom-slate-editor custom-slate-editor-content'
                        style={{ borderRadius: '0px' }}
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                        spellCheck
                        autoFocus
                        onFocus={(event: any) => {
                            setActiveEditor('content');
                        }}

                        onKeyDown={(event: any) => {

                            for (const hotkey in HOTKEYS) {
                                if (isHotkey(hotkey, event as any)) {
                                    event.preventDefault()
                                    const mark = HOTKEYS[hotkey]
                                    toggleMark(editor, mark)
                                }

                            }


                        }}
                    />

                    {
                        props?.footer ? (
                            <Slate editor={footerEditor}
                                initialValue={footerInitialValue} onChange={(value: any) => {
                                    onChangeFooter(value)
                                    setFooterInitialValue(value)
                                }
                                }>
                                {(String(activeEditor) === 'footer' && props?.toolbar) && <div className='toolbar'>
                                    <Toolbar
                                        className='custom-slate-toolbar'
                                    >
                                        <ElementStyle activeStyle={activeStyle} />
                                        <FontFamily activeFont={activeFont} changeFontFamily={changeFontFamily} setActiveFont={setActiveFont} />
                                        <FontSize activeFontSize={activeFontSize} changeFontSize={changeFontSize} setActiveFontSize={setActiveFontSize} />
                                        <MarkButton format="bold" icon="bold" specialIcon={(<MdFormatBold size={18} />)} />
                                        <MarkButton format="italic" icon="italic" specialIcon={(<MdFormatItalic size={18} />)} />
                                        <MarkButton format="underline" icon="underline" specialIcon={(<MdFormatUnderlined size={18} />)} />
                                        <MarkButton format="code" icon="code" specialIcon={(<MdCode size={18} />)} />
                                        <FontColor activeColor={activeColor} addColor={addColor} setActiveColor={setActiveColor} />
                                        <Indent increaseIndent={increaseIndent} decreaseIndent={decreaseIndent} />
                                        <Align />
                                        <ImageElement />

                                        <HistoryElement />
                                        <SmartFieldsElement target={target} defaultFields={props?.defaultFields} customFields={props?.customFields} />


                                    </Toolbar>
                                </div>}
                                <Editable
                                    readOnly={props?.readOnly || false}
                                    onFocus={(event: any) => {
                                        setActiveEditor('footer');
                                    }}
                                    className='custom-slate-editor bd-top'
                                    style={{}}
                                    renderElement={renderElement}
                                    renderLeaf={renderLeaf}
                                    spellCheck
                                    onKeyDown={(event: any) => {

                                        for (const hotkey in HOTKEYS) {
                                            if (isHotkey(hotkey, event as any)) {
                                                event.preventDefault()
                                                const mark = HOTKEYS[hotkey]
                                                toggleMark(footerEditor, mark)
                                            }
                                        }
                                    }}
                                />
                            </Slate>) : (<div className='hf-placeholder bd-top'>

                                <Button2 variant='link' onClick={() => {
                                    props?.addFooter()
                                }}>Add Footer</Button2>

                            </div>)
                    }

                </Slate>
            </div>

        </div>
    )
}
export default SlateEditor