import React, { useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TGenericResponse } from "@redux/interfaces/index.interfaces";

import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { toggleGenericSuccessModalAction } from '@redux/components/index.actions';

import { BsCheckCircleFill } from 'react-icons/bs';

export const toggleGenericSuccessModal = (dispatch: any, successResponseObj: TGenericResponse) => {
    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_SUCCESS_MODAL,
        payload: successResponseObj
    });
}

const MapStateToProps = (state: any) => ({
    genericSuccessModal: state.components.genericSuccessModal
})

const MapActionsToProps = {
    toggleGenericSuccessModal: toggleGenericSuccessModalAction
}

const GenerateSuccessModal: React.FunctionComponent = (props: any) => {
    const handleToggle = () => props.toggleGenericSuccessModal();

    return (
        <>
            <Modal
                show={props?.genericSuccessModal?.show}
                onHide={handleToggle}
                //backdrop="static"
                className="redux-generic-success-modal"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {"Başarılı !" || props?.genericSuccessModal?.code}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginBottom: 15 }}>
                        <BsCheckCircleFill style={{ color: "green" }} size={110} />
                    </div>
                    <div>
                        {props?.genericSuccessModal?.message}
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="primary" onClick={handleToggle}>Pencereyi Kapat</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default connect(MapStateToProps, MapActionsToProps)(GenerateSuccessModal);