import React from "react";

function JpegFile(props: any) {
    return (
        <svg
            width={props?.width || "35px"}
            height={props?.height || "35px"}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 100 100"
            viewBox="0 0 100 125"
        >
            <path d="M95.407 29.835L67.026 1.45A1.533 1.533 0 0065.944 1H33.796c-7.482 0-13.569 6.089-13.569 13.571v26.856H8.317A4.178 4.178 0 004.144 45.6v23.987a4.178 4.178 0 004.173 4.173h11.909v11.67C20.227 92.911 26.313 99 33.796 99h48.491c7.482 0 13.569-6.089 13.569-13.571v-54.51a1.54 1.54 0 00-.449-1.084zM18.445 62.979a1.53 1.53 0 112.893-1 3.403 3.403 0 003.214 2.295 3.405 3.405 0 003.401-3.401V49.387a1.53 1.53 0 113.06 0v11.486a6.47 6.47 0 01-6.462 6.462 6.463 6.463 0 01-6.106-4.356zm74.35 22.45c0 5.796-4.714 10.51-10.508 10.51H33.796c-5.794 0-10.508-4.714-10.508-10.51v-11.67h50.415a4.178 4.178 0 004.173-4.173V45.6a4.178 4.178 0 00-4.173-4.173H23.287V14.571c0-5.796 4.714-10.51 10.508-10.51h30.617v23.792a4.599 4.599 0 004.59 4.596h23.792v52.98zM34.54 65.804V49.387a1.53 1.53 0 011.529-1.531h4.933a6.47 6.47 0 016.462 6.462v.207a6.47 6.47 0 01-6.462 6.462h-3.403v4.818a1.529 1.529 0 11-3.059-.001zm20.433-1.529h4.46c.647 0 1.171-.526 1.171-1.171V59.88h-3.401a1.528 1.528 0 01-1.529-1.529c0-.846.683-1.531 1.529-1.531h4.933a1.53 1.53 0 011.529 1.531v4.753a4.237 4.237 0 01-4.232 4.232h-4.46a4.237 4.237 0 01-4.232-4.232v-11.02a4.235 4.235 0 014.232-4.228h4.46a4.235 4.235 0 014.232 4.228 1.53 1.53 0 01-3.06 0 1.17 1.17 0 00-1.171-1.168h-4.46a1.17 1.17 0 00-1.171 1.168v11.019a1.17 1.17 0 001.17 1.172zm-13.971-6.349h-3.403v-7.009h3.403a3.405 3.405 0 013.401 3.401v.207a3.404 3.404 0 01-3.401 3.401z"></path>
        </svg>
    );
}

export default JpegFile;
