import apiInstance from '@clients/api.client';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Örnek bir API çağrısı fonksiyonu simülasyonu
const fetchCustomFields = (company_id: string) => {

    return new Promise((resolve) => {
        apiInstance.get(`/custom-fields/${company_id}`).then((res: any) => {
            console.log(res)
            resolve(res?.data?.node?.contractsCustomFields || []);
        }
        ).catch((err: any) => {
            console.log('err', err)
        });
    });
};

export const useCustomFields = () => {
    const [customFields, setCustomFields] = useState([]);
    const companies = useSelector((state: any) => state.companies);
    console.log('companies', companies);

    useEffect(() => {
        // Asenkron işlemleri burada gerçekleştir
        const getCustomFields = async () => {
            try {
                const data: any = await fetchCustomFields(companies?.company?._id); // Veritabanından verileri çek
                setCustomFields(data); // Çekilen verileri state'e set et
            } catch (error) {
                console.error('Custom fields fetch error:', error);
                // Hata yönetimi
            }
        };

        getCustomFields(); // Asenkron fonksiyonu çağır
    }, []); // Boş dizi, useEffect'in yalnızca bileşen mount edildiğinde çalıştırılması anlamına gelir

    return customFields; // Custom field'ları döndür
};
