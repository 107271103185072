import React from "react";
import { ListGroup, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";

// task item
const NewPipelineStatusListItem: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <ListGroup.Item
        as="li"
        className="d-flex justify-content-between align-items-start mb-2"
      >
        <div className="ms-2 me-auto">
          <div className="fw-bold">{props?.title} {!props?.company
            ? (<span className="badge bg-secondary">
              {t("CONTRACTS_PIPELINES.DEFAULT")}
            </span>)
            : null}</div>
          {props?.description}
        </div>
      </ListGroup.Item>
    </>
  );
};

export default NewPipelineStatusListItem;
