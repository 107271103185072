import React, { useState } from 'react'
import './index.scss'
import { useTranslation } from "react-i18next";

export default function (props: any) {
    /* 
    PROCESS_APPROVAL
    STATUSES
    IN_PROGRESS
    NOT_STARTED
    APPROVED
    UPLOADED
    ASSIGNED
    
    */
    const { t } = useTranslation();
    const [statusDetail, setStatusDetail] = useState<any>({
        CREATED: {
            title: t('PROCESS_APPROVAL.STATUSES.CREATED'),
        },
        PENDING: {
            title: t('PROCESS_APPROVAL.STATUSES.PENDING'),
        },
        APPROVED: {
            title: t('PROCESS_APPROVAL.STATUSES.APPROVED'),
        },
        UPLOADED: {
            title: t('PROCESS_APPROVAL.STATUSES.UPLOADED'),
        },
        ASSIGNED: {
            title: t('PROCESS_APPROVAL.STATUSES.ASSIGNED'),
        }
    });

    return (
        <div className={"process-status status-" + props?.status.toString().toLocaleLowerCase()}>
            {
                statusDetail[props?.status]?.title
            }
        </div>
    )
}
