import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toggleNewAutomationModal } from "@redux/components/index.actions";
import './index.scss';
import { } from '@redux/files/index.actions';

import logoSm from '@assets/images/onedocs-green-logo.svg';
import { useTranslation } from "react-i18next";
import FeatherIcons from "feather-icons-react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import CustomFields from "../CustomFields";
import AssignUsers from "../AssignUsers";
import apiInstance from "@clients/api.client";
import { toast } from "react-toastify";

const MapStateToProps = (state: any) => ({
  components: state.components,
  contracts: state.contracts,
  companies: state.companies,
  cdn: state.files.cdn,
  uploadedFiles: state.files.newProcessUploadedFiles
});

const MapActionsToProps = {
  toggleNewAutomationModal: toggleNewAutomationModal
};


const AutomationModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const [conditions, setConditions] = useState<any>(
    [
      {
        value: 'equals',
        label: t('AUTOMATION.CONDITIONS.equals')
      },
      {
        value: 'notEquals',
        label: t('AUTOMATION.CONDITIONS.notEquals')
      },
      {
        value: 'contains',
        label: t('AUTOMATION.CONDITIONS.contains')
      },
      {
        value: 'notContains',
        label: t('AUTOMATION.CONDITIONS.notContains')
      },
      {
        value: 'isEmpty',
        label: t('AUTOMATION.CONDITIONS.isEmpty')
      },
      {
        value: 'isNotEmpty',
        label: t('AUTOMATION.CONDITIONS.isNotEmpty')
      },
      {
        value: 'isGreaterThan',
        label: t('AUTOMATION.CONDITIONS.isGreaterThan')
      },
      {
        value: 'isLessThan',
        label: t('AUTOMATION.CONDITIONS.isLessThan')
      },
      {
        value: 'isGreaterThanOrEqual',
        label: t('AUTOMATION.CONDITIONS.isGreaterThanOrEqual')
      },
      {
        value: 'isLessThanOrEqual',
        label: t('AUTOMATION.CONDITIONS.isLessThanOrEqual')
      }
    ]
  );
  const [ruleDetails, setRuleDetails] = useState<any>(undefined);
  const [actionDetails, setActionDetails] = useState<any>(undefined);

  const [automationData, setAutomationData] = useState<any>({
    when: 'processCreated',
    then: 'doAction',
    rules: [
      {
        value: 'customFieldsValue',
        label: t('AUTOMATION.RULES.customFieldsValue'),
        component: <CustomFields
          conditions={conditions}
          setConditions={setConditions}
          ruleDetails={ruleDetails}
          setRuleDetails={setRuleDetails}
          {...props} />
      }
    ],
    actions: [
      {
        value: 'addAssignUser',
        label: t('AUTOMATION.ACTIONS.addAssignUser'),
        component: <AssignUsers
          setConditions={setConditions}
          actionDetails={actionDetails}
          setActionDetails={setActionDetails}
          {...props} />
      }
    ],
  });


  const [ruleOn, setRuleOn] = useState<any>(undefined);
  const [action, setAction] = useState<any>(undefined);

  const onSubmit = () => {

    setLoading(true);

    let data = {
      when: automationData?.when,
      then: automationData?.then,
      rule: {
        on: ruleOn?.value,
        details: ruleDetails
      },
      action: {
        on: action?.value,
        details: actionDetails
      },
      conditions: conditions
    }

    console.log('data', data)

    apiInstance.post(`/automation`, data).then((res: any) => {
      console.log('res', res)
      setLoading(false);
      props?.setCreateAutomation(false);
    }
    ).catch((err: any) => {
      toast.error('Something went wrong');
      setLoading(false);
    })


  }



  const submitDisable = (): boolean => {
    if (automationData?.when && automationData?.then && ruleOn && action && ruleOn?.value && action?.value && conditions && ruleDetails && actionDetails && ruleDetails?.selectedValue && actionDetails?.selectedValue?.length > 0) {
      return false
    }
    return true
  }

  return (
    <>

      <div className="automation-create">
        <div className="automation-create-left">
          <div className="automation-create-left-when">
            <div className="automation-create-left-when-logo">
              <img src={logoSm} alt="" height="35" />


            </div>
            <div className="automation-create-left-when-content">
              <div className="automation-create-left-when-content-title">
                {t('AUTOMATION.WHEN_TITLE')}
              </div>
              <div className="automation-create-left-when-content-description">
                {t('AUTOMATION.WHEN.' + automationData?.when)}
              </div>
            </div>
          </div>
          <div className="automation-create-left-when-rule">
            <Select
              value={ruleOn || undefined}
              styles={
                {
                  control: (base, state) => ({
                    ...base,
                    width: '100%',
                    '&:hover': {
                      borderColor: '#2c42ff'
                    },
                    border: '1px solid #ced4da',
                    boxShadow: 'none',
                    '&:focus': {
                      borderColor: '#2c42ff',
                      boxShadow: 'none'
                    }
                  })
                }
              }
              options={automationData?.rules}
              onChange={(e: any) => {
                setRuleOn(e)
              }}
              isClearable={true}
            />
            <div className="automation-create-left-when-rule-content">
              {
                ruleOn?.value === 'customFieldsValue' && (
                  ruleOn?.component
                )
              }
            </div>
          </div>
        </div>
        <div className="automation-create-right">

          <div className="automation-create-right-then">
            <div className="automation-create-right-then-logo">
              <img src={logoSm} alt="" height="35" />
            </div>
            <div className="automation-create-right-then-content">
              <div className="automation-create-right-then-content-title">
                {t('AUTOMATION.THEN_TITLE')}
              </div>
              <div className="automation-create-right-then-content-description">
                {t('AUTOMATION.THEN.' + automationData?.then)}
              </div>
            </div>
          </div>
          <div className="automation-create-right-then-rule">
            <Select
              isClearable={true}
              value={action || undefined}
              styles={
                {
                  control: (base, state) => ({
                    ...base,
                    width: '100%',
                    '&:hover': {
                      borderColor: '#2c42ff'
                    },
                    border: '1px solid #ced4da',
                    boxShadow: 'none',
                    '&:focus': {
                      borderColor: '#2c42ff',
                      boxShadow: 'none'
                    }
                  })
                }
              }
              options={automationData?.actions}
              onChange={(e: any) => {
                setAction(e)
              }} />
            <div className="automation-create-right-then-rule-content">
              {
                action?.value === 'addAssignUser' && (
                  action?.component
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="automation-bottom">
        <Button
          disabled={submitDisable()}
          variant="primary" className="btn-onedocs-primary" onClick={onSubmit}>
          {t("AUTOMATION.ADD_AUTOMATION")}
        </Button>
      </div>
    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(AutomationModal);
