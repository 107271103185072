import React, { useEffect, useMemo, useRef, useState } from "react";

import { Accordion, Button, Card, Col, Dropdown, DropdownButton, Form, ListGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { } from "@redux/process-approval/index.actions";
import { } from "@redux/files/index.actions";
import { } from "@redux/components/index.actions";
import { } from "@redux/contract/index.actions";

import Spinner from "@components/Spinner";
import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus, FiLink } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import { BsLock, BsUnlock, BsShieldLock } from "react-icons/bs";
import { MdLock, MdLockOpen, MdLockOutline } from "react-icons/md";

import { evaluate, i } from "mathjs";
import { DefaultLoader } from "@components/Loader";
import PageTitle from "@components/PageTitle";
import moment from "moment";
import './index.scss';
import apiInstance from "@clients/api.client";
import Avatar from "react-avatar";
import StatusBadge from "./components/StatusBadge/index";
import cdnServicesInstance from "@clients/cdnservices.client";
import AddStepModal from "@popups/ProcessApprovalPopup/AddStepModal";
import customCDNServicesInstance from '@clients/customcdn.client';

const MessageIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            fill="none"
            viewBox="0 0 22 20"
        >
            <path
                fill="#000"
                d="M2.34 19.714c.209.182.469.286.729.286a.938.938 0 00.52-.156l4.291-2.497a11.02 11.02 0 002.783.338c5.878 0 10.663-3.979 10.663-8.842C21.326 3.979 16.541 0 10.663 0S0 3.98 0 8.843c0 2.392 1.196 4.707 3.277 6.372l-1.196 3.303c-.182.416-.078.91.26 1.196zm.703-.832l1.326-3.667a.53.53 0 00-.182-.599l-.078-.052C2.16 13.082 1.04 11.001 1.04 8.843c0-4.292 4.318-7.803 9.623-7.803 5.306 0 9.623 3.511 9.623 7.803 0 4.29-4.317 7.802-9.623 7.802-.884 0-1.768-.104-2.6-.286l-.157-.026a.682.682 0 00-.39.052l-4.473 2.6-.026-.025c.026-.026.026-.052.026-.078z"
            ></path>
            <path
                fill="#000"
                d="M5.982 6.501h5.462c.285 0 .52-.234.52-.52a.522.522 0 00-.52-.52H5.982a.522.522 0 00-.52.52c0 .286.234.52.52.52zM5.982 9.365h9.103c.286 0 .52-.235.52-.52a.522.522 0 00-.52-.52H5.982a.522.522 0 00-.52.52c0 .285.234.52.52.52zM5.982 12.224h9.103c.286 0 .52-.234.52-.52a.522.522 0 00-.52-.52H5.982a.522.522 0 00-.52.52c0 .286.234.52.52.52z"
            ></path>
        </svg>
    );
}




const mapStateToProps = (state: any) => ({
    components: state?.components,
    companies: state?.companies,
    contracts: state?.contracts,
    process: state?.contracts?.processOverview,
    files: state?.files,
    users: state?.users,
});
const mapActionsToProps = {

};
const ApprovalFlowOverview = (props: any) => {
    const { t } = useTranslation();

    const [show, setShow] = useState<boolean>(false);
    const [showDeleteStepModal, setShowDeleteStepModal] = useState<boolean>(false);
    const [deleteStepId, setDeleteStepId] = useState<string>("");

    const [showAddStepModal, setShowAddStepModal] = useState<boolean>(false);
    const toggleAddStepModal = () => {
        setShowAddStepModal(!showAddStepModal);
    }


    const [actionData, setActionData] = useState<any>({});

    const [approvalData, setApprovalData] = useState<any>({});

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const generateDownloadUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return props?.companies?.company?.customCDNEndpoint + "/local/download/" + file?.key + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return cdnServicesInstance.defaults.baseURL + `/gdrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "one-drive") {
            return cdnServicesInstance.defaults.baseURL + `/onedrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "share-point") {
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    const generateFileUrl = (file: any) => {
        console.log('File Url', file)
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return file?.fileUrl + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return file?.fileUrl;
        } else if (file?.source?.sourceKey === "one-drive") {
            //return file?.fileUrl;
            return cdnServicesInstance.defaults.baseURL + `/onedrive/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "share-point") {
            //return file?.fileUrl;
            //return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }
    const getApprovalData = (approvalId: string, processId: string) => {
        setLoading(true);
        apiInstance.get(`/process-approval`, {
            params: {
                approvalId: approvalId,
                contractId: processId
            }
        }).then((res) => {
            console.log('@@@@', res?.data?.processApprovals);
            setApprovalData(res?.data?.processApprovals)
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            setError(true);
        });
    }


    useEffect(() => {
        if (props?.approvalId && props?.processData?._id) {

            getApprovalData(props?.approvalId, props?.processData?._id);
        }
    }, [props?.approvalId])

    if (loading) {
        return (
            <>
                <div style={{
                    height: 'calc(100vh - 300px)',
                }} className="d-flex align-items-center justify-content-center">
                    <DefaultLoader />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="process-approval-overview">


                <div className="process-approval-overview-inner">
                    <span className="mb-3 d-flex align-items-center justify-content-left gap-1">
                        <h3>{approvalData?.title}</h3>
                        {/* Tooltip: Start */}
                        <OverlayTrigger placement="right" overlay={<Tooltip className="custom-pa-tooltip" >

                            <div>

                                <table>
                                    <tr style={{
                                        borderBottom: '1px solid #ccc'
                                    }}>
                                        <th style={{
                                            minWidth: '115px'
                                        }}>Started at: </th>
                                        <td>{moment(approvalData?.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                                    </tr>
                                    <tr style={{
                                        borderBottom: '1px solid #ccc'
                                    }}>
                                        <th>Started by: </th>
                                        <td>{approvalData?.user?.name + ' ' + approvalData?.user?.surname}</td>
                                    </tr>
                                    <tr>
                                        <th>Message: </th>
                                        <td>{approvalData?.message}</td>
                                    </tr>
                                </table>

                            </div>

                        </Tooltip>}>
                            <span className="user">
                                <span className="">
                                    <FeatherIcons size="22" icon="info" />
                                </span>
                            </span>
                        </OverlayTrigger>
                        {/* Tooltip: End */}
                    </span>
                    <div className="steps">
                        {
                            (approvalData?.steps || []).map((item: any, index: number) => {

                                return (
                                    <div className="steps-item">
                                        {item?.isDeleted && (<div className="step-item-deleted">{t('PROCESS_APPROVAL.DETAIL.DELETED_STEP')}</div>)}
                                        <div className="step-item-number">{index + 1}. {t('PROCESS_APPROVAL.DETAIL.STEP')}</div>
                                        <div className="step-item-wrapper" style={
                                            item?.isDeleted ? {
                                                opacity: 0.5
                                            } : {}

                                        }>
                                            {
                                                (item?.stepUsers || []).map((stepUser: any, userIndex: number) => {
                                                    return (
                                                        <div
                                                            onClick={() => {
                                                                if (stepUser?.action) {
                                                                    console.log('action', {
                                                                        ...stepUser?.action,
                                                                        lastProcessDate: stepUser?.lastProcessDate,
                                                                        stepUserStatus: stepUser?.status,
                                                                        userDetail: {
                                                                            fullname: (stepUser?.user?.name && stepUser?.user?.surname) ? stepUser?.user?.name + ' ' + stepUser?.user?.surname : stepUser.email,
                                                                        }
                                                                    })
                                                                    setActionData({
                                                                        ...stepUser?.action,
                                                                        lastProcessDate: stepUser?.lastProcessDate,
                                                                        stepUserStatus: stepUser?.status,
                                                                        userDetail: {
                                                                            fullname: (stepUser?.user?.name && stepUser?.user?.surname) ? stepUser?.user?.name + ' ' + stepUser?.user?.surname : stepUser.email,
                                                                        },
                                                                        userFiles: stepUser?.userFiles,
                                                                        needFile: stepUser?.needFile,
                                                                    });
                                                                    setShow(true);
                                                                }
                                                            }}
                                                            className="step-item-user">
                                                            <div className="step-item-user-inner">
                                                                <div className="step-item-user-avatar">
                                                                    <Avatar name={(stepUser?.user?.name && stepUser?.user?.surname) ? stepUser?.user?.name + ' ' + stepUser?.user?.surname : stepUser.email} size="30" round={true} />
                                                                </div>
                                                                <div className="step-item-user-detail">
                                                                    <div className="step-item-user-name">
                                                                        {(stepUser?.user?.name && stepUser?.user?.surname) ? stepUser?.user?.name + ' ' + stepUser?.user?.surname : stepUser.email}
                                                                        {stepUser?.action?.detail?.message && (<MessageIcon />)}
                                                                    </div>
                                                                    <StatusBadge status={stepUser?.status} />

                                                                </div>
                                                                <span className="last-process-date">
                                                                    {
                                                                        stepUser?.lastProcessDate ? moment(stepUser?.lastProcessDate).format('DD.MM.YYYY HH:mm') : ''
                                                                    }
                                                                </span>
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }

                                            {
                                                ((approvalData?.user?._id === props?.users?.user?._id) && item?.status === 'CREATED' && !item?.isDeleted) && (<>
                                                    <div
                                                        onClick={() => {
                                                            setDeleteStepId(item?._id);
                                                            setShowDeleteStepModal(true);

                                                        }}
                                                        className="step-item-wrapper-delete">
                                                        <FeatherIcons size="18" icon="trash-2" />
                                                        Delete
                                                    </div>
                                                </>)
                                            }

                                        </div>
                                        {approvalData?.steps?.length != index + 1 && <div className="connector"></div>}

                                    </div>
                                )

                            })
                        }
                        {(approvalData?.user?._id === props?.users?.user?._id) && (<>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                }}
                                className="steps-item add-step" >
                                <div className="connector" style={{
                                    height: '80px',
                                    top: '-62px'
                                }}></div>
                                <Button style={{
                                    width: '50px',
                                    height: '50px',
                                    marginLeft: '121px',
                                    zIndex: 9999,
                                    backgroundColor: '#fff',
                                    border: '1px solid #ccc',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '50%',
                                    color: '#ccc'

                                }} onClick={() => {
                                    toggleAddStepModal();
                                }}>
                                    <FeatherIcons size="18" icon="plus" />
                                </Button>
                            </div>
                        </>)}

                    </div>
                </div>

                <Modal
                    show={show}
                    onHide={() => {
                        setActionData({})
                        setShow(false)
                    }}
                    size="lg"
                    centered
                >
                    <Modal.Body>

                        <Button
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                zIndex: 9999
                            }}
                            variant="link"
                            onClick={() => {
                                setActionData({})
                                setShow(false)
                            }
                            } className="process-approval-action-close">
                            <FeatherIcons size="18" icon="x" />
                        </Button>

                        <div className="process-approval-action">

                            {
                                actionData?.stepUserStatus === 'UPLOADED' && (
                                    <>
                                        <div className="process-approval-action-feed">
                                            <div className="process-approval-action-feed-avatar">
                                                <Avatar style={{
                                                    fontSize: '12px'
                                                }} name={`${actionData?.userDetail?.fullname}`} size="30" round={true} />
                                            </div>
                                            <div className="process-approval-action-feed-detail">
                                                <div className="pc-action">
                                                    <strong>
                                                        {actionData?.userDetail?.fullname}
                                                    </strong>
                                                    <span> uploaded a file.</span>
                                                </div>
                                                <span className="process-approval-action-feed-detail-date">
                                                    {actionData.lastProcessDate ? moment(actionData.lastProcessDate).format('DD.MM.YYYY HH:mm') : ''}
                                                </span>

                                            </div>
                                        </div>
                                    </>
                                )}

                            {
                                actionData?.stepUserStatus === 'APPROVED' && (
                                    <>
                                        <div className="process-approval-action-feed">
                                            <div className="process-approval-action-feed-avatar">
                                                <Avatar style={{
                                                    fontSize: '12px'
                                                }} name={`${actionData?.userDetail?.fullname}`} size="30" round={true} />
                                            </div>
                                            <div className="process-approval-action-feed-detail">
                                                <div className="pc-action">
                                                    <strong>
                                                        {actionData?.userDetail?.fullname}
                                                    </strong>
                                                    <span> approved.</span>
                                                </div>
                                                <span className="process-approval-action-feed-detail-date">
                                                    {actionData.lastProcessDate ? moment(actionData.lastProcessDate).format('DD.MM.YYYY HH:mm') : ''}
                                                </span>

                                            </div>
                                        </div>
                                    </>
                                )}
                            {
                                actionData?.stepUserStatus === 'ASSIGNED' && (
                                    <>
                                        <div className="process-approval-action-feed">
                                            <div className="process-approval-action-feed-avatar">
                                                <Avatar style={{
                                                    fontSize: '12px'
                                                }} name={`${actionData?.userDetail?.fullname}`} size="30" round={true} />
                                            </div>
                                            <div className="process-approval-action-feed-detail">
                                                <div className="pc-action">
                                                    <strong>
                                                        {actionData?.userDetail?.fullname}
                                                    </strong>
                                                    <span> assigned the authority.</span>
                                                </div>
                                                <span className="process-approval-action-feed-detail-date">
                                                    {actionData.lastProcessDate ? moment(actionData.lastProcessDate).format('DD.MM.YYYY HH:mm') : ''}
                                                </span>

                                            </div>
                                        </div>
                                    </>
                                )}

                            {
                                actionData?.detail?.files && (
                                    <div className="process-approval-action-files">
                                        {
                                            actionData?.detail?.files?.map((file: any) => {
                                                console.log('file', file)
                                                return (
                                                    <>
                                                        <FeatherIcons size="26" icon="file" />
                                                        <a href={generateFileUrl(file)} target="_blank">{file?.filename}</a>
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }


                            {
                                actionData?.detail?.message && (
                                    <div className="process-approval-action-message">
                                        <span className="message-icon">
                                            <MessageIcon />
                                        </span>
                                        <span className="message-content">{actionData?.detail?.message}</span>
                                    </div>
                                )
                            }

                            {
                                actionData?.needFile && (
                                    <div className="process-approval-action-userfiles">
                                        {
                                            actionData?.userFiles?.map((userFile: any, index: number) => {
                                                let file = userFile?.file;
                                                return (
                                                    <div className="userfile" onClick={() => {
                                                        window.open(generateDownloadUrl(file), '_blank');
                                                    }}>
                                                        <span className="filename">{userFile?.title || '#' + index + 1}:</span>

                                                        <a href="javascript:void(0)" target="_blank">{file?.filename}</a>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }

                        </div>
                    </Modal.Body>
                </Modal>
                {/* Delete Step Modal:Start */}

                <Modal
                    show={showDeleteStepModal}
                    onHide={() => {
                        setShowDeleteStepModal(false)
                        setDeleteStepId("");
                    }}
                    size="lg"
                    centered
                >
                    <Modal.Body>
                        <div className="delete-step-modal">
                            <p className="lead">Are you sure you want to permanently delete this step? This action cannot be undone.</p>
                            <div className="delete-step-modal-buttons mt-2 d-flex align-content-center justify-content-end gap-1">
                                <Button onClick={() => {
                                    setShowDeleteStepModal(false)
                                }} variant="outline-primary">Cancel</Button>
                                <Button onClick={() => {
                                    apiInstance.delete(`/process-approval/step`, {
                                        data: {
                                            approvalId: approvalData?._id,
                                            stepId: deleteStepId
                                        }
                                    }).then((res) => {
                                        getApprovalData(props?.approvalId, props?.processData?._id);
                                        setShowDeleteStepModal(false)
                                    }).catch((err) => {
                                        console.log(err);
                                    });
                                }} variant="primary" className="btn-onedocs-primary">Delete</Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* Delete Step Modal:End */}
            </div >

            <AddStepModal show={showAddStepModal} toggle={toggleAddStepModal} approveData={approvalData} getApprovalData={getApprovalData} />
        </>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(ApprovalFlowOverview);
