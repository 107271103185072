import { ILoaderType } from '@components/Loader/interfaces/index.interfaces';


export const PageLoader: any = () => {
    return (
        <div className="container-fluid" style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            zIndex: 9998,
            top: 0,
            left: 0
        }}>
            <div className="preloader" id="preloader">
                <div style={{
                    width: 100,
                    height: 100,
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <div className="spinner" style={{
                        fontSize: 15,
                        width: '100%',
                        height: '100%',
                    }}>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};