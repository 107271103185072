import React from "react";

function McSheet(props: any) {
    return (
        <svg
            width={props?.width || "35px"}
            height={props?.height || "35px"}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 2289.75 2130"
            xmlSpace="preserve"
        >
            <path
                fill="#185C37"
                d="M1437.75 1011.75L532.5 852v1180.393c0 53.907 43.7 97.607 97.607 97.607h1562.036c53.907 0 97.607-43.7 97.607-97.607V1597.5l-852-585.75z"
            ></path>
            <path
                fill="#21A366"
                d="M1437.75 0H630.107C576.2 0 532.5 43.7 532.5 97.607V532.5l905.25 532.5L1917 1224.75 2289.75 1065V532.5L1437.75 0z"
            ></path>
            <path fill="#107C41" d="M532.5 532.5h905.25V1065H532.5V532.5z"></path>
            <path
                d="M1180.393 426H532.5v1331.25h647.893c53.834-.175 97.432-43.773 97.607-97.607V523.607c-.175-53.834-43.773-97.432-97.607-97.607z"
                opacity="0.1"
            ></path>
            <path
                d="M1127.143 479.25H532.5V1810.5h594.643c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
                opacity="0.2"
            ></path>
            <path
                d="M1127.143 479.25H532.5V1704h594.643c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
                opacity="0.2"
            ></path>
            <path
                d="M1073.893 479.25H532.5V1704h541.393c53.834-.175 97.432-43.773 97.607-97.607V576.857c-.175-53.834-43.773-97.432-97.607-97.607z"
                opacity="0.2"
            ></path>
            <linearGradient
                id="SVGID_1_"
                x1="203.513"
                x2="967.987"
                y1="1729.018"
                y2="404.982"
                gradientTransform="matrix(1 0 0 -1 0 2132)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#18884F"></stop>
                <stop offset="0.5" stopColor="#117E43"></stop>
                <stop offset="1" stopColor="#0B6631"></stop>
            </linearGradient>
            <path
                fill="url(#SVGID_1_)"
                d="M97.607 479.25h976.285c53.907 0 97.607 43.7 97.607 97.607v976.285c0 53.907-43.7 97.607-97.607 97.607H97.607C43.7 1650.75 0 1607.05 0 1553.143V576.857c0-53.907 43.7-97.607 97.607-97.607z"
            ></path>
            <path
                fill="#FFF"
                d="M302.3 1382.264l205.332-318.169L319.5 747.683h151.336l102.666 202.35c9.479 19.223 15.975 33.494 19.49 42.919h1.331a798.667 798.667 0 0121.3-44.677L725.371 747.79H864.3l-192.925 314.548L869.2 1382.263H721.378L602.79 1160.158a186.298 186.298 0 01-14.164-29.66h-1.757a140.458 140.458 0 01-13.739 28.755l-122.102 223.011H302.3z"
            ></path>
            <path
                fill="#33C481"
                d="M2192.143 0H1437.75v532.5h852V97.607C2289.75 43.7 2246.05 0 2192.143 0z"
            ></path>
            <path fill="#107C41" d="M1437.75 1065h852v532.5h-852V1065z"></path>
        </svg>
    );
}

export default McSheet;
