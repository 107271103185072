//TYPES
import { ILoaderType } from '@components/Loader/interfaces/index.interfaces';
/**
 * Renders the preloader
 */
const Loader: ILoaderType = () => {
    return (
        <div style={{ zIndex: 99999999 }} className="preloader" id="preloader">
            <div className="status" id="status">
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};


export const WhiteLoader: React.FunctionComponent<{ size?: number, border?: number }> = (props) => {
    return (<div className="spinner-border text-light" style={{
        width: props?.size || 40,
        height: props?.size || 40,
        borderWidth: props?.border || 4
    }} role="status">
        <span className="visually-hidden">Loading...</span>
    </div>);
}

export const DefaultLoader: React.FunctionComponent<{ size?: number, border?: number }> = (props) => {
    return (<div className="spinner-border text-dark" style={{
        width: props?.size || 40,
        height: props?.size || 40,
        borderWidth: props?.border || 4,
    }} role="status">
        <span className="visually-hidden">Loading...</span>
    </div>);
}


export default Loader;
