import React from "react";

function McSlide(props: any) {
    return (
        <svg
            width={props?.width || "35px"}
            height={props?.height || "35px"}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            viewBox="0 0 172.022 156.453"
        >
            <defs>
                <linearGradient
                    x1="0"
                    x2="172.074"
                    y1="767.839"
                    y2="767.839"
                    gradientTransform="scale(1.28817 .7763)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#2c5a9e" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#285294" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    x1="0"
                    x2="172.074"
                    y1="767.839"
                    y2="767.839"
                    gradientTransform="scale(1.28817 .7763)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#2c5a9e" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#285294" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    x1="0"
                    x2="172.074"
                    y1="767.839"
                    y2="767.839"
                    gradientTransform="scale(1.28817 .7763)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#217747" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#1d6b41" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    x1="0"
                    x2="172.074"
                    y1="767.839"
                    y2="767.839"
                    gradientTransform="scale(1.28817 .7763)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#d44a27" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#d04323" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    x1="0"
                    x2="172.074"
                    y1="767.839"
                    y2="767.839"
                    gradientTransform="scale(1.28817 .7763)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#2c5a9e" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#285294" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    x1="0"
                    x2="172.074"
                    y1="767.839"
                    y2="767.839"
                    gradientTransform="scale(1.28817 .7763)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#217747" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#1d6b41" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    x1="0"
                    x2="172.074"
                    y1="767.839"
                    y2="767.839"
                    gradientTransform="scale(1.28817 .7763)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#d44a27" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#d04323" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient id="linearGradient11849">
                    <stop offset="0" stopColor="#d04223" stopOpacity="1"></stop>
                    <stop offset="1" stopColor="#d44a27" stopOpacity="1"></stop>
                </linearGradient>
                <linearGradient
                    id="linearGradient17477"
                    x1="110.829"
                    x2="110.829"
                    y1="729.648"
                    y2="596.074"
                    gradientTransform="matrix(0 -.70582 .70582 0 -492.157 131.62)"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient11849"
                ></linearGradient>
            </defs>
            <g transform="translate(71.437 24.832)">
                <g
                    fill="#fff"
                    fillOpacity="1"
                    stroke="none"
                    transform="matrix(.70582 0 0 .70582 1.019 -406.663)"
                >
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.25"
                        d="M0 569.896H138.075V729.646H0z"
                        className="st1"
                    ></path>
                </g>
                <g
                    fill="#d24625"
                    fillOpacity="1"
                    stroke="none"
                    transform="matrix(.70582 0 0 .70582 -8.317 -403.765)"
                >
                    <path
                        fill="#d24625"
                        fillOpacity="1"
                        stroke="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="0.683"
                        d="M9.48 560.69c-5.25 0-9.48 3.99-9.48 8.96v151.04c0 4.96 4.23 8.96 9.48 8.96h135.33c5.25 0 9.48-4 9.48-8.96V569.65c0-4.97-4.23-8.96-9.48-8.96zm-1.65 7.94h138.63v152.89l-139.17.18.29-153.59z"
                        className="st6"
                    ></path>
                </g>
                <g
                    fill="#d24625"
                    fillOpacity="1"
                    stroke="none"
                    transform="matrix(.70582 0 0 .70582 5.894 -439.183)"
                >
                    <path
                        fill="#d24625"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.25"
                        d="M0 719.538H97.416V729.646H0z"
                        className="st7"
                    ></path>
                </g>
                <g
                    fill="#d24625"
                    fillOpacity="1"
                    stroke="none"
                    transform="matrix(.70582 0 0 .70582 5.894 -422.366)"
                >
                    <path
                        fill="#d24625"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.25"
                        d="M0 719.538H97.416V729.646H0z"
                        className="st7"
                    ></path>
                </g>
                <g
                    fill="#d24625"
                    fillOpacity="1"
                    stroke="none"
                    transform="matrix(.70582 0 0 .70582 17.622 -455.715)"
                >
                    <g transform="translate(4.642 -4.087)">
                        <path
                            fill="#d24625"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.25"
                            d="M32.66 696.99h32.66a32.659 32.659-180 00-32.66-32.66z"
                            className="st7"
                        ></path>
                    </g>
                    <g>
                        <path
                            fill="#d24625"
                            fillOpacity="1"
                            stroke="none"
                            strokeWidth="0.25"
                            d="M32.66 696.99v-32.66a32.659 32.659-180 1032.66 32.66z"
                            className="st7"
                        ></path>
                    </g>
                </g>
                <path
                    fill="url(#linearGradient17477)"
                    fillOpacity="1"
                    stroke="none"
                    strokeWidth="0.176"
                    d="M22.847 131.62V-24.831L-71.437-9.19v125.164z"
                    className="st25"
                ></path>
                <g
                    fill="#fff"
                    fillOpacity="1"
                    stroke="none"
                    transform="matrix(.70582 0 0 .70582 -40.293 -434.482)"
                >
                    <path
                        fill="#fff"
                        fillOpacity="1"
                        stroke="none"
                        strokeWidth="0.75"
                        d="M0 728.82v-74.27l25.71-1.43c8.321-.268 12.2 2.554 16.04 8.044 3.35 4.8 4.35 11.706 3.83 17.746-1.11 12.76-9 23.78-20.87 24.38H13.88v-13.718h7.22c5.175-.229 9.055-5.871 8.807-11.731-.223-6.61-3.698-10.53-8.401-10.696l-7.626.045.01 62.46z"
                        className="st24"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default McSlide;
