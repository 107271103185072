import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import UnknownUser from '@assets/images/users/unknown.jpg';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

export const localStorageKeys: {

} = {

};

const initialState = {
    departments: [],
    endedFetchingDepartments: false

}


const DepartmentsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.GET_DEPARTMENTS_SUCCESSFUL:
            return {
                ...state,
                departments: action.payload?.node?.departments
            }
        case ActionDispatchTypes.DELETE_DEPARTMENTS_SUCCESSFUL:
            return {
                ...state,
                departments: state.departments.filter((department: any) => department._id !== action.payload?._id)
            }
        default:
            return state;
    }
}

export default DepartmentsReducer;