import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Row, Col, Button, Alert } from "react-bootstrap";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { connect } from "react-redux";
import { deleteReminder } from "@redux/contract/index.actions";

// components
import Select from "react-select";
import { useTranslation } from "react-i18next";

import EditPipelineStatusList from "@pages/pipelines/components/EditPipelineStatusList";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
});

const mapActionsToProps = {
  deleteReminder,
};

const DeleteConfirmPopup = (props: any) => {
  const { t } = useTranslation();
  const handleConfirmRemove = () => {
    props
      .deleteReminder({
        reminderId: props?.deletedData?.id,
      })
      .then((res: any) => {
        if (res == true) {
          props?.toggleDeleteConfirmModal();
          props?.handleRefresh();
        }
      });
  };

  return (
    <Modal
      contentClassName="shadow"
      backdrop={true}
      show={props?.deleteConfirmModal}
      onHide={props?.toggleDeleteConfirmModal}
      size="sm"
      centered
    >
      <Modal.Header className="bg-danger" closeButton>
        <h4 className="modal-title text-white">{t("CONTRACTS.OVERVIEW.REMINDER.ARE_YOU_SURE")}</h4>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <p>
            {t("CONTRACTS.OVERVIEW.REMINDER.ARE_YOU_SURE_MODAL_DESCRIPTION")}
          </p>
          <Col md={12} className="d-flex justify-content-end gap-1">
            <Button
              className="btn btn-sm"
              onClick={props?.toggleDeleteConfirmModal}
            >
              {t("CONTRACTS.OVERVIEW.REMINDER.CANCEL")}
            </Button>
            <Button
              className="btn btn-sm btn-danger"
              onClick={handleConfirmRemove}
            >
              {t("CONTRACTS.OVERVIEW.REMINDER.REMOVE")}

            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(DeleteConfirmPopup);
