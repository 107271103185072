import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toggleNewAutomationModal } from "@redux/components/index.actions";

import { } from '@redux/files/index.actions';

import { useTranslation } from "react-i18next";
import './index.scss'
import FeatherIcons from "feather-icons-react";
import { Button, Form } from "react-bootstrap";
import CreateAutomation from "./components/CreateAutomation/";
import ListAutomations from "./components/ListAutomations/";

const MapStateToProps = (state: any) => ({
  components: state.components,
  contracts: state.contracts,
  companies: state.companies,
  cdn: state.files.cdn,
  uploadedFiles: state.files.newProcessUploadedFiles
});

const MapActionsToProps = {
  toggleNewAutomationModal: toggleNewAutomationModal
};


const AutomationModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const [createAutomation, setCreateAutomation] = useState<boolean>(props?.components?.automationInitialPage == 'add' ? true : false);

  return (
    <>
      <Modal
        show={props?.components?.newAutomationModal}
        onHide={() => {
          props.toggleNewAutomationModal();
        }}
        size="xl"
        keyboard={false}
        backdrop={false}
        centered
        contentClassName="shadow"
        dialogClassName="modal-70w"
      >
        <Modal.Body className="automation">
          <div className="automation-header">

            <div className="automation-header-title">
              {createAutomation ? (
                <>
                  <Button variant="link" onClick={() => {
                    setCreateAutomation(false)
                  }
                  }>
                    <FeatherIcons icon="chevron-left" />
                  </Button>

                  <span>{t("AUTOMATION.ADD_AUTOMATION")}</span>
                </>
              ) : (
                <>
                  <span>{t("AUTOMATION.AUTOMATIONS")}</span>
                </>
              )}
            </div>
            <div className="automation-header-close">
              <Button variant="link" onClick={() => {
                props.toggleNewAutomationModal();
              }}>
                <FeatherIcons icon="x" />
              </Button>
            </div>
          </div>

          <div className="automation-body">

            {createAutomation ? (
              <CreateAutomation
                setCreateAutomation={setCreateAutomation}
              />
            ) : (
              <div className="automation-list">
                {/* <div className="automation-action-bar">
                  <Button
                    onClick={() => {
                      setCreateAutomation(true)
                    }}
                    variant="primary" className="btn-onedocs-primary">
                    {t("Add Automation")}
                  </Button>

                </div> */}
                <ListAutomations
                  setCreateAutomation={setCreateAutomation}
                />
              </div>
            )}

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(AutomationModal);
