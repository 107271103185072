import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Card, FloatingLabel, Form, Row, Col, ListGroup, OverlayTrigger
  , Tooltip,
  Button
} from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { connect } from "react-redux";
import { deleteCustomField, setNewContractTypeData } from "@redux/contract/index.actions";

import { useTranslation } from "react-i18next";

import DefaultFieldsList from "@pages/contractTypes/components/CustomFieldOffcanvas/DefaultFieldsList";


const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts
});
const mapActionsToProps = {
  setNewContractTypeData,
};

const StatusDropList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const handleInputChange = (event: any) => {
    console.log(event?.target?.value)
    console.log(event?.target?.name)
    props.setNewContractTypeData({
      key: "changeInput",
      data: {
        [event?.target?.name]: event?.target?.value,
      }
    })
  }

  const SVGIcon = (props: any) => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 24 30">
        <path d="M15.55 23.94H9.5a.5.5 0 01-.5-.5c0-1.378 1.122-2.5 2.5-2.5h.793L8.47 17.117a1.552 1.552 0 01-.458-1.104c0-.417.163-.809.458-1.104.589-.588 1.617-.59 2.207 0l.896.896a1.57 1.57 0 011.645-.912 1.56 1.56 0 012.045-1.178 1.563 1.563 0 012.59-.63l1.55 1.55A5.418 5.418 0 0121 18.49a5.456 5.456 0 01-5.45 5.45zm-5.465-1h5.465A4.455 4.455 0 0020 18.49a4.421 4.421 0 00-1.303-3.146l-1.55-1.55a.56.56 0 10-.793.793.498.498 0 010 .708.5.5 0 01-.707 0l-.5-.5a.56.56 0 00-.793.792l.5.5a.498.498 0 010 .707.5.5 0 01-.707 0l-.75-.75a.574.574 0 00-.793 0 .557.557 0 000 .792l1.25 1.25a.502.502 0 010 .708.5.5 0 01-.707 0L9.97 15.617a.575.575 0 00-.793 0 .558.558 0 000 .792l4.676 4.676a.499.499 0 01-.354.854h-2a1.506 1.506 0 00-1.414 1.001zM3.5 20.44c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5S7 15.01 7 16.94s-1.57 3.5-3.5 3.5zm0-6c-1.378 0-2.5 1.122-2.5 2.5s1.122 2.5 2.5 2.5S6 18.318 6 16.94s-1.122-2.5-2.5-2.5zM21.207 6.856a.5.5 0 01-.117-.986 2.49 2.49 0 00.762-.327.5.5 0 01.541.841 3.51 3.51 0 01-1.067.458.49.49 0 01-.119.014zm-1.955-.187a.517.517 0 01-.208-.045 3.48 3.48 0 01-.961-.652.5.5 0 11.691-.723c.202.193.433.35.687.465a.501.501 0 01-.209.955zm4.084-1.748a.5.5 0 01-.473-.663 2.49 2.49 0 00.037-1.522.5.5 0 01.96-.282 3.535 3.535 0 01-.051 2.131.502.502 0 01-.473.336zm-5.807-.555a.502.502 0 01-.495-.429 3.564 3.564 0 01.029-1.161.5.5 0 01.982.19 2.556 2.556 0 00-.02.829.5.5 0 01-.496.571zm1.007-2.694a.5.5 0 01-.328-.877c.295-.256.629-.459.99-.604a.5.5 0 01.373.927 2.52 2.52 0 00-.709.433.496.496 0 01-.326.121zm3.704-.176a.495.495 0 01-.29-.093 2.476 2.476 0 00-.745-.362.5.5 0 11.282-.96c.375.11.727.281 1.045.509a.499.499 0 01-.292.906z"></path>
        <path d="M5 14.44a.499.499 0 01-.487-.617c.078-.323.215-.673.407-1.042a.5.5 0 11.887.463c-.155.296-.263.57-.322.813A.498.498 0 015 14.44zm1.493-2.578a.5.5 0 01-.386-.817c.21-.256.437-.516.678-.777a.5.5 0 01.735.679c-.228.246-.441.49-.639.732a.504.504 0 01-.388.183zm2.079-2.166a.5.5 0 01-.338-.869c.248-.228.502-.454.762-.676a.5.5 0 01.65.76c-.25.216-.497.434-.735.654a.502.502 0 01-.339.131zm2.311-1.92a.499.499 0 01-.301-.899c.272-.206.546-.406.821-.601a.5.5 0 11.577.817c-.266.188-.532.382-.796.582a.498.498 0 01-.301.101zm2.482-1.69a.5.5 0 01-.259-.928c.301-.183.598-.354.889-.511a.5.5 0 01.477.879c-.277.15-.56.313-.848.487a.504.504 0 01-.259.073zm2.685-1.337a.501.501 0 01-.169-.971 6.336 6.336 0 011.049-.287.501.501 0 01.167.987 5.225 5.225 0 00-.878.242.5.5 0 01-.169.029z"></path>
      </svg>
    )
  }

  return (
    <Card style={{
      borderStyle: "dashed",
    }}>

      <Card.Body>
        <div className="custom-card-header">
          <h6>{t("CONTRACTS_TYPES.SELECTED_STATUSES")}</h6>

        </div>


        <Droppable key={2} droppableId={"contractStatuses"}>
          {(provided, snapshot) => (
            <div className="drop-list" ref={provided.innerRef}>
              {
                props?.contracts?.newContractType?.statuses?.length <= 0 && (<>
                  <span style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "1rem",

                    justifyContent: "center",

                  }}>
                    <span style={{

                      width: "50px",
                      height: "50px",
                    }}>{<SVGIcon />}</span>
                    {t("Bu kayıt tipi için seçtiğiniz aşamaları buraya sürükleyin.")}
                  </span>
                </>)
              }

              <ListGroup className="dropInner" as="ul">
                {(props?.contracts?.newContractType?.statuses || []).map((item: any, index: number) => (
                  <Draggable
                    key={item?._id}
                    draggableId={item?._id + ""}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListGroup.Item
                          key={index}
                          as="li"
                          className="rounded mb-1 border-1"
                        >
                          <div className="d-flex gap-1 align-items-center justify-content-between">
                            <span>{item?.title}</span>
                            <Button
                              onClick={() => {
                                props?.setNewContractTypeData({
                                  key: "removeStatus",
                                  data: {
                                    sourceId: 'contractStatuses',
                                    destinationId: 'statusFieldList',
                                    sourceIndex: 0,
                                    destinationIndex: index
                                  }
                                })
                              }}

                              variant="link" size="sm" >
                              <FeatherIcons icon={'trash'} size="16" />
                            </Button>

                          </div>
                        </ListGroup.Item>
                      </div>
                    )}
                  </Draggable>
                ))}
              </ListGroup>
              {provided.placeholder}
            </div>
          )}
        </Droppable>


      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(StatusDropList);
