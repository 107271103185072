import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import { MdFormatListBulleted, MdFormatListNumbered } from 'react-icons/md';

export default function Lists(props: any) {

    const editor = useSlate()

    return (
        <Dropdown className='p-0 m-0'>
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip>
                    Lists
                </Tooltip>
            }>
                <Dropdown.Toggle

                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                    }}
                    as={Button}
                    variant='link'
                >

                    <MdFormatListNumbered size={18} />
                    <FaChevronDown style={{ marginLeft: '3px' }} size={10} />



                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='slate-toolbar-dd-menu'
            >
                <Dropdown.Item
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                        toggleBlock(editor, 'numbered-list')
                    }}
                    active={isBlockActive(editor, 'numbered-list')}
                ><MdFormatListNumbered size={18} /></Dropdown.Item>
                <Dropdown.Item
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                        toggleBlock(editor, 'bulleted-list')
                    }}
                    active={isBlockActive(editor, 'bulleted-list')}
                >
                    <MdFormatListBulleted size={18} />
                </Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>
    )
}
