import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import './index.scss';

const randomIdGenerator = (length = 8) => {
    return Math.random().toString(36).substr(2, length);
}



const ScreenModal: React.FunctionComponent<{
    show?: boolean,
    className?: string,
    containerClassName?: string,
    children?: React.ReactChild,
    renderHeader?: React.ReactNode,
    renderFooter?: React.ReactNode,
    style?: React.CSSProperties,
    onClose: () => void,
    scrollable?: boolean,
    size?: 'xl' | 'lg' | 'sm',
    maxHeight?: string,
}> = (props) => {
    const [show, setShow] = useState(false);
    const modalId = randomIdGenerator();
    useEffect(() => {
    }, [])
    return (
        <React.Fragment>
            <Modal
                contentClassName={props?.className ? props?.className : 'modal-content'}
                show={props?.show || false}
                onClose={props?.onClose}
                onHide={props?.onClose}
                size={props?.size || "xl"}
                id={modalId}
                centered>
                <Modal.Dialog size={props?.size || "xl"} scrollable={props?.scrollable} style={{
                    paddingLeft: "0px !important",
                    padding: "0px !important",
                    margin: 0,
                    display: 'flex',
                    ...props?.style
                }}>
                    {props?.renderHeader && (<Modal.Header className="screen-modal-header" closeButton>
                        <Modal.Title>
                            {props?.renderHeader}
                        </Modal.Title>
                    </Modal.Header>)}
                    <Modal.Body className='screen-modal-body' style={{
                        height: 'auto',
                        display: "flex"
                    }}>
                        {props?.children}
                    </Modal.Body>
                    {props?.renderFooter && (<Modal.Footer>
                        {props?.renderFooter}
                    </Modal.Footer>
                    )}
                </Modal.Dialog>

            </Modal>
        </React.Fragment>
    )
}

export const SaveAndCloseButtonGroup: React.FunctionComponent<{
    onSave?: any,
    onClose?: any,
}> = (props) => {
    return (
        <React.Fragment>
            <Button className="btn btn-primary btn-success" onClick={props?.onSave}>Save</Button>
            <Button className="btn btn-primary" onClick={props?.onClose}>Close</Button>
        </React.Fragment>
    )
}

export default ScreenModal;