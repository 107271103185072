import React, { useEffect, useState } from "react";
import CompanyOnboarding from "@popups/CompanyOnboarding";
import AddUserToCompany from "@popups/AddUserToCompany";
import { connect } from "react-redux";
import { getCurrentUser } from "@redux/users/index.actions";
import { toggleNewCompanyModal, toggleUploadFileModal } from "@redux/components/index.actions";
import CompanySwitch from "@popups/CompanySwitch";
import AddCustomFieldModal from "@popups/AddCustomField/AddCustomFieldModal";
import AddContractModal from "@popups/AddContract/AddContractModal";
import UploadFilesPopup from "@popups/UploadFilesPopup";
import FilesVersionsPopup from "./FileVersionsPopup";
import AddPipelineModal from "@popups/AddPipeline/AddPipelineModal";
import AddContractTypeModal from "@popups/AddContractType/AddContractTypeModal";
import EditCustomFormsPopup from "@popups/EditCustomFormsPopup/EditCustomFormsPopup";
import AddStatusModal from "@popups/AddStatus/AddStatusModal";
import AddContractCompanies from "@popups/AddContractCompanies/AddContractCompanies";
import GoogleDocsIFrameModal from "@popups/GoogleDocsIFrameModal/index";
import GoogleFilePermissionModal from "@popups/GoogleFilePermissionModal";
import SharePointFilePermissionModal from "@popups/SharePointFilePermissionModal";
import OneDriveFilePermissionModal from "@popups/OneDriveFilePermissionModal";
import ContractOverviewModal from "@popups/ContractOverview";
import GoogleDocsTemplateIFrameModal from "@popups/GoogleDocsTemplateIFrameModal/index";
import FilePdfViewer from "@popups/FilePdfViewer";
import InternalApprovePopup from "@popups/InternalApprovePopup";
import ApprovalModal from "@popups/AddApprovalTemplateModal";
import ApprovalProcessFileModal from "@popups/ApprovalProcessFileModal";
import ApprovalProcessFileModalV2 from "@popups/ApprovalProcessFileModalV2";
import ProcessApprovalPopup from "@popups/ProcessApprovalPopup";
import CreateProcessScreen from "@popups/CreateProcessScreen";
import NewProcessModal from "@popups/NewProcessModal";
import FillFormScreen from "@popups/FillFormScreen";
import AutomationPopup from "@popups/AutomationPopup";
import CDNUploadPopup from "./CDNUploadPopup";
import { toggleGenericAddContractModal, toggleGenericContractOverviewModal } from "@redux/components/index.actions";
import { useHistory } from "react-router-dom";
import EditorScreen from "./EditorScreen";

const mapStateToProps: any = (state: any) => ({
  users: state.users,
  components: state.components,
  approve: state.approve,
  processApproval: state.processApproval,
});

const mapActionsToProps: any = {
  getCurrentUser: getCurrentUser,
  toggleNewCompanyModal: toggleNewCompanyModal,
  toggleUploadFileModal: toggleUploadFileModal,
  toggleGenericContractOverviewModal: toggleGenericContractOverviewModal,

};
const RenderPopups: React.FC<any> = (props) => {
  const [data, setData] = useState<string[]>(["Ali", "Ayşe", "Mehmet"]);


  useEffect(() => {
    console.log('processApproval', props.processApproval)

  }
    , [props.processApproval])
  const history = useHistory();

  useEffect(() => {
    if (!props?.users?.user?.companies) {
      return;
    }
    if (!props?.users?.user?.isEmailVerified) {
      return;
    }
    if (props?.components?.toggleNewCompanyModal) {
      return;
    }
    if ((props?.users?.user?.companies || []).length === 0) {
      props?.toggleNewCompanyModal(true);
    }
  }, [props?.users?.user?.companies]);

  /* useEffect(() => {
    const invite = JSON.parse(localStorage.getItem("userInvite") || "{}");
    console.log(window.location.pathname)
        if (invite?.inviteCode && window.location.pathname != `/company/users/invites/${invite.inviteCode}`) {
          window.location.href = `/company/users/invites/${invite.inviteCode}`;
        }
  
        console.log("invite", invite);
  }, []) */

  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const contractId: string | null = urlParams.get('c');
    if (contractId) {

      if (contractId != undefined && contractId != null && contractId != 'undefined') {

        setTimeout(() => {
          console.log('ÇALIŞTI')
          props.toggleGenericContractOverviewModal({ show: true, contractId: contractId })
        }, 500)
      }



    } else {
      //history.push(redirectUrl);
    }


  }, [])

  return (
    <React.Fragment>
      {props?.components?.showNewCompanyModal && <CompanyOnboarding />}
      {props?.components?.showAddNewUserToCompanyModal && <AddUserToCompany />}
      {props?.components?.showCompanySwitchModal && <CompanySwitch />}
      {props?.components?.newCustomFieldModal && <AddCustomFieldModal />}
      {props?.components?.newPipelineModal && <AddPipelineModal />}
      {props?.components?.newContractModal && <AddContractModal />}
      {props?.components?.newContractTypeModal && <AddContractTypeModal />}
      {props?.components?.editCustomFormsModal && <EditCustomFormsPopup />}
      {props?.components?.newStatusModal && <AddStatusModal />}
      {props?.components?.googleDocsIFrameModal && <GoogleDocsIFrameModal />}
      {props?.components?.templateGoogleDocsIFrameModal && <GoogleDocsTemplateIFrameModal />}
      {props?.components?.googleFilePermissionModal && <GoogleFilePermissionModal />}
      {props?.components?.sharePointFilePermissionModal && <SharePointFilePermissionModal />}
      {props?.components?.oneDriveFilePermissionModal && <OneDriveFilePermissionModal />}
      {props?.components?.newContractCompaniesModal && <AddContractCompanies />}
      {props?.components?.contractOverviewModal && <ContractOverviewModal />}
      {props?.components?.filesPdfViewerModal?.show && <FilePdfViewer />}
      {props?.components?.approvalTemplateModal && <ApprovalModal />}
      {props?.components?.approvalProcessFileModal && <ApprovalProcessFileModal />}
      {props?.components?.approvalProcessFileModalV2?.show && <ApprovalProcessFileModalV2 />}
      {props?.components?.createProcessModal?.show && <CreateProcessScreen />}
      {props?.components?.fillFormModal?.show && <FillFormScreen />}
      {props?.components?.editorModal?.show && <EditorScreen />}
      {props?.components?.newProcessModal?.show && <NewProcessModal />}
      {props?.approve?.internalApprovePopup?.show && <InternalApprovePopup />}
      {props?.processApproval?.sendProcessApprovalPopup?.show && <ProcessApprovalPopup />}
      {/* {props?.components?.showUploadFileModal && <UploadFilesPopup
        show={props?.components?.showUploadFileModal}
        reOpen={() => {
          props?.toggleUploadFileModal(false);
          setTimeout(() => {
            props?.toggleUploadFileModal(true);
          }, 300)

        }}
        fixedError={true}
        onClose={() => {
          props?.toggleUploadFileModal(false);
        }}
      />} */}
      {props?.components?.showUploadFileModal && <CDNUploadPopup />}
      {props?.components?.showFilesVersions && <FilesVersionsPopup />}
      {props?.components?.newAutomationModal && <AutomationPopup />}
    </React.Fragment>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(RenderPopups);
