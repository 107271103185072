import apiInstance from '@clients/api.client';
import React, { useEffect, useState } from 'react';
import { Document, pdfjs, Page } from "react-pdf";
import cdnServicesInstance from '@clients/cdnservices.client';
import { PageLoader } from '@components/Loader/PageLoader';
import FeatherIcons from 'feather-icons-react';
import ApprovalButton from '@components/ApprovalButton';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewer(props: any) {
  const [numPages, setNumPages] = useState<any>(1);
  const [scale, setScale] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<any>(1);

  const [file, setFile] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(true);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    console.log('Loaded a document with ', numPages, ' pages!');
    setNumPages(numPages);
  }

  const downloadAwsFile = (url: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const file = await cdnServicesInstance.get(url, { responseType: 'blob' });
        console.log('CDN_FILE', file);
        const blob = new Blob([file.data], { type: 'application/pdf' });
        let base64data: any;
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          base64data = reader.result;
          resolve(base64data)
        }
      } catch (error) {
        reject(error);
      }
    })
  }



  const downloadFile = async () => {
    try {
      setLoading(true);
      const url = await downloadAwsFile(props?.url)
      setFile(url);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    downloadFile();
  }, [])




  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '800px',
        width: '100%',
        overflow: 'auto'
      }}>
        {file && (
          <Document
            loading={<div>Loading...</div>}
            file={`${file}`}
            noData={<div>No PDF file.</div>}
            onPassword={(password: any) => console.log('password', password)}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              scale={scale}
              pageNumber={pageNumber} />
          </Document>
        )}
      </div>
      <div className={props?.className}>
        <p>Page {pageNumber} of {numPages}</p>
        <div className='d-flex gap-1'>

          <div className='btn-group'>
            <button
              className='btn btn-sm btn-primary'
              type="button"
              disabled={pageNumber <= 1}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              <FeatherIcons icon="chevron-left" />
            </button>
            <button
              className='btn btn-sm btn-primary'
              type="button"
              disabled={pageNumber >= numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <FeatherIcons icon="chevron-right" />
            </button>
          </div>
          <div className='btn-group'>
            <button
              className='btn btn-sm btn-primary'
              type="button"
              disabled={scale <= 1}
              onClick={() => setScale(scale - 0.1)}
            >
              <FeatherIcons icon="minus" />
            </button>
            <button
              className='btn btn-sm btn-primary'
              type="button"
              disabled={scale >= 2}
              onClick={() => setScale(scale + 0.1)}
            >
              <FeatherIcons icon="plus" />
            </button>

          </div>

        </div>
      </div>
    </>
  );
}

export default PdfViewer