import { Route, Redirect, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
//TYPES
import { PrivateRouteProps } from '@routes/interfaces/PrivateRoute.interfaces';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { changeUsersLocale, changeUsersLocaleWithoutRequest, getCurrentUser } from '@redux/users/index.actions';
import { PageLoader } from '@components/Loader/PageLoader';
import { withRouter } from 'react-router-dom';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */

const mapStateToProps = (state: {
    users: any,
    companies: any
}) => ({
    users: state.users,
    companies: state.companies
});

const mapActionsToProps = {
    getCurrentUser: getCurrentUser,
    changeUsersLocale: changeUsersLocale
}

const PrivateRoute: React.FunctionComponent<any> = (props) => {
    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);
    const to = urlParams.get('to');
    const redirectToConfirm = () => {
        history.push('/confirm');
    }

    useEffect(() => {
        if (props?.users?.authorization?.token && props?.users?.user) {
            if (!props?.users?.user?.isEmailVerified) {
                redirectToConfirm();
            }
        }
    }, [props?.users?.user]);


    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (props?.users?.authorization?.token) {
            const to = urlParams.get("to");

            if (to) {
                history.push(to);
            }
        }

    }, [])


    if (!props?.users?.authorization?.token && !urlParams.get("token")) {
        if (!props?.users?.authorization?.user?.email) {
            if (to) {
                return <Redirect to={`/login?to=${to}`} />
            } else {
                return <Redirect to={`/login`} />
            }
        } else {
            return <Redirect to={`/locked`} />;
        }
    }

    /* if (!users?.authorization?.token) {
        if (!users?.authorization?.user?.email) {
            return <Redirect to="/login" />;
        } else {
            return <Redirect to="/locked" />;
        }
    } */



    if (!props?.users?.user) {
        return <PageLoader />
    }


    const { component: Component, ...rest } = props;

    return (
        <Route
            {...rest}
            render={(properties: RouteComponentProps) => {
                // authorised so return component
                return <Component {...properties} />;
            }}
        />
    );
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(PrivateRoute));
