import * as ActionDispatchTypes from "@redux/ActionDispatchTypes/index.types";
import { Reducer } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

const initialState = {
    statistics: {
        contactStatistics: 0,
        contractsCompaniesStatistics: 0,
        contractsContactsStatistics: 0,
        filesStatistics: 0,
        usersStatistics: 0,
        templatesStatistics: 0,
        customFormsStatistics: 0,
        contractsDetailedStatistics: [],
        contractsTypeStatistics: [],
        contractsMonthlyStatistics: [],
    }
};

const DashboardReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {

        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }

        case ActionDispatchTypes?.LOGOUT:
            //window.location.href = "/";
            return {
                ...initialState
            }

        case ActionDispatchTypes.GET_DASHBOARD_STATISTICS:
            return {
                ...state,
            };


        case ActionDispatchTypes.GET_DASHBOARD_STATISTICS_SUCCESSFUL:
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    contactStatistics: action?.payload?.contactStatistics,
                    contractsCompaniesStatistics: action?.payload?.contractsCompaniesStatistics,
                    contractsContactsStatistics: action?.payload?.contractsContactsStatistics,
                    filesStatistics: action?.payload?.filesStatistics,
                    usersStatistics: action?.payload?.usersStatistics,
                    templatesStatistics: action?.payload?.templatesStatistics,
                    customFormsStatistics: action?.payload?.customFormsStatistics,
                    contractsDetailedStatistics: [...action?.payload?.contractsDetailedStatistics],
                    contractsTypeStatistics: [...action?.payload?.contractsTypeStatistics],
                    contractsMonthlyStatistics: [...action?.payload?.contractsMonthlyStatistics],
                }
            };

        case ActionDispatchTypes.GET_DASHBOARD_STATISTICS_FAILED:
            return {
                ...state,
            };

        default:
            return state;
    }
};

export default DashboardReducer;
