import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';

import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import { toast } from 'react-toastify';

import api from '@clients/api.client';

import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';

/* Login a user */
export const getRoles: TAction = (payload: any) => async (dispatch, getState) => {
    dispatch({
        type: ActionDispatchTypes.GET_ROLES,
        payload: payload
    });
    const company = getState()?.companies?.company?._id;
    return api.get(`/roles/${company}/all`).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.GET_ROLES_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_ROLES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
        return errorResponse;
    })
}



export const createNewRole: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_ROLES,
        payload: payload
    });
    return api.post(`/roles/${getState()?.companies?.company?._id}`, payload).then(response => {
        //toggleGenericSuccessModal(dispatch, response.data.response);

        toast.success(response.data?.response?.message || "Role created successfully");
        dispatch({
            type: ActionDispatchTypes.CREATE_ROLES_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        toast.error(errorResponse?.message || "Role is not created, please try again");
        dispatch({
            type: ActionDispatchTypes.CREATE_ROLES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
    })

}

export const updateRole: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.UPDATE_ROLES,
        payload: payload
    });
    return api.put(`/roles/${payload?._id}`, payload).then(response => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.UPDATE_ROLES_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.UPDATE_ROLES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
    })

}

export const deleteRole: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_ROLES,
        payload: payload
    });
    return api.delete(`/roles/${payload?._id}`, payload).then(response => {
        toast.success(response.data?.response?.message || "Role deleted successfully");
        dispatch({
            type: ActionDispatchTypes.DELETE_ROLES_SUCCESSFUL,
            payload: response.data
        }); // Sends the response to the reducer
        return response.data;
    }).catch(error => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toast.error(errorResponse?.message || "Role is deleted, something went wrong");
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_ROLES_FAILED,
            payload: errorResponse
        }); // Sends the error response to the reducer
    })

}