export const HOTKEYS: any = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

export const LIST_TYPES: any = ['numbered-list', 'bulleted-list']
export const TEXT_ALIGN_TYPES: any = ['left', 'center', 'right', 'justify']

export const TEST_CUSTOM_FIELDS = [
    {
        field: 1,
        label: 'İsim Soyisim'
    },
    {
        field: 2,
        label: 'Telefon',
    },
    {
        field: 3,
        label: 'E-posta',
    },
    {
        field: 4,
        label: 'Adres',
    },
    {
        field: 5,
        label: 'Şehir',
    },
    {
        field: 6,
        label: 'Ülke',
    },
    {
        field: 7,
        label: 'Posta Kodu',
    },
    {
        field: 8,
        label: 'Vergi Numarası',
    },
    {
        field: 9,
        label: 'Vergi Dairesi',
    },
    {
        field: 10,
        label: 'Fatura Adresi',
    },
    {
        field: 11,
        label: 'Fatura Şehri',
    },
    {
        field: 12,
        label: 'Fatura Ülkesi',
    },
    {
        field: 13,
        label: 'Fatura Posta Kodu',
    },
    {
        field: 14,
        label: 'Fatura Vergi Numarası',
    },
    {
        field: 15,
        label: 'Fatura Vergi Dairesi',
    },
    {
        field: 16,
        label: 'Teslimat Adresi',
    },
    {
        field: 17,
        label: 'Teslimat Şehri',
    },
    {
        field: 18,
        label: 'Teslimat Ülkesi',
    },
    {
        field: 19,
        label: 'Teslimat Posta Kodu',
    },
    {
        field: 20,
        label: 'Teslimat Vergi Numarası',
    },
    {
        field: 21,
        label: 'Teslimat Vergi Dairesi',
    },
    {
        field: 22,
        label: 'Sipariş Notu',
    },
    {
        field: 23,
        label: 'Sipariş Notu',
    },
    {
        field: 24,
        label: 'Sipariş Notu',
    },
    {
        field: 25,
        label: 'Sipariş Notu',
    },
    {
        field: 26,
        label: 'Sipariş Notu',
    }
]

export const TEST_CLAUSES = [
    {
        id: 1,
        title: 'Amaç',
        content: `Bu Sözleşme, Kişisel Veri Sağlayıcısı tarafından Şirket'e sağlanan veya Şirket tarafından elde edilen Kişisel Veri'nin gizliliğini korumayı amaçlamaktadır.`
    },
    {
        id: 2,
        title: "NDA gizlilik maddesi",
        content: "NDA Maaş Gizliliği Maddesi Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl"
    },
    {
        id: 3,
        title: "Ödeme koşulları",
        content: "Ödeme Koşulları Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget aliquam ultricies, nunc nisl aliquet nunc, eget aliquam nisl"
    }
]

export const HIGHLIGHT_COLORS = [
    "yellow",
    "green",
    "blue",
    "orange",
    "red",
    "pink",
    "purple",
    "gray",
    "black",
    "cyan",
    "darkBlue",
    "darkRed",
    "darkGreen",
    "darkYellow",
    "darkCyan",
    "darkMagenta",
    "white",
];

export const FONT_COLORS = [
    "#000000", // Siyah
    "#FFFFFF", // Beyaz
    "#FF0000", // Kırmızı
    "#00FF00", // Yeşil
    "#0000FF", // Mavi
    "#FFFF00", // Sarı
    "#FFA500", // Turuncu
    "#800080", // Mor
    "#FFC0CB", // Pembe
    "#A52A2A", // Kahverengi
    "#808080", // Gri
    "#FFD700", // Altın
    "#C0C0C0", // Gümüş
    // Diğer renkler HEX kodları ile eklenebilir
];


export const FONT_SIZES = [
    "8pt",
    "10pt",
    "12pt",
    "14pt",
    "16pt",
    "18pt",
    "20pt",
    "22pt",
    "24pt",
    "26pt",
    "28pt",
    "30pt",
    "32pt"
]

export const FONT_FAMILIES = [
    "Arial",
    "Arial Black",
    "Comic Sans MS",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Lucida Sans Unicode",
    "Palatino Linotype",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
    "Roboto",
]