import * as ActionDispatchTypes from "@redux/ActionDispatchTypes/index.types";
import { Reducer } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

const initialState = {
    sendProcessApprovalPopup: {
        show: false,
        loading: false,
        data: {},
    }
};

const ProcessApprovalReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        // ? ----------------- Approve Templates Operations ----------------- @ //

        // ! ------------ Approve Templates LIST ------------



        case ActionDispatchTypes?.TOGGLE_PROCESS_APPROVAL_POPUP:
            return {
                ...state,
                sendProcessApprovalPopup: {
                    ...state.sendProcessApprovalPopup,
                    show: action.payload?.show,
                    data: action.payload?.data
                },
            };

        default:
            return state;
    }
};

export default ProcessApprovalReducer;