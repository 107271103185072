import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { connect } from "react-redux";
import {
  createNewStatus,
  setNewStatusData,
} from "@redux/contract/index.actions";

import FormInput from "@components/FormInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
});

const mapActionsToProps = {
  createNewStatus,
  setNewStatusData,
};

const StatusAdd: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({})
    ),
  });

  const handleActionData = (values: any) => {

    if (!props?.contracts?.newStatus?.title) {
      toast.error(t("CONTRACTS_STATUSES.NO_TITLE"))
      return;
    }

    props.createNewStatus().then((res: any) => {
      if (res === true) {
        props?.modalClose();
      }
    });
  };

  const handleTitle = (title: any) => {
    props.setNewStatusData({ key: "changeData", data: { title: title } });
  };

  const handleDescription = (description: any) => {
    props.setNewStatusData({
      key: "changeData",
      data: { description: description },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleActionData)} className="px-2">
      <Row>
        <Col md={12}>
          <div>
            <Form.Group className="mb-2">
              <Form.Label>{t("CONTRACTS_STATUSES.TITLE_LABEL")}</Form.Label>
              <FormInput
                name="title"
                placeholder={t("CONTRACTS_STATUSES.TITLE_PLACEHOLDER")}
                type="text"
                onChange={(event) => handleTitle(event?.target?.value)}
                register={register}
                key="title"
                errors={errors}
                control={control}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>{t("CONTRACTS_STATUSES.DESCRIPTION_LABEL")}</Form.Label>
              <FormInput
                name="description"
                placeholder={t("CONTRACTS_STATUSES.DESCRIPTION_PLACEHOLDER")}
                type="text"
                onChange={(event) => handleDescription(event?.target?.value)}
                register={register}
                key="description"
                errors={errors}
                control={control}
              />
            </Form.Group>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="text-end mt-3">
            <Button
              variant="secondary"
              className="me-1"
              onClick={props?.modalClose}
            >
              {t("CONTRACTS_STATUSES.CANCEL")}
            </Button>
            <Button variant="success" type="submit">
              {t("CONTRACTS_STATUSES.CREATE")}
            </Button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(StatusAdd);
