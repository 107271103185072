import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import UnknownUser from '@assets/images/users/unknown.jpg';
import { useHistory } from 'react-router-dom';
import apiInstance from '@clients/api.client';
import moment from 'moment';
export const localStorageKeys: {
    token: string,
    Authorization: string,
    refresh_token: string,
    user: string
} = {
    token: "users::token",
    Authorization: "users:Authorization",
    refresh_token: "users::refresh_token",
    user: "users::user"
};


export const loginLocalStorageHandler = (payload: any) => {
    /* Authorization into the localStorage */
    if (payload?.authorization?.Authorization) {
        localStorage.setItem(localStorageKeys?.Authorization, payload?.authorization?.Authorization);
    }
    /* Token into the localStorage */
    if (payload?.authorization?.token) {
        localStorage.setItem(localStorageKeys?.token, payload?.authorization?.token);
    }
    /* refreshToken into the localStorage */
    if (payload?.authorization?.refresh_token) {
        localStorage.setItem(localStorageKeys?.refresh_token, payload?.authorization?.refresh_token);
    }
}

const authUserStr: string | null = localStorage.getItem(localStorageKeys?.user);
const initialState = {
    locale: localStorage.getItem("users::locale"),//"en",
    authorization: {
        token: localStorage.getItem(localStorageKeys?.token) || null, // Authorization token
        Authorization: localStorage.getItem(localStorageKeys?.Authorization) || null, // Authorization type
        refresh_token: localStorage.getItem(localStorageKeys?.refresh_token) || null, // Authorization refresh_token
        user: JSON.parse(authUserStr || "{}"), // User data)
    },
    register: {
        attemptedRegister: false,
        falseRegister: false,
        falseRegisterCounter: 0,
        falseRegisterResponse: null
    },
    login: {
        attemptedLogin: false, // Login attempt started
        falseLogin: false, // Controls if user login is failed before
        falseLoginCounter: 0, // Controls if user login is failed before
    },
    user: null, //Currently logged in user
    currentCompany: null, //Currently logged in company
    roles: null, //Roles of the user
    fetchedUserInvite: false,
    userInvite: null, //User invited by the company
    untilTimer: null,
}


const UsersReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        /* LOGIN STARTED */
        case ActionDispatchTypes.LOGIN:
            return {
                ...state,
                login: {
                    ...state?.login, // Adding nested changes
                    attemptedLogin: true
                }
            };
        /* LOGIN SUCCESSFUL */
        case ActionDispatchTypes.LOGIN_SUCCESSFUL:
            localStorage.removeItem(localStorageKeys?.user);
            loginLocalStorageHandler(action.payload);
            return {
                ...state,
                authorization: {
                    token: action?.payload?.authorization?.token,
                    refresh_token: action?.payload?.authorization?.refresh_token,
                    Authorization: action?.payload?.authorization?.Authorization,
                },
                login: {
                    ...state?.login, // Adding nested changes
                    falseLogin: false,
                    falseLoginCounter: 0,
                    attemptedLogin: false,
                    untilTimer: null
                },
                user: {
                    ...action?.payload?.node?.user,
                    profileImage: action?.payload?.node?.user?.profileImage
                }
            };

        /* LOGIN FAILED */
        case ActionDispatchTypes.LOGIN_FAILED:

            if (action?.payload?.code === 'USER_LOGIN_ATTEMPTS_EXCEEDED') {
                return {
                    ...state,
                    login: {
                        ...state.login, // Adding nested changes
                        attemptedLogin: false,
                        falseLogin: true,
                        falseLoginCounter: 0,
                        untilTimer: moment().add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                    }
                };
            } else {
                return {
                    ...state,
                    login: {
                        ...state.login, // Adding nested changes
                        attemptedLogin: false,
                        falseLogin: true,
                        falseLoginCounter: state.login.falseLoginCounter + 1
                    }
                };
            }



        /* REGISTER STARTED */
        case ActionDispatchTypes.REGISTER:
            return {
                ...state,
                register: {
                    ...state.register, // Adding nested changes
                    attemptedRegister: true
                }
            }
        /* REGISTER SUCCESSFUL */
        case ActionDispatchTypes.REGISTER_SUCCESSFUL:
            loginLocalStorageHandler(action.payload);
            return {
                ...initialState,
                authorization: {
                    token: action?.payload?.authorization?.token,
                    Authorization: action?.payload?.authorization?.Authorization,
                },
                user: {
                    ...action?.payload?.node?.user,
                    profileImage: action?.payload?.node?.user?.profileImage
                }
            }

        /* REGISTER FAILED */
        case ActionDispatchTypes.REGISTER_FAILED:
            return {
                ...state,
                register: {
                    ...state.register, // Adding nested changes
                    attemptedRegister: false,
                    falseRegister: true,
                    falseRegisterCounter: state.register?.falseRegisterCounter + 1,
                    falseRegisterResponse: action?.payload
                }
            }

        /* LOCKING CURRENT SESSION */
        case ActionDispatchTypes.LOCK:
            const authUser = {
                name: state?.user?.name,
                surname: state?.user?.surname,
                email: state?.user?.email,
                profileImage: state?.user?.profileImage,
            }
            localStorage.setItem(localStorageKeys?.user, JSON.stringify(authUser));
            localStorage.removeItem(localStorageKeys?.token);
            localStorage.removeItem(localStorageKeys?.refresh_token);
            localStorage.removeItem(localStorageKeys?.Authorization);
            console.log('localStorageKeys?.token', localStorageKeys?.token)
            console.log('localStorageKeys?.refresh_token', localStorageKeys?.refresh_token)
            console.log('localStorageKeys?.Authorization', localStorageKeys?.Authorization)
            return {
                ...state,
                authorization: {
                    token: null,
                    Authorization: null,
                    user: {
                        name: state?.user?.name,
                        surname: state?.user?.surname,
                        email: state?.user?.email,
                        profileImage: state?.user?.profileImage
                    }
                },
                user: {
                    name: state?.user?.name,
                    surname: state?.user?.surname,
                    email: state?.user?.email,
                    profileImage: state?.user?.profileImage
                }
            }

        /* LOGOUT */
        case ActionDispatchTypes.LOGOUT:
            localStorage.removeItem(localStorageKeys?.token);
            localStorage.removeItem(localStorageKeys?.refresh_token);
            localStorage.removeItem(localStorageKeys?.Authorization);
            localStorage.removeItem(localStorageKeys?.user);
            console.log('localStorageKeys?.token', localStorageKeys?.token)
            console.log('localStorageKeys?.refresh_token', localStorageKeys?.refresh_token)
            console.log('localStorageKeys?.Authorization', localStorageKeys?.Authorization)
            return {
                ...initialState,
                authorization: null
            }
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState,
                authorization: {
                    ...state?.authorization
                }
            }
        case ActionDispatchTypes.GET_CURRENT_USER:
            return {
                ...state
            }
        case ActionDispatchTypes.GET_CURRENT_USER_SUCCESSFUL:
            const authorization = { ...state.authorization };
            return {
                ...state,
                ...authorization,
                user: {
                    ...action?.payload?.node?.user,
                    profileImage: action?.payload?.node?.user?.profileImage,

                },
                currentCompany: action?.payload?.node?.currentCompany,
                roles: action?.payload?.node?.roles,

            }
        case ActionDispatchTypes.GET_CURRENT_USER_FAILED:
            return {
                ...initialState
            }
        case ActionDispatchTypes.UPDATE_USER:
            return {
                ...state
            }
        case ActionDispatchTypes.UPDATE_USER_SUCCESSFUL:
            return {
                ...state,
                user: {
                    ...state?.user,
                    ...action?.payload?.node?.user,
                    profileImage: action?.payload?.node?.user?.profileImage
                }
            }
        case ActionDispatchTypes.DELETE_USER_SUCCESSFUL:
            return {
                ...state
            }
        case ActionDispatchTypes.CHANGE_USERS_LOCALE:
            localStorage.setItem("users::locale", action?.payload?.locale);
            return {
                ...state,
                locale: action?.payload?.locale,
                ...state?.user && {
                    user: {
                        ...state?.user,
                        locale: action?.payload?.locale
                    }
                }
            }
        case ActionDispatchTypes.CHANGE_USERS_LOCALE_SUCCESSFUL:
            localStorage.setItem("users::locale", action?.payload?.node?.user?.locale);
            return {
                ...state,
                locale: action?.payload?.node?.user?.locale,
                user: {
                    ...action?.payload?.node?.user
                }
            }

        /* CREATE COMPANY */
        case ActionDispatchTypes.CREATE_COMPANY_SUCCESSFUL:
            return {
                ...state,
                user: {
                    ...state?.user,
                    ...action?.payload?.node?.user,
                    profileImage: action?.payload?.node?.user?.profileImage
                }
            }
        case ActionDispatchTypes.SET_CURRENT_COMPANY_SUCCESSFUL:
            return {
                ...initialState,
                authorization: {
                    ...state?.authorization
                },
                user: null
            }
        case ActionDispatchTypes.GET_COMPANY_USER_INVITE:
            return {
                ...state,
                fetchedUserInvite: false,
                userInvite: null
            }
        case ActionDispatchTypes.GET_COMPANY_USER_INVITE_SUCCESSFUL:
            localStorage.setItem('userInvite', JSON.stringify(action?.payload?.node?.invite));
            return {
                ...state,
                fetchedUserInvite: true,
                userInvite: action?.payload?.node?.invite
            }
        case ActionDispatchTypes.GET_COMPANY_USER_INVITE_FAILED:
            return {
                ...state,
                fetchedUserInvite: true,
                userInvite: null
            }
        case ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE_SUCCESSFUL:
            return {
                ...state,
                userInvite: null,
                fetchedUserInvite: true
            }
        case ActionDispatchTypes.DECLINE_COMPANY_USER_INVITE_SUCCESSFUL:
            localStorage.removeItem('userInvite')
            return {
                ...state,
                userInvite: null,
                fetchedUserInvite: true
            }
        default:
            return state;
    }
}

export default UsersReducer;