import React, { useEffect, useState } from "react";

import { Card, ListGroup, OverlayTrigger, Tooltip, Button, Form } from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { getListCustomFields } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal } from "@redux/components/index.actions";
import { setNewContractTypeData } from "@redux/contract/index.actions";

import { connect } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts
});
const mapActionsToProps = {
  getListCustomFields,
  toggleGenericAddCustomFieldModal,
  setNewContractTypeData
};

const EditingFieldList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();


  return (
    <div className="d-flex align-items-center justify-content-between">
      <span>
        {props?.item?.title}
      </span>
      <Button
        variant="link"
        className="outline-0 border-0 m-0 p-1">
        <i className="icon">
          <span>

            {props?.item?.switch === true ? (<Form.Group>

              <Form.Check
                key={props?.item?.name + "_switch"}
                type="switch"
                checked={props?.contracts?.newContractType[props?.item?.name]}
                onChange={(event: any) => {
                  console.log(event.target?.checked)
                  props.setNewContractTypeData({
                    key: "setDate",
                    data: {
                      [props?.item?.name]: event.target?.checked,
                    }
                  })
                }}

              />
            </Form.Group>) : (<FeatherIcons icon="lock"></FeatherIcons>)}

          </span>
        </i>
      </Button>

    </div >
  );
};

export default connect(mapStateToProps, mapActionsToProps)(EditingFieldList);
