import { Card, Row, Col } from "react-bootstrap";

import DefaultFieldsListItem from "@pages/contractTypes/components/CustomFieldOffcanvas/DefaultFieldsListItem";
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({});
const mapActionsToProps = {};

const DefaultFieldsList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Body>
        <h6 className="m-0 p-0 mb-2">Default Fields</h6>
        <div className="default-fields-list">
          {([
            {
              _id: 1,
              title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.TITLE")
            },
            { _id: 2, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.STATUS"), switch: false },
            { _id: 3, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.PIPELINE"), switch: false },
            { _id: 4, title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.USER"), switch: false },
            { _id: 5, name: 'showStartDate', title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.START_DATE"), switch: true },
            { _id: 6, name: 'showEndDate', title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.END_DATE"), switch: true },
            { _id: 7, name: 'showEffectiveDate', title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.EFFECTIVE_DATE"), switch: true },
            { _id: 8, name: 'showRenewalDate', title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.RENEWAL_DATE"), switch: true },
            { _id: 9, name: 'showSignedDate', title: t("CONTRACTS_TYPES.DEFAULT_FIELDS.SIGNED_DATE"), switch: true },

          ]).map((item: any, index: number) => (
            <div
              key={index}
              className="default-fields-list-item"
            >
              <DefaultFieldsListItem
                item={item}
              />
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(DefaultFieldsList);
