import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Button, Alert, Navbar, Nav, Card, Tab, ListGroup, FloatingLabel, Offcanvas, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toggleCreateProcessModal, toggleNewProcessModal, toggleEditorModal } from "@redux/components/index.actions";
import { useTranslation } from "react-i18next";
import "./index.scss";
import FeatherIcons from "feather-icons-react";
import { FaFileUpload, FaPlus } from "react-icons/fa";
import { IoChevronBack, IoDocumentText } from "react-icons/io5";
/* import icons/verified.png */
import verified from "./icons/verified.png";
import writing from "./icons/writing.png";
import DocumentIcon from "./components/DocumentIcon";
import EditFileIcon from "./components/EditFileIcon";
import { HiMiniClipboardDocumentList, HiDocumentMagnifyingGlass } from "react-icons/hi2";
import { BsCardList, BsCloudUpload, BsFiles } from "react-icons/bs";
import apiInstance from "@clients/api.client";
import SlateEditor from "@components/SlateEditor";
import Loader from "@components/Loader";
import ContractsSelect from '@components/SelectsPaginated/Contracts';
import { toast } from "react-toastify";
import { useCustomFields } from "@hooks/useCustomFields";
import { useHistory } from "react-router-dom";
import { MdOutlineCloudDone } from "react-icons/md";




const mapStateToProps = (state: any) => ({
  companies: state.companies,
  contracts: state.contracts,
  components: state.components,
  customForms: state.customForms
});
const mapActionsToProps = {
  toggleEditorModal: toggleEditorModal
};

const CreateProcessModal = (props: any) => {
  const { t } = useTranslation();
  const customFields = useCustomFields();
  const history = useHistory();
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSaveAsFileModal, setShowSaveAsFileModal] = useState<boolean>(false);
  const [contract, setContract] = useState<any>(null);
  const [fileTitle, setFileTitle] = useState<string>("");
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [refreshCounter, setRefreshCounter] = useState<number>(0)

  function Icon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 32">
        <path d="M24.8 0H7.2A3.176 3.176 0 004 3.143v25.714A3.176 3.176 0 007.2 32h17.6a3.176 3.176 0 003.2-3.143V3.143A3.176 3.176 0 0024.8 0zM12.254 6.201A3.859 3.859 0 1117.86 10.7a1.815 1.815 0 00-.926 1.557v.1a1 1 0 01-2 0v-.1a3.82 3.82 0 011.926-3.29 1.855 1.855 0 00.88-2.06 1.834 1.834 0 00-1.355-1.355 1.857 1.857 0 00-2.056.871 1.798 1.798 0 00-.166.375 1 1 0 01-1.91-.597zM16.93 15.5a.711.711 0 01-.02.2 1.187 1.187 0 01-.05.18l-.09.18a1.768 1.768 0 01-.13.15 1.015 1.015 0 01-.71.29.837.837 0 01-.38-.08.992.992 0 01-.32-.21 1.768 1.768 0 01-.13-.15l-.09-.18-.06-.18a1.528 1.528 0 01-.02-.2 1.017 1.017 0 01.3-.71 1.034 1.034 0 011.41 0 1.006 1.006 0 01.29.71zM23 28H9a1 1 0 010-2h14a1 1 0 010 2zm0-4H9a1 1 0 010-2h14a1 1 0 010 2zm0-4H9a1 1 0 010-2h14a1 1 0 010 2z"></path>
      </svg>
    );
  }

  useEffect(() => {

    setPageLoading(true)
    apiInstance.get(`editor/fill/template/${props?.components?.editorModal?.data?._id}`).then((res: any) => {
      console.log('RES', res)
      setData(res.data)
      setPageLoading(false)
    }).catch((err: any) => {
      setPageLoading(false)
      console.log('ERR', err)
    });

    return () => {
      console.log('UNMOUNTED')
    }
  }, [refreshCounter])

  const exportData = async () => {
    apiInstance.get(`/editor/export`, {
      params: {
        editor_id: data?.editorDetail?._id,
        version_id: data?.lastVersion?._id
      },
      responseType: 'blob'
    }).then((res: any) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data?.title}.docx`); // Dosya adı.
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }).catch((err) => {
      console.log('err', err)
    })
  }

  const addHeader = () => {
    if (!data?._id) {
      toast.error('Please save the template first')
    }

    apiInstance.post(`/editor/template/header/${data?.editorDetail?._id}`).then((res) => {
      setRefreshCounter(refreshCounter + 1)
    }).catch((err) => {
      console.log('err', err)
    })

  }
  const addFooter = () => {
    if (!data?._id) {
      toast.error('Please save the template first')
    }

    apiInstance.post(`/editor/template/footer/${data?.editorDetail?._id}`).then((res) => {
      setRefreshCounter(refreshCounter + 1)
    }).catch((err) => {
      console.log('err', err)
    })

  }
  return (
    <>



      <Offcanvas show={props?.components?.editorModal?.show}
        onHide={props?.editorModal}

      >
        <Offcanvas.Header className="editorpopup-header">
          <div className="w-100 editorpopup-header-content d-flex align-items-center justify-content-between">
            <div className="editorpopup-header-left d-flex align-items-center justify-content-center gap-2">
              <div className="d-flex align-items-center justify-content-center gap-1">
                <Button
                  size="lg"
                  className="d-flex align-items-center justify-content-center gap-1"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    props?.toggleEditorModal({
                      show: false,
                      data: null
                    })
                    history.push('/contract/templates')

                  }} variant="link">
                  <IoChevronBack /> <span>Templates</span>
                </Button>
                <span>/</span>
                <Button className="d-flex align-items-center justify-content-center" variant="link">
                  {data?.editorDetail?.title}
                </Button>
                <Button className="d-flex align-items-center justify-content-center" variant="link">
                  {loading ? (<span className="spinner-border spinner-border-sm"></span>) : <MdOutlineCloudDone />}

                </Button>
              </div>

            </div>
            <div className="editorpopup-header-center"></div>
            <div className="editorpopup-header-right">

            </div>
          </div>
          <div className="progress"></div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">

          <div className="editorpopup-body">
            <div className="editorpopup-body-left">

            </div>
            <div className="editorpopup-body-right">
              {pageLoading
                ? <div>
                  <Loader />
                </div> : (
                  <SlateEditor
                    autoSave={true}
                    fillForm={false}
                    readOnly={false}
                    toolbar={true}
                    smartFields={[]}
                    customFields={customFields || []}
                    defaultFields={[]}
                    content={data?.lastVersion?.fragment || null}
                    header={data?.lastHeaderVersion?.fragment || null}
                    footer={data?.lastFooterVersion?.fragment || null}
                    setData={setData}
                    data={data}
                    exportDocx={exportData}
                    setEditFormLoading={setLoading}
                    addHeader={addHeader}
                    addFooter={addFooter}
                  />
                )}

            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas >


    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(CreateProcessModal);
