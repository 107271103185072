import React, { useState, useEffect } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import ContractsSelect from '@components/SelectsPaginated/Contracts';
import FilesSelect from '@components/SelectsPaginated/Files';
import api from '@clients/api.client';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import {
    uploadFilesToS3, uploadFilesToGoogleDrive, uploadFilesToCustomCDN, uploadFilesToOneDrive,
    uploadFilesToSharePoint, resetStateOnUploadCompleted, resetStateOnUploadCompletedNoReload
} from '@redux/files/index.actions';
import './index.scss';
import cdnServicesInstance from '@clients/cdnservices.client';
import { Button, Form } from 'react-bootstrap';
import { setFileToGenericPermissionModal } from '@redux/files/index.actions';
import FeatherIcons from 'feather-icons-react';
import Select from 'react-select';

const mapStateToProps = (state: any) => ({
    companies: state.companies,
    cdn: state.files.cdn,
    users: state.users
});

const mapActionsTopProps = {
    uploadFilesToS3: uploadFilesToS3,
    uploadFilesToGoogleDrive: uploadFilesToGoogleDrive,
    uploadFilesToCustomCDN: uploadFilesToCustomCDN,
    setFileToGenericPermissionModal: setFileToGenericPermissionModal,
    uploadFilesToOneDrive: uploadFilesToOneDrive,
    uploadFilesToSharePoint: uploadFilesToSharePoint,
    resetStateOnUploadCompleted: resetStateOnUploadCompleted,
    resetStateOnUploadCompletedNoReload: resetStateOnUploadCompletedNoReload
}

const FilesTable: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();

    const generateFileUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance?.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return file?.fileUrl + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return file?.fileUrl;
        } else if (file?.source?.sourceKey === "one-drive") {
            //return file?.fileUrl;
            return cdnServicesInstance.defaults.baseURL + `/onedrive/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "share-point") {
            //return file?.fileUrl;
            //return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    useEffect(() => {
        toast.info(t("POPUPS.CDN.CONTINUING_UPLOAD"), {
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            isLoading: true
        })

        const promise = new Promise(async (resolve, reject) => {
            if (props?.cdn?.files?.length > 0) {
                for (let index = 0; index < props?.cdn?.exportSources?.length; index++) {
                    const source = props?.cdn?.exportSources[index];
                    if (source === 'aws-s3') {

                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToS3({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                    if (source === "google-drive") {
                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToGoogleDrive({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                    if (source === "custom-cdn") {
                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToCustomCDN({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                    if (source === "one-drive") {
                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToOneDrive({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                    if (source === "share-point") {
                        for (let i = 0; i < props?.cdn?.files?.length; i++) {
                            const file = props?.cdn?.files[i];
                            await props?.uploadFilesToSharePoint({
                                files: [file],
                                importedSource: props?.cdn?.importedSource,
                                index: index,
                                ...props?.defaultContract?._id && {
                                    contract: props?.defaultContract?._id
                                }
                            })
                        }
                    }
                }
            }
        })

        Promise.resolve(promise).then(() => { }).catch((error: any) => { })

        /* props?.cdn?.exportSources?.map(async (source: string, index: number) => {
            if (source === 'aws-s3') {

                for (let i = 0; i < props?.cdn?.files?.length; i++) {
                    const file = props?.cdn?.files[i];
                    await props?.uploadFilesToS3({
                        files: [file],
                        index: index,
                        ...props?.defaultContract?._id && {
                            contract: props?.defaultContract?._id
                        }
                    })
                }
            }
            if (source === "google-drive") {
                for (let i = 0; i < props?.cdn?.files?.length; i++) {
                    const file = props?.cdn?.files[i];
                    await props?.uploadFilesToGoogleDrive({
                        files: [file],
                        index: index,
                        ...props?.defaultContract?._id && {
                            contract: props?.defaultContract?._id
                        }
                    })
                }
            }
            if (source === "custom-cdn") {
                for (let i = 0; i < props?.cdn?.files?.length; i++) {
                    const file = props?.cdn?.files[i];
                    await props?.uploadFilesToCustomCDN({
                        files: [file],
                        index: index,
                        ...props?.defaultContract?._id && {
                            contract: props?.defaultContract?._id
                        }
                    })
                }
            }
        }) */

    }, [])

    useEffect(() => {
        if (props?.cdn?.uploadCompleted) {
            toast.dismiss();
            toast.success(t("POPUPS.CDN.COMPLETED_UPLOAD"), {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
            props?.resetStateOnUploadCompletedNoReload().then(() => {
                props?.nextPage();
                props?.closeModal();
            }).catch(() => { })
        }
    }, [props?.cdn?.uploadCompleted])

    return (
        <div className='cdn-popup-files-table d-none' style={{
            width: '100%',
        }}>

            {((
                props?.cdn?.uploadCompleted ? props?.cdn?.uploadedFiles : []
            )
                || [])
                .map((
                    file: any,
                    index: number
                ) => {
                    return (
                        <div className="upload-file-row">
                            <span>
                                {
                                    (props?.cdn?.uploadCompleted || file?.isUploaded) ? <img src={file?.source?.logo} alt="file" width="35px" height="auto" />
                                        : <div className="spinner-border" role="status">
                                            <span className="visually-hidden">
                                                {t("POPUPS.UPLOAD_FILES.LOADING")}
                                            </span>
                                        </div>
                                }
                            </span>
                            <span className="filename">
                                {(props?.cdn?.uploadCompleted || file?.isUploaded)
                                    ? <a href={generateFileUrl(file)} target="__blank">{file?.name || file?.filename}</a>
                                    : (file?.filename)
                                }


                            </span>

                        </div>
                    )
                })}
        </div>
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(FilesTable);