export const CheckIcon = (props: any) => {
    return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill={props?.color || "none"} xmlns="http://www.w3.org/2000/svg">
            <path d="M3.60847 9.0081C3.76163 9.16127 3.98293 9.26337 4.20427 9.26337C4.4256 9.26337 4.64679 9.17824 4.80007 9.0081L11.7447 2.06347C12.0851 1.72308 12.0851 1.19542 11.7447 0.854904C11.4043 0.514511 10.8767 0.514511 10.5361 0.854904L4.20427 7.18678L1.46386 4.46345C1.12347 4.12306 0.595809 4.12306 0.255295 4.46345C-0.0850983 4.80385 -0.0850983 5.3315 0.255295 5.67202L3.60847 9.0081Z" fill="#79C360" />
        </svg>
    )
}
export const ChangeRequestIcon = (props: any) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill={props?.color || "none"} xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.78476 0C9.07296 0 11.7435 2.64519 11.7842 5.92364C11.8252 9.21452 9.17689 11.9448 5.88628 11.9992C3.1295 12.0448 0.788972 10.2306 0.037635 7.72873C-0.13124 7.16666 0.289377 6.60049 0.876266 6.60049L5.63393 6.59997C5.94593 6.59997 6.10225 6.97727 5.88159 7.19782L4.93196 8.14705C4.72113 8.35799 4.69999 8.68673 4.86876 8.92121L4.93196 8.9956C5.1429 9.20643 5.47175 9.22757 5.70612 9.0589L5.78051 8.9956L8.35193 6.42418C8.56287 6.21324 8.5839 5.8845 8.41523 5.65002L8.35193 5.57563L5.7939 3.0176C5.55901 2.7827 5.17344 2.76743 4.93623 3.00002C4.72142 3.21075 4.69892 3.54233 4.86874 3.77839L4.93194 3.85278L5.88085 4.80126C6.10141 5.02182 5.94519 5.39901 5.63329 5.39911L0.87643 5.39922C0.288726 5.39922 -0.131058 4.83201 0.0381197 4.26919C0.762055 1.86215 2.95695 0.0919664 5.57846 0.003306L5.78476 0Z" fill="#ECBF60" />
        </svg>

    )
}

export const RejectedIcon = (props: any) => {
    return (
        <span style={{
            background: props?.color || '#F0233B',
            padding: '4px',
            borderRadius: '50%',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <svg width={props?.width || 7} height={props?.height || 7} viewBox="0 0 9 9" fill={"none"} xmlns="http://www.w3.org/2000/svg">
                <path d="M8.80714 0.192857C8.55 -0.0642857 8.16429 -0.0642857 7.90714 0.192857L4.5 3.6L1.09286 0.192857C0.835714 -0.0642857 0.45 -0.0642857 0.192857 0.192857C-0.0642857 0.45 -0.0642857 0.835714 0.192857 1.09286L3.6 4.5L0.192857 7.90714C-0.0642857 8.16429 -0.0642857 8.55 0.192857 8.80714C0.321429 8.93571 0.45 9 0.642857 9C0.835714 9 0.964286 8.93571 1.09286 8.80714L4.5 5.4L7.90714 8.80714C8.03572 8.93571 8.22857 9 8.35714 9C8.48571 9 8.67857 8.93571 8.80714 8.80714C9.06429 8.55 9.06429 8.16429 8.80714 7.90714L5.4 4.5L8.80714 1.09286C9.06429 0.835714 9.06429 0.45 8.80714 0.192857Z" fill="white" />
            </svg>
        </span>
    )
}

export const AssignIcon = (props: any) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={props?.color || "none"} xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3829 2.32727C11.812 0.814545 9.83378 0 7.68105 0C5.52832 0 3.49196 0.814545 1.97923 2.32727C1.74651 2.56 1.74651 2.96727 1.97923 3.14182C2.21196 3.31636 2.56105 3.31636 2.79378 3.14182C4.13196 1.80364 5.81923 1.10545 7.62287 1.10545C9.42651 1.10545 11.172 1.86182 12.452 3.14182C15.1283 5.81818 15.1283 10.1818 12.452 12.8582C11.172 14.1964 9.42651 14.8945 7.62287 14.8945C5.81923 14.8945 4.07378 14.1382 2.79378 12.8582C2.56105 12.6255 2.21196 12.6255 1.97923 12.8582C1.74651 13.0909 1.74651 13.44 1.97923 13.6727C3.55014 15.1855 5.52832 16 7.68105 16C9.83378 16 11.8701 15.3018 13.3829 13.7309C14.8956 12.16 15.7101 10.1818 15.7101 8.02909C15.7101 5.87636 14.9538 3.84 13.3829 2.32727Z" fill="#62A0FF" />
            <path d="M2.73455 7.97049C2.73455 7.6214 2.50182 7.38867 2.15273 7.38867H0.581818C0.232727 7.38867 0 7.6214 0 7.97049C0 8.31958 0.232727 8.55231 0.581818 8.55231H2.15273C2.50182 8.55231 2.73455 8.31958 2.73455 7.97049Z" fill="#62A0FF" />
            <path d="M3.72266 7.97075C3.72266 8.31984 3.95538 8.55257 4.30447 8.55257H9.0172L6.34084 11.2289C6.10811 11.4617 6.10811 11.8108 6.34084 12.0435C6.4572 12.1598 6.57357 12.218 6.74811 12.218C6.92266 12.218 7.03902 12.1598 7.15538 12.0435L10.7627 8.43621C10.879 8.31984 10.9372 8.20348 10.9372 8.02893C10.9372 7.85439 10.879 7.73803 10.8208 7.67984L7.15538 4.01439C6.92266 3.78166 6.57357 3.78166 6.34084 4.01439C6.10811 4.24712 6.10811 4.59621 6.34084 4.82893L8.90084 7.38893H4.30447C3.95538 7.38893 3.72266 7.62166 3.72266 7.97075Z" fill="#62A0FF" />
        </svg>

    )
}

export const NotNecessaryIcon = (props: any) => {
    return (
        <svg width="16" height="16" viewBox="0 0 15 15" fill={props?.color || "none"} xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.27273" cy="7.27273" r="7.27273" fill="#C1C1C1" />
            <line x1="3" y1="7" x2="11" y2="7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}
