import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toggleGenericAddCustomFieldModal } from "@redux/components/index.actions";

import CreateCustomField from "@pages/customFields/components/CreateCustomFields";
import { useTranslation } from "react-i18next";

const MapStateToProps = (state: any) => ({
  newCustomFieldModal: state.components.newCustomFieldModal,
});

const MapActionsToProps = {
  toggleGenericAddCustomFieldModal: toggleGenericAddCustomFieldModal,
};

const AddCustomFieldModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation()
  const handleToggle = () => props.toggleGenericAddCustomFieldModal();

  return (
    <>
      <Modal
        show={props?.newCustomFieldModal}
        onHide={handleToggle}
        keyboard={false}
        backdrop={true}
        size={"lg"}
        centered
        contentClassName="shadow"
      >
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title className="text-white">{t("POPUPS.ADD_CUSTOM_FIELD.NEW_CUSTOM_FIELD")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateCustomField />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(AddCustomFieldModal);
