import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toggleApprovalProcessFileModal } from "@redux/components/index.actions";
import {
  addApproveProcessFileUserIntoStep, addApproveProcessFileStep, removeApproveProcessFileRecipient, removeAllSteps, deleteApproveProcessFileStep,
  updateApproveProcessFileStep, getReadApproveProcess, updateApproveProcessFileStepUser, updateApproveTemplate, copyTemplateStepsToProcessFile, startApproving, showApprovalFileProcessModal
} from "@redux/approve/index.actions";

import AddContract from "@pages/contracts/AddContract";
import { useTranslation } from "react-i18next";

import './index.scss'
import FeatherIcons from "feather-icons-react";
import apiInstance from "@clients/api.client";
import { toast } from "react-toastify";
import { FiSave } from "react-icons/fi";
import { VscSaveAs, VscSave } from "react-icons/vsc";
import ApproveTemplatesSelect from "@components/SelectsPaginated/ApproveTemplates";
import Loader from "@components/Loader";
import Lootie from "@components/ReactLottie";
import { RiDeleteBinLine } from "react-icons/ri";

const MapStateToProps = (state: any) => ({
  components: state.components,
  approve: state.approve,
  companies: state.companies,
});

const MapActionsToProps = {
  toggleApprovalProcessFileModal: toggleApprovalProcessFileModal,
  addUserIntoStep: addApproveProcessFileUserIntoStep,
  addStep: addApproveProcessFileStep,
  removeRecipient: removeApproveProcessFileRecipient,
  removeAllSteps: removeAllSteps,
  deleteStep: deleteApproveProcessFileStep,
  updateStep: updateApproveProcessFileStep,
  updateStepUser: updateApproveProcessFileStepUser,
  updateApproveTemplate: updateApproveTemplate,
  copyTemplateStepsToProcessFile: copyTemplateStepsToProcessFile,
  startApproving: startApproving,
  getReadApproveProcess: getReadApproveProcess,
  showApprovalFileProcessModal: showApprovalFileProcessModal
};

const ApprovalProcessFileModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTemplate, setLoadingTemplate] = useState<boolean>(false);


  interface stepUser {
    name: string;
    email: string;
    canChangeRequest: boolean;
    canAssign: boolean;
  }


  const [steps, setSteps] = useState<any>([
    {
      stepTitle: null,
      stepUsers: [],
    }
  ]);

  return (
    <>
      <Modal
        show={props?.components?.approvalProcessFileModalV2}
        onHide={() => props.showApprovalFileProcessModal({ show: false })}
        keyboard={false}
        backdrop={true}
        centered
        size={'xl'}
        contentClassName="shadow">
        <Modal.Header className="bg-secondary">
          <Modal.Title className="text-white"
            style={{
              paddingLeft: 10
            }}
          >{t('APPROVAL_PROCESS_FILE_MODAL.TITLE')} v2</Modal.Title>
          <div className="gap-1 d-flex align-items-center justify-content-end">

            <Button variant="link" className="text-white" onClick={() => props.showApprovalFileProcessModal({ show: false })}>
              <FeatherIcons size={18} icon="x" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">

          <div className="approve-content">
            <div className="approve-create-inner">
              <div className="approve-create-inner-header">
                <div className="approve-create-inner-header-left">
                  <h4>Alıcılar</h4>
                </div>
                <div className="approve-create-inner-header-right">
                  <Button style={{ color: 'blue' }} variant="link" as="a" >Kayıtlı akışlardan seç <FeatherIcons icon="chevron-down"></FeatherIcons></Button>
                </div>
              </div>
              <div className="approve-create-inner-body">

              </div>
              <div className="approve-create-inner-footer"></div>

            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(ApprovalProcessFileModal);
