import React, { useEffect, useState } from "react";
import FlatList from "@components/FlatList";
import { connect } from "react-redux";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { getCompanyUsers } from '@redux/companies/index.actions';
import { addAssignedUserToContract } from '@redux/contract/index.actions';
import {
    getTemplatesForCustomForms
} from "@redux/files/index.actions";
import {
    selectTemplate
} from "@redux/custom-forms/index.actions";
import {
    setTemplateDataInCustomForms,
    createTemplate
} from "@redux/files/index.actions";
import { DefaultLoader } from '@components/Loader';
import _ from "lodash";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const mapStateToProps = (state: any) => ({
    users: state.users,
    companies: state.companies,
    contracts: state.contracts,
    customForms: state.customForms,
    files: state.files
})

const mapActionsToProps = {
    getCompanyUsers, addAssignedUserToContract, getTemplatesForCustomForms, selectTemplate, setTemplateDataInCustomForms,
    createTemplate
}


const ContactsFlatList: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshed, setRefreshed] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [batchSize, setBatchSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [more, setMore] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const loadMore = () => {
        props?.getTemplatesForCustomForms({
            page,
            batchSize,
            more: {
                searchText: searchText
            }
        }).then((res: any) => {
            ((res?.pagination?.page * batchSize) < total) ? setMore(true) : setMore(false)
            if ((res?.pagination?.page * batchSize) < total) {
                setMore(true);
                setPage(res?.pagination?.page + 1)
            } else {
                setMore(false);
            }
            setData([...data, ...res?.node?.templates])

        })

    }

    const handleSearch = (event: any) => {
        setLoading(true)
        setPage(1);
        setSearchText(event?.target?.value)
    }

    const handleAdd = (data: any) => {
        toast.info('Form cevaplarının dokümana işlenebilmesi için özel alanları, formdaki ilgili alanlara kopyalayıp yapıştırmalısınız.')
        props.selectTemplate(data)
        console.log(data)
    }
    const debounceSearch = _.debounce(handleSearch, 600);

    useEffect(() => {
        props?.getTemplatesForCustomForms({
            page,
            batchSize,
            more: {
                searchText: searchText
            }
        }).then((res: any) => {
            if (res?.pagination?.totalCount) setTotal(res?.pagination?.totalCount);
            if ((res?.pagination?.page * batchSize) < res?.pagination?.totalCount) {
                setMore(true);
                setPage(page + 1);
            } else {
                setMore(false)
            }
            setData([...res?.node?.templates])
            setLoading(false);
        })
    }, [searchText])

    useEffect(() => {
        if (props?.customForms?.selectingTemplate === false) {
            setRefreshed(false);
            props?.getTemplatesForCustomForms({
                page,
                batchSize,
                more: {
                    searchText: searchText
                }
            }).then((res: any) => {
                if (res?.pagination?.totalCount) setTotal(res?.pagination?.totalCount);
                if ((res?.pagination?.page * batchSize) < res?.pagination?.totalCount) {
                    setMore(true);
                    setPage(page + 1);
                } else {
                    setMore(false)
                }
                setData([...res?.node?.templates])
                setLoading(false);
                setRefreshed(true);
            })
        }
    }, [props?.customForms?.selectingTemplate])

    return (
        <>
            <input
                className="my-2 mt-1 form-control form-control-sm"
                placeholder={t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.SEARCH_TEMPLATE_PLACEHOLDER')}
                type="text"
                name="searchText"
                defaultValue={searchText}
                onInput={debounceSearch}
            />
            <div className="flat-scroll" id="contractContactsFlatList">
                {
                    (loading) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {
                    (!refreshed) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {refreshed && <FlatList
                    scrollableParentTarget={"contractContactsFlatList"}
                    data={data}
                    renderItem={(item, index) => {
                        return (
                            <div key={item?._id} className="d-flex justify-content-between align-items-center gap-1 flex-wrap p-1">
                                <span className="d-flex justify-content-center align-items-center gap-1">
                                    <span><FeatherIcons icon={'file'}></FeatherIcons></span>
                                    <span
                                        style={{ color: '#2C42FF', cursor: 'pointer', fontWeight: 'bolder' }}
                                        onClick={(e) => props?.setTemplateDataInCustomForms({ ...item, setFields: false })}
                                        className="text-nowrap">{item?.filename}</span>
                                </span>
                                <Button
                                    onClick={event => handleAdd(item)}
                                    className="btn btn-sm btn-link text-danger outline-0 border-0"
                                >
                                    <i className="icon">
                                        <span>
                                            <FeatherIcons icon="plus-square"></FeatherIcons>
                                        </span>
                                    </i>
                                </Button>
                            </div>
                        )
                    }}
                    hasMore={more}
                    endMessage={
                        <div className="d-flex align-items-center justify-content-center no-more">
                            {t("CONTRACTS.OVERVIEW.ASSIGNED_USERS.ALL_DATA_LOADED")}
                        </div>
                    }
                    onEndReached={() => {
                        loadMore()
                    }}
                    onEndReachedThreshold={0.5}
                    keyExtractor={(item, index) => index.toString()}
                />
                }

            </div>
        </>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(ContactsFlatList);
