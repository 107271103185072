import React, { useEffect, useState } from "react";

import { Col, Modal, Row, Button, Form } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import api from '@clients/api.client';
import Avatar from "react-avatar";
import { toggleGoogleFilePermissionModal } from "@redux/components/index.actions";
import { setFileToGenericPermissionModal } from "@redux/files/index.actions";
import { } from "@redux/files/index.actions";
import Select from 'react-select';
import './index.scss';
import { toast } from "react-toastify";
import FeatherIcons from "feather-icons-react";
import { TagsInput } from "react-tag-input-component";
import { trim } from "lodash";
import { DefaultLoader } from "@components/Loader";
import _ from "lodash";
import { getCompanyUsers } from '@redux/companies/index.actions'

const mapStateToProps = (state: any) => ({
  components: state?.components,
  companies: state?.companies,
  files: state?.files
});
const mapActionsToProps = {
  toggleGoogleFilePermissionModal,
  setFileToGenericPermissionModal,
  getCompanyUsers
};

const GoogleFilePermissionModal = (props: any) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>([]);
  const [defaultFilePermission, setDefaultFilePermission] = useState<'reader' | 'writer'>(props?.data?.company?.defaultFilePermissions || 'reader');

  const [file, setFile] = useState<any>(props?.data?.file);

  const defaultFilePermissions: {
    reader: any
    writer: any
    owner: any
  } = {
    reader: {
      title: t('POPUPS.USERS_MANAGEMENT.DEFAULT_FILE_PERMISSIONS.READ'),
      value: 'reader'
    },
    writer: {
      title: t('POPUPS.USERS_MANAGEMENT.DEFAULT_FILE_PERMISSIONS.EDIT'),
      value: 'writer'
    },
    owner: {
      title: t('POPUPS.USERS_MANAGEMENT.DEFAULT_FILE_PERMISSIONS.OWNER'),
      value: 'notAllowed'
    }
  }

  const fakeData: {
    name: string
    email: string
    permission: 'reader' | 'writer' | 'notAllowed'
    image: string
    id: string
  }[] = [
      {
        id: '1',
        name: 'Şahin Ersever',
        email: 'erseversahin@gmail.com',
        permission: 'reader',
        image: 'https://lh3.googleusercontent.com/a/ALm5wu3y1aGPITJo7hZHaqBjclWRP8IEqpoP62kbF98E=s64-c'
      },
      {
        id: '2',
        name: 'Fatma Ersever',
        email: 'fatma@ersever.com',
        permission: 'writer',
        image: 'https://www.gstatic.com/images/branding/product/1x/avatar_circle_blue_512dp.png'
      },
      {
        id: '3',
        name: 'Defne Ersever',
        email: 'defne@ersever.com',
        permission: 'writer',
        image: 'https://www.gstatic.com/images/branding/product/1x/avatar_circle_blue_512dp.png'
      }
    ]

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setLoader(true);
    api.get(`/files/gdrive/permissions/${props?.files?.selectedPermissionModalFile?.file?.key}/${props?.companies?.currentCompany}`).then((res: any) => {
      console.log('GD-Perms', res);

      console.log(res?.data?.node?.permissions?.permissions)
      setData(res?.data?.node?.permissions?.permissions)

      setLoader(false);
    }).catch((err: any) => {
      console.log(err);
      setLoader(false);
    })
  }, [props?.files?.selectedPermissionModalFile?.file?.key]);


  const [sendEmail, setSendEmail] = useState<boolean>(false);


  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [users, setUsers] = useState([])

  useEffect(() => {
    props?.getCompanyUsers().then((res: any) => {
      setUsers(res?.node?.users);
    })
  }, [])

  return (
    <Modal
      show={props?.components?.googleFilePermissionModal}
      size="lg"
      onHide={() => {
        props?.setFileToGenericPermissionModal({})
      }}
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">
          {t('POPUPS.FILE_PERMISSON.DRIVE.TITLE')}
        </h4>
      </Modal.Header>
      <Modal.Body className="">

        <div>
          <h5 className="fw-normal">{t('POPUPS.FILE_PERMISSON.DRIVE.ADD_USER')}:</h5>
          <div className="d-flex gap-1">

            <div
              style={{
                border: '1px solid #e0e0e0',
                borderRadius: 5,
                padding: 5,
                gap: 10,


              }}
              className="d-flex align-items-start w-100 justify-content-between">
              <div className="w-50">
                <Select
                  value={selectedUser || []}
                  isClearable={false}
                  isMulti={false}
                  options={(users.filter((user: any) => {

                    let emails: any = (data || []).map((e: any) => e?.emailAddress);
                    if (emails.includes(user?.email)) return null;
                    else return user;

                  }) || []).map((user: any) => {
                    return {
                      label: user?.email,
                      value: user?._id,
                      email: user?.email
                    }
                  })}
                  onChange={event => setSelectedUser({
                    label: event?.label,
                    value: event?.value,
                    email: event?.email
                  })}
                  className="react-select react-select-container"
                ></Select>

              </div>
              <Form.Group
                className="w-25"
              >
                <Select
                  key={'asdxxx'}
                  isMulti={false}
                  name={'defaultFilePermissions'}
                  value={
                    {
                      label: defaultFilePermissions['reader']?.title,
                      value: defaultFilePermissions['reader']?.value,
                      name: 'defaultFilePermissions'
                    }
                  }
                  options={[
                    {
                      label: defaultFilePermissions['reader'].title,
                      value: defaultFilePermissions['reader'].value,
                      name: 'defaultFilePermissions'
                    },
                    {
                      label: defaultFilePermissions['writer'].title,
                      value: defaultFilePermissions['writer'].value,
                      name: 'defaultFilePermissions'
                    }
                  ]}
                  className="react-select react-select-container"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    setDefaultFilePermission(e?.value)
                  }}
                ></Select>
              </Form.Group>
              <div

                className="w-25 h-100 d-flex align-items-center align-items-center">
                <label style={{
                  border: '1px solid #e0e0e0',
                  borderRadius: 5,
                  padding: '5px',
                  whiteSpace: 'nowrap',
                }} className="form-check-label h-100 w-100 d-flex align-items-center align-items-center gap-1">

                  <input type="checkbox" className="m-0" name="sendEmail" id="" onChange={(e: any) => {
                    console.log(e.target.checked)
                    setSendEmail(e.target.checked)
                  }} checked={sendEmail} />
                  {t('POPUPS.FILE_PERMISSON.DRIVE.NOTIFY_USER')}
                </label>
              </div>

            </div>
            <Button
              style={{
                width: '15%'
              }}

              disabled={!selectedUser || loader}
              onClick={() => {
                if (!validateEmail(selectedUser?.email)) {
                  toast.error(t('POPUPS.FILE_PERMISSON.DRIVE.INVALID_EMAIL'))

                  return;
                }
                if (selectedUser?.value) {
                  setLoader(true);
                  api.post(`/files/gdrive/permissions/${props?.files?.selectedPermissionModalFile?.file?.key}/${props?.companies?.currentCompany}`, {
                    role: defaultFilePermission,
                    sendEmail: sendEmail,
                    email: selectedUser?.email
                  }).then((res: any) => {
                    console.log('GD-Perms', res);
                    if (res?.data?.node?.updatedPermission?.emailAddress) {
                      setData([res?.data?.node?.updatedPermission, ...data])
                      setSelectedUser(null)
                      setSendEmail(false)
                      setDefaultFilePermission('reader')
                    }
                    setLoader(false);
                  }).catch((err: any) => {
                    toast.error(err?.response?.data?.response?.message || 'Bir hata oluştu');
                    setLoader(false);
                  })
                }
              }}
              variant="primary">
              <span className="fw-light">{t('POPUPS.FILE_PERMISSON.DRIVE.ADD')}</span>
            </Button>
          </div>



          {
            loader ? (
              <div style={{ minHeight: '300px' }} className="d-flex align-items-center justify-content-center">
                <DefaultLoader />
              </div>
            ) : (
              <>
                <h5 className="fw-normal">{t('POPUPS.FILE_PERMISSON.DRIVE.PEOPLE_WITH_ACCESS')}:</h5>
                <ul className="list-group w-100">
                  {(data || []).map((item: {
                    displayName: string
                    type: string
                    domain: string
                    emailAddress: string
                    role: 'reader' | 'writer' | 'owner'
                    photoLink: string
                    id: string
                  }, index: number) => (

                    ((item: {
                      displayName: string
                      type: string
                      domain: string
                      emailAddress: string
                      role: 'reader' | 'writer' | 'owner'
                      photoLink: string
                      id: string
                    }) => {
                      console.log('item', item)
                      if (item?.role === 'owner') {
                        return (
                          <li className="w-100 list-group-item d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center justify-content-start gap-1">
                              <img src={item?.photoLink} alt="" className="rounded-circle" width="30" height="30" />
                              <div className="d-flex flex-column align-items-start">
                                <span className="ml-2">{item?.displayName} ({item?.type})</span>
                                <span className="fw-light">{item?.emailAddress}</span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">

                              <span className="badge bg-secondary">
                                {item?.role}
                              </span>

                            </div>

                          </li>
                        )
                      } else {
                        return (
                          <li className="w-100 list-group-item d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center justify-content-start gap-1">
                              {item?.photoLink ? (
                                <img src={item?.photoLink} alt="" className="rounded-circle" width="30" height="30" />
                              ) : (
                                <Avatar name={item?.displayName} color="#050027" style={{
                                  fontSize: '3rem'
                                }} size="30px" className="rounded-circle" />
                              )}



                              <div className="d-flex flex-column align-items-start">
                                <span className="ml-2">{item?.displayName} ({item?.type})
                                </span>
                                <span className="fw-light">{item?.emailAddress || item?.domain}</span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-end">
                              <Select
                                key={item?.id}
                                isMulti={false}
                                name={'defaultFilePermissions'}
                                value={
                                  {
                                    label: defaultFilePermissions[item?.role]?.title,
                                    value: defaultFilePermissions[item?.role]?.value,
                                    name: 'defaultFilePermissions'
                                  }
                                }
                                options={[
                                  {
                                    label: defaultFilePermissions['reader'].title,
                                    value: defaultFilePermissions['reader'].value,
                                    name: 'defaultFilePermissions'
                                  },
                                  {
                                    label: defaultFilePermissions['writer'].title,
                                    value: defaultFilePermissions['writer'].value,
                                    name: 'defaultFilePermissions'
                                  }
                                ]}
                                className="react-select react-select-container w-100"
                                classNamePrefix="react-select"

                                onChange={(e) => {
                                  toast.loading('Please wait...');
                                  api.put(`/files/gdrive/permissions/${props?.files?.selectedPermissionModalFile?.file?.key}/${props?.companies?.currentCompany}`, {
                                    permissionId: item?.id,
                                    role: e?.value,
                                    type: item?.type
                                  }).then((res: any) => {
                                    console.log('GD-Perms', res);
                                    toast.dismiss();
                                    const responseData = res?.data?.node?.updatedPermission;

                                    console.log(_.find(data, { id: responseData.id }).role = responseData.role)
                                    setData([...data])
                                    toast.success(res.data.response?.message || 'Transaction completed successfully');

                                  }).catch((err: any) => {
                                    console.log(err);
                                  })
                                }}
                              ></Select>
                              <Button className="px-1"
                                onClick={(e) => {
                                  toast.loading('Please wait...');
                                  api.delete(`/files/gdrive/permissions/${props?.files?.selectedPermissionModalFile?.file?.key}/${props?.companies?.currentCompany}`, {
                                    data: {
                                      permissionId: item?.id
                                    }
                                  }).then((res: any) => {
                                    const responseData = res?.data?.node;
                                    toast.dismiss();

                                    if (responseData?.permissionId) {
                                      setData(_.filter(data, function (d) {
                                        return d.id !== responseData?.permissionId
                                      }))
                                      toast.success(res.data.response?.message || 'Transaction completed successfully');
                                    }


                                    console.log('deleted', res)

                                  }).catch((err: any) => {
                                    console.log(err);
                                  })
                                }}
                                variant="link">
                                <FeatherIcons size={12} icon="x" />
                              </Button>

                            </div>

                          </li>
                        )
                      }
                    })(item)

                  )
                  )}
                </ul>
              </>

            )
          }



        </div>



      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(GoogleFilePermissionModal);
