import React, { useEffect, useState } from "react";

import { Button, Form, FormGroup, Modal } from "react-bootstrap";

import { connect } from "react-redux";

// components
import StatusEdit from "@pages/statuses/StatusEdit";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apiInstance from "@clients/api.client";
import Loader from "@components/Loader";

const mapStateToProps = (state: any) => ({});
const mapActionsToProps = {};

const ApproveProcessModal = (props: any) => {
  const { t } = useTranslation();
  const submitHandle = (e: any) => {
    e.preventDefault();
    //props?.setLoading(true)
    // Get form data
    const formData = new FormData(e.target);
    const data: any = Object.fromEntries(formData.entries());
    console.log(data);

    if (!data?.email) return toast.warning('Lütfen alıcı e-posta adresini giriniz.');
    // Validate email
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(data?.email)) return toast.warning('Lütfen geçerli bir e-posta adresi giriniz.');

    if (!data?.message) return toast.warning('Lütfen mesaj metni giriniz.');
    if (data?.message.length < 10) return toast.warning('Mesaj metni en az 10 karakter olmalıdır.');

    apiInstance.post(`/approve-files/external/re-assign`, {
      ...data,
      token: props?.token
    })
      .then((res: any) => {
        console.log('res', res)
        props?.getReadApproveProcess({
          id: props?.process?._id
        })
        props?.toggle();
      })
      .catch((error: any) => {
        const errorMessage = error?.response?.data?.response?.message;
        toast.error(errorMessage || 'Onay verilecek bir dosya bulunamadı. Detaylı bilgi için onay sürecini kontrol ediniz.');
        props?.getReadApproveProcess({
          id: props?.process?._id
        })
        props.toggle();
      })
      .finally(() => {
        props?.setLoading(false);
      })


  };
  return (
    <Modal
      show={props?.show}
      onHide={props?.toggle}
      size="lg"
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REASSIGN_MODAL.TITLE')}</h4>
      </Modal.Header>
      <Modal.Body>
        <p>{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REASSIGN_MODAL.DESCRIPTION')}</p>

        <Form onSubmit={submitHandle}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <FormGroup className="mb-3">
              <Form.Label>{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REASSIGN_MODAL.RECEIVER_INPUT_LABEL')}</Form.Label>
              <Form.Control
                name="email"
                placeholder={t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REASSIGN_MODAL.RECEIVER_INPUT_PLACEHOLDER')}
                type="email" />
            </FormGroup>
            <FormGroup className="mb-3">
              <Form.Label>{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REASSIGN_MODAL.MESSAGE_INPUT_LABEL')}</Form.Label>
              <Form.Control
                name="message"
                placeholder={t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REASSIGN_MODAL.MESSAGE_INPUT_PLACEHOLDER')}
                as="textarea" rows={3} />
            </FormGroup>
          </Form.Group>


          <Button disabled={props?.loading} variant="primary" type="submit">
            {props?.loading && <Loader />} {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REASSIGN_MODAL.SEND')}
          </Button>
        </Form>


      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ApproveProcessModal);
