import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import UnknownUser from '@assets/images/users/unknown.jpg';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

export const localStorageKeys: {
} = {

};

const initialState = {
    integrations: [],
    emailIntegrations: [],
    fetchinIntegrations: false,
    fetchinEmailIntegrations: false,
    endedFetchingIntegrations: false,
    uploadingFiles: false,
    uploadedFiles: [],
}


const CompaniesIntegrationsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.GET_COMPANY_INTEGRATIONS:
            return {
                ...state,
                fetchinIntegrations: true
            }
        case ActionDispatchTypes.GET_COMPANY_INTEGRATIONS_SUCCESSFUL:
            return {
                ...state,
                fetchinIntegrations: false,
                integrations: _.unionWith(state.integrations, action.payload?.node?.integrations, _.isEqual)
            }
        case ActionDispatchTypes.GET_COMPANY_INTEGRATIONS_FAILED:
            return {
                ...state,
                fetchinIntegrations: false,
            }
        case ActionDispatchTypes.CREATE_DRIVE_INTEGRATION_FOR_COMPANY:
            return {
                ...state
            }
        case ActionDispatchTypes.CREATE_DRIVE_INTEGRATION_FOR_COMPANY_SUCCESSFUL:
            return {
                ...state
            }
        case ActionDispatchTypes.DELETE_INTEGRATION_FOR_COMPANY_SUCCESSFUL:
            return {
                ...state,
                integrations: state.integrations.filter((integration: any) => integration?.integrationType !== action.payload?.node?.companiesIntegrations?.integrationType)
            }


        // Email Integrations
        case ActionDispatchTypes.GET_EMAIL_INTEGRATIONS:
            return {
                ...state,
                fetchinEmailIntegrations: true
            }
        case ActionDispatchTypes.GET_EMAIL_INTEGRATIONS_SUCCESSFUL:
            return {
                ...state,
                fetchinEmailIntegrations: false,
                emailIntegrations: _.unionWith(state.emailIntegrations, action.payload?.node?.integrations, _.isEqual)
            }
        case ActionDispatchTypes.GET_EMAIL_INTEGRATIONS_FAILED:
            return {
                ...state,
                fetchinEmailIntegrations: false,
            }
        // Email Integrations
        case ActionDispatchTypes.UPLOAD_GMAIL_ATTACHMENT:
            return {
                ...state,
                uploadingFiles: true,
                uploadedFiles: []
            }
        case ActionDispatchTypes.UPLOAD_GMAIL_ATTACHMENT_SUCCESSFUL:
            return {
                ...state,
                uploadingFiles: false,
                uploadedFiles: action.payload?.node?.files
            }
        case ActionDispatchTypes.UPLOAD_GMAIL_ATTACHMENT_FAILED:
            return {
                ...state,
                uploadingFiles: false,
                uploadedFiles: []
            }


        default:
            return state;
    }
}

export default CompaniesIntegrationsReducer;