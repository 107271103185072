import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toggleNewAutomationModal } from "@redux/components/index.actions";
import { } from '@redux/files/index.actions';
import logoSm from '@assets/images/company-identity/logo-square.jpeg';

import { useTranslation } from "react-i18next";
import FeatherIcons from "feather-icons-react";
import { Button, Dropdown } from "react-bootstrap";
import Select from "react-select";
import apiInstance from "@clients/api.client";
import AutomationCustomFieldArea, { TCustomComponent } from "@components/AutomationCustomFieldArea";
import AssignedUsersFlatList from "./AssignedUsersFlatList";
import Avatar from "react-avatar";
import './AssignUsers.scss';

const MapStateToProps = (state: any) => ({
    components: state.components,
    contracts: state.contracts,
    companies: state.companies,
    cdn: state.files.cdn,
    uploadedFiles: state.files.newProcessUploadedFiles
});

const MapActionsToProps = {
    toggleNewAutomationModal: toggleNewAutomationModal
};


const CustomFields: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();

    const [customFields, setCustomFields] = useState<any>([]);
    const [selectedCustomField, setSelectedCustomField] = useState<any>(undefined);

    const [assignedUsers, setAssignedUsers] = useState<any>([]);

    useEffect(() => {
        apiInstance.get(`/custom-fields/${props?.companies?.company?._id}`).then((res: any) => {
            setCustomFields(res?.data?.node?.contractsCustomFields || [])
        })
    }, [])

    const [actionDetails, setActionDetails] = useState<any>({
        selectedValue: [],
    });

    useEffect(() => {
        props?.setActionDetails(actionDetails)
    }, [actionDetails])

    return (
        <>
            <div className="assign-users">
                <div className="assign-users-user-list">

                    <Dropdown as={'div'} autoClose={'outside'}>
                        <Dropdown.Toggle className="assign-users-user-add">
                            <FeatherIcons icon="user" size={50} />
                            <span className="plus-icon">
                                <FeatherIcons icon="plus" size={10} />
                            </span>

                        </Dropdown.Toggle>

                        <Dropdown.Menu align={'start'} as={'div'} style={{ width: '300px', marginTop: '5px', padding: '10px' }}>

                            <AssignedUsersFlatList
                                onUserAdd={(data: any) => {
                                    setActionDetails({
                                        ...actionDetails,
                                        selectedValue: [...actionDetails?.selectedValue, {
                                            _id: data?._id,
                                            name: data?.name,
                                            surname: data?.surname,
                                            email: data?.email
                                        }]
                                    })
                                }}
                                onUserRemove={(data: any) => {
                                    setActionDetails({
                                        ...actionDetails,
                                        selectedValue: actionDetails?.selectedValue.filter((item: any) => item?._id !== data?._id)
                                    })
                                }}
                                assignedUsers={actionDetails?.selectedValue || []}
                            />

                        </Dropdown.Menu>
                    </Dropdown>
                    {(actionDetails?.selectedValue || []).map((user: any, index: Number) => {
                        return (
                            <>

                                <div

                                    className="assign-users-user" key={'user-' + index}>
                                    <div className="assign-users-user-item" style={{}}>
                                        <Avatar style={{

                                        }} name={`${user.name} ${user.surname}`} size="40" round={true} />
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    );
};

export default connect(MapStateToProps, MapActionsToProps)(CustomFields);
