import * as ActionDispatchTypes from "@redux/ActionDispatchTypes/index.types";
import { Reducer } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

const initialState = {
    approveShowButtons: false,
    creatingApproveTemplate: false,
    selectedApproveTemplate: {},
    saving: false,
    startApproving: false,
    creatingApproveProcess: false,
    selectedApproveProcess: {},
    selectedApproveProcessFile: {},
    tables: {
        refreshApproveTemplatesTable: false,
        approveTemplatesTable: new Map(),
        approveTemplatesPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0,
        },
        refreshApproveProcessTable: false,
        approveProcessTable: new Map(),
        approveProcessPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0,
        },
    },
    internalApprovePopup: {
        show: false,
        data: {},
    },
};

const ContractsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        // ? ----------------- Approve Templates Operations ----------------- @ //

        /*
            ! ------------ Approve Templates LIST ------------
            */

        case ActionDispatchTypes.GET_APPROVE_TEMPLATES_LIST_SUCCESSFUL:
            state?.tables?.approveTemplatesTable?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.data.map((item: any, index: number) => {
                    return {
                        index:
                            (action?.payload?.pagination?.page - 1) *
                            action?.payload?.pagination?.batchSize +
                            index +
                            1,
                        ...item,
                        createdAt: moment(item?.createdAt).format("LLLL"),
                    };
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...(state?.tables?.refreshApproveTemplatesTable && {
                        refreshApproveTemplatesTable: false,
                    }),
                    approveTemplatesPagination: {
                        ...state?.tables?.approveTemplatesPagination,
                        ...action?.payload?.pagination,
                    },
                },
            };

        /*
            ! ------------ Create Approve Template ------------
            */

        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE:
            return {
                ...state,
                creatingApproveTemplate: true,
                selectedApproveTemplate: {},
            };

        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                creatingApproveTemplate: false,
                selectedApproveTemplate: action.payload,
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
            };

        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_FAILED:
            return {
                ...state,
                creatingApproveTemplate: false,
                selectedApproveTemplate: {},
            };

        /*
            ! ------------ Update Approve Template ------------
            */

        case ActionDispatchTypes.EDIT_APPROVE_TEMPLATE:
            return {
                ...state,
                creatingApproveTemplate: true,
                selectedApproveTemplate: {},
            };

        case ActionDispatchTypes.EDIT_APPROVE_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                creatingApproveTemplate: false,
                selectedApproveTemplate: action.payload,
            };

        case ActionDispatchTypes.EDIT_APPROVE_TEMPLATE_FAILED:
            return {
                ...state,
                creatingApproveTemplate: false,
                selectedApproveTemplate: {},
            };

        /*
            ! ------------ Create Approve Template Step ------------
            */

        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEP:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEP_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                selectedApproveTemplate: {
                    ...state.selectedApproveTemplate,
                    steps: [
                        ...state.selectedApproveTemplate.steps,
                        action?.payload?.step,
                    ],
                },
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
            };

        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEP_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
            ! ------------ Delete Approve Template ------------
            */

        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE:
            return {
                ...state,
            };

        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
            };

        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_FAILED:
            return {
                ...state,
            };

        /*
            ! ------------ Delete Approve Template Step User ------------
            */

        case ActionDispatchTypes.SET_APPROVE_SHOW_BUTTONS:
            return {
                ...state,
                approveShowButtons: action.payload || false,
            };
        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_USER:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_USER_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
            };

        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_USER_FAILED:
            return {
                ...state,
            };

        /*
            ! ------------ Update Approve Template Step ------------
            */

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
                selectedApproveTemplate: {
                    ...state.selectedApproveTemplate,
                    steps: state.selectedApproveTemplate.steps.map((item: any) => {
                        if (item._id === action?.payload?.stepId) {
                            return {
                                ...item,
                                [action?.payload?.fieldName]: action?.payload?.fieldValue,
                            };
                        }
                        return item;
                    }),
                },
            };

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
            ! ------------ Update Approve Template Step User ------------
            */

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_USER:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_USER_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
                selectedApproveTemplate: {
                    ...state.selectedApproveTemplate,
                    steps: state.selectedApproveTemplate.steps.map((item: any) => {
                        if (item._id === action?.payload?.stepId) {
                            const userIndex = item?.stepUsers.findIndex(
                                (user: any) => user._id === action?.payload?.stepUserId
                            );
                            if (userIndex > -1) {
                                item.stepUsers[userIndex] = {
                                    ...item.stepUsers[userIndex],
                                    [action?.payload?.fieldName]: action?.payload?.fieldValue,
                                };
                            }
                        }
                        return item;
                    }),
                },
            };

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_STEP_USER_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
            ! ------------ Update Approve Template ------------
            */

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
                selectedApproveTemplate: {
                    ...state.selectedApproveTemplate,
                    [action?.payload?.fieldName]: action?.payload?.fieldValue,
                },
            };

        case ActionDispatchTypes.UPDATE_APPROVE_TEMPLATE_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
            ! ------------ Delete Approve Template ------------
            */

        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
            };

        case ActionDispatchTypes.DELETE_APPROVE_TEMPLATE_STEP_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
            ! ------------ Create Approve Template Step User ------------
            */

        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEPUSER:
            return {
                ...state,
                saving: true,
            };
        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEPUSER_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                selectedApproveTemplate: {
                    ...state.selectedApproveTemplate,
                    steps: [...action?.payload?.steps],
                },
                tables: {
                    ...state.tables,
                    refreshApproveTemplatesTable: true,
                },
            };
        case ActionDispatchTypes.CREATE_APPROVE_TEMPLATE_STEPUSER_FAILED:
            return {
                ...state,
                saving: false,
            };

        // ? ----------------- Approve Process Operations ----------------- @ //

        /*
            ! ------------ Create Approve Process ------------ !
            */

        case ActionDispatchTypes.EDIT_APPROVE_PROCESS:
            return {
                ...state,

            };

        case ActionDispatchTypes.EDIT_APPROVE_PROCESS_SUCCESSFUL:
            return {
                ...state,
                selectedApproveProcess: {
                    ...state.selectedApproveProcess,
                    ...action.payload.editedData,
                },
            };


        case ActionDispatchTypes.EDIT_APPROVE_PROCESS_FAILED:
            return {
                ...state,
            };

        case ActionDispatchTypes.CREATE_APPROVE_PROCESS:
            return {
                ...state,
                creatingApproveProcess: true,
                selectedApproveProcess: {},
                selectedApproveProcessFile: {},
            };

        case ActionDispatchTypes.CREATE_APPROVE_PROCESS_SUCCESSFUL:
            return {
                ...state,
                creatingApproveProcess: false,
                selectedApproveProcess: action.payload.created,
                selectedApproveProcessFile: action.payload.approvedFile,
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
            };


        case ActionDispatchTypes.CREATE_APPROVE_PROCESS_FAILED:
            return {
                ...state,
                creatingApproveProcess: false,
                selectedApproveProcess: {},
                selectedApproveProcessFile: {},
            };

        case ActionDispatchTypes.SET_APPROVE_PROCESS_FILE:
            return {
                ...state,
                selectedApproveProcess: action.payload.process,
                selectedApproveProcessFile: action.payload.file
            };


        // ? ----------------- Approve Process File Operations ----------------- @ //

        /*
           ! ------------ Create Approve Template Step ------------
           */

        case ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEP:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                selectedApproveProcessFile: {
                    ...state.selectedApproveProcessFile,
                    steps: [
                        ...state.selectedApproveProcessFile.steps,
                        action?.payload?.step,
                    ],
                },
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
            };
        case ActionDispatchTypes.REFRESH_APPROVE_PROCESS_LIST:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
            };


        case ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEP_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
                 ! ------------ Delete Approve Template Step User ------------
                 */

        case ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_USER:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_USER_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
            };

        case ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_USER_FAILED:
            return {
                ...state,
            };

        /*
                 ! ------------ Delete Approve Template Step User ------------
                 */

        case ActionDispatchTypes.DELETE_ALL_APPROVE_PROCESS_FILE_STEPS:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.DELETE_ALL_APPROVE_PROCESS_FILE_STEPS_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                selectedApproveProcessFile: {
                    ...state.selectedApproveProcessFile,
                    steps: action?.payload?.steps,
                },
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
            };

        case ActionDispatchTypes.DELETE_ALL_APPROVE_PROCESS_FILE_STEPS_FAILED:
            return {
                ...state,
            };

        /*
            ! ------------ Update Approve Template Step ------------
            */

        case ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
                selectedApproveProcessFile: {
                    ...state.selectedApproveProcessFile,
                    steps: state.selectedApproveProcessFile.steps.map((item: any) => {
                        if (item._id === action?.payload?.stepId) {
                            return {
                                ...item,
                                [action?.payload?.fieldName]: action?.payload?.fieldValue,
                            };
                        }
                        return item;
                    }),
                },
            };

        case ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
            ! ------------ Update Approve Template Step User ------------
            */

        case ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_USER:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_USER_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
                selectedApproveProcessFile: {
                    ...state.selectedApproveProcessFile,
                    steps: state.selectedApproveProcessFile.steps.map((item: any) => {
                        if (item._id === action?.payload?.stepId) {
                            const userIndex = item?.stepUsers.findIndex(
                                (user: any) => user._id === action?.payload?.stepUserId
                            );
                            if (userIndex > -1) {
                                item.stepUsers[userIndex] = {
                                    ...item.stepUsers[userIndex],
                                    [action?.payload?.fieldName]: action?.payload?.fieldValue,
                                };
                            }
                        }
                        return item;
                    }),
                },
            };

        case ActionDispatchTypes.UPDATE_APPROVE_PROCESS_FILE_STEP_USER_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
                  ! ------------ Create Approve Template Step User ------------
                  */

        case ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEPUSER:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEPUSER_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                selectedApproveProcessFile: {
                    ...state.selectedApproveProcessFile,
                    steps: [...action?.payload?.steps],
                },
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
            };

        case ActionDispatchTypes.CREATE_APPROVE_PROCESS_FILE_STEPUSER_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
            ! ------------ Delete Approve Template ------------
            */

        case ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
            };

        case ActionDispatchTypes.DELETE_APPROVE_PROCESS_FILE_STEP_FAILED:
            return {
                ...state,
                saving: false,
            };

        /*
            ! ------------ Copy Template Steps To Approve Process File ------------
            */

        case ActionDispatchTypes.COPY_TEMPLATE_STEPS_TO_PROCESS_FILE:
            return {
                ...state,
                saving: true,
            };

        case ActionDispatchTypes.COPY_TEMPLATE_STEPS_TO_PROCESS_FILE_SUCCESSFUL:
            return {
                ...state,
                saving: false,
                selectedApproveProcessFile: {
                    ...state.selectedApproveProcessFile,
                    steps: [...action?.payload?.steps],
                },
                tables: {
                    ...state.tables,
                    refreshApproveProcessTable: true,
                },
            };

        /*
            ! ------------ Start Approving To Approve Process File ------------
            */

        case ActionDispatchTypes.START_APPROVING:
            return {
                ...state,
                startApproving: true,
            };

        case ActionDispatchTypes.START_APPROVING_SUCCESSFUL:
            return {
                ...state,
                startApproving: false,
            };

        case ActionDispatchTypes.START_APPROVING_FAILED:
            return {
                ...state,
                startApproving: false,
            };

        /*
       ! ------------ Approve Process LIST ------------
       */

        case ActionDispatchTypes.GET_APPROVE_PROCESS_LIST_SUCCESSFUL:
            state?.tables?.approveProcessTable?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.data.map((item: any, index: number) => {
                    return {
                        index:
                            (action?.payload?.pagination?.page - 1) *
                            action?.payload?.pagination?.batchSize +
                            index +
                            1,
                        ...item,
                        //createdAt: moment(item?.createdAt).format('LLLL')
                    };
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...(state?.tables?.refreshApproveProcessTable && {
                        refreshApproveProcessTable: false,
                    }),
                    approveProcessPagination: {
                        ...state?.tables?.approveProcessPagination,
                        ...action?.payload?.pagination,
                    },
                },
            };

        /*
        ! ------------ Internal Approve ------------
        */

        case ActionDispatchTypes?.TOGGLE_INTERNAL_APPROVAL_POPUP:
            return {
                ...state,
                internalApprovePopup: { ...action?.payload },
            };
        /*
        ! ------------ Get Read Single Internal Approve ------------
        */

        case ActionDispatchTypes.GET_APPROVE_PROCESS_DETAIL:
            return {
                ...state,
                internalApprovePopup: { ...action?.payload },
            };

        case ActionDispatchTypes.GET_APPROVE_PROCESS_DETAIL_SUCCESSFUL:
            return {
                ...state,
                internalApprovePopup: {
                    show: true,
                    loading: false,
                    data: {
                        ...action?.payload
                    }
                },

            };

        case ActionDispatchTypes.GET_APPROVE_PROCESS_DETAIL_FAILED:
            return {
                ...state,
                internalApprovePopup: {
                    show: false,
                    loading: false,
                    data: {}
                }
            };

        /*
        ! ------------ Default ------------
        */

        default:
            return state;
    }
};

export default ContractsReducer;
