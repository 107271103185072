import React, { useEffect, useState } from 'react';
import FlatListSelect from '@components/FlatListSelect';
import api from '@clients/api.client';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

import { toggleGenericAddContractCompaniesModal } from "@redux/components/index.actions"
import { getContractCompanies } from "@redux/contract/index.actions"
import { Button } from 'react-bootstrap';
import FeatherIcons from 'feather-icons-react';
const mapStateToProps = (state: any) => ({
    companies: state.companies,
    users: state.users,
    contracts: state?.contracts
})
const randomIdGenerator = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
const mapActionsTopProps = {
    getContractCompanies

};


const AddNewPipeline = (props: any) => {
    const dispatch = useDispatch();



    const handleClick = () => {
        console.log('click', props)
        dispatch(toggleGenericAddContractCompaniesModal({
            partiesSide: 'external',
        }));
    }
    return (
        <components.MenuList {...props}>
            <div className='d-flex align-items-center justify-content-center p-2'>
                <Button
                    variant='outline-primary'
                    onClick={handleClick}
                    className="p-2 pointer"
                    style={{ width: '80%' }}
                >
                    <i className="icon">
                        <span>
                            <FeatherIcons icon="plus-square"></FeatherIcons>
                        </span>

                    </i> Add New
                </Button>
            </div>
            {props.children}
        </components.MenuList>
    );
};

const CompaniesSelect: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const batchSize = 30;
    const [firstInitial, setFirstInitial] = useState<boolean>(true);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [value, setValue] = useState(props?.values || []);
    const [data, setData] = useState<{ label: string, value: string }[]>([]);

    const dispatch = useDispatch();

    const fetchContracts = async () => {
        console.log(searchText)
        return
    }
    useEffect(() => {
        setValue(props?.values);
    }, [props?.values])

    const [refreshed, setRefreshed] = useState<boolean>(true);
    useEffect(() => {
        if (props?.contracts?.tables?.refreshContractCompaniesTable === true) {
            setRefreshed(false);
            setTimeout(() => {
                setRefreshed(true);
            }, 350)
        }
    }, [props?.contracts?.tables?.refreshContractCompaniesTable])

    useEffect(() => {
        setTimeout(() => {
            setFirstInitial(false);
        }, 1000)
    }, [])


    return (
        <>
            {refreshed && (
                <FlatListSelect
                    components={{ MenuList: AddNewPipeline }}
                    partiesSide={props?.partiesSide}
                    isMulti={true}
                    data={data}
                    value={value}
                    cacheUniqs={[]}
                    onChange={(currentItem: any) => {
                        console.log('currentItem', currentItem)
                        if (!firstInitial) {
                            props?.onValueSubmit(currentItem);
                            //props?.handleSaveButtonToggle(true);
                        }
                        setFirstInitial(false);
                        setValue(currentItem);
                    }}
                    placeholder={t("SELECTS.CONTRACTS.SEARCH_COMPANY")}
                    defaultOption={props?.defaultOption?.value ? props?.defaultOption : null}
                    className="select-contracts-companies"
                    onEndReached={async (search: string, additional: any) => {
                        if (searchText !== search) {
                            console.log('search', search)
                            console.log('searchText', searchText)
                            if (!(search === "" || search === null || search === undefined)) {
                                console.warn("SEARCH CHANGING");
                                setSearchText(search)
                                setPage(1);
                            }
                        } else {
                            setPage(page + 1);
                        }
                        const response = await props?.getContractCompanies({
                            batchSize: batchSize,
                            page: page,
                            more: {
                                searchText: search,
                                partiesSide: props?.partiesSide
                            }
                        }).then((response: any) => {
                            console.log(response)
                            return response?.node?.contractCompanies;
                        }).catch((error: any) => {
                            console.log(error);
                        });
                        const newData = (response || []).map((item: any) => {
                            console.log(item)
                            return {
                                label: `${item?.title}`,
                                value: item?._id
                            }
                        })

                        const finalNewData = _.difference(newData, data);
                        setData([...data, ...finalNewData]);
                        console.warn(finalNewData.length)
                        if (newData.length < batchSize) {
                            return {
                                options: finalNewData,
                                hasMore: false,
                                additional: {
                                    page: page + 1
                                }
                            };
                        }
                        return {
                            options: [...finalNewData],
                            hasMore: newData.length !== 0,
                            additional: {
                                page: page + 1
                            }
                        };
                        //setPage(page + 1);
                    }}
                />
            )}
        </>
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(CompaniesSelect);