import React, { useEffect, useState } from "react";
import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { setFileData, editTemplate, createDocumentFromTemplate } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";
import reactStringReplace from 'react-string-replace';

import './index.scss';
import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import Avatar from "react-avatar";
import apiInstance from "@clients/api.client";
import moment from "moment";
import ActivityFeed from "../ActivityFeed";

const mapStateToProps = (state: any) => ({
  components: state?.components,
  companies: state?.companies,
  files: state?.files,
  users: state?.users,
  contracts: state?.contracts
});
const mapActionsToProps = {
  toggleGenericContractOverviewModal
};


const ContractOverviewRightPanel = (props: any) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>("");
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [comments, setComments] = useState<any>([]);
  const [userData, setUserData] = useState<any>([]);

  const handlePopupUser = (id: string) => {
    console.log('id', id);
  }

  const [refreshed, setRefreshed] = useState<boolean>(false);

  const handleSendComment = () => {


    if (!value) {
      toast.error(t("Please enter a comment"));
      return;
    }

    apiInstance.post(`/comments/${props?.companies?.currentCompany}/${props?.contracts?.contractOverviewModalContractId}`, {
      comment: value
    }).then((res: any) => {
      setValue('');
      setComments([...comments, res?.data?.node?.commentData]);
      scrollToBottom();
      console.log('res', res);
      setRefreshed(true)

    }
    ).catch((err: any) => {
      console.log('err', err);
    }
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setRefreshed(false)
    }, 300);
  }, [refreshed])

  useEffect(() => {
    if (props?.contracts?.contractOverviewModalContractId) {
      apiInstance.get(`/comments/${props?.companies?.currentCompany}/${props?.contracts?.contractOverviewModalContractId}`).then((res: any) => {
        setComments(res?.data?.node?.commentData);
        scrollToBottom();
      }
      ).catch((err: any) => {
        console.log('err', err);
      }
      )
    }

  }, [props?.contracts?.contractOverviewModalContractId])



  const scrollToBottom = () => {
    const element = document.getElementById("comments");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }



  return (
    <>
      <div className="comments-container">

        {/* <div id="comments" className="comments">
          {(comments || []).map((comment: any, index: number) => (
            <div className="comment" key={index}>
              <div className="message">
                <div className="comment-avatar">
                  <Avatar name={comment?.user?.name + '' + comment?.user?.surname} size="30" round={true} />
                </div>
                <div className="comment-content">
                  <div className="comment-header">
                    <div className="comment-author">
                      <span>{comment?.user?.name} commented</span>
                    </div>
                    <div className="comment-date">
                      <span>{moment(comment?.createdAt).fromNow(true)}</span>
                    </div>
                  </div>
                  <div className="comment-body">
                    <span>
                      <div>
                        {reactStringReplace(comment?.comment, /(@\[[a-zA-Z0-9 wığüşöçĞÜŞÖÇİ]+\]\([a-zA-Z0-9]+\))/gi, (match, i) => {
                          let regex = new RegExp(/@\[([a-zA-Z0-9 wığüşöçĞÜŞÖÇİ]+)\]\(([a-zA-Z0-9]+)\)/g);
                          let matches: any = regex.exec(match) || [];
                          return (
                            <span
                              className="badge bg-primary"
                              onClick={() => handlePopupUser(matches[2])} key={i} style={{ cursor: 'pointer' }}>@{matches[1]}</span>
                          )
                        })}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}




        </div> */}

        <div id="comment-block" className="write-comment-block">


          <MentionsInput
            allowSuggestionsAboveCursor={true}
            forceSuggestionsAboveCursor={false}

            className="comment-input mb-1"
            value={value}
            allowSpaceInQuery={true}
            onChange={
              (event: any) => {
                setValue(event.target.value)
              }
            }
            placeholder={t("CONTRACTS.OVERVIEW.CHAT.WRITE_A_MESSAGE")}

          >


            <Mention
              style={{ backgroundColor: "lightblue" }}
              markup="@[__display__](__id__)"
              trigger="@"
              data={async (query, callback) => {
                console.log('query', query);

                await apiInstance.get(`/companies/${props?.companies?.currentCompany}/user-list`, {
                  params: {
                    page: 1,
                    batchSize: 100,
                    searchText: query
                  }
                }).then((response: any) => {
                  console.log('response', response);
                  callback([...response?.data?.node?.users]?.map((user: any) => {
                    return {
                      id: user._id,
                      display: user.name + ' ' + user.surname
                    }
                  }
                  ))
                }).catch((error: any) => {
                  console.log('error', error);
                })
              }}

              displayTransform={(id: any, display: any) => {
                return `@${display}`
              }}

              renderSuggestion={(suggestion: any, search: any, highlightedDisplay: any, index: any, focused: any) => {
                return (
                  <div className={focused ? 'bg-primary text-white list-group-item user-list' : 'list-group-item user-list'}>
                    <Avatar name={suggestion?.display} style={{
                      fontSize: '1rem',
                      marginRight: '5px'
                    }} size="20px" className="rounded-circle" />
                    <span>{suggestion?.display}</span>
                  </div>
                )
              }}

            />


          </MentionsInput>

          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Button

                style={{
                  paddingLeft: '0',
                }}
                size={'sm'} variant="link" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                <FeatherIcons size={'16'} icon="smile" />
              </Button>


            </div>
            <Button
              disabled={value === ''}
              onClick={handleSendComment}
              variant={value === '' ? "send-button-empty" : "send-button-full"} size={'sm'} >
              {t('CONTRACTS.OVERVIEW.CHAT.SEND')} <FeatherIcons icon="send" size={16} />
            </Button>
          </div>

          {showEmojiPicker && <div
            style={{ position: 'absolute', bottom: '20px', left: '40px' }}
            className="emoji-picker">
            <div
              className="d-flex align-items-center justify-content-end"
            >
              <Button
                variant="primary"
                onClick={() => {
                  setShowEmojiPicker(!showEmojiPicker)
                }}
              >
                <FeatherIcons icon={'x'}></FeatherIcons>
              </Button>
            </div>
            <EmojiPicker
              width={300}
              height={400}
              previewConfig={{
                defaultCaption: "Pick one!",
                defaultEmoji: "1f92a" // 🤪
              }}
              onEmojiClick={(emojiObject: any, event: any) => {
                console.log(event)
                console.log(emojiObject)
                setShowEmojiPicker(!showEmojiPicker)
                setValue(value + emojiObject.emoji)
              }} />
          </div>
          }



        </div>

        <div className="w-100 af-wrapper">
          <ActivityFeed
            commentRefresh={refreshed}
          />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ContractOverviewRightPanel);
