/* eslint-disable */
export default {
    LANGUAGE_SHORT_CODE: 'tr',
    CREATE_BUTTON: 'Yeni',
    CREATE_DOCUMENT_FROM_TEMPLATE: 'Şablondan Belge Oluştur',
    PERMISSIONS: "İzinler",
    FEEDBACK: {
        MESSAGE: "Bu ekranı geliştiriyoruz. Bu süreçte bize öneride bulunmak ister misiniz?",
        BUTTON: "Geri bildirim ver",
    },
    LEFT_MENU: {
        APPROVE_TEMPLATES: 'Onay Şablonları',
        REPORTS: "Raporlar",
        PIPELINE: "Süreçler",
        FILES: "Dokümanlar",
        CONTRACTS: "Süreçler",
        ASSETS: "Varlıklar",
        STATUSES: "Aşamalar",
        PIPELINES: "Süreçler",
        CUSTOM_FIELDS: "Özel alanlar",
        CUSTOM_FORMS: "Formlar",
        CONTRACT_TYPES: "Süreç Tipleri",
        CONTRACT_COMPANIES: "Taraflar",
        CONTRACT_CONTACTS: "Taraf Kişiler",
        MY_ACCOUNT: "Hesabım",
        INBOX: "Gelen Kutusu",
        TEMPLATES: "Şablonlar",
        RECORDS: "Süreçler",
        TABLE: "Süreçler",
        ADMIN: "Yönetim",
        COMPANY_SETTINGS: "Çalışma Alanı Ayarları",
        USERS: "Kullanıcılar",
        USER_INVITES: "Davetli Kullanıcılar",
        ROLES: "Roller",
        COMPANY_INTEGRATIONS: "Çalışma Alanı Entegrasyonları",
        APPROVAL_MANAGEMENT: "İş Akış Yönetimi",
        MY_ACCOUNT_SETTINGS: "Hesap Ayarları",
        MY_ACCOUNT_INTEGRATIONS: "Entegrasyonlarım",
        DOCUMENT_MANAGEMENT: "Doküman Yönetimi",
        WORKFLOW_MANAGEMENT: "İş Akışı Yönetimi",
        DOCUMENT_GENERATION: "Belge Otomasyonu",
        WORKFLOWS: "İş Akışları",
        EVENTS: "Etkinlikler",
        DASHBOARD: "Anasayfa",
        PROCESS: "Süreçler",
        APPS: "Uygulamalar",
        INSIGHT: "Analiz",
        ANALYTICS: "Analiz ve Rapolar",
        AUDIT_LOGS: "İşlem Kayıtları",
        SPECIAL_APPLICATIONS: "Özel Uygulamalar",
        FORMS_AND_TEMPLATE: "Google Drive Şablonlar",
    },
    TOPBAR_MENU: {
        TABLE: "Tablo",
        KANBAN: "Kanban",
        EVENTS: "Takvim",
        FILES_MANAGEMENT: "Dokümanlar",
        TEMPLATES: "Şablonlar",
        CUSTOM_FORMS: "Formlar",
        PROFILE: "Hesabım",
        MY_INTETRATIONS: "Entegrasyonlarım",
        COMPANY_SETTINGS: "Ayarlar",
        COMPANY_USERS: "Kullanıcı Yönetimi",
        COMPANY_INVITES: "Davetler",
        COMPANY_ROLES: "Roller",
        CONTRACT_TYPES: "Süreç Tipleri",
        CONTRACT_STATUSES: "Aşamalar",
        CUSTOM_FIELDS: "Özel Alanlar",
        MY_INTEGRATIONS: "Entegrasyonlarım",
        DASHBOARD: "Analiz",
        FILES_MANAGEMENT_ALL: "Tümü",
        FILES_MANAGEMENT_DRAFT: "Taslak",
        FILES_MANAGEMENT_COMPLATED: "Tamamlanan",
        FILES_MANAGEMENT_TEMPLATE: "Şablon",
        INSIGHTS: "Analiz",
        REPOSITORY: "Detaylı Tablo",
        TRACKING: "Takip",
        CUSTOMIZATIONS: "Özelleştirmeler",
        INTEGRATIONS: "Entegrasyonlar",
        INSIGHT: "Analiz",
        ANALYTICS: "Analiz ve Rapolar",
        AUDIT_LOGS: "İşlem Kayıtları",
    },
    API_TABLE: {
        SEARCH_FOR_KEYWORDS: "Anahtar Kelime Ara",
        SEARCH: "Ara",
        CLEAR: "Temizle",
        DISPLAY: "Göster:",
        ENTRIES: "Girdi Sayısı",
        TOTAL: "Toplam:",
        NO_DATA_FOUND: "Sistemde veri bulunamadi"
    },
    API_TABLE_CUSTOM_COMPONENT: {
        SEARCH_FOR_KEYWORDS: "Anahtar Kelime Ara",
        SEARCH: "Ara",
        CLEAR: "Temizle",
        EXPORT: "Dışa Aktar",
        EXPORT_TO_EXCEL: "Excel ile Dışa Aktar",
        PLEASE_WAIT: "Lutfen bekleyin...",
        DISPLAY: "Görüntüle:",
        ENTRIES: "Girdi Sayısı",
        TOTAL: "Toplam:",
        NO_DATA_FOUND: "Sistemde veri bulunamadi"
    },
    ACTIONS_DROPDOWN_MENU: {
        GENERAL: "Genel",
        CONTRACT: "Süreç",
        FILE: "Doküman",
        USER: "Kullanıcı",
        ASSET: "Varlık",
        PIPELINE: "Süreç",
        STATUS: "Aşama",
        CONTRACT_TYPE: "Süreç Tipi",
        CUSTOM_FIELD: "Özel Alan",
        TYPE: "Süreç Tipleri",
        COMPANY: "Taraflar",
        CONTACT: "Taraf Kişi",
        CREATE: "Oluştur",
        NEW_RECORD: "Yeni Süreç",
        NEW_TEMPLATE: "Doküman Oluştur",
        NEW_FORM: "Yeni Form",
        UPLOAD: "Yükle",
        UPLOAD_DOCUMENT: "Toplu Yükle",
        NEW_PROCESS: "Yeni Süreç",
        CREATE_PROCESS: "Süreç Oluştur",
        DESCRIPTIONS: {
            CREATE_PROCESS: "Süreçlerinizi sıfırdan veya dokümanlarla başlayarak veya sözleşme talep ederek oluşturun",
            UPLOAD_DOCUMENT: "Dokümanlarınızı toplu olarak yükleyin ve süreçlere bağlayın",
            NEW_TEMPLATE: "Formları ve şablonları kullanarak doküman oluşturun"
        }
    },
    PROFILE_DROPDOWN_MENU: {
        WELCOME: "Hoşgeldin !",
        PROFILE: "Profil",
        MY_ACCOUNT: "Hesabım",
        MY_INTEGRATIONS: "Entegrasyonlarım",
        CREATE_COMPANY: "Çalışma Alanı Oluştur",
        COMPANY_SWITCH: "Başka Çalışma Alanına Geçiş Yap",
        COMPANY: "Çalışma Alanı",
        COMPANY_SETTINGS: "Çalışma Alanı Ayarları",
        COMPANY_USERS: "Çalışma Alanı Kullanıcıları",
        COMPANY_INVITES: "Davetli Kullanıcılar",
        COMPANY_ROLES: "Çalışma Alanı Rolleri",
        COMPANY_INTEGRATIONS: "Çalışma Alanı Entegrasyonları",
        COMPANY_PIPELINES: "Süreçler",
        COMPANY_STATUSES: "Aşamalar",
        LOG_OUT: "Çıkış Yap",
        LOCK_SCREEN: "Ekranı Kilitle",
        LOGOUT: "Çıkış Yap",
        CUSTOM_FIELDS: "Özel Alanlar",
        RECORD_TYPES: "Süreç Tipleri",
    },
    LANGUAGE: {
        ENGLISH: "English",
        TURKISH: "Türkçe"
    },
    APPS: {
        DRIVE_IMPORT: "Drive İçe Aktarma",
        DRIVE_EXPORT: "Drive Dışa Aktarma",
        GMAIL_IMPORT: "Gmail İçe Aktarma",
        ONEDRIVE_IMPORT: "OneDrive İçe Aktarma",
        ONEDRIVE_EXPORT: "OneDrive Dışa Aktarma",
        SHAREPOINT_IMPORT: "SharePoint İçe Aktarma",
        SHAREPOINT_EXPORT: "SharePoint Dışa Aktarma",
    },
    COMPANIES: {
        DETAILS: {
            TITLE: "Çalışma Alanı Ayarları",
            DESCRIPTION: "Mevcut Çalışma Alanı detayları: şirketteki detayları okuyabilir, düzenleyebilir, silebilirsiniz",
            COMPANY_TITLE_LABEL: "Çalışma Alanı Adı:",
            COMPANY_TITLE_PLACEHOLDER: "Çalışma Alanı Adı",
            COMPANY_DESCRIPTION_LABEL: "Çalışma Alanı Açıklaması:",
            COMPANY_DESCRIPTION_PLACEHOLDER: "Çalışma Alanı Açıklaması",
            COMPANY_DOMAIN_LABEL: "Çalışma Alanı Domaini:",
            COMPANY_DOMAIN_PLACEHOLDER: "Çalışma Alanı Domain",
            COMPANY_LOGO_URL_LABEL: "Logo Url (300x300):",
            COMPANY_LOGO_URL_PLACEHOLDER: "https://website.com/logo.png",
            SAVE_CHANGES: "Değişiklikleri Kaydet",
            DELETE_COMPANY_LABEL: "ŞİRKETİ SİL",
            DELETE_COMPANY_DESCRIPTION: "Firmanızı onedocs uygulamasından silebilirsiniz. Sil komutundan sonra firma bilgileriniz 30 gün süreyle saklanacaktır. Bilgilerinizi geri yüklemek için sildikten sonra destek kanalı üzerinden bize ulaşabilirsiniz.",
            DELETE_COMPANY_CHECK_DESCRIPTION: "Çalışma Alanı silmek için giriş kutusuna 'permanent-delete' yazabilirsiniz",
            DELETE_CHECK_LABEL: "Silmeyi Onayla:",
            DELETE_CHECK_PLACEHOLDER: "permanent-delete",
            DELETE_CHECK_INVALID: "Lütfen giriş kutusuna 'permanent-delete' kelimesini girin",
            DELETE_COMPANY_BUTTON: "Çalışma Alanı Sil",
            CDN_ENDPOINT_TITLE: "Custom CDN Uç Noktası",
            CDN_ENDPOINT_DESCRIPTION: "Çalışma Alanı dokümanlarınızı depolamak için kendi CDN uç noktanızı kullanabilirsiniz. Herhangi bir CDN uç noktası sağlarsanız, Çalışma Alanı dokümanlarınızı depolamak için CDN uç noktamızı kullanırız.",
            CDN_ENDPOINT: "CDN Uç Noktası",
            CDN_ENDPOINT_PLACEHOLDER: "https://cdn-jetlexa.example.com",
            CDN_ENDPOINT_LOCKED: "Çalışma Alanı için CDN uç noktası ayarlanmıştır. Bu ayarı değiştirmek için lütfen destek ile iletişime geçin.",
            SAVE: "Değişiklikleri Kaydet",
            DEMO_DATA_DELETION_TITLE: "Demo Verilerini Sil",
            DEMO_DATA_DELETION_DESCRIPTION: "Çalışma Alanı için oluşturulan tüm demo kayıtları silinecektir. Bu işlem geri alınamaz.",
            CONTRACTS: "Süreçler",
            PIPELINES: "Süreçler",
            STATUSES: "Aşamalar",
            CUSTOM_FIELDS: "Özel Alanlar",
            RECORD_TYPES: "Süreç Tipleri",
            PARTIES: "Taraflar",
            DELETE: "Sil",
            DELETE_ALL: "Tümünü Sil",
        },
        USERS: {
            TITLE: "ŞİRKET KULLANICILARI",
            DESCRIPTION: "Mevcut Çalışma Alanı kullanıcıları: Çalışma Alanı kullanıcıları arayabilir, okuyabilir, düzenleyebilir, silebilirsiniz.",
            INVITE_USER: "Kullanıcı Davet Et",
            TABLE: {
                ID: "ID",
                NAME: "Ad",
                SURNAME: "Soyadı",
                EMAIL: "E-posta",
                PHONE_NUMBER: "Telefon Numarası",
                USERNAME: "Kullanıcı Adı",
                CREATED_DATE: "Oluşturma Tarihi"
            }
        },
        INVITES: {
            TITLE: "DAVETLİ KULLANICILAR",
            DESCRIPTION: "Mevcut Çalışma Alanı davetiyeleri: Çalışma Alanı davet edilen kullanıcıları arayabilir, okuyabilir, düzenleyebilir, silebilirsiniz.",
            INVITE_USER: "Kullanıcı Davet Et",
            TABLE: {
                ID: "ID",
                EMAIL: "E-posta",
                STATUS: "Aşama",
                CREATED_DATE: "Oluşturma Tarihi"
            }
        },
        ROLES: {
            TITLE: "ŞİRKET ROLLERİ",
            DESCRIPTION: "Mevcut Çalışma Alanı rolleri: Çalışma Alanı rolleri arayabilir, okuyabilir, düzenleyebilir, silebilirsiniz.",
            CREATE_ROLE: "Rol Oluştur",
            TABLE: {
                ID: "ID",
                TITLE: "Ad",
                NOMINATIONS: "İzinler",
                CREATED_DATE: "Oluşturma Tarihi"
            },
            DEFAULT_ROLES: {
                OWNER: "Çalışma Alanı Sahibi",
                SUPER_ADMIN: "Süper Yönetici",
                ADMIN: "Yönetici",
                USER: "Kullanıcı",
            },
            NOMINATIONS: {
                "edit-company-details": "Çalışma Alanı Ayarları: Detaylar",
                "edit-company-users": "Çalışma Alanı Ayarları: Kullanıcılar",
                "edit-company-users-invites": "Çalışma Alanı Ayarları: Kullanıcı Detayları",
                "edit-company-roles": "Çalışma Alanı Ayarları: Roller",
                "edit-company-integrations": "Çalışma Alanı Ayarları: Entegrasyonlar",
                "files-management": "Doküman Yönetimi",
                "contracts-management": "Süreç Yönetimi",
                "contracts-deletion": "Süreç Silme",
                "approve-management": "İş Akış Yönetimi",
                "approve-file-continue-flow": "İş Akış Yönetimi - Akışa Kaldığı Yerde Devam Edebilme",
                "edit-process": "Süreç Düzenleme",
            }
        }
    },
    COMPANIES_INTEGRATIONS: {
        TITLE: "ŞİRKET ENTEGRASYONLARI",
        DESCRIPTION: "Mevcut Firmanın Entegrasyonları: Firma içi entegrasyonlar için okuyabilir, silebilirsiniz.",
        GDRIVE_INTEGRATION_TITLE: "Google Drive ile Senkronizasyon",
        ONEDRIVE_INTEGRATION_TITLE: "OneDrive ile Senkronizasyon",
        SHAREPOINT_INTEGRATION_TITLE: "SharePoint ile Senkronizasyon",
        SHAREPOINT_SITES_SELECTION: "SharePoint Sites Seçimi",
        GDRIVE_INTEGRATION_DESCRIPTION: "Dokümanları Google Drive'a aktarabilmek için Google Drive entegrasyonunu kurabilirsiniz.",
        ONEDRIVE_INTEGRATION_DESCRIPTION: "Dokümanları OneDrive'a aktarabilmek için OneDrive entegrasyonunu kurabilirsiniz.",
        SHAREPOINT_INTEGRATION_DESCRIPTION: "Dokümanları SharePoint'e aktarabilmek için SharePoint entegrasyonunu kurabilirsiniz.",
        CONNECT: "Bağlan",
        CONNECTED: "Bağlandı",
        REMOVE_CONNECTION: "Bağlantıyı Kaldır",
        ACTIVE_INTEGRATION: "aktif hesap(lar)",
        CONNTECTED_ACCOUNT: "Bağlı Hesap",
        ACTIVE: "Etkin",
        SHARE_INFO: "Ekibinizle belge paylaşmak istiyorsanız ortak bir depolama konumu seçin.",
        CONNECTED_ACCOUNT_SHARE_INFO: "Dokümanlarınızın ekibinizle paylaşılabilmesi için, ortak bir depolama hesabı seçtiğinizden emin olun.",
        CHAT_GPT: "ChatGPT hesabınızı onedocs'a entegre ederek veri giriş sürecini hızlandırabilirsiniz.",
        SALES_FORCE: "onedocs ve Salesforce entegrasyonu çok yakında..."

    },
    CONTRACTS: {
        PAGE_TITLE: "Süreç Detayı",
        CONTRACT: {
            TITLE: "Süreç:",
        },
        REPORTS: {
            ADD: "Ekle",
            NEW: "Yeni",
            FILES: "Dokümanlar",
            DASHBOARD: "Panel",
            TEMPLATES: "Şablonlar",
            CUSTOM_FORMS: "Özel Formlar",
            CONTRACTS: "Süreçler",
            STATUSES: "Aşamalar",
            CREATE_COMPANY: "Taraf Oluştur",
            CREATE_CONTRACT: "Süreç Ekle",
            ADD_USER_TO_COMPANY: "Yeni Kullanıcı Ekle",
            CONTRACT_TYPES: "Süreç Tipleri",
            MONTHLY_REPORT: "Aylık Süreçler",
            TIMELINE: "Zaman Çizelgesi",
            NO_DATA_TIMELINE: "Zaman Çizelgesi için veri bulunamadı.",
        },
        REPORTS_TABLE: {
            TITLE: "Başlık",
            START_DATE: "Başlangıç Tarihi",
            END_DATE: "Bitiş Tarihi",
            RENEWAL_DATE: "Yenileme/Uyarı Tarihi",
            TYPE: "Tip",
            PIPELINE: "Süreç",
            CREATED_AT: "Oluşturan",
        },
        TABLE: {
            TOP_TITLE: "Süreçler",
            PAGE_TITLE: "KAYITLAR",
            PAGE_DESCRIPTION: "Süreç listelendi.",
            ADD_NEW: "Yeni Ekle",
            TITLE: "Başlık",
            START_DATE: "Başlangıç Tarihi",
            END_DATE: "Bitiş Tarihi",
            RENEWAL_DATE: "Yenileme/Uyarı Tarihi",
            EFFECTIVE_DATE: "Yürürlük Tarihi",
            SIGNED_DATE: "İmza Tarihi",
            COMPANIES: "Taraflar",
            COMPANIES_INTERNAL: "Şirket Tarafı (Dahili)",
            COMPANIES_EXTERNAL: "Karşı Taraf",
            CONTACTS: "Taraf Kişiler",
            ASSIGNED_USERS: "Tanımlı Kullanıcılar",
            PIPELINE: "Süreç",
            STATUS: "Aşama",
            TYPE: "Tip",
            CREATED_AT: "Oluşturma Tarihi",
            DELETE_ALL: "Tümünü Sil",
            DELETE_SELECTED: "Seçilenleri Sil",
            SEARCH: {
                PLACEHOLDER: 'Süreç Ara...',
                TITLE: 'Başlık',
                DESCRIPTION: 'Açıklama',
                CUSTOM_FIELDS: 'Özel Alanlar'
            },
            FILTERS: {
                FILTERS: 'Filtreler',
                ADD_FILTERS: 'Filtre Ekle',
                RESET_FILTERS: 'Filtreyi Sıfırla',
                WHERE: 'NEREDE',
                AND: 'VE',
                OR: 'VEYA',
                CONTAINS: 'İçeren',
                NOT_CONTAINS: 'İçermeyen',
                DATE_RANGE: 'Tarih Aralığı',
                SELECTION: 'Seçim',
                SELECTION_IN: 'İse',
                SELECTION_NOT_IN: 'Değilse',
                FAVORITE_FILTERS: 'Favori Filtreler',
                SAVE_FILTERS: 'Filtreyi Kaydet',
                MAKE_DEFAULT_FILTERS: 'Varsayılan Yap',
                SEARCH_FILTERS: 'Filtre Ara',
                EDIT_FILTERS: 'Filtreyi Düzenle',
                FILTER_TITLE: 'Filtreler',
                DELETE_FILTERS: 'Filtreyi Sil',
                CANCEL: 'İptal',
                ARE_YOU_SURE: 'Emin misiniz?',
                ARE_YOU_SURE_DELETE_FILTERS_DESCRIPTION: 'Bu filtreyi kalıcı olarak silmek istediğinize emin misiniz?',
                DELETE: 'Sil',
                SAVE: 'Kaydet',
                DEFAULT_FILTERS: 'Varsayılan Filtre',
                NO_DEFAULT_FILTERS: 'Varsayılan Filtre Yok',
                NO_FAVORITE_FILTERS: 'Favori Filtre Yok',
                ALL_DATA_LOADED: 'Tüm veriler yüklendi',
                FILTERS_SELECTED: 'Seçili Filtreler',
                REMOVE_DEFAULT_FILTERS: 'Varsayılandan Kaldır',
                EQ: 'Eşitse',
                NEQ: 'Eşit Değilse',
                GT: 'Büyükse',
                LT: 'Küçükse',
                GTE: 'Büyük Eşitse',
                LTE: 'Küçük Eşitse',
                EMPTY: 'Boşsa',
                NOT_EMPTY: 'Boş Değilse',
                DATE_EQ: 'Tarihinde ise',
                DATE_NEQ: 'Tarihinde değilse',
                DATE_GT: 'Tarihinden sonra',
                DATE_LT: 'Tarihinden önce',
                DATE_GTE: 'Tarihinde veya sonrasında',
                DATE_LTE: 'Tarihinde veya öncesinde',
                DATE_EMPTY: 'Tarih yoksa',
                DATE_NOT_EMPTY: 'Tarih varsa',
            },
            SORT_BY: {
                SORT_BY: 'Sırala'
            },
            COLUMNS: {
                COLUMNS: 'Sütunlar'
            },
            EXPORT: {
                EXPORT: 'Dışarı Aktar',
                EXPORT_TO_EXCEL: 'Excel\'e Aktar'
            },
            FOOTER: {
                TOTAL: 'Toplam',
                PER_PAGE: 'Sayfa Başına'
            }
        },
        OVERVIEW: {
            SUB_RECORDS: "Alt Süreçler",
            PARENT_RECORD: "Ana Süreç",
            REMOVE_RELATION: "İlişkiyi Kaldır",
            ADD_SUB_RECORD: "Alt Süreç Ekle",
            SELECT_SUB_RECORD: "Mevcut kayıtlardan seç",
            SELECT_SUB_RECORDS: "Alt Süreç Seç",
            NEW_RECORD: "Yeni Süreç",
            DELETE_RECORD: "Sil",
            RELATE_TO_THE_RECORD: "Kayıtla İlişkilendir",
            TITLE_ERROR: "Lütfen kaydınız için bir başlık girin.",
            ACTIONS: {
                ACTIONS: "Aksiyonlar",
                EDIT_CONTRACT: "Düzenle",
                REMOVE_CONTRACT: "Sil"
            },
            INFO_CARDS: {
                START_DATE: "Başlangıç Tarihi",
                END_DATE: "Bitiş Tarihi",
                EFFECTIVE_DATE: "Yürürlük Tarihi",
                SIGNED_DATE: "İmza Tarihi",
                RENEWAL_DATE: "Yenileme/Uyarı Tarihi",
                ASSIGNED_USERS: "Tanımlı Kullanıcılar"
            },
            ACTIVITY_FIELD: {
                FILES: "Dokümanlar",
                REMINDER: "Etkinlikler"
            },
            FILES: {
                FILES_DESCRIPTION: "Dokümanlarınızı eklediğinizde etkinlik akışında etkinliklerini görebilirsiniz.",
                ADD_FILES: "Doküman Yükle",
                UPLOAD_FILES: "Doküman Yükle",
                SELECT_UPLOADED_FILES: "Yüklü Dokümanlardan Seç",
                ADDED_FILES: "Yüklenen Dokümanlar",
                SHOW_ALL_FILES: "Yüklenen Tüm Dokümanları Görüntüle",
                FILENAME: "Doküman Adı",
                UPLOADED_BY: "Yükleyen",
                ACTIONS: "Aksiyonlar",
                NO_FILES: "Bu süreç için doküman bulunamadı",
                SAVE_CHANGES: "Değişiklikleri Kaydet",
                EDIT: "Düzenle",
                CLOSE_EDIT_MODE: "Düzenleme Modunu Kapat",
                DOWNLOAD: "İndir",
                DELETE: "Sil",
                GO_TO_PAGE: "Sayfaya Git",
                TOTAL_FILES: "Toplam Doküman:",
                MAX_PAGE: "Toplam Sayfa:",
                DELETE_FILE: "Dokümanı Sil",
                DELETE_FILE_DESCRIPTION: "Dokümanın silindiğinden emin misiniz?",
                STATUS: "Durum",
                FILES_TITLE: "Documents",
                APPROVE_PROCESS: "İlişkili İş Akışı",
            },
            REMINDER: {
                REMINDER_DESCRIPTION: "Önemli şeyleri unutmamak için etkinlik oluşturabilirsiniz.",
                ADD_REMINDER: "Etkinlik Oluştur",
                REMINDERS: "Etkinlikler",
                PROVIDE_GUEST_NAME: "Lütfen bir misafir adı girin",
                PROVIDE_GUEST_SURNAME: "Lütfen bir konuk soyadı girin",
                PROVIDE_GUEST_EMAIL: "Lütfen bir konuk e-postası girin",
                TITLE_LABEL: "Başlık",
                TITLE_PLACEHOLDER: "Etkinlik başlığı gir...",
                DATE_LABEL: "Etkinlik Tarihi",
                DESCRIPTION_LABEL: "Açıklama",
                DESCRIPTION_PLACEHOLDER: "Etkinlik açıklaması gir...",
                GUESTS_LABEL: "Misafirler",
                ADD_USER: "Kullanıcı Ekle",
                ADD_OUTSOURCE_GUESTS: "Misafir Ekle",
                CANCEL: "İptal Et",
                CREATE: "Oluştur",
                NAME_LABEL: "Ad",
                NAME_PLACEHOLDER: "Misafir adını girin...",
                SURNAME_LABEL: "Soyadı",
                SURNAME_PLACEHOLDER: "Misafirin soyadını girin...",
                EMAIL_LABEL: "Eposta",
                EMAIL_PLACEHOLDER: "Misafir epostasını girin...",
                ADD_GUEST: "Misafir Ekle",
                SEARCH_A_USER: "Kullanıcı Ara",
                NO_MORE: "Devamı Yok",
                NO_DATA_FOUND_IN_THE_REMINDERS: "Etkinliklerde veri bulunamadı",
                CREATE_FIRST_REMINDER: "İlk Hatırlatmayı Oluştur",
                NEW_REMINDER: "Yeni Etkinlik",
                NEW_STATUS: "Yeni Aşama",
                ADD_TO_CALENDAR: "Takvime Ekle",
                ADD_TO_GOOGLE_CALENDAR: "Google Takvim'e Ekle",
                ADD_TO_OUTLOOK_CALENDAR: "Outlook Takvim'e Ekler",
                ADD_TO_OFFICE_360_CALENDAR: "Office 365 Takvim'e Ekle",
                ARE_YOU_SURE: "Emin misin?",
                ARE_YOU_SURE_MODAL_DESCRIPTION: "Etkinliği kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
                REMOVE: "Kaldır"
            },
            DESCRIPTION: {
                TITLE: "Süreç Açıklaması",
                DESCRIPTION_PLACEHOLDER: "Süreç açıklamasını girin...",
                SAVE: "Kaydet"
            },
            TAGS: {
                TAGS: "Etiketler",
                NO_TAGS: "Etiket bulunamadı",
                ADD_TAGS_TO_CONTRACT: " Süreç için etiket ekleyin",
                ADD_NEW_TAG_PLACEHOLDER: "Yeni bir etiket ekle...",
            },
            COMPANIES: {
                COMPANIES: "Taraflar",
                NO_COMPANY_FOUND: "Süreç için taraf bulunamadı",
                NO_MORE: "Devamı Bulunamadı",
                ADD_COMPANY_TO_CONTRACT: " Yeni Taraf  Ekle"
            },
            CONTACTS: {
                CONTACTS: "Taraf Kişiler",
                ADD_CONTACT_TO_CONTRACT: "Taraf Kişi Ekle",
                ALL_DATA_LOADED: "Tüm veriler yüklendi",
            },
            CUSTOM_FIELDS: {
                CUSTOM_FIELDS: "Özel Alanlar",
            },
            ASSIGNED_USERS: {
                ASSIGNED_USERS: "Tanımlı Kullanıcılar",
                ASSIGN_USER_TO_CONTRACT: "Süreç için kullanıcı tanımla",
                NO_ASSIGNED_USERS: "Tanımlı kullanıcı bulunamadı",
                ALL_DATA_LOADED: "Tüm veriler yüklendi.",
                SEARCH_PLACEHOLDER: "Bir kullanıcı ara"
            },
            CONTRACT_TYPE: "Süreç Tipi",
            CHAT: {
                SEND: "Gönder",
                WRITE_A_MESSAGE: "Yorum yaz...",
            },
            DELETE_MODAL: {
                DELETE: "Sil",
                DELETE_ALL: "Tümünü Sil",
                DELETE_ALL_DESCRIPTION: "Ana kaydı silmeniz halinde ona bağlı tüm alt kayıtlar da silinecektir. Silinmesini istemediğiniz alt kayıtlar için alt süreç aksiyonlarında yer alan \"İlişkiyi Kaldır\" seçeneğini tercih edebilirsiniz.",
                DELETE_DESCRIPTION: "Kaydı kalıcı olarak silmek istediğinize emin misiniz?",
                CANCEL: "Vazgeç",
            }
        },
        ADD_CONTRACT: {
            NEW_CONTRACT: "Yeni Süreç",
            NO_TITLE: "Lütfen bir başlık girin",
            NO_STATUS: "Lütfen bir aşama ekleyin",
            NO_TYPE: "Lütfen bir süreç tipi ekleyin",
            PIPELINE: "Lütfen bir süreç süreci seçin",
            NO_COMPANY: "Lütfen bir şirket ekleyin",
            TITLE_LABEL: "Başlık",
            TITLE_PLACEHOLDER: "Başlığı girin...",
            DESCRIPTION_LABEL: "Açıklama",
            DESCRIPTION_PLACEHOLDER: "Bir açıklama ekleyin...",
            START_DATE_LABEL: "Başlangıç Tarihi",
            END_DATE_LABEL: "Bitiş Tarihi",
            EFFECTIVE_DATE_LABEL: "Yürürlük Tarihi",
            RENEWAL_DATE_LABEL: "Yenileme/Uyarı Tarihi",
            SIGNED_DATE_LABEL: "İmza Tarihi",
            PIPELINE_LABEL: "Süreç",
            PIPELINE_PLACEHOLDER: "Bir süreç seç",
            STATUS_LABEL: "Aşama",
            STATUS_PLACEHOLDER: "Bir aşama belirle",
            TYPE_LABEL: "Süreç Tipi",
            TYPE_PLACEHOLDER: "Bir süreç tipi seç",
            CANCEL: "İptal",
            CREATE: "Oluştur",
            MAKE_PRIVATE: "Özel Hale Getir",
            MAKE_PUBLIC: "Herkese Açık Hale Getir",
            PARTIES_INTERNAL: "Şirket Tarafı (Dahili)",
            PARTIES_EXTERNAL: "Karşı Taraf",
            ASSIGNED_USERS: "Tanımlı Kullanıcılar",
            RELATED_WITH: "İlişkili Süreç",
        },
        EDIT_CONTRACT: {
            MODAL_TITLE: "Süreç Düzenle",
            SOMETHING_IS_WRONG: "Bir şeyler yanlış gitti! Lütfen sayfanızı yenileyiniz!",
            NO_TITLE: "Lütfen bir başlık girin",
            NO_STATUS: "Lütfen bir aşama belirleyin",
            NO_TYPE: "Lütfen bir süreç tipi belirleyin",
            NO_COMPANY: "Lütfen bir şirket belirleyin",
            TITLE_LABEL: "Başlık",
            TITLE_PLACEHOLDER: "Başlığı girin...",
            DESCRIPTION_LABEL: "Açıklama",
            DESCRIPTION_PLACEHOLDER: "Açıklamayı girin...",
            START_DATE_LABEL: "Başlangıç Tarihi",
            END_DATE_LABEL: "Bitiş Tarihi",
            EFFECTIVE_DATE_LABEL: "Yürürlük Tarihi",
            RENEWAL_DATE_LABEL: "Yenileme Tarihi",
            SIGNED_DATE_LABEL: "İmza Tarihi",
            PIPELINE_LABEL: "Süreç",
            PIPELINE_PLACEHOLDER: "Bir süreç belirleyin",
            STATUS_LABEL: "Aşama",
            STATUS_PLACEHOLDER: "Bir aşama seçin",
            TYPE_LABEL: "Süreç Tipi",
            TYPE_PLACEHOLDER: "Bir süreç tipi seçin",
            CANCEL: "İptal",
            SAVE: "Kaydet",
            MAKE_PRIVATE: "Make Private",
            MAKE_PUBLIC: "Make Public"
        },
        KANBAN: {
            PAGE_TITLE: "Kanban",
            PIPELINES: "Süreçler",
            SWITCH_PIPELINE: "Süreç Tipi",
            EDIT_PIPELINE: "Bu Süreci Düzenle",
            MAKE_USER_DEFAULT: "Varsayılan Pipeline Yap",
            ADD_NEW: "Yeni Ekle",
            CONTRACT: "Süreç",
            ALL_DATA_LOADED: "Tüm veriler yüklendi.",
            NO_CONTRACT_FOUND: "Bu aşamada süreç bulunamadı.",
            CONTRACT_CONTACTS: "Taraf Kişiler",
            CONTRACT_COMPANIES: "Taraflar",
            CONTRACT_TYPE: "Süreç Tipi",
        }
    },
    CONTRACTS_COMPANIES: {
        PAGE_TITLE: "Taraflar",
        TITLE: "Firma Adı/Ünvan",
        TITLE_COMPANY: "Firma Adı/Ünvan",
        TITLE_CONTACT: "İsim Soyisim",
        DESCRIPTION: "Açıklama",
        CREATED_DATE: "Oluşturma Tarihi",
        ACTIONS: "Aksiyonlar",
        EDIT: "Düzenle",
        DELETE: "Sil",
        CONTRACTS_COMPANIES: "TARAFLAR",
        CONTRACTS_COMPANIES_DESCRIPTION: "taraf listelendi.",
        ADD_NEW: "Yeni Ekle",
        NEW_CONTRACT_COMPANY: "Yeni Taraf",
        NO_TITLE: "Lütfen bir başlık belirleyin",
        NO_COMPANY: "Lütfen sayfayı yenileyin!",
        TITLE_LABEL: "Firma Adı/Ünvan",
        TITLE_LABEL_COMPANY: "Firma Adı/Ünvan",
        TITLE_LABEL_CONTACT: "İsim Soyisim",
        TITLE_PLACEHOLDER: "Firma Adı/Ünvan girin...",
        TITLE_PLACEHOLDER_COMPANY: "Firma Adı/Ünvan girin...",
        TITLE_PLACEHOLDER_CONTACT: "İsim Soyisim girin...",
        DESCRIPTION_LABEL: "Açıklama",
        DESCRIPTION_PLACEHOLDER: "Açıklama ekleyin...",
        TAGS_LABEL: "Etiketler",
        TAGS_PLACEHOLDER: "Taraf etiketleri...",
        CANCEL: "İptal",
        CREATE: "Oluştur",
        SAVE: "Kaydet",
        EDIT_CONTRACT_COMPANY: "Düzenle",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        TAX_ID: "Vergi No",
        TC_ID: "T.C. Kimlik No",
        TAX_OFFICE: "Vergi Dairesi",
        COMPANY_ADDRESS: "Şirket Adresi",
        ADDRESS: "Adres",
        CONTACT_NAME: "Kontak kişisinin adı-soyadı",
        CONTACT_EMAIL: "Kontak kişisinin e-maili",
        EMAIL: "E-posta",
    },
    CONTRACTS_CONTACTS: {
        PAGE_TITLE: "Taraf Kişiler",
        FULLNAME: "Ad Soyadı",
        DESCRIPTION: "Açıklama",
        CREATED_DATE: "Oluşturma Tarihi",
        CREATED_BY: "Oluşturan",
        ACTIONS: "Aksiyonlar",
        CONTRACTS_CONTACTS: "TARAF KİŞİLER",
        CONTRACTS_CONTACTS_DESCRIPTION: "taraf kişi listelendi.",
        ADD_NEW: "Yeni Ekle",
        NEW_CONTRACT_CONTACT: "Yeni Taraf Kişisi",
        NO_NAME: "Lütfen geçerli bir ad girin",
        NO_SURNAME: "Lütfen geçerli bir soyadı girin",
        NO_COMPANY: "Lütfen sayfayı yenileyin!",
        NAME_LABEL: "Adı",
        NAME_PLACEHOLDER: "Taraf kişisinin adını girin...",
        SURNAME_LABEL: "Soyadı",
        SURNAME_PLACEHOLDER: "Taraf kişisinin soyadını girin...",
        DESCRIPTION_LABEL: "Açıklama",
        DESCRIPTION_PLACEHOLDER: "Bir açıklama veya not ekleyin...",
        DELETE: "Sil",
        TAGS_LABEL: "Etiketler",
        TAGS_PLACEHOLDER: "Etiketler oluşturun..",
        CANCEL: "İptal",
        CREATE: "Oluştur",
        SAVE: "Kaydet",
        EDIT_CONTRACT_CONTACT: "Kişiyi Düzenle",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Kişiyi kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?"
    },
    CONTRACTS_CUSTOM_FIELDS: {
        PAGE_TITLE: "Özel Alanlar",
        TITLE: "Başlık",
        TITLE_PLACEHOLDER: "Özel alan ekleyin...",
        INTERNAL_NAME: "Etiket Başlık",
        INTERNAL_NAME_PLACEHOLDER: "Özel alan için etiket belirleyin...",
        FIELD_TYPE: "Özel Alan Tipi",
        REQUIRED: "Zorunlu",
        CREATED_DATE: "Oluşturma Tarihi",
        ACTIONS: "Aksiyonlar",
        CUSTOM_FIELDS: "ÖZEL ALANLAR",
        CUSTOM_FIELDS_DESCRIPTION: "özel alan listelendi.",
        ADD_NEW: "Yeni Ekle",
        CUSTOM_FIELDS_COMPONENT_NAMES: {
            TEXT: "Metin Alanı",
            TEXTAREA: "Uzun Metin Alanı",
            NUMBER: "Numara",
            EMAIL: "Eposta",
            DATE: "Tarih",
            TIME: "Zaman",
            SINGLE_SELECTION: "Tekli Seçim",
            PHONE: "Telefon",
            CURRENCY: "Para Birimi",
            FORMULA: "Formül",
        },
        SELECT_CUSTOM_COMPONENT_FIELD: "Özel alan tipi seç",
        THIS_IS_DEFAULT: "Bu varsayılan",
        FIELD_TYPE_REQUIRED: "Alan Tipi (Gerekli)",
        FIELD_TYPE_FEEDBACK: "Lütfen bir alan tipi girin",
        FIELD_REQUIREMENT: "Alan Zorunluluğu",
        CREATE: "Oluştur",
        SAVE: "Kaydet",
        CANCEL: "İptal",
        DELETE: "Sil",
        EDIT_CUSTOM_FIELD: "Özel Alanı Düzenle",
        WARNING: "Dikkat !",
        WARNING_MESSAGE: "Tüm süreç tipleri bu değişiklikten etkilenecektir.",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        OPTIONS: "Seçenekler",
        OPTION_TITLE: "Seçenek Başlığı",
        OPTION_VALUE: "Seçenek Değeri",
        OPTION_INTERNAL_NAME: "Seçenek Etiket Başlığı",
        ADD_OPTION: "Seçenek Ekle",
        REMOVE_OPTION: "Seçenek Kaldır",
        DELETE_OPTION: "Seçenek Sil",
    },
    CONTRACTS_PIPELINES: {
        PAGE_TITLE: "Süreçler",
        PIPELINES: "Süreçler",
        NO_TITLE: "Lütfen bir başlık belirleyin",
        NEW_PIPELINE: "Yeni Süreç",
        LOADING: "Yükleniyor...",
        STATUSES: "Aşamalar",
        TITLE: "Başlık",
        TITLE_PLACEHOLDER: "Bir başlık girin...",
        DESCRIPTION: "Açıklama",
        DESCRIPTION_PLACEHOLDER: "Bir açıklama ekleyin...",
        CANCEL: "İptal",
        CREATE: "Oluştur",
        SAVE: "Kaydet",
        EDIT: "Düzenle",
        CHECK_STATUSES: "Aşamaları Kontrol Et",
        PIPELINE_STATUSES: "Süreç Aşamaları",
        AVAILABLE_STATUSES: "Mevcut Aşamalar",
        SELECT_NEW_PIPELINE: "Yeni Süreç Seç",
        SELECT_NEW_STATUS: "Yeni Aşama Seç",
        MIGRATE_YOUR_CONTRACTS: "Kayıtlarını Taşıyın",
        EXISTING_CONTRACTS_WARNING: "kayıtlar bu süreç durumunda mevcuttur. Silmeden önce kayıtlarınızı taşımanız gerekir.",
        MIGRATE_ALL: "Tümünü Taşı",
        PIPELINE_TITLE_LABEL: "Süreç Başlığı",
        PIPELINE_TITLE_PLACEHOLDER: "Süreç başlığı girin...",
        DRAG_AND_DROP_DESCRIPTION: "Bir süreç belirlemek için aşamaları\n sürükleyip bırakın",
        STATUS_NOT_FOUND: "Sistemde aşama bulunamadı",
        DEFAULT: "Varsayılan",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Süreci kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        ADD_PIPELINE: "Süreç Oluştur",
        KANBAN_VIEW: "Kanban Görüntüle",
        BOARD_DESCRIPTION: "Süreçteki kayıtları listeleyin",
        EDIT_PIPELINE: "Süreci Düzenle",
        DELETE_PIPELINE: "Süreci Sil",
        ARE_YOU_SURE_STATUS: "Emin misin?",
        ARE_YOU_SURE_STATUS_MODAL_DESCRIPTION: "Kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        USER_DEFAULT: "Kullanıcı Varsayılanı",
        SYSTEM_DEFAULT: "Sistem Varsayılanı",

    },
    CONTRACTS_STATUSES: {
        PAGE_TITLE: "Aşamalar",
        ADD_STATUS: "Aşama Ekle",
        NEW_STATUS: "Yeni Aşama",
        EDIT_STATUS: "Aşamayı Düzenle",
        DELETE_STATUS: "Aşamayı Sil",
        NO_TITLE: "Lütfen geçerli bir başlık girin",
        TITLE_LABEL: "Başlık",
        TITLE_PLACEHOLDER: "Aşamanın başlığını girin..",
        DESCRIPTION_LABEL: "Açıklama",
        DESCRIPTION_PLACEHOLDER: "Aşamanın açıklamasını girin...",
        CANCEL: "İptal",
        CREATE: "Oluştur",
        DELETE: "Sil",
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Aşamayı kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?"
    },
    CONTRACTS_TYPES: {
        STATUSES: "Aşamalar",
        SELECTED_STATUSES: "Seçili Aşamalar",
        SELECTED_CUSTOM_FIELDS: "Seçili Özel Alanlar",
        SAVED: "Kaydedildi",
        SAVING: "Kaydediliyor...",
        PAGE_TITLE: "Süreç Tipleri",
        CONTRACT_TYPES: "SÜREÇ TİPLERİ",
        NO_TITLE: "Lütfen geçerli bir başlık girin",
        TITLE: "Başlık",
        CUSTOM_FIELDS: "Özel Alanlar",
        CREATED_DATE: "Oluşturma Tarihi",
        ACTIONS: "Aksiyonlar",
        CONTRACT_TYPES_DESCRIPTION: "Süreç tipi listelendi.",
        ADD_NEW: "Yeni Ekle",
        NEW_CONTRACT_TYPE: "Yeni Süreç Tipi",
        CREATE: "Oluştur",
        EDIT_CONTRACT_TYPE: "Süreç Tipini Düzenle",
        SAVE: "Kaydet",
        DEFAULT_FIELDS: {
            TITLE: "Başlık",
            STATUS: "Aşama",
            PIPELINE: "Süreç",
            USER: "Kullanıcı",
            CREATED_DATE: "Oluşturma Tarihi",
            UPDATED_DATE: "Güncelleme Tarihi",
            EFFECTIVE_DATE: "Yürürlük Tarihi",
            SIGNED_DATE: "İmza Tarihi",
            RENEWAL_DATE: "Yenileme Tarihi",
            START_DATE: "Başlangıç Tarihi",
            END_DATE: "Bitiş Tarihi",
        },
        ARE_YOU_SURE: "Emin misin?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Süreç tipini kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        ARE_YOU_SURE_CUSTOM_FIELD_MODAL_DESCRIPTION: "Özel alanı kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?",
        DELETE: "Sil",
        CANCEL: "İptal",
        TITLE_LABEL: "Başlık Girin",
        TITLE_PLACEHOLDER: "Süreç tipinin bir başlığını girin..."
    },
    FILES: {
        PAGE_TITLE: "Doküman Yönetimi",
        FILE_MANAGER: "Doküman Yöneticisi",
        SEARCH_INPUT_PLACEHOLDER: "Doküman ara",
        UPLOAD_FILES: "Doküman Yükle",
        FILES: "Dokümanlar",
        HIDE_LONG_TAGS: "Uzun Etiketleri Gizle",
        SHOW: "Göster",
        MORE_TAGS: "etiketi daha göster",
        NONE: "Hiçbiri",
        NO_TAGS: "Etiket Yok",
        FILENAME: "Doküman adı",
        CREATED_DATE: "Oluşturma Tarihi",
        UPLOADED_BY: "Yükleyen",
        CONTRACT: "Süreç",
        TAGS: "Etiketler",
        SIZE: "Boyut",
        TYPE: "Tip",
        VERSION: "Versiyon",
        SOURCE: "Kaynak",
        ACTIONS: "Aksiyonlar",
        EXPORT: "Dışa Aktar",
        SAVE_CHANGES: "Değişiklikleri Kaydet",
        CANCEL: "İptal",
        EDIT: "Düzenle",
        CLOSE_EDIT_MODE: "Düzenleme Modunu Kapat",
        DELETE: "Sil",
        DOWNLOAD: "İndir",
        SEPERATE_TAGS_WITH_COMMAS: "Etiketleri virgülle ayırın",
        DELETE_FILE: "Dokümanı Sil",
        DELETE_FILE_DESCRIPTION: "Dokümanın silindiğinden emin misiniz?",
        TOTAL_FILES: "Toplam Doküman",
        MAX_PAGES: "Toplam Sayfa",
        NO_DATA_FOUND: "Hiçbir veri bulunamadı",
        VERSIONS: "versiyon",
        NO_VERSIONS: "Versiyon yok",
        STATUS: "Durum",
        DRAFT: "Taslak",
        TEMPLATE: "Şablon",
        COMPLETED: "Tamamlandı",
        ALL: "Tümü",
        APPROVE_PROCESS: "İş Akışı",
        VERSIONS_POPUP: {
            FILENAME: "Doküman adı",
            VERSION: "Versiyon",
            CREATED_DATE: "Oluşturulma Tarihi",
            UPLOADED_BY: "Yükleyen",
            ACTIONS: "Aksiyonlar",
            REMOVE_FROM_VERSIONS: "Eklerden Sil",
            DOWNLOAD: "Dokümanı İndir",
            DELETE: "Dokümanı Sil",
            MODAL_HEADER: "Versiyonlar",
        },
        MIMETYPES: {
            'application/rtf': 'Zengin Metin Biçmi(.rtf)',
            'application/vnd.oasis.opendocument.text': 'OpenDocument Biçmi(.odt)',
            'text/html': 'Web Sayfası(.html)',
            'application/pdf': 'PDF Dokümanı',
            'application/epub+zip': 'EPUB Publication(.epub)',
            'application/zip': 'Web Sayfası(.html, sıkıştırılmış)',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Microsoft Word(.docx)',
            'text/plain': 'Düz Metin(.txt)',
        }
    },
    TEMPLATES: {
        PAGE_TITLE: "Doküman Yönetimi",
        SEARCH_INPUT_PLACEHOLDER: "Doküman ara",
        UPLOAD_FILES: "Doküman Yükle",
        TEMPLATES: "Şablonlar",
        HIDE_LONG_TAGS: "Uzun Etiketleri Gizle",
        SHOW: "Göster",
        MORE_TAGS: "etketi daha göster",
        NONE: "Hiçbiri",
        NO_TAGS: "Etiket Yok",
        TEMPLATE_NAME: "Şablon adı",
        DOCUMENT_NAME: "Doküman adı",
        CREATED_DATE: "Oluşturma Tarihi",
        UPLOADED_BY: "Yükleyen",
        CONTRACT: "Süreç",
        TAGS: "Etiketler",
        SIZE: "Boyut",
        TYPE: "Tip",
        DOCUMENT_TYPE: "Doküman Tipi",
        SOURCE: "Kaynak",
        ACTIONS: "Aksiyonlar",
        SAVE_CHANGES: "Değişiklikleri Kaydet",
        CANCEL: "İptal",
        EDIT: "Düzenle",
        CLOSE_EDIT_MODE: "Düzenleme Modunu Kapat",
        DELETE: "Sil",
        DOWNLOAD: "İndir",
        SEPERATE_TAGS_WITH_COMMAS: "Etiketleri virgülle ayırın",
        DELETE_FILE: "Şablonu Sil",
        DELETE_FILE_DESCRIPTION: "Şablonu silmek istediğinizden emin misiniz?",
        TOTAL_FILES: "Toplam Doküman",
        MAX_PAGES: "Toplam Sayfa",
        NO_DATA_FOUND: "Hiçbir veri bulunamadı",
        EDIT_TEMPLATE: "Şablonu Düzenle",
        CREATE_DOCUMENT: "Şablondan Belge Yarat",
        CREATE_TEMPLATE: "Şablon Oluştur",
        DOCUMENT: 'Belge',
        SPREADSHEET: 'Elektronik Tablo',
        PRESENTATION: 'Sunum',
        TEMPLATE_EDITOR: 'Şablon Düzenleyici',
        DOCUMENT_EDITOR: 'Doküman Düzenleyici',
        PROVIDE_DOCUMENT_TITLE: 'Dokümanınıza bir isim verin',
        CREATING_DOCUMENT: 'Doküman Oluşturuluyor',
        CREATING_TEMPLATE: 'Şablon Oluşturuluyor',
    },
    USERS: {
        MY_ACCOUNT: "Hesabım",
        DASHBOARD: "Panel",
        PROFILE_TITLE: "Profil",
        CONTACT_INFORMATION: "Kişi Bilgisi",
        EMAIL: "Eposta:",
        PHONENUMBER: "Telefon:",
        PROFILE: {
            GENERAL: {
                TAB_TITLE: "Genel",
                GENERAL: "Genel",
                GENERAL_DESCRIPTION: "In this section, you can edit your general information.",
                NAME_LABEL: "Ad:",
                NAME_PLACEHOLDER: "Adı girin...",
                SURNAME_LABEL: "Soyadı:",
                SURNAME_PLACEHOLDER: "Soyadını girin...",
                USERNAME_LABEL: "Kullanıcı isimleri:",
                USERNAME_PLACEHOLDER: "Kullanıcı adı belirleyin..",
                PHONENUMBER_LABEL: "Numara alanı:",
                PHONENUMBER_PLACEHOLDER: "5XX XXX XX XX",
                SAVE_CHANGES: "Değişiklikleri Kaydet",
            },
            CREDENTIALS: {
                TAB_TITLE: "Giriş ve Kimlik Ayarları",
                CREDENTIALS: "Giriş / Kullanıcı Kimlik Bilgileri",
                CREDENTIALS_DESCRIPTION: "Bu bölümde onedocs Panel için giriş ve kullanıcı bilgilerinizi düzenleyebilirsiniz.",
                EMAIL_LABEL: "Eposta:",
                EMAIL_PLACEHOLDER: "E-posta adresinizi girin ve onaylayın",
                EMAIL_FEEDBACK: "Lütfen geçerli bir e-posta girin.",
                OLD_PASSWORD_LABEL: "Önceki Şifre:",
                OLD_PASSWORD_PLACEHOLDER: "Önceki şifreyi girin...",
                OLD_PASSWORD_FEEDBACK: "Lütfen şifre bilgilerinize dikkat edin",
                NEW_PASSWORD_LABEL: "Yeni Şifre:",
                NEW_PASSWORD_PLACEHOLDER: "Yeni şifreyi girin...",
                NEW_PASSWORD_FEEDBACK: "Lütfen şifre bilgilerinize dikkat edin",
                REENTER_NEW_PASSWORD_LABEL: "Yeni Şifreyi Tekrar Giriniz",
                REENTER_NEW_PASSWORD_PLACEHOLDER: "Yeni şifreyi tekrar girin..",
                REENTER_NEW_PASSWORD_FEEDBACK: "Lütfen şifre bilgilerinize dikkat edin",
                SAVE_CHANGES: "Değişiklikleri Kaydet",
            },
            DELETION: {
                TAB_TITLE: "Hesap Silme",
                DELETE_YOUR_ACCOUNT: "Hesabınızı silin",
                DELETE_YOUR_ACCOUNT_DESCRIPTION: "Hesabınızı onedocs'dan silebilirsiniz. Hesap bilgileriniz silme komutundan sonra 30 gün süreyle saklanacaktır. Tekrar giriş yaparak silme işlemini iptal edebilirsiniz.",
                DELETE_YOUR_ACCOUNT_INFORM: "Hesabınızı silmek için kutuya 'permanent-delete' yazabilirsiniz.",
                DELETE_CHECK: "Kontrollü Sil:",
                DELETE_CHECK_FEEDBACK: "Lütfen aşağıdaki kutuya 'permanent-delete' yazın.",
                DELETE_ACCOUNT: "Hesabı Silin",
            }
        }
    },
    USERS_INTEGRATIONS: { //done
        TITLE: "KULLANICI ENTEGRASYONLARI",
        DESCRIPTION: "Entegrasyonlarınızı yönetebilirsiniz",
        GDRIVE_INTEGRATION_TITLE: "Google Drive'dan İçe Aktarma Entegrasyonu",
        ONEDRIVE_INTEGRATION_TITLE: "OneDrive'dan İçe Aktarma Entegrasyonu",
        SHAREPOINT_INTEGRATION_TITLE: "SharePoint'den İçe Aktarma Entegrasyonu",
        GDRIVE_INTEGRATION_DESCRIPTION: "Google Drive'daki dokümanları içe aktarmak için hesabınızı Google Drive ile entegre edebilirsiniz.",
        ONEDRIVE_INTEGRATION_DESCRIPTION: "OneDrive'daki dokümanları içe aktarmak için hesabınızı OneDrive ile entegre edebilirsiniz.",
        SHAREPOINT_INTEGRATION_DESCRIPTION: "SharePoint'teki dokümanları içe aktarmak için hesabınızı SharePoint ile entegre edebilirsiniz.",
        CONNECT: "Bağlan",
        CONNECTED: "Bağlantıyı Kes",
        REMOVE_CONNECTION: "Bağlantıyı Kaldır",
    },
    POPUPS: {
        FILE_PERMISSON: {
            DRIVE: {
                TITLE: "Google Drive İzinleri",
                READ: "Görüntüleme",
                WRITE: "Düzenleme",
                OWNER: "Sahip",
                DOMAIN: "Domain",
                USER: "Kullanıcı",
                ADD_PERMISSION: "İzin Ekle",
                ADD_USER: "Kullanıcı Ekle",
                ADD: "Ekle",
                NOTIFY_USER: "Kullanıcıya Bildir",
                PEOPLE_WITH_ACCESS: "Erişimi olan kişiler",
                INVALID_EMAIL: "Geçersiz e-posta adresi. Lütfen geçerli bir e-posta adresi girin.",
            }
        },
        ACCEPT_OR_CLOSE: {
            ACCEPT: "Kabul Et",
            CANCEL: "İptal Et",
        },
        ADD_CONTRACT: {
            NEW_CONTRACT: "Yeni Süreç",
            NEXT: "İleri",
        },
        ADD_CUSTOM_FIELD: {
            NEW_CUSTOM_FIELD: "Yeni Özel Alan"
        },
        ADD_USER_TO_COMPANY: {
            INVITE_USER_TO_COMPANY: "Şirkete Kullanıcı Davet Edin",
            INVITE_USER_TO_COMPANY_DESCRIPTION: "Takım arkadaşlarınızı davet edin ve iş akışınızı planlamaya başlayın.",
            NO_EMAIL_ADDRESS_ADDED: "E-posta adresi eklenmedi",
            INVITE_VIA_EMAIL: "E-posta ile davet edin",
            ADD_NEW_EMAIL: "Yeni e-posta ekle",
            PROVIDE_VALID_EMAIL: "Lütfen geçerli bir e-posta girin",
            SELECT_ROLES: "Listeden Rolü seçin...",
            FINISH: "Tamamlandı",
        },
        CDN: {
            SELECT_SOURCE: 'Dokümanları yükleyebilmek için bir depolama alanını seç',
            UPLOAD: 'Dokümanları Yükle',
            ADD_NEW: 'Yeni Ekle',
            FROM_COMPUTER: 'Bilgisayardan içeri aktar',
            FROM_CLOUD: 'Buluttan içeri aktar',
            JETLEXA_ON_PREM: 'onedocs On-premises Depolama',
            AWS_S3: 'Onedocs Depolama',
            GOOGLE_DRIVE: 'Google Drive Depolama',
            ONE_DRIVE: 'OneDrive Depolama',
            SHARE_POINT: 'SharePoint Depolama',
            UPLOADING_TITLE: 'Dokümanlar seçilen depolama alanına ekleniyor',
            UPLOADED_TITLE: "Dokümanlar yüklendi. Dokümanlar için süreç, versiyon veya etiket ekleyebilirsiniz",
            FINISH: 'Tamamla',
            IMPORTING: 'Dokümanlar depolama alanından indiriliyor. İndirme işlemi bitene kadar bekleyin...',
            FROM_CLOUD_TITLE: 'Aşağıdaki bir depolama alanından doküman içeri aktar (Maximum 20 doküman)',
            CONTINUING_UPLOAD: 'Yükleme işlemi devam ediyor...',
            COMPLETED_UPLOAD: 'Yükleme işlemi tamamlandı',
            INTEGRATE_WITH_GDRIVE: 'Lütfen şirket adminine başvurarak şirketi Google Drive ile entegre etmesini isteyin',
            INTEGRATE_WITH_ONEDRIVE: 'Lütfen şirket adminine başvurarak şirketi OneDrive ile entegre etmesini isteyin',
            INTEGRATE_WITH_SHAREPOINT: 'Lütfen şirket adminine başvurarak şirketi SharePoint ile entegre etmesini isteyin',
            SELECT_SOURCE_WARNING: "Dokümanları yükleyebilmek için bir depolama alanı seçmelisiniz.",
            SOMETHING_IS_WRONG: "İçeri alma sistemimizle ilgili bir şeyler ters gitti. Lütfen daha sonra tekrar deneyin.",
            ONEDRIVE_IMPORT_ACCOUNT_PROBLEM: 'OneDrive import hesabının onedrive.com üzerinde açık halde bırakılması gerekmektedir',
        },
        COMPANY_ONBOARDING: {
            NO_COMPANY_FOUND: "Lütfen bir Çalışma Alanı oluşturun veya bir çalışma alanına katılın",
            COMPANY_ONBOARDING: "Şirket Hesabı Oluşturma",
            TITLE_LABEL: "Çalışma Alanı Adı:",
            TITLE_PLACEHOLDER: "Çalışma Alanı adını girin...",
            TITLE_FEEDBACK: "Lütfen geçerli bir Çalışma Alanı adı girin",
            WEBSITE_LABEL: "Şirketinizin Websitesi:",
            WEBSITE_PLACEHOLDER: "Şirketinizin web sitesini girin...",
            WEBSITE_FEEDBACK: "Lütfen geçerli bir web sitesi girin",
            DESCRIPTION_LABEL: "Şirket Hakkında:",
            DESCRIPTION_PLACEHOLDER: "Şirket hakkında bilgi girin...",
            DESCRIPTION_FEEDBACK: "Lütfen şirket hakkında geçerli bilgi girin.",
            WHAT_IS_YOUR_ROLE_LABEL: "Şirketteki rolünüz nedir?",
            WHAT_IS_YOUR_ROLE_PLACEHOLDER: "Şirketteki rolünüzü girin...",
            WHAT_IS_YOUR_ROLE_FEEDBACK: "Lütfen geçerli bir rol girin.",
            WHAT_TO_DO_WITH_SYSTEM_LABEL: "Bu uygulamayı ne için kullanmak istiyorsunuz?",
            WHAT_TO_DO_WITH_SYSTEM_PLACEHOLDER: "Uygulamayı ne için kullandığınızı girin...",
            WHAT_TO_DO_WITH_SYSTEM_FEEDBACK: "Lütfen uygulamayı ne için kullandığınızı belirtin",
            SIZE_OF_COMPANY_LABEL: "Şirketinizde kaç çalışan bulunuyor?",
            SIZE_OF_COMPANY_PLACEHOLDER: "Çalışan sayısını girin...",
            SIZE_OF_COMPANY_FEEDBACK: "Lütfen geçerli çalışan sayısını girin.",
            USAGE_SIZE_LABEL: "Bu uygulamayı şirketinizden kaç kişi kullanacaktır?",
            USAGE_SIZE_PLACEHOLDER: "Kaç kullanıcı olacağını belirtin...",
            USAGE_SIZE_FEEDBACK: "Lütfen bu uygulamayı kaç kişinin kullanacağını belirtin.",
            NEXT: "İleri",
            PREVIOUS: "Geri",
            FINISH: "Çalışma Alanı Oluştur",
            CREATING: "Çalışma Alanı oluşturuluyor...",
            JOINING: "Çalışma Alanına katılıyorunuz...",
            JOIN_COMPANY: "Mevcut bir çalışma alanına katılın ve birlikte çalışmaya başlayın!",
            CREATE_COMPANY: "Yeni bir şirket oluşturun ve süreçlerinize başlayın!",
            JOIN: "Şirkete Katıl",
            INVITE_CODE_LABEL: "Davet Kodu",
            INVITE_CODE_PLACEHOLDER: "Davet kodunu girin...",
        },
        COMPANY_SWITCH: {
            COMPANY_SWITCH: "Devam Edeceğiniz Çalışma Alanını Seçin",
            CHOOSE_COMPANY_DESCRIPTION: "Çalışmaya başlamak için mevcut çalışma alanınızı seçin!",
            NO_COMPANY_FOUND: "Çalışma Alanı bulunamadı",
        },
        ROLES_MANAGEMENT: {
            EDIT_ROLE: "Rolü Düzenle",
            DELETE_ROLE: "Rolü Sil",
            SAVE: "Kaydet",
            FIELD: "Alan",
            VALUE: "Değer",
            DELETE_ROLE_DESCRIPTION: "Rolün silinmesinden emin misiniz?",
            FIELDS: {
                index: "Dizin",
                _id: "Benzersiz ID",
                title: "Rol Başlığı",
                company: "Çalışma Alanı",
                nominations: "İzinler",
                createdAt: "Oluşturma Tarihi"
            },
            ACCEPT_OR_CLOSE_TITLE: "Rolü Sil",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Rolün silinmesinden emin misiniz?"
        },
        ROLES_NEW: {
            ADD_NEW_ROLE: "Çalışma Alanınıza Yeni Rol Ekleyin",
            TITLE_LABEL: "Rol Adı",
            TITLE_PLACEHOLDER: "Rolün adını girin...",
            TITLE_FEEDBACK: "Lütfen geçerli bir rol adını girin",
            NOMINATIONS_OF_ROLE_LABEL: "Rol için İzinler (Zorunlu)",
            NOMINATIONS_OF_ROLE_PLACEHOLDER: "Rol için izinleri seçin.",
            CREATE: "Oluştur",
        },
        UPLOAD_FILES: {
            UPLOAD_STATUS: "Yükleme Durumu",
            FILENAME: "Doküman adı",
            CONTRACT: "Süreç",
            VERSION_OF: "Dokümanın Eki",
            TAGS: "Etiketler",
            FILE_SIZE: "Doküman Boyutu",
            LOADING: "Yükleniyor...",
            TAGS_PLACEHOLDER: "Virgülle ayrılmış etiketleri girin",
            SEPERATE_TAGS_WITH_COMMAS: "Etiketleri virgülle ayırın",
            COMPANY_NOT_INTEGRATED_WITH_DRIVE: "Çalışma Alanı, Google Drive ile entegre değil",
            COMPANY_NOT_INTEGRATED_WITH_DRIVE_DESCRIPTION: "Google Drive'ı entegre etmek için lütfen Çalışma Alanı yöneticinizle iletişime geçin.",
            UPLOAD_FILES_MAX_20: "Dokümanlarınızı yükleyin (En fazla 20 doküman)",
            ERROR: "Hata",
            UPLOAD: "Yükle",
            FINISH: "Tamamlandı",
            UPLOAD_WINDOW_GETTING_READY_FOR_YOU: "Yükleme penceresi sizin için hazırlanıyor...",
            DROP_FILES_OR_CLICK: "Dokümanları buraya bırakın veya yüklemek için tıklayın.",
            DROP_FILES_OR_CLICK_DESCRIPTION: "Sürükleyerek veya tıklayarak bu ekranda en fazla 20 doküman yükleyebilirsiniz.",
            FILES_ARE_BEING_DOWNLOADED_FROM_DRIVE: "Dokümanlar Google Drive'ınızdan indiriliyor ve bir yükleme prosedürü başlatılıyor.",
            INTEGRATION_COMPLETED_GOOGLE: "Entegrasyon tamamlandı. Şimdi Google Drive'dan yükleyin.",
            INTEGRATION_COMPLETED_ONEDRIVE: "Entegrasyon tamamlandı. Şimdi OneDrive'dan yükleyin.",
            INTEGRATION_COMPLETED_SHAREPOINT: "Entegrasyon tamamlandı. Şimdi Sharepoint'den yükleyin.",
            ADD_FILES_FROM_GOOGLE_DRIVE: "Google Drive'dan doküman ekleyin",
            DRAFT: "Taslak",
            COMPLETED: "Tamamlanan",
            TEMPLATE: "Şablon",
            STATUS: "Durum",
            IN_REVIEW: "İncelemede",
            APPROVED: "Onaylandı",
            REJECTED: "Reddedildi",
        },
        USERS_INVITES_MANAGEMENT: {
            UPDATE_CURRENT_USER_INVITE: "Mevcut Kullanıcı Davetini Güncelle",
            DELETE_INVITE: "Daveti Sil",
            FIELD: "Alan",
            VALUE: "Değer",
            SAVE: "Kaydet",
            FIELDS: {
                index: "Dizin",
                _id: "Benzersiz ID",
                email: "Eposta",
                createdAt: "Oluşturma Tarihi",
                inviteCode: "Davet Kodu",
                status: "Durum",
            },
            ACCEPT_OR_CLOSE_TITLE: "Daveti Sil",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Daveti silmek istediğinize emin misiniz?",
        },
        USERS_MANAGEMENT: {
            EDIT_COMPANY_USER: "Şirket Kullanıcısını Düzenle",
            REMOVE_USER: "Kullanıcıyı Kaldır",
            SAVE: "Kaydet",
            FIELD: "Alan",
            VALUE: "Değer",
            SELECT_ROLES_PLACEHOLDER: "Kullanıcı için rol seçin.",
            FIELDS: {
                index: "Dizin",
                _id: "Benzersiz ID",
                name: "Ad",
                surname: "Soyadı",
                email: "Eposta",
                phoneNumber: "Telefon Numarası",
                createdAt: "Oluşturma Tarihi",
                username: "Kullanıcı Adı",
                role: "Rol"
            },
            DEFAULT_FILE_PERMISSIONS: {
                READ: "Görüntüleme",
                EDIT: "Düzenleme",
                NOT_ALLOWED: "İzin Yok",
                OWNER: "Sahip",
            },
            ACCEPT_OR_CLOSE_TITLE: "Kullanıcıyı Çalışma Alanından Kaldır",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Kullancıyı Çalışma Alanından kaldırmak istediğinize emin misiniz?",
        }
    },
    SELECTS: {
        CONTRACTS: {
            SEARCH_CONTRACTS: "Süreç Seç",
            SEARCH_COMPANY: "Taraf Seçiniz",
            CONTRACT_CHANGE_SUCCESS: "Süreç değiştirme başarılı!",
        },
        FILES: {
            SEARCH_FILES: "Doküman Seç",
        }
    },
    UNAUTHORIZED_PAGES: {
        UNAUTH_LAYOUT: {
            DESCRIPTION: "Süreç Yönetimi için en kolay, en hızlı ve en güvenli platformdur.",
            AUTHOR: "onedocs EKİBİ"
        },
        LOGIN: {
            WELCOME_BACK: "Tekrar hoşgeldiniz!",
            LOGIN_DESCRIPTION: "onedocs'a erişmek için e-posta adresinizi ve şifrenizi girin",
            DONT_HAVE_ACCOUNT: "Hesabınız yok mu?",
            SIGN_UP: "Kaydol",
            EMAIL_FEEDBACK: "Lütfen geçerli bir e-posta girin",
            PASSWORD_FEEDBACK: "Lütfen geçerli bir şifre girin",
            PASSWORD_OR_EMAIL_INCORRECT: "Eposta veya şifre yanlış.",
            PLEASE_LOGIN: "Lütfen giriş yapın veya",
            REGISTER: "kaydolun",
            TO_CONTINUE_WITH: "davetinize devam etmek için",
            LOGIN_SUCCESSFUL: "Başarıyla giriş yapıldı, yönlendiriliyor...",
            EMAIL_LABEL: "Eposta Adresi",
            EMAIL_PLACEHOLDER: "Eposta adresinizi girin...",
            PASSWORD_LABEL: "Şifre",
            PASSWORD_PLACEHOLDER: "Şifrenizi girin..",
            LOGIN: "Giriş Yap",
            REMEMBER_ME: "Beni Hatırla",
            FORGOT_PASSWORD: "Şifrenizi mi unuttunuz?",
        },
        LOGOUT: {
            SEE_YOU_AGAIN: "Başarıyla çıkış yaptınız",
            DESCRIPTION: "Başarıyla çıkış yaptınız, onedocs'u ziyaret ederek istediğiniz zaman giriş yapabilirsiniz.",
            BACK_TO: "Geri dön",
            LOG_IN: "Giriş yap",
        },
        REGISTER: {
            CREATE_ACCOUNT: "Hesabını Oluştur",
            CREATE_ACCOUNT_DESCRIPTION: "Hesabınızı oluşturun ve Çalışma Alanı hesabınızı açın.",
            NAME_LABEL: "İlk Adı:",
            NAME_PLACEHOLDER: "İlk adı girin...",
            NAME_FEEDBACK: "Lütfen geçerli bir ilk adı girin",
            SURNAME_LABEL: "İkinci Ad:",
            SURNAME_PLACEHOLDER: "İkinci adı girin...",
            SURNAME_FEEDBACK: "Lütfen geçerli bir ikinci adı girin.",
            PHONENUMBER_LABEL: "Telefon Numarası:",
            PHONENUMBER_PLACEHOLDER: "54X XXX XX XX",
            PHONENUMBER_FEEDBACK: "Lütfen geçerli bir telefon numarası girin.",
            EMAIL_LABEL: "Eposta Adresi:",
            EMAIL_PLACEHOLDER: "Eposta adresi girin...",
            EMAIL_FEEDBACK: "Lütfen geçerli bir eposta adresi girin",
            PASSWORD_LABEL: "Şifre:",
            PASSWORD_PLACEHOLDER: "Şifreyi girin...",
            PASSWORD_FEEDBACK: "Lütfen geçerli bir şifre girin.",
            PASSWORD_MINIMUM_LENGTH_FEEDBACK: "Şifreniz 5 karakterden uzun olmalıdır.",
            PASSWORD_MAXIMUM_LENGTH_FEEDBACK: "Şifreniz 25 karakterden uzun olmamalıdır.",
            PASSWORD_MINIMUM_CHARACTER_FEEDBACK: "Şifreniz en az 6 karakter içermelidir.",
            PASSWORD_MINUMUM_ONE_UPPERCASE_ONE_LOWER_CASE_FEEDBACK: "Şifreniz bir büyük harf, bir küçük harf içermelidir",
            PASSWORD_SPECIAL_CHARACTER_FEEDBACK: "Şifreniz en az bir özel karakter içermelidir",
            PASSWORD_NUMBER_FEEDBACK: "Şifreniz en az bir sayı içermelidir",
            ACCEPT_TERMS_AND_SERVICES_LABEL: "Şartları ve hizmetleri kabul ediyorum",
            ACCEPT_TERMS_AND_SERVICES_FEEDBACK: "Lütfen şartları ve hizmetleri kabul edin",
            REGISTER: "Kaydol",
            SHOW_PASSWORD: "Şifreyi Göster",
            HIDE_PASSWORD: "Şifreyi Gizle",
            ALREADY_HAVE_ACCOUNT: "Already have an account?",
            LOGIN: "Giriş Yap",
            ALERT_REGISTRATION_COMPLETE: "Süreçler başarılı, sonraki sayfaya yönlendirileceksiniz",
            ALERT_INVITE_CODE: "Davetinize devam etmek için lütfen kaydolun",
            ATTEMPTED: "Denendi:",
            REGISTER_CODE: "Süreç Kodu",
        },
        CONFIRM: {
            TITLE: "Email adresini onayla",
            DESCRIPTION: "Hesabınız başarıyla kaydedildi. Doğrulama işlemini tamamlamak için lütfen doğrulama isteği için e-postanızı kontrol edin.",
            BACK_TO: "Geri Dön",
            LOGIN: "Giriş Yap"
        },
        CONFIRM_SUCCESSFUL: {
            GO_TO: "Git",
            MAIL_CONFIRMATION_SUCCESS: "Posta Onayı Başarılı!",
            DESCRIPTION: "Onaylanan mailiniz başarılı, onedocs'a gidebilirsiniz."
        },
        CONFIRM_FAILED: {
            GO_TO: "Git",
            LOGIN: "Giriş Yap",
            MAIL_CONFIRMATION_FAILED: "Posta Onayı Başarısız!",
            DESCRIPTION: "Posta onayınız başarısız oldu, doğru url için posta kutunuzu kontrol edebilir veya sayfanın altındaki buton aracılığıyla destek ile iletişime geçebilirsiniz."
        },
        FORGET_PASSWORD: {
            TITLE: "Şifremi Unuttum",
            DESCRIPTION: "E-posta adresinizi girin, size şifrenizi sıfırlama talimatlarını içeren bir e-posta gönderelim.",
            GO_TO: "Git",
            LOGIN: "Giriş Yap",
            EMAIL_LABEL: "Eposta Adresi",
            EMAIL_PLACEHOLDER: "Eposta adresi girin...",
            EMAIL_FEEDBACK: "Lütfen geçerli bir eposta adresi girin.",
            ALERT_SUCCESSFULLY_SENT: "E-posta başarıyla e-posta adresine gönderildi. Bir sonraki adıma geçmek için lütfen bağlantıyı tıklayın",
            SEND: "Eposta Gönder",
        },
        RESET_PASSWORD: {
            CHANGE_PASSWORD: "Şifreyi Değiştir",
            CHANGE_PASSWORD_DESCRIPTION: "Aşağıdaki formu doldurarak mevcut şifrenizi değiştirebilir ve onedocs'a giriş yapabilirsiniz.",
            BACK_TO: "Geri Git",
            LOGIN: "Giriş Yap",
            PASSWORDS_DOESNT_MATCH: "Şifre eşleşmiyor",
            PASSWORD_LABEL: "Şifre:",
            CHECK_PASSWORD_LABEL: "Şifre Tekrarı:",
            PASSWORD_PLACEHOLDER: "Şifrenizi girin...",
            CHECK_PASSWORD_PLACEHOLDER: "Şifrenizi tekrar girin...",
            PASSWORD_FEEDBACK: "Lütfen geçerli bir şifre girin.",
            PASSWORD_MINIMUM_LENGTH_FEEDBACK: "Şifreniz 5 karakterden uzun olmalıdır.",
            PASSWORD_MAXIMUM_LENGTH_FEEDBACK: "Şifreniz 25 karakterden uzun olmamalıdır.",
            PASSWORD_MINIMUM_CHARACTER_FEEDBACK: "Şifreniz en az 6 karakter içermelidir.",
            PASSWORD_MINUMUM_ONE_UPPERCASE_ONE_LOWER_CASE_FEEDBACK: "Şifreniz bir büyük harf, bir küçük harf içermelidir",
            PASSWORD_SPECIAL_CHARACTER_FEEDBACK: "Şifreniz en az bir özel karakter içermelidir",
            PASSWORD_NUMBER_FEEDBACK: "Şifreniz en az bir sayı içermelidir",
            ALERT_CHANGE_PASSWORD: "Aşağıdaki formu doldurarak şifrenizi değiştirebilirsiniz",
            SHOW_PASSWORD: "Şifreyi Göster",
            HIDE_PASSWORD: "Şifreyi Gizle"
        },
        LOCK_SCREEN: {
            HI: "Merhaba, ",
            DESCRIPTION: "onedocs'a erişmek için şifrenizi tekrar girin",
            PASSWORD_LABEL: "Şifre:",
            PASSWORD_PLACEHOLDER: "Şifrenizi girin...",
            PASSWORD_FEEDBACK: "Lütfen geçerli bir şifre girin.",
            LOGIN: "Giriş",
            NOT_YOU: "Siz değil misiniz? Buradan devam edin",
            ALERT_LOGIN_SUCCESSFUL: "Başarıyla giriş yapıldı, onedocs'a yönlendiriliyor...",
            ATTEMPTED: "Denendi:",
            MESSAGE: "Şifre veya eposta yanlış."
        }
    },
    MAIL_INBOX: {
        TITLE: "Gelen Kutusu",
        NEW_CONNECTION: "Yeni Bağlantı",
        DISCONNECT: "Bağlantıyı Kes",
        ALL: "Tümü",
        IMPORTED: "İçe aktarılan",
        MAIL_TEXT: "Posta İçeriği",
        IMPORT: "İçe Aktar",
        SELECT_EMAIL: "Eposta Seç",
        IMPORT_SELECTED_FILES: "Seçilen dokümanları içe aktar",
        CONNECT_TO_EMAIL: "E-Posta Adresinize Bağlanın",
        ALL_DATA_LOADED: "Tüm veriler yüklendi",
        ARE_YOU_SURE: "Emin misiniz?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Eposta adresinizi ile bağlantınız kesilecek, bu işlem geri alınamaz.",
        UPLOADING_FILES: "Dokümanlar Yükleniyor",
        UPLOADING_FILES_DESCRIPTION: "Dokümanlar yükleniyor, lütfen bekleyiniz...",
        CANCEL: "İptal",
        DELETE: "Sil",
        FILES_UPLOADED_SUCCESSFULLY: "Dokümanlar başarıyla yüklendi",
        MAXIMUM_20_FILES: "Maksimum 20 doküman yükleyebilirsiniz",
    },
    CUSTOMFORMS: {
        CUSTOMFORMS_EDIT_POPUP: {
            FORM_STATUS_ACTIVE: 'Aktif',
            FORM_STATUS_PASSIVE: 'Pasif',
            FORM: 'Form',
            TEMPLATES: 'Şablonlar',
            SETTINGS: 'Ayarlar',
            SHARE_FORM: 'Paylaş',
            SAVE: 'Kaydet',
            FORM_ELEMENTS: 'Form Elementleri',
            HEADER: 'Başlık',
            DIVIDER: 'Ayraç',
            FIELD_SETTINGS: 'Alan Ayarları',
            FIELD: 'Alan',
            TITLE: 'Başlık',
            LABEL: 'Etiket',
            DESCRIPTION: 'Açıklama',
            HELP_TEXT: 'Yardım Metni',
            REQUIRE: 'Doldurulması zorunlu alan',
            ENTER_DESCRIPTION: 'Bir açıklama giriniz...',
            DEFAULT_VALUE: 'Varsayılan Değer',
            AVAILABLE_TEMPLATES: 'Kullanılabilir Şablonlar',
            SELECTED_TAMPLATES: 'Seçilen Şablonlar',
            FORM_STATUS: 'Form Durumu',
            SHARE: 'Paylaş',
            COPY_LINK: 'Kopyala',
            LINK_COPIED: 'Link kopyalandı!',
            CODE_COPIED: 'Kod kopyalandı',
            PREVIEW: 'Form Ön İzleme',
            SEARCH_TEMPLATE_PLACEHOLDER: 'Kullanılabilir şablonlarda ara...',
            CUSTOM_FORMS_TITLE: 'Özel Formlar',
            CUSTOM_FORMS_DESCRIPTION: 'Özel bir form oluşturun, şablonlarla ilişkilendirin. Belgelerinizi otomatik olarak oluşturun...',
            CREATE_FORM: 'Form Oluştur',
            FORM_PASSIVE_MESSAGE: 'Bu form şu anda mevcut değil.',
            SUBMIT: 'Gönder',
            RESET: 'Sıfırla',
            ARE_YOU_SURE_DESCRIPTION: 'Formu kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?',
            OPEN_FORM: 'Formu Aç',
            PROVIDE_FORM_TITLE: 'Form başlığını girin',
            CREATING_DOCUMENT: 'Form oluşturuluyor',
            INVALID_EMAIL: 'Geçersiz e-posta adresi',
            SEND_EMAIL: 'E-posta gönder',
            RECEIVERS: 'Alıcılar',
            MESSAGE_TEXT: 'Mesaj metni',
            INVALID_MESSAGE_TEXT: 'Geçersiz mesaj metni. Mesaj metni boş bırakılamaz.',
            CONFIRM_AND_SEND: 'Gönder',
            CUSTOM_FIELDS: 'Custom Fields',
            NEW_CUSTOM_FIELDS: 'Yeni Özel Alan',
            SELECTED_CUSTOM_FIELD: 'Seçili Özel Alan',
        }
    },
    MONTHS: {
        jan: 'Ocak',
        feb: 'Şubat',
        mar: 'Mart',
        apr: 'Nisan',
        may: 'Mayıs',
        jun: 'Haziran',
        jul: 'Temmuz',
        aug: 'Ağustos',
        sep: 'Eylül',
        oct: 'Ekim',
        nov: 'Kasım',
        dec: 'Aralık'
    },
    DASHBOARD: {
        DATE_TYPES: {
            startDate: 'Başlangıç Tarihi',
            endDate: 'Bitiş Tarihi',
            effectiveDate: 'Yürürlük Tarih',
            renewalDate: 'Yenileme Tarihi',
            reminderDate: 'Etkinlik Tarihi',
        },
        CHARTS: {
            NUMBER_OF_CONTRACTS_ADDED: 'Eklenen Süreç Sayısı',
        }
    },
    APPROVE_TEMPLATES: {
        NAME: 'Kayıtlı Akışlarım',
        TITLE: 'Kayıtlı Akışlarım',
        TEMPLATE_NAME: 'Kayıtlı Akışın Adı',
        REACH_ERROR: 'Onay şablonu oluşturulamadı, lütfen sayfa yenileyerek tekrar deneyin.',
        TABLE: {
            NAME: 'Kayıtlı Akışlarım',
            TITLE: 'Kayıtlı Akışın Adı',
            CREATED_AT: 'Oluşturma Tarihi',
            APPROVERS: 'Alıcılar',
            STEPS: 'Adımlar',
            ACTIONS: 'Aksiyonlar',
            EDIT: 'Düzenle',
            DELETE: 'Sil',
            CREATE: 'Oluştur',
        },
        CREATE_MODAL: {
            TITLE: 'Kayıtlı Akış Oluştur',
            NAME: 'Akış Adı',
            NAME_PLACEHOLDER: 'Akış adını girin',
            NAME_ERROR: 'Akış adı boş bırakılamaz',
            CREATE_BUTTON: 'Oluştur',
            CREATING_BUTTON: 'Oluşturuluyor...',
        },
        DELETE_MODAL: {
            TITLE: 'Onay Şablonu Sil',
            DESCRIPTION: 'Onay şablonunu kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?',
            DELETE_BUTTON: 'Sil',
            CANCEL_BUTTON: 'İptal',
        },
        APPROVE_TEMPLATES_GENERIC_MODAL: {
            TITLE: 'Onay Şablonu Düzenle',
            NAME: 'Şablon Adı',
            STEP_NOT_FOUND: 'Adım bulunamadı, sayfayı yenileyerek tekrar deneyin.',
            EMAIL_VALIDATION_ERROR: 'Geçersiz e-posta adresi',
            SAVING: 'Kaydediliyor...',
            SAVE: 'Kaydet',
            SAVED: 'Kaydedildi',
            STEP: 'Adım',
            DELETE: 'Şablonu Sil',
            CAN_CHANGE_REQUEST: "Revize edebilir",
            CAN_ASSIGN: "Başkasına atayabilir",
            APPROVE_FROM: 'Hangi alıcıların kararı gerekli?',
            APPROVE_FROM_ALL: "Hepsinin",
            APPROVE_FROM_ONE: "Herhangi birinin",
            ADD_STEP: 'Adım Ekle',
            RECIPIENT_EMAIL: 'Alıcı E-posta adresi giriniz',
        },
    },
    CUSTOM_FIELDS: {
        TOUSHANDS_SEPARATOR: 'Binler ayırıcı nokta (.) ve ondalık ayırıcı virgül (,) olmalıdır.',
    },
    APPROVE_PROCESS: {
        PAGE_TITLE: 'İş Akış Yönetimi',
        TABLE: {
            TITLE: 'Akış Adı',
            STATUS: 'Durum',
            VERSIONS: 'Versiyonlar',
            LAST_ACTION_DATE: 'Son İşlem Tarihi',
            LAST_ACTION_USER: 'Son İşlem Yapan',
            NOTES_COMMENT: 'Notlar/Yorumlar',
            TIME_TRACKING: 'Zaman Takibi',
            TIME_TRACKING_APPROVED: '{{time}} sürdü',
            TIME_TRACKING_PENDING: '{{time}} önce başladı',
            SHOW: 'Göster',
            DELETE: 'Sil',
            NAV: {
                APPROVE_PROCESS: 'Tüm İş Akışları',
                APPROVAL_FLOW_TEMPLATES: 'Kayıtlı İş Akışlarım',
            }
        },
        APPROVE_PROCESS_POPUP: {
            TITLE: 'İş Akışı',
            APPROVE: 'Onayla',
            ACCEPT: 'Kabul Et',
            REJECT: 'Reddet',
            REJECT_AND_COMPLATE: 'Reddet ve Akışı Sonlandır',
            REJECT_AND_SUGGEST_CHANGES: 'Reddet ve Değişiklik Öner',
            ASSIGN_TO_SOMEONE_ELSE: 'Başkasına Ata',
            START_APPROVE_PROCESS: 'İş Akışını Başlat',
            APPROVED: 'Onaylandı',
            REJECTED: 'Reddedildi',
            PENDING: 'Bekliyor',
            CREATED: 'Oluşturuldu',
            CANCELED: 'Vazgeçildi',
            REASSIGNED: 'Başkasına Atadı',
            CHANGE_REQUESTED: 'Değişiklik Önerdi',
            NOT_STARTED: 'Karar Bekleniyor',
            STEP: 'Adım',
            FILE: 'Doküman',
            START_APPROVE_PROCESS_AGAIN: 'İş Akışını Tekrar Başlat',
            VERSIONS: 'Versiyonlar',
            COMPARE: 'Karşılaştır',
            APPROVE_MODAL: {
                TITLE: 'Onayla',
                DESCRIPTION: 'Şu anda bu belgeyi onaylamak üzeresiniz. Onaylama adımını tamamlamak için "Gönder" butonuna basınız.',
                SEND: 'Gönder',
                MESSAGE_INPUT_LABEL: 'Mesaj Metni',
                MESSAGE_INPUT_PLACEHOLDER: 'Onaylamadan önce bu alana bir metin ekleyebilirsiniz. Bu metin onay adımındaki ilgili alıcıya iletilecektir.',
            },
            REJECT_COMPLATE_MODAL: {
                TITLE: 'Reddet ve Sonlandır',
                DESCRIPTION: 'Şu anda bu belgeyi reddetmek üzeresiniz. Ret adımını tamamlamak için aşağıdaki alana ret nedeninizi girip "Gönder" butonuna basınız.',
                SEND: 'Gönder',
                MESSAGE_INPUT_LABEL: 'Mesaj Metni (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'En az 10 karakter olacak şekilde ret nedeninizi giriniz. Bu metin onay adımındaki ilgili alıcıya iletilecektir.',
            },
            REASSIGN_MODAL: {
                TITLE: 'Başkasına Ata',
                DESCRIPTION: 'Bu belgeyi başkasına atamak üzeresiniz. Aşağıdaki yer alan kutucuğa, belgeyi atamak istediğiniz kişinin e-posta adresini ve mesaj metni alanına açıklamanızı girip "Gönder" butonuna basınız.',
                SEND: 'Gönder',
                RECEIVER_INPUT_LABEL: 'Alıcı',
                RECEIVER_INPUT_PLACEHOLDER: 'Alıcı e-posta adresini giriniz',
                MESSAGE_INPUT_LABEL: 'Mesaj Metni (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'Başkasına atama nedeninizi içeren bir açıklama giriniz. Açıklama en az 10 karakter olmalıdır.',
            },
            REJECT_CHANGE_MODAL: {
                TITLE: 'Reddet ve Değişiklik Öner',
                DESCRIPTION: 'Değişiklik talep etmek istediğiniz belgeyi aşağıdan indirebilir, “Değişiklikleri İzle” açık olacak şekilde revize edebilirsiniz.',
                SEND: 'Gönder',
                MESSAGE_INPUT_LABEL: 'Mesaj Metni (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'Bu alana reddetme ve değişiklik önerme nedeninizi içeren bir açıklama giriniz. Açıklama en az 10 karakter olmalıdır.',
                FILE_INPUT_LABEL: 'Doküman Yükleme (*)',
                FILE_INPUT_PLACEHOLDER: 'Değişiklik yaptığınız yeni belgeyi buraya sürükleyebilir veya “Yükle” butonuna basarak seçebilirsiniz.',
                SELECT_FILE: 'Doküman Seç',
            },
            USER_STATUSES: {
                APPROVED: 'Onaylandı',
                REJECTED: 'Reddedildi',
                PENDING: 'Onayı Bekleniyor',
                CREATED: 'Oluşturuldu',
                CANCELED: 'Vazgeçildi',
                REASSIGNED: 'Başkasına Atadı',
                CHANGE_REQUESTED: 'Değişiklik Önerdi',
                QUEUE: 'Sırası Bekleniyor',
                NOT_NECESSARY: 'İptal',
            },
            FILE_MESSAGES: {
                FROM_ANYONE_MESSAGE: 'Adımdaki herhangi birinin onayı gerekli',
                FROM_EVERYONE_MESSAGE: 'Adımdaki herkesin onayı gerekli',
                FILE_UPLOADED: 'Yeni doküman yüklendi.',
                NO_START_FILE_REJECT: '{{ownerName}}, yeni doküman reddedip değişiklik önerdi.',
                NO_START_FILE_REJECT_COMPLATE: '{{ownerName}}, yeni dokümanı reddederek iş akışını sonlandırdı.',
                NO_START_FILE_PROCESS: '{{ownerName}}, yeni doküman için onayı başlatması bekleniyor.',
                CONTINUE_FROM_SCRATCH: '{{ownerName}} {{startDate}} tarihinde yeni doküman için iş akışını yeniden başlattı.',
                CONTINUE_FLOW: '{{ownerName}} {{startDate}} tarihinde yeni doküman için mevcut iş akışına devam etti.',
                CONTINUE_NEW_FLOW_FIRST: '{{ownerName}} {{startDate}} tarihinde iş akışını başlattı.',
                CONTINUE_NEW_FLOW: '{{ownerName}} {{startDate}} tarihinde yeni doküman için yeni bir iş akışı başlattı.',
                RESEND_MAIL_SUCCESS: 'Onay vermesi beklenen kişilere hatırlarma maili başarıyla gönderildi.',
            },
            USER_MESSAGES: {
                SUGGECTED_CHANGE_MESSAGE: 'Değişiklik Öner ile bu versiyonun iş akışı sona ermiş ve bu onay kişisinin seçimine gerek kalmamıştır.',
                REJECTED_MESSAGE: 'Bu versiyonun iş akışı reddedilerek sona ermiş ve bu onay kişisinin seçimine gerek kalmamıştır.',
                APPROVE_ANYONE_MESSAGE: 'Adımdaki herhangi birinin onayı gerekli olduğundan bu onay kişisinin seçimine gerek kalmamıştır.',
            }
        },
        APPROVE_PROCESS_DELETE_MODAL: {
            TITLE: 'İş Akşını Sil',
            DESCRIPTION: 'İş akışını kalıcı olarak siliyorsunuz. Silmek istediğinize emin misiniz?',
            DELETE_BUTTON: 'Sil',
            CANCEL_BUTTON: 'İptal',
        }
    },
    APPROVE_ACTIVITY: {
        MODAL_TITLE: 'Notlar/Yorumlar',
        MODAL_CLOSE: 'Kapat',
        APPROVE: {
            CREATED: 'Onay işlemi oluşturuldu.',
            APPROVED: 'Onay işlemi onaylandı.',
            PENDING: 'Onay işlemi bekliyor.',

        },
        APPROVE_STEP: {
            CREATED: 'Bir onay adımı oluşturuldu.',
            APPROVED: 'Bir onay adımı onaylandı.',
            PENDING: 'Bir onay adımı bekliyor.',
        },
        APPROVE_STEP_USER: {
            CREATED: 'Bir onaylayan oluşturuldu.',
            APPROVED: 'Bir onay verildi.',
            PENDING: 'Onay bekliyor.',
            REASSIGNED: 'Onaylayan değiştirildi.',
        },
        APPROVE_FILE: {
            CREATED: 'Bir doküman eklendi.',
            APPROVED: 'Bir doküman onaylandı.',
            PENDING: 'Bir doküman onay bekliyor.',
        }
    },
    APPROVE_PROCESS_MODAL: {
        CREATE_MODAL: {
            TITLE: 'İş Akışı Oluştur',
            NAME_LABEL: 'İş Akışını İsimlendir',
            SELECT_APPROVE_TEMPLATE: 'İş Akışı Seç',
            SAVE_AS_TEMPLATE: 'Şablon Olarak Kaydet',
            SAVE: 'Kaydet',
            SEND_APPROVE: 'İş Akışını Başlat',
            USER_NOTE_PLACEHOLDER: 'Alıcılara iletmek istediğiniz mesajı bu alana ekleyebilirsiniz. (İsteğe bağlı)'
        },
    },
    APPROVE_BUTTON_COMPONENT: {
        SEND: 'Akışa Gönder',
        TITLE: 'İş Akışını Oluştur',
        NAME_LABEL: 'İş Akışını İsimlendir',
        SAVE: 'Kaydet',
        CANCEL: 'İptal',
        YES: 'Evet',
    },
    APPROVAL_PROCESS_FILE_MODAL: {
        TITLE: 'İş Akışı Oluştur',
        SEND_APPROVE: 'Gönder',
        LOADING: 'İş akışı oluşturuluyor. Lütfen bekleyiniz...',
        STEP: 'Adım',
        DELETE: 'Sil',
        DELETE_ALL: 'Tümünü Sil',
        CAN_CHANGE_REQUEST: 'Revize edebilir',
        CAN_ASSIGN: 'Başkasına atayabilir',
        RECIPIENT_EMAIL: 'Alıcı E-posta Adresi Giriniz',
        APPROVE_FROM: 'Hangi alıcıların kararı gerekli?',
        APPROVE_FROM_ALL: 'Hepsinin',
        APPROVE_FROM_ONE: 'Herhangi birinin',
        ADD_STEP: 'Adım Ekle',
        SAVE: 'Kaydet',
        SEND: 'Gönder',
        SAVE_AS_TEMPLATE: 'Şablon Olarak Kaydet',
        TEMPLATE_NAME: 'Şablon Adı',
        TEMPLATE_NAME_PLACEHOLDER: 'Şablon adını giriniz',
        EDIT_TEMPLATE: 'Şablonu Güncelle',
        EDIT_TEMPLATE_MESSAGE: 'Bu iş akış şablonu, yaptığınız değişikliğe göre güncellenecektir. Bu işleme devam etmek istediğinize emin misiniz?',
        SELECT_APPROVAL_FLOW: 'İş Akışı Seç',
        SAVE_AS_DRAFT: 'Taslak Olarak Kaydet',
        SAVE_AS_DRAFT_MESSAGE: 'İş akışını taslak olarak kaydetmek istediğinize emin misiniz?',
        BACK: 'Geri',
        CANCEL: 'İptal',
        RECIPIENTS: 'Alıcılar',
        SELECT_FROM_TEMPLATE: 'Kayıtlı Akışlardan Seç',
        SETTINGS_INFORMATION: 'Dokümanı akışa göndermeden önce yetkileri düzenleyebilirsiniz.',
        ADD_STEP_INFO: 'Adım ekleyerek alıcıların akıştaki inceleme sırasını ve yetkilerini düzenleyebilirsiniz. Böylece bir adımdaki alıcıların incelemesi tamamlanmadan sonraki adıma geçmez.',
        MESSAGE: 'Mesaj',
        SAVE_FLOW: 'Akışı Kaydet',
        NEW_FLOW: 'Yeni Akış Yarat',
        NEED_FILE: 'Dosya eklemelidir',
    },
    CREATE_PROCESS_MODAL: {
        TITLE: 'Yeni süreç oluştur',
        DESCRIPTION: 'Nasıl başlamak istersiniz?',
        FROM_SCRATCH: 'Sıfırdan başlayın',
        FROM_SCRACTH_DESCRIPTION: 'Henüz belge hazır değilse.',
        FOR_READY_DOCUMENTS: 'Belge Yükleyin',
        FOR_READY_DOCUMENTS_DESCRIPTION: 'Yeni bir belge yükleyin.',
        FOR_CONTRACT_REQUEST: ' Sözleşme talep edin',
        FOR_CONTRACT_REQUEST_DESCRIPTION: 'Verilerinizi girin, atadığınız kullanıcılar sözleşmeyi hazırlasın.',
        START_WITH_TEMPLATE: 'Şablon Seçin',
        START_WITH_TEMPLATE_DESCRIPTION: 'Şablon listenizden seçin.',
    },
    NEW_PROCESS_MODAL: {
        DATES: 'Tarihler',
        PARTIES: 'Taraflar',
        DESCRIPTION_PLACEHOLDER: 'Bir açıklama ekleyin',
        CUSTOM_FIELDS: 'Özel Alanlar',
        DOCUMENTS: 'Dokümanlar',
        UPLOAD_DOCUMENT: 'Doküman Yükle',
        SELECT_DOCUMENT: 'Yüklü Dokümanlardan Seç',
        ADD: 'Ekle',
        ASSIGN_USERS: 'Tanımlı Kullanıcılar',
        RELATED_WITH: 'İlişkili Süreç',
        TAGS: 'Etiketler',
        MAKE_PRIVATE: 'Gizli Hale Getir',
        CREATE_PROCESS: 'Süreci Oluştur',
        NEXT: 'İleri',
        SELECT_FROM_UPLOADED_DOCUMENTS: 'Yüklü Dokümanlardan Seç',
        CREATE_RELATIONSHIP: 'Süreç ile ilişkilendir',
        SELECT: 'Seç',
        ARE_YOU_SURE_TITLE: 'Emin misiniz?',
        ARE_SURE_TO_EXIT: 'Önemli bilgilerde\'de yaptığınız değişiklikleri kaydetmek istiyor musunuz?',
        CHANGES_WILL_BE_LOST: 'Önemli bilgileri değiştirdiniz. Bu değişiklikleri kaydetmek istiyor musunuz?',
        SAVE: 'Kaydet',
        CANCEl: 'İptal',
        EDIT_KEY_TERMS_SUCCESS: 'Değişiklikler başarıyla kaydedildi.',
        FILES: {
            TITLE: 'Başlık',
            STATUS: 'Durum',
            VERSION: 'Versiyon',
            UPLOADED_DATE: 'Yükleme Tarihi',
            UPLOADED_BY: 'Yükleyen',
        },
        KEY_TERMS: 'Kontrol Listesi',
        ACTIVITY_FEED: 'Akış',
        SEND_APPROVE: 'Onaya Gönder',
        SELECT_TEMPLATE: 'Şablonlarınızı seçerek süreci oluşturabilirsiniz.',
    },
    USER_DASHBOARD: {
        WHAT_WOULD_YOU_LIKE_TO_DO_TODAY: 'Bugün ne yapmak istersiniz?',
        PROCESS_ASSIGNED_TO_ME: 'Atandığım Süreçler',
        PROCESS_CREATED_BY_ME: 'Oluşturduğum Süreçler',
        EVENTS_ASSIGNED_TO_ME: 'Atandığım Etkinlikler',
        NO_DATA_RIGHT_NOW: 'Şu anda veri yok',
        RECENT: 'En Güncel',
        LAST_30_DAYS: 'Son 30 Gün',
        ALL: 'Tümü',
        LAST_ACTIVITIES: 'Son Aktiviteler',
        DUE_DATES_FOR_NEXT_7_DAYS: 'Önemli Tarihler (7 günlük)',
    },
    PROCESS_APPROVAL: {
        NAV_BAR: {
            OVERVIEW: 'Genel Bakış',
            APPROVAL_FLOWS: 'Onay Akışları',
        },
        STATUSES: {
            PENDING: 'Devam Ediyor',
            CREATED: 'Başlamadı',
            APPROVED: 'Onaylandı',
            UPLOADED: 'Versiyon Yüklendi',
            ASSIGNED: 'Atadı',
        },
        APPROVAL_CONTENT: 'Onay İçeriği',
        NEXT: 'İleri',
        PROCESS_NAME: 'Süreç Adı',
        KEY_TERMS: 'Önemli Bilgiler',
        FILES: 'Dokümanlar',
        RECIPIENTS: 'Alıcılar',
        MESSAGE: 'Mesaj',
        BACK: 'Geri',
        SEND: 'Gönder',
        YOU_CAN_EDIT_RECIPIENTS_BEFORE_STARTING_APPROVAL_FLOW: 'Onay akışını başlatmadan önce alıcı yetkilerini düzenleyebilirsiniz',
        KEYTERMS_DATA: {
            TITLE: "Başlık",
            DESCRIPTION: "Açıklama",
            START_DATE: "Başlangıç Tarihi",
            END_DATE: "Bitiş Tarihi",
            RENEWAL_DATE: "Yenileme/Uyarı Tarihi",
            EFFECTIVE_DATE: "Yürürlük Tarihi",
            SIGNED_DATE: "İmza Tarihi",
            COMPANIES: "Taraflar",
            COMPANIES_INTERNAL: "Şirket Tarafı (Dahili)",
            COMPANIES_EXTERNAL: "Karşı Taraf",
            CONTACTS: "Taraf Kişiler",
            ASSIGNED_USERS: "Tanımlı Kullanıcılar",
            PIPELINE: "Süreç",
            STATUS: "Aşama",
            TYPE: "Tip",
            CREATED_AT: "Oluşturma Tarihi",
            PROCESS_TYPE: "Süreç Tipi",
            DETAIL: {
                STEP: 'Adım',
                DELETED_STEP: 'Bu adım silindi.',
            }
        },
        EXTERNAL: {
            APPROVE_PROCESS_CHECKING: 'Onay akışı kontrol ediliyor...',
            PLEASE_WAIT: 'Lütfen bekleyiniz...',
            SENDER: 'Gönderen',
            SEND_DATE: 'Gönderim Tarihi',
            MESSAGE: 'Mesaj',
            KEY_TERMS: 'Önemli Bilgiler',
            APPROVE_ALL: 'Tümünü Onayla',
            UPLOAD_VERSION: 'Versiyon Yükle',
            ASSIGN_TO_SOMEONE_ELSE: 'Başkasına Ata',
            APPROVE_MODAL: {
                MESSAGE: 'Onaylama işlemini tamamlamak için aşağıdaki alanları doldurarak “Onayla” butonuna basmalısınız. Onay işlemi geri alınamaz.',
                REQUESTED_DOCUMENTS: 'Talep Edilen Dokümanlar*',
                SELECT_DOCUMENT: 'Doküman Seç',
                REQUESTED_DOCUMENTS_DESCRIPTION: 'Bu süreçte talep edilen dokümanlar aşağıda listelenmiştir. Bu dokümanları yükleyerek süreci tamamlayabilirsiniz.',
                YOUR_MESSAGE: 'Mesajınız',
                ACCEPT: 'Onayla',
                CANCEL: 'İptal',

            },
            NEW_VERSION_MODAL: {
                TITLE: 'Yeni Versiyon Yükle',
                MESSAGE: 'Her belge için yeni bir versiyon yükleyebilirsiniz.',
                REQUESTED_DOCUMENTS: 'Talep Edilen Dokümanlar*',
                REQUESTED_DOCUMENTS_DESCRIPTION: 'Bu süreçte talep edilen dokümanlar aşağıda listelenmiştir. Bu dokümanları yükleyerek süreci tamamlayabilirsiniz.',
                SELECT_DOCUMENT: 'Doküman Seç',
                YOUR_MESSAGE: 'Mesajınız',
                ACCEPT: 'Onayla',
                CANCEL: 'İptal',
                UPLOAD_NEW_VERSION: 'Yeni Versiyon Yükle',

            },
            ASSIGN_TO_SOMEONE_ELSE_MODAL: {
                TITLE: 'Başkasına Ata',
                DESCRIPTION: 'Atayacağınız kişinin e-posta adresini aşağıdaki alana giriniz. Başkasına atama işlemi geri alınamaz.',
                ASSIGNEE_EMAIL: 'Yeni alıcının e-posta adresi (*)',
                YOUR_MESSAGE: 'Mesajınız (*)',
                ACCEPT: 'Onayla',
                CANCEL: 'İptal',

            },
            REGISTER_GUEST_MODAL: {
                DESCRIPTION: 'İşleminize devam edebilmek için aşağıdaki alanları doldurarak "Kaydet" butonuna basmalısınız. Kayıt işlemi geri alınamaz.',
                NAME: 'Adınız (*)',
                SURNAME: 'Soyadınız (*)',
                ALLOW_ELECTRONIC_MESSAGE: 'onedocs ürün tanıtımları, kampanyaları ve reklamları ile ilgili ticari elektronik ileti gönderilmesi amacıyla kişisel verilerimin işlenmesine ve e-posta yoluyla tarafıma ticari elektronik ileti gönderilmesini izin veriyorum.',
                SAVE: 'Kaydet',
            },
            ERROR_MESSAGES: {
                PROCESS_APPROVE_TOKEN_INVALID: {
                    title: 'Onay Akışı Geçersiz',
                    description: 'Onay akışı geçersiz veya süresi dolmuş.'
                },
                USER_NOT_FOUND: {
                    title: 'Kullanıcı Bulunamadı',
                    description: 'Kullanıcı bulunamadı.'
                },
                PROCESS_APPROVAL_NOT_FOUND: {
                    title: 'Onay Akışı Bulunamadı',
                    description: 'Onay akışı bulunamadı.'
                },
                PROCESS_APPROVAL_NOT_PENDING: {
                    title: 'Onay Akışı Devam Ediyor',
                    description: 'Onay akışı devam ediyor.'
                },
                PROCESS_APPROVAL_STEP_NOT_FOUND: {
                    title: 'Onay Adımı Bulunamadı',
                    description: 'Onay adımı bulunamadı.'
                },
                PROCESS_APPROVAL_STEP_NOT_PENDING: {
                    title: 'Onay Adımı Devam Ediyor',
                    description: 'Onay adımı devam ediyor.'
                },
                PROCESS_APPROVAL_STEP_STEPUSER_NOT_PENDING: {
                    title: 'Onay Adımı Devam Ediyor',
                    description: 'Onay adımı devam ediyor.'
                },
                PROCESS_APPROVED: {
                    title: 'Onay Akışı Onaylandı',
                    description: 'Onay akışı onaylandı.'
                },
                PROCESS_REASSIGNED: {
                    title: 'Onay Akışı Başkasına Atandı',
                    description: 'Onay akışı başkasına atandı.'
                },

            }

        },
    },
    AUTOMATION: {
        AUTOMATION: 'Otomasyon',
        AUTOMATIONS: 'Otomasyonlar',
        ADD_AUTOMATION: 'Otomasyon Ekle',
        WHEN_TITLE: 'Ne Zaman',
        THEN_TITLE: 'Ne Yap',
        ACTIVE: 'Aktif',
        INACTIVE: 'Pasif',
        WHEN: {
            processCreated: 'Süreç Oluşturulduğunda',
        },
        THEN: {
            doAction: 'Aksiyon Yap',
        },
        RULES: {
            customFieldsValue: 'Özel Alan Değeri',
        },
        ACTIONS: {
            addAssignUser: 'Kullanıcı Ata',
        },
        CONDITIONS: {
            equals: 'Eşittir',
            notEquals: 'Eşit Değildir',
            contains: 'İçerir',
            notContains: 'İçermez',
            isEmpty: 'Boş',
            isNotEmpty: 'Boş Değil',
            isGreaterThan: 'Büyüktür',
            isLessThan: 'Küçüktür',
            isGreaterThanOrEqual: 'Büyük Eşittir',
            isLessThanOrEqual: 'Küçük Eşittir',
        },
        FIELD: 'Alan',
        CONDITION: 'Koşul',
        VALUE: 'Değer',
        MAKE_ACTIVE: 'Aktif Yap',
        MAKE_DEACTIVE: 'Pasif Yap',
        DELETE: 'Sil',
        DELETE_AUTOMATION: 'Otomasyonu Sil',
        CANCEL: 'İptal',
        DELETE_MESSAGE: 'Bu otomasyonu kalıcı olarak silmek istediğinize emin misiniz?',
        TOPBAR: {
            AUTOMATIONS: 'Otomasyonlar',
            ADD_AUTOMATION: 'Otomasyon Ekle',
            ACTIVE_AUTOMATIONS: 'Aktif Otomasyonlar',
        }
    },
    ANALYTICS_REPORTING: {
        PAGE_TITLE: 'Analiz ve Raporlama',
        TOTAL_CONTRACTS: 'Toplam Süreç Sayısı',
        DAILY_CHART_BY_USERS: 'Günlük Süreç Sayısı (Kullanıcı Bazında)',
        DAILY_CHART_BY_PROCESS_TYPE: 'Günlük Süreç Sayısı (Süreç Tipi Bazında)',
        BY_PROCESS_TYPE: 'Süreç Tipine Göre',
        BY_USER: 'Kullanıcıya Göre',
        BY_STATUS: 'Duruma Göre',
        NUMBER_OF_PROCESS_ADDED: 'Eklenen Süreç Sayısı',
        PROCESS_TYPE: 'Süreç Tipi',
        USER: 'Kullanıcı',
        STATUS: 'Durum',
        TOTAL: 'Toplam',
    },
    AUDIT_LOGS: {
        PAGE_TITLE: 'İşlem Kayıtları',
        FILTER_BY_ASSIGNEE: 'Kişiye Göre Filtrele',
        FILTER_BY_EVENT_TYPE: 'Tipine Göre Filtrele',
    },
    RSUITE: {
        DATE_RANGE_PICKER: {
            TODAY: 'Bugün',
            YESTERDAY: 'Dün',
            THIS_WEEK: 'Bu Hafta',
            LAST_7_DAYS: 'Son 7 Gün',
            LAST_30_DAYS: 'Son 30 Gün',
            THIS_MONTH: 'Bu Ay',
            LAST_MONTH: 'Geçen Ay',
            THIS_YEAR: 'Bu Yıl',
            LAST_YEAR: 'Geçen Yıl',
            PREVIOUS_WEEK: 'Önceki Hafta',
            NEXT_WEEK: 'Sonraki Hafta',
        }
    },
    SPECIAL_APPLICATIONS: {
        APPS: {
            GDRIVE_TEMPLATE: {
                TITLE: 'Google Drive Şablonları',
                DESCRIPTION: 'Google Drive\'da hazırladığınız şablonları kullanarak kolayca belge oluşturabilirsiniz.',
                BUTTON: 'Google Drive Şablonlara Git',
            }
        }
    },
    ACTIVITY_FEED: {
        PROCESS_OVERVIEW: {
            EVENTS: {
                /* 'createProcessEvent': "<strong>{{name}}</strong> süreci oluşturdu. ", */
                updateProcessStatusEvent: "<strong>{{name}}</strong> süreç aşaması güncelledi. ",
                updateProcessTypeEvent: "<strong>{{name}}</strong> süreç tipini güncelledi. ",
                updateProcessTitleEvent: "<strong>{{name}}</strong> süreç başlığını güncelledi. ",
                updateProcessDescriptionEvent: "<strong>{{name}}</strong> süreç açıklamasını güncelledi. ",
                updateProcessStartDateEvent: "<strong>{{name}}</strong> başlangıç tarihi alanını güncelledi. ",
                updateProcessEndDateEvent: "<strong>{{name}}</strong> bitiş tarihi alanını güncelledi. ",
                updateProcessEffectiveDateEvent: "<strong>{{name}}</strong> yürürlük tarihi alanını güncelledi. ",
                updateProcessRenewalDateEvent: "<strong>{{name}}</strong> yenileme tarihi alanını güncelledi. ",
                updateProcessSignedDateEvent: "<strong>{{name}}</strong> imza tarihi alanını güncelledi. ",
                createCommentEvent: "<strong>{{name}}</strong> yorum ekledi. ",
                createProcessEvent: "<strong>{{name}}</strong> süreci oluşturdu. ",
                updateProcessMakePrivateEvent: "<strong>{{name}}</strong> görünürlüğü gizli olarak güncelledi. ",
                updateProcessMakePublicEvent: "<strong>{{name}}</strong> görünürlüğü herkese açık olarak güncelledi. ",
                removeAssignUserEvent: "<strong>{{name}}</strong> süreçten <strong>{{removedUser}}</strong> kullanıcısını çıkardı. ",
                addAssignUserEvent: "<strong>{{name}}</strong> sürece <strong>{{addedUser}}</strong> kullanıcısını ekledi. ",
                createSubProcessEvent: "<strong>{{name}}</strong> alt süreç oluşturdu.",
                linkProcessWithDocumentEvent: "<strong>{{name}}</strong> doküman ekledi.",
                addEventToProcessEvent: "<strong>{{name}}</strong> etkinlik ekledi.",
                updateProcessCustomFieldEvent: "<strong>{{name}}</strong> özel alan güncelledi.",
                /* Buradan sonra İngilizce için de yazıldı! */
                uploadProcessApprovalVersionEvent: "<0>{{name}}</0> <2></2> süreci kapsamında <1>{{name2}}</1> adında bir doküman yükledi.",
                createProcessApprovalEvent: "<0>{{name}}</0> süreç için <1></1> adında bir onay akışı başlattı.",
                approveProcessApprovalEvent: "<0>{{name}}</0> süreci <1></1> kapsamında onayladı.",
                assignProcessApprovalEvent: "<0>{{name}}</0> <1></1> süreci kapsamındaki yetkilerini <2>@{{name3}}</2> kişisine atadı.",
                openFileEvent: "<0>{{name}}</0> <1>{{name2}}</1> isimli dosyayı görüntüledi.",
                downloadFileEvent: "<0>{{name}}</0> downloaded the file named <1>{{name2}}</1>.",
            },
        },
        USER_DASHBOARD: {
            EVENTS: {
                /* 'createProcessEvent': "<strong>{{name}}</strong> süreci oluşturdu. ", */
                updateProcessStatusEvent: "<strong>{{name}}</strong> <2></2> sürecinin aşaması güncelledi.",
                updateProcessTypeEvent: "<strong>{{name}}</strong> <2></2> sürecinin tipini güncelledi. ",
                updateProcessTitleEvent: "<strong>{{name}}</strong> <2></2> sürecinin başlığını güncelledi. ",
                updateProcessDescriptionEvent: "<strong>{{name}}</strong> <2></2> sürecinin açıklamasını güncelledi. ",
                updateProcessStartDateEvent: "<strong>{{name}}</strong> <2></2> sürecinin başlangıç tarihini güncelledi. ",
                updateProcessEndDateEvent: "<strong>{{name}}</strong> <2></2> sürecinin bitiş tarihini güncelledi. ",
                updateProcessEffectiveDateEvent: "<strong>{{name}}</strong> <2></2> sürecinin yürürlük tarihini güncelledi. ",
                updateProcessRenewalDateEvent: "<strong>{{name}}</strong> <2></2> sürecinin yenileme tarihini güncelledi. ",
                updateProcessSignedDateEvent: "<strong>{{name}}</strong> <2></2> sürecinin imza tarihini güncelledi. ",
                createCommentEvent: "<strong>{{name}}</strong> <2></2> sürecine yorum ekledi. ",
                createProcessEvent: "<strong>{{name}}</strong> yeni bir süreç oluşturdu. ",
                updateProcessMakePrivateEvent: "<strong>{{name}}</strong> <2></2> sürecinin görünürlüğü gizli olarak güncelledi. ",
                updateProcessMakePublicEvent: "<strong>{{name}}</strong> <2></2> sürecinin görünürlüğü herkese açık olarak güncelledi. ",
                removeAssignUserEvent: "<strong>{{name}}</strong> <2></2> sürecinden <strong>{{removedUser}}</strong> kullanıcısını çıkardı. ",
                addAssignUserEvent: "<strong>{{name}}</strong> <2></2> sürecine <strong>{{addedUser}}</strong> kullanıcısını ekledi. ",
                createSubProcessEvent: "<strong>{{name}}</strong> <2></2> süreci için alt süreç oluşturdu.",
                linkProcessWithDocumentEvent: "<strong>{{name}}</strong> <2></2> sürecine doküman ekledi.",
                addEventToProcessEvent: "<strong>{{name}}</strong> <2></2> sürecine etkinlik ekledi.",
                updateProcessCustomFieldEvent: "<strong>{{name}}</strong> <2></2> sürecinde bir özel alan güncelledi.",
            },
        }
    }
}