import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toggleApprovalProcessFileModal } from "@redux/components/index.actions";
import { startApproving, editApproveProcess } from "@redux/approve/index.actions";
import { toggleProcessApprovalPopup } from "@redux/process-approval/index.actions";

import { resfreshDocumensTable } from "@redux/files/index.actions";

import AddContract from "@pages/contracts/AddContract";
import { useTranslation } from "react-i18next";

import './index.scss'
import FeatherIcons from "feather-icons-react";
import apiInstance from "@clients/api.client";
import { toast } from "react-toastify";
import { FiSave } from "react-icons/fi";
import { VscSaveAs, VscSave } from "react-icons/vsc";
import ApproveTemplatesSelect from "@components/SelectsPaginated/ApproveTemplates";
import Loader from "@components/Loader";
import Lootie from "@components/ReactLottie";
import { RiDeleteBinLine } from "react-icons/ri";
import { Card, Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import ApproveTemplatesFlatList from "./components/ApproveTemplatesFlatList";
import moment from "moment";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";


const MapStateToProps = (state: any) => ({
  components: state.components,
  companies: state.companies,
  processApproval: state.processApproval
});

const MapActionsToProps = {
  toggleProcessApprovalPopup
};

const AddStepModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const [keyTerms, setKeyTerms] = useState<any[]>([
    {
      key: 'title',
      title: 'Süreç Adı',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.title,
      show: true,
      disabled: true
    },
    {
      key: 'description',
      title: 'Süreç Açıklaması',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.description,
      show: true
    },
    {
      key: 'contractCompaniesInternal',
      title: 'Dahili Taraf',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.contractCompanies
        ?.filter((item: any) => item?.partiesSide == 'internal')
        ?.map((item: any) => item?.title)
        ?.join(', '),
      show: true
    },
    {
      key: 'contractCompaniesExternal',
      title: 'Karşı Taraf',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.contractCompanies
        ?.filter((item: any) => item?.partiesSide == 'external')
        ?.map((item: any) => item?.title)
        ?.join(', '),
      show: true
    },
    {
      key: 'type',
      title: 'Süreç Tipi',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.type?.title,
      show: true

    },
    {
      key: 'status',
      title: 'Aşama',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.status?.title,
      show: true
    },

    {
      key: 'startDate',
      title: 'Başlangıç Tarihi',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.startDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.startDate).format('DD.MM.YYYY') : null,
      show: true
    },
    {
      key: 'endDate',
      title: 'Bitiş Tarihi',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.endDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.endDate).format('DD.MM.YYYY') : null,
      show: true
    },
    {
      key: 'renewalDate',
      title: 'Yenileme Tarihi',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.renewalDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.renewalDate).format('DD.MM.YYYY') : null,
      show: true
    },
    {
      key: 'effectiveDate',
      title: 'Yürürlük Tarihi',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.effectiveDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.effectiveDate).format('DD.MM.YYYY') : null,
      show: true
    },
    {
      key: 'signatureDate',
      title: 'İmza Tarihi',
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.signatureDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.signatureDate).format('DD.MM.YYYY') : null,
      show: true
    },

  ]);

  useEffect(() => {
    /* Add custom fields to keyterms */
    if (props?.processApproval?.sendProcessApprovalPopup?.data?.customFields?.length > 0) {
      props?.processApproval?.sendProcessApprovalPopup?.data?.customFields?.map((item: any) => {
        console.log('item', item)
        if (item?.field?.CustomComponent != 'Formula') {

          if (item?.field?.CustomComponent == 'Date') {
            setKeyTerms((prev: any) => [...prev, {
              key: item?._id,
              title: item?.field?.title,
              value: item?.value ? moment(item?.value).format('DD.MM.YYYY') : null,
              show: true
            }])
          }

          else {
            setKeyTerms((prev: any) => [...prev, {
              key: item?._id,
              title: item?.field?.title,
              value: item?.value,
              show: true
            }])
          }

        }

      })
    }
  }, [props?.processApproval?.sendProcessApprovalPopup?.data?.customFields])


  const [page, setPage] = useState(0);

  const [files, setFiles] = useState<any[]>([]);


  useEffect(() => {
    apiInstance.get(`/contracts/${props?.companies?.company?._id}/${props?.processApproval?.sendProcessApprovalPopup?.data?._id}/files`, {
      params: {
        contractId: props?.processApproval?.sendProcessApprovalPopup?.data?._id,
        page: 1,
        batchSize: 100,
        searchText: '',
      }
    }).then((res: any) => {
      console.log('res', res);
      if (res?.data?.node?.files?.length > 0) {
        res?.data?.node?.files?.map((item: any) => {
          console.log('item', item)
          setFiles((prev: any) => [...prev, {
            key: item?._id,
            title: item?.filename,
            value: item?._id,
            show: false
          }])

        })
      }
    }).catch((err: any) => {
      console.log('ERR', err);
    })
  }
    , [])

  function goTo(arg0: number): void {
    if (arg0 == 1) {
      if (keyTerms.filter((item: any) => item?.show == true).length < 2) {
        toast.error('En az bir tane anahtar kelime seçmelisiniz.')
        return;
      } else if (keyTerms.filter((item: any) => item?.key == 'title')?.[0]?.show != true) {
        toast.error('Süreç adı seçmelisiniz.')
        return;
      }
    }

    setPage(arg0);
  }


  /* Steps */
  const [steps, setSteps] = useState<any[]>([
    {
      aproveAll: false,
      stepUsers: []
    }
  ])
  const [suggestions, setSuggestions] = useState<any>({})
  const [emailValues, setEmailValues] = useState<any>({})

  const [userMessage, setUserMessage] = useState<string>('');


  const addStep = () => {
    setSteps((prev: any) => [...prev, {
      aproveAll: false,
      stepUsers: []
    }])
  }

  const handleRemoveStep = (index: number) => {
    setSteps((prev: any) => prev.filter((item: any, i: number) => i != index))
  }

  const handleRemoveRecipient = (stepIndex: number, recipientIndex: number) => {
    setSteps((prev: any) => prev.map((item: any, index: number) => {
      if (index == stepIndex) {
        item.stepUsers = item.stepUsers.filter((item: any, i: number) => i != recipientIndex)
      }
      return item;
    }
    ))
  }

  const handleAddStepUser = (stepIndex: number, user: any) => {
    setSteps((prev: any) => prev.map((item: any, index: number) => {
      if (index == stepIndex) {
        item.stepUsers.push({
          user: user?._id,
          email: user?.email,
          fullname: user?.name ? user?.name + ' ' + user?.surname : null,
          canChangeRequest: false,
          canAssign: false,
          needFile: false,
          userFiles: [
            {
              title: '',
              file: null
            }
          ]
        })
      }
      return item;
    }
    ))
  }

  const changeStepUserConfig = (value: boolean, stepIndex: number, recipientIndex: number, key: string) => {
    setSteps((prev: any) => prev.map((item: any, index: number) => {
      if (index == stepIndex) {
        item.stepUsers[recipientIndex][key] = value
      }
      return item;
    }
    ))
  }

  const changeApproveAll = (value: string, stepIndex: number) => {
    setSteps((prev: any) => prev.map((item: any, index: number) => {
      if (index == stepIndex) {
        item.aproveAll = value == 'all' ? true : false
      }
      return item;
    }
    ))
  }

  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(true);


  const disableAlgorithm = (keyterms: any[], files: any[]) => {
    let disabled: boolean = true;
    let countSelectedKeyterms = keyTerms.filter((item: any) => item?.show == true)?.length;
    let countSelectedFiles = files?.filter((item: any) => item?.show == true)?.length;
    let titleShow = keyTerms?.filter((item: any) => { return item?.key == 'title' && item?.show == true })?.length > 0 ? true : false;

    if (titleShow === true) {
      if (countSelectedKeyterms > 1 || countSelectedFiles > 0) {
        console.log('button enable')
        disabled = false;
      } else {
        disabled = true;
      }
    } else {
      disabled = true;
    }

    return disabled;
  }

  return (
    <>
      <Modal
        show={props?.show}
        keyboard={false}
        size="xl"
        backdrop={'static'}
        centered
        contentClassName="shadow">
        <Modal.Header className="bg-transparent d-flex align-content-center justify-content-between">
          <Modal.Title className="w-100">
            Step Ekleme
          </Modal.Title>
          <div className="action" style={{ width: '20px' }}>
            <Button className="p-0" variant="link" onClick={() => {
              props?.toggle();
              setPage(0);
              setSteps([
                {
                  aproveAll: false,
                  stepUsers: []
                }
              ])

            }}>
              <FeatherIcons icon="x" size={20} />
            </Button>
          </div>
        </Modal.Header>


        <Modal.Body>

          <>
            <Card>

              <Card.Body>
                {steps?.length <= 1 && (<h4>Alıcılar *</h4>)}
                <div className="steps" style={{
                  background: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '#f1f1f9' : 'white',
                  paddingTop: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '30px' : '',
                  marginTop: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '25px' : ''
                }}>

                  {(steps || []).map((stepItem: any, index: number) => {
                    console.log('stepItem', stepItem)
                    return (
                      <div key={'step__' + index} className="step">

                        <div className="recipients-wrapper">
                          <div className="recipients">

                            {(stepItem?.stepUsers || []).map((stepUserItem: any, recipientIndex: number) => {
                              return (
                                <div key={'recipient' + stepUserItem?.key + recipientIndex + '1'} className="recipient-group">
                                  <div className="recipient-label">
                                    <Avatar name={stepUserItem?.fullname || stepUserItem?.email} size={'30'} round={true} />
                                    <span>{stepUserItem?.fullname || stepUserItem?.email}</span>
                                    <button className="btn btn-link text-dark" style={{ marginLeft: '5px', paddingLeft: '0', paddingRight: '0' }} onClick={e => handleRemoveRecipient(index, recipientIndex)}>
                                      <FeatherIcons size={14} icon="x" />
                                    </button>
                                  </div>


                                </div>
                              )
                            })}
                            <div className="recipients-new">
                              <input
                                onKeyDown={(e) => {

                                  if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {

                                    e.preventDefault();
                                    e.stopPropagation();
                                    /* Email validation with regex then throw toast error */
                                    if (!emailValues[index]?.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
                                      toast.error('Lütfen geçerli bir e-posta adresi giriniz.')
                                      return;
                                    }

                                    /* Check if user already added */
                                    if (steps?.[index]?.stepUsers?.filter((item: any) => item?.email == emailValues[index])?.length > 0) {
                                      toast.error('Bu kullanıcı zaten eklenmiş.')
                                      return;
                                    }

                                    apiInstance.post(`/process-approval/user`, {
                                      email: emailValues[index]
                                    }).then((res: any) => {
                                      console.log(res?.data)
                                      if (res?.data?.user?._id && res?.data?.user?.email) {

                                        handleAddStepUser(index, {
                                          _id: res?.data?.user?._id,
                                          email: res?.data?.user?.email,
                                          name: res?.data?.user?.name || null,
                                          surname: res?.data?.user?.surname || null,
                                          canChangeRequest: res?.data?.user?.type === 'GUEST' ? false : true,
                                          canAssign: res?.data?.user?.type === 'GUEST' ? false : true,
                                          needFile: false,
                                          userFiles: [
                                            {
                                              title: '',
                                              file: null
                                            }
                                          ]
                                        })
                                        setEmailValues({
                                          ...emailValues,
                                          [index]: ''
                                        })
                                        setSuggestions({
                                          ...suggestions,
                                          [index]: []
                                        })

                                      }

                                    }).catch((err: any) => {
                                      console.log('err', err);
                                    })



                                  }
                                }}
                                key={'recipient__input_email' + index}
                                value={emailValues[index] || ''}
                                onChange={(e) => {
                                  setEmailValues({
                                    ...emailValues,
                                    [index]: e.target.value
                                  })
                                }}
                                onInput={(e: any) => {

                                  if (e.target.value.length > 2) {
                                    apiInstance.get(`/companies/${props?.companies?.currentCompany}/users`, {
                                      params: {
                                        page: 1,
                                        batchSize: 10,
                                        searchText: e?.target?.value
                                      }
                                    }).then((res: any) => {
                                      console.log(res?.data?.node?.users)
                                      setSuggestions({
                                        ...suggestions,
                                        [index]: res?.data?.node?.users
                                      })
                                    })
                                  } else {
                                    setSuggestions({
                                      ...suggestions,
                                      [index]: []
                                    })
                                  }
                                }}
                                type="email" className="form-control"
                                placeholder={t('APPROVAL_PROCESS_FILE_MODAL.RECIPIENT_EMAIL')} />


                              {/* <Button variant="primary" size="sm" className="add-recipient-button"
                              onClick={() => handleAddUser({
                                step: { ...stepItem, index },
                                email: emailValues[index]
                              })}
                            >
                              <FeatherIcons icon="plus" />
                            </Button> */}

                            </div>





                          </div>
                          {steps?.length > 1 && (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveStep(index)
                              }}
                              style={{ paddingRight: 0 }}
                              className="btn btn-link btn-sm text-danger">
                              <FeatherIcons size={16} icon="trash" />
                            </button>
                          )}
                        </div>

                        <div>
                          {suggestions?.[index]?.length > 0 && (
                            <div className="recipient-suggestions">
                              {suggestions[index].filter((item: any) => {
                                return !steps?.[index]?.stepUsers?.map((item: any) => item?.email)?.includes(item?.email)
                              })?.map((item: any) => {
                                return (
                                  <div className="recipient-suggestions-item"
                                    onClick={(e: any) => {
                                      handleAddStepUser(index, { ...item })
                                      setEmailValues({
                                        ...emailValues,
                                        [index]: ''
                                      })
                                      setSuggestions({
                                        ...suggestions,
                                        [index]: []
                                      })

                                    }}
                                  >
                                    <span>{item?.email}</span>
                                  </div>
                                )
                              })}
                            </div>
                          )}
                        </div>

                        <div className="step-settings">

                        </div>

                      </div>
                    )
                  })}


                </div>

              </Card.Body>

            </Card>
            {
              /* If exists any step at least a user */
              steps?.filter((item: any) => item?.stepUsers?.length > 0)?.length > 0 && (
                <div className="steps-edit" style={{ padding: 0 }}>
                  {(steps || []).map((stepItem: any, index: number) => {
                    console.log('stepItem', stepItem)
                    return (
                      <div key={'step__' + index} className="step" style={{ padding: '20px' }}>

                        <div className="recipients">

                          {(stepItem?.stepUsers || []).map((stepUserItem: any, recipientIndex: number) => {
                            return (
                              <div key={'recipient' + stepUserItem?.key + recipientIndex} className="recipient">
                                <div className="recipient-group">
                                  <div className="recipient-label">
                                    <Avatar name={stepUserItem?.fullname || stepUserItem?.email} size={'30'} round={true} />
                                    <span>{stepUserItem?.fullname || stepUserItem?.email}</span>
                                    {/* <button className="btn btn-link text-dark" style={{ marginLeft: '5px', paddingLeft: '0', paddingRight: '0' }} onClick={e => handleRemoveRecipient(index, recipientIndex)}>
                                      <FeatherIcons size={14} icon="x" />
                                    </button> */}
                                  </div>
                                  <div className="recipient-settings">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input m-0" type="checkbox" name="inlineRadioOptions" id={"inlineRadio1" + stepUserItem?.key + recipientIndex + index}
                                        onChange={(e) => changeStepUserConfig(e.target.checked, index, recipientIndex, 'canChangeRequest')}
                                        checked={stepUserItem?.canChangeRequest} />
                                      <label className="form-check-label" htmlFor={"inlineRadio1" + stepUserItem?.key + recipientIndex + index}>{t('APPROVAL_PROCESS_FILE_MODAL.CAN_CHANGE_REQUEST')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        onChange={(e) => changeStepUserConfig(e.target.checked, index, recipientIndex, 'canAssign')}
                                        className="form-check-input m-0" type="checkbox" name="inlineRadioOptions" id={"inlineRadio2" + stepUserItem?.key + recipientIndex + index} checked={stepUserItem?.canAssign} />
                                      <label className="form-check-label" htmlFor={"inlineRadio2" + stepUserItem?.key + recipientIndex + index}>{t('APPROVAL_PROCESS_FILE_MODAL.CAN_ASSIGN')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        onChange={(e) => changeStepUserConfig(e.target.checked, index, recipientIndex, 'needFile')}
                                        className="form-check-input m-0" type="checkbox" name="inlineRadioOptions" id={"inlineRadio3" + stepUserItem?.key + recipientIndex + index} checked={stepUserItem?.needFile} />
                                      <label className="form-check-label" htmlFor={"inlineRadio3" + stepUserItem?.key + recipientIndex + index}>{t('APPROVAL_PROCESS_FILE_MODAL.NEED_FILE')}</label>
                                    </div>

                                  </div>

                                </div>
                                {stepUserItem?.needFile && (
                                  <div className="recipient-settings">

                                    {stepUserItem?.userFiles?.map((item: any, fileIndex: number) => {
                                      return (
                                        <>
                                          <input
                                            value={steps?.[index]?.stepUsers?.[recipientIndex]?.userFiles?.[fileIndex]?.title}
                                            type="text" className="form-control mt-1" name="filename" onChange={(e: any) => {
                                              console.log(index, e.target.value)
                                              setSteps((prev: any) => prev.map((item: any, i: number) => {
                                                if (i == index) {
                                                  item.stepUsers[recipientIndex].userFiles[fileIndex].title = e?.target?.value
                                                }
                                                return item;
                                              }
                                              ))
                                            }} />
                                          {item?.stepUsers?.[recipientIndex]?.userFiles?.[fileIndex]?.title}
                                        </>
                                      )
                                    })}

                                    <div className="d-flex align-items-center justify-content-end gap-1">
                                      <Button
                                        size="sm"
                                        className="mt-1 btn-onedocs-primary"
                                        variant="outline-primary"
                                        onClick={() => {
                                          setSteps((prev: any) => prev.map((item: any, i: number) => {
                                            if (i == index) {
                                              item.stepUsers[recipientIndex].userFiles.push({
                                                title: '',
                                                file: null
                                              })
                                            }
                                            return item;
                                          }
                                          ))

                                        }}
                                      ><FeatherIcons icon="plus" /></Button>
                                      {
                                        stepUserItem?.userFiles?.length > 1 && (
                                          <Button
                                            size="sm"
                                            className="mt-1 btn-onedocs-secondary"
                                            variant="outline-danger"
                                            onClick={() => {
                                              setSteps((prev: any) => prev.map((item: any, i: number) => {
                                                if (i == index) {
                                                  item.stepUsers[recipientIndex].userFiles.pop()
                                                }
                                                return item;
                                              }
                                              ))

                                            }}
                                          ><FeatherIcons icon="minus" /></Button>
                                        )
                                      }

                                    </div>

                                  </div>
                                )}
                              </div>
                            )
                          })}




                        </div>
                        {stepItem?.stepUsers?.length > 0 && (
                          <div className="step-settings">
                            <span>
                              {t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM')}:
                            </span>
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  value={'all'}
                                  onChange={(e) => changeApproveAll(e.target.value, index)}
                                  className="form-check-input" type="radio" id={'inlineRadio1' + index} key={'key_inline_radio_1' + index} checked={stepItem?.aproveAll === true ? true : false} />
                                <label className="form-check-label" htmlFor={'inlineRadio1' + index}>{t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM_ALL')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  value={'one'}
                                  onChange={(e) => changeApproveAll(e.target.value, index)}
                                  className="form-check-input" type="radio" id={'inlineRadio2' + index} key={'key_inline_radio_2' + index} checked={stepItem?.aproveAll !== true ? true : false} />
                                <label className="form-check-label" htmlFor={'inlineRadio2' + index}>{t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM_ONE')}</label>
                              </div>
                            </div>
                          </div>
                        )}


                      </div>
                    )
                  })}
                </div>
              )
            }
            <div className="d-flex justify-content-between">
              <Button className="btn-onedocs-secondary" size="lg" variant="primary" onClick={() => goTo(1)}>Geri</Button>
              <Button
                disabled={loading}
                className="btn-onedocs-primary" size="lg" variant="primary" onClick={() => {
                  setLoading(true)
                  apiInstance.post(`/process-approval/step`, {
                    steps: steps,
                    approveId: props?.approveData?._id,
                  }).then((res: any) => {
                    console.log('res', res);
                    if (res?.data?.response == true) {
                      props?.toggle();
                      props?.getApprovalData(props?.approveData?._id, props?.approveData?.contract?._id);
                    }
                  }).catch((err: any) => {
                    console.log('err', err);
                    toast.error('Onay akışı başlatılamadı.')
                  })

                  console.log({
                    steps: steps,
                    keyTerms: keyTerms,
                    files: files
                  })

                }}>Gönder</Button>
            </div>
          </>

        </Modal.Body>
      </Modal>


    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(AddStepModal);
