import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import UnknownUser from '@assets/images/users/unknown.jpg';
import _, { initial } from 'lodash';
import moment from 'moment';
import { urlFriendly } from '@utils/url';
import i18n from 'i18next';

const initialState = {
    creatingCompany: false,
    currentCompany: undefined,
    defaultPipeline: undefined,
    defaultType: undefined,
    company: undefined,
    fetchingCompany: false,
    companies: [],
    automationCounter: 0,
    editCompanyTables: {
        refreshUsersTable: false,
        users: new Map(),
        usersPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
        refreshUsersInvitesTable: false,
        usersInvites: new Map(),
        usersInvitesPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
        refreshDepartmentsTable: false,
        departments: new Map(),
        departmentsPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
        refreshRolesTable: false,
        roles: new Map(),
        rolesPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        }
    }
}


const CompaniesReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.LOGIN_SUCCESSFUL:
            return {
                ...state,
                currentCompany: action.payload?.node?.currentCompany?._id,
                company: action.payload?.node?.currentCompany
            }
        /* CREATE COMPANY */
        case ActionDispatchTypes.CREATE_COMPANY:
            return {
                ...state,
                creatingCompany: true
            }
        /* CREATE COMPANY SUCCESSFUL */
        case ActionDispatchTypes.CREATE_COMPANY_SUCCESSFUL:
            return {
                ...state,
                currentCompany: action?.payload?.node?.company?._id || null,
                company: action?.payload?.node?.company,
                companies: _.union([{
                    ...action?.payload?.node?.company,
                    isCurrent: true
                }], [...state?.companies.map((company: any, index: number) => {
                    if (company?.isCurrent) {
                        return { ...company, isCurrent: false };
                    }
                    return company;
                })
                ]),
                creatingCompany: false
            }
        /* CREATE COMPANY FAILED */
        case ActionDispatchTypes.CREATE_COMPANY_FAILED:
            return {
                ...state,
                creatingCompany: false
            }

        /* UPDATE COMPANY */
        case ActionDispatchTypes.UPDATE_COMPANY:
            return {
                ...state
            }
        /* UPDATE COMPANY SUCCESSFUL */
        case ActionDispatchTypes.UPDATE_COMPANY_SUCCESSFUL:
            return {
                ...state,
                company: action?.payload?.node?.company,
                currentCompany: action?.payload?.node?.company?._id || null,
                companies: (state?.companies || []).map((company: any, index: number) => {
                    if (company?._id === action?.payload?.node?.company?._id) {
                        return {
                            ...company,
                            ...action?.payload?.node?.company
                        }
                    }
                    return company;
                })
            }

        /* GET COMPANIES */
        case ActionDispatchTypes.GET_COMPANIES:
            return {
                ...state
            };
        case ActionDispatchTypes.GET_COMPANIES_SUCCESSFUL:
            return {
                ...state,
                companies: _.union(
                    [...state?.companies],
                    [...action?.payload?.node?.companies]
                )
            }
        case ActionDispatchTypes.GET_COMPANIES_FAILED:
            return {
                ...state
            };
        /* GET CDURRENT COMPANY */
        case ActionDispatchTypes.GET_CURRENT_COMPANY:
            return {
                ...state,
                fetchingCompany: true
            }
        case ActionDispatchTypes.GET_CURRENT_COMPANY_SUCCESSFUL:
            return {
                ...state,
                company: action?.payload?.node?.company,
                currentCompany: action?.payload?.node?.company?._id || null,
                defaultPipeline: action?.payload?.node?.defaultPipeline || null,
                defaultType: action?.payload?.node?.defaultType || null,
                automationCounter: action?.payload?.node?.automations || 0,
                fetchingCompany: false
            }

        case ActionDispatchTypes.GET_CURRENT_COMPANY_FAILED:
            return {
                ...state,
                fetchingCompany: false
            };

        /* SET CURRENT COMPANY */
        case ActionDispatchTypes.SET_CURRENT_COMPANY:
            return {
                ...state
            };
        case ActionDispatchTypes.SET_CURRENT_COMPANY_SUCCESSFUL:
            return {
                ...initialState,
                currentCompany: action?.payload?.node?.company?._id,
                companies: state?.companies.map((company: any) => {
                    if (company._id === action?.payload?.node?.company?._id) {
                        company.isCurrent = true;
                    } else {
                        company.isCurrent = false;
                    }
                    return company;
                })
            }
        case ActionDispatchTypes.SET_CURRENT_COMPANY_FAILED:
            return {
                ...state
            };


        /* SET CURRENT COMPANY */
        case ActionDispatchTypes.SET_USER_DEFAULT_PIPELINE:
            return {
                ...state
            };
        case ActionDispatchTypes.SET_USER_DEFAULT_PIPELINE_SUCCESSFUL:
            return {
                ...state,
                defaultPipeline: action?.payload?.node?.pipeline || null,
            }
        case ActionDispatchTypes.SET_USER_DEFAULT_PIPELINE_FAILED:
            return {
                ...state
            };

        /* SET CURRENT COMPANY */
        case ActionDispatchTypes.SET_USER_DEFAULT_TYPE:
            return {
                ...state
            };
        case ActionDispatchTypes.SET_USER_DEFAULT_TYPE_SUCCESSFUL:
            return {
                ...state,
                defaultType: action?.payload?.node?.typeId || null,
            }
        case ActionDispatchTypes.SET_USER_DEFAULT_TYPE_FAILED:
            return {
                ...state
            };

        case ActionDispatchTypes.GET_COMPANY_USERS_SUCCESSFUL:
            state?.editCompanyTables?.users?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.users.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    ...state?.editCompanyTables?.refreshUsersTable && { refreshUsersTable: false },
                    usersPagination: {
                        ...state?.editCompanyTables?.usersPagination,
                        ...action?.payload?.pagination
                    }
                }
            }
        case ActionDispatchTypes.UPDATE_COMPANY_USER_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshUsersTable: true,
                }
            }
        case ActionDispatchTypes.DELETE_USER_FROM_COMPANY_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshUsersTable: true,
                }
            }
        case ActionDispatchTypes.GET_COMPANY_USERS_INVITES_SUCCESSFUL:
            state?.editCompanyTables?.usersInvites?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.users.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    ...state?.editCompanyTables?.refreshUsersInvitesTable && { refreshUsersInvitesTable: false },
                    usersInvitesPagination: {
                        ...state?.editCompanyTables?.usersInvitesPagination,
                        ...action?.payload?.pagination
                    }
                }
            }
        case ActionDispatchTypes.UPDATE_COMPANY_USERS_INVITES_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshUsersInvitesTable: true,
                }
            }
        case ActionDispatchTypes.DELETE_COMPANY_USERS_INVITES_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshUsersInvitesTable: true,
                }
            }
        case ActionDispatchTypes.GET_COMPANY_DEPARTMENTS_SUCCESSFUL:
            state?.editCompanyTables?.departments?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.departments.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    ...state?.editCompanyTables?.refreshDepartmentsTable && { refreshDepartmentsTable: false },
                    departmentsPagination: {
                        ...state?.editCompanyTables?.departmentsPagination,
                        ...action?.payload?.pagination
                    }
                }
            }
        case ActionDispatchTypes.CREATE_DEPARTMENTS_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshDepartmentsTable: true,
                }
            }
        case ActionDispatchTypes.UPDATE_DEPARTMENTS_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshDepartmentsTable: true,
                }
            }
        case ActionDispatchTypes.DELETE_DEPARTMENTS_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshDepartmentsTable: true,
                }
            }
        case ActionDispatchTypes.GET_COMPANY_ROLES_SUCCESSFUL:
            state?.editCompanyTables?.roles?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.roles.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        nominationTitles: item?.nominations?.map((nomination: any) => {
                            return i18n.t("COMPANIES.ROLES.NOMINATIONS." + nomination?.operationId);
                            return nomination.title
                        }).join(`, `) || "-",
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    ...state?.editCompanyTables?.refreshRolesTable && { refreshRolesTable: false },
                    rolesPagination: {
                        ...state?.editCompanyTables?.rolesPagination,
                        ...action?.payload?.pagination
                    }
                }
            }
        case ActionDispatchTypes.CREATE_ROLES_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshRolesTable: true,
                }
            }
        case ActionDispatchTypes.UPDATE_ROLES_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshRolesTable: true,
                }
            }
        case ActionDispatchTypes.DELETE_ROLES_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshRolesTable: true,
                }
            }
        case ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE:
            localStorage.removeItem('userInvite')
            return {
                ...state,
                creatingCompany: true,
            }
        case ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE_SUCCESSFUL:
            localStorage.removeItem('userInvite')
            return {
                ...state,
                companies: [action?.payload?.node?.company],
                company: action?.payload?.node?.company,
                currentCompany: action?.payload?.node?.company?._id,
                creatingCompany: false,
            }
        case ActionDispatchTypes.ACCEPT_COMPANY_USER_INVITE_FAILED:
            localStorage.removeItem('userInvite')
            return {
                ...state,
                creatingCompany: false,
            }
        case ActionDispatchTypes.CREATE_ADD_USER_TO_COMPANY_SUCCESSFUL:
            return {
                ...state,
                editCompanyTables: {
                    ...state?.editCompanyTables,
                    refreshUsersInvitesTable: true,
                }
            }
        case ActionDispatchTypes.CREATE_CUSTOM_CDN_ENDPOINT_SUCCESSFUL:
            return {
                ...state,
                company: {
                    ...state?.company,
                    ...action?.payload?.node?.company
                }
            }
        default:
            return state;
    }
}

export default CompaniesReducer;