import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import { Transforms } from 'slate';
import { BsTable } from 'react-icons/bs';

export default function TableElement(props: any) {

    const editor = useSlate()

    return (
        <Button

            variant='link'
            onMouseDown={
                (event: any) => {
                    event.preventDefault()
                    Transforms.select(editor, props?.target)
                    Transforms.insertNodes(
                        editor,
                        {
                            type: 'table',
                            children: [
                                {
                                    type: 'table-row',
                                    children: [
                                        {
                                            type: 'table-cell',
                                            children: [{ text: '' }]
                                        },
                                        {
                                            type: 'table-cell',
                                            children: [{ text: '' }]
                                        }
                                    ]
                                }
                            ]
                        }
                    )
                    Transforms.move(editor)
                }
            }>
            <BsTable size={18} />
        </Button>
    )
}
