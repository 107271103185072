import React, { useEffect, useState } from 'react';
import FlatListSelect from '@components/FlatListSelect';
import api from '@clients/api.client';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { components } from 'react-select';
import { Button } from 'react-bootstrap';
import FeatherIcons from 'feather-icons-react';
import { toggleCreateProcessModal, toggleNewProcessModal } from "@redux/components/index.actions";

const randomIdGenerator = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const mapStateToProps = (state: any) => ({
    companies: state.companies,
    users: state.users,
    components: state.components,
})

const mapActionsTopProps = {

};


const AddNewContract = (props: any) => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(toggleCreateProcessModal({ show: false, openOverview: false, refreshContractList: true }))
        dispatch(toggleNewProcessModal({ show: true, pages: ['addRecord'], currentPage: 'addRecord', openOverview: false, refreshContractList: true }))
    }
    return (
        <components.MenuList {...props}>
            <div className='d-flex align-items-center justify-content-center p-2'>
                <Button
                    variant='outline-primary'
                    onClick={handleClick}
                    className="p-2 pointer"
                    style={{ width: '80%' }}
                >
                    <i className="icon">
                        <span>
                            <FeatherIcons icon="plus-square"></FeatherIcons>
                        </span>

                    </i> Add New
                </Button>
            </div>
            {props.children}
        </components.MenuList>
    );
};


const ContractsSelect: React.FunctionComponent<any> = (props: any) => {
    const { t } = useTranslation();
    const batchSize = 30;
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [value, setValue] = useState(null);
    const [data, setData] = useState<{ label: string, value: string }[]>([]);


    const fetchContracts = async () => {
        console.log(searchText)
        return
    }


    return (
        <>
            {props?.components?.newProcessModal?.show != true && (
                <FlatListSelect
                    components={{ MenuList: AddNewContract }}
                    data={data}
                    value={value}
                    cacheUniqs={[]}
                    onChange={(currentItem: any) => {
                        props?.onValueSubmit && props?.onValueSubmit(currentItem?.value);
                        props?.onSelectForFiles && props?.onSelectForFiles(currentItem)
                        setValue(currentItem);
                    }}
                    placeholder={t("SELECTS.CONTRACTS.SEARCH_CONTRACTS")}
                    defaultOption={props?.defaultOption?.value ? props?.defaultOption : null}
                    className="select-contracts-type"
                    onEndReached={async (search: string, additional: any) => {
                        if (searchText !== search) {
                            if (!(search === "" || search === null || search === undefined)) {
                                console.warn("SEARCH CHANGING");
                                setSearchText(search)
                                setPage(1);
                            }
                        } else {
                            setPage(page + 1);
                        }
                        const response = await api.get(`/contracts/list/${props?.companies?.company?._id}`, {
                            params: {
                                batchSize: batchSize,
                                page: page,
                                searchText: search
                            }
                        }).then((response: any) => {
                            //toast.success(t("SELECTS.CONTRACTS.CONTRACT_CHANGE_SUCCESS"))
                            return response.data?.node?.contracts;
                        }).catch((error: any) => {
                            console.log(error);
                        });
                        const newData = (response || []).map((item: any) => {
                            return {
                                label: item?.title,
                                value: item?._id
                            }
                        })

                        const finalNewData = _.difference(newData, data);
                        setData([...data, ...finalNewData]);
                        console.warn(finalNewData.length)
                        if (newData.length < batchSize) {
                            return {
                                options: finalNewData,
                                hasMore: false,
                                additional: {
                                    page: page + 1
                                }
                            };
                        }
                        return {
                            options: [...finalNewData],
                            hasMore: newData.length !== 0,
                            additional: {
                                page: page + 1
                            }
                        };
                        //setPage(page + 1);
                    }}
                />
            )}
        </>
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(ContractsSelect);