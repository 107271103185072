import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { toggleNewAutomationModal } from "@redux/components/index.actions";

import { } from '@redux/files/index.actions';

import { useTranslation } from "react-i18next";
import FeatherIcons from "feather-icons-react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import apiInstance from "@clients/api.client";
import './index.scss';
import { toast } from "react-toastify";
import Toggle from 'rsuite/Toggle';

const MapStateToProps = (state: any) => ({
  components: state.components,
  contracts: state.contracts,
  companies: state.companies,
  cdn: state.files.cdn,
  uploadedFiles: state.files.newProcessUploadedFiles
});

const MapActionsToProps = {
  toggleNewAutomationModal: toggleNewAutomationModal
};


const ListAutomations: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deletedId, setDeletedId] = useState<string>('');

  const [automations, setAutomations] = useState<any[]>([]);

  useEffect(() => {
    apiInstance.get(`/automations`, {
      params: {
        isActive: 'all'
      }
    }).then((res: any) => {
      setAutomations(res?.data?.node?.automations || [])
      setLoading(false)
    }).catch((err: any) => {
      setLoading(false)
      console.log('res', err)
    });

  }, []);

  useEffect(() => {
    console.log('automations', automations)
  }, [automations])

  return (
    <>
      {
        loading ? (
          <div
            style={{
              minHeight: '300px'
            }}
            className="d-flex justify-content-center align-items-center w-100 h-100">
            <div className="d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="automation-list-header">
              <div className="automation-list-header-nav">
                <div
                  className={`automation-list-header-nav-item ${isActive ? 'active' : ''}`}
                  onClick={() => {
                    setIsActive(true)
                  }}
                >
                  {t('AUTOMATION.ACTIVE')} ({automations?.filter((item: any) => item?.isActive).length})
                </div>
                <div
                  className={`automation-list-header-nav-item ${!isActive ? 'active' : ''}`}
                  onClick={() => {
                    setIsActive(false)
                  }}
                >
                  {t('AUTOMATION.INACTIVE')} ({automations?.filter((item: any) => !item?.isActive).length})
                </div>
              </div>
              <Button
                onClick={() => {
                  props?.setCreateAutomation(true)
                }}
                variant="primary" className="btn-onedocs-primary">
                {t("AUTOMATION.ADD_AUTOMATION")}
              </Button>
            </div>
            <div className="automation-list-container">

              {
                automations?.map((automation: any) => {

                  if (automation?.isActive === !isActive) {
                    return null;
                  }

                  return (
                    <div className="automation-list-item" key={'check-cont' + automation?._id}>
                      <div className="automation-list-item-description">
                        When <span className="automation-list-item-description-label">{t('AUTOMATION.WHEN.' + automation?.whenExecute)}</span> then <span className="automation-list-item-description-label">{t('AUTOMATION.ACTIONS.' + automation?.action)}</span>
                      </div>
                      <div className="automation-list-item-action">

                        <OverlayTrigger

                          placement="top"
                          overlay={
                            <Tooltip
                            >
                              {!isActive ? t('AUTOMATION.MAKE_ACTIVE') : t('AUTOMATION.MAKE_DEACTIVE')}
                            </Tooltip>
                          }
                        >
                          <span>

                            <Toggle
                              onChange={async (e: any) => {
                                let tempAutomations = [...automations];
                                let index = tempAutomations.findIndex((item: any) => item?._id === automation?._id);
                                tempAutomations[index].isActive = e;

                                await apiInstance.put(`/automation/${automation?._id}`, {
                                  isActive: e
                                }).then((res: any) => {

                                  setTimeout(() => {
                                    setAutomations(tempAutomations);
                                  }, 300)
                                  console.log('isActive@@@@@@@@@@@', isActive, e)
                                  /* Update on database */
                                }).catch((err: any) => {
                                  console.log('err', err)
                                  toast.error('Something went wrong');
                                })
                              }}
                              defaultChecked={automation?.isActive}
                              size="xs" checkedChildren={t('AUTOMATION.ACTIVE')} unCheckedChildren={t('AUTOMATION.INACTIVE')} />
                          </span>
                        </OverlayTrigger>

                        <OverlayTrigger

                          placement="top"
                          overlay={
                            <Tooltip
                            >
                              {t('AUTOMATION.DELETE_AUTOMATION')}
                            </Tooltip>
                          }
                        >
                          <span>
                            <Button
                              variant="link"
                              size="sm"
                              onClick={() => {
                                setShowDeleteModal(true)
                                setDeletedId(automation?._id)
                              }}
                            >
                              <FeatherIcons icon="trash" size={15} />
                            </Button>
                          </span>
                        </OverlayTrigger>




                      </div>
                    </div>
                  )
                })
              }


            </div>
            {/* Delete modal */}
            <Modal
              show={showDeleteModal}
              onHide={() => {
                setShowDeleteModal(false)
                setDeletedId('');
              }}
              contentClassName="shadow"
              centered
              backdrop={false}
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t('AUTOMATION.DELETE_AUTOMATION')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="delete-modal">

                  <div className="lead">
                    {t('AUTOMATION.DELETE_MESSAGE')}
                  </div>

                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowDeleteModal(false)
                    setDeletedId('');
                  }}
                >
                  {t('AUTOMATION.CANCEL')}
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    apiInstance.delete(`/automation/${deletedId}`).then((res: any) => {
                      console.log('res', res)
                      let tempAutomations = [...automations];
                      let index = tempAutomations.findIndex((item: any) => item?._id === deletedId);
                      tempAutomations.splice(index, 1);
                      setAutomations(tempAutomations);
                      setShowDeleteModal(false)
                      setDeletedId('');
                    }).catch((err: any) => {
                      console.log('err', err)
                      toast.error('Something went wrong');
                    })
                  }}
                >
                  {t('AUTOMATION.DELETE')}
                </Button>
              </Modal.Footer>
            </Modal>

          </>
        )
      }
    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(ListAutomations);
