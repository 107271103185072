import React, { useEffect, useRef, useState } from "react";
import ScreenModal from "@components/Modals/ScreenModal";
import { connect } from 'react-redux';
import "./index.scss";
import globalStyles from "@styles/globalStyles";
import { Form, Button, Toast, Col, Row } from "react-bootstrap";
import ButtonLoader from "@components/Buttons/ButtonLoader";
import { GrChapterAdd, GrAdd } from 'react-icons/gr';
import { BsFillCursorFill, BsSearch, BsPlusCircle } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';
import { RiDeleteBinFill } from 'react-icons/ri';
import { ImRadioChecked, ImRadioUnchecked } from 'react-icons/im';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import Select from 'react-select';
import './index.scss';
import { getCompanies, setCurrentCompany } from "@redux/companies/index.actions";
import Loader from "@components/Loader";
import { useTranslation } from "react-i18next";

const randomIdGenerator = (length: number = 6): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

type TValue = {
    email: string,
    role: string
}

const mapStateToProps = (state: any) => ({
    components: state.components
});

const mapActionsTopProps = {

}


const AcceptOrClose: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [index, setIndex] = useState<number>(0);
    const [currentSelection, setCurrentSelection] = useState<string | null>(null);
    const next = () => {

    }
    const previous = () => {

    }

    const batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    } = {
        onClose: () => {
            props?.toggleAcceptOrCloseModal(false);
        },
        next: next,
        previous: previous,
        index: index,
        length: 1
    }


    return (
        <React.Fragment>
            <ScreenModal
                show={props?.show}
                renderHeader={(props?.renderHeader)}
                size={"sm"}
                renderFooter={(
                    <React.Fragment>
                        <button
                            className="btn btn-primary AcceptOrClose-finish"
                            onClick={(e) => {
                                props?.onAccept();
                                props?.onClose();
                            }}>
                            {t("POPUPS.ACCEPT_OR_CLOSE.ACCEPT")}
                        </button>
                        <button
                            className="btn btn-outline-primary accept-or-cancel-button-cancel"
                            onClick={(e) => {
                                props?.onClose()
                            }}>
                            {t("POPUPS.ACCEPT_OR_CLOSE.CANCEL")}
                        </button>
                    </React.Fragment>
                )}
                onClose={props?.onClose}>
                <div className="accept-or-close">
                    <div className="container-fluid accept-or-close">
                        <div className="row accept-or-close-row">
                            <div className="col-lg-12 col-md-12">
                                <div className="innerContent">
                                    {props?.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScreenModal>
        </React.Fragment>
    )
}


export default connect(mapStateToProps, mapActionsTopProps)(AcceptOrClose);