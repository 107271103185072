import React, { useEffect, useMemo, useRef, useState } from "react";

import QuillEditor from "@components/QuillEditor";
import { Accordion, Button, Card, Col, Container, Dropdown, DropdownButton, Form, ListGroup, Modal, Row, Table } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { setFileData, editTemplate, createDocumentFromTemplate, addFilesToContract } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal, toggleGenericAddContractCompaniesModal, toggleGenericAddContractModal, toggleNewProcessModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";

import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus, FiLink } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import ContractOverviewRightPanel from "@popups/ContractOverview/components/ContractOverviewRightPanel";
import { BsLock, BsUnlock, BsShieldLock } from "react-icons/bs";
import { MdLock, MdLockOpen, MdLockOutline } from "react-icons/md";
import ContractsSelect from "@components/SelectsPaginated/Files";
import {
    getContractById, getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    editKeyTerms,
    resetContractOverview,
    addRecordsAsSubRecordToContract,
    getFullProcessData
} from "@redux/contract/index.actions";
import { evaluate } from "mathjs";

import Loader, { DefaultLoader } from "@components/Loader";
import moment from "moment";
import _, { set } from "lodash";
import './index.scss';
import Select from "react-select";
import ProcessOverviewCustomFieldAreaAccordion, { TCustomComponent } from "@components/ProcessOverviewCustomFieldAreaAccordion";
import ParentlessContracts from "@components/SelectsPaginated/ParentlessContracts";
import { ref } from "yup";
import CompaniesSelectInternal from "@components/SelectsPaginated/Companies/internal"
import CompaniesSelectExternal from "@components/SelectsPaginated/Companies/external"

const mapStateToProps = (state: any) => ({
    components: state?.components,
    companies: state?.companies,
    contracts: state?.contracts,
    process: state?.contracts?.processOverview,
    files: state?.files
});
const mapActionsToProps = {
    toggleGenericContractOverviewModal,
    getContractById,
    getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    editKeyTerms,
    resetContractOverview,
    addFilesToContract,
    toggleGenericAddContractCompaniesModal,
    addRecordsAsSubRecordToContract,
    toggleGenericAddContractModal,
    toggleNewProcessModal,
    getFullProcessData
};



const ProcessOverviewScreen = (props: any) => {
    const { t } = useTranslation();

    const editedContract = props?.process

    const [contractCompaniesInternal, setContractCompaniesInternal] = useState([])
    const [contractCompaniesExternal, setContractCompaniesExternal] = useState([])

    const [areYouSureSaveModal, setAreYouSureSaveModal] = useState(false);
    const [data, setData] = useState<{
        _id: string
        title: string
        description?: string
        startDate?: string
        endDate?: string
        renewalDate?: string
        effectiveDate?: string
        signedDate?: string
        status: string | undefined
        pipeline?: string
        type: string
        private: boolean
        parentContract?: string
    }>({
        _id: editedContract?._id,
        title: editedContract?.title,
        description: editedContract?.description,
        startDate: editedContract?.startDate,
        endDate: editedContract?.endDate,
        renewalDate: editedContract?.renewalDate,
        effectiveDate: editedContract?.effectiveDate,
        signedDate: editedContract?.signedDate,
        status: editedContract?.status?._id,
        pipeline: editedContract?.pipeline?._id,
        type: editedContract?.type?._id,
        private: editedContract?.private,
        parentContract: editedContract?.parentContract?._id
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsData, setCustomFieldsData] = useState<{}>({});
    const [selectedContractType, setSelectedContractType] = useState<any>(editedContract?.type);
    const [newPipeline, setNewPipeline] = useState(null);
    const [newStatus, setNewStatus] = useState(editedContract?.status?._id);
    const [error, setError] = useState("");

    const [selectedPipeline, setSelectedPipeline] = useState<any>({
        _id: editedContract?.pipeline?._id,
        statuses: editedContract?.pipeline?.statuses,
    });

    const [firstLoad, setFirstLoad] = useState(true);

    const handleInput = (target: any) => {

        if (target?.name === 'title') {
            console.log('title', target?.value)
        }
        setData({ ...data, ...{ [target?.name]: target?.value } })
        handleSaveButtonToggle(true);
    }

    const handleCustomFields = (event: any) => {

        setCustomFieldsData({
            ...customFieldsData, ...{
                [event?.target?.name]: {
                    field: event?.target?.name,
                    value: event?.target?.value,
                    relatedOption: null,
                }
            }
        })
        if (!firstLoad) {
            handleSaveButtonToggle(true);
        }
        setFirstLoad(false);

    }

    const handleOnChange = (event: any, e: any) => {

        //e?.field?._id

        setCustomFieldsData({
            ...customFieldsData, ...{
                [e?.field?._id]: {
                    field: e?.field?._id,
                    value: event?.opt?.value,
                    relatedOption: event?.value,
                }
            }
        })
        handleSaveButtonToggle(true);
    }

    const handleSwitch = (switchData: {
        name: string,
        value: boolean
    }) => {
        setData({ ...data, ...{ [switchData?.name]: switchData?.value } })
        handleSaveButtonToggle(true);
    }



    const handleContractType = (target: any) => {


        if (target?.value == null) {
            console.log('BURADA!!!!')
            setData({ ...data, ...{ [target?.name]: undefined } })
            setData({ ...data, ...{ status: undefined } })

        }


        setData({ ...data, ...{ type: target?.value, status: undefined } })

        setNewStatus(undefined);



        const contractType = _.filter(props?.contracts?.types?.ContractsTypes, { _id: target?.value });

        setSelectedContractType(contractType[0]);
        console.log('contractType', contractType)
        setShowStartDate(contractType[0]?.showStartDate);
        setShowEndDate(contractType[0]?.showEndDate);
        setShowRenewalDate(contractType[0]?.showRenewalDate);
        setShowEffectiveDate(contractType[0]?.showEffectiveDate);
        setShowSignedDate(contractType[0]?.showSignedDate);

        setCustomFieldsData({});
        setCustomFields([])

        const cf = contractType[0]?.customFields
        const newData: any = {};
        contractType[0]?.customFields?.map((e: any) => {
            newData[e?.field?._id] = {
                field: e?.field?._id,
                value: ""
            }
        })
        setCustomFieldsData(newData);
        setCustomFields(cf?.length ? cf : []);

        setRefreshStatusSelect(true);
        console.log('contractType', contractType)
        handleSaveButtonToggle(true);
    }




    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const requestData: {
            _id: string
            customFields?: any[]
        } = {
            _id: data?._id,
            customFields: _.values(customFieldsData),
        }





        if (!requestData?._id) {
            toast.warning(t("CONTRACTS.EDIT_CONTRACT.SOMETHING_IS_WRONG"))
            setLoading(false);
            return;
        }



        await props.editKeyTerms(requestData).then((res: any) => {

            setLoading(false);
            toast.success(t("NEW_PROCESS_MODAL.EDIT_KEY_TERMS_SUCCESS"))
        }).catch((error: any) => {
            console.log('error', error)
            setLoading(false);
        })

        handleSaveButtonToggle(false);
        setAreYouSureSaveModal(false);
        props?.setAreYouSureModal(false)
        props?.setSaveTrigger(0)


    }
    const typeDefaultValue = (): {
        label: string
        value: string
    } => {
        //let data = _.filter(props?.contracts?.types?.ContractsTypes, { _id: editedContract?.type })[0]
        return {
            label: editedContract?.type?.title,
            value: editedContract?.type?._id
        }
    }
    const statusDefaultValue = (): {
        label: string
        value: string
    } => {
        let data = _.filter(props?.contracts?.statuses, { _id: editedContract?.status })[0]
        return {
            label: data?.title,
            value: data?._id
        }
    }
    const pipelineDefaultValue = (): {
        label: string
        value: string
    } => {
        let data = _.filter(props?.contracts?.pipelines, { _id: editedContract?.pipeline })[0]
        setSelectedPipeline(data);
        return {
            label: data?.title,
            value: data?._id
        }
    }


    const [showStartDate, setShowStartDate] = useState(false);
    const [showEndDate, setShowEndDate] = useState(false);
    const [showRenewalDate, setShowRenewalDate] = useState(false);
    const [showEffectiveDate, setShowEffectiveDate] = useState(false);
    const [showSignedDate, setShowSignedDate] = useState(false);

    useEffect(() => {
        if (props?.saveTrigger > 0) {
            handleSubmit({ preventDefault: () => { } })

        } else {
            console.log('SIFIR OLDU')
        }
    }, [props?.saveTrigger])


    const [mode, setMode] = useState('view');

    useEffect(() => {

        console.log(editedContract?.type)

        setShowStartDate(editedContract?.type?.showStartDate);
        setShowEndDate(editedContract?.type?.showEndDate);
        setShowRenewalDate(editedContract?.type?.showRenewalDate);
        setShowEffectiveDate(editedContract?.type?.showEffectiveDate);
        setShowSignedDate(editedContract?.type?.showSignedDate);


        const contractType = _.filter(props?.contracts?.types?.ContractsTypes, { _id: editedContract?.type?._id });
        const cf = contractType[0]?.customFields
        setCustomFields(cf?.length ? cf : []);
        const currentCustomFieldsData: any = {}
        editedContract?.customFields?.map((e: any) => {
            currentCustomFieldsData[e?.field?._id] = {
                field: e?.field?._id,
                value: e?.value,
                relatedOption: e?.relatedOption
            }
        })

        setCustomFieldsData(currentCustomFieldsData)
        setCustomFields(cf?.length ? cf : []);


    }, [props?.contracts?.types?.ContractsTypes])


    useEffect(() => {

        setContractCompaniesInternal(props?.process?.contractCompanies?.filter((e: any) => e?.partiesSide === 'internal')?.map((e: any) => {
            return {
                value: e?._id,
                label: e?.title
            }
        }))
        setContractCompaniesExternal(props?.process?.contractCompanies?.filter((e: any) => e?.partiesSide === 'external')?.map((e: any) => {
            return {
                value: e?._id,
                label: e?.title
            }
        }))


    }, [props?.process?.contractCompanies])


    const [refreshStatus, setRefreshStatus] = useState(false);

    const [refrestStatusSelect, setRefreshStatusSelect] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setRefreshStatusSelect(false)
        }, 100)
    }, [refrestStatusSelect])


    const [showSaveButton, setShowSaveButton] = useState(false);

    const handleSaveButtonToggle = (value: boolean) => {
        // Ekstra bir işlem yapmak istediğiniz yer

        console.log('SAVE BUTTON TOGGLE', value)
        setShowSaveButton(value);
        props?.setAllowClose(value);
    };


    const [emptyCustomFields, setEmptyCustomFields] = useState(0);

    useEffect(() => {
        // Calculate empty custom fields
        let empty = 0;
        _.values(customFieldsData).map((e: any) => {
            if (e?.value === "" || e?.value === null || e?.value === undefined) {
                empty++;
            }
        })
        setEmptyCustomFields(empty);
    }, [customFieldsData])



    return (
        <>
            {customFields?.length > 0 && (
                <>
                    <Card className="checklist-overview" style={props?.style}>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div className="d-flex gap-1 align-items-center">
                                <h5>{t('NEW_PROCESS_MODAL.KEY_TERMS')}
                                </h5>

                            </div>

                            <div className="d-flex align-items-center justify-content-end gap-1">
                                {emptyCustomFields > 0 && (<span>({emptyCustomFields} empty field)</span>)}
                                {(showSaveButton) ? (
                                    <Button
                                        onClick={event => setAreYouSureSaveModal(true)}
                                        disabled={loading} name="btn" value={1} variant="outline-primary" size="sm" type="submit">
                                        {loading && <Loader />} {
                                            t("CONTRACTS.EDIT_CONTRACT.SAVE")
                                        }
                                    </Button>
                                ) : <span style={{ cursor: 'pointer' }} onClick={(e: any) => {
                                    console.log(mode)
                                    setMode('edit')
                                    setShowSaveButton(true)
                                }}>{mode === 'view' && <FeatherIcons icon="edit" />}</span>
                                }
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className={`key-terms ${props?.disabled ? 'disabled-div' : ''}`}>
                                <form

                                    onSubmit={event => setAreYouSureSaveModal(true)} >
                                    <Container>



                                        {customFields?.length > 0 && (<ProcessOverviewCustomFieldAreaAccordion>
                                            {(customFields || []).map((e: {
                                                field: {
                                                    title: string;
                                                    key: string;
                                                    CustomComponent: TCustomComponent
                                                    isOptional: boolean
                                                    _id: string
                                                    existOptionValues: boolean
                                                    OptionValues: any
                                                },
                                                _id: string;
                                                value: any;
                                            }, index: number) => {

                                                // eklenen kontrol
                                                const customFieldsArray = Array.isArray(editedContract?.customFields) ? editedContract?.customFields : [];

                                                if (e?.field?.existOptionValues === true) {
                                                    let detailField = _.filter([...customFieldsArray], { _id: e?.field?._id })[0] || ''
                                                    let detailFieldValue = _.filter(e?.field?.OptionValues, { _id: detailField?.relatedOption })[0] || ''
                                                    return React.createElement(ProcessOverviewCustomFieldAreaAccordion[e?.field?.CustomComponent], {
                                                        customKey: String(index),
                                                        size: 6,
                                                        name: e?.field?._id,
                                                        label: e?.field?.title,
                                                        mode: mode as 'edit' | 'view',
                                                        value: {
                                                            value: detailFieldValue?.value,
                                                            label: detailFieldValue?.title
                                                        },
                                                        onChange: (event: any) => handleOnChange(event, e),
                                                        require: e?.field?.isOptional,
                                                        data: e
                                                    })
                                                } else {
                                                    return React.createElement(ProcessOverviewCustomFieldAreaAccordion[e?.field?.CustomComponent], {
                                                        customKey: String(index),
                                                        size: 6,
                                                        mode: mode as 'edit' | 'view',
                                                        value: _.filter([...customFieldsArray], { _id: e?.field?._id })[0]?.value || '',
                                                        name: e?.field?._id,
                                                        label: e?.field?.title,
                                                        onInput: (event: any) => handleCustomFields(event),
                                                        require: e?.field?.isOptional,
                                                        data: e
                                                    })
                                                }

                                            })}

                                        </ProcessOverviewCustomFieldAreaAccordion>)}


                                    </Container>
                                </form>

                            </div>
                        </Card.Body>
                    </Card>
                    <Modal
                        contentClassName="border shadow"
                        show={areYouSureSaveModal}
                        onHide={() => setAreYouSureSaveModal(false)}
                        centered
                        size="lg"
                    >
                        <Modal.Header className="bg-light" closeButton>
                            <Modal.Title>{t("NEW_PROCESS_MODAL.ARE_YOU_SURE_TITLE")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("NEW_PROCESS_MODAL.CHANGES_WILL_BE_LOST")}</p>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                width: '100%',
                                marginTop: '20px',
                                gap: '10px'

                            }}>
                                <Button
                                    disabled={loading}
                                    onClick={() => {
                                        handleSubmit({ preventDefault: () => { } })
                                        setMode('view')
                                        setShowSaveButton(false)
                                    }}
                                    variant="primary" className="d-flex align-items-center gap-1">
                                    {t("NEW_PROCESS_MODAL.SAVE")}
                                </Button>
                                <Button
                                    disabled={loading}
                                    onClick={() => {

                                        setAreYouSureSaveModal(false);
                                        setMode('view')
                                        setShowSaveButton(false)
                                    }}
                                    variant="secondary" className="d-flex align-items-center gap-1">
                                    {t("NEW_PROCESS_MODAL.CANCEl")}
                                </Button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
            )}

        </>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(ProcessOverviewScreen);
