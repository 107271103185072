import React, { useEffect, useState } from "react";

import { Modal, Button, Row, Col } from "react-bootstrap";
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  deleteContract,
} from "@redux/contract/index.actions";
import { toggleGenericContractOverviewModal } from "@redux/components/index.actions";
const mapStateToProps = (state: any) => ({
  companies: state?.companies
});
const mapActionsToProps = {
  deleteContract,
  toggleGenericContractOverviewModal: toggleGenericContractOverviewModal
};

const DeleteModal = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const request: {
    id: string
    company: string
  } = {
    id: props?.data?._id,
    company: props?.companies?.currentCompany,
  }

  const handleConfirmRemove = () => {
    props.deleteContract(request).then((res: any) => {
      //history.push('/contract/list');
      if (res !== false && props?.subRecord === true) {
        console.log('Burada', 1, props?.subRecord)
        props.toggle();
        window.location.href = '/contract/table?c=' + props?.parentRecord?._id;
      } else if (res !== false && props?.subRecord !== true) {
        console.log('Burada', 2)
        window.location.href = '/contract/table';
      }
    })
  }

  useEffect(() => {
    console.log('DeleteConfirm', props)
  }, [])

  return (
    <Modal
      show={props?.show}
      onHide={props?.toggle}
      size="sm"
      centered
      backdropClassName="contract-overview-modal-backdrop"
    >
      <Modal.Header closeButton className="bg-danger">
        <h4 className="modal-title text-white">{t("CONTRACTS.OVERVIEW.DELETE_MODAL.DELETE")}</h4>
      </Modal.Header>
      <Modal.Body>
        {props?.data?.subContracts?.length > 0 ? (<>
          <Row>
            <p>
              {t('CONTRACTS.OVERVIEW.DELETE_MODAL.DELETE_ALL_DESCRIPTION')}
            </p>
            <Col md={12} className="d-flex justify-content-end gap-1">
              <Button
                className="btn btn-sm"
                onClick={props?.toggle}
              >
                {t('CONTRACTS.OVERVIEW.DELETE_MODAL.CANCEL')}
              </Button>
              <Button
                className="btn btn-sm btn-danger"
                onClick={handleConfirmRemove}
              >
                {t('CONTRACTS.OVERVIEW.DELETE_MODAL.DELETE_ALL')}
              </Button>
            </Col>
          </Row>
        </>) : (<>
          <Row>
            <p>
              {t('CONTRACTS.OVERVIEW.DELETE_MODAL.DELETE_DESCRIPTION')}
            </p>
            <Col md={12} className="d-flex justify-content-end gap-1">
              <Button
                className="btn btn-sm"
                onClick={props?.toggle}
              >
                {t('CONTRACTS.OVERVIEW.DELETE_MODAL.CANCEL')}
              </Button>
              <Button
                className="btn btn-sm btn-danger"
                onClick={handleConfirmRemove}
              >
                {t('CONTRACTS.OVERVIEW.DELETE_MODAL.DELETE')}
              </Button>
            </Col>
          </Row>
        </>)}

      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(DeleteModal);
