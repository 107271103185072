import React, { useEffect, useMemo, useRef, useState } from "react";

import QuillEditor from "@components/QuillEditor";
import { Accordion, Button, Card, Col, Dropdown, DropdownButton, Form, ListGroup, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { toggleProcessApprovalPopup } from "@redux/process-approval/index.actions";
import { setFileData, editTemplate, createDocumentFromTemplate, addFilesToContract } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal, toggleGenericAddContractCompaniesModal, toggleGenericAddContractModal, toggleNewProcessModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";

import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus, FiLink } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import { BsLock, BsUnlock, BsShieldLock } from "react-icons/bs";
import { MdLock, MdLockOpen, MdLockOutline } from "react-icons/md";
import ContractsSelect from "@components/SelectsPaginated/Files";
import Chat from "./components/Chat";
import Files from "./components/Files";
import AssignedUsersFlatList from "./components/AssignedUsersFlatList";
import {
    getContractById, getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    resetContractOverview,
    addRecordsAsSubRecordToContract,
    getFullProcessData,
    editKeyTerms,
    removeTagToContract,
    addTagToContract,
} from "@redux/contract/index.actions";
import { evaluate } from "mathjs";

import './index.scss';
import { DefaultLoader } from "@components/Loader";

import PageTitle from "@components/PageTitle";
import moment from "moment";
import _, { set } from "lodash";
import ParentlessSublessContracts from "@components/SelectsPaginated/ParentlessSublessContracts";

/* COMPONENTS */
import KeyTerms from "./components/KeyTerms";
import FilesList from "./components/FilesList";
import DeleteModal from "./components/DeleteModal";
import RemoveSubRecordModal from "./components/RemoveSubRecordModal";
import CDNUploadPopup from "@popups/CDNUploadPopup";
import Avatar from "react-avatar";
import Reminder from "./components/Reminder";
import apiInstance from "@clients/api.client";
import ApprovalFlowOverview from "@components/ApprovalFlowOverview";
import Dates from "./components/Dates";
import Parties from "./components/Parties";




const mapStateToProps = (state: any) => ({
    components: state?.components,
    companies: state?.companies,
    contracts: state?.contracts,
    process: state?.contracts?.processOverview,
    files: state?.files
});
const mapActionsToProps = {
    toggleGenericContractOverviewModal,
    getContractById,
    getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    resetContractOverview,
    addFilesToContract,
    toggleGenericAddContractCompaniesModal,
    addRecordsAsSubRecordToContract,
    toggleGenericAddContractModal,
    toggleNewProcessModal,
    getFullProcessData,
    editKeyTerms,
    removeTagToContract,
    addTagToContract,
    toggleProcessApprovalPopup
};
const ProcessOverviewScreen = (props: any) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [processId, setProcessId] = useState(props?.contractId || null);
    const [saveTrigger, setSaveTrigger] = useState(0);
    const [showReminderModal, setShowReminderModal] = useState<boolean>(false);
    /* Textarea Auto Height */
    const textAreaRef = useRef<any>(null);

    /* Approval */
    const [processApprovalFlows, setProcessApprovalFlows] = useState<any[]>([]);
    const [selectedApproval, setSelectedApproval] = useState<any>(null);

    const handleSelectApproval = (id: any) => {
        if (props?.contractId && !id) {
            history.push(`?c=${props?.contractId}`);
        } else {
            history.push(`?c=${props?.contractId}&approvalId=${id}`);
        }
        setSelectedApproval(id);
    }


    /* useEffect(() => {
        let element: any = textAreaRef?.current;
        if (element !== null) {
            element.style.height = 'auto';
            const scrollHeight = element.scrollHeight;
            const maxScrollHeight = lineHeight * maxRows;
            if (scrollHeight > maxScrollHeight) {
                element.style.height = maxScrollHeight + 'px';
            } else {
                element.style.height = scrollHeight + 'px';
            }
        }
        if (props?.roles?.edit) {
            element?.setAttribute('disabled', 'disabled');
        }
    }, [props?.process?.description]); */



    useEffect(() => {
        apiInstance.get(`/process-approval/contract/${props?.contractId}`).then((res: any) => {
            setProcessApprovalFlows(res?.data?.processApprovals);
            console.log('processApprovals', res?.data?.processApprovals)
        }).catch((error: any) => {
            console.log('error', error)
        })
    }, [props?.contractId]);

    const lineHeight = 24; //px
    const maxRows = 10;

    const [areYouSureModal, setAreYouSureModal] = useState(false);

    const [status, setStatus] = useState(null);
    const [statuses, setStatuses] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const [type, setType] = useState(null);
    const [types, setTypes] = useState([]);

    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);


    const [selectedFiles, setSelectedFiles] = useState<any>([]);


    const [selectFileModal, setSelectFileModal] = useState(false);

    const toggleSelectFileModal = () => {
        setSelectFileModal((prevstate) => !prevstate);
    };

    const [selectSubRecordModal, setSelectSubRecordModal] = useState(false);

    const toggleSelectSubRecordModal = () => {
        setSelectSubRecordModal((prevstate) => !prevstate);
    };

    const [selectedSubRecords, setSelectedSubRecords] = useState<any>(null);


    const [deleteSubModal, setDeleteSubModal] = useState(false);
    const [selectedSubContract, setSelectedSubContract] = useState<any>(null);
    const toggleDeleteSubModal = () => {
        setDeleteSubModal((prevstate) => !prevstate);
    };

    const [removeSubRecordModal, setRemoveSubRecordModal] = useState(false);
    const toggleRemoveSubRecordModal = () => {
        setRemoveSubRecordModal((prevstate) => !prevstate);
    };

    /* Tag Operation */
    const [inputValue, setInputValue] = useState('');
    const [tags, setTags] = useState(props?.process?.tags || []);
    const colors = ["bg-primary text-white", "bg-secondary text-white", "bg-success text-white", "bg-danger text-white", "bg-warning text-white", "bg-info text-white", "bg-dark text-white"];

    /* Assigned User Operation */
    const [assignedUsers, setAssignedUsers] = useState<any[]>([]);
    const [refreshFileList, setRefreshFileList] = useState(false);

    useEffect(() => {
        if (props?.contractId) {
            setProcessId(props?.contractId);

            props?.getFullProcessData({
                processId: props?.contractId,
            }).then((res: any) => {
                console.log(res);
                setStatuses(res?.contract?.type?.statuses);
                setStatus(res?.contract?.status);

                setType(res?.contract?.type);

                /* findIndex status in statuses */
                const index = res?.contract?.type?.statuses?.findIndex((item: any) => item?._id == res?.contract?.status?._id);
                setActiveIndex(index);


            }).catch((err: any) => {
                console.log(err);
            });

            props.getContractTypes();

        }
    }, [props?.contractId]);

    useEffect(() => {
        /* check url exists approvalId get param */
        const urlParams = new URLSearchParams(window.location.search);
        const approvalId = urlParams.get('approvalId');
        console.log('APPROVALID@@@@@', approvalId)
        if (approvalId) {

            history.push(`?c=${props?.contractId}&approvalId=${approvalId}`);
            setSelectedApproval(approvalId);
        } else if (!approvalId && props?.contractId) {
            history.push(`?c=${props?.contractId}`);
        }


    }, []);

    useEffect(() => {

        setRefreshFileList(true);

        setTimeout(() => {
            setRefreshFileList(false);
        }, 350)


    }, [props?.files?.refreshTableList]);



    const toggleNewReminderModal = () => {
        setShowReminderModal((prevstate) => !prevstate || false);
    };








    if (props?.contracts?.processLoading) {
        return (
            <>
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <DefaultLoader />
                </div>
            </>
        )
    }






    return (
        <>

            {deleteSubModal && (
                <DeleteModal
                    show={deleteSubModal}
                    toggle={toggleDeleteSubModal}
                    data={selectedSubContract}
                    subRecord={true}
                    parentRecord={props?.process}
                />
            )}
            {removeSubRecordModal && (
                <RemoveSubRecordModal
                    show={removeSubRecordModal}
                    toggle={toggleRemoveSubRecordModal}
                    data={selectedSubContract}
                />
            )}

            <div className="process-overview-screen">
                <div className="pos-header">
                    <div className="pos-header-left">
                        <div className="pos-header-left-title">
                            <input
                                disabled={props?.roles?.edit}
                                onBlur={async (e: any) => {
                                    if (e?.target?.value == props?.process?.title) return;
                                    await props.editKeyTerms({
                                        _id: processId,
                                        title: e?.target?.value
                                    }).then((res: any) => {
                                        console.log('RRRESSS', res)
                                        toast.success(t("NEW_PROCESS_MODAL.EDIT_KEY_TERMS_SUCCESS"))
                                    }).catch((error: any) => {
                                        console.log('error', error)
                                    })
                                }}
                                className="input-title"
                                type="text"
                                defaultValue={props?.process?.title} />
                        </div>
                        <div>
                            <span
                                style={{
                                    borderRadius: '5px',
                                    padding: '6px 15px',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    borderColor: 'transparent',
                                    backgroundColor: '#f3f4f7',

                                }}
                            >{
                                    props?.process?.type?.title
                                }</span>
                        </div>

                    </div>
                    <div className="pos-header-right">
                        <div className="pos-header-right-tags">
                            <div className="add-tags d-flex gap-1 align-items-center">
                                <div className="d-flex align-items-center gap-1">
                                    {
                                        props?.process?.tags?.slice(0, 3)?.map((tag: any, index: number) => {
                                            return (
                                                <span

                                                    /* onClick={() => {
                                                        const requestData: {
                                                            contractId: string
                                                            companyId: string
                                                            tag: string
                                                        } = {
                                                            contractId: props?.contracts?.processOverview?._id,
                                                            companyId: props?.companies?.company?._id,
                                                            tag: tag
                                                        }
                                                        console.log(requestData, 'requestData')
                                                        props?.removeTagToContract(requestData);
                                                    }} */
                                                    key={index} className="d-flex justify-content-between align-items-center w-100">
                                                    <span
                                                        className={"d-flex align-items-center justify-content-between " + colors[index % colors.length]}
                                                        style={{
                                                            cursor: "pointer", height: '30px', padding: '6px 15px', borderRadius: '0 5px 5px 0', fontSize: '12px', fontWeight: 400, borderColor: 'transparent', textShadow: '0 1px 0 rgba(0,0,0,.2)'
                                                        }}
                                                    >{tag}</span>

                                                </span>
                                            )
                                        })
                                    }
                                </div>

                                <Dropdown as={'div'} autoClose={'outside'} >
                                    <OverlayTrigger placement="left" overlay={<Tooltip id="tt-user-list">{t('NEW_PROCESS_MODAL.TAGS')}</Tooltip>}>
                                        <Dropdown.Toggle className={`select-tag ${props?.process?.tags?.length > 0 ? 'solid-border' : null}`}>
                                            <FeatherIcons icon="tag" size={40} />
                                            {(props?.process?.tags?.length - 3) > 0 && (<span className="counter">
                                                +{props?.process?.tags?.length - 3}
                                            </span>)}




                                        </Dropdown.Toggle>
                                    </OverlayTrigger>

                                    <Dropdown.Menu align={'start'} as={'div'} style={{ width: '300px', marginTop: '5px', padding: '10px' }}>

                                        <div className="input-group">
                                            <input
                                                disabled={props?.roles?.edit}
                                                style={{
                                                    borderRadius: '4px 0px 0px 4px',
                                                }}
                                                type="text"
                                                className="form-control"
                                                value={inputValue}
                                                onChange={(e: any) => setInputValue(e.target.value)}
                                                onKeyPress={(e: any) => {
                                                    let value = e.target.value?.trim();
                                                    if (!value || value == "") return;
                                                    if (e.key === 'Enter' || e.key === 'NumpadEnter' || e.key === 'Tab' || e.key === ',') {
                                                        /* Delete , */
                                                        if (e.key === ',') {
                                                            e.preventDefault();
                                                            value = value?.replace(',', '');
                                                        }
                                                        if (props?.process?.tags.includes(value)) {
                                                            toast.error('Bu etiket zaten var');
                                                            return
                                                        }
                                                        const requestData: {
                                                            contractId: string
                                                            companyId: string
                                                            tag: string
                                                        } = {
                                                            contractId: props?.contracts?.processOverview?._id,
                                                            companyId: props?.companies?.currentCompany,
                                                            tag: value
                                                        }

                                                        props?.addTagToContract(requestData);
                                                        setInputValue('');
                                                        setInputValue('');
                                                    }
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    disabled={props?.roles?.edit}
                                                    style={{
                                                        borderRadius: '0px 4px 4px 0px',
                                                    }}
                                                    className="btn btn-secondary"
                                                    type="button"
                                                    onClick={(e: any) => {
                                                        let value = inputValue?.trim();
                                                        if (!value || value == "") return;
                                                        console.log('tagvalue', value)
                                                        if (props?.process?.tags.includes(value)) {
                                                            toast.error('Bu etiket zaten var');
                                                            return
                                                        }
                                                        const requestData: {
                                                            contractId: string
                                                            companyId: string
                                                            tag: string
                                                        } = {
                                                            contractId: props?.contracts?.processOverview?._id,
                                                            companyId: props?.companies?.currentCompany,
                                                            tag: value
                                                        }

                                                        props?.addTagToContract(requestData);
                                                        setInputValue('');
                                                    }}
                                                >
                                                    <FeatherIcons icon="plus" size={15} />
                                                </button>
                                            </div>
                                        </div>
                                        <ul className="list-group mt-3">
                                            {(props?.process?.tags || []).map((item: any, index: number) => (
                                                <li className="list-group-item d-flex align-items-center justify-content-between" key={index}>
                                                    <span>{item}</span>
                                                    <Button
                                                        disabled={props?.roles?.edit}
                                                        onClick={() => {
                                                            const requestData: {
                                                                contractId: string
                                                                companyId: string
                                                                tag: string
                                                            } = {
                                                                contractId: props?.contracts?.processOverview?._id,
                                                                companyId: props?.companies?.company?._id,
                                                                tag: item
                                                            }
                                                            console.log(requestData, 'requestData')
                                                            props?.removeTagToContract(requestData);

                                                        }}
                                                        variant="link"><FeatherIcons icon="x" size="14"></FeatherIcons></Button>
                                                </li>
                                            ))}
                                        </ul>

                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                        <div className="pos-header-right-assign-users">
                            <div className="footer-assign-user">


                                <div className="user-list" style={props?.process?.assignedUsers?.length <= 0 ? { border: 'none', width: '49px', height: '49px', paddingLeft: '10px' } : { height: '49px', paddingLeft: '20px' }}>
                                    <Dropdown as={'div'} autoClose={'outside'} style={props?.process?.assignedUsers?.length <= 0 ? { border: 'none', width: '49px', height: '49px' } : { width: '39px', height: '39px' }}>
                                        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tt-user-list">{t('NEW_PROCESS_MODAL.ASSIGN_USERS')}</Tooltip>}>
                                            <Dropdown.Toggle className="user-add" style={props?.process?.assignedUsers?.length <= 0 ? { width: '49px', height: '49px' } : { width: '39px', height: '39px' }}>
                                                <FeatherIcons icon="user" size={30} />
                                                <span className="plus-icon">
                                                    <FeatherIcons icon="plus" size={20} />
                                                </span>

                                            </Dropdown.Toggle>

                                        </OverlayTrigger>
                                        <Dropdown.Menu align={'start'} as={'div'} style={{ width: '300px', marginTop: '5px', padding: '10px' }}>

                                            <AssignedUsersFlatList
                                                disabled={props?.roles?.edit}
                                                roles={props?.roles}
                                                onUserAdd={(data: any) => {
                                                    setAssignedUsers([...props?.process?.assignedUsers, data]);
                                                }}
                                                onUserRemove={(data: any) => {
                                                    setAssignedUsers(props?.process?.assignedUsers.filter((user: any) => user._id !== data._id));
                                                }}
                                                assignedUsers={props?.process?.assignedUsers}
                                            />

                                        </Dropdown.Menu>
                                    </Dropdown>


                                    {(props?.process?.assignedUsers || []).map((user: any, index: number) => {
                                        return (
                                            <>
                                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tt-user-list">{`${user.name} ${user.surname}`}</Tooltip>}>
                                                    <div className="user" key={index}>
                                                        <div className="">
                                                            <Avatar name={`${user.name} ${user.surname}`} size="39" round={true} />
                                                        </div>
                                                    </div>
                                                </OverlayTrigger>
                                            </>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>
                        {/* <div className="pos-header-right-private">
                            <div className={`private-button ${props?.process?.private === true ? 'button-selected' : ''}`}>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tt-user-list">{t('NEW_PROCESS_MODAL.MAKE_PRIVATE')}</Tooltip>}>
                                    <Button
                                        disabled={props?.roles?.edit}
                                        variant="link" onClick={() => {

                                            props.editKeyTerms({
                                                _id: processId,
                                                private: !props?.process?.private
                                            }).then((res: any) => {
                                                console.log('RRRESSS', res)
                                                toast.success(t("NEW_PROCESS_MODAL.EDIT_KEY_TERMS_SUCCESS"))
                                            }).catch((error: any) => {
                                                console.log('error', error)
                                            })
                                        }}>
                                        {props?.process?.private ? (<FeatherIcons icon="lock" size={25} />) : (<FeatherIcons icon="unlock" size={25} />)}
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div> */}
                        <div className="pos-header-right-private">
                            <div className={`private-button`}>
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="tt-user-list">{t('NEW_PROCESS_MODAL.SEND_APPROVE')}</Tooltip>}>
                                    <Button
                                        disabled={props?.roles?.edit}
                                        variant="link" onClick={() => {
                                            props?.toggleProcessApprovalPopup({
                                                show: true,
                                                data: {
                                                    ...props?.process
                                                }

                                            })
                                        }}>
                                        <FeatherIcons icon="share" size={25} />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="pos-header-right-close">
                            <Button variant="link"
                                onClick={() => {
                                    props?.allowClose !== true ?
                                        props?.toggleGenericContractOverviewModal({
                                            contractId: null,
                                            overview: {}
                                        }) : setAreYouSureModal(true)
                                }}>
                                <FeatherIcons icon="x" />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="pos-menu">
                    <ul>
                        <li onClick={() => handleSelectApproval(null)} >{t('PROCESS_APPROVAL.NAV_BAR.OVERVIEW')}</li>

                        {processApprovalFlows?.length > 0 && (
                            <Dropdown as={'li'}>
                                <Dropdown.Toggle as={'span'} className="active">
                                    <span>{t('PROCESS_APPROVAL.NAV_BAR.APPROVAL_FLOWS')}</span> <FeatherIcons icon="chevron-down" size={15} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {
                                        (processApprovalFlows || [])?.map((item: any, index: number) => {
                                            return (
                                                <Dropdown.Item onClick={() => {

                                                    handleSelectApproval(item?._id);
                                                }} eventKey={index}>{item?.title}</Dropdown.Item>
                                            )
                                        })
                                    }

                                </Dropdown.Menu>
                            </Dropdown>
                        )}



                    </ul>
                </div>

                {selectedApproval && (

                    <><ApprovalFlowOverview approvalId={selectedApproval} processData={props?.process} /></>

                )}
                {
                    !selectedApproval && (
                        <>
                            <div className="pos-status-bar">
                                <div className="statuses">
                                    {(statuses?.slice(0, 6) || []).map((status: any, index: number) => (
                                        <div
                                            onClick={async (e: any) => {

                                                if (props?.roles?.edit) return;

                                                await props.editKeyTerms({
                                                    _id: processId,
                                                    status: status?._id
                                                }).then((res: any) => {
                                                    setStatus(res?.contract?.status);
                                                    const index = res?.contract?.type?.statuses?.findIndex((item: any) => item?._id == res?.contract?.status?._id);
                                                    setActiveIndex(index);
                                                    toast.success(t("NEW_PROCESS_MODAL.EDIT_KEY_TERMS_SUCCESS"))
                                                }).catch((error: any) => {
                                                    console.log('error', error)
                                                })
                                            }}
                                            className={`status ${index == activeIndex ? ' active' : ''} ${index == (activeIndex - 1) ? ' active-prev' : ''} ${index < activeIndex ? ' active-passed' : ''}`}>
                                            <span>{status?.title}</span>
                                        </div>
                                    ))}

                                </div>
                                {statuses?.length > 6 && (
                                    <div className={`statuses-more`}>
                                        <DropdownButton
                                            id="dropdown-basic-button"
                                            title={statuses?.slice(6, statuses?.length)?.length + " more"}
                                            variant="link"
                                            size="sm"
                                            className={`btn-more d-flex align-items-center justify-content-center ${activeIndex > 6 ? ' active' : ''}`}
                                        >
                                            {(statuses?.slice(6, statuses?.length) || []).map((status: any, index: number) => (
                                                <Dropdown.Item
                                                    className={`status ${index + 6 == activeIndex ? ' fw-bolder' : ''}`}
                                                    onClick={async (e: any) => {

                                                        if (props?.roles?.edit) return;

                                                        await props.editKeyTerms({
                                                            _id: processId,
                                                            status: status?._id
                                                        }).then((res: any) => {
                                                            setStatus(res?.contract?.status);
                                                            const index = res?.contract?.type?.statuses?.findIndex((item: any) => item?._id == res?.contract?.status?._id);
                                                            setActiveIndex(index);
                                                            toast.success(t("NEW_PROCESS_MODAL.EDIT_KEY_TERMS_SUCCESS"))
                                                        }).catch((error: any) => {
                                                            console.log('error', error)
                                                        })
                                                    }}
                                                    key={index}>
                                                    {index + 6 == activeIndex && (
                                                        <FeatherIcons icon="check" size={15} />
                                                    )} {status?.title}

                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>


                                    </div>
                                )}

                            </div>
                            <div className="pos-description">
                                <textarea className="form-control"
                                    ref={textAreaRef}
                                    placeholder={t('NEW_PROCESS_MODAL.DESCRIPTION_PLACEHOLDER')}
                                    style={{
                                        resize: "none",
                                        overflowY: "auto",
                                        lineHeight: `${lineHeight}px`,
                                        maxHeight: `${lineHeight * maxRows}px`,
                                        padding: '10px 20px',
                                    }}
                                    onBlur={async (e: any) => {

                                        if (props?.roles?.edit) return;

                                        if (e?.target?.value == props?.process?.description) return;
                                        await props.editKeyTerms({
                                            _id: processId,
                                            description: e?.target?.value
                                        }).then((res: any) => {
                                            console.log('RRRESSS', res)
                                            toast.success(t("NEW_PROCESS_MODAL.EDIT_KEY_TERMS_SUCCESS"))

                                        }).catch((error: any) => {
                                            console.log('error', error)
                                        })
                                    }}
                                    defaultValue={props?.process?.description}
                                ></textarea>
                            </div>
                            <div className="pos-content-wrapper">
                                <div className="pos-content-wrapper-left">
                                    <Card style={{
                                        zIndex: 9
                                    }}>
                                        <Card.Header className="d-flex align-items-center gap-1">
                                            <h5>{t('NEW_PROCESS_MODAL.DOCUMENTS')}</h5>
                                            <div>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="d-flex align-items-center justify-content-between gap-1" variant="outline-primary" size="sm">
                                                        <FeatherIcons icon="plus" size={16} />
                                                        {t("CONTRACTS.OVERVIEW.FILES.ADD_FILES")}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item disabled={props?.roles?.edit} onClick={() => setShowUploadModal(!showUploadModal)} eventKey="1">{t("CONTRACTS.OVERVIEW.FILES.UPLOAD_FILES")}</Dropdown.Item>
                                                        <Dropdown.Item disabled={props?.roles?.edit} onClick={toggleSelectFileModal} eventKey="2">{t("CONTRACTS.OVERVIEW.FILES.SELECT_UPLOADED_FILES")}</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {showUploadModal && <CDNUploadPopup defaultContract={{
                                                    _id: props?.process?._id,
                                                    title: props?.process?.title,
                                                }} />}
                                            </div>
                                        </Card.Header>
                                        <Card.Body>
                                            {!refreshFileList && (<FilesList></FilesList>)}
                                        </Card.Body>
                                    </Card>
                                    <Card>
                                        <Card.Header>
                                            <h5>{t('NEW_PROCESS_MODAL.ACTIVITY_FEED')}</h5>
                                        </Card.Header>
                                        <Card.Body>
                                            <Chat contractId={props?.contracts?.processOverview?._id} />

                                        </Card.Body>
                                    </Card>
                                    <Reminder
                                        edit={props?.roles?.edit}
                                        toggleNewReminderModal={toggleNewReminderModal}
                                        showReminderModal={showReminderModal} />
                                </div>
                                <div className="pos-content-wrapper-right">
                                    <KeyTerms
                                        style={{
                                            zIndex: 9
                                        }}
                                        disabled={props?.roles?.edit}
                                        status={status}
                                        statuses={statuses}
                                        type={type}
                                        setStatus={setStatus}
                                        setStatuses={setStatuses}
                                        setType={setType}
                                        setActiveIndex={setActiveIndex}
                                        setAllowClose={props?.setAllowClose}
                                        allowClose={props?.allowClose}
                                        saveTrigger={saveTrigger}
                                        setAreYouSureModal={setAreYouSureModal}
                                        setSaveTrigger={setSaveTrigger}
                                    />

                                    <Dates style={{
                                        zIndex: 9
                                    }}
                                        disabled={props?.roles?.edit}
                                        setActiveIndex={setActiveIndex}
                                        setAllowClose={props?.setAllowClose}
                                        allowClose={props?.allowClose}
                                        saveTrigger={saveTrigger}
                                        setAreYouSureModal={setAreYouSureModal}
                                        setSaveTrigger={setSaveTrigger}>
                                    </Dates>

                                    <Parties style={{
                                        zIndex: 9
                                    }}
                                        disabled={props?.roles?.edit}
                                        setActiveIndex={setActiveIndex}
                                        setAllowClose={props?.setAllowClose}
                                        allowClose={props?.allowClose}
                                        saveTrigger={saveTrigger}
                                        setAreYouSureModal={setAreYouSureModal}
                                        setSaveTrigger={setSaveTrigger}>
                                    </Parties>

                                    <div>
                                        {props?.process?.parentContract && (
                                            <Card>
                                                <Card.Header className="d-flex align-items-center justify-content-start">
                                                    <h5>{t("CONTRACTS.OVERVIEW.PARENT_RECORD")}</h5>
                                                </Card.Header>

                                                <Card.Body
                                                    className={`key-terms ${props?.roles?.edit ? 'disabled-div' : ''}`}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f3f4f7',
                                                            border: '1px solid rgba(0, 0, 0, 0.025)',
                                                            padding: '16px 20px',
                                                            width: '100%',
                                                            borderRadius: '4px',
                                                            marginBottom: '10px',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',

                                                        }}
                                                    >
                                                        <span className="dotted-long"
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={(e: any) => {
                                                                setShowUploadModal(false)
                                                                props.toggleGenericContractOverviewModal({ contractId: props?.process?.parentContract?._id, show: true })
                                                            }}
                                                        >
                                                            {props?.process?.parentContract?.title}
                                                        </span>

                                                    </div>
                                                </Card.Body>


                                            </Card>
                                        )}

                                        {!props?.process?.parentContract && (
                                            <Card>

                                                <Card.Header className="d-flex align-items-center justify-content-start gap-1">
                                                    <h5>{t("CONTRACTS.OVERVIEW.SUB_RECORDS")}</h5>


                                                    <Dropdown >
                                                        <Dropdown.Toggle
                                                            disabled={props?.roles?.edit}
                                                            as={Button} variant="link" className="d-flex align-items-center justify-content-center" id="dropdown-basic">
                                                            <FeatherIcons icon="plus" size={16} />
                                                            {t('CONTRACTS.OVERVIEW.ADD_SUB_RECORD')}
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>

                                                            <Dropdown.Item
                                                                disabled={props?.roles?.edit}
                                                                onClick={el => {
                                                                    if (props?.roles?.edit) return;

                                                                    props?.toggleNewProcessModal({
                                                                        show: true, pages: ['addRecord'], currentPage: 'addRecord', extraProps: {
                                                                            parentContract: {
                                                                                _id: props?.process?._id,
                                                                                title: props?.process?.title
                                                                            }
                                                                        }
                                                                    })
                                                                }}>{t('CONTRACTS.OVERVIEW.NEW_RECORD')}
                                                            </Dropdown.Item>

                                                            <Dropdown.Item
                                                                onClick={event => {
                                                                    if (props?.roles?.edit) return;
                                                                    toggleSelectSubRecordModal()
                                                                }}
                                                            >{t('CONTRACTS.OVERVIEW.SELECT_SUB_RECORD')}
                                                            </Dropdown.Item>

                                                        </Dropdown.Menu>

                                                    </Dropdown>
                                                </Card.Header>
                                                <Card.Body
                                                    className={`key-terms ${props?.roles?.edit ? 'disabled-div' : ''}`}
                                                >
                                                    {(props?.process?.subContracts || [])?.map((e: any, i: number) => {
                                                        return (<div
                                                            style={{
                                                                backgroundColor: '#f3f4f7',
                                                                border: '1px solid rgba(0, 0, 0, 0.025)',
                                                                padding: '16px 20px',
                                                                width: '100%',
                                                                borderRadius: '4px',
                                                                marginBottom: '10px',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',

                                                            }}
                                                        >
                                                            <span className="dotted-long"
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={(event: any) => {
                                                                    setShowUploadModal(false)
                                                                    props.toggleGenericContractOverviewModal({ contractId: e?._id, show: true })
                                                                }}
                                                            >
                                                                {e?.title}
                                                            </span>
                                                            <span>
                                                                <Dropdown >
                                                                    <Dropdown.Toggle as={Button} variant="link" className="d-flex align-items-center justify-content-center" id="dropdown-basic">
                                                                        <i className="uil uil-ellipsis-h" style={{
                                                                            fontSize: "0.8rem"
                                                                        }}></i>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>

                                                                        <Dropdown.Item onClick={event => {
                                                                            console.log('removeSubModal', e)
                                                                            setSelectedSubContract(e);
                                                                            toggleRemoveSubRecordModal()
                                                                        }}>{t('CONTRACTS.OVERVIEW.REMOVE_RELATION')}
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item onClick={event => {
                                                                            console.log('deleteSubModal', e)
                                                                            setSelectedSubContract(e);
                                                                            toggleDeleteSubModal()
                                                                        }}>{t('CONTRACTS.OVERVIEW.DELETE_RECORD')}
                                                                        </Dropdown.Item>

                                                                    </Dropdown.Menu>

                                                                </Dropdown>
                                                            </span>
                                                        </div>)
                                                    })}
                                                </Card.Body>


                                            </Card>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </>
                    )
                }



            </div >



            <Modal
                contentClassName="border shadow"
                show={selectFileModal}
                onHide={toggleSelectFileModal}
                centered
                size="lg"
            >
                <Modal.Header className="bg-light" closeButton>
                    <Modal.Title>{t("CONTRACTS.OVERVIEW.FILES.SELECT_UPLOADED_FILES")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section">
                        <ContractsSelect
                            contractId={props?.process?._id}
                            isMulti={true}
                            onValueSubmit={(value: any) => {
                                setSelectedFiles(value);
                            }}


                        />

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '20px'

                        }}>
                            <Button
                                onClick={() => {
                                    if (selectedFiles?.length > 0) {
                                        props?.addFilesToContract(
                                            {
                                                contractId: props?.process?._id,
                                                files: selectedFiles
                                            }
                                        ).then((res: any) => {
                                            console.log('RES', res);
                                            setSelectedFiles([]);
                                        }
                                        ).catch((err: any) => {
                                            console.log('ERR', err);
                                        }
                                        )
                                    }
                                    toggleSelectFileModal();
                                }}
                                variant="primary" size="lg" className="d-flex align-items-center gap-1">
                                <FeatherIcons icon="plus" size={20} />
                                Süreç ile ilişkilendir
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                contentClassName="border shadow"
                show={selectSubRecordModal}
                onHide={toggleSelectSubRecordModal}
                centered
                size="lg"
            >
                <Modal.Header className="bg-light" closeButton>
                    <Modal.Title>{t("CONTRACTS.OVERVIEW.SELECT_SUB_RECORD")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="section">
                        <ParentlessSublessContracts
                            mainContractId={props?.process?._id}
                            isMulti={true}
                            onValueSubmit={(value: any) => {
                                console.log('first', value)
                                setSelectedSubRecords(value);
                            }}


                        />

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '20px'

                        }}>
                            <Button
                                onClick={() => {
                                    if (selectedSubRecords?.length > 0) {
                                        props?.addRecordsAsSubRecordToContract(
                                            {
                                                contractId: props?.process?._id,
                                                selectedSubRecords: selectedSubRecords?.map((el: any) => el?.value)
                                            }
                                        )
                                    }
                                    toggleSelectSubRecordModal();
                                }}
                                variant="primary" size="lg" className="d-flex align-items-center gap-1">
                                <FeatherIcons icon="plus" size={20} />
                                {t("CONTRACTS.OVERVIEW.RELATE_TO_THE_RECORD")}
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                contentClassName="border shadow"
                show={areYouSureModal}
                onHide={() => setAreYouSureModal(false)}
                centered
                size="lg"
            >
                <Modal.Header className="bg-light" closeButton>
                    <Modal.Title>{t("NEW_PROCESS_MODAL.ARE_YOU_SURE_TITLE")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('NEW_PROCESS_MODAL.ARE_SURE_TO_EXIT')}</p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '20px',
                        gap: '10px'

                    }}>
                        <Button
                            onClick={() => {
                                setSaveTrigger(saveTrigger + 1);
                            }}
                            variant="primary" className="d-flex align-items-center gap-1">
                            {t("NEW_PROCESS_MODAL.SAVE")}
                        </Button>
                        <Button
                            onClick={() => {
                                props?.toggleGenericContractOverviewModal({
                                    contractId: null,
                                    overview: {}
                                });
                                setAreYouSureModal(false);
                            }}
                            variant="secondary" className="d-flex align-items-center gap-1">
                            {t("NEW_PROCESS_MODAL.CANCEl")}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(ProcessOverviewScreen);
