import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import FeatherIcons from 'feather-icons-react';
import { Transforms } from 'slate';

export default function SmartFieldsElement(props: any) {

    const editor = useSlate()

    return (
        <Dropdown className='p-0 m-0'>
            <OverlayTrigger key={'toolbar_smart_field'} placement="bottom" overlay={
                <Tooltip>
                    Smart Fields
                </Tooltip>
            }>
                <Dropdown.Toggle
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                    }}
                    as={Button}>
                    <FeatherIcons icon='tag' size={18} />
                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='slate-toolbar-dd-menu'>

                {/* <span className='text-muted'>Default Field</span>
                {
                    (props?.defaultFields || []).map((item: any, index: number) => {
                        return (
                            <Dropdown.Item key={index} onMouseDown={(event: any) => {
                                event.preventDefault()
                                Transforms.select(editor, props?.target)
                                Transforms.insertNodes(
                                    editor,
                                    { type: 'default-field', field: item?.key + '', label: item?.title, children: [{ text: '' }] }
                                )
                                Transforms.move(editor)
                            }}>
                                {item?.title}
                            </Dropdown.Item>
                        )
                    })
                } */}
                <span className='text-muted'>Smart Field</span>
                {
                    (props?.customFields?.filter((item: any) => item?.CustomComponent != 'Formula') || []).map((item: any, index: number) => {
                        return (
                            <Dropdown.Item key={index} onMouseDown={(event: any) => {
                                event.preventDefault()
                                Transforms.select(editor, props?.target)
                                Transforms.insertNodes(
                                    editor,
                                    { type: 'custom-field', field: item?._id + '', fieldKey: item?.key, label: item?.title, children: [{ text: '' }] }
                                )
                                Transforms.move(editor)
                            }}>
                                {item?.title}
                            </Dropdown.Item>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}
