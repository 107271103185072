import React, { useEffect, useState } from "react";

import { Card, ListGroup, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { getListCustomFields, getCompanyStatusesList } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal, toggleGenericAddStatusModal } from "@redux/components/index.actions";

import { connect } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts
});
const mapActionsToProps = {
  getListCustomFields,
  getCompanyStatusesList,
  toggleGenericAddCustomFieldModal,
  toggleGenericAddStatusModal
};

const FieldList = (props: any) => {
  const { t } = useTranslation();

  const [statuses, setStatuses] = useState([]);



  return (
    <Card style={{
      marginBottom: "0"
    }}>

      <Card.Body>
        <div className="custom-card-header">
          <h6>{t("CONTRACTS_TYPES.STATUSES")}</h6>
          <Button variant="secondary" onClick={props?.toggleGenericAddStatusModal}>
            + {t("CONTRACTS_TYPES.ADD_NEW")}
          </Button>
        </div>
        <Droppable key={1} droppableId={"statusFieldList"}>
          {(provided, snapshot) => (
            <div className="field-list" ref={provided.innerRef}>
              <ListGroup as="ul">
                {(props?.contracts?.newContractTypeStatuses || []).map((item: any, index: number) => (
                  <Draggable
                    key={item?._id}
                    draggableId={item?._id + ""}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListGroup.Item
                          key={index}
                          as="li"
                          className="rounded mb-1 border-1"
                        >
                          <div className="d-flex gap-1 align-items-center justify-content-between">
                            <span>{item?.title}</span>
                            <Button
                              onClick={() => {
                                props?.setNewContractTypeData({
                                  key: "addStatus",
                                  data: {
                                    sourceId: 'statusFieldList',
                                    destinationId: 'statusFieldList',
                                    sourceIndex: index,
                                    destinationIndex: props?.contracts?.newContractType?.statuses?.length || 0,
                                  }
                                })
                              }}

                              variant="link" size="sm" >
                              <FeatherIcons icon="plus" size="16" />
                            </Button>

                          </div>
                        </ListGroup.Item>
                      </div>
                    )}
                  </Draggable>
                ))}
              </ListGroup>
              {provided.placeholder}
            </div>
          )}
        </Droppable>


      </Card.Body>
    </Card>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(FieldList);
