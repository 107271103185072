import React, { useEffect, useState } from "react";
import FlatList from "@components/FlatList";
import { connect } from "react-redux";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { getCompanyUserList } from '@redux/companies/index.actions';
import { addAssignedUserToContract, removeAssignedUserToContract } from '@redux/contract/index.actions';
import { DefaultLoader } from '@components/Loader';
import _ from "lodash";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import apiInstance from "@clients/api.client";
const mapStateToProps = (state: any) => ({
    users: state.users,
    companies: state.companies,
    contracts: state.contracts
})

const mapActionsToProps = { getCompanyUsers: getCompanyUserList, addAssignedUserToContract, removeAssignedUserToContract }


const AssignedUsersFlatList: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshed, setRefreshed] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [batchSize, setBatchSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [more, setMore] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const loadMore = () => {


        apiInstance.get(`/contracts/parentless/${props?.companies?.company?._id}`, {
            params: {
                batchSize: batchSize,
                page: page,
                searchText: searchText
            }

        }).then((res: any) => {
            ((res?.data?.pagination?.page * batchSize) < total) ? setMore(true) : setMore(false)
            if ((res?.data?.pagination?.page * batchSize) < total) {
                setMore(true);
                setPage(res?.data?.pagination?.page + 1)
            } else {
                setMore(false);
            }
            setData([...data, ...res?.data?.node?.contracts])
        })

    }

    const handleSearch = (event: any) => {
        setLoading(true)
        setPage(1);
        setSearchText(event?.target?.value)
    }


    const handleRemove = (data: any) => {
        props?.onUserRemove(data);
    }

    const handleAdd = (data: any) => {
        props?.onUserAdd(data);
    }
    const debounceSearch = _.debounce(handleSearch, 600);

    useEffect(() => {

        apiInstance.get(`/contracts/parentless/${props?.companies?.company?._id}`, {
            params: {
                batchSize: batchSize,
                page: page,
                searchText: searchText
            }

        }).then((res: any) => {
            console.log('CONTRACTS', res?.data)
            if (res?.data?.pagination?.totalCount) setTotal(res?.data?.pagination?.totalCount);
            if ((res?.data?.pagination?.page * batchSize) < res?.data?.pagination?.totalCount) {
                setMore(true);
                setPage(page + 1);
            } else {
                setMore(false)
            }
            setData([...res?.data?.node?.contracts])
            setLoading(false);
        })



    }, [searchText])

    return (
        <>
            <div className="search">
                <input
                    placeholder={t("Search a process...")}
                    type="text"
                    name="searchText"
                    defaultValue={searchText}
                    onInput={debounceSearch}
                />
            </div>
            <div className="flat-scroll" id="contractContactsFlatList2">
                {
                    (loading) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {
                    (!refreshed) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {refreshed && <FlatList
                    scrollableParentTarget={"contractContactsFlatList2"}
                    data={data}
                    renderItem={(item, index) => (
                        <div

                            onClick={() => {
                                if (props?.parentContract?._id == item?._id) {
                                    console.log('REMOVE')
                                    props?.onUnSelect(null)
                                } else {
                                    props?.onSelect({
                                        _id: item?._id,
                                        title: item?.title
                                    })
                                }

                            }}

                            key={item?._id} className="parent-item d-flex justify-content-between align-items-center gap-1 flex-wrap p-1">
                            <span className="d-flex justify-content-center align-items-center gap-1">
                                {props?.parentContract?._id == item?._id && <FeatherIcons icon="check" size={16} />}<span className="text-dark text-nowrap">{item?.title}</span>
                            </span>

                            {/* {
                                props?.assignedUsers?.map((user: any) => user?._id)?.includes(item?._id) ? (
                                    <Button
                                        variant="link"
                                        size="sm"
                                        onClick={() => handleRemove(item)}
                                    >
                                        <FeatherIcons icon="x" size={14} />
                                    </Button>
                                ) : (
                                    <Button
                                        variant="link"
                                        size="sm"
                                        onClick={() => handleAdd(item)}

                                    >
                                        <FeatherIcons icon="plus" size={14} />
                                    </Button>
                                )

                            } */}

                        </div>
                    )}
                    hasMore={more}
                    endMessage={
                        <div className="d-flex align-items-center justify-content-center no-more">
                            {t("CONTRACTS.OVERVIEW.ASSIGNED_USERS.ALL_DATA_LOADED")}
                        </div>
                    }
                    onEndReached={() => {
                        loadMore()
                    }}
                    onEndReachedThreshold={0.5}
                    keyExtractor={(item, index) => index.toString()}
                />
                }

            </div>
        </>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(AssignedUsersFlatList);
