import isHotkey from 'is-hotkey'
import { Editable, withReact, useSlate, Slate, useSlateStatic, ReactEditor, useSelected, useFocused } from 'slate-react'
import {
    Editor,
    Transforms,
    createEditor,
    Descendant,
    Element as SlateElement,
    Node,
    Range,
    Path,
} from 'slate'
import { withHistory, HistoryEditor } from 'slate-history'
import { Button as Button2, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Button, Toolbar } from '@components/SlateEditor/components/index'
import { BsPlusLg } from 'react-icons/bs'
import { addTableColumn, addTableRow } from '@components/SlateEditor/utils/table.utils'
import {
    HOTKEYS,
    LIST_TYPES,
    TEXT_ALIGN_TYPES,
    TEST_CUSTOM_FIELDS,
    TEST_CLAUSES,
    HIGHLIGHT_COLORS,
    FONT_COLORS,
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import { css } from '@emotion/css'
import FeatherIcons from 'feather-icons-react'

export const toggleBlock = (editor: any, format: any) => {
    const isActive = isBlockActive(
        editor,
        format,
        //@ts-ignore
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: (n: any) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties: any;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes<SlateElement>(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

export const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}
interface CustomElement {
    type: string;
    [key: string]: any; // Bu satırı ekleyin
}
export const isBlockActive = (editor: any, format: any) => {
    const { selection }: any = editor
    if (!selection) return false

    const [match]: any = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: (n: any) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === format, // Burada 'blockType' yerine direkt 'type' kullanılıyor
        })
    )

    return !!match
}
export const isMarkActive = (editor: any, format: any) => {
    const marks: any = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

export const withCustomFields = (editor: any) => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = (element: any) => {
        return element.type === 'custom-field' ? true : isInline(element)
    }

    editor.isVoid = (element: any) => {
        return element.type === 'custom-field' ? true : isVoid(element)
    }

    editor.markableVoid = (element: any) => {
        return element.type === 'custom-field' || markableVoid(element)
    }

    return editor
}
export const withDefaultFields = (editor: any) => {
    const { isInline, isVoid, markableVoid } = editor

    editor.isInline = (element: any) => {
        return element.type === 'default-field' ? true : isInline(element)
    }

    editor.isVoid = (element: any) => {
        return element.type === 'default-field' ? true : isVoid(element)
    }

    editor.markableVoid = (element: any) => {
        return element.type === 'default-field' || markableVoid(element)
    }

    return editor
}
export const withClauses = (editor: any) => {
    const { insertData, insertText, isInline, isVoid, markableVoid } = editor

    editor.isInline = (element: any) => {
        return element.type === 'clause' ? true : isInline(element)
    }

    editor.isVoid = (element: any) => {
        return element.type === 'clause' ? true : isVoid(element)
    }

    editor.markableVoid = (element: any) => {
        return element.type === 'clause' || markableVoid(element)
    }

    /*   editor.insertText = (text: any) => {
  
          const { selection } = editor
  
          if (selection && Range.isCollapsed(selection)) {
              const { anchor } = selection
              const block = Editor.above(editor, {
                  match: (n: any) => Editor.isBlock(editor, n),
              })
              const path = block ? block[1] : []
              const start = Editor.start(editor, path)
              const range = { anchor, focus: start }
              const beforeText = Editor.string(editor, range)
              console.log('beforeText', beforeText)
              if (beforeText === '' && text === ' ') {
                  Transforms.select(editor, range)
                  Transforms.insertText(editor, text)
                  return
              }
          }
  
          insertText(text)
  
      }
  
      editor.insertData = (data: any) => {
          const text = data.getData('text/plain')
          console.log('TEXT', text)
      } */

    return editor
}

const Image = ({ attributes, children, element }: any) => {
    const editor = useSlate()
    const path = ReactEditor.findPath(editor, element)

    const selected = useSelected()
    const focused = useFocused()
    return (
        <div {...attributes}>
            {children}
            <div

                contentEditable={false}
                className={css`
          position: relative;
          padding: 10px;
        `}
            >
                <img
                    onClick={() => {
                        // Focused 
                        Transforms.select(editor, path)

                    }}
                    src={element.url}
                    className={css`
            display: block;
            max-width: 100%;
            max-height: 20em;
            box-shadow: ${selected && focused ? '0 0 0 3px #B4D5FF' : 'none'};
          `}
                />
                <Button
                    active
                    onClick={() => {
                        console.log('Image deletion on server!')
                        Transforms.removeNodes(editor, { at: path })
                    }}
                    className={css`
            display: ${selected && focused ? 'inline' : 'none'};
            position: absolute;
            top: 0.5em;
            left: 0.5em;
            background-color: red;
          `}
                >
                    <FeatherIcons icon="x" size="20" />
                </Button>
            </div>
        </div>
    )
}

export const Element = (props: any) => {

    const { attributes, children, element } = props

    const editor = useSlate()


    const styles = {
        textAlign: element.align || 'left',
        ...element?.style
    }


    switch (element.type) {
        case 'page-break':
            return (
                <div
                    {...attributes}
                    contentEditable={false}
                    style={{
                        padding: '3px 3px 2px',
                        verticalAlign: 'baseline',
                        borderRadius: '4px',
                        backgroundColor: '#eee',
                        fontSize: '0.9em',
                        width: '100%',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}>
                    Page Break{children}
                </div>
            )
        case 'header':
            return (
                <div
                    {...attributes}
                    style={{
                        display: 'block',
                        width: '100%',
                        backgroundColor: '#eee',
                        minHeight: '50px',
                        border: '1px solid #ddd',
                    }}>
                    {children}
                </div>

            )
        case 'header-content':
            return (
                <p
                    {...attributes}
                    style={{
                        backgroundColor: 'red',
                        width: '100%',
                        height: '100%',
                    }}>
                    {children || 'Header Content'}
                </p>

            )
        case 'footer':
            return (
                <div
                    {...attributes}
                    contentEditable={false}
                    style={{
                        padding: '3px 3px 2px',
                        verticalAlign: 'baseline',
                        borderRadius: '4px',
                        backgroundColor: '#eee',
                        fontSize: '0.9em',
                        width: '100%',
                        marginBottom: '0',
                        position: 'absolute',
                        bottom: '0',
                        left: '0',
                    }}>
                    Page Break{children}
                </div>
            )
        case 'custom-field':
            console.log('ELEMENT', element)
            return (
                <span
                    {...attributes}
                    contentEditable={false}
                    style={{
                        padding: '3px 3px 2px',
                        margin: '0 1px',
                        verticalAlign: 'baseline',
                        display: 'inline-block',
                        borderRadius: '4px',
                        backgroundColor: '#eee',
                        fontSize: '0.9em',
                        boxShadow: '0 0 0 2px #B4D5FF',
                    }}>
                    {element?.labelValue || element?.label}
                    {children}
                </span>
            )
        case 'default-field':
            return (
                <span
                    {...attributes}
                    contentEditable={false}
                    style={{
                        padding: '3px 3px 2px',
                        margin: '0 1px',
                        verticalAlign: 'baseline',
                        display: 'inline-block',
                        borderRadius: '4px',
                        backgroundColor: '#eee',
                        fontSize: '0.9em',
                        boxShadow: '0 0 0 2px #B4D5FF',
                    }}>
                    {element?.labelValue || element?.label}
                    {children}
                </span>
            )
        case 'clause':
            return (
                <Card
                    {...attributes}
                    contentEditable={true}
                    style={{
                        display: 'block',
                        width: '100%'
                    }}>
                    <Card.Header>

                        {element?.clause?.clauseTitle}
                    </Card.Header>
                    <Card.Body

                    >
                        {element?.clause?.clauseDescription}
                        {children}

                    </Card.Body>


                </Card>
            )
        case 'block-quote':
            return (
                <blockquote style={styles} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'image':
            return <Image {...props}
            />
        /*  return (
             < >
                 {children}
                 {element?.url && <img {...attributes} style={{
                     ...styles,
                     maxWidth: '100%',
                     maxHeight: '20em',
                     display: 'block',
                     marginLeft: 'auto',
                     marginRight: 'auto'

                 }} src={element?.url} />}
             </>
         ) */
        case 'code':
            return (
                <pre style={{
                    ...styles,
                    padding: '20px',
                    backgroundColor: '#f6f8fa',
                    borderRadius: '5px',
                    overflowX: 'auto',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    fontFamily: 'monospace',
                    fontSize: '16px'

                }} {...attributes}>
                    {children}
                </pre>
            )
        case 'bulleted-list':
            return (
                <ul style={styles} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            return (
                <h1 style={styles} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            return (
                <h2 style={styles} {...attributes}>
                    {children}
                </h2>
            )
        case 'heading-three':
            return (
                <h3 style={styles} {...attributes}>
                    {children}
                </h3>
            )
        case 'heading-four':
            return (
                <h4 style={styles} {...attributes}>
                    {children}
                </h4>
            )
        case 'heading-five':
            return (
                <h5 style={styles} {...attributes}>
                    {children}
                </h5>
            )
        case 'heading-six':
            return (
                <h6 style={styles} {...attributes}>
                    {children}
                </h6>
            )
        case 'list-item':
            return (
                <li style={styles} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={styles} {...attributes}>
                    {children}
                </ol>
            )
        case 'paragraph':
            return (
                <p style={styles} {...attributes}>
                    {children}
                </p>
            )
        case 'table':
            return (
                <div className="slate-table-wrapper">
                    <div className='slate-table'>
                        <table className='table table-bordered' style={styles} {...attributes}>
                            <tbody>
                                {children}
                            </tbody>
                        </table>
                        <Button
                            className='table-add-row'
                            onMouseDown={(event: any) => {
                                event.preventDefault(); // Editörün odaklanmasını önle
                                event.stopPropagation();
                                addTableRow(editor)
                            }}
                        >
                            <span>
                                <BsPlusLg />
                            </span>
                        </Button>
                        <Button
                            className='table-add-column'
                            onMouseDown={(event: any) => {
                                event.preventDefault(); // Editörün odaklanmasını önle
                                event.stopPropagation();
                                addTableColumn(editor)
                            }}
                        >
                            <span>
                                <BsPlusLg />
                            </span>
                        </Button>
                    </div>
                </div>

            )
        case 'table-row':
            return (
                <tr style={styles} {...attributes}>
                    {children}
                </tr>
            )
        case 'table-cell':
            return (
                <td style={styles} {...attributes}>
                    {children}
                </td>
            )

        case 'div':
            return (
                <div {...attributes}>
                    {children}
                </div>
            )
        default:
            return (
                <p style={styles} {...attributes}>
                    {children}
                </p>
            )
    }
}

export const Leaf = ({ attributes, children, leaf }: any) => {
    if (leaf.backgroundColor) {
        children = <span style={{ backgroundColor: leaf.backgroundColor }}>{children}</span>
    }

    if (leaf.color) {
        children = <span style={{ color: leaf.color }}>{children}</span>
    }

    if (leaf.fontSize) {
        children = <span style={{ fontSize: leaf.fontSize }}>{children}</span>
    }

    if (leaf.fontFamily) {
        children = <span style={{ fontFamily: leaf.fontFamily }}>{children}</span>
    }

    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }



    return <span {...attributes}>{children}</span>
}

export const BlockButton = ({ format, icon, specialIcon }: any) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(
                editor,
                format,
                //@ts-ignore
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            )}
            onMouseDown={(event: any) => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            {specialIcon ? specialIcon : icon}
        </Button>
    )
}

export const MarkButton = ({ format, icon, specialIcon }: any) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={(event: any) => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            {specialIcon ? specialIcon : icon}
        </Button>
    )
}

export const activeStyleName = (editor: any, format: any) => {
    /* isBlockActive(editor, 'paragraph') ? 'Paragraph' :
                                                    isBlockActive(editor, 'heading-one') ? 'Heading 1' :
                                                        isBlockActive(editor, 'heading-two') ? 'Heading 2' :
                                                            isBlockActive(editor, 'heading-three') ? 'Heading 3' :
                                                                isBlockActive(editor, 'heading-four') ? 'Heading 4' :
                                                                    isBlockActive(editor, 'heading-five') ? 'Heading 5' :
isBlockActive(editor, 'heading-six') ? 'Heading 6' : 'Paragraph' */

    if (isBlockActive(editor, 'paragraph')) {
        return 'Paragraph'
    } else if (isBlockActive(editor, 'heading-one')) {
        return 'Heading 1'
    } else if (isBlockActive(editor, 'heading-two')) {
        return 'Heading 2'
    } else if (isBlockActive(editor, 'heading-three')) {
        return 'Heading 3'
    } else if (isBlockActive(editor, 'heading-four')) {
        return 'Heading 4'
    } else if (isBlockActive(editor, 'heading-five')) {
        return 'Heading 5'
    } else if (isBlockActive(editor, 'heading-six')) {
        return 'Heading 6'
    } else {
        return 'Paragraph'
    }

}

export const findActiveMark = (editor: any): string => {
    for (const format of ['paragraph', 'heading-one', 'heading-two', 'heading-three', 'heading-four', 'heading-five', 'heading-six']) {
        if (isBlockActive(editor, format)) {
            return format; // Eğer aktif blok bulunursa format döndürülür
        }
    }
    return 'paragraph'; // Hiçbir format aktif değilse 'paragraph' döndürülür
}