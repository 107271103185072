import React from "react";

function PdfFile(props: any) {
    return (
        <svg
            width={props?.width || "35px"}
            height={props?.height || "35px"}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 75.32 92.604"
        >
            <path
                fill="#ff2116"
                d="M-29.633 123.947c-3.552 0-6.443 2.894-6.443 6.446v49.498c0 3.551 2.891 6.445 6.443 6.445h37.85c3.552 0 6.443-2.893 6.443-6.445v-40.702s.102-1.191-.416-2.351a6.516 6.516 0 00-1.275-1.844 1.058 1.058 0 00-.006-.008l-9.39-9.21a1.058 1.058 0 00-.016-.016s-.802-.764-1.99-1.274c-1.4-.6-2.842-.537-2.842-.537l.021-.002z"
                color="#000"
                fontFamily="sans-serif"
                overflow="visible"
                paintOrder="markers fill stroke"

                transform="translate(53.548 -183.975) scale(1.4843)"
            ></path>
            <path
                fill="#f5f5f5"
                d="M-29.633 126.064h28.38a1.058 1.058 0 00.02 0s1.135.011 1.965.368a5.385 5.385 0 011.373.869l9.368 9.19s.564.595.838 1.208c.22.495.234 1.4.234 1.4a1.058 1.058 0 00-.002.046v40.746a4.294 4.294 0 01-4.326 4.328h-37.85a4.294 4.294 0 01-4.326-4.328v-49.498a4.294 4.294 0 014.326-4.328z"
                color="#000"
                fontFamily="sans-serif"
                overflow="visible"
                paintOrder="markers fill stroke"

                transform="translate(53.548 -183.975) scale(1.4843)"
            ></path>
            <path
                fill="#ff2116"
                d="M-23.408 161.093c-1.456-1.457.12-3.458 4.397-5.584l2.691-1.337 1.049-2.294a90.314 90.314 0 001.912-4.575l.864-2.281-.595-1.688c-.732-2.075-.994-5.194-.529-6.316.63-1.519 2.69-1.363 3.506.265.638 1.272.572 3.575-.183 6.48l-.62 2.381.546.926c.3.51 1.177 1.719 1.948 2.687l1.449 1.803 1.803-.235c5.73-.748 7.692.523 7.692 2.345 0 2.299-4.499 2.489-8.277-.165-.85-.597-1.433-1.19-1.433-1.19s-2.367.482-3.532.796c-1.203.324-1.803.527-3.564 1.122 0 0-.619.898-1.021 1.55-1.499 2.428-3.249 4.44-4.498 5.173-1.4.82-2.866.875-3.605.137zm2.286-.817c.82-.506 2.477-2.466 3.624-4.285l.464-.737-2.115 1.064c-3.266 1.642-4.76 3.19-3.984 4.126.437.526.96.483 2.011-.168zm21.218-5.955c.801-.561.685-1.692-.22-2.148-.705-.354-1.273-.427-3.103-.4-1.125.076-2.934.303-3.24.372 0 0 .993.687 1.434.94.587.335 2.015.957 3.057 1.276 1.027.315 1.622.282 2.072-.04zm-8.531-3.546c-.485-.51-1.309-1.573-1.832-2.364-.683-.896-1.026-1.528-1.026-1.528s-.5 1.606-.91 2.573l-1.278 3.161-.37.717s1.97-.646 2.973-.908c1.062-.277 3.218-.701 3.218-.701zm-2.75-11.026c.124-1.038.177-2.074-.157-2.596-.924-1.01-2.04-.168-1.851 2.235.063.809.264 2.19.533 3.042l.488 1.549.343-1.167c.19-.641.48-2.02.645-3.063z"
                transform="translate(53.548 -183.975) scale(1.4843)"
            ></path>
            <path
                fill="#2c2c2c"
                d="M-20.93 167.839h2.365q1.133 0 1.84.217.706.21 1.19.944.482.728.482 1.756 0 .945-.392 1.624-.392.678-1.056.98-.658.3-2.03.3h-.818v3.73h-1.581zm1.58 1.224v3.33h.785q1.05 0 1.448-.391.406-.392.406-1.274 0-.657-.266-1.063-.266-.413-.588-.504-.315-.098-1-.098zm5.508-1.224h2.148q1.56 0 2.49.552.938.553 1.414 1.645.483 1.091.483 2.42 0 1.4-.434 2.499-.427 1.091-1.316 1.763-.881.672-2.518.672h-2.267zm1.58 1.266v7.018h.659q1.378 0 2-.952.623-.958.623-2.553 0-3.513-2.623-3.513zm6.473-1.266h5.304v1.266h-3.723v2.855h2.981v1.266h-2.98v4.164H-5.79z"
                fontFamily="Franklin Gothic Medium Cond"
                letterSpacing="0"
                style={{ lineHeight: "125%" }}
                transform="translate(53.548 -183.975) scale(1.4843)"
                wordSpacing="4.26"
            ></path>
        </svg>
    );
}

export default PdfFile;
