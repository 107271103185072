import React from "react";

function GoogleWordDocx(props: any) {
    return (
        <svg
            width={props?.width || "35px"}
            height={props?.height || "35px"}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"

            viewBox="0 0 47 65"
        >
            <defs>
                <path
                    id="path-1"
                    d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L29.375 0z"
                ></path>
                <path
                    id="path-3"
                    d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L29.375 0z"
                ></path>
                <linearGradient
                    id="linearGradient-5"
                    x1="50.005%"
                    x2="50.005%"
                    y1="8.586%"
                    y2="100.014%"
                >
                    <stop offset="0%" stopColor="#1A237E" stopOpacity="0.2"></stop>
                    <stop offset="100%" stopColor="#1A237E" stopOpacity="0.02"></stop>
                </linearGradient>
                <path
                    id="path-6"
                    d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L29.375 0z"
                ></path>
                <path
                    id="path-8"
                    d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L29.375 0z"
                ></path>
                <path
                    id="path-10"
                    d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L29.375 0z"
                ></path>
                <path
                    id="path-12"
                    d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L29.375 0z"
                ></path>
                <path
                    id="path-14"
                    d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L29.375 0z"
                ></path>
                <radialGradient
                    id="radialGradient-16"
                    cx="3.168%"
                    cy="2.717%"
                    r="161.249%"
                    fx="3.168%"
                    fy="2.717%"
                    gradientTransform="matrix(1 0 0 .72308 0 .008)"
                >
                    <stop offset="0%" stopColor="#FFF" stopOpacity="0.1"></stop>
                    <stop offset="100%" stopColor="#FFF" stopOpacity="0"></stop>
                </radialGradient>
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(-451 -463)">
                    <g transform="translate(0 63)">
                        <g transform="translate(277 309)">
                            <g transform="translate(174 91)">
                                <g>
                                    <g>
                                        <mask id="mask-2" fill="#fff">
                                            <use xlinkHref="#path-1"></use>
                                        </mask>
                                        <path
                                            fill="#4285F4"
                                            fillRule="nonzero"
                                            d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L36.719 10.34 29.375 0z"
                                            mask="url(#mask-2)"
                                        ></path>
                                    </g>
                                    <g>
                                        <mask id="mask-4" fill="#fff">
                                            <use xlinkHref="#path-3"></use>
                                        </mask>
                                        <path
                                            fill="url(#linearGradient-5)"
                                            fillRule="nonzero"
                                            d="M30.6638281 16.4309659L47 32.8582386 47 17.7272727z"
                                            mask="url(#mask-4)"
                                        ></path>
                                    </g>
                                    <g>
                                        <mask id="mask-7" fill="#fff">
                                            <use xlinkHref="#path-6"></use>
                                        </mask>
                                        <path
                                            fill="#F1F1F1"
                                            fillRule="nonzero"
                                            d="M11.75 47.273h23.5v-2.955h-23.5v2.955zm0 5.909h17.625v-2.955H11.75v2.955zm0-20.682v2.955h23.5V32.5h-23.5zm0 8.864h23.5v-2.955h-23.5v2.955z"
                                            mask="url(#mask-7)"
                                        ></path>
                                    </g>
                                    <g>
                                        <mask id="mask-9" fill="#fff">
                                            <use xlinkHref="#path-8"></use>
                                        </mask>
                                        <g mask="url(#mask-9)">
                                            <g transform="translate(26.438 -2.955)">
                                                <path
                                                    fill="#A1C2FA"
                                                    fillRule="nonzero"
                                                    d="M2.938 2.955V16.25c0 2.449 1.971 4.432 4.406 4.432h13.218L2.938 2.955z"
                                                ></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g>
                                        <mask id="mask-11" fill="#fff">
                                            <use xlinkHref="#path-10"></use>
                                        </mask>
                                        <path
                                            fill="#FFF"
                                            fillOpacity="0.2"
                                            fillRule="nonzero"
                                            d="M4.406 0C1.983 0 0 1.994 0 4.432v.37C0 2.363 1.983.368 4.406.368h24.969V0H4.406z"
                                            mask="url(#mask-11)"
                                        ></path>
                                    </g>
                                    <g>
                                        <mask id="mask-13" fill="#fff">
                                            <use xlinkHref="#path-12"></use>
                                        </mask>
                                        <path
                                            fill="#1A237E"
                                            fillOpacity="0.2"
                                            fillRule="nonzero"
                                            d="M42.594 64.63H4.406C1.983 64.63 0 62.637 0 60.2v.37C0 63.005 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-.37c0 2.438-1.983 4.433-4.406 4.433z"
                                            mask="url(#mask-13)"
                                        ></path>
                                    </g>
                                    <g>
                                        <mask id="mask-15" fill="#fff">
                                            <use xlinkHref="#path-14"></use>
                                        </mask>
                                        <path
                                            fill="#1A237E"
                                            fillOpacity="0.1"
                                            fillRule="nonzero"
                                            d="M33.781 17.727c-2.434 0-4.406-1.983-4.406-4.432v.37c0 2.448 1.972 4.432 4.406 4.432H47v-.37H33.781z"
                                            mask="url(#mask-15)"
                                        ></path>
                                    </g>
                                </g>
                                <path
                                    fill="url(#radialGradient-16)"
                                    fillRule="nonzero"
                                    d="M29.375 0H4.406C1.983 0 0 1.994 0 4.432v56.136C0 63.006 1.983 65 4.406 65h38.188C45.017 65 47 63.006 47 60.568v-42.84L29.375 0z"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default GoogleWordDocx;
