import './index.scss';
import FeatherIcons from 'feather-icons-react';
import {
    CheckIcon,
    ChangeRequestIcon,
    RejectedIcon,
    AssignIcon,
    NotNecessaryIcon,
} from './icons';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * PageTitle
 */
const StatusBadge = (props: any) => {
    return (
        <span className='d-inline-flex align-items-center justify-content-center gap-1 p-0 m-0'>

            <span className='p-0 m-0'>
                ({props?.title || 'Created'})
            </span>
            <span className='status-badge-icon p-0 m-0'>
                {(() => {
                    switch (props?.className) {
                        case 'CREATED':
                            return <FeatherIcons icon='loader' size="16" />;
                        case 'PENDING':
                            return <FeatherIcons icon='loader' size="16" />;
                        case 'APPROVED':
                            return <FeatherIcons icon='check' size="16" />;
                        case 'CANCELED':
                            return <FeatherIcons icon='x-circle' size="16" />;
                        case 'REJECTED':
                            return <RejectedIcon width={10} height={10} />;
                        case 'REASSIGNED':
                            return <AssignIcon />;
                        default:
                            return <FeatherIcons icon='clock' size="16" />;
                    }
                })()}
            </span>
        </span>
    );
};

export const StepStatusBadge = (props: any) => {
    return (
        <>
            {(() => {
                switch (props?.className) {
                    case 'APPROVED':
                        return (
                            <span className={'step-status step-status-' + (props?.className || 'created').toLocaleLowerCase('en')}>
                                <FeatherIcons color="#2EB818" icon='check' size="18" />
                            </span>
                        );
                    case 'REJECTED':
                        return (
                            <span className={'step-status step-status-' + (props?.className || 'created').toLocaleLowerCase('en')}>
                                <FeatherIcons color="#F0233B" icon='x' size="18" />
                            </span>
                        );
                    default:
                        return "";
                }
            })()}
        </>
    );
};
export const StepUserStatusBadge = (props: any) => {
    return (
        <span className={'status-user-badge color-' + (props?.className || 'created').toLocaleLowerCase('en')}>
            <span className='status-badge-icon'>
                {(() => {
                    switch (props?.className) {
                        case 'CREATED':
                            return <FeatherIcons icon='clock' size="14" />;
                        case 'PENDING':
                            return <FeatherIcons icon='loader' size="14" />;
                        case 'APPROVED':
                            return <CheckIcon />;
                        case 'CANCELED':
                            return <FeatherIcons icon='x-circle' size="14" />;
                        case 'REJECTED':
                            return <RejectedIcon />;
                        case 'REASSIGNED':
                            return <AssignIcon />;
                        case 'CHANGE_REQUESTED':
                            return <ChangeRequestIcon />;
                        default:
                            return <FeatherIcons icon='clock' size="14" />;
                    }
                })()}
            </span>
            <span style={{
                color: '#000000',
                paddingLeft: '5px',
                fontWeight: 400,
                fontSize: '13px',
            }}>
                {props?.title || 'Created'}
            </span>
        </span>
    );
};

export const InternalApprovePopupStepUserStatusBadge = (props: {
    rejectedUser: any;
    rejectedStep: any;
    fileStatus: string;
    stepStatus: string;
    userStatus: string;
}) => {

    const { t } = useTranslation();


    let { fileStatus, stepStatus, userStatus, rejectedStep, rejectedUser } = props;

    const userRejectStatus = rejectedUser?.status;
    const stepRejectStatus = rejectedStep?.status;
    let statusMessage = '';

    if (fileStatus == 'PENDING') {
        if (stepStatus == 'CREATED') {
            userStatus = 'QUEUE';
        }
        if (stepStatus == 'APPROVED') {

            if (userStatus == 'PENDING') {
                userStatus = 'NOT_NECESSARY';
                statusMessage = t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_MESSAGES.APPROVE_ANYONE_MESSAGE')
            }
        }

    } else if (fileStatus == 'REJECTED') {
        if (stepStatus == 'CREATED') {
            if (userStatus == 'CREATED') {
                userStatus = 'NOT_NECESSARY';
                if (userRejectStatus == 'REJECTED') {
                    statusMessage = t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_MESSAGES.REJECTED_MESSAGE')
                } else if (userRejectStatus == 'CHANGE_REQUESTED') {
                    statusMessage = t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_MESSAGES.SUGGECTED_CHANGE_MESSAGE')
                }
            }
            if (userStatus == 'PENDING') {
                userStatus = 'NOT_NECESSARY';
            }
        } else if (stepStatus == 'REJECTED') {
            if (userStatus == 'CREATED') {
                userStatus = 'NOT_NECESSARY';
                if (userRejectStatus == 'REJECTED') {
                    statusMessage = t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_MESSAGES.REJECTED_MESSAGE')
                } else if (userRejectStatus == 'CHANGE_REQUESTED') {
                    statusMessage = t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_MESSAGES.SUGGECTED_CHANGE_MESSAGE')
                }
            }
            if (userStatus == 'PENDING') {
                userStatus = 'NOT_NECESSARY';
                if (userRejectStatus == 'REJECTED') {
                    statusMessage = t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_MESSAGES.REJECTED_MESSAGE')
                } else if (userRejectStatus == 'CHANGE_REQUESTED') {
                    statusMessage = t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_MESSAGES.SUGGECTED_CHANGE_MESSAGE')
                }
            }
        }
    } else if (fileStatus == 'APPROVED') {
        if (stepStatus == 'APPROVED') {

            if (userStatus == 'PENDING') {
                userStatus = 'NOT_NECESSARY';
                statusMessage = t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_MESSAGES.APPROVE_ANYONE_MESSAGE')
            }
        }
    }
    else {

    }

    return (
        <span className={'status-user-badge color-' + (userStatus || 'created').toLocaleLowerCase('en')}>
            <span className='status-badge-icon'>
                {(() => {
                    switch (userStatus) {
                        case 'CREATED':
                            return <FeatherIcons icon='clock' size="14" />;
                        case 'PENDING':
                            return <FeatherIcons color="#cccccc" icon='loader' size="14" />;
                        case 'APPROVED':
                            return <CheckIcon />;
                        case 'CANCELED':
                            return <FeatherIcons icon='x-circle' size="14" />;
                        case 'REJECTED':
                            return <RejectedIcon color="#F0233B" />;
                        case 'REASSIGNED':
                            return <AssignIcon />;
                        case 'CHANGE_REQUESTED':
                            return <ChangeRequestIcon />;
                        case 'QUEUE':
                            return <FeatherIcons color="#cccccc" icon='loader' size="14" />;
                        case 'NOT_NECESSARY':
                            return <NotNecessaryIcon />;
                        default:
                            return <FeatherIcons icon='clock' size="14" />;
                    }
                })()}
            </span>
            <span style={{
                color: '#000000',
                paddingLeft: '5px',
                fontWeight: 400,
                fontSize: '13px',
            }}>

                {statusMessage ? (
                    <>
                        <OverlayTrigger

                            placement="left"
                            overlay={
                                <Tooltip
                                >
                                    {statusMessage}
                                </Tooltip>
                            }
                        >
                            <span>
                                {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_STATUSES.' + userStatus)}
                            </span>
                        </OverlayTrigger>
                    </>
                ) : (
                    <>{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.USER_STATUSES.' + userStatus)}</>
                )}

            </span>
        </span>
    );
};

export default StatusBadge;
