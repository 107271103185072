import React, { useEffect, useState } from "react";
import ScreenModal from "@components/Modals/ScreenModal";
import { connect } from 'react-redux';
import "./CreateCompany.scss";
import globalStyles from "@styles/globalStyles";
import { Form } from "react-bootstrap";
import ButtonLoader from "@components/Buttons/ButtonLoader";
import { GrChapterAdd, GrAdd } from 'react-icons/gr';
import { BsFillCursorFill, BsSearch } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';

import { setNewCompanyData } from "@redux/components/index.actions";
import { createNewCompany } from "@redux/companies/index.actions";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
    users: state.users,
    components: state.components
});

const mapActionsTopProps = {
    setNewCompanyData,
    createNewCompany
}


const RenderFooterWithRedux: React.FunctionComponent<{
    users: any,
    components: any,
    setNewCompanyData: any,
    createNewCompany: any,
    batchOperations: {
        onClose: (isLastStep?: boolean) => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    }
}> = (props) => {
    const { t } = useTranslation()
    useEffect(() => {

        /* props?.createNewCompany({
            ...props?.components?.newCompanyData?.companyData,
            survey: props?.components?.newCompanyData?.surveyData
        }); */
    }, [])
    return (
        <React.Fragment>
            {props?.batchOperations?.index > 0 && (
                <button className="btn btn-outline-primary companyOnboard-previous"
                    onClick={props?.batchOperations?.previous}>
                    {t("POPUPS.COMPANY_ONBOARDING.PREVIOUS")}
                </button>
            )
            }
            {(
                <>
                    {/* <button className="btn btn-primary companyOnboard-next" onClick={async () => {
                        //props?.batchOperations?.next();
                        await props?.createNewCompany({
                            ...props?.components?.newCompanyData?.companyData,
                            survey: props?.components?.newCompanyData?.surveyData
                        })
                        props.batchOperations.onClose(true);
                    }}>
                        {t("POPUPS.COMPANY_ONBOARDING.FINISH")}
                    </button> */}
                </>
            )}
        </React.Fragment>
    )
}


export const renderFooter = connect(mapStateToProps, mapActionsTopProps)(RenderFooterWithRedux);

const CreateCompanySurvey: React.FunctionComponent<{
    users: any,
    components: any,
    setNewCompanyData: any,
    batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    }
}> = (props) => {
    const { t } = useTranslation();
    const onInput = (e: any) => {
        const key = e.currentTarget.name;
        const value = e.currentTarget.value;
        props?.setNewCompanyData({
            key: "surveyData",
            value: {
                ...props?.components?.newCompanyData?.surveyData,
                [key]: value
            }
        })

    }
    const handleSubmit = (e: any) => {
    }

    return (
        <div className="container-fluid create-company">
            <div className="row create-company-row">
                <div className="col-lg-7 col-md-12">
                    <div className="innerContent">
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-4" controlId="roleValidation">
                                <Form.Label htmlFor="title">
                                    {t("POPUPS.COMPANY_ONBOARDING.WHAT_IS_YOUR_ROLE_LABEL")}
                                </Form.Label>
                                <Form.Control onInput={onInput}
                                    id="role"
                                    name="role"
                                    type="text"
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.WHAT_IS_YOUR_ROLE_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.surveyData?.role}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.WHAT_IS_YOUR_ROLE_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="whatToDoValidation">
                                <Form.Label htmlFor="website">
                                    {t("POPUPS.COMPANY_ONBOARDING.WHAT_TO_DO_WITH_SYSTEM_LABEL")}
                                </Form.Label>
                                <Form.Control
                                    onInput={onInput}
                                    id="whatToDoWithSystem"
                                    name="whatToDoWithSystem"
                                    type="text"
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.WHAT_TO_DO_WITH_SYSTEM_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.surveyData?.whatToDoWithSystem}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.WHAT_TO_DO_WITH_SYSTEM_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="sizeValidation">
                                <Form.Label htmlFor="size">
                                    {t("POPUPS.COMPANY_ONBOARDING.SIZE_OF_COMPANY_LABEL")}
                                </Form.Label>
                                <Form.Control onInput={onInput}
                                    id="size"
                                    name="size"
                                    type="number"
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.SIZE_OF_COMPANY_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.surveyData?.size}
                                    required />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.SIZE_OF_COMPANY_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" controlId="howManyPeopleValidation">
                                <Form.Label htmlFor="howManyPeople">
                                    {t("POPUPS.COMPANY_ONBOARDING.USAGE_SIZE_LABEL")}
                                </Form.Label>
                                <Form.Control onInput={onInput}
                                    id="howManyPeople"
                                    name="howManyPeople"
                                    type="number"
                                    placeholder={t("POPUPS.COMPANY_ONBOARDING.USAGE_SIZE_PLACEHOLDER")}
                                    defaultValue={props?.components?.newCompanyData?.surveyData?.howManyPeople}
                                    required />
                                <Form.Control.Feedback type="invalid">
                                    {t("POPUPS.COMPANY_ONBOARDING.USAGE_SIZE_FEEDBACK")}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4" style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                                controlId="companyNameValidation">
                                <div>

                                </div>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
                <div className="col-lg-5 d-lg-block d-md-none">
                    <div className="sideBarContent">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(CreateCompanySurvey);