import React, { useEffect, useState } from "react";

import { Card, ListGroup, OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { getListCustomFields, addCustomFieldToContractType } from "@redux/contract/index.actions";
import { addCustomFieldsToCustomForm } from "@redux/custom-forms/index.actions";
import { toggleGenericAddCustomFieldModal } from "@redux/components/index.actions";

import { connect } from "react-redux";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import apiInstance from "@clients/api.client";

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
  companies: state.companies,
  customForms: state.customForms,
});
const mapActionsToProps = {
  getListCustomFields,
  toggleGenericAddCustomFieldModal,
  addCustomFieldToContractType,
  addCustomFieldsToCustomForm
};

const EditingFieldList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const handleAddCustomFieldToForm = () => {
    console.log(props?.item);

    let requestData = {
      formId: props?.customForms?.selectedForm?._id,
      customComponentType: props?.item?.CustomComponent,
      title: props?.item?.title,
      type: 'FormElements',
      isRequired: false,
      field: null,

    }

    props?.addCustomFieldsToCustomForm(requestData)


  }

  return (
    <div className="d-flex gap-1 align-items-center justify-content-between">

      <span className="d-flex align-items-center">
        <OverlayTrigger
          key={"kanban-tt-dp-edli"}
          placement="right"
          overlay={<Tooltip>
            <strong>{t("CONTRACTS_CUSTOM_FIELDS.INTERNAL_NAME")}:</strong> {props?.item?.key}
            <br /><strong>{t("CONTRACTS_CUSTOM_FIELDS.FIELD_TYPE")}:</strong> {props?.item?.CustomComponent}
            <br /><strong>{t("CONTRACTS_CUSTOM_FIELDS.REQUIRED")}:</strong> {props?.item?.isOptional === true ? 'Required' : 'Not Required'}
          </Tooltip>}
        >

          <Button
            className="d-none btn btn-sm btn-link text-info outline-0 border-0 m-0 p-1"
          >
            <i className="icon">
              <span>
                <FeatherIcons icon="info"></FeatherIcons>
              </span>
            </i>
          </Button>
        </OverlayTrigger>
        <div title={props?.item?.title} className="dotted">{props?.item?.title}</div>
      </span>

      <Button onClick={handleAddCustomFieldToForm} className="btn btn-sm btn-link text-danger outline-0 border-0 m-0 p-1">
        <i className="icon">
          <span>
            <FeatherIcons icon="plus"></FeatherIcons>
          </span>
        </i>
      </Button>

    </div >
  );
};

export default connect(mapStateToProps, mapActionsToProps)(EditingFieldList);
