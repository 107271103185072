import * as ActionDispatchTypes from "@redux/ActionDispatchTypes/index.types";
import { Reducer } from "redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

const initialState = {
    creatingForm: false,
    selectedForm: {},
    saving: false,
    editSelectedFormFieldData: {},
    tables: {
        refreshCustomFormsTable: false,
        customForms: new Map(),
        customFormsPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
    }
};

const ContractsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {

        /**
        * * Set editing custom form data
        */

        case ActionDispatchTypes.SET_EDIT_CUSTOM_FORMS_DATA:
            return {
                ...state,
                selectedForm: action.payload,
                editSelectedFormFieldData: action?.payload?.formFields?.[0] || initialState?.editSelectedFormFieldData,
            };

        /**
        * * Set editing custom form field data
        */

        case ActionDispatchTypes.SET_CUSTOM_FORMS_FIELD_EDIT_DATA:
            return {
                ...state,
                saving: false,
                editSelectedFormFieldData: action?.payload,
            };

        /**
        * * Adding custom form
        */

        case ActionDispatchTypes.ADD_CUSTOM_FORMS:
            return {
                ...state,
                creatingForm: true,
                saving: true
            };
        case ActionDispatchTypes.ADD_CUSTOM_FORMS_SUCCESSFUL:
            return {
                ...state,
                creatingForm: false,
                selectedForm: action?.payload?.customForm,
                saving: false,
                tables: {
                    ...state.tables,
                    refreshCustomFormsTable: true
                }
            };
        case ActionDispatchTypes.ADD_CUSTOM_FORMS_FAILED:
            return {
                ...state,
                creatingForm: false
            };

        /**
        * * Adding custom form
        */

        case ActionDispatchTypes.EDIT_CUSTOM_FORMS_FIELD_DATA:
            return {
                ...state,
                saving: true
            };
        case ActionDispatchTypes.EDIT_CUSTOM_FORMS_FIELD_DATA_SUCCESSFUL:
            return {
                ...state,
                selectedForm: {
                    ...state.selectedForm
                },
                saving: false,
            };
        case ActionDispatchTypes.EDIT_CUSTOM_FORMS_FIELD_DATA_FAILED:
            return {
                ...state,
            };


        /**
               * * Select and add template to custom form
               */

        case ActionDispatchTypes.CUSTOM_FORMS_SELECT_TEMPLATE:
            return {
                ...state,
                selectingTemplate: true,
                saving: true,
            };
        case ActionDispatchTypes.CUSTOM_FORMS_SELECT_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                selectingTemplate: false,
                saving: false,
                selectedForm: {
                    ...state.selectedForm,
                    templates: [
                        ...state?.selectedForm?.templates,
                        action?.payload?.addedTemplate
                    ]
                }
            };
        case ActionDispatchTypes.CUSTOM_FORMS_SELECT_TEMPLATE_FAILED:
            return {
                ...state,
                selectingTemplate: false
            };



        /**
               * * Select and add template to custom form
               */

        case ActionDispatchTypes.CUSTOM_FORMS_REMOVE_FROM_TEMPLATE:
            return {
                ...state,
                selectingTemplate: true,
                saving: true,
            };
        case ActionDispatchTypes.CUSTOM_FORMS_REMOVE_FROM_TEMPLATE_SUCCESSFUL:

            state?.selectedForm?.templates.splice(action?.index, 1)

            return {
                ...state,
                selectingTemplate: false,
                saving: false,
                selectedForm: {
                    ...state.selectedForm
                }
            };
        case ActionDispatchTypes.CUSTOM_FORMS_REMOVE_FROM_TEMPLATE_FAILED:
            return {
                ...state,
                selectingTemplate: false
            };

        /**
               * * Select and add template to custom form
               */

        case ActionDispatchTypes.CUSTOM_FORMS_TEMPLATE_REFRESH:
            return {
                ...state,
                selectingTemplate: true
            };
        case ActionDispatchTypes.CUSTOM_FORMS_TEMPLATE_REFRESH_SUCCESSFUL:


            return {
                ...state,
                selectingTemplate: false,
            };








        /**
        * * Delete custom form
        */

        case ActionDispatchTypes.DELETE_CUSTOM_FORMS:
            return {
                ...state,
                creatingForm: true
            };
        case ActionDispatchTypes.DELETE_CUSTOM_FORMS_SUCCESSFUL:
            return {
                ...state,
                creatingForm: false,
                tables: {
                    ...state.tables,
                    refreshCustomFormsTable: true
                }
            };
        case ActionDispatchTypes.DELETE_CUSTOM_FORMS_FAILED:
            return {
                ...state,
                creatingForm: false
            };

        /**
        * * Edit custom form field data reducer
        */

        case ActionDispatchTypes.SET_CUSTOM_FORMS_FIELD_DATA_TO_REDUCER:

            var i: number = state?.selectedForm?.formFields?.findIndex((o: any) => o._id === action?.payload?.fieldId);

            state.selectedForm.formFields[i][action?.payload?.targetName] = action?.payload?.targetValue

            return {
                ...state,
                selectedForm: {
                    ...state?.selectedForm
                },
                editSelectedFormFieldData: {
                    ...state.editSelectedFormFieldData,
                    [action?.payload?.targetName]: action?.payload?.targetValue
                }
            };

        /**
        * * Edit custom form
        */

        case ActionDispatchTypes.EDIT_CUSTOM_FORMS:
            return {
                ...state,
                creatingForm: true,
                saving: true,
            };
        case ActionDispatchTypes.EDIT_CUSTOM_FORMS_SUCCESSFUL:
            console.log('action?.setData', action?.setData)
            return {
                ...state,
                creatingForm: false,
                saving: false,
                selectedForm: {
                    ...state.selectedForm,
                    ...action?.setData
                }
                /*tables: {
                    ...state.tables,
                    refreshCustomFormsTable: true
                }*/
            };
        case ActionDispatchTypes.EDIT_CUSTOM_FORMS_FAILED:
            return {
                ...state,
                creatingForm: false
            };

        /**
        * * Reordering custom form fields
        */

        case ActionDispatchTypes.REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS:
            return {
                ...state,
                saving: true
            };
        case ActionDispatchTypes.REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS_SUCCESSFUL:

            let removedState = state?.selectedForm?.formFields[action?.payload?.sourceIndex];
            state?.selectedForm?.formFields.splice(action?.payload?.sourceIndex, 1);
            state?.selectedForm?.formFields.splice(action?.payload?.destinationIndex, 0, removedState);

            return {
                ...state,
                saving: false,
                selectedForm: {
                    ...state.selectedForm,
                    formFields: [
                        ...state?.selectedForm?.formFields
                    ]
                },
            };
        case ActionDispatchTypes.REORDER_CUSTOM_FIELDS_IN_CUSTOM_FORMS_FAILED:
            return {
                ...state,
            };

        /**
        * * Adding custom field and form element to custom form
        */

        case ActionDispatchTypes.ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS:
            return {
                ...state,
                saving: true,
            };
        case ActionDispatchTypes.ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS_SUCCESSFUL:


            return {
                ...state,
                saving: false,
                selectedForm: {
                    ...state.selectedForm,
                    formFields: [
                        ...state.selectedForm.formFields,
                        action?.payload?.addedField
                    ]
                },
                editSelectedFormFieldData: action?.payload?.addedField,
                tables: {
                    ...state.tables,
                    refreshCustomFormsTable: true
                }
            };
        case ActionDispatchTypes.ADD_CUSTOM_FIELDS_TO_CUSTOM_FORMS_FAILED:
            return {
                ...state,
            };

        /**
        * * Remove custom form field from custom form
        */

        case ActionDispatchTypes.REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS:
            return {
                ...state,
                saving: true,
            };
        case ActionDispatchTypes.REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS_SUCCESSFUL:
            let index = _.findIndex(state.selectedForm.formFields, { _id: action?.payload?.removedField });
            state.selectedForm.formFields.splice(index, 1)

            return {
                ...state,
                saving: false,
                selectedForm: {
                    ...state.selectedForm,
                    formFields: [...state.selectedForm.formFields]
                },
                editSelectedFormFieldData: state.selectedForm.formFields[0] || {},
            };
        case ActionDispatchTypes.REMOVE_CUSTOM_FIELDS_FROM_CUSTOM_FORMS_FAILED:
            return {
                ...state,
            };

        /**
         * * Form listing operations
         */

        case ActionDispatchTypes.GET_TABLE_CUSTOM_FORMS_SUCCESSFUL:


            state?.tables?.customForms?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.customForms.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...state?.tables?.refreshCustomFormsTable && { refreshCustomFormsTable: false },
                    customFormsPagination: {
                        ...state?.tables?.customFormsPagination,
                        ...action?.payload?.pagination
                    }
                }
            }

        default:
            return state;
    }
};

export default ContractsReducer;
