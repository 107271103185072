import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { connect } from "react-redux";

// components
import CompanyAdd from "@pages/contractCompanies/CompanyAdd";

import { useTranslation } from "react-i18next";

import { toggleGenericAddContractCompaniesModal } from "@redux/components/index.actions";


const mapStateToProps = (state: any) => ({
  components: state?.components
});
const mapActionsToProps = {
  toggleGenericAddContractCompaniesModal
};

const AddNewStatusModal = (props: any) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={props?.components?.newContractCompaniesModal}
      onHide={props?.toggleGenericAddContractCompaniesModal}
      size="sm"
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">
          {t("CONTRACTS_COMPANIES.NEW_CONTRACT_COMPANY")}
        </h4>
      </Modal.Header>
      <Modal.Body>
        <CompanyAdd modalClose={props?.toggleGenericAddContractCompaniesModal} />
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(AddNewStatusModal);
