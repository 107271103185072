import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

// layout constants
import { LayoutTypes } from '@constants/layout';

import ScrollToTop from '@components/ScrollToTop';

// strore
import { RootState } from '@redux/store';

// All layouts containers
import DefaultLayout from '@layouts/Default';
import VerticalLayout from '@layouts/Vertical';
import DetachedLayout from '@layouts/Detached';
import HorizontalLayout from '@layouts/Horizontal';
import TwoColumnLayout from '@layouts/TwoColumn';

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from '@routes/index';

import { RoutesProps } from '@routes/interfaces/Routes.interfaces';
import Error404 from '@pages/error/Error404';
import PrivateRoute from './PrivateRoute';

import { useLocation } from 'react-router-dom';

import { redirectToMainApp } from '@utils/redirect';

const mapStateToProps = (state: any) => ({
    users: state.users
});

const mapActionsToProps = {

}

const Routes: React.FunctionComponent<any> = (props) => {
    const history = useHistory();
    const { layout } = useSelector((state: RootState) => ({
        layout: state.Layout,
    }));


    const getLayout = () => {
        let layoutCls = TwoColumnLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_VERTICAL:
                layoutCls = VerticalLayout;
                break;
            default:
                layoutCls = TwoColumnLayout;
                break;
        }
        return layoutCls;
    };



    let Layout = getLayout();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const refresh_token = urlParams.get('refresh_token');
        const token = urlParams.get('token');
        if (token) {
            const url = new URL(window.location.href);
            url.searchParams.delete('token');
            url.searchParams.delete('refresh_token');
            localStorage.setItem("users::token", token);
            localStorage.setItem("users::refresh_token", refresh_token || '');
            window.location.href = url.toString();
        }
    }, [props?.users?.authorization?.token]);

    return (
        <BrowserRouter>
            <ScrollToTop />
            {props?.children}
            <Switch>
                <Route path={publicProtectedFlattenRoutes.map((r: any) => r['path'])}>
                    <DefaultLayout {...props} layout={layout}>
                        <Switch>
                            {publicProtectedFlattenRoutes.map((route: any, index: number) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                            <Route>
                                <Error404 />
                            </Route>
                        </Switch>
                    </DefaultLayout>
                </Route>
                <PrivateRoute path={authProtectedFlattenRoutes.map((r: any) => r['path'])}>
                    <Layout {...props}>
                        <Switch>
                            {authProtectedFlattenRoutes.map((route: any, index: number) => {
                                return (
                                    !route.children && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                        />
                                    )
                                );
                            })}
                            <Route>
                                <Error404 />
                            </Route>
                        </Switch>
                    </Layout>
                </PrivateRoute>
            </Switch>
        </BrowserRouter>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Routes);
