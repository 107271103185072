import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import UnknownUser from '@assets/images/users/unknown.jpg';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

export const localStorageKeys: {
} = {

};

const initialState = {
    integrations: [],
    fetchinIntegrations: false,
    endedFetchingIntegrations: false
}


const CompaniesIntegrationsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }
        case ActionDispatchTypes.GET_USERS_INTEGRATIONS:
            return {
                ...state,
                fetchinIntegrations: true
            }
        case ActionDispatchTypes.GET_USERS_INTEGRATIONS_SUCCESSFUL:
            return {
                ...state,
                fetchinIntegrations: false,
                integrations: _.unionWith(state.integrations, action.payload?.node?.integrations, _.isEqual)
            }
        case ActionDispatchTypes.GET_USERS_INTEGRATIONS_FAILED:
            return {
                ...state,
                fetchinIntegrations: false,
            }
        case ActionDispatchTypes.CREATE_DRIVE_INTEGRATION_FOR_USERS:
            return {
                ...state
            }
        case ActionDispatchTypes.CREATE_DRIVE_INTEGRATION_FOR_USERS_SUCCESSFUL:
            return {
                ...state
            }
        case ActionDispatchTypes.DELETE_INTEGRATION_FOR_USERS_SUCCESSFUL:
            return {
                ...state,
                integrations: action.payload?.node?.integrations
            }
        default:
            return state;
    }
}

export default CompaniesIntegrationsReducer;