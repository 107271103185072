import cdnservices from '@clients/cdnservices.client';
import ErrorResponse from '@redux/utils/ErrorResponse.utils';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
function dataURLtoFile(dataurl: string, filename: string) {
    try {
        var arr: any = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    } catch (error) {
        console.log(error);
        return error;
    }
}

export function toDataUrl(url: string, filename: string) {
    return new Promise(async (resolve, reject) => {
        try {
            const base64Str: any = await getBase64FromUrl(url);
            resolve(dataURLtoFile(base64Str, filename));
        } catch (error) {
            reject(error);
        }
    })
}

const getBase64FromUrl = async (url: string) => {
    const data = await fetch(url, {
        redirect: 'follow',
        mode: 'no-cors'
    });
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                console.warn(base64data)
                resolve(base64data);
            }
        } catch (e) {
            console.log("error", e);
            reject(false);
        }
    });
}


export const toDataUrlCDNGDrive = async (
    fileId: string,
    filename: string,
    fileType: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            /* const base64Str: any = await getBase64FromCDNServices(fileId);
            resolve(dataURLtoFile(base64Str, filename)); */
            const file: any = await getFileFromGDriveCDN(fileId, filename, fileType);
            resolve(file);
        } catch (error) {
            reject(error);
        }
    })

}

export const getFileFromGDriveCDN = async (key: string, filename: string, fileType: string) => {
    return cdnservices.get(`/gdrive/user/files/${key}`, {
        responseType: 'blob'
    }).then((response: AxiosResponse) => {
        const blob = response?.data;
        return new Promise((resolve, reject) => {
            try {
                resolve(new File([blob], filename, {
                    type: response?.headers['content-type']
                }));
            } catch (e) {
                console.log("error", e);
                reject(false);
            }
        });
    }).catch((error: any) => {
        const errorResponse = ErrorResponse(error);
        return errorResponse;
    })
}

export const toDataUrlCDNOneDrive = async (
    fileId: string,
    filename: string,
    fileSize: number,
) => {
    return new Promise(async (resolve, reject) => {
        try {
            /* const base64Str: any = await getBase64FromCDNServices(fileId);
            resolve(dataURLtoFile(base64Str, filename)); */
            const file: any = await getFileFromOneDriveCDN(fileId, filename, fileSize);
            resolve(file);
        } catch (error) {
            reject(error);
        }
    })

}


export const getFileFromOneDriveCDN = async (key: string, filename: string, fileSize: number) => {
    return cdnservices.get(`/onedrive/user/files/download/${key}`, {
        responseType: 'blob'
    }).then((response: AxiosResponse) => {
        const blob = response?.data;
        //alert(filename);
        return new Promise((resolve, reject) => {
            try {
                resolve(new File([blob], filename, {
                    type: response?.headers['content-type']
                }));
            } catch (e) {
                console.log("error", e);
                reject(false);
            }
        });
    }).catch((error: any) => {
        const errorResponse = ErrorResponse(error);
        return errorResponse;
    })
}

export const toDataUrlCDNSharePoint = async (
    fileId: string,
    filename: string,
    fileSize: number,
) => {
    return new Promise(async (resolve, reject) => {
        try {
            /* const base64Str: any = await getBase64FromCDNServices(fileId);
            resolve(dataURLtoFile(base64Str, filename)); */
            const file: any = await getFileFromSharePointCDN(fileId, filename, fileSize);
            resolve(file);
        } catch (error) {
            reject(error);
        }
    })

}


export const getFileFromSharePointCDN = async (key: string, filename: string, fileSize: number) => {
    return cdnservices.get(`/sharepoint/user/files/download/${key}`, {
        responseType: 'blob'
    }).then((response: AxiosResponse) => {
        const blob = response?.data;
        //alert(filename);
        return new Promise((resolve, reject) => {
            try {
                resolve(new File([blob], filename, {
                    type: response?.headers['content-type']
                }));
            } catch (e) {
                console.log("error", e);
                reject(false);
            }
        });
    }).catch((error: any) => {
        const errorResponse = ErrorResponse(error);
        return errorResponse;
    })
}
