import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_FAMILIES,
} from '@components/SlateEditor/constants/index.constant'

export default function FontFamily(props: any) {

    const editor = useSlate()

    return (
        <Dropdown className='p-0 m-0'>
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip>
                    Font Family
                </Tooltip>
            }>
                <Dropdown.Toggle
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                    }}
                    variant='link'
                    className='toolbar-dd-button'
                >

                    <span>{props?.activeFont}</span>
                    <FaChevronDown style={{ marginLeft: '3px' }} size={10} />




                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='slate-toolbar-dd-menu'
            >
                {
                    (FONT_FAMILIES || []).map((item: any, index: number) => {
                        return (

                            <Dropdown.Item key={index} onMouseDown={(event: any) => {
                                event.preventDefault(); // Editörün odaklanmasını önle
                                event.stopPropagation();
                                props?.changeFontFamily(editor, item);
                                props?.setActiveFont(item)
                            }}>
                                {item}
                            </Dropdown.Item>

                        )
                    })
                }

            </Dropdown.Menu>
        </Dropdown>
    )
}
