import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toggleApprovalProcessFileModal } from "@redux/components/index.actions";
import {
  addApproveProcessFileUserIntoStep, addApproveProcessFileStep, removeApproveProcessFileRecipient, removeAllSteps, deleteApproveProcessFileStep,
  updateApproveProcessFileStep, getReadApproveProcess, updateApproveProcessFileStepUser, updateApproveTemplate, copyTemplateStepsToProcessFile, startApproving, editApproveProcess,
} from "@redux/approve/index.actions";

import { resfreshDocumensTable } from "@redux/files/index.actions";

import AddContract from "@pages/contracts/AddContract";
import { useTranslation } from "react-i18next";

import './index.scss'
import FeatherIcons from "feather-icons-react";
import apiInstance from "@clients/api.client";
import { toast } from "react-toastify";
import { FiSave } from "react-icons/fi";
import { VscSaveAs, VscSave } from "react-icons/vsc";
import ApproveTemplatesSelect from "@components/SelectsPaginated/ApproveTemplates";
import Loader from "@components/Loader";
import Lootie from "@components/ReactLottie";
import { RiDeleteBinLine } from "react-icons/ri";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import ApproveTemplatesFlatList from "./components/ApproveTemplatesFlatList";


const MapStateToProps = (state: any) => ({
  components: state.components,
  approve: state.approve,
  companies: state.companies,
});

const MapActionsToProps = {
  toggleApprovalProcessFileModal: toggleApprovalProcessFileModal,
  addUserIntoStep: addApproveProcessFileUserIntoStep,
  addStep: addApproveProcessFileStep,
  removeRecipient: removeApproveProcessFileRecipient,
  removeAllSteps: removeAllSteps,
  deleteStep: deleteApproveProcessFileStep,
  updateStep: updateApproveProcessFileStep,
  updateStepUser: updateApproveProcessFileStepUser,
  updateApproveTemplate: updateApproveTemplate,
  copyTemplateStepsToProcessFile: copyTemplateStepsToProcessFile,
  startApproving: startApproving,
  getReadApproveProcess: getReadApproveProcess,
  editApproveProcess: editApproveProcess,
  resfreshDocumensTable: resfreshDocumensTable
};

const ApprovalProcessFileModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();



  const listRef = useRef<HTMLDivElement>(null)
  const headerRef = useRef<HTMLDivElement>(null)


  const [userMessage, setUserMessage] = useState<string>("")

  const [initialSteps, setInitialSteps] = useState<any>({})

  const [approveFile, setApproveFile] = useState<any>(initialSteps)

  const [suggestions, setSuggestions] = useState<any>({})

  const [emailValues, setEmailValues] = useState<any>({})

  const [pageIndex, setPageIndex] = useState<number>(0)

  /*  if (listRef && listRef.current && headerRef && headerRef.current) {
     listRef.current.addEventListener('scroll', (e: any) => {
       if (e.target.scrollTop === 0) {
         headerRef.current?.classList.remove('shadow')
       } else {
         headerRef.current?.classList.add('shadow')
       }
 
     })
   } */


  const handleDeleteStep = (step: any) => {
    const id = step._id;
    if (!id) {
      toast.error(t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.STEP_NOT_FOUND'))
      return;
    }

    props?.deleteStep({ id });
    setShowButtons(true)
  }


  const handleAddUser = (data: any) => {

    if (!data?.email) {
      return;
    }
    //email validation with regex
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(data?.email)) {
      return toast.error(t('APPROVE_TEMPLATES.APPROVE_TEMPLATES_GENERIC_MODAL.EMAIL_VALIDATION_ERROR'))
    }

    console.log('data?.step', data?.step)
    props?.addUserIntoStep({
      step: data?.step,
      email: data?.email
    }).then((res: any) => {
      console.log('BURADA', res)
      setEmailValues({})
      setSuggestions({})
      setShowButtons(true)
    })
  }

  const handleRemoveRecipient = (stepUserItem: any, stepItem: any) => {
    props?.removeRecipient({
      recipientId: stepUserItem._id,
      stepId: stepItem._id,
    })
    setShowButtons(true)

  }
  const handleRemoveAllSteps = () => {
    props?.removeAllSteps({})
    setShowButtons(true)

  }
  const changeApproveAll = (result: any, stepItem: any) => {
    console.log('RESULT', result, stepItem?._id)

    props?.updateStep({
      id: stepItem?._id,
      fieldName: 'aproveAll',
      fieldValue: result === 'all' ? true : false
    }
    ).then((res: any) => {
      setShowButtons(true)
    })
  }
  const changeStepUserConfig = (result: any, stepItem: any, stepUserItem: any, fieldName: any) => {
    console.log('RESULT', result, stepItem?._id)

    props?.updateStepUser({
      stepId: stepItem?._id,
      stepUserId: stepUserItem?._id,
      fieldName: fieldName,
      fieldValue: result
    }).then((res: any) => {
      setShowButtons(true)
    })
  }
  const handleUpdateApproveTemplate = (fieldName: any, fieldValue: any) => {

    if (!fieldName || !fieldValue) {
      return;
    }

    props?.updateApproveTemplate({
      fieldName: fieldName,
      fieldValue: fieldValue
    })
  }

  const [approveTemplateId, setApproveTemplateId] = useState<any>(null)
  const [approveTemplateName, setApproveTemplateName] = useState<any>(null)

  const handleSelectApproveTemplate = (data: any) => {
    let approveTemplateId = data;
    if (!approveTemplateId) {
      return;
    }
    console.log('approveTemplateId', approveTemplateId)
    props?.copyTemplateStepsToProcessFile({
      approveTemplateId: approveTemplateId
    })
    setShowButtons(false)

  }

  useEffect(() => {
    setShowButtons(props?.approve?.approveShowButtons)
  }, [props?.approve?.approveShowButtons])

  const [saveAsName, setSaveAsName] = useState<any>(null)
  const [showSaveAsModal, setShowSaveAsModal] = useState<any>(false)

  const [saveName, setSaveName] = useState<any>(null)
  const [showSaveModal, setShowSaveModal] = useState<any>(false)

  const [loading, setLoading] = useState<any>(false)
  const [showButtons, setShowButtons] = useState<any>(false)

  const [editorState, setEditorState] = useState<any>(null)


  const [editTitle, setEditTitle] = useState<any>(false)

  const [showDraftModal, setShowDraftModal] = useState<any>(false)

  return (
    <>
      <Modal
        show={props?.components?.approvalProcessFileModal}
        onHide={(e: any) => {
          setShowDraftModal(true)

        }}
        keyboard={false}

        backdrop={'static'}
        centered
        size={pageIndex == 0 ? 'xl' : 'sm'}
        contentClassName="shadow">
        {pageIndex === 0 && (
          <Modal.Header className="bg-secondary">
            <div className="editable-title">
              {!editTitle ? (
                <div className="editable-title-inner">
                  <h4>{props?.approve?.selectedApproveProcess?.title}</h4>
                  <span className="icon">
                    <FeatherIcons onClick={() => {
                      setEditTitle(true)
                      /* focus title */
                      document.getElementById('title')?.focus()
                    }} size={18} icon="edit-2" />
                  </span>
                </div>
              ) : (
                <div className="editable-title-inner">
                  <input
                    autoFocus
                    id="title"
                    onBlur={(e: any) => {
                      props?.editApproveProcess({
                        id: props?.approve?.selectedApproveProcess?._id,
                        title: e?.target?.value
                      }).then((res: any) => {
                        console.log('res', res)
                      })

                      /* setEditTitle(false) */
                    }}
                    className="editable-input-text" type="text"
                    defaultValue={props?.approve?.selectedApproveProcess?.title} />
                  <FeatherIcons onClick={() => {
                    setEditTitle(false)

                  }} size={18} icon="save" />
                </div>
              )}

            </div>
            <div className="gap-1 d-flex align-items-center justify-content-end">

              <Button variant="link" className="text-white" onClick={(e: any) => { setShowDraftModal(true) }}>
                <FeatherIcons size={18} icon="x" />
              </Button>
            </div>
          </Modal.Header>
        )}

        <Modal.Body className="p-0">

          {pageIndex === 0 && (
            <><div ref={listRef} className="aprrove-process">


              {props?.approve?.startApproving === true ? (
                <div
                  className="preloader"
                  style={{
                    height: '100%',
                    background: 'white',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    minHeight: '400px'
                  }}>
                  <div style={{ width: '300px' }}>
                    <Lootie></Lootie>
                    <p>{t('APPROVAL_PROCESS_FILE_MODAL.LOADING')}</p>
                  </div>

                </div>
              ) : (
                <>

                  <div ref={headerRef} className="approve__header">


                    <div className="approve__header__body">
                      <div className="approve__header__title">
                        <h4>{t('APPROVAL_PROCESS_FILE_MODAL.RECIPIENTS')}</h4>

                      </div>
                      <div className="approve__header__actions">

                        <div className="flow-template-dd">
                          <Dropdown as={'a'} autoClose={true} >
                            <Dropdown.Toggle variant="link" as={'a'} style={{ color: 'blue', fontWeight: 'bold' }}>
                              {t('APPROVAL_PROCESS_FILE_MODAL.SELECT_FROM_TEMPLATE')} <FeatherIcons icon={'chevron-down'} size={16} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu align={'end'} as={'div'} style={{ width: '300px', marginTop: '5px', padding: '10px' }}>


                              <div>
                                <ApproveTemplatesFlatList
                                  approveTemplateId={approveTemplateId}
                                  onValueSubmit={(value: any) => {

                                    console.log('value', value)
                                    handleSelectApproveTemplate(value?._id)
                                    setApproveTemplateId(value?._id)
                                    setApproveTemplateName(value?.title)
                                  }}

                                />
                              </div>
                              <div className="divider"></div>
                              <div className="flow-template-actions">

                                <Button
                                  disabled={!showButtons}
                                  onClick={() => {
                                    setShowSaveAsModal(true)
                                  }}
                                  className="px-1 d-flex align-items-center justify-content-center gap-1" variant="link"><FeatherIcons size={14} icon="bookmark" ></FeatherIcons><span>{t('APPROVAL_PROCESS_FILE_MODAL.SAVE_FLOW')}</span></Button>
                                <Button className="px-1 d-flex align-items-center justify-content-center gap-1" variant="link" onClick={(e: any) => {
                                  console.log('removeAllSteps')
                                  props?.removeAllSteps({})
                                  setApproveTemplateId(null)
                                  setApproveTemplateName(null)
                                }}><FeatherIcons size={14} icon="plus"></FeatherIcons><span>{t('APPROVAL_PROCESS_FILE_MODAL.NEW_FLOW')}</span></Button>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>



                      </div>

                    </div>


                  </div>
                  <div className="steps" style={{
                    background: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '#f1f1f9' : 'white',
                    paddingTop: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '30px' : '',
                    marginTop: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '25px' : ''
                  }}>
                    {/* {props?.approve?.selectedApproveProcessFile?.steps?.length > 0 && (
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        style={{ paddingRight: 0 }}
                        onClick={e => handleRemoveAllSteps()}
                        variant="link" className="text-danger d-flex align-items-center justify-content-between gap-1">
                        <span
                          className="d-flex align-items-center justify-content-between"
                        ><RiDeleteBinLine size={14} /></span>
                        <span
                          className="d-flex align-items-center justify-content-between"
                          style={{ fontSize: '14px', lineHeight: '14px' }}>
                          {t('APPROVAL_PROCESS_FILE_MODAL.DELETE_ALL')}
                        </span>
                      </Button>
                    </div>
                  )} */}
                    {(props?.approve?.selectedApproveProcessFile?.steps || []).map((stepItem: any, index: number) => {
                      console.log('stepItem', stepItem)
                      return (
                        <div key={'step__' + index} className="step">
                          {props?.approve?.selectedApproveProcessFile?.steps?.length > 1 && (
                            <div className="step-number" style={{ marginBottom: 20 }}>
                              <h5 className="m-0">{index + 1}. {t('APPROVAL_PROCESS_FILE_MODAL.STEP')}</h5>
                              {/* <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteStep(stepItem)
                            }}
                            style={{ paddingRight: 0 }}
                            className="btn btn-link btn-sm text-danger">{t('APPROVAL_PROCESS_FILE_MODAL.DELETE')}</button> */}
                            </div>
                          )}
                          <div className="recipients-wrapper">
                            <div className="recipients">

                              {(stepItem?.stepUsers || []).map((stepUserItem: any, recipientIndex: number) => {
                                return (
                                  <div key={'recipient' + stepUserItem?._id} className="recipient-group">
                                    <div className="recipient-label">
                                      <span>{stepUserItem?.email}</span>
                                      <button className="btn btn-link text-dark" style={{ marginLeft: '5px', paddingLeft: '0', paddingRight: '0' }} onClick={e => handleRemoveRecipient(stepUserItem, stepItem)}>
                                        <FeatherIcons size={14} icon="x" />
                                      </button>
                                    </div>


                                  </div>
                                )
                              })}
                              <div className="recipients-new">
                                <input
                                  onKeyDown={(e) => {

                                    if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {

                                      handleAddUser({
                                        step: { ...stepItem, index },
                                        email: emailValues[index]
                                      })
                                    }
                                  }}
                                  key={'recipient__input_email' + index}
                                  value={emailValues[index] || ''}
                                  onChange={(e) => {
                                    setEmailValues({
                                      ...emailValues,
                                      [index]: e.target.value
                                    })
                                  }}
                                  onInput={(e: any) => {

                                    if (e.target.value.length > 2) {
                                      apiInstance.get(`/companies/${props?.companies?.currentCompany}/users`, {
                                        params: {
                                          page: 1,
                                          batchSize: 10,
                                          searchText: e?.target?.value
                                        }
                                      }).then((res: any) => {
                                        console.log(res?.data?.node?.users)
                                        setSuggestions({
                                          ...suggestions,
                                          [index]: res?.data?.node?.users
                                        })
                                      })
                                    } else {
                                      setSuggestions({
                                        ...suggestions,
                                        [index]: []
                                      })
                                    }
                                  }}
                                  type="email" className="form-control"
                                  placeholder={t('APPROVAL_PROCESS_FILE_MODAL.RECIPIENT_EMAIL')} />


                                {/* <Button variant="primary" size="sm" className="add-recipient-button"
                              onClick={() => handleAddUser({
                                step: { ...stepItem, index },
                                email: emailValues[index]
                              })}
                            >
                              <FeatherIcons icon="plus" />
                            </Button> */}

                              </div>





                            </div>
                            {props?.approve?.selectedApproveProcessFile?.steps?.length > 1 && (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleDeleteStep(stepItem)
                                }}
                                style={{ paddingRight: 0 }}
                                className="btn btn-link btn-sm text-danger">
                                <FeatherIcons size={16} icon="trash" />
                              </button>
                            )}
                          </div>

                          <div>
                            {suggestions?.[index]?.length > 0 && (
                              <div className="recipient-suggestions">
                                {suggestions[index]?.map((item: any) => {
                                  return (
                                    <div className="recipient-suggestions-item"
                                      onClick={(e: any) => {
                                        handleAddUser({
                                          step: { ...stepItem, index },
                                          email: e?.target?.innerText
                                        })
                                      }}
                                    >
                                      <span>{item?.email}</span>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>

                          <div className="step-settings">
                            <span>
                              {t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM')}:
                            </span>
                            <div>
                              <div className="form-check form-check-inline">
                                <input
                                  value={'all'}
                                  onChange={(e) => changeApproveAll(e.target.value, stepItem)}
                                  className="form-check-input" type="radio" id={'inlineRadio1' + index} key={'key_inline_radio_1' + index} checked={stepItem?.aproveAll === true ? true : false} />
                                <label className="form-check-label" htmlFor={'inlineRadio1' + index}>{t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM_ALL')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  value={'one'}
                                  onChange={(e) => changeApproveAll(e.target.value, stepItem)}
                                  className="form-check-input" type="radio" id={'inlineRadio2' + index} key={'key_inline_radio_2' + index} checked={stepItem?.aproveAll !== true ? true : false} />
                                <label className="form-check-label" htmlFor={'inlineRadio2' + index}>{t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM_ONE')}</label>
                              </div>
                            </div>
                          </div>

                        </div>
                      )
                    })}

                    <div className="d-flex align-items-center justify-content-start">
                      <Button
                        onClick={() => {
                          props?.addStep()
                        }}
                        style={{ color: 'blue', paddingLeft: 0, paddingRight: 0, }}
                        variant="link" className="d-flex align-items-center justify-content-center">
                        <FeatherIcons size={14} icon="plus" />
                        <span style={{
                          marginLeft: 5,
                          textDecoration: 'underline',
                          color: 'blue',
                          fontWeight: 'bold',
                          display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3px'
                        }}>{t('APPROVAL_PROCESS_FILE_MODAL.ADD_STEP')}
                          <OverlayTrigger
                            placement="right"
                            overlay={
                              <Popover popper id={'step'}>
                                <Popover.Body>{t('APPROVAL_PROCESS_FILE_MODAL.ADD_STEP_INFO')}</Popover.Body>
                              </Popover>
                            }>
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              background: '#cccccc',
                              borderRadius: '50%',
                              padding: '2px',
                              color: 'black'
                            }}>
                              <FeatherIcons size={16} icon="info" />
                            </div>
                          </OverlayTrigger>
                        </span>
                      </Button>
                    </div>
                  </div>

                  <div className="approve__footer">
                    <div className="approve__footer__title">
                      <h4>{t('APPROVAL_PROCESS_FILE_MODAL.MESSAGE')}</h4>

                    </div>
                    <div className="approve__header__top">

                      <textarea
                        value={userMessage}
                        onChange={(e) => {
                          setUserMessage(e.target.value)
                        }}
                        className="approve-message-textarea" placeholder={t('APPROVE_PROCESS_MODAL.CREATE_MODAL.USER_NOTE_PLACEHOLDER')}></textarea>
                    </div>

                  </div>

                </>

              )}



            </div>

              <Button variant="primary"
                disabled={props?.approve?.startApproving}
                className="floating-send-button"
                onClick={() => {
                  if (props?.approve?.selectedApproveProcessFile?.steps.some((step: any) => !step.stepUsers.length)) {
                    toast.error('Lütfen adımları boş bırakmayınız.')
                    return;
                  }
                  setPageIndex(1)
                }}
              >
                {/* <FeatherIcons size={14} icon="send" /> */} <span>{t('APPROVAL_PROCESS_FILE_MODAL.SEND_APPROVE')}</span>
              </Button>
              {/* <Button variant="primary"
                disabled={props?.approve?.startApproving}
                className="floating-send-button"
                onClick={() => {
                  if (props?.approve?.selectedApproveProcessFile?.steps.some((step: any) => !step.stepUsers.length)) {
                    toast.error('Lütfen adımları boş bırakmayınız.')
                    return;
                  }
                  props?.startApproving({
                    userMessage: userMessage
                  }).then((res: any) => {
                    props?.getReadApproveProcess({
                      id: props?.approve?.selectedApproveProcess?._id
                    })
                  })
                }}
              >
                <span>{t('APPROVAL_PROCESS_FILE_MODAL.SEND_APPROVE')}</span>
              </Button> */}
            </>
          )}
          {pageIndex === 1 && (


            <>
              {props?.approve?.startApproving === true ? (
                <div
                  className="preloader"
                  style={{
                    height: '100%',
                    background: 'white',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    textAlign: 'center',
                    minHeight: '400px'
                  }}>
                  <div style={{ width: '300px' }}>
                    <Lootie></Lootie>
                    <p>{t('APPROVAL_PROCESS_FILE_MODAL.LOADING')}</p>
                  </div>

                </div>
              ) : (
                <div className="steps-edit">

                  <div className="floting-close-icon">
                    <Button variant="link" className="text-dark" onClick={(e: any) => { setShowDraftModal(true) }}>
                      <FeatherIcons size={18} icon="x" />
                    </Button>
                  </div>
                  <div className="title-wrapper">
                    <h4 className="text-center">
                      {t('APPROVAL_PROCESS_FILE_MODAL.SETTINGS_INFORMATION')}
                    </h4>
                  </div>


                  <div className="divider"></div>
                  {(props?.approve?.selectedApproveProcessFile?.steps || []).map((stepItem: any, index: number) => {
                    console.log('stepItem', stepItem)
                    return (
                      <div key={'step__' + index} className="step">
                        <div className="step-number">
                          <h4 className="m-0">{index + 1}. {t('APPROVAL_PROCESS_FILE_MODAL.STEP')}</h4>

                        </div>
                        <div className="recipients">

                          {(stepItem?.stepUsers || []).map((stepUserItem: any, recipientIndex: number) => {
                            return (
                              <div key={'recipient' + stepUserItem?._id} className="recipient">
                                <div className="recipient-group">
                                  <div className="recipient-label">{stepUserItem?.email}</div>
                                  <div className="recipient-settings">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id={"inlineRadio1" + stepUserItem?._id}
                                        onChange={(e) => changeStepUserConfig(e.target.checked, stepItem, stepUserItem, 'canChangeRequest')}
                                        checked={stepUserItem?.canChangeRequest} />
                                      <label className="form-check-label" htmlFor={"inlineRadio1" + stepUserItem?._id}>{t('APPROVAL_PROCESS_FILE_MODAL.CAN_CHANGE_REQUEST')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        onChange={(e) => changeStepUserConfig(e.target.checked, stepItem, stepUserItem, 'canAssign')}
                                        className="form-check-input" type="checkbox" name="inlineRadioOptions" id={"inlineRadio2" + stepUserItem?._id} checked={stepUserItem?.canAssign} />
                                      <label className="form-check-label" htmlFor={"inlineRadio2" + stepUserItem?._id}>{t('APPROVAL_PROCESS_FILE_MODAL.CAN_ASSIGN')}</label>
                                    </div>

                                  </div>

                                </div>
                              </div>
                            )
                          })}




                        </div>



                      </div>
                    )
                  })}

                  <div className="footer-buttons">
                    <div className="buttons-left px-0">
                      <Button
                        onClick={() => {
                          setPageIndex(0)
                        }}
                        variant="link" className="d-flex align-items-center justify-content-start mx-0 px-0">
                        <FeatherIcons size={14} icon="arrow-left" />
                        <span style={{

                          textDecoration: 'underline',

                          fontWeight: 'bold'
                        }}>{t('APPROVAL_PROCESS_FILE_MODAL.BACK')}</span>
                      </Button>
                    </div>
                    <div className="buttons-right">
                      <Button variant="primary"
                        disabled={props?.approve?.startApproving}
                        className="floating-send-button"
                        onClick={() => {
                          if (props?.approve?.selectedApproveProcessFile?.steps.some((step: any) => !step.stepUsers.length)) {
                            toast.error('Lütfen adımları boş bırakmayınız.')
                            return;
                          }
                          props?.startApproving({
                            userMessage: userMessage
                          }).then((res: any) => {
                            props?.getReadApproveProcess({
                              id: props?.approve?.selectedApproveProcess?._id
                            })
                          })
                        }}
                      >
                        <span>{t('APPROVAL_PROCESS_FILE_MODAL.SEND_APPROVE')}</span>
                      </Button>
                      <Button variant="primary"
                        disabled={props?.approve?.startApproving}
                        className="floating-cancel-button"
                        onClick={(e: any) => { setShowDraftModal(true) }}
                      >
                        <span>{t('APPROVAL_PROCESS_FILE_MODAL.CANCEL')}</span>
                      </Button>
                    </div>

                  </div>
                </div>
              )}

            </>
          )}

        </Modal.Body>
      </Modal>


      <Modal
        show={showSaveAsModal}
        onHide={() => setShowSaveAsModal(false)}
        backdropClassName="shadow"
        contentClassName="shadow"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('APPROVAL_PROCESS_FILE_MODAL.SAVE_AS_TEMPLATE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="save-as-modal">
            <div className="form-group mb-3">
              <label htmlFor="">{t('APPROVAL_PROCESS_FILE_MODAL.TEMPLATE_NAME')}</label>
              <input
                key={'saveAsName'}
                value={saveAsName}
                onChange={(e) => setSaveAsName(e.target.value)}
                type="text" className="form-control" />
            </div>
            <div className="form-group d-flex align-items-center justify-content-end">
              <Button
                disabled={(saveAsName || loading) ? false : true}
                onClick={() => {
                  setLoading(true)
                  if (!saveAsName) {
                    toast.error('Lütfen şablon adı giriniz');
                    return
                  }
                  if (!props?.approve?.selectedApproveProcessFile?._id || !props?.companies?.company?._id) {
                    toast.error('Bir hata oluştu. Sayfayı yenileyip tekrar deneyiniz.');
                    return
                  }

                  apiInstance.post(`/approve-templates/save-as/${props?.companies?.company?._id}`, {
                    fileId: props?.approve?.selectedApproveProcessFile?._id,
                    saveAsName: saveAsName
                  }).then((res: any) => {

                    toast.success('Şablon başarıyla kaydedildi');
                    setShowSaveAsModal(false)

                    setApproveTemplateId(res?.data?.node?.data?.newApproveTemplate?._id)
                    setApproveTemplateName(res?.data?.node?.data?.newApproveTemplate?.title)
                    setShowButtons(false)

                  }).finally(() => {
                    setLoading(false)
                  })
                }}
                variant="primary" className="save-as-button">
                {t('APPROVAL_PROCESS_FILE_MODAL.SAVE')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSaveModal}
        onHide={() => setShowSaveModal(false)}
        backdropClassName="shadow"
        contentClassName="shadow"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('APPROVAL_PROCESS_FILE_MODAL.EDIT_TEMPLATE')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="save-modal">
            <p>{t('APPROVAL_PROCESS_FILE_MODAL.EDIT_TEMPLATE_MESSAGE')}</p>
            <div className="form-group">
              <Button
                disabled={!loading ? false : true}
                onClick={() => {

                  setLoading(true)
                  if (!props?.approve?.selectedApproveProcessFile?._id || !props?.companies?.company?._id || !approveTemplateId) {
                    toast.error('Bir hata oluştu. Sayfayı yenileyip tekrar deneyiniz.');
                    setLoading(false)
                    return
                  }

                  apiInstance.post(`/approve-templates/save/${props?.companies?.company?._id}`, {
                    templateId: approveTemplateId,
                    fileId: props?.approve?.selectedApproveProcessFile?._id,
                  }).then((res: any) => {
                    toast.success('Şablon başarıyla güncellendi');
                    setShowSaveModal(false)

                  }).finally(() => {
                    setLoading(false)
                  })
                }}
                variant="primary" className="save-button">
                {t('APPROVAL_PROCESS_FILE_MODAL.SAVE')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDraftModal}
        onHide={() => setShowDraftModal(false)}
        backdropClassName="shadow"
        contentClassName="shadow"
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('APPROVAL_PROCESS_FILE_MODAL.SAVE_AS_DRAFT')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="save-modal">
            <p>{t('APPROVAL_PROCESS_FILE_MODAL.SAVE_AS_DRAFT_MESSAGE')}</p>
            <div className="form-group d-flex align-items-center justify-content-end gap-1">
              <Button
                disabled={!loading ? false : true}
                onClick={() => {
                  apiInstance.delete(`/approve/delete/${props?.companies?.company?._id}/${props?.approve?.selectedApproveProcess?._id}`).then((res: any) => {
                    props.toggleApprovalProcessFileModal().then((res: any) => {
                      props?.resfreshDocumensTable();
                    })
                  })
                }}
                variant="outline-primary" className="save-button">
                {t('APPROVAL_PROCESS_FILE_MODAL.DELETE')}
              </Button>
              <Button
                disabled={!loading ? false : true}
                onClick={() => {
                  props.toggleApprovalProcessFileModal()
                }}
                variant="primary" className="save-button">
                {t('APPROVAL_PROCESS_FILE_MODAL.SAVE')}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(ApprovalProcessFileModal);
