import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import FeatherIcons from 'feather-icons-react';
import { HistoryEditor } from 'slate-history';

export default function HistoryElement(props: any) {

    const editor = useSlate()

    return (
        <Dropdown
            autoClose={"outside"}
            className='p-0 m-0'>
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip>
                    History
                </Tooltip>
            }>
                <Dropdown.Toggle

                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                    }}
                    as={Button}
                    variant='link'
                >

                    <FeatherIcons icon='rotate-ccw' size={18} />
                    <FaChevronDown style={{ marginLeft: '3px' }} size={10} />



                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='slate-toolbar-dd-menu'
            >
                <Dropdown.Item onMouseDown={(event: any) => {
                    event.preventDefault(); // Butona tıklama olayı sayfa yenilenmesini tetiklemesin
                    HistoryEditor.undo(editor); // Geri işlemi
                }}>
                    <FeatherIcons icon='rotate-ccw' size={18} />
                </Dropdown.Item>
                <Dropdown.Item onMouseDown={(event: any) => {
                    event.preventDefault(); // Butona tıklama olayı sayfa yenilenmesini tetiklemesin
                    HistoryEditor.redo(editor); // İleri işlemi
                }}>
                    <FeatherIcons icon='rotate-cw' size={18} />
                </Dropdown.Item>


            </Dropdown.Menu>
        </Dropdown>
    )
}
