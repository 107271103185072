import React from "react";
import { Route } from "react-router-dom";

//TYPES
import { RoutesProps } from "@routes/interfaces/index.interfaces";

// components
import PrivateRoute from "./PrivateRoute";
import Root from "@routes/Root";


// lazy load all the views

// auth
const Login = React.lazy(() => import("@pages/auth/Login"));
const Logout = React.lazy(() => import("@pages/auth/Logout"));
const Confirm = React.lazy(() => import("@pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import("@pages/auth/ForgetPassword"));
const ResetPassword = React.lazy(() => import("@pages/auth/ResetPassword"));
const Register = React.lazy(() => import("@pages/auth/Register"));
const LockScreen = React.lazy(() => import("@pages/auth/LockScreen"));
const ConfirmStart = React.lazy(() => import("@pages/auth/ConfirmStart"));
const ConfirmSuccessful = React.lazy(
  () => import("@pages/auth/ConfirmSuccessful")
);
const ConfirmFailed = React.lazy(() => import("@pages/auth/ConfirmFailed"));
// landing
const Landing = React.lazy(() => import("@pages/landing/"));

// dashboard
const ReportsDashboard = React.lazy(() => import("@pages/dashboard/index"));
const UserHome = React.lazy(() => import("@pages/userDashboard"));
const AnalyticsDashboard = React.lazy(
  () => import("@pages/dashboard/Analytics/")
);

// apps
const CalendarApp = React.lazy(() => import("@pages/apps/Calendar/"));
const Projects = React.lazy(() => import("@pages/apps/Projects/"));
const ProjectDetail = React.lazy(() => import("@pages/apps/Projects/Detail/"));
// - chat
const ChatApp = React.lazy(() => import("@pages/apps/Chat/"));
// - email
const Inbox = React.lazy(() => import("@pages/apps/Email/Inbox"));
const EmailDetail = React.lazy(() => import("@pages/apps/Email/Detail"));
const EmailCompose = React.lazy(() => import("@pages/apps/Email/Compose"));
// - tasks
const TaskList = React.lazy(() => import("@pages/apps/Tasks/List/"));
const Kanban = React.lazy(() => import("@pages/apps/Tasks/Board/"));

// - Contracts Operations

const ContractKanban = React.lazy(() => import("@pages/contracts/Board"));
const KanbanView = React.lazy(() => import("@pages/contracts/Kanban"));
const ContractOverview = React.lazy(() => import("@pages/contracts/Overview"));
const ContractTableList = React.lazy(() => import("@pages/contracts/List"));
const ContractFilterTableList = React.lazy(() => import("@pages/contracts/filterTable"));
const PipelineList = React.lazy(() => import("@pages/pipelines"));
const StatusList = React.lazy(() => import("@pages/statuses"));
const TemplatesList = React.lazy(() => import("@pages/templates"));
const CustomFields = React.lazy(() => import("@pages/customFields"));
const ContractTypes = React.lazy(() => import("@pages/contractTypes"));
const CustomForms = React.lazy(() => import("@pages/customForms"));
const ContractCompanies = React.lazy(() => import("@pages/contractCompanies"));
const ContractContacts = React.lazy(() => import("@pages/contractContacts"));

// - file
const FileManager = React.lazy(() => import('@pages/apps/FileManager'));
const FilesManagement = React.lazy(() => import('@pages/files/FilesManagement'));
const pdfViewer = React.lazy(() => import('@pages/pdfviewer'));

// Form
const ShareForm = React.lazy(() => import("@pages/customForms/ShareForm"));

// extra pages
const Error404 = React.lazy(() => import("@pages/error/Error404"));
const Error500 = React.lazy(() => import("@pages/error/Error500"));
// -other
const Starter = React.lazy(() => import("@pages/other/Starter"));
const Profile = React.lazy(() => import("@pages/other/Profile"));
const EditProfile = React.lazy(() => import("@pages/users/EditProfile"));
/* Edit Company Pages */
const EditCompany = React.lazy(() => import('@pages/companies/EditCompany'));
const EditCompanyDetailsScreen = React.lazy(() => import('@pages/companies/EditCompany/EditCompanyDetailsScreen'));
const EditCompanyUsersScreen = React.lazy(() => import('@pages/companies/EditCompany/EditCompanyUsersScreen'));
const EditCompanyUsersInvitesScreen = React.lazy(() => import('@pages/companies/EditCompany/EditCompanyUsersInvitesScreen'));
const EditCompanyDepartmentsScreen = React.lazy(() => import('@pages/companies/EditCompany/EditCompanyDepartmentsScreen'));
const EditCompanyRolesScreen = React.lazy(() => import('@pages/companies/EditCompany/EditCompanyRolesScreen'));

const CompaniesIntegrations = React.lazy(() => import('@pages/companies/Integrations'));
const DriveCompanies = React.lazy(() => import('@pages/integration/DriveCompanies'));
const GMailCompanies = React.lazy(() => import('@pages/integration/GMailCompanies'));
const CompanyInbox = React.lazy(() => import('@pages/inbox'));

const UsersIntegrations = React.lazy(() => import('@pages/users/UsersIntegrations'));
const DriveUsers = React.lazy(() => import('@pages/integration/DriveUsers'));

const Invites = React.lazy(() => import('@pages/companies/Invites'));

/* Edit Company Pages Ends */
const Activity = React.lazy(() => import("@pages/other/Activity"));
const Invoice = React.lazy(() => import("@pages/other/Invoice"));
const Maintenance = React.lazy(() => import("@pages/other/Maintenance"));
const Pricing = React.lazy(() => import("@pages/other/Pricing"));

// uikit
const UIElements = React.lazy(() => import("@pages/uikit"));

// widgets
const Widgets = React.lazy(() => import("@pages/widgets/"));

// icons
const Unicons = React.lazy(() => import("@pages/icons/Unicons"));
const FeatherIcons = React.lazy(() => import("@pages/icons/Feather/"));
const BootstrapIcon = React.lazy(() => import("@pages/icons/Bootstrap/"));

// forms
const BasicForms = React.lazy(() => import("@pages/forms/Basic"));
const FormAdvanced = React.lazy(() => import("@pages/forms/Advanced"));
const FormValidation = React.lazy(() => import("@pages/forms/Validation"));
const FormWizard = React.lazy(() => import("@pages/forms/Wizard"));
const FileUpload = React.lazy(() => import("@pages/forms/FileUpload"));
const Editors = React.lazy(() => import("@pages/forms/Editors"));

// charts
const Charts = React.lazy(() => import("@pages/charts/"));

// tables
const BasicTables = React.lazy(() => import("@pages/tables/Basic"));
const AdvancedTables = React.lazy(() => import("@pages/tables/Advanced"));

// maps
const GoogleMaps = React.lazy(() => import("@pages/maps/GoogleMaps"));
const VectorMaps = React.lazy(() => import("@pages/maps/VectorMaps"));

const OneDriveCompanies = React.lazy(() => import("@pages/integration/OneDriveCompanies"));
const OneDriveUsers = React.lazy(() => import("@pages/integration/OneDriveUsers"));
const SharePointCompanies = React.lazy(() => import("@pages/integration/SharePointCompanies"));
const SharePointUsers = React.lazy(() => import("@pages/integration/SharePointUsers"));
const ApproveTemplates = React.lazy(() => import("@pages/approve-templates"));
const Approve = React.lazy(() => import("@pages/approve"));
const ExternalApprovePage = React.lazy(() => import("@pages/approve/ExternalApprovePage"));
const ExtProcessApproval = React.lazy(() => import("@pages/ext/approval/index"));
const Feedback = React.lazy(() => import("@pages/feedback"));
const EditorComponents = React.lazy(() => import("@components/editors/index"));
const AuditTrail = React.lazy(() => import("@pages/audit-trails"));
const SpecialApplications = React.lazy(() => import("@pages/special-applications"));
const TestPage = React.lazy(() => import("@pages/test-page"));
const ContractTemplates = React.lazy(() => import("@pages/contractTemplates"));
const ContractTemplatesDetail = React.lazy(() => import("@pages/contractTemplates/templateDetails"));

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: () => <Root />,
  route: Route,
};

// recep routes
const Recep = React.lazy(() => import("@pages/recep/"));
const RecepDashboardBeta = React.lazy(() => import("@pages/recep-dashboard-beta/"));
// dashboards
const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/recep-dashboard",
      exact: true,
      component: () => <Recep />,
      route: Route,
    },
    {
      path: "/recep-dashboard-beta",
      exact: true,
      component: () => <RecepDashboardBeta />,
      route: Route,
    },
    {
      path: "/dashboard/reports",
      name: "Ecommerce",
      component: ReportsDashboard,
      route: PrivateRoute,
    }, {
      path: "/dashboard/user",
      component: UserHome,
      route: PrivateRoute,
    },
    {
      path: ['/users'],
      exact: true,
      name: 'Profile',
      component: Profile,
      route: Route,
    },
    {
      path: ['/users/profile'],
      exact: true,
      name: 'Profile',
      component: Profile,
      route: Route,
    },
    {
      path: ['/users/profile/edit'],
      exact: true,
      name: 'Edit Profile',
      component: EditProfile,
      route: Route
    },
    {
      path: ['/company/edit/all'],
      exact: true,
      name: 'Edit Company',
      component: EditCompany,
      route: Route
    },
    {
      path: ['/company/edit/details'],
      exact: true,
      name: 'Edit Company Details',
      component: EditCompanyDetailsScreen,
      route: Route
    },
    {
      path: ['/company/edit/users'],
      exact: true,
      name: 'Edit Company Users',
      component: EditCompanyUsersScreen,
      route: Route
    },
    {
      path: ['/company/edit/users/invites'],
      exact: true,
      name: 'Edit Company Users Invites',
      component: EditCompanyUsersInvitesScreen,
      route: Route
    },
    {
      path: ['/company/integrations'],
      exact: true,
      name: 'Edit Company Integrations',
      component: CompaniesIntegrations,
      route: Route
    },
    {
      path: ['/company/drive'],
      exact: true,
      name: 'Drive Integration for companies',
      component: DriveCompanies,
      route: Route
    },
    {
      path: ['/company/microsoft/onedrive'],
      exact: true,
      name: 'OneDrive Integration for companies',
      component: OneDriveCompanies,
      route: Route
    },
    {
      path: ['/user/microsoft/onedrive'],
      exact: true,
      name: 'OneDrive Integration for users',
      component: OneDriveUsers,
      route: Route
    },
    {
      path: ['/company/microsoft/sharepoint'],
      exact: true,
      name: 'SharePoint Integration for companies',
      component: SharePointCompanies,
      route: Route
    },
    {
      path: ['/user/microsoft/sharepoint'],
      exact: true,
      name: 'SharePoint Integration for users',
      component: SharePointUsers,
      route: Route
    },
    {
      path: ['/company/gmail'],
      exact: true,
      name: 'GMail Integration for companies',
      component: GMailCompanies,
      route: Route
    }, {
      path: ['/inbox'],
      exact: true,
      name: 'Company Inboxes',
      component: CompanyInbox,
      route: Route
    }, {
      path: ['/inbox/:integrationId'],
      exact: true,
      name: 'Company Inboxes',
      component: CompanyInbox,
      route: Route
    },
    {
      path: ['/user/integrations'],
      exact: true,
      name: 'Users Integrations',
      component: UsersIntegrations,
      route: Route
    },
    {
      path: ['/user/drive'],
      exact: true,
      name: 'Drive Integration for users',
      component: DriveUsers,
      route: Route
    },
    /* {
        path: ['/company/edit/departments'],
        exact: true,
        name: 'Edit Company Departments',
        component: EditCompanyDepartmentsScreen,
        route: Route
    }, */
    {
      path: ['/company/edit/roles'],
      exact: true,
      name: 'Edit Company Roles',
      component: EditCompanyRolesScreen,
      route: Route
    },
    {
      path: ['/company/users/invites/:inviteCode'],
      exact: true,
      name: 'Users Invites',
      component: Invites,
      route: Route
    },
    {
      path: ['/events', '/reports'],
      exact: true,
      name: 'Feedback',
      component: Feedback,
      route: Route
    },

    /* {
            path: '/dashboard/analytics',
            name: 'Analytics',
            component: AnalyticsDashboard,
            route: PrivateRoute,
        }, */
  ],
};

const calendarAppRoutes: RoutesProps = {
  path: "/apps/calendar",
  name: "Calendar",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "calendar",
  component: CalendarApp,
  header: "Apps",
};

const chatAppRoutes: RoutesProps = {
  path: "/apps/chat",
  name: "Chat",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "message-square",
  component: ChatApp,
};

const emailAppRoutes: RoutesProps = {
  path: "/apps/email",
  name: "Email",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "mail",
  children: [
    {
      path: "/apps/email/inbox",
      name: "Inbox",
      component: Inbox,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/details",
      name: "Email Details",
      component: EmailDetail,
      route: PrivateRoute,
    },
    {
      path: "/apps/email/compose",
      name: "Compose Email",
      component: EmailCompose,
      route: PrivateRoute,
    },
  ],
};

const projectAppRoutes: RoutesProps = {
  path: "/apps/projects",
  name: "Projects",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "uil-briefcase",

  children: [
    {
      path: "/apps/projects/list",
      name: "List",
      component: Projects,
      route: PrivateRoute,
    },
    {
      path: "/apps/projects/details",
      name: "Detail",
      component: ProjectDetail,
      route: PrivateRoute,
    },
  ],
};

const taskAppRoutes: RoutesProps = {
  path: "/apps/tasks",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: "/apps/tasks/list",
      name: "Task List",
      component: TaskList,
      route: PrivateRoute,
    },
    {
      path: "/apps/tasks/kanban",
      name: "Kanban",
      component: Kanban,
      route: PrivateRoute,
    },
  ],
};

const contractRoutes: RoutesProps = {
  path: "/contract",
  name: "Tasks",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/contract/kanban/:pipelineId"],
      name: "Kanban",
      component: ContractKanban,
      route: PrivateRoute,
    },
    {
      path: ["/kanban/:typeId", "/kanban"],
      name: "Kanban",
      component: KanbanView,
      route: PrivateRoute,
    },
    {
      path: ["/contract/overview/:contractId"],
      name: "Overview",
      component: ContractOverview,
      route: PrivateRoute,
    },
    {
      path: ["/contract/list"],
      name: "Contracts",
      component: ContractTableList,
      route: PrivateRoute,
    },
    {
      path: ["/contract/table"],
      name: "Contracts",
      component: ContractFilterTableList,
      route: PrivateRoute,
    },
    {
      exact: true,
      path: ["/contract/templates"],
      name: "Contracts",
      component: ContractTemplates,
      route: PrivateRoute,
    },
    {
      path: ["/contract/templates/:id"],
      exact: true,
      name: "ContractTemplatesDetail",
      component: ContractTemplatesDetail,
      route: PrivateRoute,
    },
  ],
};

const pipelineRoutes: RoutesProps = {
  path: "/pipelines",
  name: "Pipeline",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/pipelines"],
      name: "Pipeline List",
      component: PipelineList,
      route: PrivateRoute,
    },
  ],
};

const statusRoutes: RoutesProps = {
  path: "/statuses",
  name: "Status",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/statuses"],
      name: "Status List",
      component: StatusList,
      route: PrivateRoute,
    },
  ],
};
const templateRoutes: RoutesProps = {
  path: "/templates",
  name: "Templates",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/templates"],
      name: "Templates",
      component: TemplatesList,
      route: PrivateRoute,
    },
  ],
};
const specialApplications: RoutesProps = {
  path: "/special-applications",
  name: "Special Applications",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/special-applications"],
      name: "Templates",
      component: SpecialApplications,
      route: PrivateRoute,
    },
  ],
};

const customFields: RoutesProps = {
  path: "/custom-fields",
  name: "Custom Fields",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/custom-fields"],
      name: "Custom Field Management",
      component: CustomFields,
      route: PrivateRoute,
    },
  ],
};

const contractTypes: RoutesProps = {
  path: "/contract-types",
  name: "Contract Types Management",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/contract-types"],
      name: "Contract Types",
      component: ContractTypes,
      route: PrivateRoute,
    },
  ],
};
const customForms: RoutesProps = {
  path: "/contract-types",
  name: "Custom Forms Management",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/custom-forms"],
      name: "Custom Forms",
      component: CustomForms,
      route: PrivateRoute,
    },
  ],
};

const contractCompanies: RoutesProps = {
  path: "/contract-companies",
  name: "Contract Companies Management",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/contract-companies"],
      name: "Contract Companies",
      component: ContractCompanies,
      route: PrivateRoute,
    },
  ],
};

const contractContacts: RoutesProps = {
  path: "/contract-contacts",
  name: "Contract Contacts Management",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "clipboard",
  children: [
    {
      path: ["/contract-contacts"],
      name: "Contract Contacts",
      component: ContractContacts,
      route: PrivateRoute,
    },
  ],
};

const fileAppRoutes: RoutesProps = {
  path: "/apps/file-manager",
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: FileManager,
};
const approveTemplatesAppRoutes: RoutesProps = {
  path: "/approve-templates",
  name: "Approve Templates",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: ApproveTemplates,
};
const approveRoutes: RoutesProps = {
  path: "/approve",
  name: "Approve",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: Approve,
};
const auditTrailRoutes: RoutesProps = {
  path: "/audit-logs",
  name: "Audit Trail",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: AuditTrail,
};

const filesManagementRoutes: RoutesProps = {
  path: ["/files-management/:type", "/files-management"],
  name: "File Manager",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: FilesManagement,
};
const pdfRoutes: RoutesProps = {
  path: "/pdf",
  name: "pdf",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: pdfViewer,
};
const testRoutes: RoutesProps = {
  path: "/test-page",
  name: "testtest",
  route: PrivateRoute,
  roles: ["Admin"],
  icon: "folder-plus",
  component: TestPage,
};

const appRoutes = [
  pdfRoutes,
  testRoutes,
  calendarAppRoutes,
  chatAppRoutes,
  emailAppRoutes,
  projectAppRoutes,
  taskAppRoutes,
  fileAppRoutes,
  contractRoutes,
  pipelineRoutes,
  statusRoutes,
  templateRoutes,
  customFields,
  contractTypes,
  customForms,
  contractCompanies,
  contractContacts,
  specialApplications,
];

// pages
const extrapagesRoutes: RoutesProps = {
  path: "/pages",
  name: "Pages",
  icon: "package",
  header: "Custom",
  children: [
    {
      path: "/pages/starter",
      name: "Starter",
      component: Starter,
      route: PrivateRoute,
    },
    {
      path: "/pages/profile",
      name: "Profile",
      component: Profile,
      route: PrivateRoute,
    },
    {
      path: "/pages/activity",
      name: "Activity",
      component: Activity,
      route: PrivateRoute,
    },
    {
      path: "/pages/invoice",
      name: "Invoice",
      component: Invoice,
      route: PrivateRoute,
    },
    {
      path: "/pages/pricing",
      name: "Pricing",
      component: Pricing,
      route: PrivateRoute,
    },
  ],
};

// ui
const uiRoutes: RoutesProps = {
  path: "/components",
  name: "Components",
  icon: "package",
  header: "UI Elements",
  children: [
    {
      path: "/components/editors",
      name: "UIElements",
      component: EditorComponents,
      route: PrivateRoute,
    },
    {
      path: "/components/ui-elements",
      name: "UIElements",
      component: UIElements,
      route: PrivateRoute,
    },
    {
      path: "/components/widgets",
      name: "Widgets",
      component: Widgets,
      route: PrivateRoute,
    },
    {
      path: "/icons",
      name: "Icons",
      children: [
        {
          path: "/icons/unicons",
          name: "Unicons",
          component: Unicons,
          route: PrivateRoute,
        },
        {
          path: "/icons/feather",
          name: "Feather",
          component: FeatherIcons,
          route: PrivateRoute,
        },
        {
          path: "/icons/bootstrap",
          name: "Bootstrap Icon",
          component: BootstrapIcon,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/forms",
      name: "Forms",
      children: [
        {
          path: "/forms/basic",
          name: "Basic Elements",
          component: BasicForms,
          route: PrivateRoute,
        },
        {
          path: "/forms/advanced",
          name: "Form Advanced",
          component: FormAdvanced,
          route: PrivateRoute,
        },
        {
          path: "/forms/validation",
          name: "Form Validation",
          component: FormValidation,
          route: PrivateRoute,
        },
        {
          path: "/forms/wizard",
          name: "Form Wizard",
          component: FormWizard,
          route: PrivateRoute,
        },
        {
          path: "/forms/upload",
          name: "File Upload",
          component: FileUpload,
          route: PrivateRoute,
        },
        {
          path: "/forms/editors",
          name: "Editors",
          component: Editors,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/components/charts",
      name: "Charts",
      component: Charts,
      route: PrivateRoute,
    },
    {
      path: "/tables",
      name: "Tables",
      children: [
        {
          path: "/tables/basic",
          name: "Basic",
          component: BasicTables,
          route: PrivateRoute,
        },
        {
          path: "/tables/advanced",
          name: "Advanced",
          component: AdvancedTables,
          route: PrivateRoute,
        },
      ],
    },
    {
      path: "/maps",
      name: "Maps",
      children: [
        {
          path: "/maps/googlemaps",
          name: "Google Maps",
          component: GoogleMaps,
          route: PrivateRoute,
        },
        {
          path: "/maps/vectorMaps",
          name: "Google Maps",
          component: VectorMaps,
          route: PrivateRoute,
        },
      ],
    },
  ],
};

// auth
const authRoutes: RoutesProps[] = [
  {
    path: ["/login", "/auth/login"],
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: ["/register", "/signup", "/auth/register", "/auth/signup"],
    name: "Register",
    component: Register,
    route: Route,
  },
  {
    path: ["/confirm", "/auth/confirm"],
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
  {
    path: ["/forget-password", "/auth/forget-password"],
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
    exact: true
  },
  {
    path: ["/forget-password/:userId/reset-password/:passwordVerificationCode", "/auth/forget-password/:userId/reset-password/:passwordVerificationCode"],
    name: "Reset Password",
    component: ResetPassword,
    route: Route,
  },
  {
    path: ["/locked", "/auth/lock-screen", "/auth/locked", "/auth/lock-screen"],
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
  {
    path: [
      "/mail-confirmation/:userId/confirm/:emailVerificationCode",
      "/auth/mail-confirmation/:userId/confirm/:emailVerificationCode",
    ],
    name: "Confirm Mail",
    component: ConfirmStart,
    route: Route,
  },
  {
    path: [
      "/mail/confirmation/successful",
      "/auth/mail/confirmation/successful",
    ],
    name: "Confirm Mail Successful",
    component: ConfirmSuccessful,
    route: Route,
  },
  {
    path: ["/mail/confirmation/failed", "/auth/mail/confirmation/failed"],
    name: "Confirm Mail Successful",
    component: ConfirmFailed,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
];

// public routes
const otherPublicRoutes: RoutesProps[] = [
  /* {
        path: '/landing',
        name: 'landing',
        component: Landing,
        route: Route,
    }, */
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    component: Error404,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: Error500,
    route: Route,
  },
];
// public routes
const formPublicRoutes: RoutesProps[] = [

  {
    path: "/form/:companyUid/:formUid",
    name: "Form",
    component: ShareForm,
    route: Route,
  },
  {
    path: "/approve/e/:token",
    name: "External Approve Page",
    component: ExternalApprovePage,
    route: Route,
  },
  {
    path: "/ext/approval/:token",
    name: "External Process Approve Page",
    component: ExtProcessApproval,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  filesManagementRoutes,
  approveTemplatesAppRoutes,
  approveRoutes,
  auditTrailRoutes,
  ...appRoutes,
  extrapagesRoutes,
  uiRoutes,
];
const publicRoutes = [...authRoutes, ...otherPublicRoutes, ...formPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
  otherPublicRoutes,
};
