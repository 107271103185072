import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';

import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import api from '@clients/api.client';

import { toast } from 'react-toastify';

import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';

export const getUserIntegrations: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.GET_USERS_INTEGRATIONS,
        payload
    })
    return api.get("/user/integrations/all").then((response: any) => {
        dispatch({
            type: ActionDispatchTypes.GET_USERS_INTEGRATIONS_SUCCESSFUL,
            payload: response.data
        })
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.GET_USERS_INTEGRATIONS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    });
}

export const getDriveAuthUrlAndVisitPage = (payload: any) => (dispatch: any, getState: any) => {
    return api.post("/users/integrations/drive/authUrl", payload)
        .then((response: any) => {
            window.location.href = response.data?.node?.authUrl;
        })
        .catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toast.error(errorResponse.message);
            //toggleGenericErrorModal(dispatch, errorResponse);
            return errorResponse;
        })
}

export const getDriveAuthUrl = (payload: any) => (dispatch: any, getState: any) => {
    return api.post("/users/integrations/drive/authUrl", payload)
        .then((response: any) => {
            return response.data?.node?.authUrl;
        })
        .catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toast.error(errorResponse.message);
            //toggleGenericErrorModal(dispatch, errorResponse);
            return errorResponse;
        })
}


export const createDriveIntegrationForUsers: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_DRIVE_INTEGRATION_FOR_USERS,
        payload
    });

    return api.post(`/users-integrations`, payload).then((response: any) => {
        if (window.opener) {
            window.close();
        }
        toast.success(response.data.response.message);
        if (window.opener) {
            window.close();
        }

        if (window.opener) {
            window.close();
        } else {
            window.location.href = '/user/integrations';
        }
        //toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.CREATE_DRIVE_INTEGRATION_FOR_USERS_SUCCESSFUL,
            payload: response.data
        });
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        //toggleGenericErrorModal(dispatch, errorResponse);
        if (window.opener) {
            window.close();
        }
        window.location.href = '/user/integrations';
        toast.error(errorResponse.message);
        dispatch({
            type: ActionDispatchTypes.CREATE_DRIVE_INTEGRATION_FOR_USERS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const deleteIntegration: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.DELETE_INTEGRATION_FOR_USERS,
        payload
    });
    return api.delete(`/users-integrations/${payload?.integrationId}`).then((response: any) => {
        toggleGenericSuccessModal(dispatch, response.data.response);
        dispatch({
            type: ActionDispatchTypes.DELETE_INTEGRATION_FOR_USERS_SUCCESSFUL,
            payload: response.data
        });
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        dispatch({
            type: ActionDispatchTypes.DELETE_INTEGRATION_FOR_USERS_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const createOneDriveIntegrationForUser: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY,
        payload
    });
    return api.post(`/users/integrations/onedrive`, payload).then((response: any) => {
        toast.success(response.data.response?.message);
        window.location.href = '/user/integrations';
        if (window.opener) {
            window.close();
        }
        dispatch({
            type: ActionDispatchTypes.CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY_SUCCESSFUL,
            payload: response.data
        });
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        window.location.href = '/user/integrations';
        if (window.opener) {
            window.close();
        }
        dispatch({
            type: ActionDispatchTypes.CREATE_ONEDRIVE_INTEGRATION_FOR_COMPANY_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}

export const getOneDriveAuthUrl = (payload: any) => (dispatch: any, getState: any) => {
    return api.post("/users/integrations/onedrive/authUrl", payload)
        .then((response: any) => {
            return response.data?.node?.authUrl;
        })
        .catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            return errorResponse;
        })
}

export const getOneDriveAuthUrlAndVisitPage = (payload: any) => (dispatch: any, getState: any) => {
    return api.post("/users/integrations/onedrive/authUrl", payload)
        .then((response: any) => {
            window.location.href = response.data?.node?.authUrl;
        })
        .catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            return errorResponse;
        })
}

export const getSharePointAuthUrl = (payload: any) => (dispatch: any, getState: any) => {
    return api.post("/users/integrations/sharepoint/authUrl", payload)
        .then((response: any) => {
            return response.data?.node?.authUrl;
        })
        .catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            return errorResponse;
        })
}

export const getSharePointAuthUrlAndVisitPage = (payload: any) => (dispatch: any, getState: any) => {
    return api.post("/users/integrations/sharepoint/authUrl", payload)
        .then((response: any) => {
            window.location.href = response.data?.node?.authUrl;
        })
        .catch((error: any) => {
            const errorResponse: TGenericResponse = ErrorResponse(error);
            toggleGenericErrorModal(dispatch, errorResponse);
            return errorResponse;
        })
}

export const createSharePointIntegrationForUser: TAction = (payload: any) => (dispatch: any, getState: any) => {
    dispatch({
        type: ActionDispatchTypes.CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY,
        payload
    });
    return api.post(`/users/integrations/sharepoint`, payload).then((response: any) => {
        toast.success(response.data.response?.message);
        window.location.href = '/user/integrations';
        if (window.opener) {
            window.close();
        }
        dispatch({
            type: ActionDispatchTypes.CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY_SUCCESSFUL,
            payload: response.data
        });
        return response.data;
    }).catch((error: any) => {
        const errorResponse: TGenericResponse = ErrorResponse(error);
        toggleGenericErrorModal(dispatch, errorResponse);
        window.location.href = '/user/integrations';
        if (window.opener) {
            window.close();
        }
        dispatch({
            type: ActionDispatchTypes.CREATE_SHAREPOINT_INTEGRATION_FOR_COMPANY_FAILED,
            payload: errorResponse
        });
        return errorResponse;
    })
}