import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Row, Col, Form, Button, Alert, FloatingLabel } from "react-bootstrap";


import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
// components
import FieldList from "@pages/contractTypes/components/CustomFieldOffcanvas/FieldList";
import DropList from "@pages/contractTypes/components/CustomFieldOffcanvas/DropList";
import ContractTypesFieldList from "@pages/contractTypes/components/CustomFieldOffcanvas/ContractTypesFieldList";
import StatusList from "@pages/contractTypes/components/CustomFieldOffcanvas/StatusList";
import StatusDropList from "@pages/contractTypes/components/CustomFieldOffcanvas/StatusDropList";
import DefaultFieldsList from "@pages/contractTypes/components/CustomFieldOffcanvas/DefaultFieldsList";



import { connect } from "react-redux";
import { deleteCustomField, setNewContractTypeData, createNewContractType, getListCustomFields, getCompanyStatusesList } from "@redux/contract/index.actions";
import { toggleGenericAddCustomFieldModal, toggleGenericAddContractTypeModal } from "@redux/components/index.actions";

// components
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./index.scss";
import { toast } from "react-toastify";
import FeatherIcons from "feather-icons-react";

const mapStateToProps = (state: any) => ({
  companies: state.companies,
  contracts: state.contracts,
  components: state.components
});
const mapActionsToProps = {
  createNewContractType,
  setNewContractTypeData,
  deleteCustomField,
  toggleGenericAddCustomFieldModal,
  toggleGenericAddContractTypeModal,
  getListCustomFields,
  getCompanyStatusesList
};

const CreateContractType = (props: any) => {
  const { t } = useTranslation();


  useEffect(() => {
    props.getListCustomFields();
    props.getCompanyStatusesList();
    props.setNewContractTypeData({
      key: "reset",
      data: {}
    })
  }, [])

  const [id, setId] = useState(props?.data?._id);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [pageIndex, setPageIndex] = useState(0);

  const nextPage = () => {

    if (!props?.contracts?.newContractType?.title) {
      toast.error(t("Lütfen kayıt tipi adını giriniz."));
      setLoading(false);
      return;
    }

    if (pageIndex == 0 && props?.contracts?.newContractType?.statuses <= 0) {
      toast.error(t("Lütfen aşama ekleyiniz."));
      return;
    }



    setPageIndex(pageIndex + 1);
  }
  const prevPage = () => {
    setPageIndex(pageIndex - 1);
  }

  const onDragEnd = (result: DropResult) => {

    const { source, destination } = result;

    if (source.droppableId === 'fieldList' && destination?.droppableId == 'contractCustomFields') {

      props.setNewContractTypeData({
        key: "addCustomField",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

    if (source.droppableId === 'contractCustomFields' && destination?.droppableId == 'fieldList') {

      props.setNewContractTypeData({
        key: "removeCustomField",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

    if (source.droppableId === 'contractCustomFields' && destination?.droppableId == 'contractCustomFields') {

      props.setNewContractTypeData({
        key: "reOrder",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

  };


  const onDragEndStatus = (result: DropResult) => {

    const { source, destination } = result;

    if (source.droppableId === 'statusFieldList' && destination?.droppableId == 'contractStatuses') {
      console.log('Add status')
      props.setNewContractTypeData({
        key: "addStatus",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

    if (source.droppableId === 'contractStatuses' && destination?.droppableId == 'statusFieldList') {
      console.log('Remove status')
      props.setNewContractTypeData({
        key: "removeStatus",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

    if (source.droppableId === 'contractStatuses' && destination?.droppableId == 'contractStatuses') {

      console.log('Reorder status')
      props.setNewContractTypeData({
        key: "reOrderStatus",
        data: {
          sourceId: source.droppableId,
          destinationId: destination.droppableId,
          sourceIndex: source.index,
          destinationIndex: destination.index
        }
      })
    }

  };
  const handleSubmit = () => {
    setLoading(true);
    const newContract = props?.contracts?.newContractType

    interface requestData {
      title: string,
      company: string,
      customFields: [{
        field: string
        value: string
      }]
      showStartDate: boolean
      showEndDate: boolean
      showEffectiveDate: boolean
      showRenewalDate: boolean
      showSignedDate: boolean
      statuses: []
    }

    if (!newContract?.title) {
      setError(t("CONTRACTS_TYPES.NO_TITLE"));
      setLoading(false);
      return;
    }


    const requestData: requestData = {
      title: newContract?.title,
      company: props?.companies?.currentCompany,
      customFields: newContract?.customFields?.map((item: any) => {
        return {
          field: item?._id,
          value: ""
        }
      }),
      showStartDate: newContract?.showStartDate,
      showEndDate: newContract?.showEndDate,
      showEffectiveDate: newContract?.showEffectiveDate,
      showRenewalDate: newContract?.showRenewalDate,
      showSignedDate: newContract?.showSignedDate,
      statuses: newContract?.statuses?.map((item: any) => item?._id),
    };

    props
      .createNewContractType(requestData)
      .then((res: any) => {
        setLoading(false);
        setError('');
        props?.toggleGenericAddContractTypeModal();
      })
      .catch((err: any) => {
        setLoading(false);
        setError('');
      });
  };

  const handleInputChange = (event: any) => {
    console.log(event?.target?.value)
    console.log(event?.target?.name)
    props.setNewContractTypeData({
      key: "changeInput",
      data: {
        [event?.target?.name]: event?.target?.value,
      }
    })
  }

  return (
    <>
      <Modal
        show={props?.components?.newContractTypeModal}
        onHide={props?.toggleGenericAddContractTypeModal}
        size="xl"
        centered
      >
        <Modal.Header closeButton className="bg-secondary">
          <h4 className="modal-title text-white">{t("CONTRACTS_TYPES.NEW_CONTRACT_TYPE")}</h4>
        </Modal.Header>
        <Modal.Body>

          {pageIndex == 0 && (
            <>
              <Row>
                <Col xs={12} className="mb-3">

                  <FloatingLabel label={t("CONTRACTS_TYPES.TITLE_LABEL")}>
                    <Form.Control
                      name="title"
                      value={props?.contracts?.newContractType?.title}
                      onInput={event => handleInputChange(event)}
                      type="text" placeholder={t("CONTRACTS_TYPES.TITLE_PLACEHOLDER")} />
                  </FloatingLabel>
                  {(error) && <Alert className="mt-1" variant="danger">
                    {error}
                  </Alert>}
                </Col>
                <Col xs={12}>
                  <DefaultFieldsList />
                </Col>
                <><Col md={12} className="mb-3">
                  <DragDropContext onDragEnd={onDragEndStatus}>
                    <Row>
                      <Col md={6}>
                        <StatusList
                          setNewContractTypeData={props.setNewContractTypeData}
                        />
                      </Col>
                      <Col md={6}>

                        <StatusDropList
                          setNewContractTypeData={props.setNewContractTypeData} />
                      </Col>
                    </Row>
                  </DragDropContext>
                </Col></>
              </Row>
            </>
          )}

          {pageIndex == 1 && (
            <>

              <Col md={12} className="mb-3">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Row>
                    <Col md={6}>
                      <FieldList
                        setNewContractTypeData={props.setNewContractTypeData}
                      />
                    </Col>
                    <Col md={6}>
                      <DropList
                        setNewContractTypeData={props.setNewContractTypeData}
                      />
                    </Col>
                  </Row>
                </DragDropContext>
              </Col></>
          )}


          <Row>


            <Col md={12} className="d-flex justify-content-between gap-1">
              {
                pageIndex == 1 && (
                  <Button
                    variant="link"

                    onClick={prevPage}
                    style={{
                      color: '#000',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      fontSize: '14px',
                      lineHeight: '14px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px'

                    }}
                  >
                    <FeatherIcons icon="chevron-left" size={14} />
                    <span>{t("Önceki")}</span>
                  </Button>
                )

              }
              <div className="d-flex align-items-center justify-content-end w-100">
                {
                  pageIndex == 1 && (
                    <>
                      <Button
                        disabled={loading}
                        className="btn btn-md btn-dark"
                        onClick={handleSubmit}
                      >
                        {t("CONTRACTS_TYPES.CREATE")}
                      </Button>
                    </>)
                }

                {
                  pageIndex == 0 && (
                    <>
                      <Button
                        className="btn btn-md btn-dark"
                        onClick={nextPage}
                      >
                        {t("Sonraki")}
                      </Button>
                    </>
                  )
                }
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(CreateContractType);
