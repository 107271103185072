import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { connect } from "react-redux";

// components
import ReminderAdd from "../ReminderAdd";

import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({});
const mapActionsToProps = {};

const AddNewStatusModal = (props: any) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={props?.newStatusModal}
      onHide={props?.toggleNewStatusModal}
      size={'xl'}
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">{t("CONTRACTS.OVERVIEW.REMINDER.NEW_REMINDER")}</h4>
      </Modal.Header>
      <Modal.Body>
        <ReminderAdd
          handleRefresh={props.handleRefresh}
          handleSetCalendarData={props.handleSetCalendarData}
          toggleAddToCalendarModal={props?.toggleAddToCalendarModal}
          modalClose={props?.toggleNewStatusModal} />
      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(AddNewStatusModal);
