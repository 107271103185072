import React, { useEffect, useState } from "react";

import { Modal, Button, Row, Col } from "react-bootstrap";
import { Redirect, useHistory } from 'react-router-dom';
import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  removeSubContract,
} from "@redux/contract/index.actions";
import { toggleGenericContractOverviewModal } from "@redux/components/index.actions";
const mapStateToProps = (state: any) => ({
  companies: state?.companies
});
const mapActionsToProps = {
  removeSubContract,
  toggleGenericContractOverviewModal: toggleGenericContractOverviewModal
};

const RemoveSubRecordModal = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();

  const request: {
    id: string
    company: string
  } = {
    id: props?.data?._id,
    company: props?.companies?.currentCompany
  }

  const handleConfirmRemove = () => {
    props.removeSubContract(request).then((res: any) => {
      props.toggle();
      //history.push('/contract/list');
      /* if (res !== false && props?.subRecord === true) {
        console.log('Burada', 1, props?.subRecord)
        props.toggle();
        window.location.href = '/contract/table?c=' + props?.parentRecord?._id;
      } else if (res !== false && props?.subRecord !== true) {
        console.log('Burada', 2)
        window.location.href = '/contract/table';
      } */
    })
  }

  useEffect(() => {
    console.log('DeleteConfirm', props)
  }, [])

  return (
    <Modal
      show={props?.show}
      onHide={props?.toggle}
      size="sm"
      centered
      backdropClassName="contract-overview-modal-backdrop"
    >
      <Modal.Header closeButton className="bg-danger">
        <h4 className="modal-title text-white">{t("Remove the relationship")}</h4>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <p>
            Are you sure you want to remove the relationship?
          </p>
          <Col md={12} className="d-flex justify-content-end gap-1">
            <Button
              className="btn btn-sm"
              onClick={props?.toggle}
            >
              Cancel
            </Button>
            <Button
              className="btn btn-sm btn-danger"
              onClick={handleConfirmRemove}
            >
              Remove
            </Button>
          </Col>
        </Row>

      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(RemoveSubRecordModal);
