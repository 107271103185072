import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import UnknownUser from '@assets/images/users/unknown.jpg';
import { useHistory } from 'react-router-dom';


const initialState = {
    roles: [],
    endedFetchingRoles: false
}


const UsersReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }
        /* LOGIN STARTED */
        case ActionDispatchTypes.GET_ROLES:
            return {
                ...state
            }
        case ActionDispatchTypes.GET_ROLES_SUCCESSFUL:
            return {
                ...state,
                roles: Array.from(new Set([
                    ...action?.payload?.node?.roles
                ])),
                endedFetchingRoles: action?.payload?.node?.roles?.length === 0 ? true : false
            };
        case ActionDispatchTypes.GET_ROLES_FAILED:
            return {
                ...state
            }
        default:
            return state;
    }
}

export default UsersReducer;