import React from "react";

function PngFile(props: any) {
    return (
        <svg
            width={props?.width || "35px"}
            height={props?.height || "35px"}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 100 100"
            viewBox="0 0 100 125"
        >
            <path d="M95.407 29.835L67.026 1.45A1.533 1.533 0 0065.944 1H33.796c-7.482 0-13.569 6.089-13.569 13.571v26.856H8.317A4.178 4.178 0 004.144 45.6v23.987a4.178 4.178 0 004.173 4.173h11.909v11.67C20.227 92.911 26.313 99 33.796 99h48.491c7.482 0 13.569-6.089 13.569-13.571v-54.51a1.54 1.54 0 00-.449-1.084zM21.366 65.804a1.53 1.53 0 11-3.06 0V49.382a1.53 1.53 0 011.531-1.531h4.933c3.562 0 6.462 2.902 6.462 6.468v.207a6.47 6.47 0 01-6.462 6.462h-3.403v4.816zm71.429 19.625c0 5.796-4.714 10.51-10.508 10.51H33.796c-5.794 0-10.508-4.714-10.508-10.51v-11.67h50.415a4.178 4.178 0 004.173-4.173V45.6a4.178 4.178 0 00-4.173-4.173H23.287V14.571c0-5.796 4.714-10.51 10.508-10.51h30.617v23.792a4.599 4.599 0 004.59 4.596h23.792v52.98zM34.534 65.804V49.382c0-.687.457-1.29 1.122-1.474a1.527 1.527 0 011.719.685l7.023 11.693V49.382a1.53 1.53 0 113.06 0v16.423a1.531 1.531 0 01-2.843.789L37.595 54.9v10.904a1.53 1.53 0 11-3.061 0zm20.487-1.529h4.466c.634 0 1.168-.536 1.168-1.171V59.88h-3.401a1.53 1.53 0 110-3.06h4.931a1.532 1.532 0 011.531 1.532v4.753a4.235 4.235 0 01-4.228 4.232h-4.466a4.237 4.237 0 01-4.232-4.232V52.084a4.238 4.238 0 014.232-4.234h4.466a4.236 4.236 0 014.228 4.234 1.53 1.53 0 01-3.06 0c0-.635-.534-1.173-1.168-1.173h-4.466c-.645 0-1.171.526-1.171 1.173v11.019a1.171 1.171 0 001.17 1.172zm-30.252-6.349h-3.403v-7.015h3.403a3.41 3.41 0 013.401 3.407v.207a3.405 3.405 0 01-3.401 3.401z"></path>
        </svg>
    );
}

export default PngFile;
