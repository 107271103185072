import React, { useEffect, useRef, useState } from "react";
import ScreenModal from "@components/Modals/ScreenModal";
import { connect } from 'react-redux';
import "./index.scss";
import globalStyles from "@styles/globalStyles";
import { Form, Button, Toast, Col, Row } from "react-bootstrap";
import ButtonLoader from "@components/Buttons/ButtonLoader";
import { GrChapterAdd, GrAdd } from 'react-icons/gr';
import { BsFillCursorFill, BsSearch, BsPlusCircle } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';
import { RiDeleteBinFill } from 'react-icons/ri';
import { ImRadioChecked, ImRadioUnchecked } from 'react-icons/im';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import Select from 'react-select';

import { toggleCompanySwitchModal, toggleNewCompanyModal } from "@redux/components/index.actions";
import { getCompanies, setCurrentCompany } from "@redux/companies/index.actions";
import Loader from "@components/Loader";
import { PageLoader } from "@components/Loader/PageLoader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
    users: state.users,
    components: state.components,
    roles: state.roles,
    companies: state.companies
});

const mapActionsTopProps = {
    toggleCompanySwitchModal,
    getCompanies,
    setCurrentCompany,
    toggleNewCompanyModal
}


const CompanySwitch: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [changeCompany, setChangeCompany] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);
    const [currentSelection, setCurrentSelection] = useState<string | null>(null);
    const [startedFetching, setStartedFetching] = useState<boolean>(false);
    const [processed, setProcessed] = useState<boolean>(false);
    const next = () => {

    }
    const previous = () => {

    }

    const batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    } = {
        onClose: () => {
            props?.toggleCompanySwitchModal(false);
        },
        next: next,
        previous: previous,
        index: index,
        length: 1
    }



    useEffect(() => {
        if (props?.companies?.companies?.length === 0 && !startedFetching && props?.users?.user) {
            setStartedFetching(true);
            props?.getCompanies({}).then((res: any) => {
            });
        }

        if (props?.companies?.companies?.length > 0 && !processed) {
            setProcessed(true);
            props?.companies?.companies.forEach((item: any, index: number) => {
                if (item?.isCurrent) {
                    setCurrentSelection(item?._id);
                }
            })
        }
    }, [startedFetching, props?.users?.user, props?.companies?.companies]);



    const onInput = (e: any) => {

    }
    const handleSubmit = (e: any) => {

    }

    function copyText(entryText: string) {
        navigator.clipboard.writeText(entryText)
    }

    const prepareCompanies = () => {
        return (props?.companies?.companies || []).map((company: any, index: number) => {
            return <div role="btn" onClick={() => {
                if (currentSelection !== company?._id) {
                    setChangeCompany(true)
                    props?.setCurrentCompany({
                        companyId: company?._id
                    }).then((res: any) => {
                        props?.toggleCompanySwitchModal(false);
                        setCurrentSelection(company._id);
                        setChangeCompany(false)
                    }).catch((error: any) => {
                        setChangeCompany(false)
                    });
                } else {
                    toast.error("You already selected this company");
                }
            }} className={`btn ${(currentSelection === company?._id) ? "btn-primary" : "btn-outline-primary"} choose-company`}>
                <Row>
                    <Col xl={1} lg={1} md={1} sm={1} xs={1} className="radio">
                        {currentSelection === company?._id
                            ? <ImRadioChecked size={20} />
                            : <ImRadioUnchecked size={20} />}
                    </Col>
                    <Col xl={9} lg={9} md={9} sm={9} xs={9} className="main">
                        {company?.title}
                    </Col>
                    <Col xl={2} lg={2} md={2} sm={2} xs={2} className="arrow">
                        <MdOutlineArrowForwardIos size={30} />
                    </Col>
                </Row>
            </div>
        })
    }

    if (changeCompany) {
        return <PageLoader />
    }

    return (
        <React.Fragment>
            <ScreenModal
                show={props?.components?.showCompanySwitchModal}
                renderHeader={(<h4>
                    {t("POPUPS.COMPANY_SWITCH.COMPANY_SWITCH")}
                </h4>
                )}
                size={"lg"}
                renderFooter={(
                    <React.Fragment>

                        {/* <button
                            className="btn btn-primary companySwitch-finish"
                            onClick={(e) => {
                                if (currentSelection) {
                                    props?.setCurrentCompany({
                                        companyId: currentSelection
                                    }).then((res: any) => {
                                        props?.toggleCompanySwitchModal(false);
                                    });
                                }
                            }}>
                            Finish
                        </button> */}
                        <div className="d-flex align-item-start justify-content-start w-100">
                            <button
                                className="btn btn-outline-primary"
                                onClick={(e) => {
                                    props?.toggleCompanySwitchModal(false)
                                    props?.toggleNewCompanyModal(true)
                                }}>
                                {t("PROFILE_DROPDOWN_MENU.CREATE_COMPANY")}
                            </button>
                            <button
                                className="btn btn-primary companySwitch-finish"
                                onClick={(e) => {
                                    if (currentSelection) {
                                        props?.setCurrentCompany({
                                            companyId: currentSelection
                                        }).then((res: any) => {
                                            props?.toggleCompanySwitchModal(false);
                                        });
                                    }
                                }}>
                                Finish
                            </button>
                        </div>

                    </React.Fragment>
                )}
                onClose={batchOperations?.onClose}>
                <div className="company-switch">
                    <div className="container-fluid company-switch">
                        <div className="row company-switch-row">
                            <div className="col-lg-12 col-md-12">
                                <div className="innerContent">
                                    <p>
                                        {t("POPUPS.COMPANY_SWITCH.CHOOSE_COMPANY_DESCRIPTION")}
                                    </p>
                                    {(props?.companies?.company?.title) && prepareCompanies()}
                                    {(!props?.companies?.company?.title) && <div>
                                        {t("POPUPS.COMPANY_SWITCH.NO_COMPANY_FOUND")}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ScreenModal>

        </React.Fragment>
    )
}


export default connect(mapStateToProps, mapActionsTopProps)(CompanySwitch);