/* eslint-disable */
export default {
    LANGUAGE_SHORT_CODE: 'en',
    CREATE_BUTTON: 'New',
    PERMISSIONS: "Permissions",
    CREATE_DOCUMENT_FROM_TEMPLATE: "Create Document From Template",
    FEEDBACK: {
        MESSAGE: "We are improving this screen. Would you like to make a suggestion during this process?",
        BUTTON: "Give feedback",
    },
    LEFT_MENU: {
        APPROVE_TEMPLATES: 'Workflow Templates',
        REPORTS: "Reports",
        PIPELINE: "Pipelines",
        FILES: "Documents",
        CONTRACTS: "Process",
        ASSETS: "Assets",
        STATUSES: "Statuses",
        PIPELINES: "Pipelines",
        CUSTOM_FIELDS: "Custom Fields",
        CUSTOM_FORMS: "Forms",
        CONTRACT_TYPES: "Process Types",
        CONTRACT_COMPANIES: "Parties",
        CONTRACT_CONTACTS: "Process Contacts",
        MY_ACCOUNT: "Account",
        INBOX: "Inbox",
        TEMPLATES: "Templates",
        RECORDS: "Process",
        TABLE: "Process",
        ADMIN: "Admin",
        COMPANY_SETTINGS: "Workspace Settings",
        USERS: "Users",
        USER_INVITES: "User Invites",
        ROLES: "Roles",
        COMPANY_INTEGRATIONS: "Workspace Integrations",
        APPROVAL_MANAGEMENT: "Workflow Management",
        MY_ACCOUNT_SETTINGS: "My Account Settings",
        MY_ACCOUNT_INTEGRATIONS: "My Integrations",
        DOCUMENT_MANAGEMENT: "Repository",
        WORKFLOW_MANAGEMENT: "Workflow Management",
        DOCUMENT_GENERATION: "Document Generation",
        WORKFLOWS: "Workflows",
        EVENTS: "Events",
        DASHBOARD: "Home",
        PROCESS: "Processes",
        APPS: "Apps",
        INSIGHT: "Insights",
        ANALYTICS: 'Analytics & Reporting',
        AUDIT_LOGS: 'Audit Logs',
        SPECIAL_APPLICATIONS: 'Special Applications',
        FORMS_AND_TEMPLATE: 'Google Drive Templates',
    },
    TOPBAR_MENU: {
        TABLE: "Table",
        KANBAN: "Kanban",
        EVENTS: "Calendar",
        FILES_MANAGEMENT: "Documents",
        TEMPLATES: "Templates",
        CUSTOM_FORMS: "Forms",
        PROFILE: "My Account",
        MY_INTETRATIONS: "My Integrations",
        COMPANY_SETTINGS: "Settings",
        COMPANY_USERS: "User Management",
        COMPANY_INVITES: "Invites",
        COMPANY_ROLES: "Roles",
        CONTRACT_TYPES: "Process Types",
        CONTRACT_STATUSES: "Statuses",
        CUSTOM_FIELDS: "Custom Fields",
        MY_INTEGRATIONS: "My Integrations",
        DASHBOARD: "Analytics",
        FILES_MANAGEMENT_ALL: "All",
        FILES_MANAGEMENT_DRAFT: "Draft",
        FILES_MANAGEMENT_COMPLATED: "Completed",
        FILES_MANAGEMENT_TEMPLATE: "Template",
        INSIGHTS: "Insights",
        REPOSITORY: "Repository",
        TRACKING: "Tracking",
        CUSTOMIZATIONS: "Customizations",
        INTEGRATIONS: "Integrations",
        INSIGHT: "Insights",
        ANALYTICS: "Analytics & Reporting",
        AUDIT_LOGS: "Audit Logs",
    },
    API_TABLE: {
        SEARCH_FOR_KEYWORDS: "Search For Keywords",
        SEARCH: "Search",
        CLEAR: "Clear",
        DISPLAY: "Display:",
        ENTRIES: "Entries",
        TOTAL: "Total:",
        NO_DATA_FOUND: "No data found in the system"
    },
    API_TABLE_CUSTOM_COMPONENT: {
        SEARCH_FOR_KEYWORDS: "Search For Keywords",
        SEARCH: "Search",
        CLEAR: "Clear",
        EXPORT: "Export",
        EXPORT_TO_EXCEL: "Export to Excel",
        PLEASE_WAIT: "Please wait...",
        DISPLAY: "Display:",
        ENTRIES: "Entries",
        TOTAL: "Total:",
        NO_DATA_FOUND: "No data found in the system"
    },
    ACTIONS_DROPDOWN_MENU: {
        GENERAL: "General",
        CONTRACT: "Process",
        FILE: "Document",
        USER: "User",
        ASSET: "Asset",
        PIPELINE: "Pipeline",
        STATUS: "Status",
        CONTRACT_TYPE: "Process Type",
        CUSTOM_FIELD: "Custom Field",
        TYPE: "Process Types",
        COMPANY: "Workspace",
        CONTACT: "Contact",
        CREATE: 'Create',
        NEW_RECORD: 'New Process',
        NEW_TEMPLATE: 'Create Document',
        NEW_FORM: 'New Form',
        UPLOAD: 'Upload',
        UPLOAD_DOCUMENT: 'Bulk Upload',
        NEW_PROCESS: 'New Process',
        CREATE_PROCESS: 'Create Process',
        DESCRIPTIONS: {
            CREATE_PROCESS: 'Start the process from scratch, by uploading or requesting documents',
            UPLOAD_DOCUMENT: 'Bulk upload your documents and link them to processes',
            NEW_TEMPLATE: 'Use forms and templates to create documents'
        }
    },
    PROFILE_DROPDOWN_MENU: {
        WELCOME: "Welcome !",
        PROFILE: "Account",
        MY_ACCOUNT: "My Account Settings",
        MY_INTEGRATIONS: "My Integrations",
        CREATE_COMPANY: "Create Workspace",
        COMPANY_SWITCH: "Workspace Switch",
        COMPANY: "Admin",
        COMPANY_SETTINGS: "Workspace Settings",
        COMPANY_USERS: "Users",
        COMPANY_INVITES: "Invites",
        COMPANY_ROLES: "Roles",
        COMPANY_INTEGRATIONS: "Workspace Integrations",
        COMPANY_PIPELINES: "Pipelines",
        COMPANY_STATUSES: "Statuses",
        LOG_OUT: "Log Out",
        LOCK_SCREEN: "Lock Screen",
        LOGOUT: "Logout",
        CUSTOM_FIELDS: "Custom Fields",
        RECORD_TYPES: "Process Types",
    },
    LANGUAGE: {
        ENGLISH: "English",
        TURKISH: "Turkish"
    },
    APPS: {
        DRIVE_IMPORT: "Drive Import",
        DRIVE_EXPORT: "Drive Export",
        GMAIL_IMPORT: "Gmail Import",
        ONEDRIVE_IMPORT: "OneDrive Import",
        ONEDRIVE_EXPORT: "OneDrive Export",
        SHAREPOINT_IMPORT: "SharePoint Import",
        SHAREPOINT_EXPORT: "SharePoint Export",
    },
    COMPANIES: {
        DETAILS: {
            TITLE: "COMPANY SETTINGS",
            DESCRIPTION: "Details of the current company: you can read, edit, delete details in the company",
            COMPANY_TITLE_LABEL: "Workspace Title:",
            COMPANY_TITLE_PLACEHOLDER: "Workspace Title",
            COMPANY_DESCRIPTION_LABEL: "Workspace Description:",
            COMPANY_DESCRIPTION_PLACEHOLDER: "Workspace Description",
            COMPANY_DOMAIN_LABEL: "Workspace Domain:",
            COMPANY_DOMAIN_PLACEHOLDER: "Workspace Domain",
            COMPANY_LOGO_URL_LABEL: "Logo Url (300x300):",
            COMPANY_LOGO_URL_PLACEHOLDER: "https://website.com/logo.png",
            SAVE_CHANGES: "Save Changes",
            DELETE_COMPANY_LABEL: "DELETE YOUR COMPANY",
            DELETE_COMPANY_DESCRIPTION: "You can delete your company from onedocs. Your company information will be stored for 30 days after delete command. You can reach us after deletion via support channel to restore your information.",
            DELETE_COMPANY_CHECK_DESCRIPTION: "You can type 'permanent-delete' into input box to delete your company",
            DELETE_CHECK_LABEL: "Delete Check:",
            DELETE_CHECK_PLACEHOLDER: "permanent-delete",
            DELETE_CHECK_INVALID: "Please provide 'permanent-delete' key into the input box",
            DELETE_COMPANY_BUTTON: "Delete Workspace",
            CDN_ENDPOINT_TITLE: "Custom CDN Endpoint",
            CDN_ENDPOINT_DESCRIPTION: "You can use your own CDN endpoint to store your companies documents. If you provide any CDN endpoint, we will use our CDN endpoint to store your companies documents.",
            CDN_ENDPOINT: "CDN Endpoint",
            CDN_ENDPOINT_PLACEHOLDER: "https://cdn-jetlexa.example.com",
            CDN_ENDPOINT_LOCKED: "You can not change CDN Endpoint after you set it. If you want to change CDN Endpoint, please contact us via support channel.",
            SAVE: "Save Changes",
            DEMO_DATA_DELETION_TITLE: "Demo Data Deletion",
            DEMO_DATA_DELETION_DESCRIPTION: "You can delete demo data from your Workspace. Demo data will be deleted permanently.",
            CONTRACTS: "Process",
            PIPELINES: "Pipelines",
            STATUSES: "Statuses",
            CUSTOM_FIELDS: "Custom Fields",
            RECORD_TYPES: "Process Types",
            PARTIES: "Parties",
            DELETE: "Delete",
            DELETE_ALL: "Delete All",
        },
        USERS: {
            TITLE: "COMPANY USERS",
            DESCRIPTION: "Users of the current workspace: you can search, read, edit, delete for users in the company",
            INVITE_USER: "Invite a new person",
            TABLE: {
                ID: "ID",
                NAME: "Name",
                SURNAME: "Surname",
                EMAIL: "Email",
                PHONE_NUMBER: "Phone Number",
                USERNAME: "Username",
                CREATED_DATE: "Created Date"
            }
        },
        INVITES: {
            TITLE: "COMPANY INVITES",
            DESCRIPTION: "Invites of the current company: you can search, read, edit, delete for users invited in the company",
            INVITE_USER: "Invite a new person",
            TABLE: {
                ID: "ID",
                EMAIL: "Email",
                STATUS: "Status",
                CREATED_DATE: "Created Date"
            }
        },
        ROLES: {
            TITLE: "COMPANY ROLES",
            DESCRIPTION: "Roles of the current company: you can search, read, edit, delete for roles in the company",
            CREATE_ROLE: "Create a role",
            TABLE: {
                ID: "ID",
                TITLE: "Title",
                NOMINATIONS: "Nominations",
                CREATED_DATE: "Created Date"
            },
            DEFAULT_ROLES: {
                OWNER: "Owner",
                SUPER_ADMIN: "Super Admin",
                ADMIN: "Admin",
                USER: "User",
            },
            NOMINATIONS: {
                "edit-company-details": "Workspace Settings: Details",
                "edit-company-users": "Workspace Settings: Users",
                "edit-company-users-invites": "Workspace Settings: Users Invites",
                "edit-company-roles": "Workspace Settings: Roles",
                "edit-company-integrations": "Workspace Settings: Integrations",
                "files-management": "Documents Management",
                "contracts-management": "Process Management",
                "contracts-deletion": "Process Deletion",
                "approve-management": "Workflow Management",
                "approve-file-continue-flow": "Workflow Management - Continue Flow",
                "remove-integration": "Remove Integration",
                "edit-process": "Edit Process",
            }
        }
    },
    COMPANIES_INTEGRATIONS: {
        TITLE: "COMPANY INTEGRATIONS",
        DESCRIPTION: "Integrations of the current company: you can read, delete for integrations in the company",
        GDRIVE_INTEGRATION_TITLE: "Google Drive Export",
        ONEDRIVE_INTEGRATION_TITLE: "OneDrive Export",
        SHAREPOINT_INTEGRATION_TITLE: "SharePoint Export",
        SHAREPOINT_SITES_SELECTION: "SharePoint Sites Selection",
        GDRIVE_INTEGRATION_DESCRIPTION: "You can integrate your company account with Google Drive to export documents on Google Drive",
        ONEDRIVE_INTEGRATION_DESCRIPTION: "You can integrate your company account with OneDrive to export documents on OneDrive",
        SHAREPOINT_INTEGRATION_DESCRIPTION: "You can integrate your company account with SharePoint to export documents on SharePoint",
        CONNECT: "Connect",
        CONNECTED: "Connected",
        REMOVE_CONNECTION: "Remove Connection",
        ACTIVE_INTEGRATION: "active account(s)",
        CONNTECTED_ACCOUNT: "Connected Account",
        ACTIVE: "Active",
        SHARE_INFO: "If you want to share documents with your team, choose a common storage location.",
        CONNECTED_ACCOUNT_SHARE_INFO: "Make sure you choose a shared storage account so that your documents can be shared with your team.",
        CHAT_GPT: "You can integrate your ChatGPT account with onedocs and speed up your data entry processes.",
        SALES_FORCE: "We continue to work on onedocs and Salesforce integration..."
    },
    CONTRACTS: {
        PAGE_TITLE: "Process Overview",
        CONTRACT: {
            TITLE: "Process:",
        },
        REPORTS: {
            ADD: "Add",
            NEW: "New",
            FILES: "DOCUMENTS",
            DASHBOARD: "Dashboard",
            TEMPLATES: "Templates",
            CUSTOM_FORMS: "Custom Forms",
            CONTRACTS: "Process",
            STATUSES: "Statuses",
            CREATE_COMPANY: "Create company",
            CREATE_CONTRACT: "Create process",
            ADD_USER_TO_COMPANY: "Add user to company",
            CONTRACT_TYPES: "Process Types",
            MONTHLY_REPORT: "Monthly Process",
            TIMELINE: "Timeline",
            NO_DATA_TIMELINE: "No data for timeline",
        },
        REPORTS_TABLE: {
            TITLE: "Title",
            START_DATE: "Start Date",
            END_DATE: "End Date",
            RENEWAL_DATE: "Renewal Date",
            TYPE: "Type",
            PIPELINE: "Pipeline",
            CREATED_AT: "Created At",
        },
        TABLE: {
            TOP_TITLE: "Process",
            PAGE_TITLE: "RECORDS",
            PAGE_DESCRIPTION: "processes listed.",
            ADD_NEW: "Add New",
            TITLE: "Title",
            START_DATE: "Start Date",
            END_DATE: "End Date",
            RENEWAL_DATE: "Renewal Date",
            EFFECTIVE_DATE: "Effective Date",
            SIGNED_DATE: "Signed Date",
            COMPANIES: "Parties",
            COMPANIES_INTERNAL: "Company (Internal) Party",
            COMPANIES_EXTERNAL: "Counter Party",
            CONTACTS: "Contacts",
            ASSIGNED_USERS: "Assigned Users",
            PIPELINE: "Pipeline",
            STATUS: "Status",
            TYPE: "Type",
            CREATED_AT: "Created At",
            DELETE_ALL: "Delete All",
            DELETE_SELECTED: "Delete Selected",
            SEARCH: {
                PLACEHOLDER: 'Search process...',
                TITLE: 'Title',
                DESCRIPTION: 'Description',
                CUSTOM_FIELDS: 'Custom Fields'
            },
            FILTERS: {
                FILTERS: 'Filters',
                ADD_FILTERS: 'Add Filters',
                RESET_FILTERS: 'Reset Filters',
                WHERE: 'WHERE',
                AND: 'AND',
                OR: 'OR',
                CONTAINS: 'Contains',
                NOT_CONTAINS: 'Not Contains',
                DATE_RANGE: 'Date Range',
                SELECTION: 'Selection',
                SELECTION_IN: 'Is',
                SELECTION_NOT_IN: 'Is not',
                FAVORITE_FILTERS: 'Favorite Filters',
                SAVE_FILTERS: 'Save Filters',
                MAKE_DEFAULT_FILTERS: 'Make Default',
                SEARCH_FILTERS: 'Search Filters',
                EDIT_FILTERS: 'Edit Filters',
                FILTER_TITLE: 'Filter Title',
                DELETE_FILTERS: 'Delete Filters',
                CANCEL: 'Cancel',
                ARE_YOU_SURE: 'Are you sure?',
                ARE_YOU_SURE_DELETE_FILTERS_DESCRIPTION: 'You are permanently deleting the filter. Are you sure you want to delete?',
                DELETE: 'Delete',
                SAVE: 'Save',
                DEFAULT_FILTERS: 'Default Filters',
                NO_DEFAULT_FILTERS: 'No Default Filters',
                NO_FAVORITE_FILTERS: 'No Favorite Filters',
                ALL_DATA_LOADED: 'All data loaded',
                FILTERS_SELECTED: 'Filters Selected',
                REMOVE_DEFAULT_FILTERS: 'Remove Default',
                EQ: 'Equals',
                NEQ: 'Not Equals',
                GT: 'Greater Than',
                LT: 'Less Than',
                GTE: 'Greater Than or Equals',
                LTE: 'Less Than or Equals',
                EMPTY: 'Is Empty',
                NOT_EMPTY: 'Is Not Empty',
                DATE_EQ: 'Date Equals',
                DATE_NEQ: 'Date Not Equals',
                DATE_GT: 'Date Greater Than',
                DATE_LT: 'Date Less Than',
                DATE_GTE: 'Date Greater Than or Equals',
                DATE_LTE: 'Date Less Than or Equals',
                DATE_EMPTY: 'Date Empty',
                DATE_NOT_EMPTY: 'Date Not Empty',
            },
            SORT_BY: {
                SORT_BY: 'Sort By'
            },
            COLUMNS: {
                COLUMNS: 'Columns'
            },
            EXPORT: {
                EXPORT: 'Export',
                EXPORT_TO_EXCEL: 'Export to Excel'
            },
            FOOTER: {
                TOTAL: 'Total',
                PER_PAGE: 'Per Page'
            }
        },
        OVERVIEW: {
            SUB_RECORDS: "Sub Process",
            PARENT_RECORD: "Main Process",
            REMOVE_RELATION: "Remove the relationship",
            ADD_SUB_RECORD: "Add Sub Process",
            SELECT_SUB_RECORD: "Select from existing process",
            SELECT_SUB_RECORDS: "Select Sub Process",
            NEW_RECORD: "New Process",
            DELETE_RECORD: "Delete",
            RELATE_TO_THE_RECORD: "Relate to the process",
            TITLE_ERROR: "Please provide a title for the process",
            ACTIONS: {
                ACTIONS: "Actions",
                EDIT_CONTRACT: "Edit Process",
                REMOVE_CONTRACT: "Remove Process",
            },
            INFO_CARDS: {
                START_DATE: "Start Date",
                END_DATE: "End Date",
                EFFECTIVE_DATE: "Effective Date",
                SIGNED_DATE: "Signed Date",
                RENEWAL_DATE: "Renewal Date",
                ASSIGNED_USERS: "Assigned Users"
            },
            ACTIVITY_FIELD: {
                FILES: "Documents",
                REMINDER: "Events"
            },
            FILES: {
                FILES_DESCRIPTION: "When you add your documents, you can see their activities in activity feed.",
                ADD_FILES: "Add Documents",
                UPLOAD_FILES: "Upload Documents",
                SELECT_UPLOADED_FILES: "Select Uploaded Documents",
                ADDED_FILES: "Added Documents",
                SHOW_ALL_FILES: "Show All Documents",
                FILENAME: "Filename",
                UPLOADED_BY: "Uploaded By",
                ACTIONS: "Actions",
                NO_FILES: "No document has been found for this process.",
                SAVE_CHANGES: "Save Changes",
                EDIT: "Edit",
                CLOSE_EDIT_MODE: "Close Edit Mode",
                DOWNLOAD: "Download",
                DELETE: "Delete",
                GO_TO_PAGE: "Go to Page",
                TOTAL_FILES: "Total Documents:",
                MAX_PAGE: "Max Page:",
                DELETE_FILE: "Delete Document",
                DELETE_FILE_DESCRIPTION: "Are you sure about deletion of the documents?",
                STATUS: "Status",
                FILES_TITLE: "Documents",
                APPROVE_PROCESS: "Related Workflow",
            },
            REMINDER: {
                REMINDER_DESCRIPTION: "You can add event to not forget important things.",
                ADD_REMINDER: "Add Event",
                REMINDERS: "Events",
                PROVIDE_GUEST_NAME: "Please provide a guest name",
                PROVIDE_GUEST_SURNAME: "Please provide a guest surname",
                PROVIDE_GUEST_EMAIL: "Please provide a guest email",
                TITLE_LABEL: "Title",
                TITLE_PLACEHOLDER: "Enter a event title...",
                DATE_LABEL: "Event Date",
                DESCRIPTION_LABEL: "Description",
                DESCRIPTION_PLACEHOLDER: "Enter a event description...",
                GUESTS_LABEL: "Guests",
                ADD_USER: "Add User",
                ADD_OUTSOURCE_GUESTS: "Add Outsourced Guests",
                CANCEL: "Cancel",
                CREATE: "Create",
                NAME_LABEL: "Name",
                NAME_PLACEHOLDER: "Enter a guest name...",
                SURNAME_LABEL: "Surname",
                SURNAME_PLACEHOLDER: "Enter a guest surname...",
                EMAIL_LABEL: "Email",
                EMAIL_PLACEHOLDER: "Enter a guest email...",
                ADD_GUEST: "Add Guest",
                SEARCH_A_USER: "Search a user",
                NO_MORE: "No More",
                NO_DATA_FOUND_IN_THE_REMINDERS: "No data found in the events",
                CREATE_FIRST_REMINDER: "Create first event",
                NEW_REMINDER: "New Event",
                NEW_STATUS: "New Status",
                ADD_TO_CALENDAR: "Add to Calendar",
                ADD_TO_GOOGLE_CALENDAR: "Add to Google Calendar",
                ADD_TO_OUTLOOK_CALENDAR: "Add to Outlook Calendar",
                ADD_TO_OFFICE_360_CALENDAR: "Add to Office 365 Calendar",
                ARE_YOU_SURE: "Are you sure?",
                ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the event. Are you sure you want to delete?",
                REMOVE: "Remove"
            },
            DESCRIPTION: {
                TITLE: "Process Description",
                DESCRIPTION_PLACEHOLDER: "Enter a process description...",
                SAVE: "Save"
            },
            TAGS: {
                TAGS: "Tags",
                NO_TAGS: "Process doesn't have tags",
                ADD_TAGS_TO_CONTRACT: " Add tags to process",
                ADD_NEW_TAG_PLACEHOLDER: "Add a new tag...",
            },
            COMPANIES: {
                COMPANIES: "Parties",
                NO_COMPANY_FOUND: "Process doesn't have any company",
                NO_MORE: "No More",
                ADD_COMPANY_TO_CONTRACT: "Add Company to Process"
            },
            CONTACTS: {
                CONTACTS: "Contacts",
                ADD_CONTACT_TO_CONTRACT: "Add Contact to Process",
                ALL_DATA_LOADED: "All data loaded",
            },
            CUSTOM_FIELDS: {
                CUSTOM_FIELDS: "Custom Fields",
            },
            ASSIGNED_USERS: {
                ASSIGNED_USERS: "Assigned Users",
                ASSIGN_USER_TO_CONTRACT: "Assign a user to process",
                NO_ASSIGNED_USERS: "Contract doesn't have assigned users",
                ALL_DATA_LOADED: "All data loaded",
                SEARCH_PLACEHOLDER: "Search a user"
            },
            CONTRACT_TYPE: "Process Type",
            CHAT: {
                SEND: "Send",
                WRITE_A_MESSAGE: "Write a comment...",
            },
            DELETE_MODAL: {
                DELETE: "Delete",
                DELETE_ALL: "Delete All",
                DELETE_ALL_DESCRIPTION: "If you delete the main process, all subrecords also will be deleted. If there is a subrecord which you do not want to delete, you can select \"Remove the relationship\" in the subrecord actions.",
                DELETE_DESCRIPTION: "Are you sure permanently delete the process?",
                CANCEL: "Vazgeç",
            }
        },
        ADD_CONTRACT: {
            NEW_CONTRACT: "New Process",
            NO_TITLE: "Please provide a title",
            NO_STATUS: "Please provide a status",
            NO_TYPE: "Please provide a type",
            PIPELINE: "Please provide a pipeline",
            NO_COMPANY: "Please provide a company",
            TITLE_LABEL: "Title",
            TITLE_PLACEHOLDER: "Enter a process title...",
            DESCRIPTION_LABEL: "Description",
            DESCRIPTION_PLACEHOLDER: "Enter a process description...",
            START_DATE_LABEL: "Start Date",
            END_DATE_LABEL: "End Date",
            EFFECTIVE_DATE_LABEL: "Effective Date",
            RENEWAL_DATE_LABEL: "Renewal Date",
            SIGNED_DATE_LABEL: "Signed Date",
            PIPELINE_LABEL: "Pipeline",
            PIPELINE_PLACEHOLDER: "Select a pipeline",
            STATUS_LABEL: "Status",
            STATUS_PLACEHOLDER: "Select a status",
            TYPE_LABEL: "Process Type",
            TYPE_PLACEHOLDER: "Select a process type",
            CANCEL: "Cancel",
            CREATE: "Create",
            MAKE_PRIVATE: "Make Private",
            MAKE_PUBLIC: "Make Public",
            PARTIES_INTERNAL: "Company (Internal) Party",
            PARTIES_EXTERNAL: "Counter Party",
            ASSIGNED_USERS: "Assigned Users",
            RELATED_WITH: "Related With",
        },
        EDIT_CONTRACT: {
            MODAL_TITLE: "Edit Process",
            SOMETHING_IS_WRONG: "Something went wrong! Please refresh your page!",
            NO_TITLE: "Please provide a title",
            NO_STATUS: "Please provide a status",
            NO_TYPE: "Please provide a type",
            NO_COMPANY: "Please provide a company",
            TITLE_LABEL: "Title",
            TITLE_PLACEHOLDER: "Enter a process title...",
            DESCRIPTION_LABEL: "Description",
            DESCRIPTION_PLACEHOLDER: "Enter a process description...",
            START_DATE_LABEL: "Start Date",
            END_DATE_LABEL: "End Date",
            EFFECTIVE_DATE_LABEL: "Effective Date",
            RENEWAL_DATE_LABEL: "Renewal Date",
            SIGNED_DATE_LABEL: "Signed Date",
            PIPELINE_LABEL: "Pipeline",
            PIPELINE_PLACEHOLDER: "Select a pipeline",
            STATUS_LABEL: "Status",
            STATUS_PLACEHOLDER: "Select a status",
            TYPE_LABEL: "Process Type",
            TYPE_PLACEHOLDER: "Select a process type",
            CANCEL: "Cancel",
            SAVE: "Save",
            MAKE_PRIVATE: "Make Private",
            MAKE_PUBLIC: "Make Public"
        },
        KANBAN: {
            PAGE_TITLE: "Kanban",
            PIPELINES: "Pipelines",
            SWITCH_PIPELINE: "Switch",
            EDIT_PIPELINE: "Edit Pipeline",
            MAKE_USER_DEFAULT: "Make User Default",
            ADD_NEW: "Add New",
            CONTRACT: "Process",
            ALL_DATA_LOADED: "All data loaded",
            NO_CONTRACT_FOUND: "No process found for this status",
            CONTRACT_CONTACTS: "Process Contacts",
            CONTRACT_COMPANIES: "Parties",
            CONTRACT_TYPE: "Process Type",
        }
    },
    CONTRACTS_COMPANIES: {
        PAGE_TITLE: "Parties",
        TITLE: "Party Name/Title",
        TITLE_COMPANY: "Party Name/Title",
        TITLE_CONTACT: "Name Surname",
        DESCRIPTION: "Description",
        CREATED_DATE: "Created Date",
        ACTIONS: "Actions",
        EDIT: "Edit",
        DELETE: "Delete",
        CONTRACTS_COMPANIES: "PARTIES",
        CONTRACTS_COMPANIES_DESCRIPTION: "parties listed.",
        ADD_NEW: "Add New",
        NEW_CONTRACT_COMPANY: "New Party",
        NO_TITLE: "Please provide a title",
        NO_COMPANY: "Please refresh your page!",
        TITLE_LABEL: "Party Name/Title",
        TITLE_LABEL_COMPANY: "Party Name/Title",
        TITLE_LABEL_CONTACT: "Name Surname",
        TITLE_PLACEHOLDER: "Enter a party title...",
        TITLE_PLACEHOLDER_COMPANY: "Enter a party title...",
        TITLE_PLACEHOLDER_CONTACT: "Enter a process contact name surname...",
        DESCRIPTION_LABEL: "Description",
        DESCRIPTION_PLACEHOLDER: "Enter a party description...",
        TAGS_LABEL: "Tags",
        TAGS_PLACEHOLDER: "Enter party tags...",
        CANCEL: "Cancel",
        CREATE: "Create",
        SAVE: "Save",
        EDIT_CONTRACT_COMPANY: "Edit Company",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the party. Are you sure you want to delete?",
        TAX_ID: "Tax ID",
        TC_ID: "T.C. ID",
        TAX_OFFICE: "Tax Office",
        COMPANY_ADDRESS: "Company Address",
        ADDRESS: "Address",
        CONTACT_NAME: "Contact Name",
        CONTACT_EMAIL: "Contact Email",
        EMAIL: "E-mail",
    },
    CONTRACTS_CONTACTS: {
        PAGE_TITLE: "Process Contacts",
        FULLNAME: "Full Name",
        DESCRIPTION: "Description",
        CREATED_DATE: "Created Date",
        CREATED_BY: "Created By",
        ACTIONS: "Actions",
        CONTRACTS_CONTACTS: "RECORD CONTACTS",
        CONTRACTS_CONTACTS_DESCRIPTION: "process contacts listed.",
        ADD_NEW: "Add New",
        NEW_CONTRACT_CONTACT: "New Process Contact",
        NO_NAME: "Please provide a valid name",
        NO_SURNAME: "Please provide a valid surname",
        NO_COMPANY: "Please refresh your page!",
        NAME_LABEL: "Name",
        NAME_PLACEHOLDER: "Enter a process contact name...",
        SURNAME_LABEL: "Surname",
        SURNAME_PLACEHOLDER: "Enter a process contact surname...",
        DESCRIPTION_LABEL: "Description",
        DESCRIPTION_PLACEHOLDER: "Enter a party description...",
        DELETE: "Delete",
        TAGS_LABEL: "Tags",
        TAGS_PLACEHOLDER: "Enter party tags...",
        CANCEL: "Cancel",
        CREATE: "Create",
        SAVE: "Save",
        EDIT_CONTRACT_CONTACT: "Edit Contact",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the contact. Are you sure you want to delete?"
    },
    CONTRACTS_CUSTOM_FIELDS: {
        PAGE_TITLE: "Custom Fields",
        TITLE: "Title",
        TITLE_PLACEHOLDER: "Enter a custom field title...",
        INTERNAL_NAME: "Internal Name",
        INTERNAL_NAME_PLACEHOLDER: "Enter a custom field internal name...",
        FIELD_TYPE: "Field Type",
        REQUIRED: "Required",
        CREATED_DATE: "Created Date",
        ACTIONS: "Actions",
        CUSTOM_FIELDS: "CUSTOM FIELDS",
        CUSTOM_FIELDS_DESCRIPTION: "custom fields listed.",
        ADD_NEW: "Add New",
        CUSTOM_FIELDS_COMPONENT_NAMES: {
            TEXT: "Text",
            TEXTAREA: "Textarea",
            NUMBER: "Number",
            EMAIL: "Email",
            DATE: "Date",
            TIME: "Time",
            SINGLE_SELECTION: "Single Selection",
            PHONE: "Phone",
            CURRENCY: "Currency",
            FORMULA: "Formula",
        },
        SELECT_CUSTOM_COMPONENT_FIELD: "Select custom field type",
        THIS_IS_DEFAULT: "This is default",
        FIELD_TYPE_REQUIRED: "Field Type (Required)",
        FIELD_TYPE_FEEDBACK: "Please provide a field type",
        FIELD_REQUIREMENT: "Field Requirement",
        CREATE: "Create",
        SAVE: "Save",
        CANCEL: "Cancel",
        DELETE: "Delete",
        EDIT_CUSTOM_FIELD: "Edit Custom Field",
        WARNING: "Warning !",
        WARNING_MESSAGE: "All process types will be affected by this change.",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting it. Are you sure you want to delete?",
        OPTIONS: "Options",
        OPTION_TITLE: "Option Title",
        OPTION_VALUE: "Option Value",
        OPTION_INTERNAL_NAME: "Option Internal Name",
        ADD_OPTION: "Add Option",
        REMOVE_OPTION: "Remove Option",
        DELETE_OPTION: "Delete Option",
    },
    CONTRACTS_PIPELINES: {
        PAGE_TITLE: "Pipelines",
        PIPELINES: "Pipelines",
        NO_TITLE: "Please provide a title",
        NEW_PIPELINE: "New Pipeline",
        LOADING: "Loading...",
        STATUSES: "Statuses",
        TITLE: "Title",
        TITLE_PLACEHOLDER: "Enter a pipeline title...",
        DESCRIPTION: "Description",
        DESCRIPTION_PLACEHOLDER: "Enter a pipeline description...",
        CANCEL: "Cancel",
        CREATE: "Create",
        SAVE: "Save",
        EDIT: "Edit",
        CHECK_STATUSES: "Check Statuses",
        PIPELINE_STATUSES: "Pipeline Statuses",
        AVAILABLE_STATUSES: "Available Statuses",
        SELECT_NEW_PIPELINE: "Select New Pipeline",
        SELECT_NEW_STATUS: "Select New Status",
        MIGRATE_YOUR_CONTRACTS: "Migrate Your Process",
        EXISTING_CONTRACTS_WARNING: "process exist in this process status. You must migrate your process before deleting.",
        MIGRATE_ALL: "Migrate All",
        PIPELINE_TITLE_LABEL: "Pipeline Title",
        PIPELINE_TITLE_PLACEHOLDER: "Enter a pipeline title...",
        DRAG_AND_DROP_DESCRIPTION: "Drag and drop statuses\n to create a pipeline",
        STATUS_NOT_FOUND: "No status found in the system",
        DEFAULT: "Default",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the pipeline. Are you sure you want to delete?",
        ADD_PIPELINE: "Add Pipeline",
        KANBAN_VIEW: "Kanban View",
        BOARD_DESCRIPTION: "List the pipeline's process on board",
        EDIT_PIPELINE: "Edit Pipeline",
        DELETE_PIPELINE: "Delete Pipeline",
        ARE_YOU_SURE_STATUS: "Are you sure?",
        ARE_YOU_SURE_STATUS_MODAL_DESCRIPTION: "You are permanently deleting it. Are you sure you want to delete?",
        USER_DEFAULT: "User Default",
        SYSTEM_DEFAULT: "System Default",

    },
    CONTRACTS_STATUSES: {
        PAGE_TITLE: "Statuses",
        ADD_STATUS: "Add Status",
        NEW_STATUS: "New Status",
        EDIT_STATUS: "Edit Status",
        DELETE_STATUS: "Delete Status",
        NO_TITLE: "Please provide a title",
        TITLE_LABEL: "Title",
        TITLE_PLACEHOLDER: "Enter a status title...",
        DESCRIPTION_LABEL: "Description",
        DESCRIPTION_PLACEHOLDER: "Enter a status description...",
        CANCEL: "Cancel",
        CREATE: "Create",
        DELETE: "Delete",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the status. Are you sure you want to delete?"
    },
    CONTRACTS_TYPES: {
        STATUSES: "Statuses",
        SELECTED_STATUSES: "Selected Statuses",
        SELECTED_CUSTOM_FIELDS: "Selected Custom Fields",
        SAVED: "Saved",
        SAVING: "Saving...",
        PAGE_TITLE: "Process Types",
        CONTRACT_TYPES: "Process Types",
        NO_TITLE: "Please provide a title",
        TITLE: "Title",
        CUSTOM_FIELDS: "Custom Fields",
        CREATED_DATE: "Created Date",
        ACTIONS: "Actions",
        CONTRACT_TYPES_DESCRIPTION: "process types listed.",
        ADD_NEW: "Add New",
        NEW_CONTRACT_TYPE: "New Process Type",
        CREATE: "Create",
        EDIT_CONTRACT_TYPE: "Edit Process Type",
        SAVE: "Save",
        DEFAULT_FIELDS: {
            TITLE: "Title",
            STATUS: "Status",
            PIPELINE: "Pipeline",
            USER: "User",
            CREATED_DATE: "Created Date",
            UPDATED_DATE: "Updated Date",
            EFFECTIVE_DATE: "Effective Date",
            SIGNED_DATE: "Signed Date",
            RENEWAL_DATE: "Renewal Date",
            START_DATE: "Start Date",
            END_DATE: "End Date",
        },
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "You are permanently deleting the process type. Are you sure you want to delete?",
        ARE_YOU_SURE_CUSTOM_FIELD_MODAL_DESCRIPTION: "You are permanently deleting the custom field. Are you sure you want to delete?",
        DELETE: "Delete",
        CANCEL: "Cancel",
        TITLE_LABEL: "Enter a title",
        TITLE_PLACEHOLDER: "Enter a process type title..."
    },
    FILES: {
        PAGE_TITLE: "Documents Management",
        FILE_MANAGER: "Documents Manager",
        SEARCH_INPUT_PLACEHOLDER: "Search for Documents",
        UPLOAD_FILES: "Upload Documents",
        FILES: "Documents",
        HIDE_LONG_TAGS: "Hide Long Tags",
        SHOW: "Show",
        MORE_TAGS: "more tags exists",
        NONE: "None",
        NO_TAGS: "No Tags",
        FILENAME: "Filename",
        CREATED_DATE: "Created Date",
        UPLOADED_BY: "Uploaded By",
        CONTRACT: "Process",
        TAGS: "Tags",
        SIZE: "Size",
        TYPE: "Type",
        VERSION: "Version",
        SOURCE: "Source",
        ACTIONS: "Actions",
        EXPORT: "Export",
        SAVE_CHANGES: "Save Changes",
        CANCEL: "Cancel",
        EDIT: "Edit",
        CLOSE_EDIT_MODE: "Close Edit Mode",
        DELETE: "Delete",
        DOWNLOAD: "Download",
        SEPERATE_TAGS_WITH_COMMAS: "Separate tags with commas",
        DELETE_FILE: "Delete Document",
        DELETE_FILE_DESCRIPTION: "Are you sure about deletion of the document?",
        TOTAL_FILES: "Total Documents",
        MAX_PAGES: "Max Pages",
        NO_DATA_FOUND: "No Data Found",
        VERSIONS: "versions",
        NO_VERSIONS: "No versions",
        STATUS: "Status",
        DRAFT: "Draft",
        TEMPLATE: "Template",
        COMPLETED: "Completed",
        ALL: "All",
        APPROVE_PROCESS: "Related Workflow",
        VERSIONS_POPUP: {
            FILENAME: "Document Name",
            VERSION: "Version",
            CREATED_DATE: "Created Date",
            UPLOADED_BY: "Uploaded By",
            ACTIONS: "Actions",
            REMOVE_FROM_VERSIONS: "Remove from attachments",
            DOWNLOAD: "Download Document",
            DELETE: "Delete Documents",
            MODAL_HEADER: "Versions",
        },
        MIMETYPES: {
            'application/rtf': 'Rich Text Format(.rtf)',
            'application/vnd.oasis.opendocument.text': 'OpenDocument Format(.odt)',
            'text/html': 'Web Page(.html)',
            'application/pdf': 'PDF Document(.pdf)',
            'application/epub+zip': 'EPUB Publication(.epub)',
            'application/zip': 'Web Page(.html, zipped)',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Microsoft Word(.docx)',
            'text/plain': 'Text Plain(.txt)',
        }
    },
    TEMPLATES: {
        PAGE_TITLE: "Templates",
        SEARCH_INPUT_PLACEHOLDER: "Search for templates",
        UPLOAD_FILES: "Upload Documents",
        TEMPLATES: "Templates",
        HIDE_LONG_TAGS: "Hide Long Tags",
        SHOW: "Show",
        MORE_TAGS: "more tags exists",
        NONE: "None",
        NO_TAGS: "No Tags",
        TEMPLATE_NAME: "Template Name",
        DOCUMENT_NAME: "Document Name",
        CREATED_DATE: "Created Date",
        UPLOADED_BY: "Uploaded By",
        CONTRACT_TYPE: "Process Type",
        TAGS: "Tags",
        SIZE: "Size",
        TYPE: "Type",
        DOCUMENT_TYPE: "Document Type",
        SOURCE: "Source",
        ACTIONS: "Actions",
        SAVE_CHANGES: "Save Changes",
        CANCEL: "Cancel",
        EDIT: "Edit",
        CLOSE_EDIT_MODE: "Close Edit Mode",
        DELETE: "Delete",
        DOWNLOAD: "Download",
        SEPERATE_TAGS_WITH_COMMAS: "Separate tags with commas",
        DELETE_FILE: "Delete Template",
        DELETE_FILE_DESCRIPTION: "Are you sure about deletion of the template?",
        TOTAL_FILES: "Total Documents",
        MAX_PAGES: "Max Pages",
        NO_DATA_FOUND: "No Data Found",
        EDIT_TEMPLATE: "Edit template",
        CREATE_DOCUMENT: "Use to create document",
        CREATE_TEMPLATE: "Create a Template",
        DOCUMENT: 'Document',
        SPREADSHEET: 'Spreadsheet',
        PRESENTATION: 'Presentation',
        TEMPLATE_EDITOR: 'Template Editor',
        DOCUMENT_EDITOR: 'Document Editor',
        PROVIDE_DOCUMENT_TITLE: 'Provide a document name',
        CREATING_DOCUMENT: 'Creating Document',
        CREATING_TEMPLATE: 'Creating Template',
    },
    USERS: {
        MY_ACCOUNT: "My Account",
        DASHBOARD: "Dashboard",
        PROFILE_TITLE: "Profile",
        CONTACT_INFORMATION: "Contact Information",
        EMAIL: "Email:",
        PHONENUMBER: "Phone:",
        PROFILE: {
            GENERAL: {
                TAB_TITLE: "General",
                GENERAL: "General",
                GENERAL_DESCRIPTION: "In this section, you can edit your general information.",
                NAME_LABEL: "Name:",
                NAME_PLACEHOLDER: "Enter your name...",
                SURNAME_LABEL: "Surname:",
                SURNAME_PLACEHOLDER: "Enter your surname...",
                USERNAME_LABEL: "Username:",
                USERNAME_PLACEHOLDER: "Enter your username...",
                PHONENUMBER_LABEL: "Phone Number:",
                PHONENUMBER_PLACEHOLDER: "5XX XXX XX XX",
                SAVE_CHANGES: "Save Changes",
            },
            CREDENTIALS: {
                TAB_TITLE: "Credentials",
                CREDENTIALS: "Login / User Credentials",
                CREDENTIALS_DESCRIPTION: "In this section, you can edit your login and user information for onedocs Panel.",
                EMAIL_LABEL: "Email:",
                EMAIL_PLACEHOLDER: "Enter you email address and confirm it",
                EMAIL_FEEDBACK: "Please provide a valid email.",
                OLD_PASSWORD_LABEL: "Old Password:",
                OLD_PASSWORD_PLACEHOLDER: "Enter your old password...",
                OLD_PASSWORD_FEEDBACK: "Please be careful about your password information",
                NEW_PASSWORD_LABEL: "New Password:",
                NEW_PASSWORD_PLACEHOLDER: "Enter your new password...",
                NEW_PASSWORD_FEEDBACK: "Please be careful about your password information",
                REENTER_NEW_PASSWORD_LABEL: "Re-enter New Password:",
                REENTER_NEW_PASSWORD_PLACEHOLDER: "Re-enter your new password...",
                REENTER_NEW_PASSWORD_FEEDBACK: "Please be careful about your password information",
                SAVE_CHANGES: "Save Changes",
            },
            DELETION: {
                TAB_TITLE: "Deletion",
                DELETE_YOUR_ACCOUNT: "Delete your account",
                DELETE_YOUR_ACCOUNT_DESCRIPTION: "You can delete your account from onedocs. Your account information will be stored for 30 days after delete command. You can cancel deletion by logging in back",
                DELETE_YOUR_ACCOUNT_INFORM: "You can type 'permanent-delete' into input box to delete your account",
                DELETE_CHECK: "Delete Check:",
                DELETE_CHECK_FEEDBACK: "Please type 'permanent-delete' into input box to delete your account",
                DELETE_ACCOUNT: "Delete Account",
            }
        }
    },
    USERS_INTEGRATIONS: { //done
        TITLE: "USER INTEGRATIONS",
        DESCRIPTION: "You can manage integrations of your company",
        GDRIVE_INTEGRATION_TITLE: "Google Drive Import",
        ONEDRIVE_INTEGRATION_TITLE: "OneDrive Import",
        SHAREPOINT_INTEGRATION_TITLE: "SharePoint Import",
        GDRIVE_INTEGRATION_DESCRIPTION: "You can integrate your account with Google Drive to import documents on Google Drive",
        ONEDRIVE_INTEGRATION_DESCRIPTION: "You can integrate your account with OneDrive to import documents on OneDrive",
        SHAREPOINT_INTEGRATION_DESCRIPTION: "You can integrate your account with SharePoint to import documents on SharePoint",
        CONNECT: "Connect",
        CONNECTED: "Connected",
        REMOVE_CONNECTION: "Remove Connection",
    },
    POPUPS: {
        FILE_PERMISSON: {
            DRIVE: {
                TITLE: "Google Drive Permission",
                READ: "Read",
                WRITE: "Write",
                OWNER: "Owner",
                DOMAIN: "Domain",
                USER: "User",
                ADD_PERMISSION: "Add Permission",
                ADD_USER: "Add User",
                ADD: "Add",
                NOTIFY_USER: "Notify User",
                PEOPLE_WITH_ACCESS: "People with access",
                INVALID_EMAIL: "Please provide a valid email address",
            }
        },
        ACCEPT_OR_CLOSE: {
            ACCEPT: "Accept",
            CANCEL: "Cancel",
        },
        ADD_CONTRACT: {
            NEW_CONTRACT: "New Process",
            NEXT: "Next",
        },
        ADD_CUSTOM_FIELD: {
            NEW_CUSTOM_FIELD: "New Custom Field"
        },
        ADD_USER_TO_COMPANY: {
            INVITE_USER_TO_COMPANY: "Invite User to Workspace",
            INVITE_USER_TO_COMPANY_DESCRIPTION: "Invite your teammates and start planing your business flow.",
            NO_EMAIL_ADDRESS_ADDED: "No email address added",
            INVITE_VIA_EMAIL: "Invite via Email",
            ADD_NEW_EMAIL: "Add new email",
            PROVIDE_VALID_EMAIL: "Please provide a valid email",
            SELECT_ROLES: "Select Roles from the list...",
            FINISH: "Finish",
        },
        CDN: {
            SELECT_SOURCE: 'Select Sources to transfer documents',
            UPLOAD: 'Upload Document(s)',
            ADD_NEW: 'Add New',
            FROM_COMPUTER: 'From Computer',
            FROM_CLOUD: 'From Cloud',
            JETLEXA_ON_PREM: 'onedocs On-premises Storage',
            AWS_S3: 'Onedocs Storage',
            GOOGLE_DRIVE: 'Google Drive Storage',
            ONE_DRIVE: 'OneDrive Storage',
            SHARE_POINT: 'SharePoint Storage',
            UPLOADING_TITLE: 'Documents are uploading to source',
            UPLOADED_TITLE: "Documents are uploaded, you can choose documents' process, document's version or add tags",
            FINISH: 'Finish',
            IMPORTING: 'Documents are being downloaded from the imported source, wait till download process is completed...',
            FROM_CLOUD_TITLE: 'Select source to import documents from a cloud storage (Up to 20 documents)',
            CONTINUING_UPLOAD: 'Document are uploading to selected storages...',
            COMPLETED_UPLOAD: 'Documents are succesfully uploaded to selected storages',
            INTEGRATE_WITH_GDRIVE: 'Please contact your system admin to integrate with Google Drive',
            INTEGRATE_WITH_ONEDRIVE: 'Please contact your system admin to integrate with OneDrive',
            INTEGRATE_WITH_SHAREPOINT: 'Please contact your system admin to integrate with SharePoint',
            SELECT_SOURCE_WARNING: "You need to select a source to upload document(s)",
            SOMETHING_IS_WRONG: 'Something is wrong with our import system, try again later',
            ONEDRIVE_IMPORT_ACCOUNT_PROBLEM: 'OneDrive import account must be opened on onedrive.com',
        },
        COMPANY_ONBOARDING: {
            NO_COMPANY_FOUND: "Please create or join a Workspace",
            COMPANY_ONBOARDING: "Onboarding",
            TITLE_LABEL: "Workspace Title:",
            TITLE_PLACEHOLDER: "Enter your Workspace title...",
            TITLE_FEEDBACK: "Please provide a valid Workspace title",
            WEBSITE_LABEL: "Workspace Website:",
            WEBSITE_PLACEHOLDER: "Enter your Workspace website...",
            WEBSITE_FEEDBACK: "Please provide a valid Workspace website",
            DESCRIPTION_LABEL: "Workspace Description:",
            DESCRIPTION_PLACEHOLDER: "Enter your Workspace description...",
            DESCRIPTION_FEEDBACK: "Please provide a valid Workspace description",
            WHAT_IS_YOUR_ROLE_LABEL: "What is your role in the Workspace?",
            WHAT_IS_YOUR_ROLE_PLACEHOLDER: "Enter your role in the Workspace...",
            WHAT_IS_YOUR_ROLE_FEEDBACK: "Please provide a valid role in the Workspace",
            WHAT_TO_DO_WITH_SYSTEM_LABEL: "What to do with the system?",
            WHAT_TO_DO_WITH_SYSTEM_PLACEHOLDER: "Enter what to do with the system...",
            WHAT_TO_DO_WITH_SYSTEM_FEEDBACK: "Please provide a valid what to do with the system",
            SIZE_OF_COMPANY_LABEL: "What is the size of your Workspace?",
            SIZE_OF_COMPANY_PLACEHOLDER: "Enter the size of your Workspace...",
            SIZE_OF_COMPANY_FEEDBACK: "Please provide a valid size of your Workspace",
            USAGE_SIZE_LABEL: "How many people will use the system?",
            USAGE_SIZE_PLACEHOLDER: "Enter the number of people using the system...",
            USAGE_SIZE_FEEDBACK: "Please provide a valid number of people using the system",
            NEXT: "Next",
            PREVIOUS: "Previous",
            FINISH: "Create Workspace",
            CREATING: "Creating your Workspace...",
            JOINING: "Joining to Workspace...",
            JOIN_COMPANY: "Join an existing Workspace and start working !",
            CREATE_COMPANY: "Create a new Workspace and start onboarding !",
            JOIN: "Join to Workspace",
            INVITE_CODE_LABEL: "Invite Code",
            INVITE_CODE_PLACEHOLDER: "Enter your invite code...",
        },
        COMPANY_SWITCH: {
            COMPANY_SWITCH: "Choose Workspace To Continue With",
            CHOOSE_COMPANY_DESCRIPTION: "Choose your current Workspace to start working !",
            NO_COMPANY_FOUND: "No Workspace found",
        },
        ROLES_MANAGEMENT: {
            EDIT_ROLE: "Edit Role",
            DELETE_ROLE: "Delete Role",
            SAVE: "Save",
            FIELD: "Field",
            VALUE: "Value",
            DELETE_ROLE_DESCRIPTION: "Are you sure about deletion of the role?",
            FIELDS: {
                index: "Index",
                _id: "Unique ID",
                title: "Role Title",
                company: "Workspace",
                nominations: "Nominations",
                createdAt: "Created Date"
            },
            ACCEPT_OR_CLOSE_TITLE: "Delete Role",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Are you sure about deletion of the role?"
        },
        ROLES_NEW: {
            ADD_NEW_ROLE: "Add New Role To Your Workspace",
            TITLE_LABEL: "Role's Title",
            TITLE_PLACEHOLDER: "Enter your role's title...",
            TITLE_FEEDBACK: "Please provide a valid role's title",
            NOMINATIONS_OF_ROLE_LABEL: "Nominations for the role (Required)",
            NOMINATIONS_OF_ROLE_PLACEHOLDER: "Select nominations for the role",
            CREATE: "Create",
        },
        UPLOAD_FILES: {
            UPLOAD_STATUS: "Upload Status",
            FILENAME: "Filename",
            CONTRACT: "Process",
            VERSION_OF: "Version of",
            TAGS: "Tags",
            FILE_SIZE: "File Size",
            LOADING: "Loading...",
            TAGS_PLACEHOLDER: "Enter tags separated by comma",
            SEPERATE_TAGS_WITH_COMMAS: "Separate tags with commas",
            COMPANY_NOT_INTEGRATED_WITH_DRIVE: "Workspace is not integrated with Google Drive",
            COMPANY_NOT_INTEGRATED_WITH_DRIVE_DESCRIPTION: "Please contact your Workspace administrator to integrate Google Drive.",
            UPLOAD_FILES_MAX_20: "Upload your documents (Maximum 20 documents)",
            ERROR: "Error",
            UPLOAD: "Upload",
            FINISH: "Finish",
            UPLOAD_WINDOW_GETTING_READY_FOR_YOU: "Upload window is getting ready for you...",
            DROP_FILES_OR_CLICK: "Drop documents here or click to upload.",
            DROP_FILES_OR_CLICK_DESCRIPTION: "You can upload up to 20 documents in this screen by dragging or clicking",
            FILES_ARE_BEING_DOWNLOADED_FROM_DRIVE: "Documents are being downloaded from your Google Drive and starting a upload procedure.",
            INTEGRATION_COMPLETED_GOOGLE: "Integration complete. Now upload from Google Drive.",
            INTEGRATION_COMPLETED_ONEDRIVE: "Integration complete. Now upload from OneDrive.",
            INTEGRATION_COMPLETED_SHAREPOINT: "Integration complete. Now upload from SharePoint.",
            ADD_FILES_FROM_GOOGLE_DRIVE: "Add documents from Google Drive",
            DRAFT: "Draft",
            COMPLETED: "Completed",
            TEMPLATE: "Template",
            STATUS: "Status",
            IN_REVIEW: "In Review",
            APPROVED: "Approved",
            REJECTED: "Rejected",
        },
        USERS_INVITES_MANAGEMENT: {
            UPDATE_CURRENT_USER_INVITE: "Update Current User Invite",
            DELETE_INVITE: "Delete Invite",
            FIELD: "Field",
            VALUE: "Value",
            SAVE: "Save",
            FIELDS: {
                index: "Index",
                _id: "Unique ID",
                email: "Email",
                createdAt: "Created Date",
                inviteCode: "Invite Code",
                status: "Status",
            },
            ACCEPT_OR_CLOSE_TITLE: "Delete Invite",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Are you sure about deletion of the invite?",
        },
        USERS_MANAGEMENT: {
            EDIT_COMPANY_USER: "Edit Workspace User",
            REMOVE_USER: "Remove User",
            SAVE: "Save",
            FIELD: "Field",
            VALUE: "Value",
            SELECT_ROLES_PLACEHOLDER: "Select roles for the user",
            FIELDS: {
                index: "Index",
                _id: "Unique ID",
                name: "Name",
                surname: "Surname",
                email: "Email",
                phoneNumber: "Phone Number",
                createdAt: "Created Date",
                username: "Username",
                role: "Role"
            },
            DEFAULT_FILE_PERMISSIONS: {
                READ: "Read",
                EDIT: "Edit",
                NOT_ALLOWED: "Not Allowed",
                OWNER: "Owner"
            },
            ACCEPT_OR_CLOSE_TITLE: "Remove User From Workspace",
            ACCEPT_OR_CLOSE_DESCRIPTION: "Are you sure about removal of the user from the Workspace?",
        }
    },
    SELECTS: {
        CONTRACTS: {
            SEARCH_CONTRACTS: "Select Process",
            SEARCH_COMPANY: "Select Parties",
            CONTRACT_CHANGE_SUCCESS: "Process change is successful",
        },
        FILES: {
            SEARCH_FILES: "Select Documents",
        }
    },
    UNAUTHORIZED_PAGES: {
        UNAUTH_LAYOUT: {
            DESCRIPTION: "It is the easiest, fastest and safest platform for CLM",
            AUTHOR: "onedocs TEAM"
        },
        LOGIN: {
            WELCOME_BACK: "Welcome Back!",
            LOGIN_DESCRIPTION: "Enter your email address and password to access onedocs",
            DONT_HAVE_ACCOUNT: "Don't have an account?",
            SIGN_UP: "Register",
            EMAIL_FEEDBACK: "Please provide a valid email",
            PASSWORD_FEEDBACK: "Please provide a valid password",
            PASSWORD_OR_EMAIL_INCORRECT: "Your password or email is incorrect.",
            PLEASE_LOGIN: "Please login or",
            REGISTER: "register",
            TO_CONTINUE_WITH: "to continue with your invite",
            LOGIN_SUCCESSFUL: "Logged in successfully, redirecting...",
            EMAIL_LABEL: "Email Address",
            EMAIL_PLACEHOLDER: "Enter your email address...",
            PASSWORD_LABEL: "Password",
            PASSWORD_PLACEHOLDER: "Enter your password...",
            LOGIN: "Login",
            REMEMBER_ME: "Remember me",
            FORGOT_PASSWORD: "Forgot your password?",
        },
        LOGOUT: {
            SEE_YOU_AGAIN: "Your logout is successful !",
            DESCRIPTION: "You successfully logged out, you can login at any time visiting onedocs",
            BACK_TO: "Back to",
            LOG_IN: "Login",
        },
        REGISTER: {
            CREATE_ACCOUNT: "Create Your Account",
            CREATE_ACCOUNT_DESCRIPTION: "Create your account and start your onboarding",
            NAME_LABEL: "First Name:",
            NAME_PLACEHOLDER: "Enter your first name...",
            NAME_FEEDBACK: "Please provide a valid first name",
            SURNAME_LABEL: "Last Name:",
            SURNAME_PLACEHOLDER: "Enter your last name...",
            SURNAME_FEEDBACK: "Please provide a valid last name",
            PHONENUMBER_LABEL: "Phone Number:",
            PHONENUMBER_PLACEHOLDER: "54X XXX XX XX",
            PHONENUMBER_FEEDBACK: "Please provide a valid phone number",
            EMAIL_LABEL: "Email Address:",
            EMAIL_PLACEHOLDER: "Enter your email address...",
            EMAIL_FEEDBACK: "Please provide a valid email",
            PASSWORD_LABEL: "Password:",
            PASSWORD_PLACEHOLDER: "Enter your password...",
            PASSWORD_FEEDBACK: "Please provide a valid password",
            PASSWORD_MINIMUM_LENGTH_FEEDBACK: "Your password must be longer than 5 characters.",
            PASSWORD_MAXIMUM_LENGTH_FEEDBACK: "Your password must not be longer than 25 characters.",
            PASSWORD_MINIMUM_CHARACTER_FEEDBACK: "Your password must contain at least 6 characters.",
            PASSWORD_MINUMUM_ONE_UPPERCASE_ONE_LOWER_CASE_FEEDBACK: "Your password must contain one uppercase, one lowercase",
            PASSWORD_SPECIAL_CHARACTER_FEEDBACK: "Your password must contain at least one special characters",
            PASSWORD_NUMBER_FEEDBACK: "Your password must contain at least one number",
            ACCEPT_TERMS_AND_SERVICES_LABEL: "I accept the terms and services",
            ACCEPT_TERMS_AND_SERVICES_FEEDBACK: "Please accept the terms and services",
            REGISTER: "Register",
            SHOW_PASSWORD: "Show Password",
            HIDE_PASSWORD: "Hide Password",
            ALREADY_HAVE_ACCOUNT: "Already have an account?",
            LOGIN: "Login",
            ALERT_REGISTRATION_COMPLETE: "Registrations is successful, you will be redirected to next page",
            ALERT_INVITE_CODE: "Please register to continue with your invite",
            ATTEMPTED: "Attempted:",
            REGISTER_CODE: "Register Code",
        },
        CONFIRM: {
            TITLE: "Confirm your Email address",
            DESCRIPTION: "Your account has been successfully registered. To complete the verification process, please check your email for a validation request.",
            BACK_TO: "Back to",
            LOGIN: "Login"
        },
        CONFIRM_SUCCESSFUL: {
            GO_TO: "Go to",
            MAIL_CONFIRMATION_SUCCESS: "Mail Confirmation Successful!",
            DESCRIPTION: "Your mail confirmed is successful, you can go to onedocs"
        },
        CONFIRM_FAILED: {
            GO_TO: "Go to",
            LOGIN: "Login",
            MAIL_CONFIRMATION_FAILED: "Mail Confirmation Failed!",
            DESCRIPTION: "Your mail confirmation is failed, you can check your mail box for the correct url or contact the support via button under the page"
        },
        FORGET_PASSWORD: {
            TITLE: "Forget Password",
            DESCRIPTION: "Enter your email address and we'll send you an email with instructions to reset your password.",
            GO_TO: "Go to",
            LOGIN: "Login",
            EMAIL_LABEL: "Email Address",
            EMAIL_PLACEHOLDER: "Enter your email address...",
            EMAIL_FEEDBACK: "Please provide a valid email",
            ALERT_SUCCESSFULLY_SENT: "Email is successfully sent to email address. Please click the link to proceed to next step",
            SEND: "Send Email",
        },
        RESET_PASSWORD: {
            CHANGE_PASSWORD: "Change Password",
            CHANGE_PASSWORD_DESCRIPTION: "By filling the form below, you can change your current password and log in to the onedocs",
            BACK_TO: "Back to",
            LOGIN: "Login",
            PASSWORDS_DOESNT_MATCH: "Password doesn't match",
            PASSWORD_LABEL: "Password:",
            CHECK_PASSWORD_LABEL: "Re-enter Password:",
            PASSWORD_PLACEHOLDER: "Enter your password...",
            CHECK_PASSWORD_PLACEHOLDER: "Enter your password again...",
            PASSWORD_FEEDBACK: "Please provide a valid password",
            PASSWORD_MINIMUM_LENGTH_FEEDBACK: "Your password must be longer than 5 characters.",
            PASSWORD_MAXIMUM_LENGTH_FEEDBACK: "Your password must not be longer than 25 characters.",
            PASSWORD_MINIMUM_CHARACTER_FEEDBACK: "Your password must contain at least 6 characters.",
            PASSWORD_MINUMUM_ONE_UPPERCASE_ONE_LOWER_CASE_FEEDBACK: "Your password must contain one uppercase, one lowercase",
            PASSWORD_SPECIAL_CHARACTER_FEEDBACK: "Your password must contain at least one special characters",
            PASSWORD_NUMBER_FEEDBACK: "Your password must contain at least one number",
            ALERT_CHANGE_PASSWORD: "You can change your password by filling form below",
            SHOW_PASSWORD: "Show Password",
            HIDE_PASSWORD: "Hide Password"
        },
        LOCK_SCREEN: {
            HI: "Hi, ",
            DESCRIPTION: "Re-enter your password to access onedocs",
            PASSWORD_LABEL: "Password:",
            PASSWORD_PLACEHOLDER: "Enter your password...",
            PASSWORD_FEEDBACK: "Please provide a valid password",
            LOGIN: "Login",
            NOT_YOU: "Not you? Go to",
            ALERT_LOGIN_SUCCESSFUL: "Logged in successfully, redirecting to onedocs...",
            ATTEMPTED: "Attempted:",
            MESSAGE: "Your password or email is incorrect."
        }
    },
    MAIL_INBOX: {
        TITLE: "Inbox",
        NEW_CONNECTION: "Connect",
        DISCONNECT: "Disconnect",
        ALL: "All",
        IMPORTED: "Imported",
        MAIL_TEXT: "Mail Text",
        IMPORT: "Import",
        SELECT_EMAIL: "Select an email",
        IMPORT_SELECTED_FILES: "Import Selected Documents",
        CONNECT_TO_EMAIL: "Connect to Email",
        ALL_DATA_LOADED: "All data loaded",
        ARE_YOU_SURE: "Are you sure?",
        ARE_YOU_SURE_MODAL_DESCRIPTION: "Are you sure you want to disconnect this email?",
        UPLOADING_FILES: "Uploading documents",
        UPLOADING_FILES_DESCRIPTION: "Uploading selected documents please wait...",
        CANCEL: "Cancel",
        DELETE: "Delete",
        FILES_UPLOADED_SUCCESSFULLY: "Documents uploaded successfully",
        MAXIMUM_20_FILES: "You can upload maximum 20 documents",
    },
    CUSTOMFORMS: {
        CUSTOMFORMS_EDIT_POPUP: {
            FORM_STATUS_ACTIVE: 'Active',
            FORM_STATUS_PASSIVE: 'Passive',
            FORM: 'Form',
            TEMPLATES: 'Templates',
            SETTINGS: 'Settings',
            SHARE_FORM: 'Share Form',
            SAVE: 'Save',
            FORM_ELEMENTS: 'Form Elements',
            HEADER: 'Header',
            DIVIDER: 'Divider (Form Line)',
            FIELD_SETTINGS: 'Field Settings',
            FIELD: 'Field',
            TITLE: 'Title',
            LABEL: 'Label',
            DESCRIPTION: 'Description',
            HELP_TEXT: 'Help Text',
            REQUIRE: 'Required field',
            ENTER_DESCRIPTION: 'Enter a description...',
            DEFAULT_VALUE: 'Default Value',
            AVAILABLE_TEMPLATES: 'Available Templates',
            SELECTED_TAMPLATES: 'Selected Templates',
            FORM_STATUS: 'Form Status',
            SHARE: 'Share',
            COPY_LINK: 'Copy Link',
            LINK_COPIED: 'Link Copied',
            CODE_COPIED: 'Code Copied',
            PREVIEW: 'Preview',
            SEARCH_TEMPLATE_PLACEHOLDER: 'Search in available templates...',
            CUSTOM_FORMS_TITLE: 'Custom Forms',
            CUSTOM_FORMS_DESCRIPTION: 'Create a custom form, associate with templates. Automatically generate your documents...',
            CREATE_FORM: 'Create Form',
            FORM_PASSIVE_MESSAGE: 'This form is not currently available.',
            SUBMIT: 'Submit',
            RESET: 'Reset',
            ARE_YOU_SURE_DESCRIPTION: 'You are permanently deleting the form. Are you sure you want to delete?',
            OPEN_FORM: 'Open Form',
            PROVIDE_FORM_TITLE: 'Please provide a form title',
            CREATING_DOCUMENT: 'Creating Document',
            INVALID_EMAIL: 'Please provide a valid email address',
            SEND_EMAIL: 'Send Email',
            RECEIVERS: 'Receivers',
            MESSAGE_TEXT: 'Message Text',
            INVALID_MESSAGE: 'Please provide a message',
            CONFIRM_AND_SEND: 'Send',
            CUSTOM_FIELDS: 'Custom Fields',
            NEW_CUSTOM_FIELDS: 'New Custom Field',
            SELECTED_CUSTOM_FIELD: 'Selected Custom Field',
        }
    },
    MONTHS: {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December'
    },
    DASHBOARD: {
        DATE_TYPES: {
            startDate: 'Start Date',
            endDate: 'End Date',
            effectiveDate: 'Effective Date',
            renewalDate: 'Renewal Date',
            reminderDate: 'Event Date',
        },
        CHARTS: {
            NUMBER_OF_CONTRACTS_ADDED: 'Number of Process Added',
        }
    },
    APPROVE_TEMPLATES: {
        NAME: 'Workflow Templates',
        TITLE: 'Workflow Management',
        TEMPLATE_NAME: 'Template Name',
        REACH_ERROR: 'An error occurred while reaching the server.',
        TABLE: {
            NAME: 'Saved Workflows',
            TITLE: 'Title',
            CREATED_AT: 'Created At',
            APPROVERS: 'Recipients',
            STEPS: 'Steps',
            ACTIONS: 'Actions',
            EDIT: 'Edit',
            DELETE: 'Delete',
            CREATE: 'Create',
        },
        CREATE_MODAL: {
            TITLE: 'Create Saved Workflow',
            NAME: 'Title',
            NAME_PLACEHOLDER: 'Enter a title...',
            NAME_ERROR: 'Title is required',
            CREATE_BUTTON: 'Create',
            CREATING_BUTTON: 'Creating...',
        },
        DELETE_MODAL: {
            TITLE: 'Delete Saved Workflow',
            DESCRIPTION: 'Are you sure you want to delete this saved workflow?',
            DELETE_BUTTON: 'Delete',
            CANCEL_BUTTON: 'Cancel',
        },
        APPROVE_TEMPLATES_GENERIC_MODAL: {
            TITLE: 'Edit Saved Workflow',
            NAME: 'Workflow Template',
            STEP_NOT_FOUND: 'Step not found',
            EMAIL_VALIDATION_ERROR: 'Please provide a valid email address',
            SAVING: 'Saving...',
            SAVE: 'Save',
            SAVED: 'Saved',
            STEP: 'Step',
            DELETE: 'Delete',
            CAN_CHANGE_REQUEST: "Can change request",
            CAN_ASSIGN: "Can assign another user",
            APPROVE_FROM: 'Approval is required from',
            APPROVE_FROM_ALL: "Everyone",
            APPROVE_FROM_ONE: "Anyone",
            ADD_STEP: 'Add Step',
            RECIPIENT_EMAIL: 'Enter a recipient email...',
        },
    },
    CUSTOM_FIELDS: {
        TOUSHANDS_SEPARATOR: 'Thousands separator is dot (.) and decimal is comma (,).'
    },
    APPROVE_PROCESS: {
        PAGE_TITLE: 'Workflow Management',
        TABLE: {
            TITLE: 'Workflow Title',
            STATUS: 'Status',
            VERSIONS: 'Versions',
            LAST_ACTION_DATE: 'Last Action',
            LAST_ACTION_USER: 'Last Action User',
            NOTES_COMMENT: 'Notes',
            TIME_TRACKING: 'Time Tracking',
            TIME_TRACKING_APPROVED: 'Took {{time}}',
            TIME_TRACKING_PENDING: 'Started {{time}} ago',
            SHOW: 'Show',
            DELETE: 'Delete',
            NAV: {
                APPROVE_PROCESS: 'All Workflows',
                APPROVAL_FLOW_TEMPLATES: 'Saved Workflows',
            }
        },
        APPROVE_PROCESS_POPUP: {
            TITLE: 'Workflow Process',
            APPROVE: 'Workflow',
            ACCEPT: 'Kabul Et',
            REJECT: 'Reject',
            REJECT_AND_COMPLATE: 'Reject and Complete',
            REJECT_AND_SUGGEST_CHANGES: 'Reject and Suggest Changes',
            ASSIGN_TO_SOMEONE_ELSE: 'Assign to Someone Else',
            START_APPROVE_PROCESS: 'Start Workflow',
            APPROVED: 'Approved',
            REJECTED: 'Rejected',
            PENDING: 'Pending',
            CREATED: 'Created',
            CANCELED: 'Canceled',
            REASSIGNED: 'Reassigned',
            CHANGE_REQUESTED: 'Change Requested',
            NOT_STARTED: 'Decision Awaited',
            STEP: 'Step',
            FILE: 'File',
            START_APPROVE_PROCESS_AGAIN: 'Start Workflow Again',
            VERSIONS: 'Versions',
            COMPARE: 'Compare',
            APPROVE_MODAL: {
                TITLE: 'Approve',
                DESCRIPTION: 'You are now ready to workflow this document. Press the "Submit" button to complete the confirmation step.',
                SEND: 'Submit',
                MESSAGE_INPUT_LABEL: 'Message',
                MESSAGE_INPUT_PLACEHOLDER: 'You can add some text to this field before confirming. This text will be forwarded to the relevant recipient at the confirmation step.',
            },
            REJECT_COMPLATE_MODAL: {
                TITLE: 'Reject and Terminate',
                DESCRIPTION: 'Şu anda bu belgeyi reddetmek üzeresiniz. Ret adımını tamamlamak için “Gönder” butonuna basınız.',
                SEND: 'Send',
                MESSAGE_INPUT_LABEL: 'Message (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'Bu alana örn. reddetme nedeninizi içeren bir metin ekleyebilirsiniz. Bu metin onay adımındaki ilgili alıcıya iletilecektir.',
            },
            REASSIGN_MODAL: {
                TITLE: 'Assign to Someone Else',
                DESCRIPTION: 'You are now about to assign this document to someone else. In the box below, enter the person you want to assign the document to and click the "Send" button.',
                SEND: 'Send',
                RECEIVER_INPUT_LABEL: 'Receiver Email Address',
                RECEIVER_INPUT_PLACEHOLDER: 'Enter a receiver email address...',
                MESSAGE_INPUT_LABEL: 'Message (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'You can add some text to this field before assigning. This text will be forwarded to the relevant recipient at the assignment step.',
            },
            REJECT_CHANGE_MODAL: {
                TITLE: 'Reject and Suggest Changes',
                DESCRIPTION: 'You can download the document you want to request changes from below and revise it so that "Track Changes" is on.',
                SEND: 'Send',
                MESSAGE_INPUT_LABEL: 'Message (*)',
                MESSAGE_INPUT_PLACEHOLDER: 'You can add some text to this field before confirming. This text will be forwarded to the relevant recipient at the confirmation step.',
                FILE_INPUT_LABEL: 'Document Upload',
                FILE_INPUT_PLACEHOLDER: 'You can drag the new document you have changed here or select it by pressing the “Upload” button.',
                SELECT_FILE: 'Select Documents',
            },
            USER_STATUSES: {
                APPROVED: 'Approved',
                REJECTED: 'Rejected',
                PENDING: 'Pending',
                CREATED: 'Created',
                CANCELED: 'Canceled',
                REASSIGNED: 'Reassigned',
                CHANGE_REQUESTED: 'Change Requested',
                QUEUE: 'Queue',
                NOT_NECESSARY: 'Canceled',
            },
            FILE_MESSAGES: {
                FROM_ANYONE_MESSAGE: 'Approval from anyone',
                FROM_EVERYONE_MESSAGE: 'Approval from everyone',
                FILE_UPLOADED: 'New document uploaded.',
                NO_START_FILE_REJECT: '{{ownerName}}, rejected the new document and suggested changes.',
                NO_START_FILE_REJECT_COMPLATE: '{{ownerName}}, rejected the new document, thereby terminating the approval process.',
                NO_START_FILE_PROCESS: '{{ownerName}}, expected to initiate approval for the new document.',
                CONTINUE_FROM_SCRATCH: '{{ownerName}} restarted the approval process for the new document on {{startDate}}.',
                CONTINUE_FLOW: '{{ownerName}} continued the approval flow for the new documents on {{startDate}}',
                CONTINUE_NEW_FLOW_FIRST: '{{ownerName}} started the approval flow on {{startDate}}.',
                CONTINUE_NEW_FLOW: '{{ownerName}} initiated a new approval process for the new document on {{startDate}}.',
                RESEND_MAIL_SUCCESS: 'Mail has been sent successfully.',
            },
            USER_MESSAGES: {
                SUGGECTED_CHANGE_MESSAGE: 'The approval flow ended for this document by "Suggest Changes" option. It is not required of this user\'s decision.',
                REJECTED_MESSAGE: 'This version\'s approval process has been terminated by rejection, and there was no need for the approval person\'s selection.',
                APPROVE_ANYONE_MESSAGE: 'Since approval from anyone in the step is required, there was no need for the selection of this approval person.',
            }
        },
        APPROVE_PROCESS_DELETE_MODAL: {
            TITLE: 'Delete Workflow Process',
            DESCRIPTION: 'Are you sure you want to delete this workflow process?',
            DELETE_BUTTON: 'Delete',
            CANCEL_BUTTON: 'Cancel'
        }
    },
    APPROVE_ACTIVITY: {
        MODAL_TITLE: 'Notes/Comments',
        MODAL_CLOSE: 'Close',
        APPROVE: {
            CREATED: 'Workflow process created.',
            APPROVED: 'Workflow process approved.',
            PENDING: 'Workflow process pending.',

        },
        APPROVE_STEP: {
            CREATED: 'A step created.',
            APPROVED: 'A step approved.',
            PENDING: 'A step pending.',
        },
        APPROVE_STEP_USER: {
            CREATED: 'An approve user added.',
            APPROVED: 'An approve user approved.',
            PENDING: 'An approve user pending.',
            REASSIGNED: 'An approve user reassigned.',
        },
        APPROVE_FILE: {
            CREATED: 'A document created.',
            APPROVED: 'A document approved.',
            PENDING: 'A document pending.',
        }
    },
    APPROVE_PROCESS_MODAL: {
        CREATE_MODAL: {
            TITLE: 'Create Workflow Process',
            NAME_LABEL: 'Workflow Process Name',
            SELECT_APPROVE_TEMPLATE: 'Select Workflow Template',
            SAVE_AS_TEMPLATE: 'Save as Template',
            SAVE: 'Save',
            SEND_APPROVE: 'Start Workflow Process',
            USER_NOTE_PLACEHOLDER: 'You can leave a message here for users in the Workflow. (optional)'
        },
    },
    APPROVE_BUTTON_COMPONENT: {
        SEND: 'Send to Workflow',
        TITLE: 'Create Workflow Process',
        NAME_LABEL: 'Workflow Process Name',
        SAVE: 'Save',
        CANCEL: 'Cancel',
        YES: 'Yes',
    },
    APPROVAL_PROCESS_FILE_MODAL: {
        TITLE: 'Create Workflow',
        SEND_APPROVE: 'Send',
        LOADING: 'Creating Workflow...',
        STEP: 'Step',
        DELETE: 'Remove',
        DELETE_ALL: 'Remove All',
        CAN_CHANGE_REQUEST: 'Can Revise',
        CAN_ASSIGN: 'Can Assign',
        RECIPIENT_EMAIL: 'Recipient email',
        APPROVE_FROM: 'Workflow is required from',
        APPROVE_FROM_ALL: 'Everyone',
        APPROVE_FROM_ONE: 'Anyone',
        ADD_STEP: 'Add Step',
        SAVE: 'Save',
        SEND: 'Send',
        SAVE_AS_TEMPLATE: 'Save as Template',
        TEMPLATE_NAME: 'Template Title',
        TEMPLATE_NAME_PLACEHOLDER: 'Enter a template name...',
        EDIT_TEMPLATE: 'Edit Template',
        EDIT_TEMPLATE_MESSAGE: 'This Workflow template will be updated according to the change you make. Are you sure you want to continue this process?',
        SELECT_APPROVAL_FLOW: 'Select an Workflow',
        SAVE_AS_DRAFT: 'Save as Draft',
        SAVE_AS_DRAFT_MESSAGE: 'Do you want to save the Workflow as a draft?',
        BACK: 'Back',
        CANCEL: 'Cancel',
        RECIPIENTS: 'Recipients',
        SELECT_FROM_TEMPLATE: 'Select From Saved Flows',
        SETTINGS_INFORMATION: 'You can adjust the permissions before sending the document to the flow.',
        ADD_STEP_INFO: "You can manage the order and permissions of recipients in the flow by adding steps. Thus, it does not move to the next step until the review of the recipients in one step is completed.",
        MESSAGE: 'Message',
        SAVE_FLOW: 'Save Flow',
        NEW_FLOW: 'Create a New Flow',
        NEED_FILE: 'Required Document',
    },
    CREATE_PROCESS_MODAL: {
        TITLE: 'Create a new process',
        DESCRIPTION: 'How would you like to start?',
        FROM_SCRATCH: 'From scratch',
        FROM_SCRACTH_DESCRIPTION: 'If your documents are not ready yet but your data is.',
        FOR_READY_DOCUMENTS: 'For ready documents',
        FOR_READY_DOCUMENTS_DESCRIPTION: 'Firstly select your documents and then enter the data.',
        FOR_CONTRACT_REQUEST: ' For contract request',
        FOR_CONTRACT_REQUEST_DESCRIPTION: 'Enter the data and request a document.',
        START_WITH_TEMPLATE: 'Start with template',
        START_WITH_TEMPLATE_DESCRIPTION: 'Select a template and create a process.',
    },
    NEW_PROCESS_MODAL: {
        DATES: 'Dates',
        PARTIES: 'Parties',
        DESCRIPTION_PLACEHOLDER: 'Enter a process description',
        CUSTOM_FIELDS: 'Custom Fields',
        DOCUMENTS: 'Documents',
        UPLOAD_DOCUMENT: 'Upload Document',
        SELECT_DOCUMENT: 'Select Document',
        ADD: 'Add',
        ASSIGN_USERS: 'Assign Users',
        RELATED_WITH: 'Related With',
        TAGS: 'Tags',
        MAKE_PRIVATE: 'Make Private',
        CREATE_PROCESS: 'Create Process',
        NEXT: 'Next',
        SELECT_FROM_UPLOADED_DOCUMENTS: 'Select from uploaded documents',
        CREATE_RELATIONSHIP: 'Create Relationship',
        SELECT: 'Select',
        ARE_YOU_SURE_TITLE: 'Are you sure?',
        ARE_SURE_TO_EXIT: 'Do you want to save the changes you made to the key terms?',
        CHANGES_WILL_BE_LOST: 'You changed key terms. Do you want to save these changes?',
        SAVE: 'Save',
        CANCEl: 'Cancel',
        EDIT_KEY_TERMS_SUCCESS: 'Changes were successfully saved.',
        FILES: {
            TITLE: 'Title',
            STATUS: 'Status',
            VERSION: 'Version',
            UPLOADED_DATE: 'Uploaded',
            UPLOADED_BY: 'By',
        },
        KEY_TERMS: 'Check List',
        ACTIVITY_FEED: 'Activity Feed',
        SEND_APPROVE: 'Send to Approval',
        SELECT_TEMPLATE: 'Select Template to Create Process'
    },
    USER_DASHBOARD: {
        WHAT_WOULD_YOU_LIKE_TO_DO_TODAY: 'What would you like to do today?',
        PROCESS_ASSIGNED_TO_ME: 'Process Assigned to Me',
        PROCESS_CREATED_BY_ME: 'Process Created by Me',
        EVENTS_ASSIGNED_TO_ME: 'Events Assigned to Me',
        NO_DATA_RIGHT_NOW: 'No data right now',
        RECENT: 'Recent',
        LAST_30_DAYS: 'Last 30 Days',
        ALL: 'All',
        LAST_ACTIVITIES: 'Last Activities',
        DUE_DATES_FOR_NEXT_7_DAYS: 'Due Dates for Next 7 Days',
    },
    PROCESS_APPROVAL: {
        NAV_BAR: {
            OVERVIEW: 'Overview',
            APPROVAL_FLOWS: 'Approval Flows',
        },
        STATUSES: {
            PENDING: 'In Progress',
            CREATED: 'Not Started',
            APPROVED: 'Approved',
            UPLOADED: 'Uploaded',
            ASSIGNED: 'Assigned',
        },
        APPROVAL_CONTENT: 'Approval Content',
        NEXT: 'Next',
        PROCESS_NAME: 'Process Name',
        KEY_TERMS: 'Key Terms',
        FILES: 'Files',
        RECIPIENTS: 'Recipients',
        MESSAGE: 'Message',
        BACK: 'Back',
        SEND: 'Send',
        YOU_CAN_EDIT_RECIPIENTS_BEFORE_STARTING_APPROVAL_FLOW: 'You can edit recipient authorizations before starting the approval flow',
        KEYTERMS_DATA: {
            TITLE: "Title",
            DESCRIPTION: "Description",
            START_DATE: "Start Date",
            END_DATE: "End Date",
            RENEWAL_DATE: "Renewal Date",
            EFFECTIVE_DATE: "Effective Date",
            SIGNED_DATE: "Signed Date",
            COMPANIES: "Parties",
            COMPANIES_INTERNAL: "Company (Internal) Party",
            COMPANIES_EXTERNAL: "Counter Party",
            CONTACTS: "Contacts",
            ASSIGNED_USERS: "Assigned Users",
            PIPELINE: "Pipeline",
            STATUS: "Status",
            TYPE: "Type",
            CREATED_AT: "Created At",
            PROCESS_TYPE: "Process Type",
        },
        DETAIL: {
            STEP: 'Step',
            DELETED_STEP: 'This step is deleted.'
        },
        EXTERNAL: {
            APPROVE_PROCESS_CHECKING: 'Checking approval flow...',
            PLEASE_WAIT: 'Please wait...',
            SENDER: 'Sender',
            SEND_DATE: 'Send Date',
            MESSAGE: 'Message',
            KEY_TERMS: 'Key Terms',
            APPROVE_ALL: 'Approve All',
            UPLOAD_VERSION: 'Upload Version',
            ASSIGN_TO_SOMEONE_ELSE: 'Assign to Someone Else',
            APPROVE_MODAL: {
                MESSAGE: 'You are about to approve this document. To complete the approval step, click the "Send" button.',
                REQUESTED_DOCUMENTS: 'Requested Documents*',
                SELECT_DOCUMENT: 'Select Document',
                REQUESTED_DOCUMENTS_DESCRIPTION: 'The requested documents are listed below. You can complete the process by uploading these documents.',
                YOUR_MESSAGE: 'Your Message',
                ACCEPT: 'Accept',
                CANCEL: 'Cancel',

            },
            NEW_VERSION_MODAL: {
                TITLE: 'Upload New Version',
                MESSAGE: 'You can upload a new version for each document.',
                REQUESTED_DOCUMENTS: 'Requested Documents*',
                REQUESTED_DOCUMENTS_DESCRIPTION: 'The requested documents are listed below. You can complete the process by uploading these documents.',
                SELECT_DOCUMENT: 'Select Document',
                YOUR_MESSAGE: 'Your Message',
                ACCEPT: 'Accept',
                CANCEL: 'Cancel',
                UPLOAD_NEW_VERSION: 'Upload New Version',

            },
            ASSIGN_TO_SOMEONE_ELSE_MODAL: {
                TITLE: 'Assign to Someone Else',
                DESCRIPTION: 'Enter the email address of the person you want to assign in the field below. The assignment process cannot be undone.',
                ASSIGNEE_EMAIL: 'New recipient email address (*)',
                YOUR_MESSAGE: 'Your Message (*)',
                ACCEPT: 'Accept',
                CANCEL: 'Cancel',

            },
            REGISTER_GUEST_MODAL: {
                DESCRIPTION: 'To continue your transaction, you must fill in the fields below and click the "Save" button. Registration cannot be undone.',
                NAME: 'Name (*)',
                SURNAME: 'Surname (*)',
                ALLOW_ELECTRONIC_MESSAGE: 'I allow my personal data to be processed for the purpose of sending commercial electronic messages related to onedocs product promotions, campaigns and advertisements and sending commercial electronic messages to me via e-mail.',
                SAVE: 'Save',
            },
            ERROR_MESSAGES: {
                PROCESS_APPROVE_TOKEN_INVALID: {
                    TITLE: 'Approval Flow Invalid',
                    DESCRIPTION: 'Approval flow is invalid or expired.'
                },
                USER_NOT_FOUND: {
                    TITLE: 'User Not Found',
                    DESCRIPTION: 'User not found.'
                },
                PROCESS_APPROVAL_NOT_FOUND: {
                    TITLE: 'Approval Flow Not Found',
                    DESCRIPTION: 'Approval flow not found.'
                },
                PROCESS_APPROVAL_NOT_PENDING: {
                    TITLE: 'Approval Flow Pending',
                    DESCRIPTION: 'Approval flow pending.'
                },
                PROCESS_APPROVAL_STEP_NOT_FOUND: {
                    TITLE: 'Approval Step Not Found',
                    DESCRIPTION: 'Approval step not found.'
                },
                PROCESS_APPROVAL_STEP_NOT_PENDING: {
                    TITLE: 'Approval Step Pending',
                    DESCRIPTION: 'Approval step pending.'
                },
                PROCESS_APPROVAL_STEP_STEPUSER_NOT_PENDING: {
                    TITLE: 'Approval Step Pending',
                    DESCRIPTION: 'Approval step pending.'
                },
                PROCESS_APPROVED: {
                    TITLE: 'Approval Flow Approved',
                    DESCRIPTION: 'Approval flow approved.'
                },
                PROCESS_REASSIGNED: {
                    TITLE: 'Approval Flow Reassigned',
                    DESCRIPTION: 'Approval flow reassigned.'
                },

            }

        }
    },
    AUTOMATION: {
        AUTOMATION: 'Automation',
        AUTOMATIONS: 'Automations',
        ADD_AUTOMATION: 'Add Automation',
        WHEN_TITLE: 'When',
        THEN_TITLE: 'Then',
        ACTIVE: 'Active',
        INACTIVE: 'Inactive',
        WHEN: {
            processCreated: 'Process Created',
        },
        THEN: {
            doAction: 'Do Action',
        },
        RULES: {
            customFieldsValue: 'Custom Fields Value',
        },
        ACTIONS: {
            addAssignUser: 'Add Assign User',
        },
        CONDITIONS: {
            equals: 'Equals',
            notEquals: 'Not Equals',
            contains: 'Contains',
            notContains: 'Not Contains',
            isEmpty: 'Is Empty',
            isNotEmpty: 'Is Not Empty',
            isGreaterThan: 'Is Greater Than',
            isLessThan: 'Is Less Than',
            isGreaterThanOrEqual: 'Is Greater Than Or Equal',
            isLessThanOrEqual: 'Is Less Than Or Equal',
        },
        FIELD: 'Field',
        CONDITION: 'Condition',
        VALUE: 'Value',
        MAKE_ACTIVE: 'Make Active',
        MAKE_DEACTIVE: 'Make Deactive',
        DELETE: 'Delete',
        DELETE_AUTOMATION: 'Delete Automation',
        CANCEL: 'Cancel',
        DELETE_MESSAGE: 'Are you sure you want to delete this automation?',
        TOPBAR: {
            AUTOMATIONS: 'Automations',
            ADD_AUTOMATION: 'Add Automation',
            ACTIVE_AUTOMATIONS: 'Active Automations',
        }
    },
    ANALYTICS_REPORTING: {
        PAGE_TITLE: 'Analytics & Reporting',
        TOTAL_CONTRACTS: 'Total Contracts',
        DAILY_CHART_BY_USERS: 'Daily Chart by Users',
        DAILY_CHART_BY_PROCESS_TYPE: 'Daily Chart by Process Type',
        BY_PROCESS_TYPE: 'By Process Type',
        BY_USER: 'By User',
        BY_STATUS: 'By Status',
        NUMBER_OF_PROCESS_ADDED: 'Number of Process Added',
        PROCESS_TYPE: 'Process Type',
        USER: 'User',
        STATUS: 'Status',
        TOTAL: 'Total',
    },
    AUDIT_LOGS: {
        PAGE_TITLE: 'Audit Logs',
        FILTER_BY_ASSIGNEE: 'Filter by Assignee',
        FILTER_BY_EVENT_TYPE: 'Filter by Event Type',
    },
    RSUITE: {
        DATE_RANGE_PICKER: {
            TODAY: 'Today',
            YESTERDAY: 'Yesterday',
            THIS_WEEK: 'This Week',
            LAST_7_DAYS: 'Last 7 Days',
            LAST_30_DAYS: 'Last 30 Days',
            THIS_MONTH: 'This Month',
            LAST_MONTH: 'Last Month',
            THIS_YEAR: 'This Year',
            LAST_YEAR: 'Last Year',
            PREVIOUS_WEEK: 'Previous Week',
            NEXT_WEEK: 'Next Week',
        }
    },
    SPECIAL_APPLICATIONS: {
        APPS: {
            GDRIVE_TEMPLATE: {
                TITLE: 'Templates via Google Drive',
                DESCRIPTION: 'Create and manage your templates via Google Drive',
                BUTTON: 'Go to Google Drive Templates',
            }
        }
    },
    ACTIVITY_FEED: {
        PROCESS_OVERVIEW: {
            EVENTS: {
                uploadProcessApprovalVersionEvent: "<0>{{name}}</0> uploaded a version for <1>{{name2}}</1> within the scope of <2>{{processApprovalLabel}}</2>",
                createProcessApprovalEvent: "<0>{{name}}</0> sent the process to <1></1> for approval.",
                approveProcessApprovalEvent: "<0>{{name}}</0> approved the process within the scope of <1></1>.",
                assignProcessApprovalEvent: "<0>{{name}}</0> assigned the authority in the <1></1> to <2>{{name3}}</2>",
                openFileEvent: "<0>{{name}}</0> opened the file named <1>{{name2}}</1>.",
                downloadFileEvent: "<0>{{name}}</0> downloaded the file named <1>{{name2}}</1>.",
            },
        },
        USER_DASHBOARD: {
            EVENTS: {

            },
        }
    }
}