import React, { useEffect, useMemo, useRef, useState } from "react";

import QuillEditor from "@components/QuillEditor";
import { Accordion, Button, Card, Col, Container, Dropdown, DropdownButton, Form, ListGroup, Modal, Row, Table } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { addFilesToContract } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal, toggleGenericAddContractCompaniesModal, toggleGenericAddContractModal, toggleNewProcessModal } from "@redux/components/index.actions";

import { toast } from "react-toastify";
import {
    getContractById, getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    editKeyTerms,
    resetContractOverview,
    addRecordsAsSubRecordToContract,
    getFullProcessData
} from "@redux/contract/index.actions";
import { evaluate } from "mathjs";

import Loader, { DefaultLoader } from "@components/Loader";
import _, { set } from "lodash";
import './index.scss';
import CompaniesSelectInternal from "@components/SelectsPaginated/Companies/internal"
import CompaniesSelectExternal from "@components/SelectsPaginated/Companies/external"

const mapStateToProps = (state: any) => ({
    components: state?.components,
    companies: state?.companies,
    contracts: state?.contracts,
    process: state?.contracts?.processOverview,
    files: state?.files
});
const mapActionsToProps = {
    toggleGenericContractOverviewModal,
    getContractById,
    getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    editKeyTerms,
    resetContractOverview,
    addFilesToContract,
    toggleGenericAddContractCompaniesModal,
    addRecordsAsSubRecordToContract,
    toggleGenericAddContractModal,
    toggleNewProcessModal,
    getFullProcessData
};



const ProcessOverviewScreen = (props: any) => {
    const { t } = useTranslation();

    const editedContract = props?.process

    const [contractCompaniesInternal, setContractCompaniesInternal] = useState([])
    const [contractCompaniesExternal, setContractCompaniesExternal] = useState([])

    const [areYouSureSaveModal, setAreYouSureSaveModal] = useState(false);
    const [data, setData] = useState<{
        _id: string
        title: string
        description?: string
        startDate?: string
        endDate?: string
        renewalDate?: string
        effectiveDate?: string
        signedDate?: string
        status: string | undefined
        pipeline?: string
        type: string
        private: boolean
        parentContract?: string
    }>({
        _id: editedContract?._id,
        title: editedContract?.title,
        description: editedContract?.description,
        startDate: editedContract?.startDate,
        endDate: editedContract?.endDate,
        renewalDate: editedContract?.renewalDate,
        effectiveDate: editedContract?.effectiveDate,
        signedDate: editedContract?.signedDate,
        status: editedContract?.status?._id,
        pipeline: editedContract?.pipeline?._id,
        type: editedContract?.type?._id,
        private: editedContract?.private,
        parentContract: editedContract?.parentContract?._id
    });
    const [loading, setLoading] = useState<boolean>(false);


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const requestData: {
            _id: string
            contractCompanies?: any[]
        } = {
            _id: data?._id,
            contractCompanies: [...contractCompaniesInternal, ...contractCompaniesExternal],
        }





        if (!requestData?._id) {
            toast.warning(t("CONTRACTS.EDIT_CONTRACT.SOMETHING_IS_WRONG"))
            setLoading(false);
            return;
        }



        await props.editKeyTerms(requestData).then((res: any) => {
            console.log('RRRESSS', res)
            setLoading(false);
            toast.success(t("NEW_PROCESS_MODAL.EDIT_KEY_TERMS_SUCCESS"))
        }).catch((error: any) => {
            console.log('error', error)
            setLoading(false);
        })

        handleSaveButtonToggle(false);
        setAreYouSureSaveModal(false);
        props?.setAreYouSureModal(false)
        props?.setSaveTrigger(0)


    }

    useEffect(() => {
        if (props?.saveTrigger > 0) {
            handleSubmit({ preventDefault: () => { } })

        } else {
            console.log('SIFIR OLDU')
        }
    }, [props?.saveTrigger])





    useEffect(() => {

        setContractCompaniesInternal(props?.process?.contractCompanies?.filter((e: any) => e?.partiesSide === 'internal')?.map((e: any) => {
            return {
                value: e?._id,
                label: e?.title
            }
        }))
        setContractCompaniesExternal(props?.process?.contractCompanies?.filter((e: any) => e?.partiesSide === 'external')?.map((e: any) => {
            return {
                value: e?._id,
                label: e?.title
            }
        }))


    }, [props?.process?.contractCompanies])


    const [refrestStatusSelect, setRefreshStatusSelect] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setRefreshStatusSelect(false)
        }, 100)
    }, [refrestStatusSelect])


    const [showSaveButton, setShowSaveButton] = useState(false);

    const handleSaveButtonToggle = (value: boolean) => {
        // Ekstra bir işlem yapmak istediğiniz yer

        console.log('SAVE BUTTON TOGGLE', value)
        setShowSaveButton(value);
        props?.setAllowClose(value);
    };




    return (
        <>
            <Card style={props?.style}>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5>{t('NEW_PROCESS_MODAL.PARTIES')}</h5>

                    {(showSaveButton) && (
                        <Button
                            onClick={event => setAreYouSureSaveModal(true)}
                            disabled={loading} name="btn" value={1} variant="outline-primary" size="sm" type="submit">
                            {loading && <Loader />} {
                                t("CONTRACTS.EDIT_CONTRACT.SAVE")
                            }
                        </Button>
                    )}
                </Card.Header>
                <Card.Body>
                    <div className={`key-terms ${props?.disabled ? 'disabled-div' : ''}`}>
                        <form

                            onSubmit={event => setAreYouSureSaveModal(true)} >
                            <Container>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>{t('CONTRACTS.ADD_CONTRACT.PARTIES_EXTERNAL')}</Form.Label>
                                            <CompaniesSelectExternal
                                                width="100%"
                                                partiesSide="external"
                                                values={contractCompaniesExternal}
                                                handleSaveButtonToggle={handleSaveButtonToggle}
                                                onValueSubmit={(value: any) => {
                                                    handleSaveButtonToggle(true);
                                                    setContractCompaniesExternal(value)

                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>

                                        <Form.Group>

                                            <Form.Label>{t('CONTRACTS.ADD_CONTRACT.PARTIES_INTERNAL')}</Form.Label>
                                            <CompaniesSelectInternal
                                                width="100%"
                                                partiesSide="internal"
                                                values={contractCompaniesInternal}
                                                handleSaveButtonToggle={handleSaveButtonToggle}
                                                onValueSubmit={(value: any) => {
                                                    console.log('value', value)
                                                    handleSaveButtonToggle(true);
                                                    setContractCompaniesInternal(value)
                                                }}
                                            />
                                        </Form.Group>

                                    </Col>
                                </Row>
                            </Container>
                        </form>

                    </div>
                </Card.Body>
            </Card>
            <Modal
                contentClassName="border shadow"
                show={areYouSureSaveModal}
                onHide={() => setAreYouSureSaveModal(false)}
                centered
                size="lg"
            >
                <Modal.Header className="bg-light" closeButton>
                    <Modal.Title>{t("NEW_PROCESS_MODAL.ARE_YOU_SURE_TITLE")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("NEW_PROCESS_MODAL.CHANGES_WILL_BE_LOST")}</p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '20px',
                        gap: '10px'

                    }}>
                        <Button
                            disabled={loading}
                            onClick={() => {
                                handleSubmit({ preventDefault: () => { } })
                            }}
                            variant="primary" className="d-flex align-items-center gap-1">
                            {t("NEW_PROCESS_MODAL.SAVE")}
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={() => {

                                setAreYouSureSaveModal(false);
                            }}
                            variant="secondary" className="d-flex align-items-center gap-1">
                            {t("NEW_PROCESS_MODAL.CANCEl")}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(ProcessOverviewScreen);
