import React, { useEffect, useState } from 'react';
import i18n from 'i18next';

import { connect, useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

/* Company Onboarding Popup */
import CompanyOnboardingPopup from '@popups/CompanyOnboarding';

import processEnv from '@clients/index.env'

import Routes from '@routes/Routes';
import GenerateErrorModal, { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import GenerateSuccessModal from '@redux/utils/components/GenerateSuccessModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* GET_CURRENT_USER */

import { getCurrentUser, changeUsersLocaleWithoutRequest, changeUsersLocale } from '@redux/users/index.actions';
import { getCurrentCompany } from '@redux/companies/index.actions';
import { toggleNewCompanyModal } from '@redux/components/index.actions';
// Themes
// For Default import Theme.scss
import '@assets/scss/Theme.scss';
import '@redux/utils/components/GenerateErrorModal.scss';
import '@redux/utils/components/GenerateSuccessModal.scss';


import Popups from '@popups/index';
import moment from 'moment';
import "moment/locale/tr";
import "moment/locale/en-gb";
import { PageLoader } from '@components/Loader/PageLoader';

import translationConfig from './i18n';
import { useTranslation } from 'react-i18next';
import LogRocket from 'logrocket';
import 'rsuite/dist/rsuite.min.css';
import ENV from '@clients/index.env';


// This is an example script - don't forget to change it!



// For Dark import Theme-Dark.scss
// import '@assets/scss/Theme-Dark.scss';

// configure fake backend


moment.locale(['en-gb', 'tr']);


const mapStateToProps: any = (state: any) => ({
    users: state.users,
    companies: state.companies,
    components: state.components
});

const mapActionsToProps: any = {
    getCurrentUser: getCurrentUser,
    toggleNewCompanyModal: toggleNewCompanyModal,
    getCurrentCompany,
    changeUsersLocaleWithoutRequest,
    changeUsersLocale
}

const App: React.FunctionComponent<any> = (props) => {
    const dispatch = useDispatch();
    const [internetStatus, setInternetStatus] = useState<boolean>(true);
    useEffect(() => {
        const events = ['online', 'offline'];
        const status = () => {
            setInternetStatus(navigator.onLine);
        }
        events.forEach(event => {
            window.addEventListener(event, status);
        }
        );
        if (internetStatus === false) {
            toggleGenericErrorModal(dispatch, {
                status: 500,
                code: "İnternet Bağlantısı Yok",
                message: "İnternet bağlantınız kesildi kontrol edin ve tekrar deneyin."
            })
        }
        return () => {
            events.forEach(event => {
                window.removeEventListener(event, status);
            });
        }

    }, [internetStatus]);

    const history = useHistory();
    const urlParams = new URLSearchParams(window.location.search);

    const lang = urlParams.get('lang');

    const [loadedLocale, setLoadedLocale] = useState<boolean>(false);

    if (processEnv?.PRODUCTION === true && props?.users?.user?._id) {
        LogRocket.init('5mgviy/onedocs-backend-log');
        LogRocket.identify(props?.users?.user?._id, {
            name: props?.users?.user?.name + ' ' + props?.users?.user?.surname,
            email: props?.users?.user?.email,
            company: props?.companies?.company?.title,
            // Add your own custom user variables here, ie:
            subscriptionType: 'pro'
        });
    }



    useEffect(() => {
        if (lang) {
            if (["en", "tr"].includes(lang)) {
                props.changeUsersLocale({
                    locale: lang
                });
                //props?.changeUsersLocaleWithoutRequest(lang);
            }
        }
    }, [])

    useEffect(() => {
        if (props?.users?.authorization?.token && ENV?.PRODUCTION === true) {
            console.log('INTERCOM')
            //@ts-ignore
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "ue9lsgjn",
                name: props?.users?.user?.name + ' ' + props?.users?.user?.surname, // Full name
                email: props?.users?.user?.email, // Email address
                created_at: props?.users?.user?.createdAt // Signup date as a Unix timestamp
            }, [])
        }
    }, [props?.users?.user?.name]);

    useEffect(() => {
        if (props?.users?.authorization?.token && !props?.users?.user) {
            props?.getCurrentUser();
        }
        if (!props?.companies?.company?._id && !props?.companies?.fetchingCompany) {
            props?.getCurrentCompany();
        }
    }, [props?.users?.user]);

    useEffect(() => {
        if (props?.users?.user?._id) {
            if (props?.users?.user?.locale) {
                translationConfig(props?.users?.user?.locale);
                moment.locale(props?.users?.user?.locale);
                console.log('props?.users?.user?.locale')
                setLoadedLocale(true);
                if (props?.users?.user?.locale !== props?.users?.locale) {
                    props?.changeUsersLocaleWithoutRequest(props?.users?.user?.locale);
                }
            }
        } else {
            if (props?.users?.locale) {
                translationConfig(props?.users?.locale);
                setLoadedLocale(true);
            } else {
                translationConfig("en");
                setLoadedLocale(true);
            }
        }
    }, [props?.users?.user?.locale, props?.users?.locale])

    if (props?.companies?.fetchingCompany && !window.location.pathname.includes("/auth/logout")) {
        return <PageLoader />
    }

    return (
        <React.Fragment>
            <Routes>
                <Popups />
                <GenerateErrorModal />
                <GenerateSuccessModal />
            </Routes>
            <ToastContainer />
        </React.Fragment>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(App);
