import React, { useEffect, useState } from "react";

import { Modal } from "react-bootstrap";

import { connect } from "react-redux";

// components
import ReminderAdd from "@pages/contracts/Overview/contract/ActivityField/Reminder/ReminderAdd";

import { useTranslation } from "react-i18next";
import moment from "moment";
import google from '@assets/images/reminder-icons/google-calendar.svg';
import office from '@assets/images/reminder-icons/office-360.svg';
import outlook from '@assets/images/reminder-icons/outlook.svg';

const mapStateToProps = (state: any) => ({});
const mapActionsToProps = {};

const AddNewStatusModal = (props: any) => {
  const { t } = useTranslation();

  useEffect(() => {
    console.log('ADD_TO_CALENDAR', props?.calendarData)
  }, [])

  const handleGoogleCalendar = () => {
    const guestsStr = props?.calendarData?.guests?.map((e: any) => e?.email).join(',')
    const date = moment(props?.calendarData?.startDate).format('YYYYMMDDTHHmmss')
    const description = props?.calendarData?.description
    const title = props?.calendarData?.title

    window.open(`https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${date}/${date}&details=${description}&text=${title}&add=${guestsStr}`, "__blank");

  }

  const handleOutlookCalendar = () => {

    const guestsStr = props?.calendarData?.guests?.map((e: any) => e?.email).join(',')
    const date = moment(props?.calendarData?.startDate).toISOString()
    const description = props?.calendarData?.description
    const title = props?.calendarData?.title

    window.open(`https://outlook.live.com/calendar/0/deeplink/compose?body=${description}&enddt=${date}&path=/calendar/action/compose&rru=addevent&startdt=${date}&subject=${title}&to=${guestsStr}`);
  }

  const handleOffice360 = () => {

    const guestsStr = props?.calendarData?.guests?.map((e: any) => e?.email).join(',')
    const date = moment(props?.calendarData?.startDate).toISOString()
    const description = props?.calendarData?.description
    const title = props?.calendarData?.title

    window.open(`https://outlook.office.com/calendar/0/deeplink/compose?body=${description}&enddt=${date}&path=/calendar/action/compose&rru=addevent&startdt=${date}&subject=${title}&to=${guestsStr}`);
  }

  return (
    <Modal
      show={props?.addToCalendarModal}
      onHide={props?.toggleAddToCalendarModal}
      size={'lg'}
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">{t("CONTRACTS.OVERVIEW.REMINDER.ADD_TO_CALENDAR")}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center flex-column gap-3">
          <button className="btn btn-light border w-100" onClick={handleGoogleCalendar}>
            <img src={google} alt="Google-Calendar" />
            {t("CONTRACTS.OVERVIEW.REMINDER.ADD_TO_GOOGLE_CALENDAR")}
          </button>
          <button className="btn btn-light border w-100" onClick={handleOutlookCalendar}>
            <img src={outlook} alt="Outlook-Calendar" />
            {t("CONTRACTS.OVERVIEW.REMINDER.ADD_TO_OUTLOOK_CALENDAR")}
          </button>
          <button className="btn btn-light border w-100" onClick={handleOffice360}>
            <img src={office} alt="Office-360" />
            {t("CONTRACTS.OVERVIEW.REMINDER.ADD_TO_OFFICE_360_CALENDAR")}
          </button>
        </div>

      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(AddNewStatusModal);
