import React, { useEffect, useState } from "react";
import ScreenModal from "@components/Modals/ScreenModal";
import { connect } from 'react-redux';
import "./index.scss";
import globalStyles from "@styles/globalStyles";
import { Form } from "react-bootstrap";
import ButtonLoader from "@components/Buttons/ButtonLoader";
import { GrChapterAdd, GrAdd } from 'react-icons/gr';
import { BsFillCursorFill, BsSearch } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';
import ShouldCreateOrJoinACompany, { renderFooter as renderCreateOrJoinCompanyFooter } from '@popups/CompanyOnboarding/ShouldCreateOrJoinACompany';
import CreateCompany, { renderFooter as renderCreateCompanyFooter } from "@popups/CompanyOnboarding/CreateCompany";
import JoinCompany, { renderFooter as renderJoinCompanyFooter } from "@popups/CompanyOnboarding/JoinCompany";
import CreateCompanySurvey, { renderFooter as renderCreateCompanySurveyFooter } from "@popups/CompanyOnboarding/CreateCompanySurvey";


import { setNewCompanyData, toggleNewCompanyModal } from "@redux/components/index.actions";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const mapStateToProps = (state: any) => ({
    users: state.users,
    components: state.components,
    companies: state.companies
});

const mapActionsTopProps = {
    setNewCompanyData,
    toggleNewCompanyModal
}
const CompanyOnboardingPopup: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [show, setShow] = useState<boolean>(false);
    const [index, setIndex] = useState<number>(0);

    const next = (data?: number) => {

        console.log('data', data)

        if (data || data === 0) {
            setIndex(data);
        }
        else if (!data && index < renderArr.length - 1) {
            setIndex(index + 1);
        }
    }
    const previous = () => {
        if (index > 0) {
            setIndex(index - 1)
        }
    }


    const batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    } = {
        onClose: (isLastStep: boolean = false) => {
            if (props?.companies?.company || isLastStep) {
                props?.toggleNewCompanyModal(false)
            } else {
                toast.error(t("POPUPS.COMPANY_ONBOARDING.NO_COMPANY_FOUND"));
            }
        },
        next: next,
        previous: previous,
        index: index,
        length: 4
    }


    const renderArr = [
        <div style={{ flexDirection: 'column' }}>
            <ShouldCreateOrJoinACompany batchOperations={batchOperations} />
        </div>,
        <div style={{ flexDirection: 'column' }}>
            <CreateCompany batchOperations={batchOperations} />
        </div>,
        <div style={{ flexDirection: 'column' }}>
            <CreateCompanySurvey batchOperations={batchOperations} />
        </div>,
        <div style={{ flexDirection: 'column' }}>
            <JoinCompany batchOperations={batchOperations} />
        </div>
    ];

    const Footers: React.FunctionComponent<any>[] = [
        renderCreateOrJoinCompanyFooter,
        /* renderCreateCompanyFooter, */
        renderCreateCompanySurveyFooter,
        renderCreateCompanySurveyFooter,
        renderJoinCompanyFooter,
    ];

    const CurrentFooter = Footers[index];

    useEffect(() => {
        const invalidRoutes = [
            '/mail-confirmation',
            '/invites',
            '/confirm',
            '/mail/confirmation',
            '/login',
            '/register',
            '/forgot-password',
            '/reset-password',
            '/auth/logout',
            '/logout'
        ];
        const showable: boolean[] = invalidRoutes.map((item: string, index: number) => {
            return location?.pathname?.includes(item);
        })
        if (showable.some((boolean: boolean) => boolean === true)) {
            setShow(false)
        } else {
            setShow(true);
        }

    }, [location?.pathname])

    return (
        <ScreenModal
            show={(props?.components?.showNewCompanyModal && show) || (show && props?.companies?.company === null)}
            renderHeader={(
                <h5>
                    {t("POPUPS.COMPANY_ONBOARDING.COMPANY_ONBOARDING")}
                </h5>
            )}
            size={"xl"}
            renderFooter={(
                <CurrentFooter batchOperations={batchOperations} />
            )}
            onClose={batchOperations?.onClose}>
            <div className="company-onboarding">
                {[renderArr[index]]}
            </div>
        </ScreenModal>
    )
}


export default connect(mapStateToProps, mapActionsTopProps)(CompanyOnboardingPopup);