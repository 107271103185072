import * as XLSX from "xlsx"

export const excelCreator = (filename: string, xlsHeader: string[], xlsRows: any[]) => {
    var createXLSLFormatObj = [];
    createXLSLFormatObj.push(xlsHeader);
    const table = document.createElement("table");
    const tbody = document.createElement("tbody");
    xlsRows.forEach((xlsRow: any, index: number) => {
        const innerRowData = Object.keys(xlsRow).map((key: string, index: number) => {
            return xlsRow[key]
        });
        createXLSLFormatObj.push(innerRowData);
    })
    /* File Name */
    var filename = `${filename}.xlsx`;
    /* Sheet Name */
    var ws_name = "onedocs sheet";
    if (typeof console !== 'undefined') console.log(new Date());
    var wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
    /* Add worksheet to workbook */
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    /* Write workbook and Download */
    if (typeof console !== 'undefined') console.log(new Date());
    XLSX.writeFile(wb, filename);
    if (typeof console !== 'undefined') console.log(new Date());
}

export const convertToCsv = (arr: any) => {
    const keys = Object.keys(arr[0]);
    const replacer: any = (_key: any, value: any) => value === null ? '' : value;
    const processRow = (row: any) => keys.map(key => JSON.stringify(row[key], replacer)).join(',');
    return [keys.join(','), ...arr.map(processRow)].join('\r\n');
};