import React, { useEffect, useState } from "react";

import { Button, Form, FormGroup, Modal } from "react-bootstrap";

import { connect } from "react-redux";

// components
import StatusEdit from "@pages/statuses/StatusEdit";

import { useTranslation } from "react-i18next";
import apiInstance from "@clients/api.client";
import Loader from "@components/Loader";
import { toast } from "react-toastify";

const mapStateToProps = (state: any) => ({});
const mapActionsToProps = {};

const ApproveProcessModal = (props: any) => {
  const { t } = useTranslation();

  const submitHandle = (e: any) => {
    e.preventDefault();
    props?.setLoading(true)
    // Get form data
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    console.log(data);

    apiInstance.post(`/approve-files/external/approve`, {
      ...data,
      token: props?.token
    })
      .then((res: any) => {
        props?.toggle();
        props?.getReadApproveProcess({
          id: props?.process?._id
        })

      })
      .catch((error: any) => {
        const errorMessage = error?.response?.data?.response?.message;
        toast.error(errorMessage || 'Onay verilecek bir dosya bulunamadı. Detaylı bilgi için onay sürecini kontrol ediniz.');
        props?.getReadApproveProcess({
          id: props?.process?._id
        })
        props.toggle();
      })
      .finally(() => {
        props?.setLoading(false);
      })


  };

  return (
    <Modal
      show={props?.show}
      onHide={props?.toggle}
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.APPROVE_MODAL.TITLE')}</h4>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.APPROVE_MODAL.DESCRIPTION')}
        </p>

        <Form onSubmit={submitHandle}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <FormGroup className="mb-3">
              <Form.Label>{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.APPROVE_MODAL.MESSAGE_INPUT_LABEL')}</Form.Label>
              <Form.Control
                name="message"
                placeholder={t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.APPROVE_MODAL.MESSAGE_INPUT_PLACEHOLDER')}
                as="textarea" rows={3} />
            </FormGroup>
          </Form.Group>


          <Button disabled={props?.loading} variant="primary" type="submit">
            {props?.loading && <Loader />} {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.APPROVE_MODAL.SEND')}
          </Button>
        </Form>


      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ApproveProcessModal);
