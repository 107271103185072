import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import {
  Card, FloatingLabel, Form, Row, Col, ListGroup, OverlayTrigger
  , Tooltip,
  Button
} from "react-bootstrap";
import FeatherIcons from "feather-icons-react";
import { connect } from "react-redux";
import { setEditContractTypeData, setSaveContractTypeData } from "@redux/contract/index.actions";
import { setCustomFormsFieldEditData, reOrderFieldsInCustomForm, removeFieldFromForm, editForm } from "@redux/custom-forms/index.actions";
import EditingDropListItem from "@popups/EditCustomFormsPopup/components/EditingDropListItem"
import { useTranslation } from "react-i18next";
import CustomFormFieldArea, { TCustomComponent } from "@components/CustomFormFieldArea";
const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
  companies: state.companies,
  customForms: state.customForms
});
const mapActionsToProps = {
  setEditContractTypeData,
  setSaveContractTypeData,
  setCustomFormsFieldEditData,
  reOrderFieldsInCustomForm,
  removeFieldFromForm,
  editForm
};



const EditingDropList: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const handleSaveData = (event: any) => {
    props.editForm({
      [event?.target?.name]: event?.target?.value,
    })
  }

  const handleSetCustomFormEditData = (data: any) => {
    console.log('data', data)
    props?.setCustomFormsFieldEditData(data)
  }
  //const debounceSave = _.debounce(handleSaveData, 1000);


  return (
    <Row>

      <Col xs={12} className="mb-3">
        <FloatingLabel className="mb-1 custom-forms-control-label" label={t("CONTRACTS_TYPES.TITLE_LABEL")} >
          <Form.Control
            name="title"
            onBlur={(event) => {
              handleSaveData(event);
            }}
            //onInput={debounceSave}
            defaultValue={props?.customForms?.selectedForm?.title}
            type="text"
            placeholder={t("CONTRACTS_TYPES.TITLE_PLACEHOLDER")}
            className="custom-forms-control"
          />
        </FloatingLabel>
        <FloatingLabel className="custom-forms-control-label" label={t('CUSTOMFORMS.CUSTOMFORMS_EDIT_POPUP.DESCRIPTION')} >
          <Form.Control
            name="description"
            onBlur={(event) => {
              handleSaveData(event);
            }}
            defaultValue={props?.customForms?.selectedForm?.description}
            type="text"
            placeholder={t("CONTRACTS_TYPES.TITLE_PLACEHOLDER")}
            className="custom-forms-control"
          />
        </FloatingLabel>
      </Col>
      <Col xs={12}>
        <Droppable key={2} droppableId={"contractCustomFields"}>
          {(provided, snapshot) => (
            <div className="custom-forms-drop-list" ref={provided.innerRef}>
              <ListGroup as="ul">
                {props?.customForms?.saving === false && (
                  <>
                    {(props?.customForms?.selectedForm?.formFields || []).map((item: any, index: number) => (
                      <Draggable
                        key={item?._id}
                        draggableId={item?._id + ""}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {
                              item?.type === 'CustomFields' ? (
                                <ListGroup.Item
                                  onClick={() => handleSetCustomFormEditData(item)}
                                  key={index}
                                  as="li"
                                  style={item?._id == props?.customForms?.editSelectedFormFieldData?._id ? { border: '2px solid #2C42FF' } : { border: '1px solid #000' }}
                                  className="cf-light-blue rounded mb-1 d-flex align-items-center justify-content-between"
                                >
                                  <div className="row">

                                    {

                                      ((item: any) => {

                                        //existOptionValues
                                        let costumComponent: TCustomComponent = item.CustomComponent

                                        if (costumComponent !== 'Formula') {
                                          if (item?.field?.existOptionValues === true) {
                                            return React.createElement(CustomFormFieldArea[costumComponent], {
                                              key: item?._id + index + 'existOptionValuesInput',
                                              size: 12,
                                              name: item?._id,
                                              isDisabled: true,
                                              label: item?.title,
                                              value: item?.defaultValue,
                                              helpText: item?.description || '',
                                              onChange: (event: any) => console.log(event, item),
                                              require: item?.field?.isOptional,
                                              data: item
                                            })
                                          } else {
                                            return React.createElement(CustomFormFieldArea[costumComponent], {
                                              key: item?._id + index + 'Input',
                                              size: 12,
                                              name: item?._id,
                                              isDisabled: true,
                                              label: item?.title,
                                              value: item?.defaultValue,
                                              helpText: item?.description || '',
                                              onChange: (event: any) => console.log(event, item),
                                              require: item?.field?.isOptional,
                                              data: item
                                            })
                                          }

                                        }




                                      })(item)
                                    }
                                  </div>
                                  <div className="custom-forms-droplist-buttons d-flex align-items-center justify-content-end">
                                    <Button
                                      className="d-none"
                                      onClick={() => handleSetCustomFormEditData(item)}
                                      variant={'link'}
                                    >
                                      <FeatherIcons size={14} icon="edit"></FeatherIcons>
                                    </Button>

                                    <Button
                                      className=""
                                      onClick={() => props?.reOrderFieldsInCustomForm({
                                        sourceIndex: index,
                                        destinationIndex: index - 1
                                      })}
                                      variant={'link'}
                                    >
                                      <FeatherIcons size={14} icon="chevron-up"></FeatherIcons>
                                    </Button>
                                    <Button
                                      className=""
                                      onClick={() => props?.reOrderFieldsInCustomForm({
                                        sourceIndex: index,
                                        destinationIndex: index + 1
                                      })}
                                      variant={'link'}
                                    >
                                      <FeatherIcons size={14} icon="chevron-down"></FeatherIcons>
                                    </Button>
                                    <Button
                                      className=""
                                      onClick={() => props?.removeFieldFromForm({
                                        fieldId: item?._id
                                      })}
                                      variant={'link'}
                                    >
                                      <FeatherIcons size={14} icon="x"></FeatherIcons>
                                    </Button>
                                  </div>
                                </ListGroup.Item>
                              ) : (
                                <ListGroup.Item
                                  key={index}
                                  as="li"
                                  onClick={() => handleSetCustomFormEditData(item)}
                                  style={item?._id == props?.customForms?.editSelectedFormFieldData?._id ? { border: '2px solid #2C42FF' } : { border: '1px solid #000' }}
                                  className="cf-light-blue rounded mb-1 d-flex align-items-center justify-content-between"
                                >

                                  <div className="row w-100" >
                                    {
                                      ((item: {
                                        _id: string,
                                        field: any,
                                        title: string,
                                        description: string,
                                        CustomComponent: TCustomComponent
                                      }) => {

                                        if (item?.CustomComponent !== 'Formula') {
                                          return React.createElement(CustomFormFieldArea[item?.CustomComponent], {
                                            size: 12,
                                            name: item?._id,
                                            label: item?.title,
                                            helpText: item?.description || '',
                                            onChange: (event: any) => console.log(event, item),
                                            require: false,
                                            data: item
                                          })
                                        }



                                      })(item)
                                    }
                                  </div>
                                  <div className="custom-forms-droplist-buttons d-flex align-items-center justify-content-end">
                                    <Button
                                      className="d-none"
                                      onClick={() => handleSetCustomFormEditData(item)}
                                      variant={'link'}
                                    >
                                      <FeatherIcons size={14} icon="edit"></FeatherIcons>
                                    </Button>

                                    <Button
                                      className=""
                                      onClick={() => props?.reOrderFieldsInCustomForm({
                                        sourceIndex: index,
                                        destinationIndex: index - 1
                                      })}
                                      variant={'link'}
                                    >
                                      <FeatherIcons size={14} icon="chevron-up"></FeatherIcons>
                                    </Button>
                                    <Button
                                      className=""
                                      onClick={() => props?.reOrderFieldsInCustomForm({
                                        sourceIndex: index,
                                        destinationIndex: index + 1
                                      })}
                                      variant={'link'}
                                    >
                                      <FeatherIcons size={14} icon="chevron-down"></FeatherIcons>
                                    </Button>
                                    <Button
                                      className=""
                                      onClick={() => props?.removeFieldFromForm({
                                        fieldId: item?._id
                                      })}
                                      variant={'link'}
                                    >
                                      <FeatherIcons size={14} icon="x"></FeatherIcons>
                                    </Button>
                                  </div>
                                </ListGroup.Item>
                              )
                            }



                          </div>
                        )}
                      </Draggable>
                    ))}
                  </>
                )}
              </ListGroup>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Col>
    </Row>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(EditingDropList);
