import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Container, Card } from "react-bootstrap";
import FormInput from "@components/FormInput";
import Select from "react-select";
import moment from "moment";
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons/dist/textMaskAddons';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Field, reduxForm } from 'redux-form';
import { trimEnd } from "lodash";
import { useTranslation } from "react-i18next";
type CustomFieldAreaProps = {
    children?: React.ReactNode
}

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    allowNegative: false,
    decimalLimit: false,
    allowLeadingZeroes: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',' // default is '.'

})

export type TCustomComponent = 'Text' | 'TextArea' | 'Number' | 'Date' | 'Time' | 'Phone' | 'Currency' | 'SingleSelect' | 'Formula'

export type CSInputTextProps = {
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    key?: string | number
    label?: string
    require?: boolean
    name: string
    value?: any
    className?: string
    onInput?: React.FormEventHandler<HTMLInputElement> | any
    onChange?: React.FormEventHandler<HTMLInputElement>
    data?: any
    style?: any
}



const AutomationCustomFieldArea = ({ children }: CustomFieldAreaProps) => {


    return (
        <div style={{
            marginTop: '10px',
        }}>
            {children}
        </div>
    )
}



const Text = (props: CSInputTextProps) => {

    return (
        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="text"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                    onChange={props?.onChange}
                />
            </span>
        </div>


    )

}
const Formula = (props: CSInputTextProps) => {


    useEffect(() => {
        props?.onInput({ target: { name: props?.name, value: props?.value } })
    }, [props?.value])


    return (

        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <FormInput
                    key={props?.key + 'formulaX'}
                    className={props?.className}
                    name={props?.name}
                    disabled={true}
                    type="text"
                    defaultValue={props?.value}
                    required={props?.require}
                /* onInput={props?.onInput}
                onChange={props?.onChange} */
                />
            </span>
        </div>

    )

}
/* const Formula = (props: CSInputTextProps) => {

    return null

} */
const Currency = (props: CSInputTextProps) => {
    const { t } = useTranslation();
    console.log('props', props?.data?.extraProps?.currency)

    return (

        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <MaskedInput
                    mask={createNumberMask({
                        prefix: '',
                        allowDecimal: true,
                        allowNegative: false,
                        decimalLimit: false,
                        allowLeadingZeroes: false,
                        thousandsSeparatorSymbol: '.',
                        decimalSymbol: ',' // default is '.'

                    })}
                    showMask={false}
                    guide={false}
                    className={'form-control'}
                    name={props?.name}
                    type="text"
                    defaultValue={props?.value?.toString().replace('.', ',')}
                    required={props?.require}
                    onInput={props?.onInput}
                />
            </span>
        </div>
    )

}
const Phone = (props: CSInputTextProps) => {

    return (

        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <PhoneInput
                    country={'tr'}
                    value={props?.value}
                    onChange={phone => props?.onInput({ target: { name: props?.name, value: phone } })}
                />
            </span>
        </div>

    )

}

const SingleSelect = (props: CSInputTextProps) => {

    return (

        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <Select
                    styles={
                        {
                            control: (base, state) => ({
                                ...base,
                                flex: 1,
                                width: '100%',
                                '&:hover': {
                                    borderColor: '#2c42ff'
                                },
                                border: '1px solid #ced4da',
                                boxShadow: 'none',
                                '&:focus': {
                                    borderColor: '#2c42ff',
                                    boxShadow: 'none'
                                }
                            })
                        }
                    }
                    isClearable={true}
                    isMulti={false}
                    defaultValue={props?.value}
                    name={props?.name}
                    options={
                        props?.data?.OptionValues?.map((opt: any) => {
                            return {
                                value: opt?._id,
                                label: opt?.title,
                                opt: opt
                            };
                        })
                    }
                    onChange={props?.onChange}
                ></Select>

            </span>
        </div>

    )

}

const TextArea = (props: CSInputTextProps) => {

    return (
        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="textarea"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}

                />
            </span>
        </div>

    )

}

const Number = (props: CSInputTextProps) => {
    const { t } = useTranslation();


    console.log('props', props?.data?.extraProps)


    if (props?.data?.extraProps?.numberType === 'integer') {

        return (

            <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
                <label style={{
                    width: '80px',
                    fontSize: '12px'

                }} htmlFor="">{props?.label}</label>

                <span style={{
                    width: '100%',
                    flex: 1,
                }}>
                    <FormInput
                        className={props?.className}
                        name={props?.name}
                        type="text"
                        defaultValue={props?.value}
                        required={props?.require}
                        onInput={(e: any) => {
                            let val = e.target.value.replace(/\D/g, '')
                            console.log(val)
                            // Update default value
                            e.target.value = val
                            props?.onInput({ target: { name: props?.name, value: val } })

                        }}
                    />
                </span>
            </div>

        )


    } else {
        return (
            <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
                <label style={{
                    width: '80px',
                    fontSize: '12px'

                }} htmlFor="">{props?.label}</label>

                <span style={{
                    width: '100%',
                    flex: 1,
                }}>
                    <MaskedInput
                        mask={currencyMask}
                        showMask={false}
                        guide={false}
                        className={'form-control'}
                        name={props?.name}
                        type="text"
                        defaultValue={props?.value?.toString().replace('.', ',')}
                        required={props?.require}
                        onInput={props?.onInput}
                    />
                </span>
            </div>

        )
    }



}

const Date = (props: CSInputTextProps) => {
    console.log(moment(props?.value).format('DD.MM.YYYY').toString())
    return (

        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="date"
                    defaultValue={props?.value ? moment(props?.value).format('YYYY-MM-DD').toString() : undefined}
                    required={props?.require}
                    onInput={props?.onInput}
                />
            </span>
        </div>

    )

}

const Email = (props: CSInputTextProps) => {

    return (
        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="email"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                />
            </span>
        </div>

    )

}

const Time = (props: CSInputTextProps) => {

    return (

        <div className="custom-fields-selector d-flex align-items-center justify-content-between gap-1">
            <label style={{
                width: '80px',
                fontSize: '12px'

            }} htmlFor="">{props?.label}</label>

            <span style={{
                width: '100%',
                flex: 1,
            }}>
                <FormInput
                    className={props?.className}
                    name={props?.name}
                    type="time"
                    defaultValue={props?.value}
                    required={props?.require}
                    onInput={props?.onInput}
                />
            </span>
        </div>

    )

}

AutomationCustomFieldArea.Text = Text;
AutomationCustomFieldArea.TextArea = TextArea;
AutomationCustomFieldArea.Number = Number;
AutomationCustomFieldArea.Date = Date;
AutomationCustomFieldArea.Time = Time;
AutomationCustomFieldArea.Email = Email;
AutomationCustomFieldArea.SingleSelect = SingleSelect;
AutomationCustomFieldArea.Currency = Currency;
AutomationCustomFieldArea.Phone = Phone;
AutomationCustomFieldArea.Formula = Formula;

export default AutomationCustomFieldArea;