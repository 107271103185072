import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '@redux/reducers';
import rootSaga from '@redux/sagas';
import thunk from 'redux-thunk';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}


//const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk];
let store: any;

export function configureStore(initialState: {}) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const localstore = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));
    //sagaMiddleware.run(rootSaga);
    store = localstore;
    return localstore;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
