import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import { RiIndentDecrease, RiIndentIncrease } from 'react-icons/ri';

export default function Indent(props: any) {

    const editor = useSlate()

    return (
        <>
            <Button
                variant='link'
                onMouseDown={(event: any) => {
                    event.preventDefault()

                    props?.increaseIndent(editor);

                }}
            >
                <RiIndentIncrease size={18} />
            </Button>
            <Button
                variant='link'
                onMouseDown={(event: any) => {
                    event.preventDefault()
                    props?.decreaseIndent(editor);
                }}
            >
                <RiIndentDecrease size={18} />

            </Button>
        </>
    )
}
