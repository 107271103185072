import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import UnknownUser from '@assets/images/users/unknown.jpg';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

export const localStorageKeys: {

} = {

};

const initialState = {
    nominations: [],
    endedFetchingNominations: false
}


const NominationsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }
        case ActionDispatchTypes.GET_NOMINATIONS_SUCCESSFUL:
            return {
                ...state,
                nominations: action.payload?.node?.nominations
            }
        default:
            return state;
    }
}

export default NominationsReducer;