import React, { useState, useEffect } from 'react';
import ScreenModal from '@components/Modals/ScreenModal';
import { useTranslation } from 'react-i18next';
import GoogleDrive from '@assets/images/cdn/google-drive.png';
import OneDrive from '@assets/images/cdn/onedrive.png';
import SharePoint from '@assets/images/cdn/sharepoint.png';
import AWSS3 from '@assets/images/cdn/amazon-s3.png';
import CustomCDN from '@assets/images/cdn/custom-cdn.png';
import { getDriveAuthUrl, getOneDriveAuthUrl, getSharePointAuthUrl } from '@redux/users-integrations/index.actions';
import { cdnSetPickerFiles, cdnIsImportedFiles, getUserFilesPicker } from '@redux/files/index.actions';
import { connect } from 'react-redux';
import useDrivePicker from 'react-google-drive-picker';

import './index.scss';
import api from '@clients/api.client';
import { toast } from 'react-toastify';
import { DefaultLoader } from '@components/Loader';
import { Button } from 'react-bootstrap';
import { isNull } from 'lodash';
import { formatBytes } from '@utils/size/formatBytes.utils';
import { toDataUrl, toDataUrlCDNGDrive, toDataUrlCDNOneDrive, toDataUrlCDNSharePoint } from '@utils/files/urlToFile.utils';
import { AxiosError, AxiosResponse } from 'axios';
import cdnservices from '@clients/cdnservices.client';

const mapFormats: {
    [key: string]: {
        extension: string;
        mimeType: string;
    }
} = {
    "application/vnd.google-apps.document": {
        extension: ".docx",
        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    "application/vnd.google-apps.spreadsheet": {
        extension: ".xlsx",
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    "application/vnd.google-apps.presentation": {
        extension: ".pptx",
        mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
    }
}

type Source = {
    id: string;
    name: string;
    icon: string;
}

interface Actions {
    [key: string]: {
        startPicker: () => void;
        startIntegration: () => void;
    }
}
const Sources: Source[] = [
    {
        id: 'googledrive',
        name: 'Google Drive',
        icon: GoogleDrive
    },
    {
        id: 'onedrive',
        name: 'OneDrive',
        icon: OneDrive
    },
    {
        id: 'sharepoint',
        name: 'SharePoint',
        icon: SharePoint
    }
]


const mapStateToProps: any = (state: any) => ({
    usersIntegrations: state.usersIntegrations
})

const mapActionsTopProps = {
    getDriveAuthUrl: getDriveAuthUrl,
    getOneDriveAuthUrl: getOneDriveAuthUrl,
    getSharePointAuthUrl: getSharePointAuthUrl,
    cdnSetPickerFiles: cdnSetPickerFiles,
    cdnIsImportedFiles: cdnIsImportedFiles,
    getUserFilesPicker: getUserFilesPicker,
}

const CDNImportSourceSelect: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState<boolean>(true);
    const [loadingFiles, setLoadingFiles] = useState<boolean>(false);
    const [integrationData, setIntegrationData] = useState<any>(null);
    const [sourceClicked, setSourceClicked] = useState<string | null>(null);
    const [isIntegrated, setIsIntegrated] = useState<boolean | null>(null);
    const [openPicker, data, authResponse] = useDrivePicker({
        onCancel: () => {
            setShowPopup(true)
            console.log("User closed picker with close/cancel button");
            setSourceClicked(null);
            setIsIntegrated(null);
            setIntegrationData(null);
        }
    });

    const reset = () => {
        props?.onReset();
    }

    const openGoogleDrivePicker: () => void = () => {
        //alert(JSON.stringify(integrationData?.scope))
        // const customViewsArray = [new google.picker.DocsView()]; // custom view
        setShowPopup(false)
        openPicker({
            clientId: integrationData?.clientId,
            developerKey: integrationData?.apiKey,
            //viewId: "DOCS",
            //customScopes: [...integrationData?.scope],
            customScopes: ["https://www.googleapis.com/auth/drive.file"],
            token: integrationData?.accessToken, // pass oauth token in case you already have one
            showUploadView: false,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            appId: integrationData?.appId,
            setIncludeFolders: true,
            viewId: 'DOCS',
            setSelectFolderEnabled: true
            // customViews: customViewsArray, // custom view
        })
    }

    const integrateWithGoogleDrivePicker = async () => {
        const authUrl = await props?.getDriveAuthUrl();
        const theTop = ((window.innerHeight / 2) - (1000 / 2)) / 2;
        const theLeft = (window.innerWidth / 2) - (600 / 2);
        const features = 'height=1000,width=600,top=' + theTop + ',left=' + theLeft + ',toolbar=1,Location=0,Directories=0,Status=0,menubar=1,Scrollbars=1,Resizable=1';
        const integrationWindow = window.open(authUrl, "Drive Integration", features);
        //history.push("/user/integrations");
        console.log(integrationWindow)
        const timer = setInterval(() => {
            if (integrationWindow?.closed) {
                clearInterval(timer);
                toast.success(t("POPUPS.UPLOAD_FILES.INTEGRATION_COMPLETED_GOOGLE"))
                reset();
            }
        }, 300)
    }

    const integrateWithOneDrivePicker = async () => {
        const authUrl = await props?.getOneDriveAuthUrl();
        //alert(authUrl)
        const theTop = ((window.innerHeight / 2) - (1000 / 2)) / 2;
        const theLeft = (window.innerWidth / 2) - (600 / 2);
        const features = 'height=1000,width=600,top=' + theTop + ',left=' + theLeft + ',toolbar=1,Location=0,Directories=0,Status=0,menubar=1,Scrollbars=1,Resizable=1';
        const integrationWindow = window.open(authUrl, "OneDrive Integration", features);
        //history.push("/user/integrations");
        console.log(integrationWindow)
        const timer = setInterval(() => {
            if (integrationWindow?.closed) {
                clearInterval(timer);
                toast.success(t("POPUPS.UPLOAD_FILES.INTEGRATION_COMPLETED_ONEDRIVE"));
                reset();
            }
        }, 300)
    }

    const integrateWithSharePointPicker = async () => {
        const authUrl = await props?.getSharePointAuthUrl();

        //alert(authUrl)
        const theTop = ((window.innerHeight / 2) - (1000 / 2)) / 2;
        const theLeft = (window.innerWidth / 2) - (600 / 2);
        const features = 'height=1000,width=600,top=' + theTop + ',left=' + theLeft + ',toolbar=1,Location=0,Directories=0,Status=0,menubar=1,Scrollbars=1,Resizable=1';
        const integrationWindow = window.open(authUrl, "Sharepoint Integration", features);
        //history.push("/user/integrations");
        console.log(integrationWindow)
        const timer = setInterval(() => {
            if (integrationWindow?.closed) {
                clearInterval(timer);
                toast.success(t("POPUPS.UPLOAD_FILES.INTEGRATION_COMPLETED_SHAREPOINT"));
                reset();
            }
        }, 300)
    }
    /* GDRIVE IMPORT */
    useEffect(() => {
        // do anything with the selected/uploaded files
        if (data) {
            setShowPopup(true)
            setLoadingFiles(true);
            (async () => {
                try {
                    props?.cdnIsImportedFiles(true);
                    console.log(data?.docs);
                    const finalFiles: any[] = [];
                    const selectedFiles: any[] = data?.docs?.slice(0, 20).map((file: any, index: number) => {
                        console.log(file)
                        return {
                            id: file.id,
                            filename: file.name + (mapFormats[file?.mimeType]?.extension || ""),
                            name: file.name + (mapFormats[file?.mimeType]?.extension || ""),
                            size: file.sizeBytes,
                            type: mapFormats[file?.mimeType]?.mimeType ? mapFormats[file?.mimeType]?.mimeType : file?.mimeType,
                            mimeType: mapFormats[file?.mimeType]?.mimeType ? mapFormats[file?.mimeType]?.mimeType : file?.mimeType,
                            isShared: file?.isShared || false,
                            file: undefined,
                            source: "google-drive",
                            importedFrom: "google-drive",
                            fileSize: formatBytes(file.sizeBytes),
                            downloadUrl: `https://drive.google.com/u/0/uc?id=${file?.id}&export=download`,
                            downloadUrlWithApi: `https://www.googleapis.com/drive/v3/files/${file?.id}?alt=media&key=${integrationData?.apiKey}`,
                            index: index
                        }
                    });
                    console.log(selectedFiles);
                    finalFiles.push(...selectedFiles);

                    console.log(finalFiles);
                    for (let i = 0; i < finalFiles?.length; i++) {
                        const doc: any = finalFiles[i];
                        let filename = doc?.filename;

                        if (mapFormats[doc.mimeType]?.extension) {
                            const extension = mapFormats[doc.mimeType]?.extension;
                            filename = filename + (extension || "");
                        }

                        const file: any = await toDataUrlCDNGDrive(doc?.id, filename, doc?.type);
                        //const file = URL.createObjectURL(base64);
                        //const fileReader = new File([file], doc?.filename);
                        if (file?.message) {
                            toast.warning("POPUPS.CDN.SOMETHING_IS_WRONG");
                            return props?.onClose();
                        }
                        finalFiles[i].file = file;
                        finalFiles[i].size = finalFiles[i].fileSize;
                        finalFiles[i].isUploaded = false;
                        finalFiles[i].fileType = finalFiles[i]?.type;

                    }
                    console.warn("DOWNLOADED FROM SERVER FILE:::", finalFiles);
                    props?.cdnSetPickerFiles({
                        files: finalFiles,
                        importedSource: "google-drive"
                    });
                    props?.onImportFilesSelected();

                } catch (error) {
                    props?.onClose()
                }


                //data.docs.map(i => console.warn(i))
                //props?.onClose();
                //data.docs.map(i => console.warn(i))
            }
            )().then((res) => { })
        }

    }, [data])

    useEffect(() => {
        if (sourceClicked) {
            actions?.[sourceClicked]?.startPicker();
        }
    }, [sourceClicked])

    useEffect(() => {
        if (sourceClicked === "googledrive") {
            if (integrationData) {
                openGoogleDrivePicker();
            }
        }
    }, [integrationData])

    const actions: Actions = {
        googledrive: {
            startPicker: async () => {
                if (!integrationData) {
                    try {
                        const integration = await api.post("/users/integrations/drive/token");
                        setIntegrationData(integration?.data?.node);
                        setIsIntegrated(true);
                    } catch (err) {
                        //toast.error("Google Drive integration is not found");
                        actions?.googledrive?.startIntegration();
                    }
                } else {
                    openGoogleDrivePicker();
                }
            },
            startIntegration: async () => {
                await integrateWithGoogleDrivePicker()
            }
        },
        onedrive: {
            startPicker: async () => {
                let integration: any = null;
                try {
                    integration = await api.post("/users/integrations/onedrive/token");
                    setIntegrationData(integration.data?.node);
                    setIsIntegrated(true);
                } catch (error) {
                    return actions?.onedrive?.startIntegration();
                }
                const files = await new Promise<any>((resolve, reject) => {
                    var odOptions: any = {
                        clientId: integration?.data?.node?.clientId,
                        action: "query",
                        multiSelect: true,
                        openInNewWindow: false,
                        //viewTypes: "files",
                        advanced: {
                            accessToken: integration.data?.node?.accessToken,
                            endpointHint: "api.onedrive.com",
                            //loginHint: 'https://graph.microsoft.com',
                            //"https://graph.microsoft.com/v1.0/",
                            //redirectUri: window.location.origin + "/user/microsoft/onedrive",
                            //isConsumerAccount: true,
                            //redirectUri: integration.data?.node?.redirectUri,
                        },
                        success: function (files: any) {
                            resolve(files);
                        },
                        cancel: function () {
                            props?.onClose();
                            reject(false);
                        },
                        error: function (e: any) {
                            //alert(JSON.stringify(e))
                            props?.onClose();
                            reject(e);
                        }
                    };
                    const windowObj: any = window;
                    if (windowObj && windowObj?.OneDrive) {
                        windowObj?.OneDrive.open(odOptions);
                    }
                });


                const finalFiles = files.value.map((item: any, index: number) => {
                    return item?.id;
                })

                //alert(JSON.stringify(finalFiles));

                setShowPopup(true)
                setLoadingFiles(true);
                props?.cdnIsImportedFiles(true);
                try {
                    for (let i = 0; i < finalFiles?.length; i++) {
                        const fileId = finalFiles[i];
                        const fileDetailsResponse: any = await cdnservices.get(`/onedrive/user/files/details/${fileId}`).then((response: AxiosResponse) => {
                            return response;
                        }).catch((error: AxiosError) => {
                            return error;
                        });
                        const fileDetails: any = fileDetailsResponse?.data?.node?.file;
                        //alert(fileDetails?.size)

                        const file: any = await toDataUrlCDNOneDrive(fileId, fileDetails?.name, fileDetails?.size);

                        finalFiles[i] = {
                            //...finalFiles[i],
                            id: fileDetails.id,
                            filename: fileDetails?.name,
                            name: fileDetails?.name,
                            size: fileDetails?.size,
                            type: fileDetails?.file?.mimeType,
                            mimeType: fileDetails?.file?.mimeType,
                            isShared: false,
                            file: undefined,
                            source: "one-drive",
                            fileSize: formatBytes(file.sizeBytes),
                            index: i
                        }

                        finalFiles[i].file = file;
                        finalFiles[i].fileSize = fileDetails?.size;
                        finalFiles[i].isUploaded = false;
                        finalFiles[i].fileType = fileDetails?.mimeType;
                    }
                    console.warn("DOWNLOADED FROM SERVER FILE:::", finalFiles);
                    props?.cdnSetPickerFiles({
                        files: finalFiles,
                        importedSource: "one-drive"
                    });
                    props?.onImportFilesSelected();
                } catch (error) {
                    toast.error(t("POPUPS.CDN.ONEDRIVE_IMPORT_ACCOUNT_PROBLEM"))
                }
                props?.onClose();


            },
            startIntegration: async () => {
                await integrateWithOneDrivePicker()
            }
        },
        sharepoint: {
            startPicker: async () => {
                let integration: any = null;
                try {
                    integration = await api.post("/users/integrations/sharepoint/token");
                    setIntegrationData(integration.data?.node);
                    setIsIntegrated(true);
                } catch (error) {
                    return actions?.sharepoint?.startIntegration();
                }
                const files = await new Promise<any>((resolve, reject) => {
                    var odOptions: any = {
                        clientId: integration?.data?.node?.clientId,
                        action: "query",
                        multiSelect: true,
                        openInNewWindow: false,
                        //viewTypes: "files",
                        advanced: {
                            //endpointHint: "api.sharepoint.com",
                            accessToken: integration.data?.node?.accessToken,
                            endpointHint: integration.data?.node?.endPointHint + "/Shared%20Documents",
                            //endpointHint: "https://jetlexa.sharepoint.com/shared%20documents/",
                            //endpointHint: "api.sharepoint.com",
                            //loginHint: 'https://graph.microsoft.com',
                            //"https://graph.microsoft.com/v1.0/",
                            //redirectUri: window.location.origin + "/user/microsoft/onedrive",
                            //isConsumerAccount: true,
                            //redirectUri: integration.data?.node?.redirectUri,
                        },
                        success: function (files: any) {
                            resolve(files);
                        },
                        cancel: function () {
                            props?.onClose();
                            reject(false);
                        },
                        error: function (e: any) {
                            //alert(JSON.stringify(e))
                            props?.onClose();
                            reject(e);
                        }
                    };
                    const windowObj: any = window;
                    if (windowObj && windowObj?.OneDrive) {
                        windowObj?.OneDrive.open(odOptions);
                    }
                });


                const finalFiles = files.value.map((item: any, index: number) => {
                    return item?.id;
                })

                //alert(JSON.stringify(finalFiles));

                setShowPopup(true)
                setLoadingFiles(true);
                props?.cdnIsImportedFiles(true);
                try {
                    for (let i = 0; i < finalFiles?.length; i++) {
                        const fileId = finalFiles[i];
                        const fileDetailsResponse: any = await cdnservices.get(`/sharepoint/user/files/details/${fileId}`).then((response: AxiosResponse) => {
                            return response;
                        }).catch((error: AxiosError) => {
                            return error;
                        });
                        const fileDetails: any = fileDetailsResponse?.data?.node?.file;
                        //alert(fileDetails?.size)

                        const file: any = await toDataUrlCDNSharePoint(fileId, fileDetails?.name, fileDetails?.size);

                        finalFiles[i] = {
                            //...finalFiles[i],
                            id: fileDetails.id,
                            filename: fileDetails?.name,
                            name: fileDetails?.name,
                            size: fileDetails?.size,
                            type: fileDetails?.file?.mimeType,
                            mimeType: fileDetails?.file?.mimeType,
                            isShared: false,
                            file: undefined,
                            source: "share-point",
                            fileSize: formatBytes(file.sizeBytes),
                            index: i
                        }

                        finalFiles[i].file = file;
                        finalFiles[i].fileSize = fileDetails?.size;
                        finalFiles[i].isUploaded = false;
                        finalFiles[i].fileType = fileDetails?.mimeType;
                    }
                    console.warn("DOWNLOADED FROM SERVER FILE:::", finalFiles);
                    props?.cdnSetPickerFiles({
                        files: finalFiles,
                        importedSource: "share-point"
                    });
                    props?.onImportFilesSelected();
                } catch (error) {
                    toast.error(t("POPUPS.CDN.ONEDRIVE_IMPORT_ACCOUNT_PROBLEM"))
                }
                props?.onClose();


            },
            startIntegration: async () => {
                await integrateWithSharePointPicker()
            }
        }
    }

    const onClickSource = async (source: Source) => {
        setSourceClicked(source?.id);
    }

    return (
        <ScreenModal
            show={showPopup && props?.show}
            onClose={() => props?.onClose()}
            renderHeader={(<h4>
                {t("POPUPS.CDN.FROM_CLOUD")}
            </h4>
            )}
            size="lg"
            renderFooter={(
                <div></div>
            )}>
            <div className="cdn-import-popup">
                {!loadingFiles && <div className="cdn-import-title">
                    {t("POPUPS.CDN.FROM_CLOUD_TITLE")}
                </div>}
                <div className="cdn-import-group">
                    {loadingFiles && (<div className="preparing-files">
                        <DefaultLoader size={80} />
                        <div className="preparing-files-description">
                            {t("POPUPS.CDN.IMPORTING")}
                        </div>
                    </div>)}
                    {!loadingFiles && Sources.map((source: Source, index: number) => (
                        <div className="cdn-import-source-button-container" key={"import_sources_" + index}>
                            <Button
                                disabled={!isNull(sourceClicked)}
                                onClick={(e) => {
                                    onClickSource(source)
                                }} className="cdn-import-source-button">
                                {
                                    (sourceClicked === source?.id) ? <DefaultLoader /> : <div>
                                        <img className="icon" src={source.icon} alt={source.name} />
                                        {source.name}
                                    </div>
                                }
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
        </ScreenModal>

    )
}

export default connect(mapStateToProps, mapActionsTopProps)(CDNImportSourceSelect);