import { combineReducers } from 'redux';

import Components from '@redux/components/index.reducer';
import Companies from '@redux/companies/index.reducer';
import CompaniesIntegrations from '@redux/companies-integrations/index.reducer';
import Departments from '@redux/departments/index.reducer';
import Roles from '@redux/roles/index.reducer';
import Nominations from '@redux/nominations/index.reducer';
import Files from '@redux/files/index.reducer';
import Users from '@redux/users/index.reducer';
import UsersIntegrations from '@redux/users-integrations/index.reducer';
import Layout from '@redux/layout/reducers';
import ContractsReducer from "@redux/contract/index.reducer";
import DashboardReducer from "@redux/dashboard/index.reducer";
import CustomForms from "@redux/custom-forms/index.reducer";
import Approve from "@redux/approve/index.reducer";
import ProcessApprovalReducer from "@redux/process-approval/index.reducer";

export default combineReducers({
    //  Auth,
    dashboard: DashboardReducer,
    components: Components,
    companies: Companies,
    companiesIntegrations: CompaniesIntegrations,
    departments: Departments,
    roles: Roles,
    nominations: Nominations,
    files: Files,
    users: Users,
    usersIntegrations: UsersIntegrations,
    Layout: Layout,
    contracts: ContractsReducer,
    customForms: CustomForms,
    approve: Approve,
    processApproval: ProcessApprovalReducer
});
