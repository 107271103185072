import React, { useEffect, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TGenericResponse } from "@redux/interfaces/index.interfaces";
import { useHistory } from 'react-router-dom';
import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { toggleGenericErrorModalAction } from '@redux/components/index.actions';
import './GenerateErrorModal.scss';
import { MdError } from 'react-icons/md';
import { BiMessageAltError } from 'react-icons/bi';


export const toggleGenericErrorModal = (dispatch: any, errorResponseObj: TGenericResponse) => {

    dispatch({
        type: ActionDispatchTypes.TOGGLE_GENERIC_ERROR_MODAL,
        payload: errorResponseObj
    });
}

const MapStateToProps = (state: any) => ({
    genericErrorModal: state.components.genericErrorModal
})

const MapActionsToProps = {
    toggleGenericErrorModal: toggleGenericErrorModalAction
}

const customErrorCodes = ["UNAUTHORIZED_ROLE", "UNAUTHORIZED_CONTRACT"];

const GenerateErrorModal: React.FunctionComponent = (props: any) => {
    const handleToggle = () => {
        if (props?.genericErrorModal?.code === "UNAUTHORIZED_ROLE") {
            history.push('/');
        }
        if (props?.genericErrorModal?.code === "UNAUTHORIZED_CONTRACT") {
            history.goBack();
        }
        props.toggleGenericErrorModal()
    };
    const history = useHistory();
    return (
        <>
            <Modal
                show={props?.genericErrorModal?.show}
                onHide={handleToggle}
                centered={customErrorCodes.includes(props?.genericErrorModal?.code) ? true : false}
                //backdrop="static"
                backdropClassName={customErrorCodes.includes(props?.genericErrorModal?.code) ? "backdrop-opaque-1" : "backdrop-opaque-2"}
                className="redux-generic-error-modal"
                keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {"Something is wrong" || props?.genericErrorModal?.code}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ marginBottom: 15 }}>
                        <MdError style={{ color: "red" }} size={110} />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {props?.genericErrorModal?.message || 'Something went wrong. Please refresh your page and try again.'}
                    </div>
                </Modal.Body>
                <Modal.Footer >
                    <Button variant="primary" onClick={handleToggle}>
                        Close Popup
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default connect(MapStateToProps, MapActionsToProps)(GenerateErrorModal);