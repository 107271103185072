import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

/* import translationEs from './locales/es/translation.json'; */
import translationEn from '@locales/en.locale';
import translationTr from '@locales/tr.locale';

//translations
const resources = {
    /* es: {
        translation: translationEs,
    }, */
    en: {
        translation: translationEn,
    },
    tr: {
        translation: translationTr,
    }
};


const translationConfig = (currentLocale: string) => {
    i18n.use(detector)
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: currentLocale || "en",
            fallbackLng: 'en', // use en if detected lng is not available

            //keySeparator: false, // we do not use keys in form messages.welcome

            interpolation: {
                escapeValue: false, // react already safes from xss
            },
        });
}



export default translationConfig;
