import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import { MdFormatAlignLeft } from "react-icons/md";
import { MdFormatAlignCenter } from "react-icons/md";
import { MdFormatAlignRight } from "react-icons/md";
import { MdFormatAlignJustify } from "react-icons/md";

export default function Align(props: any) {

    const editor = useSlate()

    return (
        <Dropdown className='p-0 m-0'>
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip>
                    Align
                </Tooltip>
            }>
                <Dropdown.Toggle

                    variant='link'
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                    }}
                    as={Button}>

                    <MdFormatAlignLeft size={18} />
                    <FaChevronDown style={{ marginLeft: '3px' }} size={10} />



                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='slate-toolbar-dd-menu'
            >
                <Dropdown.Item
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                        toggleBlock(editor, 'left')
                    }}
                    active={isBlockActive(editor, 'left')}
                > <MdFormatAlignLeft size={18} /></Dropdown.Item>
                <Dropdown.Item
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                        toggleBlock(editor, 'center')
                    }}
                    active={isBlockActive(editor, 'center')}
                > <MdFormatAlignCenter size={18} /></Dropdown.Item>
                <Dropdown.Item
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                        toggleBlock(editor, 'right')
                    }}
                    active={isBlockActive(editor, 'right')}
                > <MdFormatAlignRight size={18} /></Dropdown.Item>
                <Dropdown.Item
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                        toggleBlock(editor, 'justify')
                    }}
                    active={isBlockActive(editor, 'justify')}
                > <MdFormatAlignJustify size={18} /></Dropdown.Item>

            </Dropdown.Menu>
        </Dropdown>
    )
}
