import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Button, Alert, Navbar, Nav, Card, Tab, ListGroup, FloatingLabel, Offcanvas, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toggleCreateProcessModal, toggleNewProcessModal, toggleFillFormModal } from "@redux/components/index.actions";
import { useTranslation } from "react-i18next";
import "./index.scss";
import FeatherIcons from "feather-icons-react";
import { FaFileUpload, FaPlus } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
/* import icons/verified.png */
import verified from "./icons/verified.png";
import writing from "./icons/writing.png";
import DocumentIcon from "./components/DocumentIcon";
import EditFileIcon from "./components/EditFileIcon";
import { HiMiniClipboardDocumentList, HiDocumentMagnifyingGlass } from "react-icons/hi2";
import { BsCardList, BsCloudUpload, BsFiles } from "react-icons/bs";
import apiInstance from "@clients/api.client";
import SlateEditor from "@components/SlateEditor";
import Loader from "@components/Loader";
import ContractsSelect from '@components/SelectsPaginated/Contracts';
import { toast } from "react-toastify";




const mapStateToProps = (state: any) => ({
  companies: state.companies,
  contracts: state.contracts,
  components: state.components,
  customForms: state.customForms
});
const mapActionsToProps = {
  toggleFillFormModal: toggleFillFormModal
};

const CreateProcessModal = (props: any) => {
  const { t } = useTranslation();

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSaveAsFileModal, setShowSaveAsFileModal] = useState<boolean>(false);
  const [contract, setContract] = useState<any>(null);
  const [fileTitle, setFileTitle] = useState<string>("");

  function Icon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 32">
        <path d="M24.8 0H7.2A3.176 3.176 0 004 3.143v25.714A3.176 3.176 0 007.2 32h17.6a3.176 3.176 0 003.2-3.143V3.143A3.176 3.176 0 0024.8 0zM12.254 6.201A3.859 3.859 0 1117.86 10.7a1.815 1.815 0 00-.926 1.557v.1a1 1 0 01-2 0v-.1a3.82 3.82 0 011.926-3.29 1.855 1.855 0 00.88-2.06 1.834 1.834 0 00-1.355-1.355 1.857 1.857 0 00-2.056.871 1.798 1.798 0 00-.166.375 1 1 0 01-1.91-.597zM16.93 15.5a.711.711 0 01-.02.2 1.187 1.187 0 01-.05.18l-.09.18a1.768 1.768 0 01-.13.15 1.015 1.015 0 01-.71.29.837.837 0 01-.38-.08.992.992 0 01-.32-.21 1.768 1.768 0 01-.13-.15l-.09-.18-.06-.18a1.528 1.528 0 01-.02-.2 1.017 1.017 0 01.3-.71 1.034 1.034 0 011.41 0 1.006 1.006 0 01.29.71zM23 28H9a1 1 0 010-2h14a1 1 0 010 2zm0-4H9a1 1 0 010-2h14a1 1 0 010 2zm0-4H9a1 1 0 010-2h14a1 1 0 010 2z"></path>
      </svg>
    );
  }


  useEffect(() => {

    console.log('MOUNTED', props?.components?.fillFormModal)
    setLoading(true)
    apiInstance.get(`editor/fill/template/${props?.components?.fillFormModal?.data?._id}`).then((res: any) => {
      console.log('RES', res)
      setData(res.data)
      setFileTitle(res.data?.editorDetail?.title)
      setLoading(false)
    }).catch((err: any) => {
      setLoading(false)
      console.log('ERR', err)
    });

    return () => {
      console.log('UNMOUNTED')
    }
  }, [])
  /* 
  "addDocument",
              "addAssignUser",
              "addRecord",
  */


  const [documentCustomFields, setDocumentCustomFields] = useState<any>([]);


  useEffect(() => {
    console.log('data?.customFields', data?.customFields)
    setDocumentCustomFields(data?.customFields?.map((field: any) => {
      return ({
        ...field,
        value: ""
      })
    }))
  }, [data?.customFields])

  const documentCustomFieldsSetter = (field_id: string, value: any) => {
    setDocumentCustomFields(documentCustomFields.map((field: any) => {
      if (field._id === field_id) {
        return ({
          ...field,
          value: value
        })
      }
      return field;
    }))
  }

  const [isAnyEmpty, setIsAnyEmpty] = useState<boolean>(false)
  const [fieldCounter, setFieldCounter] = useState<any>({
    empty: 0,
    total: 0
  });

  useEffect(() => {
    // Check if there exists any field where isOptional=true and value is empty in documentCustomFields
    const isAnyEmpty = documentCustomFields?.some((field: any) => field.isOptional === true && (!field.value || field.value === ""));

    // Initialize counts
    let emptyCount = 0;
    let totalCount = 0;

    // Iterate over documentCustomFields to count empty and total fields
    documentCustomFields?.forEach((field: any) => {
      totalCount++; // Increment total for each field
      if (!field.value || field.value === "") {
        emptyCount++; // Increment empty if the field's value is empty
      }
    });

    // Update fieldCounter state with the new counts
    setFieldCounter({
      empty: emptyCount,
      total: totalCount
    });

    setIsAnyEmpty(isAnyEmpty);
  }, [documentCustomFields]);

  return (
    <>

      {showSaveAsFileModal && (
        <Modal
          show={showSaveAsFileModal}
          onHide={() => {
            setShowSaveAsFileModal(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="save-as-file-header">
            <div className="save-as-file-header-content">

              <div className="save-as-file-header-content-icon">
                <FeatherIcons icon="file" size="30" color="#255957" />
              </div>
              <span className="save-as-file-header-content-title">Save document</span>

            </div>
          </Modal.Header>
          <Modal.Body>

            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="File Name"
                  onChange={(e: any) => {
                    setFileTitle(e.target.value)
                  }}
                  value={fileTitle} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Choose Contract</Form.Label>
                <ContractsSelect
                  onValueSubmit={(value: any) => {
                    setContract(value);
                  }}


                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => {
              setShowSaveAsFileModal(false);

            }}>
              Close
            </Button>
            <Button
              onClick={() => {

                apiInstance.post(`editor/saveAsFile`, {
                  contract_id: contract || null,
                  title: fileTitle || new Date().toLocaleDateString(),
                  version: data?.lastVersion?.fragment ? JSON.parse(data?.lastVersion?.fragment) : null,
                  header: data?.lastHeaderVersion?.fragment ? JSON.parse(data?.lastHeaderVersion?.fragment) : null,
                  footer: data?.lastFooterVersion?.fragment ? JSON.parse(data?.lastFooterVersion?.fragment) : null,
                }).then((res: any) => {
                  toast.success('File saved successfully')
                  setShowSaveAsFileModal(false);
                  props?.toggleFillFormModal({
                    show: false,
                    data: null
                  })
                }).catch((err: any) => {
                  console.log('err', err)
                })
              }}
              variant="primary">Save</Button>
          </Modal.Footer>
        </Modal>
      )}

      <Offcanvas show={props?.components?.fillFormModal?.show}
        onHide={props?.toggleFillFormModal}

      >
        <Offcanvas.Header className="fill-form-header">
          <div className="w-100 fill-form-header-content d-flex align-items-center justify-content-between">
            <div className="fill-form-header-left d-flex align-items-center justify-content-center gap-2">
              <div>
                <Button
                  size="lg"
                  className="d-flex align-items-center justify-content-center"
                  onClick={(e: any) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                    props?.toggleFillFormModal({
                      show: false,
                      data: null
                    })

                  }} variant="link">
                  <FeatherIcons icon="x" size="20" />
                </Button>
              </div>
              <div>
                <h4 style={{ fontWeight: 500 }} className="mb-1">{props?.components?.fillFormModal?.data?.title}</h4>
                <span style={{
                  fontSize: '12px',
                  background: '#f4f0ed',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  color: '#255957',
                  border: '1px solid #255957',
                  marginTop: '4px'


                }}><FeatherIcons icon="tag" size="14" color="#255957" /> {data?.editorDetail?.contractType?.title}</span>
              </div>
            </div>
            <div className="fill-form-header-center"></div>
            <div className="fill-form-header-right">
              <Button
                size="lg"
                disabled={isAnyEmpty}
                className="d-flex align-items-center justify-content-center"
                onClick={(e: any) => {
                  e?.preventDefault();
                  e?.stopPropagation();

                  setShowSaveAsFileModal(true);



                }} variant="primary">
                Save and Close
              </Button>
            </div>
          </div>
          <div className="progress"></div>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0">
          <div className="progress-bar"
            style={{
              height: '4px',
              background: '#255957',
              width: `${fieldCounter.empty === 0 ? 100 : (100 - (fieldCounter.empty / fieldCounter.total) * 100)}%`,
              top: 0,
              left: 0,
              zIndex: 9999
            }}
          ></div>

          <div className="fill-form-body">
            <div className="fill-form-body-left">

            </div>
            <div className="fill-form-body-right">
              {loading
                ? <div>
                  <Loader />
                </div> : (
                  <SlateEditor
                    autoSave={false}
                    fillForm={true}
                    readOnly={true}
                    toolbar={false}
                    smartFields={[]}
                    customFields={[]}
                    defaultFields={[]}
                    content={data?.lastVersion?.fragment || null}
                    header={data?.lastHeaderVersion?.fragment || null}
                    footer={data?.lastFooterVersion?.fragment || null}
                    setData={setData}
                    data={data}
                    exportDocx={() => {
                      console.log('exportDocx')
                    }}
                    documentCustomFieldsSetter={documentCustomFieldsSetter}
                  />
                )}

            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas >


    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(CreateProcessModal);
