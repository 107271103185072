import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import _ from 'lodash';

const initialState = {
    genericErrorModal: {
        show: false,
        code: null,
        message: null,
        status: null
    },
    genericSuccessModal: {
        show: false,
        code: null,
        message: null,
        status: null
    },
    showNewCompanyModal: false,
    showAddNewUserToCompanyModal: false,
    showCompanySwitchModal: false,
    showUploadFileModal: false,
    showFilesVersions: false,
    newCompanyData: {
        isNewCompany: null,
        companyData: {
            title: null,
            website: 0,
            description: 0,
            inviteCode: null,
        },
        surveyData: {
            role: null,
            whatToDoWithSystem: null,
            size: null,
            howManyPeople: null
        }
    },
    newCustomFieldModal: false,
    newContractCompaniesParty: null,
    newPipelineModal: false,
    editCustomFormsModal: false,
    newContractTypeModal: false,
    newStatusModal: false,
    newContractCompaniesModal: false,
    newContractModal: false,
    googleDocsIFrameModal: false,
    googleFilePermissionModal: false,
    templateGoogleDocsIFrameModal: false,
    pageTitle: "",
    contractOverviewModal: false,
    filesPdfViewerModal: {
        show: false,
        file: {},
        url: '',
        isFetching: false,
    },
    approvalTemplateModal: false,
    approvalProcessFileModal: false,
    approvalProcessFileModalV2: {
        show: false,
    },
    createProcessModal: {
        show: false,
        data: null,
    },
    fillFormModal: {
        show: false,
    },
    editorModal: {
        show: false,
        data: null,
    },
    newProcessModal: {
        show: false,
        pageSettings: {
            addRecord: {
                modalSize: 'xl'
            },
            addDocument: {
                modalSize: 'xl'
            },
            addAssignUser: {
                modalSize: 'sm'
            }
        },
        pages: [
            "addRecord",
        ],
        currentPage: "addRecord",
        extraProps: {},
    },
    sharePointFilePermissionModal: false,
    sharePointSelectedFileForPermission: null,
    oneDriveFilePermissionModal: false,
    oneDriveSelectedFileForPermission: null,
    newAutomationModal: false,
    automationInitialPage: 'list',
}

const ComponentsReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {

        case ActionDispatchTypes.LOGOUT:
            return {
                ...initialState
            }
        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_ERROR_MODAL:
            return {
                ...state,
                genericErrorModal: {
                    show: !state.genericErrorModal?.show,
                    code: !state.genericErrorModal?.show
                        ? action.payload?.code
                        : null,
                    message: !state.genericErrorModal?.show
                        ? action.payload?.message
                        : null,
                    status: !state.genericErrorModal?.show
                        ? action.payload?.status
                        : null
                }
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_SUCCESS_MODAL:
            return {
                ...state,
                genericSuccessModal: {
                    show: !state.genericSuccessModal?.show,
                    code: !state.genericSuccessModal?.show
                        ? action.payload?.code
                        : null,
                    message: !state.genericSuccessModal?.show
                        ? action.payload?.message
                        : null,
                    status: !state.genericSuccessModal?.show
                        ? action.payload?.status
                        : null
                }
            }

        case ActionDispatchTypes.TOGGLE_CREATE_PROCESS_MODAL:
            console.log('TOGGLE_CREATE_PROCESS_MODAL')
            return {
                ...state,
                createProcessModal: {
                    ...state.createProcessModal,
                    show: _.isNull(action?.payload?.show) ? !state.createProcessModal?.show : action?.payload?.show
                }
            }
        case ActionDispatchTypes.TOGGLE_FILL_FORM_MODAL:
            console.log('TOGGLE_FILL_FORM_MODAL')
            return {
                ...state,
                fillFormModal: {
                    ...state.fillFormModal,
                    show: _.isNull(action?.payload?.show) ? !state.createProcessModal?.show : action?.payload?.show,
                    data: _.isNull(action?.payload?.data) ? state.createProcessModal?.data : action?.payload?.data,
                }
            }
        case ActionDispatchTypes.TOGGLE_EDITOR_MODAL:
            console.log('TOGGLE_EDITOR_MODAL')
            return {
                ...state,
                editorModal: {
                    ...state.editorModal,
                    show: _.isNull(action?.payload?.show) ? !state.createProcessModal?.show : action?.payload?.show,
                    data: _.isNull(action?.payload?.data) ? state.createProcessModal?.data : action?.payload?.data,
                }
            }
        case ActionDispatchTypes.TOGGLE_NEW_PROCESS_MODAL:
            console.log('TOGGLE_NEW_PROCESS_MODAL')
            return {
                ...state,
                newProcessModal: {
                    ...state.newProcessModal,
                    show: _.isNull(action?.payload?.show) ? !state.newProcessModal?.show : action?.payload?.show,
                    pages: _.isNull(action?.payload?.pages) ? state.newProcessModal?.pages : action?.payload?.pages,
                    currentPage: _.isNull(action?.payload?.currentPage) ? state.newProcessModal?.currentPage : action?.payload?.currentPage,
                    extraProps: _.isNull(action?.payload?.extraProps) ? state.newProcessModal?.extraProps : action?.payload?.extraProps,
                    openOverview: _.isNull(action?.payload?.openOverview) ? state.newProcessModal?.openOverview : action?.payload?.openOverview,
                    refreshContractList: _.isNull(action?.payload?.refreshContractList) ? state.newProcessModal?.refreshContractList : action?.payload?.refreshContractList,
                }
            }
        case ActionDispatchTypes.TOGGLE_NEW_COMPANY_MODAL:
            return {
                ...state,
                showNewCompanyModal: _.isNull(action?.payload)
                    ? !state.showNewCompanyModal
                    : action?.payload
            }
        case ActionDispatchTypes.SET_NEW_COMPANY_DATA:
            return {
                ...state,
                newCompanyData: {
                    ...state.newCompanyData,
                    [action.payload?.key]: action.payload?.value
                }
            }
        case ActionDispatchTypes.DELETE_NEW_COMPANY_DATA:
            return {
                ...state
            }
        case ActionDispatchTypes.CREATE_COMPANY_SUCCESSFUL:
            return {
                ...state,
                showAddNewUserToCompanyModal: true,
                showNewCompanyModal: false,
                newCompanyData: {
                    ...initialState?.newCompanyData
                }
            }
        case ActionDispatchTypes.TOGGLE_ADD_USER_TO_COMPANY_MODAL:
            return {
                ...state,
                showAddNewUserToCompanyModal: _.isNull(action?.payload)
                    ? !state.showAddNewUserToCompanyModal
                    : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_COMPANY_SWITCH_MODAL:
            return {
                ...state,
                showCompanySwitchModal: _.isNull(action?.payload)
                    ? !state.showCompanySwitchModal
                    : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_CUSTOM_FIELD_MODAL:
            return {
                ...state,
                newCustomFieldModal: _.isNull(action?.payload) ? !state.newCustomFieldModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_PIPELINE_MODAL:
            return {
                ...state,
                newPipelineModal: _.isNull(action?.payload) ? !state.newPipelineModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_TYPE_MODAL:
            return {
                ...state,
                newContractTypeModal: _.isNull(action?.payload) ? !state.newContractTypeModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_EDIT_CUSTOM_FORMS_MODAL:
            return {
                ...state,
                editCustomFormsModal: _.isNull(action?.payload) ? !state.editCustomFormsModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_STATUS_MODAL:
            return {
                ...state,
                newStatusModal: _.isNull(action?.payload) ? !state.newStatusModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_COMPANIES_MODAL:
            return {
                ...state,
                newContractCompaniesModal: _.isNull(action?.payload) ? !state.newContractCompaniesModal : action?.payload,
                newContractCompaniesParty: action?.partiesSide
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_CONTRACT_MODAL:
            return {
                ...state,
                newContractModal: _.isNull(action?.payload) ? !state.newContractModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_UPLOAD_FILES_MODAL:
            return {
                ...state,
                showUploadFileModal: action?.payload
            }

        case ActionDispatchTypes.TOGGLE_FILES_VERSION_MODAL:
            return {
                ...state,
                showFilesVersions: state?.showFilesVersions ? false : true
            }

        case ActionDispatchTypes.SET_PAGE_TITLE:
            return {
                ...state,
                pageTitle: action?.payload?.title || '',
                menu: action?.payload?.menu || '',
                activeKey: action?.payload?.activeKey || '',
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_GOOGLE_DOCS_IFAME_MODAL:
            return {
                ...state,
                googleDocsIFrameModal: _.isNull(action?.payload) ? !state.googleDocsIFrameModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_GOOGLE_FILE_PERMISSION_MODAL:
            return {
                ...state,
                googleFilePermissionModal: _.isNull(action?.payload) ? !state.googleFilePermissionModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_SHAREPOINT_FILE_PERMISSION_MODAL:
            return {
                ...state,
                sharePointFilePermissionModal: _.isNull(action?.payload) ? !state.sharePointFilePermissionModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_ONEDRIVE_FILE_PERMISSION_MODAL:
            return {
                ...state,
                oneDriveFilePermissionModal: _.isNull(action?.payload) ? !state.oneDriveFilePermissionModal : action?.payload
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_TEMPLATE_GOOGLE_DOCS_IFAME_MODAL:
            return {
                ...state,
                templateGoogleDocsIFrameModal: _.isNull(action?.payload) ? !state.googleDocsIFrameModal : action?.payload
            }
        case ActionDispatchTypes.TOGGLE_GENERIC_CONTRACT_OVERVIEW_MODAL:
            return {
                ...state,
                contractOverviewModal: _.isNull(action?.payload) ? !state.contractOverviewModal : action?.payload
            }
        case ActionDispatchTypes.CLOSE_CDN_POPUP:
            return {
                ...state,
                showUploadFileModal: false
            }

        case ActionDispatchTypes?.TOGGLE_FILES_PDF_VIEWER:
            //window.location.href = "/";
            return {
                ...state,
                filesPdfViewerModal: { ...action?.payload }
            }

        case ActionDispatchTypes?.TOGGLE_APPROVAL_TEMPLATE_MODAL:
            //window.location.href = "/";
            return {
                ...state,
                approvalTemplateModal: _.isNull(action?.payload) ? !state.approvalTemplateModal : action?.payload
            }

        case ActionDispatchTypes?.TOGGLE_APPROVAL_PROCESS_MODAL:
            //window.location.href = "/";
            return {
                ...state,
                approvalProcessFileModal: _.isNull(action?.payload) ? !state.approvalProcessFileModal : action?.payload
            }
        case ActionDispatchTypes?.TOGGLE_APPROVAL_PROCESS_MODAL_V2:
            //window.location.href = "/";
            return {
                ...state,
                approvalProcessFileModalV2: {
                    ...state.approvalProcessFileModalV2,
                    show: _.isNull(action?.payload?.show) ? !state.approvalProcessFileModalV2?.show : action?.payload?.show,
                }
            }

        case ActionDispatchTypes.TOGGLE_GENERIC_ADD_AUTOMATION_MODAL:
            return {
                ...state,
                newAutomationModal: _.isNull(action?.payload) ? !state.newAutomationModal : action?.payload,
                automationInitialPage: action?.initialPage || 'list',
            }


        default:
            return state;
    }
}

export default ComponentsReducer;