import React, { useEffect, useMemo, useRef, useState } from "react";

import QuillEditor from "@components/QuillEditor";
import { Accordion, Button, Card, Col, Container, Dropdown, DropdownButton, Form, ListGroup, Modal, Row, Table } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { setFileData, editTemplate, createDocumentFromTemplate, addFilesToContract } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal, toggleGenericAddContractCompaniesModal, toggleGenericAddContractModal, toggleNewProcessModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";

import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus, FiLink } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import ContractOverviewRightPanel from "@popups/ContractOverview/components/ContractOverviewRightPanel";
import { BsLock, BsUnlock, BsShieldLock } from "react-icons/bs";
import { MdLock, MdLockOpen, MdLockOutline } from "react-icons/md";
import ContractsSelect from "@components/SelectsPaginated/Files";
import {
    getContractById, getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    editKeyTerms,
    resetContractOverview,
    addRecordsAsSubRecordToContract,
    getFullProcessData
} from "@redux/contract/index.actions";
import { evaluate } from "mathjs";

import Loader, { DefaultLoader } from "@components/Loader";
import moment from "moment";
import _, { set } from "lodash";
import './index.scss';
import Select from "react-select";
import ProcessOverviewCustomFieldArea, { TCustomComponent } from "@components/ProcessOverviewCustomFieldArea";
import ParentlessContracts from "@components/SelectsPaginated/ParentlessContracts";
import { ref } from "yup";
import CompaniesSelectInternal from "@components/SelectsPaginated/Companies/internal"
import CompaniesSelectExternal from "@components/SelectsPaginated/Companies/external"

const mapStateToProps = (state: any) => ({
    components: state?.components,
    companies: state?.companies,
    contracts: state?.contracts,
    process: state?.contracts?.processOverview,
    files: state?.files
});
const mapActionsToProps = {
    toggleGenericContractOverviewModal,
    getContractById,
    getContractTypes,
    getCompanyStatuses,
    getCompanyPipelines,
    editContractDetails,
    editKeyTerms,
    resetContractOverview,
    addFilesToContract,
    toggleGenericAddContractCompaniesModal,
    addRecordsAsSubRecordToContract,
    toggleGenericAddContractModal,
    toggleNewProcessModal,
    getFullProcessData
};



const Dates = (props: any) => {
    const { t } = useTranslation();

    const editedContract = props?.process

    const [contractCompaniesInternal, setContractCompaniesInternal] = useState([])
    const [contractCompaniesExternal, setContractCompaniesExternal] = useState([])

    const [areYouSureSaveModal, setAreYouSureSaveModal] = useState(false);
    const [data, setData] = useState<{
        _id: string
        title: string
        description?: string
        startDate?: string
        endDate?: string
        renewalDate?: string
        effectiveDate?: string
        signedDate?: string
        status: string | undefined
        pipeline?: string
        type: string
        private: boolean
        parentContract?: string
    }>({
        _id: editedContract?._id,
        title: editedContract?.title,
        description: editedContract?.description,
        startDate: editedContract?.startDate,
        endDate: editedContract?.endDate,
        renewalDate: editedContract?.renewalDate,
        effectiveDate: editedContract?.effectiveDate,
        signedDate: editedContract?.signedDate,
        status: editedContract?.status?._id,
        pipeline: editedContract?.pipeline?._id,
        type: editedContract?.type?._id,
        private: editedContract?.private,
        parentContract: editedContract?.parentContract?._id
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsData, setCustomFieldsData] = useState<{}>({});
    const [selectedContractType, setSelectedContractType] = useState<any>(editedContract?.type);
    const [newPipeline, setNewPipeline] = useState(null);
    const [newStatus, setNewStatus] = useState(editedContract?.status?._id);
    const [error, setError] = useState("");

    const [selectedPipeline, setSelectedPipeline] = useState<any>({
        _id: editedContract?.pipeline?._id,
        statuses: editedContract?.pipeline?.statuses,
    });

    const [firstLoad, setFirstLoad] = useState(true);


    const handleInput = (target: any) => {

        if (target?.name === 'title') {
            console.log('title', target?.value)
        }
        setData({ ...data, ...{ [target?.name]: target?.value } })
        handleSaveButtonToggle(true);
    }



    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const requestData: {
            _id: string
            startDate?: string
            endDate?: string
            renewalDate?: string
            effectiveDate?: string
            signedDate?: string

        } = {
            _id: data?._id,
            startDate: data?.startDate,
            endDate: data?.endDate,
            renewalDate: data?.renewalDate,
            effectiveDate: data?.effectiveDate,
            signedDate: data?.signedDate,
        }





        if (!requestData?._id) {
            toast.warning(t("CONTRACTS.EDIT_CONTRACT.SOMETHING_IS_WRONG"))
            setLoading(false);
            return;
        }



        await props.editKeyTerms(requestData).then((res: any) => {

            const index = res?.contract?.type?.statuses?.findIndex((item: any) => item?._id == res?.contract?.status?._id);
            props?.setActiveIndex(index);
            setLoading(false);
            toast.success(t("NEW_PROCESS_MODAL.EDIT_KEY_TERMS_SUCCESS"))
        }).catch((error: any) => {
            console.log('error', error)
            setLoading(false);
        })

        handleSaveButtonToggle(false);
        setAreYouSureSaveModal(false);
        props?.setAreYouSureModal(false)
        props?.setSaveTrigger(0)


    }
    const typeDefaultValue = (): {
        label: string
        value: string
    } => {
        //let data = _.filter(props?.contracts?.types?.ContractsTypes, { _id: editedContract?.type })[0]
        return {
            label: editedContract?.type?.title,
            value: editedContract?.type?._id
        }
    }
    const statusDefaultValue = (): {
        label: string
        value: string
    } => {
        let data = _.filter(props?.contracts?.statuses, { _id: editedContract?.status })[0]
        return {
            label: data?.title,
            value: data?._id
        }
    }
    const pipelineDefaultValue = (): {
        label: string
        value: string
    } => {
        let data = _.filter(props?.contracts?.pipelines, { _id: editedContract?.pipeline })[0]
        setSelectedPipeline(data);
        return {
            label: data?.title,
            value: data?._id
        }
    }


    useEffect(() => {
        if (props?.saveTrigger > 0) {
            handleSubmit({ preventDefault: () => { } })

        } else {
            console.log('SIFIR OLDU')
        }
    }, [props?.saveTrigger])






    const [refreshStatus, setRefreshStatus] = useState(false);

    const [refrestStatusSelect, setRefreshStatusSelect] = useState(false);


    useEffect(() => {
        setTimeout(() => {
            setRefreshStatusSelect(false)
        }, 100)
    }, [refrestStatusSelect])


    const [showSaveButton, setShowSaveButton] = useState(false);

    const handleSaveButtonToggle = (value: boolean) => {
        // Ekstra bir işlem yapmak istediğiniz yer

        console.log('SAVE BUTTON TOGGLE', value)
        setShowSaveButton(value);
        props?.setAllowClose(value);
    };




    return (
        <>
            <Card style={props?.style}>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <h5>{t('NEW_PROCESS_MODAL.DATES')}</h5>

                    {(showSaveButton) && (
                        <Button
                            onClick={event => setAreYouSureSaveModal(true)}
                            disabled={loading} name="btn" value={1} variant="outline-primary" size="sm" type="submit">
                            {loading && <Loader />} {
                                t("CONTRACTS.EDIT_CONTRACT.SAVE")
                            }
                        </Button>
                    )}
                </Card.Header>
                <Card.Body>
                    <div className={`key-terms ${props?.disabled ? 'disabled-div' : ''}`}>
                        <form

                            onSubmit={event => setAreYouSureSaveModal(true)} >
                            <Container>
                                <Row>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {t("CONTRACTS.EDIT_CONTRACT.START_DATE_LABEL")}
                                            </Form.Label>
                                            <FormInput
                                                defaultValue={editedContract?.startDate ? moment(editedContract?.startDate).format('YYYY-MM-DD') : ''}
                                                disabled={loading}
                                                className="form-control form-control-xs"
                                                name="startDate"
                                                type="date"
                                                onInput={(event) => handleInput(event?.target)}
                                            />
                                        </Form.Group>

                                    </Col>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {t("CONTRACTS.EDIT_CONTRACT.END_DATE_LABEL")}
                                            </Form.Label>
                                            <FormInput
                                                defaultValue={editedContract?.endDate ? moment(editedContract?.endDate).format('YYYY-MM-DD') : ''}
                                                disabled={loading}
                                                className="form-control form-control-xs"
                                                name="endDate"
                                                type="date"
                                                onInput={(event) => handleInput(event?.target)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {t("CONTRACTS.EDIT_CONTRACT.RENEWAL_DATE_LABEL")}
                                            </Form.Label>
                                            <FormInput
                                                defaultValue={editedContract?.renewalDate ? moment(editedContract?.renewalDate).format('YYYY-MM-DD') : ''}
                                                disabled={loading}
                                                className="form-control form-control-xs"
                                                name="renewalDate"
                                                type="date"
                                                onInput={(event) => handleInput(event?.target)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {t("CONTRACTS.EDIT_CONTRACT.EFFECTIVE_DATE_LABEL")}
                                            </Form.Label>
                                            <FormInput
                                                defaultValue={editedContract?.effectiveDate ? moment(editedContract?.effectiveDate).format('YYYY-MM-DD') : ''}
                                                disabled={loading}
                                                className="form-control form-control-xs"
                                                name="effectiveDate"
                                                type="date"
                                                onInput={(event) => handleInput(event?.target)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {t("CONTRACTS.EDIT_CONTRACT.SIGNED_DATE_LABEL")}
                                            </Form.Label>
                                            <FormInput
                                                defaultValue={editedContract?.signedDate ? moment(editedContract?.signedDate).format('YYYY-MM-DD') : ''}
                                                disabled={loading}
                                                className="form-control form-control-xs"
                                                name="signedDate"
                                                type="date"
                                                onInput={(event) => handleInput(event?.target)}
                                            />
                                        </Form.Group>
                                    </Col>





                                </Row>

                            </Container>
                        </form>

                    </div>
                </Card.Body>
            </Card>
            <Modal
                contentClassName="border shadow"
                show={areYouSureSaveModal}
                onHide={() => setAreYouSureSaveModal(false)}
                centered
                size="lg"
            >
                <Modal.Header className="bg-light" closeButton>
                    <Modal.Title>{t("NEW_PROCESS_MODAL.ARE_YOU_SURE_TITLE")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("NEW_PROCESS_MODAL.CHANGES_WILL_BE_LOST")}</p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: '20px',
                        gap: '10px'

                    }}>
                        <Button
                            disabled={loading}
                            onClick={() => {
                                handleSubmit({ preventDefault: () => { } })
                            }}
                            variant="primary" className="d-flex align-items-center gap-1">
                            {t("NEW_PROCESS_MODAL.SAVE")}
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={() => {

                                setAreYouSureSaveModal(false);
                            }}
                            variant="secondary" className="d-flex align-items-center gap-1">
                            {t("NEW_PROCESS_MODAL.CANCEl")}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Dates);
