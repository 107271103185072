import React, { useState } from "react";
import { ListGroup, Badge } from "react-bootstrap";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { connect } from "react-redux";
import { getCompanyStatuses } from "@redux/contract/index.actions";
import NewPipelineStatusListItem from "@pages/pipelines/components/NewPipelineStatusListItem";
import { useTranslation } from "react-i18next";
const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
});

const mapActionsToProps = {
  getCompanyStatuses: getCompanyStatuses,
};
// task item
const NewPipelineStatusCreationList = (props: any) => {
  const { t } = useTranslation();
  const [item, setItem] = useState([
    // { title: "In Progress", _id: 1213 },
    // { title: "Complated", _id: 2321 },
  ]);

  return (
    <>
      <ListGroup as="ol" className="pt-2">
        {props?.contracts?.newPipeline?.statuses.length > 0 ? (
          props?.contracts?.newPipeline?.statuses.map(
            (e: any, index: number) => {
              return (
                <Draggable key={e?._id} draggableId={e?._id + ""} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <NewPipelineStatusListItem
                        id={e?._id}
                        title={e?.title}
                        description={e?.description}
                      />
                    </div>
                  )}
                </Draggable>
              );
            }
          )
        ) : (
          <>
            <div className="drophere d-flex align-items-center justify-content-center">
              <span className="text-center">
                {t("CONTRACTS_PIPELINES.DRAG_AND_DROP_DESCRIPTION")}
              </span>
            </div>
          </>
        )}
      </ListGroup>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(NewPipelineStatusCreationList);
