import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toggleApprovalProcessFileModal } from "@redux/components/index.actions";
import { startApproving, editApproveProcess } from "@redux/approve/index.actions";
import { toggleProcessApprovalPopup } from "@redux/process-approval/index.actions";

import { resfreshDocumensTable } from "@redux/files/index.actions";

import AddContract from "@pages/contracts/AddContract";
import { useTranslation } from "react-i18next";

import './index.scss'
import FeatherIcons from "feather-icons-react";
import apiInstance from "@clients/api.client";
import { toast } from "react-toastify";
import { FiSave } from "react-icons/fi";
import { VscSaveAs, VscSave } from "react-icons/vsc";
import ApproveTemplatesSelect from "@components/SelectsPaginated/ApproveTemplates";
import Loader from "@components/Loader";
import Lootie from "@components/ReactLottie";
import { RiDeleteBinLine } from "react-icons/ri";
import { Card, Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import ApproveTemplatesFlatList from "./components/ApproveTemplatesFlatList";
import moment from "moment";
import Avatar from "react-avatar";
import { useHistory } from "react-router-dom";


const MapStateToProps = (state: any) => ({
  components: state.components,
  companies: state.companies,
  processApproval: state.processApproval
});

const MapActionsToProps = {
  toggleProcessApprovalPopup
};

const ProcessApprovalPopup: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [userTitle, setUserTitle] = useState<string>('');
  const [editTitle, setEditTitle] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [keyTerms, setKeyTerms] = useState<any[]>([
    {
      key: 'title',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.TITLE'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.title,
      show: true,
      disabled: true
    },
    {
      key: 'description',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.DESCRIPTION'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.description,
      show: true
    },
    {
      key: 'contractCompaniesInternal',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.COMPANIES_INTERNAL'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.contractCompanies
        ?.filter((item: any) => item?.partiesSide == 'internal')
        ?.map((item: any) => item?.title)
        ?.join(', '),
      show: true
    },
    {
      key: 'contractCompaniesExternal',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.COMPANIES_EXTERNAL'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.contractCompanies
        ?.filter((item: any) => item?.partiesSide == 'external')
        ?.map((item: any) => item?.title)
        ?.join(', '),
      show: true
    },
    {
      key: 'type',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.PROCESS_TYPE'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.type?.title,
      show: true

    },
    {
      key: 'status',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.STATUS'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.status?.title,
      show: true
    },

    {
      key: 'startDate',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.START_DATE'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.startDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.startDate).format('DD.MM.YYYY') : null,
      show: true
    },
    {
      key: 'endDate',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.END_DATE'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.endDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.endDate).format('DD.MM.YYYY') : null,
      show: true
    },
    {
      key: 'renewalDate',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.RENEWAL_DATE'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.renewalDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.renewalDate).format('DD.MM.YYYY') : null,
      show: true
    },
    {
      key: 'effectiveDate',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.EFFECTIVE_DATE'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.effectiveDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.effectiveDate).format('DD.MM.YYYY') : null,
      show: true
    },
    {
      key: 'signatureDate',
      title: t('PROCESS_APPROVAL.KEYTERMS_DATA.SIGNED_DATE'),
      value: props?.processApproval?.sendProcessApprovalPopup?.data?.signatureDate ? moment(props?.processApproval?.sendProcessApprovalPopup?.data?.signatureDate).format('DD.MM.YYYY') : null,
      show: true
    },

  ]);

  useEffect(() => {
    /* Add custom fields to keyterms */
    if (props?.processApproval?.sendProcessApprovalPopup?.data?.customFields?.length > 0) {
      props?.processApproval?.sendProcessApprovalPopup?.data?.customFields?.map((item: any) => {
        console.log('item', item)
        if (item?.field?.CustomComponent != 'Formula') {

          if (item?.field?.CustomComponent == 'Date') {
            setKeyTerms((prev: any) => [...prev, {
              key: item?._id,
              title: item?.field?.title,
              value: item?.value ? moment(item?.value).format('DD.MM.YYYY') : null,
              show: true
            }])
          }

          else {
            setKeyTerms((prev: any) => [...prev, {
              key: item?._id,
              title: item?.field?.title,
              value: item?.value,
              show: true
            }])
          }

        }

      })
    }
  }, [props?.processApproval?.sendProcessApprovalPopup?.data?.customFields])


  const [page, setPage] = useState(0);

  const [files, setFiles] = useState<any[]>([]);


  useEffect(() => {
    apiInstance.get(`/contracts/${props?.companies?.company?._id}/${props?.processApproval?.sendProcessApprovalPopup?.data?._id}/files-with-subs`, {
      params: {
        contractId: props?.processApproval?.sendProcessApprovalPopup?.data?._id,
        page: 1,
        batchSize: 100,
        searchText: '',
      }
    }).then((res: any) => {
      console.log('res', res);
      if (res?.data?.node?.files?.length > 0) {
        res?.data?.node?.files?.map((item: any) => {
          console.log('item', item)
          setFiles((prev: any) => [...prev, {
            key: item?._id,
            title: item?.filename,
            value: item?._id,
            show: false
          }])
        })
      }
    }).catch((err: any) => {
      console.log('ERR', err);
    })
  }
    , [])

  function goTo(arg0: number): void {
    if (arg0 == 1) {
      if (keyTerms.filter((item: any) => item?.show == true).length < 1) {
        toast.error('En az bir tane anahtar kelime seçmelisiniz.')
        return;
      } else if (keyTerms.filter((item: any) => item?.key == 'title')?.[0]?.show != true) {
        toast.error('Süreç adı seçmelisiniz.')
        return;
      }
    }

    setPage(arg0);
  }


  /* Steps */
  const [steps, setSteps] = useState<any[]>([
    {
      aproveAll: false,
      stepUsers: []
    }
  ])
  const [suggestions, setSuggestions] = useState<any>({})
  const [emailValues, setEmailValues] = useState<any>({})

  const [userMessage, setUserMessage] = useState<string>('');


  const addStep = () => {
    setSteps((prev: any) => [...prev, {
      aproveAll: false,
      stepUsers: []
    }])
  }

  const handleRemoveStep = (index: number) => {
    setSteps((prev: any) => prev.filter((item: any, i: number) => i != index))
  }

  const handleRemoveRecipient = (stepIndex: number, recipientIndex: number) => {
    setSteps((prev: any) => prev.map((item: any, index: number) => {
      if (index == stepIndex) {
        item.stepUsers = item.stepUsers.filter((item: any, i: number) => i != recipientIndex)
      }
      return item;
    }
    ))
  }

  const handleAddStepUser = (stepIndex: number, user: any) => {
    setSteps((prev: any) => prev.map((item: any, index: number) => {
      if (index == stepIndex) {
        item.stepUsers.push({
          user: user?._id,
          email: user?.email,
          fullname: user?.name ? user?.name + ' ' + user?.surname : null,
          canChangeRequest: false,
          canAssign: false,
          needFile: false,
          userFiles: [
            {
              title: '',
              file: null
            }
          ]
        })
      }
      return item;
    }
    ))
  }

  const changeStepUserConfig = (value: boolean, stepIndex: number, recipientIndex: number, key: string) => {
    setSteps((prev: any) => prev.map((item: any, index: number) => {
      if (index == stepIndex) {
        item.stepUsers[recipientIndex][key] = value
      }
      return item;
    }
    ))
  }

  const changeApproveAll = (value: string, stepIndex: number) => {
    setSteps((prev: any) => prev.map((item: any, index: number) => {
      if (index == stepIndex) {
        item.aproveAll = value == 'all' ? true : false
      }
      return item;
    }
    ))
  }

  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(true);


  const disableAlgorithm = (keyterms: any[], files: any[]) => {
    let disabled: boolean = true;
    let countSelectedKeyterms = keyTerms.filter((item: any) => item?.show == true)?.length;
    let countSelectedFiles = files?.filter((item: any) => item?.show == true)?.length;
    let titleShow = keyTerms?.filter((item: any) => { return item?.key == 'title' && item?.show == true })?.length > 0 ? true : false;

    if (titleShow === true) {
      if (countSelectedKeyterms > 1 || countSelectedFiles > 0) {
        console.log('button enable')
        disabled = false;
      } else {
        disabled = true;
      }
    } else {
      disabled = true;
    }

    return disabled;
  }



  return (
    <>
      <Modal
        show={props?.processApproval?.sendProcessApprovalPopup?.show}

        keyboard={false}
        size="xl"
        backdrop={'static'}
        centered
        contentClassName="shadow">
        <Modal.Header className="bg-transparent d-flex align-content-center justify-content-between">
          <Modal.Title className="text-white w-100">
            {
              page == 0 && (
                <>
                  <h3 className="mr-2">{t('PROCESS_APPROVAL.APPROVAL_CONTENT')}</h3>
                </>
              )
            }
            {
              page == 1 && (
                <>
                  <span className="mr-2">
                    <div
                      style={{
                        color: '#4b4b5a',
                      }}
                      className="d-flex align-items-center justify-content-start gap-1">
                      <h3>{t('PROCESS_APPROVAL.PROCESS_NAME')}:
                      </h3>


                      {
                        editTitle === false ? (
                          <>
                            <h3 className="fw-normal" style={{
                              color: '#4b4b5a',
                            }}>
                              {userTitle || props?.processApproval?.sendProcessApprovalPopup?.data?.title}
                            </h3>
                            <Button variant="link" className="p-0" onClick={() => {
                              setEditTitle(true)
                            }}>
                              <FeatherIcons icon="edit" size={20} />
                            </Button>
                          </>
                        ) : (
                          <>
                            <input
                              style={{
                                width: '400px',
                              }}
                              defaultValue={userTitle || props?.processApproval?.sendProcessApprovalPopup?.data?.title}
                              onChange={(e) => {
                                setUserTitle(e.target.value)
                              }}
                              type="text" className="form-control" />
                            <Button variant="link" className="p-0" onClick={() => {
                              setEditTitle(false)
                            }
                            }>
                              <FeatherIcons icon="save" size={20} />
                            </Button>
                          </>
                        )


                      }
                    </div>
                  </span>
                </>
              )
            }
            {
              page == 2 && (
                <>
                  <span className="mr-2 w-100 d-flex align-items-center justify-content-center">
                    <span style={{
                      width: '400px',
                      textAlign: 'center',
                      color: '#4b4b5a'

                    }}>{t('PROCESS_APPROVAL.YOU_CAN_EDIT_RECIPIENTS_BEFORE_STARTING_APPROVAL_FLOW')}</span>
                  </span>
                </>
              )
            }
          </Modal.Title>
          <div className="action" style={{ width: '20px' }}>
            <Button className="p-0" variant="link" onClick={() => {
              window.location.reload();
            }}>
              <FeatherIcons icon="x" size={20} />
            </Button>
          </div>
        </Modal.Header>


        <Modal.Body>



          {
            page == 0 && (
              <>
                <Card>
                  <Card.Header className="d-flex align-items-center justify-content-start gap-1">
                    <input
                      className="form-check-input m-0"
                      type="checkbox"
                      checked={toggleSelectAll}
                      defaultChecked={false}
                      onChange={(event: any) => {

                        setToggleSelectAll(event?.target?.checked)

                        /* Select all toggle without title  */

                        setKeyTerms((prev: any) => prev.map((item: any) => {
                          if (item?.key != 'title') {
                            item.show = event?.target?.checked
                          }
                          return item;
                        }
                        ))

                      }}
                    /> <h4>{t('PROCESS_APPROVAL.KEY_TERMS')}</h4>
                  </Card.Header>
                  <Card.Body>
                    <ul className="ap-keyterms-list">
                      {
                        keyTerms.map((item, index) => {
                          return (
                            <li className="ap-keyterms-list-item">
                              <span className="ap-keyterms-list-item-checkbox">
                                <input
                                  className="form-check-input m-0"
                                  disabled={item?.disabled || false}
                                  id={item?.key + index} name={item?.key + ""} checked={item?.show} type="checkbox" onChange={(event: any) => {
                                    setKeyTerms((prev: any) => prev.map((item: any) => {
                                      if (item?.key == event?.target?.name) {
                                        item.show = event?.target?.checked
                                      }
                                      return item;
                                    }))
                                  }} />
                                <label htmlFor={item?.key + index} >{item.title}</label>
                              </span>
                              <span className="ap-keyterms-list-item-value">{item.value || '-'}</span>
                            </li>
                          )
                        })
                      }

                    </ul>

                  </Card.Body>

                </Card>

                <Card>
                  <Card.Header>
                    <h4>{t('PROCESS_APPROVAL.FILES')}</h4>
                  </Card.Header>
                  <Card.Body>
                    <ul className="ap-files-list">
                      {
                        files.map((item, index) => {
                          return (
                            <li className="ap-files-list-item">
                              <span className="ap-files-list-item-checkbox">
                                <input
                                  className="form-check-input m-0"
                                  disabled={item?.disabled || false}
                                  id={item?.key + index} name={item?.key + ""} checked={item?.show} type="checkbox" onChange={(event: any) => {
                                    setFiles((prev: any) => prev.map((item: any) => {
                                      if (item?.key == event?.target?.name) {
                                        item.show = event?.target?.checked
                                      }
                                      return item;
                                    }))
                                  }} />
                                <label htmlFor={item?.key + index} >{item.title}</label>
                              </span>

                            </li>
                          )
                        })
                      }

                    </ul>

                  </Card.Body>

                </Card>
                <div className="d-flex justify-content-end">
                  <Button
                    disabled={disableAlgorithm(keyTerms, files)}
                    className="btn-onedocs-primary" size="lg" variant="primary" onClick={() => goTo(1)}>{t('PROCESS_APPROVAL.NEXT')}</Button>
                </div>
              </>
            )
          }

          {
            page == 1 && (
              <>
                <Card>

                  <Card.Body>
                    {steps?.length <= 1 && (<h4>{t('PROCESS_APPROVAL.RECIPIENTS')} *</h4>)}
                    <div className="steps" style={{
                      background: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '#f1f1f9' : 'white',
                      paddingTop: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '30px' : '',
                      marginTop: props?.approve?.selectedApproveProcessFile?.steps?.length > 1 ? '25px' : ''
                    }}>

                      {(steps || []).map((stepItem: any, index: number) => {
                        console.log('stepItem', stepItem)
                        return (
                          <div key={'step__' + index} className="step">
                            {steps?.length > 1 && (
                              <div className="step-number" style={{ marginBottom: 20 }}>
                                <h5 className="m-0">{index + 1}. {t('APPROVAL_PROCESS_FILE_MODAL.STEP')}</h5>
                              </div>
                            )}
                            <div className="recipients-wrapper">
                              <div className="recipients">

                                {(stepItem?.stepUsers || []).map((stepUserItem: any, recipientIndex: number) => {
                                  return (
                                    <div key={'recipient' + stepUserItem?.key + recipientIndex + '1'} className="recipient-group">
                                      <div className="recipient-label">
                                        <Avatar name={stepUserItem?.fullname || stepUserItem?.email} size={'30'} round={true} />
                                        <span>{stepUserItem?.fullname || stepUserItem?.email}</span>
                                        <button className="btn btn-link text-dark" style={{ marginLeft: '5px', paddingLeft: '0', paddingRight: '0' }} onClick={e => handleRemoveRecipient(index, recipientIndex)}>
                                          <FeatherIcons size={14} icon="x" />
                                        </button>
                                      </div>


                                    </div>
                                  )
                                })}
                                <div className="recipients-new">
                                  <input

                                    onBlur={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      /* Email validation with regex then throw toast error */
                                      if (!emailValues[index]?.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
                                        return;
                                      }

                                      /* Check if user already added */
                                      if (steps?.[index]?.stepUsers?.filter((item: any) => item?.email == emailValues[index])?.length > 0) {
                                        toast.error('Bu kullanıcı zaten eklenmiş.')
                                        return;
                                      }

                                      apiInstance.post(`/process-approval/user`, {
                                        email: emailValues[index]
                                      }).then((res: any) => {
                                        console.log(res?.data)
                                        if (res?.data?.user?._id && res?.data?.user?.email) {

                                          handleAddStepUser(index, {
                                            _id: res?.data?.user?._id,
                                            email: res?.data?.user?.email,
                                            name: res?.data?.user?.name || null,
                                            surname: res?.data?.user?.surname || null,
                                            canChangeRequest: res?.data?.user?.type === 'GUEST' ? false : true,
                                            canAssign: res?.data?.user?.type === 'GUEST' ? false : true,
                                            needFile: false,
                                            userFiles: [
                                              {
                                                title: '',
                                                file: null
                                              }
                                            ]
                                          })
                                          setEmailValues({
                                            ...emailValues,
                                            [index]: ''
                                          })
                                          setSuggestions({
                                            ...suggestions,
                                            [index]: []
                                          })

                                        }

                                      }).catch((err: any) => {
                                        console.log('err', err);
                                      })

                                    }}
                                    onKeyDown={(e: any) => {

                                      if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',' || e.key === ';' || e.key === ' ' || e.key === 'Spacebar') {

                                        e.preventDefault();
                                        e.stopPropagation();
                                        /* Email validation with regex then throw toast error */
                                        if (!emailValues[index]?.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
                                          toast.error('Lütfen geçerli bir e-posta adresi giriniz.')
                                          return;
                                        }

                                        /* Check if user already added */
                                        if (steps?.[index]?.stepUsers?.filter((item: any) => item?.email == emailValues[index])?.length > 0) {
                                          toast.error('Bu kullanıcı zaten eklenmiş.')
                                          return;
                                        }

                                        apiInstance.post(`/process-approval/user`, {
                                          email: emailValues[index]
                                        }).then((res: any) => {
                                          console.log(res?.data)
                                          if (res?.data?.user?._id && res?.data?.user?.email) {

                                            handleAddStepUser(index, {
                                              _id: res?.data?.user?._id,
                                              email: res?.data?.user?.email,
                                              name: res?.data?.user?.name || null,
                                              surname: res?.data?.user?.surname || null,
                                              canChangeRequest: res?.data?.user?.type === 'GUEST' ? false : true,
                                              canAssign: res?.data?.user?.type === 'GUEST' ? false : true,
                                              needFile: false,
                                              userFiles: [
                                                {
                                                  title: '',
                                                  file: null
                                                }
                                              ]
                                            })
                                            setEmailValues({
                                              ...emailValues,
                                              [index]: ''
                                            })
                                            setSuggestions({
                                              ...suggestions,
                                              [index]: []
                                            })

                                          }

                                        }).catch((err: any) => {
                                          console.log('err', err);
                                        })



                                      }
                                    }}
                                    key={'recipient__input_email' + index}
                                    value={emailValues[index] || ''}
                                    onChange={(e) => {
                                      setEmailValues({
                                        ...emailValues,
                                        [index]: e.target.value
                                      })
                                    }}
                                    onInput={(e: any) => {

                                      if (e.target.value.length > 2) {
                                        apiInstance.get(`/companies/${props?.companies?.currentCompany}/users`, {
                                          params: {
                                            page: 1,
                                            batchSize: 10,
                                            searchText: e?.target?.value
                                          }
                                        }).then((res: any) => {
                                          console.log(res?.data?.node?.users)
                                          setSuggestions({
                                            ...suggestions,
                                            [index]: res?.data?.node?.users
                                          })
                                        })
                                      } else {
                                        setSuggestions({
                                          ...suggestions,
                                          [index]: []
                                        })
                                      }
                                    }}
                                    type="email" className="form-control"
                                    placeholder={t('APPROVAL_PROCESS_FILE_MODAL.RECIPIENT_EMAIL')} />


                                  {/* <Button variant="primary" size="sm" className="add-recipient-button"
                              onClick={() => handleAddUser({
                                step: { ...stepItem, index },
                                email: emailValues[index]
                              })}
                            >
                              <FeatherIcons icon="plus" />
                            </Button> */}

                                </div>





                              </div>
                              {steps?.length > 1 && (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRemoveStep(index)
                                  }}
                                  style={{ paddingRight: 0 }}
                                  className="btn btn-link btn-sm text-danger">
                                  <FeatherIcons size={16} icon="trash" />
                                </button>
                              )}
                            </div>

                            <div>
                              {suggestions?.[index]?.length > 0 && (
                                <div className="recipient-suggestions">
                                  {suggestions[index].filter((item: any) => {
                                    return !steps?.[index]?.stepUsers?.map((item: any) => item?.email)?.includes(item?.email)
                                  })?.map((item: any) => {
                                    return (
                                      <div className="recipient-suggestions-item"
                                        onClick={(e: any) => {
                                          handleAddStepUser(index, { ...item })
                                          setEmailValues({
                                            ...emailValues,
                                            [index]: ''
                                          })
                                          setSuggestions({
                                            ...suggestions,
                                            [index]: []
                                          })

                                        }}
                                      >
                                        <span>{item?.email}</span>
                                      </div>
                                    )
                                  })}
                                </div>
                              )}
                            </div>

                            <div className="step-settings">

                            </div>

                          </div>
                        )
                      })}

                      <div className="d-flex align-items-center justify-content-start">
                        <Button
                          onClick={() => {
                            addStep()
                          }}
                          style={{ color: 'blue', paddingLeft: 0, paddingRight: 0, }}
                          variant="link" className="d-flex align-items-center justify-content-center">
                          <FeatherIcons size={14} icon="plus" />
                          <span style={{
                            marginLeft: 5,
                            textDecoration: 'underline',
                            color: 'blue',
                            fontWeight: 'bold',
                            display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3px'
                          }}>{t('APPROVAL_PROCESS_FILE_MODAL.ADD_STEP')}
                            <OverlayTrigger
                              placement="right"
                              overlay={
                                <Popover popper id={'step'}>
                                  <Popover.Body>{t('APPROVAL_PROCESS_FILE_MODAL.ADD_STEP_INFO')}</Popover.Body>
                                </Popover>
                              }>
                              <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: '#cccccc',
                                borderRadius: '50%',
                                padding: '2px',
                                color: 'black'
                              }}>
                                <FeatherIcons size={16} icon="info" />
                              </div>
                            </OverlayTrigger>
                          </span>
                        </Button>
                      </div>
                      <div className="message-block">
                        <h4>{t('PROCESS_APPROVAL.MESSAGE')}</h4>
                        <textarea
                          rows={6}
                          value={userMessage}
                          onChange={(e) => {
                            setUserMessage(e.target.value)
                          }}
                          className="form-control" placeholder={t('APPROVE_PROCESS_MODAL.CREATE_MODAL.USER_NOTE_PLACEHOLDER')}></textarea>
                      </div>
                    </div>

                  </Card.Body>

                </Card>

                <div className="d-flex justify-content-between">
                  <Button className="btn-onedocs-secondary" size="lg" variant="primary" onClick={() => goTo(0)}>{t('PROCESS_APPROVAL.BACK')}</Button>
                  <Button
                    disabled={(() => {
                      let disabled = false;
                      steps?.map((item: any) => {
                        if (item?.stepUsers?.length == 0) {
                          disabled = true;
                        }
                      })
                      return disabled;
                    })()}
                    className="btn-onedocs-primary" size="lg" variant="primary" onClick={() => goTo(2)}>{t('PROCESS_APPROVAL.NEXT')}</Button>
                </div>
              </>
            )
          }

          {
            page == 2 && (
              <>

                <div className="steps-edit">
                  {(steps || []).map((stepItem: any, index: number) => {
                    console.log('stepItem', stepItem)
                    return (
                      <div key={'step__' + index} className="step">
                        <div className="step-number">
                          <h4 className="m-0">{index + 1}. {t('APPROVAL_PROCESS_FILE_MODAL.STEP')}</h4>

                        </div>
                        <div className="recipients">

                          {(stepItem?.stepUsers || []).map((stepUserItem: any, recipientIndex: number) => {
                            return (
                              <div key={'recipient' + stepUserItem?.key + recipientIndex} className="recipient">
                                <div className="recipient-group">
                                  <div className="recipient-label">
                                    <Avatar name={stepUserItem?.fullname || stepUserItem?.email} size={'30'} round={true} />
                                    <span>{stepUserItem?.fullname || stepUserItem?.email}</span>
                                    {/* <button className="btn btn-link text-dark" style={{ marginLeft: '5px', paddingLeft: '0', paddingRight: '0' }} onClick={e => handleRemoveRecipient(index, recipientIndex)}>
                                      <FeatherIcons size={14} icon="x" />
                                    </button> */}
                                  </div>
                                  <div className="recipient-settings">
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input m-0" type="checkbox" name="inlineRadioOptions" id={"inlineRadio1" + stepUserItem?.key + recipientIndex + index}
                                        onChange={(e) => changeStepUserConfig(e.target.checked, index, recipientIndex, 'canChangeRequest')}
                                        checked={stepUserItem?.canChangeRequest} />
                                      <label className="form-check-label" htmlFor={"inlineRadio1" + stepUserItem?.key + recipientIndex + index}>{t('APPROVAL_PROCESS_FILE_MODAL.CAN_CHANGE_REQUEST')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        onChange={(e) => changeStepUserConfig(e.target.checked, index, recipientIndex, 'canAssign')}
                                        className="form-check-input m-0" type="checkbox" name="inlineRadioOptions" id={"inlineRadio2" + stepUserItem?.key + recipientIndex + index} checked={stepUserItem?.canAssign} />
                                      <label className="form-check-label" htmlFor={"inlineRadio2" + stepUserItem?.key + recipientIndex + index}>{t('APPROVAL_PROCESS_FILE_MODAL.CAN_ASSIGN')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        onChange={(e) => changeStepUserConfig(e.target.checked, index, recipientIndex, 'needFile')}
                                        className="form-check-input m-0" type="checkbox" name="inlineRadioOptions" id={"inlineRadio3" + stepUserItem?.key + recipientIndex + index} checked={stepUserItem?.needFile} />
                                      <label className="form-check-label" htmlFor={"inlineRadio3" + stepUserItem?.key + recipientIndex + index}>{t('APPROVAL_PROCESS_FILE_MODAL.NEED_FILE')}</label>
                                    </div>

                                  </div>



                                </div>
                                {stepUserItem?.needFile && (
                                  <div className="recipient-settings">

                                    {stepUserItem?.userFiles?.map((item: any, fileIndex: number) => {
                                      return (
                                        <>
                                          <input
                                            value={steps?.[index]?.stepUsers?.[recipientIndex]?.userFiles?.[fileIndex]?.title}
                                            type="text" className="form-control mt-1" name="filename" onChange={(e: any) => {
                                              console.log(index, e.target.value)
                                              setSteps((prev: any) => prev.map((item: any, i: number) => {
                                                if (i == index) {
                                                  item.stepUsers[recipientIndex].userFiles[fileIndex].title = e?.target?.value
                                                }
                                                return item;
                                              }
                                              ))
                                            }} />
                                          {item?.stepUsers?.[recipientIndex]?.userFiles?.[fileIndex]?.title}
                                        </>
                                      )
                                    })}

                                    <div className="d-flex align-items-center justify-content-end gap-1">
                                      <Button
                                        size="sm"
                                        className="mt-1 btn-onedocs-primary"
                                        variant="outline-primary"
                                        onClick={() => {
                                          setSteps((prev: any) => prev.map((item: any, i: number) => {
                                            if (i == index) {
                                              item.stepUsers[recipientIndex].userFiles.push({
                                                title: '',
                                                file: null
                                              })
                                            }
                                            return item;
                                          }
                                          ))

                                        }}
                                      ><FeatherIcons icon="plus" /></Button>
                                      {
                                        stepUserItem?.userFiles?.length > 1 && (
                                          <Button
                                            size="sm"
                                            className="mt-1 btn-onedocs-secondary"
                                            variant="outline-danger"
                                            onClick={() => {
                                              setSteps((prev: any) => prev.map((item: any, i: number) => {
                                                if (i == index) {
                                                  item.stepUsers[recipientIndex].userFiles.pop()
                                                }
                                                return item;
                                              }
                                              ))

                                            }}
                                          ><FeatherIcons icon="minus" /></Button>
                                        )
                                      }

                                    </div>

                                  </div>
                                )}
                              </div>
                            )
                          })}




                        </div>
                        <div className="step-settings">
                          <span>
                            {t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM')}:
                          </span>
                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                value={'all'}
                                onChange={(e) => changeApproveAll(e.target.value, index)}
                                className="form-check-input" type="radio" id={'inlineRadio1' + index} key={'key_inline_radio_1' + index} checked={stepItem?.aproveAll === true ? true : false} />
                              <label className="form-check-label" htmlFor={'inlineRadio1' + index}>{t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM_ALL')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                value={'one'}
                                onChange={(e) => changeApproveAll(e.target.value, index)}
                                className="form-check-input" type="radio" id={'inlineRadio2' + index} key={'key_inline_radio_2' + index} checked={stepItem?.aproveAll !== true ? true : false} />
                              <label className="form-check-label" htmlFor={'inlineRadio2' + index}>{t('APPROVAL_PROCESS_FILE_MODAL.APPROVE_FROM_ONE')}</label>
                            </div>
                          </div>
                        </div>


                      </div>
                    )
                  })}
                </div>
                <div className="d-flex justify-content-between">
                  <Button className="btn-onedocs-secondary" size="lg" variant="primary" onClick={() => goTo(1)}>{t('PROCESS_APPROVAL.BACK')}</Button>
                  <Button
                    disabled={loading}
                    className="btn-onedocs-primary" size="lg" variant="primary" onClick={async () => {
                      setLoading(true)

                      await new Promise((resolve, reject) => {
                        steps?.map((item: any, index: number) => {
                          item.stepUsers?.map((stepUserItem: any, recipientIndex: number) => {
                            if (stepUserItem?.needFile === true) {
                              stepUserItem?.userFiles?.map((fileItem: any, fileIndex: number) => {
                                if (fileItem?.title == '') {
                                  toast.error('Lütfen dosya adını giriniz.')
                                  setLoading(false)
                                  reject(false);
                                  return false;
                                }
                              })
                            }
                          }
                          )
                        }
                        )
                        resolve(true)
                      }
                      )



                      //steps?.[index]?.stepUsers?.[recipientIndex]?.userFiles?.[fileIndex]?.title

                      apiInstance.post(`/process-approval`, {
                        contract: props?.processApproval?.sendProcessApprovalPopup?.data?._id,
                        steps: steps,
                        keyterms: keyTerms,
                        files: files,
                        message: userMessage,
                        userTitle: userTitle || null
                      }).then((res: any) => {
                        console.log('res', res);
                        if (res?.data?.response == true) {
                          console.log(res?.data)
                          history.push(`?c=${res?.data?.processApprovalData?.contract}&approvalId=${res?.data?.processApprovalData?._id}`);
                          window.location.reload();
                        }
                      }).catch((err: any) => {
                        console.log('err', err);
                        toast.error('Onay akışı başlatılamadı.')
                      })

                      console.log({
                        steps: steps,
                        keyTerms: keyTerms,
                        files: files
                      })

                    }}>{t('PROCESS_APPROVAL.SEND')}</Button>
                </div>
              </>
            )
          }






        </Modal.Body>
      </Modal>


    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(ProcessApprovalPopup);
