import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";


const ScrollToTop: React.FunctionComponent<any> = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollY > 0 && window.scrollTo(0, 0);
    }, [location]);
    return (
        <div></div>
    )
}

export default ScrollToTop;