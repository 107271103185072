import React, { useEffect, useState } from "react";

import { Col, Modal, Row, Button, Dropdown, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";

import { connect, useDispatch } from "react-redux";
import cdnServicesInstance from "@clients/cdnservices.client";
import { useTranslation } from "react-i18next";
import ContractsSelect from '@components/SelectsPaginated/Contracts';
import FilesSelect from '@components/SelectsPaginated/Files'
import classNames from 'classnames';
import api from '@clients/api.client';
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { toggleGenericGoogleDocsIframeModal, toggleFilesVersionModal, toggleFilesPdfViewerModal } from "@redux/components/index.actions";
import { setFileData, updateFile, getFileVersions } from "@redux/files/index.actions";
import { toggleInternalApprovalPopup, editReSendApproveFile, getReadApproveProcess } from "@redux/approve/index.actions";
import ApprovePdfViewer from '@components/ApprovePdfViewer';
import ApproveDocxViewer from '@components/ApproveDocxViewer';
import Lootie from "@components/ReactLottie";
import './index.scss';
import { toast } from "react-toastify";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { MdOutlineMessage } from "react-icons/md";
import StatusBadge, { StepStatusBadge, InternalApprovePopupStepUserStatusBadge } from "@components/ApproveStatus";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

import ApproveProcessModal from './components/ApproveProcessModal';
import ApproveChangeRequestModal from './components/ApproveChangeRequestModal';
import ApproveOwnerChangeRequestModal from './components/ApproveOwnerChangeRequestModal';
import ApproveOwnerNewVersionModal from './components/ApproveOwnerNewVersionModal';
import ApproveAssignModal from './components/ApproveAssignModal';
import ApproveRejectModal from './components/ApproveRejectModal';
import ApproveOwnerRejectModal from './components/ApproveOwnerRejectModal';
import apiInstance from "@clients/api.client";
import moment from "moment";
import { lowerCase } from "lodash";
import { VscFilePdf } from "react-icons/vsc";
import { toggleGenericErrorModal } from "@redux/utils/components/GenerateErrorModal";
import { TGenericResponse } from "@redux/interfaces/index.interfaces";
import ErrorResponse from "@redux/utils/ErrorResponse.utils";
import renderHTML from 'react-render-html';

const mapStateToProps = (state: any) => ({
  components: state?.components,
  companies: state?.companies,
  files: state?.files,
  approve: state?.approve,
  users: state?.users
});
const mapActionsToProps = {
  toggleGenericGoogleDocsIframeModal,
  setFileData,
  updateFile,
  getFileVersions,
  toggleFilesVersionModal,
  toggleFilesPdfViewerModal,
  toggleInternalApprovalPopup,
  editReSendApproveFile,
  getReadApproveProcess
};

const docs = [
  // {
  //   uri:
  //     "http://localhost:9000/uploads/ULRYB3ATJ56B/Screenshot%202021-04-28%20at%2014.04.23.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20210507%2F%2Fs3%2Faws4_request&X-Amz-Date=20210507T142426Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=761187860be22801088ab8c212733f7f52af8f62d638f1341ee2ae4c18944251"
  //   // "http://localhost:9000/uploads/6QK5HJ84MAEM/RAS-118_CompanyCodes__SalesOffices.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20210507%2F%2Fs3%2Faws4_request&X-Amz-Date=20210507T110429Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=c20f9b77ffdc1a15910cea5acd3420b6583a1d4d38ce5716da30f1d0ea4315d5"
  //   // "https://res.cloudinary.com/cloudinaryforme/image/upload/v1618339571/workplace-1245776_1920_i9ayae.jpg"
  // },

  // {
  //   uri:
  //     "https://code.visualstudio.com/shortcuts/keyboard-shortcuts-macos.pdf"
  // },
  { uri: require("./demo.docx") }
];

const InternalApprovePopup = (props: any) => {
  const { t } = useTranslation();
  //dispatch

  const dispatch = useDispatch();
  const [opens, setOpens] = useState<string[]>([]);

  const toggleAccordion = (index: string) => {
    if (opens.includes(index)) {
      setOpens(opens.filter((open: string) => open !== index));
    } else {
      setOpens([...opens, index]);
    }
  }

  const generateDownloadUrlWithoutBaseUrl = (file: any) => {
    if (file?.source?.sourceKey === "amazon-s3") {
      return "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
    } else if (file?.source?.sourceKey === "custom-cdn") {
      return "/local/download/" + file?.key + "?accessKey=" + props?.users?.authorization?.token;
    } else if (file?.source?.sourceKey === "google-drive") {
      return `/gdrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
    } else if (file?.source?.sourceKey === "one-drive") {
      return `/onedrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
    } else {
      return ""
    }
  }

  // MODALS

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<{
    stepUserId: string
    internalUserId: string | null
    email: string
    expireIn: string
    approveFileId: string
    stepId: string
    canChangeRequest: boolean
    canAssign: boolean
    company: string
    file: any
  }>();

  //approveProcessModal
  const [approveProcessModal, setApproveProcessModal] = useState<boolean>(false);
  const toggleApproveProcessModal = () => setApproveProcessModal(!approveProcessModal);
  //approveChangeRequestModal
  const [approveChangeRequestModal, setApproveChangeRequestModal] = useState<boolean>(false);
  const toggleApproveChangeRequestModal = () => setApproveChangeRequestModal(!approveChangeRequestModal);
  //approveOwnerChangeRequestModal
  const [approveOwnerChangeRequestModal, setApproveOwnerChangeRequestModal] = useState<boolean>(false);
  const toggleApproveOwnerChangeRequestModal = () => setApproveOwnerChangeRequestModal(!approveOwnerChangeRequestModal);

  //approveOwnerNewVersionModal
  const [approveOwnerNewVersionModal, setApproveOwnerNewVersionModal] = useState<boolean>(false);
  const toggleApproveOwnerNewVersionModal = () => setApproveOwnerNewVersionModal(!approveOwnerNewVersionModal);

  //approveAssignModal
  const [approveAssignModal, setApproveAssignModal] = useState<boolean>(false);
  const toggleApproveAssignModal = () => setApproveAssignModal(!approveAssignModal);
  //approveRejectModal
  const [approveRejectModal, setApproveRejectModal] = useState<boolean>(false);
  const toggleApproveRejectModal = () => setApproveRejectModal(!approveRejectModal);
  //approveOwnerRejectModal
  const [approveOwnerRejectModal, setApproveOwnerRejectModal] = useState<boolean>(false);
  const toggleApproveOwnerRejectModal = () => setApproveOwnerRejectModal(!approveOwnerRejectModal);
  const [continueFlow, setContinueFlow] = useState<boolean>(false);
  const [continueFlowFromScratch, setContinueFlowFromScratch] = useState<boolean>(false);

  const [selectedVersion, setSelectedVersion] = useState<any>(null);
  const [fetchSelectedVersion, setFetchSelectedVersion] = useState<boolean>(false);

  const [compareLoading, setCompareLoading] = useState<boolean>(false);
  const [compareFiles, setCompareFiles] = useState<any[]>([]);
  const [compareFilesModal, setCompareFilesModal] = useState<boolean>(false);
  const toggleCompareFilesModal = () => setCompareFilesModal(!compareFilesModal);
  const [diffResult, setDiffResult] = useState<any>(null);

  // Create function to set fileId to compareFiles if compare files has 2 files remove old one and add new one
  const handleSetCompareFile = (fileId: string) => {
    console.log('fileId', fileId)
    if (compareFiles?.length >= 2) {
      setCompareFiles([compareFiles[1], fileId]);
    } else {
      setCompareFiles([...compareFiles, fileId]);
    }
  }

  const handleRemoveCompareFile = (fileId: string) => {
    setCompareFiles(compareFiles.filter((file: string) => file !== fileId));
  }



  useEffect(() => {
    let files = props?.approve?.internalApprovePopup?.data?.approve?.approveFiles;
    let openArray: string[] = [];
    files = files?.map((file: any, index: number) => {
      if (file?.status !== "PENDING") openArray.push(`${index}${file?._id}`);
      file?.steps?.map((step: any, stepIndex: number) => {
        if (step?.status !== "PENDING") openArray.push(`${step?._id}${file?._id}`);
      })
    }
    )

    setSelectedVersion(props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1]);
    setOpens(openArray);
    setFetchSelectedVersion(false);

  }, [props?.approve?.internalApprovePopup?.data?.approve?.approveFiles]);




  const generateDownloadUrl = (file: any) => {
    if (file?.source?.sourceKey === "amazon-s3") {
      return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
    } else if (file?.source?.sourceKey === "custom-cdn") {
      return props?.companies?.company?.customCDNEndpoint + "/local/download/" + file?.key + "?accessKey=" + props?.users?.authorization?.token;
    } else if (file?.source?.sourceKey === "google-drive") {
      return cdnServicesInstance.defaults.baseURL + `/gdrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
    } else if (file?.source?.sourceKey === "one-drive") {
      return cdnServicesInstance.defaults.baseURL + `/onedrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
    } else if (file?.source?.sourceKey === "share-point") {
      return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
    } else {
      return ""
    }
  }


  // SVG Icon Component
  const SVGIcon = (props: any) => (
    <svg fill="white" width={'20px'} id="katman_2" data-name="katman 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 373.33 326.67">

      <g id="katman_1" data-name="katman 1">
        <path d="m273.26,6.86c-9.15-9.14-23.99-9.14-33.14,0-9.15,9.14-9.15,23.97,0,33.11l53.67,53.4H23.34C10.45,93.37,0,103.81,0,116.68s10.45,23.32,23.34,23.32h326.7c9.42-.05,17.89-5.75,21.47-14.46,3.66-8.68,1.72-18.71-4.9-25.42L273.26,6.86Zm76.73,179.81H23.3c-9.42.05-17.89,5.75-21.47,14.45-3.66,8.68-1.72,18.71,4.9,25.41l93.34,93.24c4.38,4.41,10.35,6.89,16.57,6.89s12.18-2.48,16.57-6.89c4.42-4.38,6.9-10.34,6.9-16.55s-2.48-12.17-6.9-16.55l-53.67-53.38h270.46c12.89,0,23.34-10.43,23.34-23.31s-10.45-23.31-23.34-23.31h0Z" />
      </g>
    </svg>
  )



  const [indexes, setIndexes] = useState<any[]>([]);

  return (
    <>

      <>

        {approveChangeRequestModal && (
          <ApproveChangeRequestModal
            toggle={toggleApproveChangeRequestModal}
            show={approveChangeRequestModal}
            data={props?.approve?.internalApprovePopup?.data?.requestUserData}
            token={props?.approve?.internalApprovePopup?.data?.requestUserData?.accessToken}
            setLoading={setLoading}
            loading={loading}
            originalFile={props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1]?.originalFile}
            editReSendApproveFile={props?.editReSendApproveFile}
            process={props?.approve?.internalApprovePopup?.data?.approve}
            file={props?.approve?.approveFiles?.[props?.approve?.approveFiles?.length - 1]}
            getReadApproveProcess={props?.getReadApproveProcess}
          />
        )}
        {approveOwnerChangeRequestModal && (
          <ApproveOwnerChangeRequestModal
            toggle={toggleApproveOwnerChangeRequestModal}
            show={approveOwnerChangeRequestModal}
            data={props?.approve?.internalApprovePopup?.data?.requestUserData}
            token={props?.approve?.internalApprovePopup?.data?.requestUserData?.accessToken}
            setLoading={setLoading}
            loading={loading}
            originalFile={props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1]?.originalFile}
            editReSendApproveFile={props?.editReSendApproveFile}
            process={props?.approve?.internalApprovePopup?.data?.approve}
            file={props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1]}
            getReadApproveProcess={props?.getReadApproveProcess}
          />
        )}

        {approveOwnerNewVersionModal && (
          <ApproveOwnerNewVersionModal
            toggle={toggleApproveOwnerNewVersionModal}
            show={approveOwnerNewVersionModal}
            data={props?.approve?.internalApprovePopup?.data?.requestUserData}
            token={props?.approve?.internalApprovePopup?.data?.requestUserData?.accessToken}
            setLoading={setLoading}
            loading={loading}
            originalFile={props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1]?.originalFile}
            editReSendApproveFile={props?.editReSendApproveFile}
            process={props?.approve?.internalApprovePopup?.data?.approve}
            file={props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1]}
            getReadApproveProcess={props?.getReadApproveProcess}
          />
        )}

        {approveProcessModal && (
          <ApproveProcessModal
            toggle={toggleApproveProcessModal}
            show={approveProcessModal}
            data={props?.approve?.internalApprovePopup?.data?.requestUserData}
            token={props?.approve?.internalApprovePopup?.data?.requestUserData?.accessToken}
            setLoading={setLoading}
            loading={loading}
            editReSendApproveFile={props?.editReSendApproveFile}
            process={props?.approve?.internalApprovePopup?.data?.approve}
            file={props?.approve?.approveFiles?.[props?.approve?.approveFiles?.length - 1]}
            getReadApproveProcess={props?.getReadApproveProcess}
          />
        )}



        {approveAssignModal && (
          <ApproveAssignModal
            toggle={toggleApproveAssignModal}
            show={approveAssignModal}
            data={props?.approve?.internalApprovePopup?.data?.requestUserData}
            token={props?.approve?.internalApprovePopup?.data?.requestUserData?.accessToken}
            setLoading={setLoading}
            loading={loading}
            editReSendApproveFile={props?.editReSendApproveFile}
            process={props?.approve?.internalApprovePopup?.data?.approve}
            file={props?.approve?.approveFiles?.[props?.approve?.approveFiles?.length - 1]}
            getReadApproveProcess={props?.getReadApproveProcess}
          />
        )}

        {approveRejectModal && (
          <ApproveRejectModal
            toggle={toggleApproveRejectModal}
            show={approveRejectModal}
            data={props?.approve?.internalApprovePopup?.data?.requestUserData}
            token={props?.approve?.internalApprovePopup?.data?.requestUserData?.accessToken}
            setLoading={setLoading}
            editReSendApproveFile={props?.editReSendApproveFile}
            process={props?.approve?.internalApprovePopup?.data?.approve}
            file={props?.approve?.approveFiles?.[props?.approve?.approveFiles?.length - 1]}
            getReadApproveProcess={props?.getReadApproveProcess}
          />
        )}
        {approveOwnerRejectModal && (
          <ApproveOwnerRejectModal
            toggle={toggleApproveOwnerRejectModal}
            show={approveOwnerRejectModal}
            data={props?.approve?.internalApprovePopup?.data?.requestUserData}
            token={props?.approve?.internalApprovePopup?.data?.requestUserData?.accessToken}
            setLoading={setLoading}
            loading={loading}
            editReSendApproveFile={props?.editReSendApproveFile}
            process={props?.approve?.internalApprovePopup?.data?.approve}
            file={props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1]}
            getReadApproveProcess={props?.getReadApproveProcess}
          />
        )}

        {compareFilesModal && (

          <Modal
            show={compareFilesModal}
            onHide={() => { setCompareFilesModal(false) }}
            size="xl"
            centered
            dialogClassName="compare-files-modal-dialog"
          >
            <Modal.Header closeButton closeVariant="white" className="bg-primary">
              <h4 className="modal-title text-white mx-2 d-flex align-items-center justify-content-center gap-1"
              >
                <span>v{indexes[0] + 1 || ''}</span> <SVGIcon></SVGIcon> <span>v{indexes[1] + 1 || ''}</span>
              </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="compare-result">
                <div className="contentA4">
                  {renderHTML(diffResult)}
                </div>

              </div>
            </Modal.Body>
          </Modal>

        )}
      </>

      <Modal
        show={props?.approve?.internalApprovePopup?.show}
        onHide={() => { props?.toggleInternalApprovalPopup({ show: false, data: {} }) }}
        size="xl"
        centered
        dialogClassName="internal-approve-modal-dialog"
      >
        <Modal.Header className="bg-primary">
          <h4 className="modal-title text-white dotted-long mx-2"
            title={props?.approve?.internalApprovePopup?.data?.approve?.title}
          >
            {props?.approve?.internalApprovePopup?.data?.approve?.title}
          </h4>
          <span>

            <Dropdown as="span">
              <Dropdown.Toggle className="btn btn-link text-white" color="white">
                <FeatherIcons icon="download" />
              </Dropdown.Toggle>

              <Dropdown.Menu className='w-100 p-3'>
                <div className="w-100 d-flex align-items-center justify-content-center gap-2">
                  <button
                    onClick={(e: any) => window.open(generateDownloadUrl(selectedVersion?.originalFile), "_blank")}
                    className="w-50 btn btn-outline-primary btn-lg file-docx fs-6 d-flex align-items-center justify-content-center flex-column gap-1">
                    DOCX
                    <FeatherIcons icon="file-text" size="26" />
                  </button>
                  <button
                    disabled={selectedVersion?.approveFile === null || selectedVersion?.approveFile === undefined || selectedVersion?.approveFile === ""}
                    onClick={(e: any) => window.open(generateDownloadUrl(selectedVersion?.approveFile), "_blank")}
                    className="w-50 btn btn-outline-primary btn-lg file-pdf fs-6 d-flex align-items-center justify-content-center flex-column gap-1">
                    PDF
                    <VscFilePdf size="26" />
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              onClick={() => {
                props?.getReadApproveProcess({
                  id: props?.approve?.internalApprovePopup?.data?.approve?._id
                })
              }}
              className="text-white"
              variant="link"
            >
              <FeatherIcons icon="refresh-cw" />
            </Button>
            <Button
              onClick={() => {
                props?.toggleInternalApprovalPopup({ show: false, data: {} })
              }}
              className="text-white"
              variant="link"
            >
              <FeatherIcons icon="x" />
            </Button>
          </span>

        </Modal.Header>
        <Modal.Body className="internal-approve-popup">




          <div className="internal-approve-left">

            <div className="top">
              <div style={{ padding: 20 }}>
                <h4 className="m-0">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.VERSIONS')}</h4>

              </div>

              <div>
                <div className={`internal-approve-left-versions`}>
                  {[...props?.approve?.internalApprovePopup?.data?.approve?.approveFiles || '']?.reverse()?.map((file: any, index: number) => {

                    const length = props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length;
                    console.log('APPROVE_FILES', props?.approve?.internalApprovePopup?.data?.approve?.approveFiles)
                    return (
                      <>
                        <div

                          className={`internal-approve-left-version ${selectedVersion?._id === file?._id ? "active" : ""}`}
                          key={index}
                        >

                          <div className={`internal-approve-left-version-selector ${compareFiles?.length > 0 ? "showSelector" : ""}`}>
                            <label
                              className={compareFiles?.length >= 2 && !compareFiles?.includes(file?._id) ? 'disabled' : ''}
                              htmlFor={file?._id + "-compare" + index}>
                              {
                                compareFiles?.includes(file?._id) ? (<span className="selector-number">{compareFiles.findIndex((compareFile) => compareFile === file?._id) + 1}</span>) : <FeatherIcons icon="square" />
                              }
                            </label>
                            <input type="checkbox"
                              style={{ display: "none" }}
                              id={file?._id + "-compare" + index}
                              disabled={compareFiles?.length >= 2 && !compareFiles?.includes(file?._id) ? true : false}
                              checked={compareFiles?.includes(file?._id) ? true : false}
                              onChange={(e: any) => {
                                if (e.target.checked) {
                                  handleSetCompareFile(file?._id)
                                } else {
                                  handleRemoveCompareFile(file?._id)
                                }
                              }}
                            />
                          </div>
                          <div className="internal-approve-left-version-number">
                            <span>{'v' + (length - index)}</span>
                          </div>
                          <div
                            onClick={() => {
                              setSelectedVersion(file);
                            }}
                            style={{ cursor: "pointer" }}
                            className="internal-approve-left-version-circle">
                            <span className="circle"></span>
                            <span className="top-connector"></span>
                          </div>
                          <div className="internal-approve-left-version-title">
                            <h6 className="title">{file?.originalFile?.filename}</h6>
                            <span className="date">{moment(file?.createdAt).format("DD MMM YYYY HH:mm")}</span>
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="bottom d-flex align-items-center justify-content-center">
              <div className="buttons d-flex flex-column align-items-center justify-content-center gap-1">

                <Button
                  disabled={compareFiles?.length <= 1 || compareLoading}
                  onClick={() => {
                    setCompareLoading(true)
                    apiInstance.get(`/approve-files/compare-versions/${props?.companies?.company?._id}/${compareFiles?.[0]}/${compareFiles?.[1]}`).then((res: any) => {

                      // Find index of selected version
                      const index1: any = props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.findIndex((file: any) => file?._id === compareFiles?.[0])
                      const index2: any = props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.findIndex((file: any) => file?._id === compareFiles?.[1])
                      setIndexes([index1, index2])
                      setDiffResult(res?.data?.node?.diffResult)
                      setCompareFilesModal(true)
                      setCompareLoading(false)
                    }).catch((err: any) => {
                      console.log(err?.response);
                      setCompareLoading(false)
                    })

                  }} className="btn btn-primary w-100 d-flex align-items-center justify-content-center gap-1">
                  <>
                    {compareLoading ? <Spinner size="sm" animation="border" variant="light" /> : <SVGIcon />} {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.COMPARE')} ({compareFiles?.length})
                  </>
                </Button>

                <Button

                  onClick={toggleApproveOwnerNewVersionModal}
                  className="btn btn-primary w-100">
                  <FeatherIcons icon="upload" /> Versiyon Yükle
                </Button>


                <Dropdown
                  className="btn btn-btn-primary w-100 p-0"
                  as="span">
                  <Dropdown.Toggle className="w-100">
                    <FeatherIcons icon="download" /> Versiyonu İndir
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='w-100 p-3'>
                    <div className="w-100 d-flex align-items-center justify-content-center gap-2">
                      <button
                        onClick={(e: any) => window.open(generateDownloadUrl(selectedVersion?.originalFile), "_blank")}
                        className="w-50 btn btn-outline-primary btn-lg file-docx fs-6 d-flex align-items-center justify-content-center flex-column gap-1">
                        DOCX
                        <FeatherIcons icon="file-text" size="26" />
                      </button>
                      <button
                        disabled={selectedVersion?.approveFile === null || selectedVersion?.approveFile === undefined || selectedVersion?.approveFile === ""}
                        onClick={(e: any) => window.open(generateDownloadUrl(selectedVersion?.approveFile), "_blank")}
                        className="w-50 btn btn-outline-primary btn-lg file-pdf fs-6 d-flex align-items-center justify-content-center flex-column gap-1">
                        PDF
                        <VscFilePdf size="26" />
                      </button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

          </div>
          <div className="internal-approve-center">
            {
              !fetchSelectedVersion && (
                <div className="internal-viewer">
                  {((approveFiles: any) => {

                    let lastFile = approveFiles?.[approveFiles?.length - 1];

                    if (selectedVersion?.approveFile) {
                      return (
                        <ApprovePdfViewer
                          className="pdf-viewer"
                          url={generateDownloadUrlWithoutBaseUrl(selectedVersion?.approveFile)}
                        />
                      )
                    } else {
                      return (
                        <>
                          {/* <ApproveDocxViewer
                        className="pdf-viewer"
                        file={lastFile?.originalFile}
                        url={generateDownloadUrlWithoutBaseUrl(lastFile?.originalFile)}
                      /> */}
                          {/*  <iframe
                        className="google-drive-approve-iframe"
                        src={`https://docs.google.com/document/d/${lastFile?.originalFile?.key}?rm=minimal`}></iframe> 
                        
                        ${lastFile?.originalFile?.key}


                        */}

                          <div style={{
                            position: 'relative', width: '100%', height: '100%'
                          }}>

                            <div style={{
                              backgroundColor: 'rgb(209 209 209)',
                              height: '46px',
                              right: '12px',
                              width: '47px',
                              zIndex: 9999999999999999999999999,
                              position: 'absolute',
                              top: '8px'
                            }}> </div>

                            <iframe
                              className="google-drive-approve-iframe"
                              src={`https://docs.google.com/viewer?srcid=${selectedVersion?.originalFile?.key}&pid=explorer&efh=false&a=v&chrome=false&embedded=true&rm=minimal`} width="580px" height="480px"></iframe>
                          </div>


                        </>
                      )
                    }

                  })(props?.approve?.internalApprovePopup?.data?.approve?.approveFiles)}
                </div>
              )
            }





          </div>
          <div className="internal-approve-right">

            {
              props?.approve?.internalApprovePopup?.data?.requestUserData?.accessToken && (
                <>

                  <div className="internal-approve-buttons">
                    <div className='external-approve-actions'>
                      <Button
                        onClick={toggleApproveProcessModal}
                        variant="success" className='button-approve'>
                        {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.APPROVE')}
                      </Button>


                      <Dropdown className='button-reject'>
                        <Dropdown.Toggle className='w-100' variant="danger">
                          {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT')} <FeatherIcons size={14} icon="chevron-down" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='w-100'>
                          <Dropdown.Item
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              toggleApproveRejectModal();
                            }}
                            className='d-flex align-items-center justify-content-center gap-1'>
                            <FeatherIcons size={14} icon="skip-forward" />
                            <span>
                              {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_AND_COMPLATE')}
                            </span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={toggleApproveChangeRequestModal}
                            disabled={props?.approve?.internalApprovePopup?.data?.requestUserData?.canChangeRequest ? false : true}
                            className='d-flex align-items-center justify-content-center gap-1'>
                            <FeatherIcons size={14} icon="message-square" />
                            <span>
                              {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_AND_SUGGEST_CHANGES')}
                            </span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <Button
                        onClick={toggleApproveAssignModal}
                        disabled={props?.approve?.internalApprovePopup?.data?.requestUserData?.canAssign ? false : true}
                        variant="outline-secondary" className='button-change-assign'>
                        {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.ASSIGN_TO_SOMEONE_ELSE')}
                      </Button>


                    </div>
                  </div>
                </>
              )
            }


            {
              props?.approve?.internalApprovePopup?.data?.processAdmin === true
              && !props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles.length - 1]?.approveFile
              && props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles.length - 1]?.status === 'CREATED'
              && (
                <>
                  {props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length > 1 ? (
                    <div className="internal-approve-buttons">
                      <div className='external-approve-actions'>
                        <div className="d-flex gap-1 flex-wrap align-items-center justify-content-center">
                          <Dropdown className='button-approve-dd'>
                            <Dropdown.Toggle className='w-100' variant="success">
                              {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.ACCEPT')} <FeatherIcons size={14} icon="chevron-down" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='w-100'>
                              <Dropdown.Item
                                onClick={(e: any) => {
                                  setContinueFlow(true);
                                }}
                                className='d-flex align-items-center justify-content-center gap-1'>
                                <FeatherIcons size={14} icon="skip-forward" />
                                <span>
                                  Akışa Devam Et
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e: any) => {
                                  setContinueFlowFromScratch(true);
                                }}
                                className='d-flex align-items-center justify-content-center gap-1'>
                                <FeatherIcons size={14} icon="message-square" />
                                <span>
                                  Aynı Akışı Yeniden Başlat
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={(e: any) => {
                                  props?.editReSendApproveFile({
                                    show: true,
                                    process: props?.approve?.internalApprovePopup?.data?.approve,
                                    file: props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles.length - 1]
                                  })
                                }}
                                className='d-flex align-items-center justify-content-center gap-1'>
                                <FeatherIcons size={14} icon="message-square" />
                                <span>
                                  Yeni Akışa Bağla
                                </span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <Dropdown className='button-reject'>
                            <Dropdown.Toggle className='w-100' variant="danger">
                              {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT')} <FeatherIcons size={14} icon="chevron-down" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='w-100'>
                              <Dropdown.Item
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  toggleApproveOwnerRejectModal();
                                }}
                                className='d-flex align-items-center justify-content-center gap-1'>
                                <FeatherIcons size={14} icon="skip-forward" />
                                <span>
                                  {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_AND_COMPLATE')}
                                </span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={toggleApproveOwnerChangeRequestModal}
                                className='d-flex align-items-center justify-content-center gap-1'>
                                <FeatherIcons size={14} icon="message-square" />
                                <span>
                                  {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.REJECT_AND_SUGGEST_CHANGES')}
                                </span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="internal-approve-buttons">
                      <div className='external-approve-actions'>
                        <Button
                          variant="outline-primary"
                          onClick={(e: any) => {
                            props?.editReSendApproveFile({
                              show: true,
                              process: props?.approve?.internalApprovePopup?.data?.approve,
                              file: props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles.length - 1]
                            })
                          }}>
                          {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.START_APPROVE_PROCESS')}
                        </Button>
                      </div>
                    </div>
                  )}

                </>
              )}


            <div style={{ marginTop: 20 }}>
              <h4 className="m-0">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.TITLE')}: <span className="fw-normal">{props?.approve?.internalApprovePopup?.data?.approve?.title}</span></h4>

            </div>
            <div className="approve-files">
              {props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.map((file: any, index: number) => {

                return (
                  <div
                    style={{
                      border: '1px solid #e5e5e5',
                    }}
                    key={file?._id + index}
                    className={`approve-file ${selectedVersion?._id === file?._id ? 'approve-file-active' : 'b'}`}

                  >
                    <div
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (selectedVersion?._id === file?._id) {
                          return;
                        }
                        setSelectedVersion(file);
                        setFetchSelectedVersion(true);
                        setTimeout(() => {

                          setFetchSelectedVersion(false);
                        }, 100);
                      }}
                      className={`${'approve-file-header'} ${'bg-file-' + lowerCase(file?.status)}`}>
                      <div className="approve-file-header-title">

                        <span>{index + 1}. {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE')}</span>

                        <span className="d-flex align-items-center justify-content-center">
                          <StatusBadge className={file?.status} title={(!file?.startType && file?.status != 'REJECTED') ? t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.NOT_STARTED') : t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.' + file?.status)} />
                        </span>
                      </div>
                      <div className="approve-file-header-action">

                        <Button
                          onClick={() => {
                            toggleAccordion(`${index}${file?._id}`);
                          }}
                          variant="link"
                        >
                          {!opens.includes(`${index}${file?._id}`) ? (<IoMdArrowDropup color="black" size={18} />) : (<IoMdArrowDropdown color="black" size={18} />)}
                        </Button>
                      </div>
                    </div>

                    <div className="approve-file-body">
                      {!opens.includes(`${index}${file?._id}`) && (
                        <>
                          <div className="approve-file-attachments">
                            <div className="d-flex align-items-center justify-content-start gap-1">

                              <span style={{
                                fontWeight: 600,
                              }}>
                                {
                                  (() => {
                                    let startType = file?.startType;
                                    const startDate = moment(file?.startDate).format('DD.MM.YY');
                                    const ownerName = props?.approve?.internalApprovePopup?.data?.approve?.user?.name + ' ' + props?.approve?.internalApprovePopup?.data?.approve?.user?.surname;

                                    if (!startType && file?.status === 'REJECTED') {

                                      //Check if current file is last file
                                      if (props?.approve?.internalApprovePopup?.data?.approve?.status === 'REJECTED' && props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.length - 1 === index) {
                                        return (
                                          <>
                                            <Avatar style={{
                                              marginRight: '5px'
                                            }} name={props?.approve?.internalApprovePopup?.data?.approve?.user?.name} size="26" round={true} />
                                            <span className="text-muted">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.NO_START_FILE_REJECT_COMPLATE', { ownerName: ownerName, startDate: startDate })}</span>
                                          </>
                                        );
                                      }

                                      return (
                                        <>
                                          <Avatar style={{
                                            marginRight: '5px'
                                          }} name={props?.approve?.internalApprovePopup?.data?.approve?.user?.name} size="26" round={true} />
                                          <span className="text-muted">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.NO_START_FILE_REJECT', { ownerName: ownerName, startDate: startDate })}</span>
                                        </>

                                      );
                                    } else if (!startType) {
                                      return (
                                        <>
                                          <Avatar style={{
                                            marginRight: '5px'
                                          }} name={props?.approve?.internalApprovePopup?.data?.approve?.user?.name} size="26" round={true} />
                                          <span className="text-muted">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.NO_START_FILE_PROCESS', { ownerName: ownerName, startDate: startDate })}</span>
                                        </>

                                      );
                                    } else if (startType === 'CONTINUE_FROM_SCRATCH') {
                                      return (
                                        <>
                                          <Avatar style={{
                                            marginRight: '5px'
                                          }} name={props?.approve?.internalApprovePopup?.data?.approve?.user?.name} size="26" round={true} />
                                          <span className="text-muted">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.CONTINUE_FROM_SCRATCH', { ownerName: ownerName, startDate: startDate })}</span>
                                        </>
                                      )

                                    } else if (startType === 'CONTINUE_FLOW') {
                                      return (
                                        <>
                                          <Avatar style={{
                                            marginRight: '5px'
                                          }} name={props?.approve?.internalApprovePopup?.data?.approve?.user?.name} size="26" round={true} />
                                          <span className="text-muted">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.CONTINUE_FLOW', { ownerName: ownerName, startDate: startDate })}</span>
                                        </>
                                      )
                                    }
                                    else if (startType === 'CONTINUE_NEW_FLOW') {
                                      if (index === 0) {
                                        return (
                                          <>
                                            <Avatar style={{
                                              marginRight: '5px'
                                            }} name={props?.approve?.internalApprovePopup?.data?.approve?.user?.name} size="26" round={true} />
                                            <span className="text-muted">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.CONTINUE_NEW_FLOW_FIRST', { ownerName: ownerName, startDate: startDate })}</span>
                                          </>
                                        )
                                      } else {
                                        return (
                                          <>
                                            <Avatar style={{
                                              marginRight: '5px'
                                            }} name={props?.approve?.internalApprovePopup?.data?.approve?.user?.name} size="26" round={true} />
                                            <span className="text-muted">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.CONTINUE_NEW_FLOW', { ownerName: ownerName, startDate: startDate })}</span>
                                          </>
                                        )
                                      }

                                    }

                                  })()
                                }

                              </span>

                            </div>
                            {file?.userNote && (
                              <span className="w-100">
                                <strong>Not:</strong> {file?.userNote}
                              </span>
                            )}
                            {/* <div className="approve-file-original-file">{file?.originalFile?.filename}

                          </div>
                          <div className="approve-file-approve-file">{file?.approveFile?.filename || '-'}</div> */}
                          </div>
                          <div className="approve-file-steps">
                            {file?.steps?.map((step: any, stepIndex: number) => {

                              return (
                                <div key={step?._id + file?._id + stepIndex} className={`approve-file-step ${step?.status === "PENDING" ? " approve-file-step-active" : ""}`}>
                                  <div className="approve-file-step-header">
                                    <div className="approve-file-step-header-title">
                                      <span>{stepIndex + 1}. {t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.STEP')}</span> <StepStatusBadge className={step?.status} title={t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.' + step?.status)} />
                                      {(props?.approve?.internalApprovePopup?.data?.processAdmin === true && step?.status === 'PENDING') && (
                                        <Button
                                          variant="primary"
                                          style={{
                                            margin: '0 5px'
                                          }}
                                          size="sm"
                                          onClick={() => {
                                            apiInstance.post(`/approve-files/mail-resend/${props?.companies?.company?._id}`, {
                                              approveFileId: file?._id,
                                              stepId: step?._id,
                                            }).then((res) => {
                                              if (res?.data?.node?.success === true) {
                                                toast.success(t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.RESEND_MAIL_SUCCESS'));
                                              }
                                            }).catch((err) => {
                                              console.log(err);
                                            })
                                          }}
                                          className="d-flex align-items-center justify-content-center gap-1">
                                          <FeatherIcons icon="mail" size={12} />
                                          <span className="p-0 m-0" style={{
                                            fontSize: '12px'
                                          }}>
                                            Resend
                                          </span>
                                        </Button>
                                      )}
                                    </div>
                                    <div className="approve-file-step-header-action">

                                      {step?.stepUsers?.length > 1 && (
                                        <>
                                          {(step?.aproveAll === true) ? (<div style={{ fontSize: 14 }} className="approve-file-step-header-action-all">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.FROM_EVERYONE_MESSAGE')}</div>) : (<div style={{ fontSize: 14 }} className="approve-file-step-header-action-all">{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.FROM_ANYONE_MESSAGE')}</div>)}
                                        </>
                                      )}


                                      <Button
                                        onClick={() => {
                                          toggleAccordion(`${step?._id}${file?._id}`);
                                        }}
                                        variant="link"
                                      >
                                        {!opens.includes(`${step?._id}${file?._id}`) ? (<IoMdArrowDropup color="white" size={16} />) : (<IoMdArrowDropdown color="white" />)}
                                      </Button>
                                    </div>
                                  </div>
                                  {!opens.includes(`${step?._id}${file?._id}`) && (
                                    <div className="approve-file-step-body">
                                      <div className="approve-file-step-users">
                                        {step?.stepUsers?.map((user: any, userIndex: number) => {
                                          return (
                                            <>
                                              {step?.stepUsers[userIndex - 1]?.status === 'REASSIGNED' ? null : null}
                                              <div key={user?._id + userIndex} className="approve-file-step-user">

                                                <div className="approve-file-step-user-detail">

                                                  <div className="approve-file-step-user-avatar">
                                                    <Avatar name={user?.name || user?.email} size="30" round={true} />
                                                    {step?.stepUsers[userIndex - 1]?.status === 'REASSIGNED' ? (<span className="top-connector"></span>) : null}
                                                  </div>
                                                  <div className="approve-file-step-user-name" title={user?.email}>{user?.name || user?.email}</div>

                                                  {user?.message && user?.message.length > 0 && (
                                                    <div className="approve-file-step-user-message">


                                                      <>
                                                        <OverlayTrigger
                                                          key={'right' + userIndex}

                                                          placement="right"
                                                          overlay={
                                                            <Tooltip
                                                              id={'tooltip-right' + userIndex}
                                                            >
                                                              {user?.message}
                                                            </Tooltip>
                                                          }
                                                        >
                                                          <span><MdOutlineMessage color="blue" size={20} /></span>
                                                        </OverlayTrigger>
                                                      </>



                                                    </div>
                                                  )}



                                                </div>
                                                <div className="approve-file-step-user-status">

                                                  {(() => {
                                                    var rejectedStep: any = {};
                                                    var rejectedUser: any = {};
                                                    if (file.status == 'REJECTED') {
                                                      // Find REJECTED step
                                                      rejectedStep = file?.steps?.find((step: any) => step.status == 'REJECTED')
                                                      // find REJECTED OR CHANGE_REQUESTED user
                                                      rejectedUser = rejectedStep?.stepUsers?.find((user: any) => user.status == 'REJECTED' || user.status == 'CHANGE_REQUESTED')
                                                    }

                                                    return (
                                                      <InternalApprovePopupStepUserStatusBadge
                                                        rejectedUser={rejectedUser || {}}
                                                        rejectedStep={rejectedStep || {}}
                                                        fileStatus={file?.status}
                                                        stepStatus={step?.status}
                                                        userStatus={user?.status} />
                                                    )

                                                  })()}


                                                </div>
                                              </div>
                                              {user?.status === 'CHANGE_REQUESTED' ? (
                                                <>

                                                  <div className="approve-file-step-user-change-requested">
                                                    <span className="added-file">
                                                      <span className="top-connector"></span>
                                                      <FeatherIcons icon="file" size={20} />
                                                    </span>
                                                    <span>{t('APPROVE_PROCESS.APPROVE_PROCESS_POPUP.FILE_MESSAGES.FILE_UPLOADED')}</span>
                                                  </div>
                                                </>
                                              ) : null}
                                            </>
                                          )
                                        })}

                                      </div>
                                    </div>
                                  )}


                                </div>
                              )
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )
              })}

            </div>

          </div>

        </Modal.Body>
      </Modal>


      {/* Modals:Start */}


      <Modal
        show={continueFlow}
        onHide={() => setContinueFlow(false)}
        backdropClassName="shadow"
        contentClassName="shadow"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Akışa Devam Et
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (<div
            className="preloader"
            style={{
              height: '100%',
              background: 'white',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              textAlign: 'center',
              minHeight: '400px'
            }}>
            <div style={{ width: '300px' }}>
              <Lootie></Lootie>
              <p>Belge hazırlanırken lütfen bekleyiniz...</p>
            </div>

          </div>) : (
            <div className="save-modal">
              <p>Bir önceki akışta son kaldığınız adımdan onay akışı devam edecektir. Onaylıyor musunuz?</p>
              <div className="form-group">
                <Button
                  disabled={!loading ? false : true}
                  onClick={() => {
                    let beforeFile = props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles.length - 2]?._id
                    let uploadedFile = props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles.length - 1]?._id
                    setLoading(true)

                    if (!beforeFile || !uploadedFile) {
                      toast.error('Bir hata oluştu. Sayfayı yenileyip tekrar deneyiniz.');
                      setLoading(false)
                      return
                    }

                    apiInstance.post(`/approve-files/continue-flow/${props?.companies?.company?._id}`, {
                      beforeFile: beforeFile,
                      uploadedFile: uploadedFile,
                    }).then((res: any) => {
                      toast.success('Şablon başarıyla güncellendi');
                      setContinueFlow(false)
                      props?.getReadApproveProcess({
                        id: props?.approve?.internalApprovePopup?.data?.approve?._id
                      })

                    })
                      .catch((err: any) => {
                        setContinueFlow(false)
                        const errorResponse: TGenericResponse = ErrorResponse(err);
                        toggleGenericErrorModal(dispatch, errorResponse);

                      })
                      .finally(() => {

                        setLoading(false)
                      })
                  }}
                  variant="primary" className="save-button" style={{ marginRight: 5 }}>
                  Onayla
                </Button>
                <Button onClick={() => setContinueFlow(false)} variant="outline-primary" className="cancel-button">
                  İptal
                </Button>
              </div>
            </div>
          )}

        </Modal.Body>
      </Modal>


      <Modal
        show={continueFlowFromScratch}
        onHide={() => setContinueFlowFromScratch(false)}
        backdropClassName="shadow"
        contentClassName="shadow"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Aynı Akışı Yeniden Başlat
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {loading ? (<div
            className="preloader"
            style={{
              height: '100%',
              background: 'white',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              textAlign: 'center',
              minHeight: '400px'
            }}>
            <div style={{ width: '300px' }}>
              <Lootie></Lootie>
              <p>Belge hazırlanırken lütfen bekleyiniz...</p>
            </div>

          </div>) : (
            <div className="save-modal">
              <p>Bir önceki onay akışı yeni belge için baştan başlatılarak devam edecek. Onaylıyor musunuz?</p>
              <div className="form-group">
                <Button
                  disabled={!loading ? false : true}
                  onClick={() => {
                    let beforeFile = props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles.length - 2]?._id
                    let uploadedFile = props?.approve?.internalApprovePopup?.data?.approve?.approveFiles?.[props?.approve?.internalApprovePopup?.data?.approve?.approveFiles.length - 1]?._id
                    setLoading(true)

                    if (!beforeFile || !uploadedFile) {
                      toast.error('Bir hata oluştu. Sayfayı yenileyip tekrar deneyiniz.');
                      setLoading(false)
                      return
                    }

                    apiInstance.post(`/approve-files/continue-flow-scratcjh/${props?.companies?.company?._id}`, {
                      beforeFile: beforeFile,
                      uploadedFile: uploadedFile,
                    }).then((res: any) => {
                      toast.success('Şablon başarıyla güncellendi');
                      setContinueFlowFromScratch(false)

                    }).finally(() => {
                      props?.getReadApproveProcess({
                        id: props?.approve?.internalApprovePopup?.data?.approve?._id
                      })
                      setLoading(false)
                    })
                  }}
                  variant="primary" className="save-button" style={{ marginRight: 5 }}>
                  Onayla
                </Button>
                <Button variant="outline-primary" onClick={() => setContinueFlowFromScratch(false)}>İptal</Button>
              </div>
            </div>
          )}


        </Modal.Body>
      </Modal>


      {/* Modals:End */}

    </>




  );
};

export default connect(mapStateToProps, mapActionsToProps)(InternalApprovePopup);
