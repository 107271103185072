import React, { useEffect, useState } from "react";
import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from 'react-i18next';

import { setFileData, editTemplate, createDocumentFromTemplate } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";
import reactStringReplace from 'react-string-replace';

import './index.scss';
import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import Avatar from "react-avatar";
import apiInstance from "@clients/api.client";
import moment from "moment";
import FlatList from "@components/FlatList";
import { DefaultLoader } from "@components/Loader";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import ProcessLabel from "./ProcessLabel";
import ProcessApprovalLabel from "./ProcessApprovalLabel";

const mapStateToProps = (state: any) => ({
  components: state?.components,
  companies: state?.companies,
  files: state?.files,
  users: state?.users,
  contracts: state?.contracts
});
const mapActionsToProps = {
  toggleGenericContractOverviewModal
};


const ActivityFeed = (props: any) => {
  const { t, i18n } = useTranslation();

  const [showLongDate, setShowLongDate] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [refreshed, setRefreshed] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [batchSize, setBatchSize] = useState<number>(1000);
  const [total, setTotal] = useState<number>(0);
  const [more, setMore] = useState<boolean>(true);
  const [data, setData] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [refreshFeed, setRefreshFeed] = useState<boolean>(false);

  useEffect(() => {

    if (refreshed === true) {
      apiInstance.get(`/feeds/${props?.companies?.company?._id}/${props?.contracts?.processOverview?._id}`, {
        params: {
          page: 1,
          batchSize: batchSize || 1000,
          more: {
            searchText: searchText
          }
        }
      }).then((res: any) => {
        res = res?.data;
        console.log(res, '@@@')
        if (res?.pagination?.totalCount) setTotal(res?.pagination?.totalCount);
        if ((res?.pagination?.page * batchSize) < res?.pagination?.totalCount) {
          setMore(true);
          setPage(page + 1);
        } else {
          setMore(false)
        }
        setData([...res?.node?.activies || []])
        setLoading(false);
        setRefreshed(false)
      })
    }

  }, [refreshed])



  useEffect(() => {
    setTimeout(() => {
      setRefreshed(true)
    }, 300);
  }, [props?.contracts])

  useEffect(() => {
    props?.commentRefresh === true && setRefreshed(true);
  }, [props?.commentRefresh])

  const [count, setCount] = useState(10)

  function loadMore() {
    console.log('LOAD MORE')
  }

  return (
    <>

      {
        (refreshed) &&
        <div className="d-flex align-items-center justify-content-center" style={{
          minHeight: '150px',
          width: '100%'
        }}>
          <DefaultLoader />
        </div>
      }


      <div className="activity-feed">
        {!refreshed && <FlatList
          scrollableParentTarget={"contractContactsFlatList"}
          data={data}
          renderItem={(item: any, index: number) => (
            <>

              <div className="af-item">
                <div className="af-item-header">
                  <Avatar style={{
                    fontSize: '12px'
                  }} name={`${item?.user?.name} ${item?.user?.surname}`} size="30" round={true} />
                  <div className="af-item-header-detail">
                    <div className="af-item-header-detail-message">

                      {item?.event === 'updateProcessStatusEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the process status.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessTypeEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the process type.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessTitleEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the process name.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessDescriptionEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the process description.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessStartDateEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the start date.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessEndDateEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the end date.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessEffectiveDateEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the effective date.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessRenewalDateEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the renewal date.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessSignedDateEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated the signed date.
                        </Trans>
                      )}
                      {item?.event === 'createCommentEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> left a comment.
                        </Trans>
                      )}
                      {item?.event === 'createProcessEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> created the process.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessMakePrivateEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> changed visibility to private.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessMakePublicEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> changed visibility to public.
                        </Trans>
                      )}
                      {item?.event === 'removeAssignUserEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> removed <strong>{{ removedUser: `@${item?.details?.removedUser?.fullname}` }}</strong> from the process.
                        </Trans>
                      )}
                      {item?.event === 'addAssignUserEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> assigned <strong>{{ addedUser: `@${item?.details?.addedUser?.fullname}` }}</strong> to the process.
                        </Trans>
                      )}
                      {item?.event === 'createSubProcessEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> selected a sub process.
                        </Trans>
                      )}
                      {item?.event === 'createMainProcessEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> selected a main process.
                        </Trans>
                      )}
                      {item?.event === 'linkProcessWithDocumentEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> added a document.
                        </Trans>
                      )}
                      {item?.event === 'addEventToProcessEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> added an event.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessCustomFieldEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> updated a custom field.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessAddExternalCompanyEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> added external parties.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessAddInternalCompanyEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> added counter parties.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessRemoveExternalCompanyEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> removed external paties.
                        </Trans>
                      )}
                      {item?.event === 'updateProcessRemoveInternalCompanyEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}>
                          <strong>{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong> removed counter parties.
                        </Trans>
                      )}

                      {item?.event === 'uploadProcessApprovalVersionEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}
                          components={[
                            <strong key="1">{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong>,
                            <span key="3">{{ name2: item?.details?.changedVersions?.versions?.[0]?.filename }}</span>,
                            <ProcessApprovalLabel key="5" title={item?.processApproval?.title} contractId={item?.contract?._id} processApprovalId={item?.prpcessApproval?._id} />
                          ]}
                        >

                        </Trans>
                      )}
                      {item?.event === 'createProcessApprovalEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}
                          components={[
                            <strong key="1">{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong>,
                            <ProcessApprovalLabel key="5" title={item?.processApproval?.title} contractId={item?.contract?._id} processApprovalId={item?.prpcessApproval?._id} />
                          ]}>

                        </Trans>
                      )}
                      {item?.event === 'approveProcessApprovalEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}
                          components={[
                            <strong key="1">{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong>,
                            <ProcessApprovalLabel key="5" title={item?.processApproval?.title} contractId={item?.contract?._id} processApprovalId={item?.prpcessApproval?._id} />
                          ]}
                        >

                        </Trans>
                      )}
                      {item?.event === 'assignProcessApprovalEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}
                          components={[
                            <strong key="1">{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong>,
                            <ProcessApprovalLabel key="3" title={item?.processApproval?.title} contractId={item?.contract?._id} processApprovalId={item?.prpcessApproval?._id} />,
                            <span key="5">{{ name3: item?.details?.userTo?.fullname || item?.details?.userTo?.email }}</span>
                          ]}
                        ></Trans>
                      )}
                      {item?.event === 'openFileEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}
                          components={[
                            <strong key="1">{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong>,
                            <span key="3">{{ name2: item?.file?.filename }}</span>
                          ]}
                        >

                        </Trans>
                      )}
                      {item?.event === 'downloadFileEvent' && (
                        <Trans i18nKey={`ACTIVITY_FEED.PROCESS_OVERVIEW.EVENTS.${item?.event}`}
                          components={[
                            <strong key="1">{{ name: item?.user?.name + ' ' + item?.user?.surname }}</strong>,
                            <span key="3">{{ name2: item?.file?.filename }}</span>
                          ]}
                        >

                        </Trans>
                      )}
                      {/* <pre>
                        {
                          JSON.stringify(item, null, 2)
                        }
                      </pre> */}

                    </div>
                    <div className="af-item-header-detail-date" onClick={(e: any) => setShowLongDate(!showLongDate)}>{
                      showLongDate ? moment(item?.createdAt).format('DD MMM YYYY HH:mm') : moment(item?.createdAt).fromNow()
                    }</div>
                  </div>
                </div>
                <div className="activity-feed__item__details">

                  {item?.event === 'updateProcessStatusEvent' && (
                    <div className="oldNewDetail">
                      <div className="oldValue">{
                        item?.details?.oldValue?.title
                      }</div>
                      <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                      <div className="newValue">{
                        item?.details?.newValue?.title
                      }</div>
                    </div>
                  )}
                  {item?.event === 'updateProcessTypeEvent' && (
                    <div className="oldNewDetail">
                      <div className="oldValue">{
                        item?.details?.oldValue?.title
                      }</div>
                      <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                      <div className="newValue">{
                        item?.details?.newValue?.title
                      }</div>
                    </div>
                  )}
                  {item?.event === 'updateProcessTitleEvent' && (
                    <div className="oldNewDetail">
                      <div className="oldValue">{
                        item?.details?.oldValue
                      }</div>
                      <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                      <div className="newValue">{
                        item?.details?.newValue
                      }</div>
                    </div>
                  )}
                  {item?.event === 'updateProcessDescriptionEvent' && (
                    <>
                      <ReactDiffViewer
                        oldValue={item?.details?.oldValue?.toString() || ''}
                        newValue={item?.details?.newValue?.toString() || ''}
                        splitView={true}
                        showDiffOnly={true}
                        extraLinesSurroundingDiff={1}
                        compareMethod={DiffMethod?.WORDS_WITH_SPACE as any}




                      />
                    </>
                  )}
                  {item?.event === 'updateProcessStartDateEvent' && (
                    <div className="oldNewDetail">
                      <div className="oldValue">{
                        moment(item?.details?.oldValue).format('DD.MM.YYYY')
                      }</div>
                      <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                      <div className="newValue">{
                        moment(item?.details?.newValue).format('DD.MM.YYYY')
                      }</div>
                    </div>
                  )}
                  {item?.event === 'updateProcessEndDateEvent' && (
                    <div className="oldNewDetail">
                      <div className="oldValue">{
                        moment(item?.details?.oldValue).format('DD.MM.YYYY')
                      }</div>
                      <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                      <div className="newValue">{
                        moment(item?.details?.newValue).format('DD.MM.YYYY')
                      }</div>
                    </div>
                  )}
                  {item?.event === 'updateProcessEffectiveDateEvent' && (
                    <div className="oldNewDetail">
                      <div className="oldValue">{
                        moment(item?.details?.oldValue).format('DD.MM.YYYY')
                      }</div>
                      <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                      <div className="newValue">{
                        moment(item?.details?.newValue).format('DD.MM.YYYY')
                      }</div>
                    </div>
                  )}
                  {item?.event === 'updateProcessRenewalDateEvent' && (
                    <div className="oldNewDetail">
                      <div className="oldValue">{
                        moment(item?.details?.oldValue).format('DD.MM.YYYY')
                      }</div>
                      <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                      <div className="newValue">{
                        moment(item?.details?.newValue).format('DD.MM.YYYY')
                      }</div>
                    </div>
                  )}
                  {item?.event === 'updateProcessSignedDateEvent' && (
                    <div className="oldNewDetail">
                      <div className="oldValue">{
                        moment(item?.details?.oldValue).format('DD.MM.YYYY')
                      }</div>
                      <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                      <div className="newValue">{
                        moment(item?.details?.newValue).format('DD.MM.YYYY')
                      }</div>
                    </div>
                  )}
                  {item?.event === 'createCommentEvent' && (
                    <div className="comment-box">
                      <div className="icon">
                        <FeatherIcons icon="message-circle" size={20} />
                      </div>
                      <div className="message">
                        {reactStringReplace(item?.details?.comment?.comment, /(@\[[a-zA-Z0-9 wığüşöçĞÜŞÖÇİ]+\]\([a-zA-Z0-9]+\))/gi, (match, i) => {
                          let regex = new RegExp(/@\[([a-zA-Z0-9 wığüşöçĞÜŞÖÇİ]+)\]\(([a-zA-Z0-9]+)\)/g);
                          let matches: any = regex.exec(match) || [];
                          return (
                            <span
                              className="badge bg-primary"
                              onClick={() => console.log(matches[2])} key={i} style={{ cursor: 'pointer' }}>@{matches[1]}</span>
                          )
                        })}
                      </div>

                    </div>
                  )}
                  {/* <pre>
                    {
                      JSON.stringify(item, null, 2)
                    }
                  </pre> */}
                  {item?.event === 'createSubProcessEvent' && (
                    <div

                      className="subprocess-box d-flex align-items-center justify-content-start gap-1" onClick={(event: any) => props.toggleGenericContractOverviewModal({ contractId: item?.details?.mainProcess?._id, show: true })}>
                      <div className="icon">
                        <FeatherIcons icon="link" size={21} />
                      </div>
                      <div style={{
                        color: 'blue',
                        cursor: 'pointer'

                      }} className="message">
                        <div className="title">{item?.details?.mainProcess?.title}</div>
                      </div>
                    </div>
                  )}

                  {item?.event === 'createMainProcessEvent' && (
                    <div

                      className="subprocess-box d-flex align-items-center justify-content-start gap-1" onClick={(event: any) => props.toggleGenericContractOverviewModal({ contractId: item?.details?.subProcesses[0]?._id, show: true })}>
                      <div className="icon">
                        <FeatherIcons icon="link" size={21} />
                      </div>
                      <div style={{
                        color: 'blue',
                        cursor: 'pointer'

                      }} className="message">

                        <div className="title">{item?.details?.subProcesses[0]?.title}</div>
                      </div>
                    </div>
                  )}

                  {item?.event === 'linkProcessWithDocumentEvent' && (
                    <div

                      className="subprocess-box d-flex align-items-center justify-content-start gap-1" onClick={(event: any) => {
                        console.log('sadads', item?.file)
                      }}>
                      <div className="icon">
                        <FeatherIcons icon="file" size={21} />
                      </div>
                      <div style={{
                        color: 'blue',
                        cursor: 'pointer'

                      }} className="message d-flex">
                        <div
                          onClick={(event: any) => props.toggleGenericContractOverviewModal({ contractId: item?.contract?._id, show: true })}
                          className="title dotted-f-dd cursor-pointer">{item?.file?.filename?.split('.')[0]}</div> .{item?.file?.filename?.split('.')[1]}
                      </div>
                    </div>
                  )}
                  {item?.event === 'uploadProcessApprovalVersionEvent' && (
                    <div

                      className="subprocess-box d-flex align-items-center justify-content-start gap-1" onClick={(event: any) => {
                        console.log('sadads', item?.file)
                      }}>
                      <div className="icon">
                        <FeatherIcons icon="file" size={21} />
                      </div>
                      <div style={{
                        color: 'blue',
                        cursor: 'pointer'

                      }} className="message d-flex">
                        <div
                          className="title dotted-f-dd cursor-pointer">{item?.details?.changedVersions?.versions?.[1]?.filename?.split('.')[0]}</div> .{item?.details?.changedVersions?.versions?.[1]?.filename?.split('.')[1]}
                      </div>
                    </div>
                  )}
                  {item?.event === 'addEventToProcessEvent' && (
                    <div

                      className="subprocess-box d-flex align-items-center justify-content-start gap-1" onClick={(event: any) => {
                        console.log('sadads', item?.reminder?.title)
                      }}>
                      <div className="icon">
                        <FeatherIcons icon="calendar" size={21} />
                      </div>

                      <div style={{
                        color: 'blue',
                        cursor: 'pointer'

                      }} className="message d-flex">
                        <div
                          onClick={(event: any) => props.toggleGenericContractOverviewModal({ contractId: item?.contract?._id, show: true })}
                          className="title dotted-f-dd">{item?.reminder?.title}</div>
                      </div>
                    </div>
                  )}



                  {item?.event === 'updateProcessCustomFieldEvent' && (
                    <div>
                      {/* <pre>{
                        JSON.stringify(item.details, null, 2)
                      }</pre> */}
                      <strong>{item?.details?.fieldDetail?.title}:</strong>
                      <div className="oldNewDetail">
                        <div className="oldValue">
                          {item?.details?.fieldDetail?.CustomComponent === 'Date' ? moment(item?.details?.oldValue?.value).format('DD.MM.YYYY') : item?.details?.oldValue?.value}
                        </div>
                        <div><FeatherIcons size="14" icon="arrow-right" /> </div>
                        <div className="newValue">
                          {item?.details?.fieldDetail?.CustomComponent === 'Date' ? moment(new Date(item?.details?.newValue?.value)).format('DD.MM.YYYY') : item?.details?.newValue?.value}
                        </div>
                      </div>
                    </div>
                  )}


                  {item?.event === 'updateProcessAddExternalCompanyEvent' && (
                    <div>
                      <div className="oldNewDetail">

                        <div className="newValue">
                          {item?.details?.companies?.map?.((c: any) => c?.title)?.join?.(', ')}
                        </div>
                      </div>
                    </div>
                  )}
                  {item?.event === 'updateProcessAddInternalCompanyEvent' && (
                    <div>
                      <div className="oldNewDetail">

                        <div className="newValue">
                          {item?.details?.companies?.map?.((c: any) => c?.title)?.join?.(', ')}
                        </div>
                      </div>
                    </div>
                  )}
                  {item?.event === 'updateProcessRemoveExternalCompanyEvent' && (
                    <div>
                      <div className="oldNewDetail">

                        <div className="oldValue">
                          {item?.details?.companies?.map?.((c: any) => c?.title)?.join?.(', ')}
                        </div>
                      </div>
                    </div>
                  )}
                  {item?.event === 'updateProcessRemoveInternalCompanyEvent' && (
                    <div>
                      <div className="oldNewDetail">

                        <div className="oldValue">
                          {item?.details?.companies?.map?.((c: any) => c?.title)?.join?.(', ')}
                        </div>
                      </div>
                    </div>
                  )}



                </div>

              </div>
            </>
          )}
          hasMore={more}
          endMessage={
            <></>
          }
          onEndReached={() => {
            loadMore()
          }}
          onEndReachedThreshold={0.5}
          keyExtractor={(item, index) => index.toString()}
        />
        }
      </div>


    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ActivityFeed);
