import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    TEXT_ALIGN_TYPES,
} from '@components/SlateEditor/constants/index.constant'
const ElementStyle = (props: {
    activeStyle: any
}) => {

    const editor = useSlate()

    return (
        <Dropdown
            className='p-0 m-0'>
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip>
                    Style
                </Tooltip>
            }>
                <Dropdown.Toggle
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                    }}
                    as={Button}>
                    <span>{props?.activeStyle}</span>
                    <FaChevronDown style={{ marginLeft: '3px' }} size={10} />
                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu className='slate-toolbar-dd-menu'
            >
                <Dropdown.Item active={isBlockActive(
                    editor,
                    'paragraph'
                )}
                    onMouseDown={(event: any) => {
                        event.preventDefault()
                        toggleBlock(editor, 'paragraph')
                    }}
                >Paragraph</Dropdown.Item>
                <Dropdown.Item active={
                    isBlockActive(
                        editor,
                        'heading-one'
                    )
                }
                    onMouseDown={(event: any) => {
                        event.preventDefault()
                        toggleBlock(editor, 'heading-one')

                    }}
                >Heading 1</Dropdown.Item>
                <Dropdown.Item active={
                    isBlockActive(
                        editor,
                        'heading-two'
                    )
                }
                    onMouseDown={(event: any) => {
                        event.preventDefault()
                        toggleBlock(editor, 'heading-two')

                    }}>Heading 2</Dropdown.Item>
                <Dropdown.Item active={
                    isBlockActive(
                        editor,
                        'heading-three'
                    )

                }
                    onMouseDown={(event: any) => {
                        event.preventDefault()
                        toggleBlock(editor, 'heading-three')

                    }}>Heading 3</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default ElementStyle