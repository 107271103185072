import React, { useState } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import './index.scss';
//TYPES
import { FileUploaderProps, FileType } from '@components/FileUploader/interfaces/index.interfaces';
import { useTranslation } from 'react-i18next';
import { VscTriangleDown } from 'react-icons/vsc';
import {
    MdCloud, MdComputer
} from 'react-icons/md';

import { AiOutlineCloud, AiOutlinePlusCircle } from 'react-icons/ai';
import { BsPlusSquare } from 'react-icons/bs';

const FileUploadDropdown: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    return (
        <Dropdown className="cdn-upload-dropdown">
            <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: "20px",
                height: "45px",
                background: "#3C7AD8",
                borderColor: "#3C7AD8",
                outline: "none",
                minWidth: "200px"
            }}>
                {t('POPUPS.CDN.ADD_NEW')}
                <VscTriangleDown style={{ marginLeft: "10px" }} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="cdn-upload-dropdown-menu">
                <Dropdown.Item onClick={() => {
                    const element = document.getElementById("cdn-upload-from-computer");
                    if (element) {
                        element.click();
                    }
                }}>
                    <MdComputer size="22px" />
                    {t('POPUPS.CDN.FROM_COMPUTER')}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => {
                    props?.onOpenImportPopup()
                }}>
                    <AiOutlineCloud size="22px" />
                    {t('POPUPS.CDN.FROM_CLOUD')}
                </Dropdown.Item>
                {/* <Dropdown.Item href="#/action-3">
                    <AiOutlinePlusCircle size="22px" />
                    Create a new file
                </Dropdown.Item> */}
            </Dropdown.Menu>
        </Dropdown>
    )
}

const FileUploader: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);

    /**
     * Handled the accepted files and shows the preview
     */
    const handleAcceptedFiles = (files: FileType[]) => {
        var allFiles = files;

        if (props.showPreview) {
            (files || []).map((file) =>
                Object.assign(file, {
                    preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
                    formattedSize: formatBytes(file.size),
                })
            );
            allFiles = [...selectedFiles];
            allFiles.push(...files);
            setSelectedFiles(allFiles.slice(0, 20));
        }

        if (props.onFileUpload) props.onFileUpload(allFiles);
    };

    /**
     * Formats the size
     */
    const formatBytes = (bytes: number, decimals: number = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    /*
     * Removes the selected file
     */
    const removeFile = (e: any, fileIndex: number) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(fileIndex, 1);
        setSelectedFiles(newFiles);
        if (props.onFileUpload) props.onFileUpload(newFiles);
    };

    return (
        <>
            <Dropzone maxFiles={20} multiple {...props}
                onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                    <div
                        className={classNames('dropzone', 'dz-clickable', {
                            'dz-started': selectedFiles && selectedFiles.length > 0,
                        }) + "d-flex"} style={{
                            height: "100%"
                        }}>
                        <div {...getRootProps()} style={{
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap"
                        }}>
                            <div className="dz-message needsclick"
                                style={{
                                    flexDirection: "column",
                                    display: selectedFiles.length === 0 ? "flex" : "none",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "80%"
                                }}>
                                <input {...getInputProps()} id="cdn-upload-from-computer" />
                                {(selectedFiles || []).length === 0 && (
                                    <div style={{
                                        flexDirection: "column",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "100%"
                                    }}>
                                        <i className="h1 text-muted uil-cloud-upload"></i>
                                        <h3>{t("POPUPS.UPLOAD_FILES.DROP_FILES_OR_CLICK")}</h3>
                                        <span className="text-muted ">
                                            ({t("POPUPS.UPLOAD_FILES.DROP_FILES_OR_CLICK_DESCRIPTION")})
                                        </span>
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <FileUploadDropdown {...props} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {props.showPreview &&
                                (selectedFiles || []).map((f, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            {f.preview && (
                                                <div
                                                    onClick={(e) => e.stopPropagation()}
                                                    className="dz-preview dz-processing dz-error dz-complete dz-image-preview">
                                                    <div className="dz-image">
                                                        <img
                                                            key={i}
                                                            data-dz-thumbnail=""
                                                            alt={f.name}
                                                            src={f.preview}
                                                        />
                                                    </div>

                                                    <div className="dz-details">
                                                        <div className="dz-size">
                                                            <span data-dz-size="">
                                                                <strong>{f.formattedSize}</strong> KB
                                                            </span>
                                                        </div>
                                                        <div className="dz-filename">
                                                            <span data-dz-name="">{f.name}</span>
                                                        </div>
                                                    </div>

                                                    <div className="dz-remove">
                                                        <Button variant="" onClick={(e) => removeFile(e, i)}>
                                                            <i className="uil uil-multiply"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                            {!f.preview && (
                                                <div
                                                    onClick={(e) => e.stopPropagation()}
                                                    className="dz-preview dz-file-preview dz-processing dz-error dz-complete">
                                                    <div className="dz-image">
                                                        <img data-dz-thumbnail="" alt="" />
                                                    </div>
                                                    <div className="dz-details">
                                                        <div className="dz-size">
                                                            <span data-dz-size="">
                                                                <strong>{f.formattedSize}</strong> KB
                                                            </span>
                                                        </div>
                                                        <div className="dz-filename">
                                                            <span data-dz-name="">{f.name}</span>
                                                        </div>
                                                    </div>

                                                    <div className="dz-remove">
                                                        <Button variant="" onClick={(e) => removeFile(e, i)}>
                                                            <i className="uil uil-multiply"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </Dropzone>
        </>
    );
};

FileUploader.defaultProps = {
    showPreview: true,
};

export default FileUploader;
