import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { Reducer } from "redux";
import UnknownUser from '@assets/images/users/unknown.jpg';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

const initialState = {
    pagination: {
        totalCount: 0,
        searchText: null,
        page: 1,
        batchSize: 50,
    },
    uploadedFiles: [],
    files: new Map(),
    endedFetchingFiles: false,
    versions: [],
    fetchedVersions: false,
    fileData: {},
    creatingTemplate: false,
    creatingDocumentFromTemplate: false,
    generatingExportLink: false,
    exportLinks: {},
    templateData: {
    },
    tables: {
        refreshTemplatesTable: false,
        templatesTable: new Map(),
        templatesPagination: {
            page: 1,
            batchSize: 10,
            totalCount: 0
        },
    },
    cdn: {
        isImportedFiles: false,
        uploadStarted: false,
        uploadedFiles: [],
        files: [] || [...Array(10).fill(1).map(() => {
            return {
                isUploaded: false,
                type: 'pdf',
                filename: 'unknown.jpg',
                _id: 'dfsnkjnfdskjgnjklsfdgn',
                fileSize: '225 KB',
                contract: null
            }
        })],
        googleDriveFiles: [],
        uploadCompleted: false,
        exportSources: [],
        selectedPermissionModalFile: {},
        selectedSharePointPermissionModalFile: {},
        refreshTableList: false
    },
    newProcessUploadedFiles: [],
}


const FilesReducer: Reducer = (state = initialState, action: any): any => {
    switch (action.type) {

        case ActionDispatchTypes?.REFRESH_TABLE_LIST:
            return {
                ...state,
                refreshTableList: true,
            }

        case ActionDispatchTypes.GET_TEMPLATES_LIST_SUCCESSFUL:

            console.log('firstasdasd', action?.payload?.node?.templates)
            state?.tables?.templatesTable?.set(
                action?.payload?.pagination?.page,
                action?.payload?.node?.templates.map((item: any, index: number) => {
                    return {
                        index: ((action?.payload?.pagination?.page - 1) * action?.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        createdAt: moment(item?.createdAt).format('LLLL')
                    }
                })
            );
            return {
                ...state,
                tables: {
                    ...state?.tables,
                    ...state?.tables?.refreshTemplatesTable && { refreshTemplatesTable: false },
                    templatesPagination: {
                        ...state?.tables?.templatesPagination,
                        ...action?.payload?.pagination
                    }
                }
            }


        case ActionDispatchTypes?.SET_FILE_TO_GENERIC_PERMISSION_MODAL:
            //window.location.href = "/";
            return {
                ...state,
                selectedPermissionModalFile: { ...action?.payload }
            }
        case ActionDispatchTypes?.SET_FILE_TO_SHAREPOINT_GENERIC_PERMISSION_MODAL:
            //window.location.href = "/";
            return {
                ...state,
                selectedSharePointPermissionModalFile: { ...action?.payload }
            }

        case ActionDispatchTypes?.SET_FILE_TO_ONEDRIVE_GENERIC_PERMISSION_MODAL:
            //window.location.href = "/";
            return {
                ...state,
                selectedOneDrivePermissionModalFile: { ...action?.payload }
            }

        case ActionDispatchTypes?.DELETE_COMPANY_SUCCESSFUL:
            //window.location.href = "/";
            return {
                ...initialState
            }

        case ActionDispatchTypes?.LOGOUT:
            //window.location.href = "/";
            return {
                ...initialState
            }
        case ActionDispatchTypes.GET_FILES:
            return {
                ...state,
                endedFetchingFiles: false
            }
        case ActionDispatchTypes.GET_FILES_SUCCESSFUL:
            state.files.set(
                action.payload?.pagination?.page,
                action.payload?.node?.files.map((item: any, index: number) => {
                    return {
                        index: (action.payload?.pagination?.page * action.payload?.pagination?.batchSize) + index + 1,
                        ...item,
                        updateDelete: true
                    }
                }));
            return {
                ...state,
                endedFetchingFiles: true,
                pagination: {
                    ...state.pagination,
                    ...action.payload?.pagination
                },
                refreshTableList: false
            }
        case ActionDispatchTypes.DELETE_FILES_SUCCESSFUL:
            return {
                ...state,
                versions: state.versions.filter((item: any) => item?._id !== action?.payload?.node?.file?._id)
            }
        case ActionDispatchTypes.CREATE_FILES_SUCCESSFUL:
            return {
                ...state,
                uploadedFiles: action?.payload?.node?.files
            }
        case ActionDispatchTypes.GET_FILE_VERSIONS:
            return {
                ...state,
                fetchedVersions: false
            }
        case ActionDispatchTypes.GET_FILE_VERSIONS_SUCCESSFUL:
            return {
                ...state,
                versions: action?.payload?.node?.versions || [],
                fetchedVersions: true
            }
        case ActionDispatchTypes.REMOVE_FILE_VERSION:
            return {
                ...state,
                versions: [],
                fetchedVersions: false
            }
        case ActionDispatchTypes.REMOVE_FILE_FROM_VERSIONS_SUCCESSFUL:
            return {
                ...state,
                versions: state.versions.filter((item: any) => item?._id !== action?.payload?.node?.file?._id)
            }
        case ActionDispatchTypes.SET_FILE_DATA_SUCCESSFUL:
            return {
                ...state,
                fileData: { ...action?.payload }
            }

        case ActionDispatchTypes.CREATE_TEMPLATE:
            return {
                ...state,
                creatingTemplate: true,
                templateData: {}
            }
        case ActionDispatchTypes.CREATE_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                creatingTemplate: false,
                tables: {
                    ...state.tables,
                    refreshTemplatesTable: true
                },
                templateData: { ...action?.payload?.template }
            }


        case ActionDispatchTypes.SET_TEMPLATE_DATA:
            return {
                ...state,
                creatingTemplate: false,
                templateData: { ...action?.payload }
            }

        case ActionDispatchTypes.SET_TEMPLATE_DATA_IN_CUSTOM_FORMS:
            return {
                ...state,
                creatingTemplate: false,
                templateData: {
                    ...action?.payload,
                    customForm: {
                        formFields: action?.customFormsData
                    },
                }
            }


        case ActionDispatchTypes.CREATE_TEMPLATE_FAILED:
            return {
                ...state,
                creatingTemplate: false,
                templateData: {}
            }

        case ActionDispatchTypes.EDIT_TEMPLATE:
            return {
                ...state,
                creatingTemplate: true,
            }
        case ActionDispatchTypes.EDIT_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                creatingTemplate: false,
                templateData: { ...action?.payload?.template }
            }
        case ActionDispatchTypes.EDIT_TEMPLATE_FAILED:
            return {
                ...state,
                creatingTemplate: false,
            }

        case ActionDispatchTypes.CREATE_DOCUMENT_FROM_TEMPLATE:
            return {
                ...state,
                creatingDocumentFromTemplate: true,
            }
        case ActionDispatchTypes.CREATE_DOCUMENT_FROM_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                creatingDocumentFromTemplate: false,
            }
        case ActionDispatchTypes.CREATE_DOCUMENT_FROM_TEMPLATE_FAILED:
            return {
                ...state,
                creatingDocumentFromTemplate: false,
            }


        case ActionDispatchTypes.GET_EXPORT_LINKS:
            return {
                ...state,
                exportLinks: {},
                generatingExportLink: true,
            }
        case ActionDispatchTypes.GET_EXPORT_LINKS_SUCCESSFUL:
            return {
                ...state,
                exportLinks: { ...action?.payload?.node?.exportLinks },
                generatingExportLink: false,
            }
        case ActionDispatchTypes.GET_EXPORT_LINKS_FAILED:
            return {
                ...state,
                generatingExportLink: false,
            }

        case ActionDispatchTypes.DELETE_TEMPLATE:
            return {
                ...state
            }
        case ActionDispatchTypes.DELETE_TEMPLATE_SUCCESSFUL:
            return {
                ...state,
                tables: {
                    ...state.tables,
                    refreshTemplatesTable: true
                },
                templateData: {}
            }
        case ActionDispatchTypes.DELETE_TEMPLATE_FAILED:
            return {
                ...state
            }

        /* CDN */
        case ActionDispatchTypes.CDN_START_UPLOAD:
            return {
                ...state,
                cdn: {
                    ...state?.cdn,
                    uploadStarted: true
                }
            }
        case ActionDispatchTypes.CDN_SET_FILES:
            return {
                ...state,
                cdn: {
                    ...state?.cdn,
                    files: action?.payload?.files
                }
            }
        case ActionDispatchTypes.CDN_SET_PICKER_FILES:
            return {
                ...state,
                cdn: {
                    ...state?.cdn,
                    files: action?.payload?.files,
                    importedSource: action?.payload?.importedSource
                }
            }
        case ActionDispatchTypes.CDN_IS_IMPORTED_FILES:
            return {
                ...state,
                isImportedFiles: action?.payload
            }
        case ActionDispatchTypes.SET_REMOVE_CDN_EXPORT_SOURCE:
            return {
                ...state,
                cdn: {
                    ...state?.cdn,
                    exportSources: state.cdn.exportSources.includes(action.payload)
                        ? state.cdn.exportSources.filter((source: string, index: number) => {
                            return source !== action?.payload
                        })
                        : [...state.cdn.exportSources, action.payload]
                }
            }
        case ActionDispatchTypes.UPLOAD_FILES_CDN:
            return {
                ...state,
                cdn: {
                    ...state?.cdn,
                    uploadStarted: true
                },
                refreshTableList: true,
            }
        case ActionDispatchTypes.UPLOAD_FILES_CDN_SUCCESSFUL:
            const uploadedFiles = [
                ...state.cdn.uploadedFiles,
                ...action?.payload?.response?.node?.files
            ];
            return {
                ...state,
                cdn: {
                    ...state?.cdn,
                    files: state.cdn.files.map((file: any, index: number) => {
                        if (index === action?.payload?.index) {
                            return {
                                ...file,
                                isUploaded: state.cdn?.exportSources?.length - 1 === file?.uploadCount ? true : false,
                                uploadCount: (file.uploadCount || 0) + 1
                            }
                        }
                        return file;
                    }),
                    importedSource: undefined,
                    uploadedFiles: uploadedFiles,
                    uploadCompleted: ((state?.cdn?.files?.length * state?.cdn?.exportSources?.length) === uploadedFiles?.length)
                        ? true
                        : false
                },
                newProcessUploadedFiles: [
                    ...state.newProcessUploadedFiles,
                    ...action?.payload?.response?.node?.files
                ],
                refreshTableList: false,
            }
        case ActionDispatchTypes.RESET_STATE_ON_UPLOAD_COMPLETED:
            return {
                ...state,
                cdn: {
                    ...initialState.cdn,
                },
                refreshTableList: true,
            }
        case ActionDispatchTypes.SET_NEWDATA_TO_NEW_PROCESS_UPLOADED_FILES:
            return {
                ...state,
                newProcessUploadedFiles: action?.payload ? action?.payload : []
            }
        case ActionDispatchTypes.RESET_NEW_PROCESS:
            return {
                ...state,
                cdn: {
                    ...initialState.cdn,
                },
                newProcessUploadedFiles: [],
            }
        case ActionDispatchTypes.RESET_STATE_ON_UPLOAD_COMPLETED_NO_RELOAD:
            return {
                ...initialState,
                newProcessUploadedFiles: state?.newProcessUploadedFiles,
                refreshTableList: true,
            }
        case ActionDispatchTypes.ADD_SELECTED_FILES_TO_NEW_PROCESS:
            return {
                ...state,
                newProcessUploadedFiles: [...action?.payload, ...state?.newProcessUploadedFiles],
            }
        case ActionDispatchTypes.CLOSE_CDN_POPUP:
            return {
                ...state
            }

        case ActionDispatchTypes.ADD_FILES_TO_CONTACT:
            return {
                ...state,
                refreshTableList: true,
            }
        case ActionDispatchTypes.ADD_FILES_TO_CONTACT_SUCCESSFUL:
            return {
                ...state,
                refreshTableList: false,
            }
        case ActionDispatchTypes.ADD_FILES_TO_CONTACT_FAILED:
            return {
                ...state,
                refreshTableList: false,
            }

        default:
            return state;
    }
}

export default FilesReducer;