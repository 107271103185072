import * as ActionDispatchTypes from '@redux/ActionDispatchTypes/index.types';
import { excelCreator } from "@utils/export/excelCreator"
import ErrorResponse from '@redux/utils/ErrorResponse.utils';

import api from '@clients/api.client';
import { toast } from 'react-toastify';
import { TAction, TGenericResponse } from "@redux/interfaces/index.interfaces";

import { toggleGenericErrorModal } from '@redux/utils/components/GenerateErrorModal';
import { toggleGenericSuccessModal } from '@redux/utils/components/GenerateSuccessModal';
import moment from 'moment';

export const toggleProcessApprovalPopup: TAction = (payload: any) => async (dispatch: any, getState: any) => {

    dispatch({
        type: ActionDispatchTypes.TOGGLE_PROCESS_APPROVAL_POPUP,
        payload: {
            show: payload?.show || !getState().approve.internalApprovePopup?.show,
            data: payload?.data || {}
        }
    });

}