import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import FeatherIcons from 'feather-icons-react';

export default function ExportDocxElement(props: any) {

    const editor = useSlate()

    return (
        <OverlayTrigger key={'export'} placement="bottom" overlay={
            <Tooltip>
                Export to DOCX
            </Tooltip>
        }>
            <Button
                variant='link'
                onClick={props?.exportDocx}
                style={{
                    position: 'absolute',
                    right: '5px',
                }}>
                <FeatherIcons icon='download' size={18} />
            </Button>
        </OverlayTrigger>
    )
}
