import React, { useEffect, useState } from "react";
import FlatList from "@components/FlatList";
import { connect } from "react-redux";
import FeatherIcons from "feather-icons-react";
import Avatar from "react-avatar";
import { getCompanyUserList } from '@redux/companies/index.actions';
import { addAssignedUserToContract, removeAssignedUserToContract } from '@redux/contract/index.actions';
import { getContractFiles } from '@redux/contract/index.actions';
import { toggleFilesVersionModal, toggleGoogleFilePermissionModal, toggleGenericGoogleDocsIframeModal } from '@redux/components/index.actions';
import { DefaultLoader } from '@components/Loader';
import _ from "lodash";
import { Button, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./index.scss";
import Select, { components, SingleValueProps } from 'react-select';
import apiInstance from "@clients/api.client";
import { GoogleWordDocx, McWordDocx, PdfFile, JpegFile, PngFile, GoogleSheet, GoogleSlide, McSheet, McSlide } from '@pages/files/FilesManagement/fileIcons';
import { FileIcon, defaultStyles, DefaultExtensionType } from 'react-file-icon';
import path from 'path-browserify';
import cdnServicesInstance from "@clients/cdnservices.client";
import { updateFile, deleteFile, getFileVersions, setFileData, getExportLinks, setFileToGenericPermissionModal } from '@redux/files/index.actions';
import AcceptOrClosePopup from "@popups/AcceptOrClosePopup";
import image from './nodata.jpg'


const mapStateToProps = (state: any) => ({
    users: state.users,
    companies: state.companies,
    contracts: state.contracts
})

const mapActionsToProps = {
    getCompanyUsers: getCompanyUserList,
    addAssignedUserToContract,
    removeAssignedUserToContract,
    getContractFiles,
    toggleFilesVersionModal,
    toggleGoogleFilePermissionModal,
    updateFile,
    deleteFile,
    getFileVersions,
    setFileData,
    getExportLinks,
    setFileToGenericPermissionModal,
    toggleGenericGoogleDocsIframeModal
}


const FilesList: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshed, setRefreshed] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [batchSize, setBatchSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [more, setMore] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const [statuses, setStatuses] = useState<any>({
        completed: {
            title: t("POPUPS.UPLOAD_FILES.COMPLETED"),
            value: "completed",
            color: "#0144E4"
        },
        template: {
            title: t("POPUPS.UPLOAD_FILES.TEMPLATE"),
            value: "template",
            color: "#0144E4"
        },
        draft: {
            title: t("POPUPS.UPLOAD_FILES.DRAFT"),
            value: "draft",
            color: "#C1C1C1"
        },
        inReview: {
            title: t("POPUPS.UPLOAD_FILES.IN_REVIEW"),
            value: "inReview",
            color: "#F6DB48"
        },
        approved: {
            title: t("POPUPS.UPLOAD_FILES.APPROVED"),
            value: "approved",
            color: "#2EB818"
        },
        rejected: {
            title: t("POPUPS.UPLOAD_FILES.REJECTED"),
            value: "rejected",
            color: "#F0233B"
        }
    });

    const [editMode, setEditMode] = useState<boolean[]>([]);
    const [editedData, setEditedData] = useState<any[]>([]);
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState<number | null>(null);
    const [showAcceptOrCloseModal, setShowAcceptOrCloseModal] = useState<boolean>(false);

    const openDeleteModal = (index: number) => {
        if (!showAcceptOrCloseModal) {
            setCurrentDeleteIndex(index);
            setShowAcceptOrCloseModal(true);
        }
    }

    const deleteFile = async (index: number) => {
        const response: any = await props?.deleteFile({
            _id: data?.[index]?._id
        })

        const responseData: any = response;
        setData(data.filter((item: any, i: number) => {
            if (i === index) {
                return false
            }
            return true;
        }))
    }

    const loadMore = () => {
        apiInstance.get(`/contracts/creation/templates`, {
            params: {
                page,
                batchSize,
                searchText: searchText
            }
        }).then((response: any) => {
            let res = response?.data;
            ((res?.pagination?.page * batchSize) < total) ? setMore(true) : setMore(false)
            if ((res?.pagination?.page * batchSize) < total) {
                setMore(true);
                setPage(res?.pagination?.page + 1)
            } else {
                setMore(false);
            }
            setData([...data, ...res?.node?.files])

        })

    }

    const handleSearch = (event: any) => {
        setLoading(true)
        setPage(1);
        setSearchText(event?.target?.value)
    }


    const handleRemove = (data: any) => {
        props?.onUserRemove(data);
    }

    const handleAdd = (data: any) => {
        props?.onUserAdd(data);
    }
    const debounceSearch = _.debounce(handleSearch, 600);

    useEffect(() => {
        apiInstance.get(`/contracts/creation/templates`, {
            params: {
                page,
                batchSize,
                searchText: searchText
            }
        }).then((response: any) => {
            let res = response.data;
            if (res?.pagination?.totalCount) setTotal(res?.pagination?.totalCount);
            if ((res?.pagination?.page * batchSize) < res?.pagination?.totalCount) {
                setMore(true);
                setPage(page + 1);
            } else {
                setMore(false)
            }
            setData([...(res?.node?.files || [])])
            setLoading(false);
        })
    }, [searchText])

    const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
        <components.SingleValue {...props}>
            <span className='circle'

                style={{
                    backgroundColor: statuses[props.data.value]?.color || '#000000',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '5px'

                }}

            ></span>
            {children}
        </components.SingleValue>
    );

    const generateFileUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return file?.fileUrl + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return file?.fileUrl;
        } else if (file?.source?.sourceKey === "one-drive") {
            //return file?.fileUrl;
            return cdnServicesInstance.defaults.baseURL + `/onedrive/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "share-point") {
            //return file?.fileUrl;
            //return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file.key}/url` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    const generateDownloadUrl = (file: any) => {
        if (file?.source?.sourceKey === "amazon-s3") {
            return cdnServicesInstance.defaults.baseURL + "/s3/files/download/" + file?.uid + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "custom-cdn") {
            return props?.companies?.company?.customCDNEndpoint + "/local/download/" + file?.key + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "google-drive") {
            return cdnServicesInstance.defaults.baseURL + `/gdrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "one-drive") {
            return cdnServicesInstance.defaults.baseURL + `/onedrive/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else if (file?.source?.sourceKey === "share-point") {
            return cdnServicesInstance.defaults.baseURL + `/sharepoint/files/${file?.key}/download` + "?accessKey=" + props?.users?.authorization?.token;
        } else {
            return ""
        }
    }

    const [showAction, setShowAction] = useState<boolean>(false);

    const [searchClosed, setSearchClosed] = useState<boolean>(true);

    return (
        <div className="new-process-modal-files">
            <input
                className="my-2 mt-1 form-control form-control-sm"
                placeholder={t("FILES.SEARCH_INPUT_PLACEHOLDER")}
                type="text"
                name="searchText"
                defaultValue={searchText}
                onInput={debounceSearch}
            />
            {/*   {!searchClosed && (
                <input
                    className="my-2 mt-1 form-control form-control-sm"
                    placeholder={t("FILES.SEARCH_INPUT_PLACEHOLDER")}
                    type="text"
                    name="searchText"
                    defaultValue={searchText}
                    onInput={debounceSearch}
                />
            )} */}
            {/* <Button
                variant="link"

                className="btn-sm position-absolute top-0 end-0"
                style={
                    searchClosed ? { color: "gray", margin: '19px' } : { color: "black", margin: '19px' }
                }
                onClick={() => {
                    setSearchClosed(!searchClosed);
                }}
            >
                <FeatherIcons icon="search" size="16" />
            </Button> */}

            {data?.length > 0 && (
                <>
                    <div className="pos-file-list">
                        <span className="pos-list-head title">
                            {t('NEW_PROCESS_MODAL.FILES.TITLE')}
                        </span>
                        <span className="pos-list-head date">
                            {t('NEW_PROCESS_MODAL.FILES.UPLOADED_DATE')}
                        </span>
                        <span className="pos-list-head user nowrap" style={{
                            whiteSpace: 'nowrap'
                        }}>
                            {t('NEW_PROCESS_MODAL.FILES.UPLOADED_BY')}

                        </span>
                    </div>
                </>
            )}

            <div className="flat-scroll" id="contractContactsFlatList" style={{
                height: 'auto',
                maxHeight: '250px',
                overflowY: 'auto',
            }}>
                {
                    (loading) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }
                {
                    (!refreshed) &&
                    <div className="d-flex align-items-center justify-content-center" style={{
                        minHeight: '150px',
                        width: '100%'
                    }}>
                        <DefaultLoader />
                    </div>
                }

                {data?.length === 0 && !loading && refreshed && (
                    <>
                        <div>
                            <div className="d-flex align-items-center justify-content-center" style={{
                                minHeight: '140px',
                                width: '100%',
                                flexDirection: 'column'
                            }}>
                                {/* <Icon /> */}
                                <img style={{
                                    width: '120px',
                                    height: '120px',
                                }} src={image} alt="" />
                                <h5 style={{
                                    fontWeight: 400,
                                    'color': 'rgba(61, 60, 64, 0.6)',
                                    'margin': '0',
                                    'padding': '0',
                                }}>
                                    No documents right now
                                </h5>

                            </div>


                        </div>
                    </>
                )}

                {refreshed && <FlatList
                    scrollableParentTarget={"contractContactsFlatList"}
                    data={data}
                    renderItem={(item, index) => (
                        <div
                            onMouseEnter={(e: any) => setShowAction(true)}
                            onMouseLeave={(e: any) => setShowAction(false)}
                            key={item?._id} className="pos-file-list">

                            <span className="file-checkbox">
                                <input type="checkbox"
                                    checked={props?.selectedTemplate?.includes(item?._id)}
                                    onChange={(e: any) => {
                                        if (e?.target?.checked) {
                                            props?.setSelectedTemplate([...props?.selectedTemplate, item?._id])
                                        } else {
                                            props?.setSelectedTemplate(props?.selectedTemplate?.filter((id: any) => id !== item?._id))
                                        }
                                    }}
                                />
                            </span>
                            <span className="title">
                                {
                                    (() => {
                                        /* Create an array includes excel file extentions */
                                        const excelExtention = [
                                            "xls",
                                            "xlsx",
                                            "APPLICATION/VND.GOOGLE-APPS.SPREADSHEET",
                                            "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET",
                                            "APPLICATION/VND.MS-EXCEL",
                                        ];

                                        /* Create an array includes document file extentions */
                                        const documentExtention = [
                                            "doc",
                                            "docx",
                                            "APPLICATION/VND.GOOGLE-APPS.DOCUMENT",
                                            "APPLICATION/MSWORD",
                                            "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT",
                                            "APPLICATION/VND.GOOGLE-APPS.KIX",
                                        ]

                                        /* Create an array includes slayt file extentions */
                                        const presentationExtention = [
                                            "ppt",
                                            "pptx",
                                            "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.PRESENTATIONML.PRESENTATION",
                                            "APPLICATION/VND.GOOGLE-APPS.PRESENTATION",
                                            "APPLICATION/VND.MS-POWERPOINT",
                                        ];

                                        const pdfExtention = [
                                            "pdf",
                                            "PDF",
                                            "APPLICATION/PDF",
                                        ];

                                        const jpegExtention = [
                                            "jpeg",
                                            "JPEG",
                                            "IMAGE/JPEG",
                                            "image/jpeg",
                                        ];

                                        const pngExtention = [
                                            "png",
                                            "PNG",
                                            "IMAGE/PNG",
                                            "image/png",
                                        ];



                                        if (documentExtention.includes(item?.type?.title)) {
                                            if (item?.source?.sourceKey === "google-drive") {
                                                return (<i className='file-icon-filetable'><GoogleWordDocx /></i>)

                                            } else if (item?.source?.sourceKey === "share-point" || item?.source?.sourceKey === "one-drive") {
                                                return (<i className='file-icon-filetable'><McWordDocx /></i>)
                                            } else {
                                                <i style={{
                                                    width: '40px',
                                                }}>
                                                    <FileIcon extension={path.parse(item?.filename)?.ext?.split('.')?.[1]} {...defaultStyles?.[path.parse(item?.filename)?.ext?.split('.')?.[1] as DefaultExtensionType]} /></i>
                                            }
                                        }

                                        else if (presentationExtention.includes(item?.type?.title)) {
                                            if (item?.source?.sourceKey === "google-drive") {
                                                return (<i className='file-icon-filetable'><GoogleSlide /></i>)

                                            } else if (item?.source?.sourceKey === "share-point" || item?.source?.sourceKey === "one-drive") {
                                                return (<i className='file-icon-filetable'><McSlide /></i>)
                                            } else {
                                                <i style={{
                                                    width: '40px',
                                                }}>
                                                    <FileIcon extension={path.parse(item?.filename)?.ext?.split('.')?.[1]} {...defaultStyles?.[path.parse(item?.filename)?.ext?.split('.')?.[1] as DefaultExtensionType]} /></i>
                                            }
                                        }

                                        else if (excelExtention.includes(item?.type?.title)) {
                                            if (item?.source?.sourceKey === "google-drive") {
                                                return (<i className='file-icon-filetable'><GoogleSheet /></i>)

                                            } else if (item?.source?.sourceKey === "share-point" || item?.source?.sourceKey === "one-drive") {
                                                return (<i className='file-icon-filetable'><McSheet /></i>)
                                            } else {
                                                <i className='file-icon-filetable'>
                                                    <FileIcon extension={path.parse(item?.filename)?.ext?.split('.')?.[1]} {...defaultStyles?.[path.parse(item?.filename)?.ext?.split('.')?.[1] as DefaultExtensionType]} /></i>
                                            }
                                        }

                                        else if (pdfExtention.includes(item?.type?.title)) {
                                            return (<i className='file-icon-filetable'><PdfFile /></i>)
                                        }

                                        else if (jpegExtention.includes(item?.type?.title)) {
                                            return (<i className='file-icon-filetable'><JpegFile /></i>)
                                        }

                                        else if (pngExtention.includes(item?.type?.title)) {
                                            return (<i className='file-icon-filetable'><PngFile /></i>)
                                        }

                                        else return (<>
                                            <i className='file-icon-filetable'>
                                                <FileIcon extension={path.parse(item?.filename)?.ext?.split('.')?.[1]} {...defaultStyles?.[path.parse(item?.filename)?.ext?.split('.')?.[1] as DefaultExtensionType]} /></i>
                                        </>)


                                    })()
                                }
                                <>
                                    <div className="dotted-long-filetable">
                                        {item?.source?.sourceKey === "google-drive" ? ((file: any) => {

                                            /* Create an array includes excel file extentions */
                                            const excelExtention = [
                                                "xls",
                                                "xlsx",
                                                "APPLICATION/VND.GOOGLE-APPS.SPREADSHEET",
                                                "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.SPREADSHEETML.SHEET",
                                                "APPLICATION/VND.MS-EXCEL",
                                            ];

                                            /* Create an array includes document file extentions */
                                            const documentExtention = [
                                                "doc",
                                                "docx",
                                                "APPLICATION/VND.GOOGLE-APPS.DOCUMENT",
                                                "APPLICATION/MSWORD",
                                                "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.WORDPROCESSINGML.DOCUMENT",
                                                "APPLICATION/VND.GOOGLE-APPS.KIX",
                                            ]

                                            /* Create an array includes slayt file extentions */
                                            const presentationExtention = [
                                                "ppt",
                                                "pptx",
                                                "APPLICATION/VND.OPENXMLFORMATS-OFFICEDOCUMENT.PRESENTATIONML.PRESENTATION",
                                                "APPLICATION/VND.GOOGLE-APPS.PRESENTATION",
                                                "APPLICATION/VND.MS-POWERPOINT",
                                            ];



                                            const fileExtension = file?.filename?.split(".")?.[file?.filename?.split(".")?.length - 1];


                                            /*
                                                                                                if (file?.fileExtra?.integration === 'google-docs') {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <Dropdown.Item onClick={(e: any) => {
                                                                                                                props?.setFileData({ file, documentTypeForUrl: file?.fileExtra?.documentType });
                                                                                                                props?.toggleGenericGoogleDocsIframeModal();
                                                                                                            }}
                                                                                                                className="d-flex align-items-center justify-content-left gap-1">
                                                                                                                <i style={{ width: '20px' }}><FileIcon labelUppercase={true} extension={fileExtension} /></i>
                                                                                                                Edit in Google Docs
                                                                                                            </Dropdown.Item>
                                                                                                            <Dropdown.Item onClick={(e: any) => {
                                                                                                                alert('Export file key: ' + file?.key)
                                                                                                            }}
                                                                                                                className="d-flex align-items-center justify-content-left gap-1">
                                                                                                                <i style={{ width: '20px' }}><FileIcon labelUppercase={true} extension={fileExtension} /></i>
                                                                                                                Export
                                                                                                            </Dropdown.Item>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                                
                                                                                                */


                                            if (documentExtention.includes(file?.type?.title)) {
                                                return (
                                                    <div className="dotted" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        props?.setFileData({ file, documentTypeForUrl: 'document' });
                                                        props?.toggleGenericGoogleDocsIframeModal();
                                                    }}>
                                                        <a target="__blank" href={generateFileUrl(file)}>{file?.filename}</a>
                                                    </div>
                                                )
                                            }
                                            else if (presentationExtention.includes(file?.type?.title)) {
                                                return (
                                                    <div className="dotted" onClick={(e: any) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        props?.setFileData({ file, documentTypeForUrl: 'presentation' });
                                                        props?.toggleGenericGoogleDocsIframeModal();
                                                    }}>
                                                        <a target="__blank" href={generateFileUrl(file)}>{file?.filename}</a>
                                                    </div>
                                                )
                                            }

                                            else if (excelExtention.includes(file?.type?.title)) {
                                                return (
                                                    <>

                                                        <div className="dotted" onClick={(e: any) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            props?.setFileData({ file, documentTypeForUrl: 'spreadsheets' });
                                                            props?.toggleGenericGoogleDocsIframeModal();
                                                        }}>
                                                            <a target="__blank" href={generateFileUrl(file)}>{file?.filename}</a>
                                                        </div>

                                                    </>
                                                )
                                            } else {
                                                return (<div className="dotted"><a target="__blank" href={generateFileUrl(file)}>{file?.filename}</a></div>)
                                            }



                                        })(item) : (<a href={generateDownloadUrl(item)} target="_blank">{item?.filename}</a>)}
                                    </div>
                                </>
                            </span>
                            <span className="date">
                                {moment(item?.createdAt).format('D MMMM YYYY, HH:mm')}
                            </span>
                            <span className="user">
                                <Avatar name={item?.user?.name + " " + item?.user?.surname} size="30" round={true} />
                            </span>
                        </div>
                    )}
                    hasMore={more}
                    endMessage={
                        <div className="d-flex align-items-center justify-content-center no-more">

                        </div>
                    }
                    onEndReached={() => {
                        loadMore()
                    }}
                    onEndReachedThreshold={0.5}
                    keyExtractor={(item, index) => index.toString()}
                />
                }

            </div>
            <AcceptOrClosePopup
                show={showAcceptOrCloseModal}
                onAccept={() => {
                    if (currentDeleteIndex !== null) {
                        deleteFile(currentDeleteIndex)
                    }
                }}
                renderHeader={(<h4>{t("CONTRACTS.OVERVIEW.FILES.DELETE_FILE")}</h4>)}
                onClose={() => {
                    setShowAcceptOrCloseModal(false);
                    setCurrentDeleteIndex(null);
                }}>
                <div>{t("CONTRACTS.OVERVIEW.FILES.DELETE_FILE_DESCRIPTION")}</div>
            </AcceptOrClosePopup>
        </div>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(FilesList);
