import React, { useState, useEffect } from 'react';
import ScreenModal from '@components/Modals/ScreenModal';
import CDNFileUpload from '@components/CDNFileUpload';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './index.scss';
import GoogleDrive from '@assets/images/cdn/google-drive.png';
import AWSS3 from '@assets/images/cdn/amazon-s3.png';
import CustomCDN from '@assets/images/cdn/custom-cdn.png';
import OneDrive from '@assets/images/cdn/onedrive.png';
import SharePoint from '@assets/images/cdn/sharepoint.png';
import { Button } from 'react-bootstrap';
import CDNImportSourceSelect from './components/CDNImportSourceSelect';
import FilesTable from './components/FilesTable';
import { connect } from 'react-redux';
import { cdnStartUpload, closeCDNPopup, cdnSetFiles, setRemoveCDNExportSource, resetStateOnUploadCompleted } from '@redux/files/index.actions';
import { DefaultLoader } from '@components/Loader';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import apiInstance from '@clients/api.client';

type Source = {
    id: string;
    name: string;
    icon: string;
}


const mapStateToProps = (state: any) => ({
    companies: state.companies,
    cdn: state.files.cdn
});

const mapActionsTopProps = {
    cdnStartUpload: cdnStartUpload,
    cdnSetFiles: cdnSetFiles,
    setRemoveCDNExportSource: setRemoveCDNExportSource,
    resetStateOnUploadCompleted: resetStateOnUploadCompleted,
    closeCDNPopup: closeCDNPopup
}


const CDNUploadPopup: React.FunctionComponent<any> = (props) => {
    const { t } = useTranslation();
    const [disableGoogleDriveButton, setDisableGoogleDriveButton] = useState<boolean>(true);
    const [disableOneDriveButton, setDisableOneDriveButton] = useState<boolean>(true);
    const [disableSharePointButton, setDisableSharePointButton] = useState<boolean>(true);
    const Sources: Source[] = [
        {
            id: 'custom-cdn',
            name: t('POPUPS.CDN.JETLEXA_ON_PREM'),
            icon: CustomCDN
        },
        {
            id: 'aws-s3',
            name: t('POPUPS.CDN.AWS_S3'),
            icon: AWSS3
        },
        {
            id: 'google-drive',
            name: t('POPUPS.CDN.GOOGLE_DRIVE'),
            icon: GoogleDrive
        },
        {
            id: 'one-drive',
            name: t('POPUPS.CDN.ONE_DRIVE'),
            icon: OneDrive
        },
        {
            id: 'share-point',
            name: t('POPUPS.CDN.SHARE_POINT'),
            icon: SharePoint
        }
    ]

    useEffect(() => {
        /* IF COMPANY INTEGRATED TO GOOGLE DRIVE */
        apiInstance.get('/cdn/gdrive/company/integration').then((res) => {
            setDisableGoogleDriveButton(false);
        }).catch((error: any) => {
            setDisableGoogleDriveButton(true);
        })
        /* IF COMPANY INTEGRATED TO GOOGLE DRIVE */
        apiInstance.get('/cdn/onedrive/company/integration').then((res) => {
            setDisableOneDriveButton(false);
        }).catch((error: any) => {
            setDisableOneDriveButton(true);
        })

        /* IF COMPANY INTEGRATED TO GOOGLE DRIVE */
        apiInstance.get('/cdn/sharepoint/company/integration').then((res) => {
            setDisableSharePointButton(false);
        }).catch((error: any) => {
            setDisableSharePointButton(true);
        })
    }, [])


    const [show, setShow] = useState<boolean>(true);
    const [showImportPopup, setShowImportPopup] = useState<boolean>(false);
    const [selectedOutputSources, setSelectedOutputSources] = useState<string[]>([]);

    const openImportPopup = () => {
        if (props?.cdn?.exportSources?.length <= 0) {
            toast.warning('Please select at least one output source');
        } else {
            setShowImportPopup(true);
        }
    }

    return (
        <React.Fragment>
            <ScreenModal
                show={show && !showImportPopup}
                onClose={() => {
                    if (props?.cdn?.uploadStarted) {
                        props?.resetStateOnUploadCompleted();
                    }
                    props?.closeCDNPopup();
                    if (props?.onClose) {
                        props?.onClose();
                    }
                    setShow(false)
                }}
                renderHeader={(<h5>
                    {!props?.cdn?.uploadStarted && t("POPUPS.CDN.SELECT_SOURCE")}
                    {(props?.cdn?.uploadStarted) && (!props?.cdn?.uploadCompleted ? (
                        <h5>
                            {t("POPUPS.CDN.UPLOADING_TITLE")}
                        </h5>
                    ) : (
                        <h5>
                            {t("POPUPS.CDN.UPLOADED_TITLE")}
                        </h5>
                    ))}
                </h5>
                )}
                size="xl"
                renderFooter={(
                    <div>
                        {!props?.cdn?.uploadStarted && (
                            <Button variant="primary"
                                style={{
                                    minWidth: "120px"
                                }}
                                disabled={props?.cdn?.files?.length === 0}
                                onClick={() => {
                                    if (props?.cdn?.exportSources.length === 0) {
                                        toast.warning(t("POPUPS.CDN.SELECT_SOURCE_WARNING"))
                                    } else {
                                        props?.cdnStartUpload();
                                    }
                                }}>
                                {t("POPUPS.CDN.UPLOAD")}
                            </Button>
                        )}
                        {props?.cdn?.uploadStarted && (
                            <Button variant="primary"
                                style={{
                                    minWidth: "120px"
                                }}
                                disabled={!props?.cdn?.uploadCompleted}
                                onClick={() => {
                                    props?.resetStateOnUploadCompleted();
                                    setShow(false);
                                }}>
                                {t("POPUPS.CDN.FINISH")}
                            </Button>
                        )}
                    </div>
                )}>
                <div className="cdn-upload-popup">
                    {props?.cdn?.isImportedFiles && (
                        <div className="cdn-upload-popup-imported-files-downloading">
                            <DefaultLoader />
                            {t("POPUPS.CDN.IMPORTING")}
                        </div>
                    )}
                    {!props?.cdn.uploadStarted && (
                        <React.Fragment>
                            <div className='cdn-upload-source-select' style={{
                                marginBottom: "10px",
                            }}>
                                {Sources.filter((source: Source, index: number) => {
                                    if (source?.id === 'custom-cdn') {
                                        if (props?.companies?.company?.customCDNEndpoint) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    }
                                    return true;
                                }).map((source, index) => {
                                    if (source?.id === 'google-drive') {
                                        return <Button
                                            style={{
                                                paddingRight: "20px",
                                                opacity: disableGoogleDriveButton ? 0.5 : 1
                                            }} onClick={() => {
                                                if (disableGoogleDriveButton) {
                                                    toast.warning(t("POPUPS.CDN.INTEGRATE_WITH_GDRIVE"), {
                                                        hideProgressBar: true,
                                                        closeOnClick: false,
                                                        pauseOnHover: false,
                                                        draggable: true,
                                                        progress: undefined
                                                    })
                                                } else {
                                                    props?.setRemoveCDNExportSource(source?.id);
                                                }
                                            }}
                                            className={classNames("btn", "btn-primary", "cdn-upload-source-button", props?.cdn?.exportSources.includes(source?.id) && "active")}>
                                            <img className="icon" src={source.icon} alt={source.name} />
                                            {source.name}
                                        </Button>
                                    } else if (source?.id === 'one-drive') {
                                        return <Button
                                            style={{
                                                paddingRight: "20px",
                                                opacity: disableOneDriveButton ? 0.5 : 1
                                            }} onClick={() => {
                                                if (disableOneDriveButton) {
                                                    toast.warning(t("POPUPS.CDN.INTEGRATE_WITH_ONEDRIVE"), {
                                                        hideProgressBar: true,
                                                        closeOnClick: false,
                                                        pauseOnHover: false,
                                                        draggable: true,
                                                        progress: undefined
                                                    })
                                                } else {
                                                    props?.setRemoveCDNExportSource(source?.id);
                                                }
                                            }}
                                            className={classNames("btn", "btn-primary", "cdn-upload-source-button", props?.cdn?.exportSources.includes(source?.id) && "active")}>
                                            <img className="icon" src={source.icon} alt={source.name} />
                                            {source.name}
                                        </Button>
                                    } else if (source?.id === 'share-point') {
                                        return <Button
                                            style={{
                                                paddingRight: "20px",
                                                opacity: disableSharePointButton ? 0.5 : 1
                                            }} onClick={() => {
                                                if (disableSharePointButton) {
                                                    toast.warning(t("POPUPS.CDN.INTEGRATE_WITH_SHAREPOINT"), {
                                                        hideProgressBar: true,
                                                        closeOnClick: false,
                                                        pauseOnHover: false,
                                                        draggable: true,
                                                        progress: undefined
                                                    })
                                                } else {
                                                    props?.setRemoveCDNExportSource(source?.id);
                                                }
                                            }}
                                            className={classNames("btn", "btn-primary", "cdn-upload-source-button", props?.cdn?.exportSources.includes(source?.id) && "active")}>
                                            <img className="icon" src={source.icon} alt={source.name} />
                                            {source.name}
                                        </Button>
                                    }
                                    return <Button
                                        style={{
                                            paddingRight: "20px"
                                        }} onClick={() => {
                                            props?.setRemoveCDNExportSource(source?.id);
                                        }}
                                        className={classNames("btn", "btn-primary", "cdn-upload-source-button", props?.cdn?.exportSources.includes(source?.id) && "active")}>
                                        <img className="icon" src={source.icon} alt={source.name} />
                                        {source.name}
                                    </Button>
                                })}
                            </div>
                            <div className='seperator' />
                            <h5>
                                {t("POPUPS.UPLOAD_FILES.UPLOAD_FILES_MAX_20")}
                            </h5>
                            <CDNFileUpload
                                onOpenImportPopup={openImportPopup}
                                showPreview={true}
                                onFileUpload={(files: any) => {
                                    const filesMetaArr = files.map((file: any) => {
                                        return {
                                            isUploaded: false,
                                            filename: file.name,
                                            type: file.type,
                                            fileSize: file.formattedSize,
                                            file: file
                                        }
                                    })
                                    console.log("RAW FILES::", files)
                                    props?.cdnSetFiles({
                                        files: filesMetaArr
                                    });
                                    //alert(JSON.stringify(files))
                                    //setFilesMetaArr(filesMetaArr);
                                    //setFiles(files);
                                }}
                            />
                        </React.Fragment>
                    )}
                    {props?.cdn?.uploadStarted && (
                        <React.Fragment>
                            <FilesTable defaultContract={props?.defaultContract} />
                        </React.Fragment>
                    )}
                </div>
            </ScreenModal>
            {showImportPopup && <CDNImportSourceSelect
                show={showImportPopup}
                onClose={() => setShowImportPopup(false)}
                onReset={async () => {
                    setShowImportPopup(false);
                    await new Promise((resolve, reject) => {
                        setTimeout(() => {
                            resolve(true);
                        }, 1000)
                    })
                    setShowImportPopup(true);
                }}
                onImportFilesSelected={() => {
                    if (props?.cdn?.exportSources.length === 0) {
                        toast.warning(t("POPUPS.CDN.SELECT_SOURCE_WARNING"))
                    } else {
                        props?.cdnStartUpload();
                        setShowImportPopup(false);
                    }
                }}
            />}
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapActionsTopProps)(CDNUploadPopup);