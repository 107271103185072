import { Editor, Transforms, Path } from 'slate';


export const addTableRow = (editor: Editor) => {
    // Mevcut tabloyu ve yolunu bul

    const tableEntry = Editor.above(editor, {
        match: n => (n as any)?.type === 'table',
    });

    if (!tableEntry) {
        console.error('Tablo bulunamadı.');
        return;
    }

    const [tableNode, tablePath] = tableEntry as [any, Path] | [];

    if (!tableNode || !tablePath) return; // Eğer bir table bulunamazsa, işlemi sonlandır

    // Tablonun son satırını ve yolunu bul
    const lastRowIndex = tableNode?.children?.length - 1;
    const lastRowPath = [...tablePath, lastRowIndex] as Path;

    // Yeni satırda oluşturulacak hücre sayısını son satırdaki hücre sayısına eşitle
    const lastRow = tableNode?.children[lastRowIndex];
    const newCells = lastRow?.children?.map(() => ({
        type: 'table-cell',
        children: [{ text: '' }],
    }));

    // Yeni satır objesini oluştur
    const newRow: any = {
        type: 'table-row',
        children: newCells,
    };

    // Yeni satırı, tablonun sonuna ekler
    const newPath = Path?.next(lastRowPath);
    if (newPath) Transforms?.insertNodes(editor, newRow, { at: newPath });
    else console.error('Yeni satır yolu bulunamadı.');
};

export const addTableColumn = (editor: Editor) => {
    // Mevcut tabloyu ve yolunu bul
    const tableEntry = Editor.above(editor, {
        match: n => (n as any)?.type === 'table',
    });

    if (!tableEntry) {
        console.error('Tablo bulunamadı.');
        return;
    }

    const [tableNode, tablePath] = tableEntry as [any, Path] | [];

    if (!tableNode || !tablePath) return; // Eğer bir table bulunamazsa, işlemi sonlandır

    // Yeni sütunu her satıra eklemek için
    tableNode.children.forEach((row: any, rowIndex: number) => {
        // Yeni hücre objesini oluştur
        const newCell: any = {
            type: 'table-cell',
            children: [{ text: '' }],
        };

        // Yeni hücreyi satırın sonuna ekler
        // Burada her satırın yolunu hesaplamak için tablePath ve rowIndex kullanılır
        const cellPath = [...tablePath, rowIndex, row.children.length] as Path;
        Transforms.insertNodes(editor, newCell, { at: cellPath });
    });
};