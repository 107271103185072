import React, { useEffect, useState } from "react";
import ScreenModal from "@components/Modals/ScreenModal";
import { connect } from 'react-redux';
import "./ShouldCreateOrJoinACompany.scss";
import globalStyles from "@styles/globalStyles";
import { Form } from "react-bootstrap";
import ButtonLoader from "@components/Buttons/ButtonLoader";
import { GrChapterAdd, GrAdd } from 'react-icons/gr';
import { BsFillCursorFill, BsSearch } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';

import { setNewCompanyData } from "@redux/components/index.actions";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state: any) => ({
    users: state.users,
    components: state.components
});

const mapActionsTopProps = {
    setNewCompanyData
}


const RenderFooterWithRedux: React.FunctionComponent<{
    users: any,
    components: any,
    setNewCompanyData: any,
    batchOperations: {
        onClose: () => void,
        next: () => void,
        previous: () => void,
        index: number,
        length: number
    }
}> = (props) => {
    useEffect(() => {
    }, [props.components.newCompanyData])
    return (
        <React.Fragment>
            {props?.batchOperations?.index > 0 && (
                <button className="btn btn-outline-primary companyOnboard-previous" onClick={props?.batchOperations?.previous}>Previous</button>
            )}
            {(<button disabled={
                !_.isBoolean(props.components?.newCompanyData?.isNewCompany) && true
            } className="btn btn-primary companyOnboard-next" onClick={props?.batchOperations?.next}>Next</button>)}
        </React.Fragment>
    )
}


export const renderFooter = connect(mapStateToProps, mapActionsTopProps)(RenderFooterWithRedux);



const ShouldCreateOrJoinACompany: React.FunctionComponent<{
    users: any,
    components: any,
    setNewCompanyData: any,
    batchOperations: {
        onClose: () => void,
        next: (data?: number) => void,
        previous: () => void,
        index: number,
        length: number,
    }
}> = (props) => {
    const { t } = useTranslation();
    const setDataAndProceed = (data: boolean) => {
        props?.setNewCompanyData({
            key: "isNewCompany",
            value: data
        });

        props?.batchOperations?.next();
    }

    return (
        <div className="container-fluid create-or-join">
            <div className="seperator"></div>
            <div className="row create-or-joing-row">
                <div className="col-lg-6 col-sm-12 option" onClick={() => setDataAndProceed(true)}>
                    <div className={`innerContent ${(props?.components?.newCompanyData?.isNewCompany || false) && "selected"}`}>
                        <HiViewGridAdd size={100} />
                        <br />
                        <br />
                        <h1>
                            {t("POPUPS.COMPANY_ONBOARDING.CREATE_COMPANY")}
                        </h1>
                    </div>
                </div>
                <div
                    className="col-lg-6 col-sm-12 option"
                    onClick={() => {
                        props?.setNewCompanyData({
                            key: "joinCompany",
                            value: true
                        });
                        props?.batchOperations?.next(3);
                    }}>
                    <div className={`innerContent ${(!props?.components?.newCompanyData?.isNewCompany || false) && "selected"}`}>
                        <BsSearch size={100} />
                        <br />
                        <br />
                        <h1>
                            {t("POPUPS.COMPANY_ONBOARDING.JOIN_COMPANY")}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default connect(mapStateToProps, mapActionsTopProps)(ShouldCreateOrJoinACompany);