import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Form, Button, Alert, Navbar, Nav, Card, Tab, ListGroup, FloatingLabel, Offcanvas, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { toggleCreateProcessModal, toggleNewProcessModal } from "@redux/components/index.actions";
import { useTranslation } from "react-i18next";
import "./index.scss";
import FeatherIcons from "feather-icons-react";
import { FaFileUpload, FaPlus } from "react-icons/fa";
import { IoDocumentText } from "react-icons/io5";
/* import icons/verified.png */
import verified from "./icons/verified.png";
import writing from "./icons/writing.png";
import DocumentIcon from "./components/DocumentIcon";
import EditFileIcon from "./components/EditFileIcon";
import { HiMiniClipboardDocumentList, HiDocumentMagnifyingGlass } from "react-icons/hi2";
import { BsCardList, BsCloudUpload, BsFiles } from "react-icons/bs";



const mapStateToProps = (state: any) => ({
  companies: state.companies,
  contracts: state.contracts,
  components: state.components,
  customForms: state.customForms
});
const mapActionsToProps = {
  toggleCreateProcessModal: toggleCreateProcessModal,
  toggleNewProcessModal: toggleNewProcessModal
};

const CreateProcessModal = (props: any) => {
  const { t } = useTranslation();

  function Icon() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 32">
        <path d="M24.8 0H7.2A3.176 3.176 0 004 3.143v25.714A3.176 3.176 0 007.2 32h17.6a3.176 3.176 0 003.2-3.143V3.143A3.176 3.176 0 0024.8 0zM12.254 6.201A3.859 3.859 0 1117.86 10.7a1.815 1.815 0 00-.926 1.557v.1a1 1 0 01-2 0v-.1a3.82 3.82 0 011.926-3.29 1.855 1.855 0 00.88-2.06 1.834 1.834 0 00-1.355-1.355 1.857 1.857 0 00-2.056.871 1.798 1.798 0 00-.166.375 1 1 0 01-1.91-.597zM16.93 15.5a.711.711 0 01-.02.2 1.187 1.187 0 01-.05.18l-.09.18a1.768 1.768 0 01-.13.15 1.015 1.015 0 01-.71.29.837.837 0 01-.38-.08.992.992 0 01-.32-.21 1.768 1.768 0 01-.13-.15l-.09-.18-.06-.18a1.528 1.528 0 01-.02-.2 1.017 1.017 0 01.3-.71 1.034 1.034 0 011.41 0 1.006 1.006 0 01.29.71zM23 28H9a1 1 0 010-2h14a1 1 0 010 2zm0-4H9a1 1 0 010-2h14a1 1 0 010 2zm0-4H9a1 1 0 010-2h14a1 1 0 010 2z"></path>
      </svg>
    );
  }


  useEffect(() => {
    console.log('MOUNTED')

    return () => {
      console.log('UNMOUNTED')
    }
  }, [])
  /* 
  "addDocument",
              "addAssignUser",
              "addRecord",
  */
  const actions = [
    {
      text: t('CREATE_PROCESS_MODAL.FROM_SCRATCH'),
      description: t('CREATE_PROCESS_MODAL.FROM_SCRACTH_DESCRIPTION'),
      iconComponent: <FaPlus size={80} />,
      onClick: () => {
        props?.toggleCreateProcessModal({ show: false })
        props?.toggleNewProcessModal({ show: true, pages: ['addRecord'], currentPage: 'addRecord' })
      }
    },
    {
      text: t('CREATE_PROCESS_MODAL.FOR_READY_DOCUMENTS'),
      description: t('CREATE_PROCESS_MODAL.FOR_READY_DOCUMENTS_DESCRIPTION'),
      iconComponent: <BsCloudUpload color="#65c7f7" />,
      onClick: () => {
        props?.toggleCreateProcessModal({ show: false })
        props?.toggleNewProcessModal({ show: true, pages: ['addDocument', 'addRecord'], currentPage: 'addDocument' })
      }
    },
    /*     {
          text: t('CREATE_PROCESS_MODAL.FOR_CONTRACT_REQUEST'),
          description: t('CREATE_PROCESS_MODAL.FOR_CONTRACT_REQUEST_DESCRIPTION'),
          iconComponent: <BsCardList color="#65c7f7" />,
          onClick: () => {
            props?.toggleCreateProcessModal({ show: false })
            props?.toggleNewProcessModal({ show: true, pages: ['addAssignUser', 'addRecord'], currentPage: 'addAssignUser' })
          },
          pages: ['assignUserPage', 'addRecord']
        }, */
    {
      text: t('CREATE_PROCESS_MODAL.START_WITH_TEMPLATE'),
      description: t('CREATE_PROCESS_MODAL.START_WITH_TEMPLATE_DESCRIPTION'),
      iconComponent: <BsFiles color="#65c7f7" />,
      onClick: () => {
        props?.toggleCreateProcessModal({ show: false })
        props?.toggleNewProcessModal({ show: true, pages: ['selectTemplate', 'addRecord'], currentPage: 'selectTemplate' })
      },
      pages: ['selectTemplate', 'addRecord']
    }

  ];

  return (
    <>


      <Offcanvas show={props?.components?.createProcessModal?.show}
        onHide={props?.toggleCreateProcessModal}

      >
        <Offcanvas.Header>
          <div className="modal-header-wrapper">
            <div className="modal-header-left"></div>
            <div className="modal-header-center"></div>
            <div className="modal-header-right">
              <Button
                size="sm"
                className="d-flex align-items-center justify-content-center"
                onClick={e => props?.toggleCreateProcessModal({ show: false })} variant="link">
                <FeatherIcons icon="x" size={24} />
              </Button>
            </div>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="cp-body">
            <div className="content">
              <div className="header">
                <h3 className="title">{t('CREATE_PROCESS_MODAL.TITLE')}</h3>
                <span className="description">{t('CREATE_PROCESS_MODAL.DESCRIPTION')}</span>
              </div>
              <div className="buttons">


                {actions.map((action, index) => {
                  return (
                    <div className="button-wrapper" key={index}>
                      <button className="button" onClick={action.onClick}>
                        <div className="icon">{action.iconComponent}</div>
                      </button>
                      <span className="text">{action.text}</span>
                      <span className="description">{action.description}</span>
                    </div>
                  )
                })}

              </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas >


    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(CreateProcessModal);
