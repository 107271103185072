import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_SIZES,
} from '@components/SlateEditor/constants/index.constant'
import { Transforms } from 'slate';
import isUrl from 'is-url'
import FeatherIcons from 'feather-icons-react';

export default function ImageElement(props: any) {

    const editor = useSlate()

    const isImageUrl = (url: any) => {
        if (!url) return false
        if (!isUrl(url)) return false
        const ext: any = new URL(url).pathname.split('.').pop()
        return true
    }

    const insertImage = (editor: any, url: any) => {
        const text = { text: '' }
        const image: any = { type: 'image', url, children: [text] }
        Transforms.insertNodes(editor, image)
        Transforms.insertNodes(editor, {
            type: 'paragraph',
            children: [{ text: '' }],
        })
    }

    return (
        <Button
            variant='link'
            onMouseDown={(event: any) => {
                event.preventDefault()
                const url = window.prompt('Enter the URL of the image:')
                if (url && !isImageUrl(url)) {
                    alert('URL is not an image')
                    return
                }
                url && insertImage(editor, url)
            }}
        >
            <FeatherIcons icon='image' size={18} />
        </Button>
    )
}
