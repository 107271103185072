import React, { useEffect, useState } from "react";

import { Col, Modal, Row, Button } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";
import ContractsSelect from '@components/SelectsPaginated/Contracts';
import FilesSelect from '@components/SelectsPaginated/Files'

import api from '@clients/api.client';

import { toggleGenericGoogleDocsIframeModal, toggleFilesVersionModal, toggleFilesPdfViewerModal } from "@redux/components/index.actions";
import { setFileData, updateFile, getFileVersions } from "@redux/files/index.actions";
import PdfViewer from '@components/PdfViewer';

import './index.scss';
import { toast } from "react-toastify";
import FeatherIcons from "feather-icons-react";

const mapStateToProps = (state: any) => ({
  components: state?.components,
  companies: state?.companies,
  files: state?.files
});
const mapActionsToProps = {
  toggleGenericGoogleDocsIframeModal,
  setFileData,
  updateFile,
  getFileVersions,
  toggleFilesVersionModal,
  toggleFilesPdfViewerModal
};



const AddNewStatusModal = (props: any) => {
  const { t } = useTranslation();


  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (saving === true) {
      setTimeout(() => {
        setSaving(false);
      }, 1000);

    }
  }, [saving]);

  return (
    <Modal
      show={props?.components?.filesPdfViewerModal?.show}
      onHide={() => {
        props?.toggleFilesPdfViewerModal({
          show: false,
          file: null,
          url: null
        })
        window.location.reload();
      }}
      size="xl"
      centered
    >
      <Modal.Header className="bg-primary">
        <h4 className="modal-title text-white">
          PDF Viewer
        </h4>
        <span>
          <Button
            onClick={() => {
              props?.toggleFilesPdfViewerModal({
                show: false,
                file: null,
                url: null
              })
              window.location.reload();
            }}
            className="text-white"
            variant="link"
          >
            <FeatherIcons icon="x" />
          </Button>
        </span>

      </Modal.Header>
      <Modal.Body className="pdf-wrapper">

        <div className="pdf-iframe-left">
          <PdfViewer
            className="pdf-viewer"
            url={props?.components?.filesPdfViewerModal?.url}
          />
        </div>


        <div className="pdf-iframe-right">
          <label>{t("FILES.FILENAME")} </label>
          <input
            onBlur={(e: any) => {
              props?.updateFile({
                _id: props?.components?.filesPdfViewerModal?.file?._id,
                filename: e?.target.value || null
              }).then(() => {
                setSaving(true);
              })
            }} type="text" className="input-group-text w-100" defaultValue={props?.components?.filesPdfViewerModal?.file?.filename} />
          <label>{t("FILES.TAGS")} </label>
          <input onBlur={(e: any) => {
            props?.updateFile({
              _id: props?.components?.filesPdfViewerModal?.file?._id,
              tags: e?.target.value || null
            }).then(() => {
              setSaving(true);
            })
          }}
            type="text"
            className="input-group-text w-100" defaultValue={props?.components?.filesPdfViewerModal?.file?.tags.join(", ")} />
          <label>{t("FILES.CONTRACT")} </label>
          <ContractsSelect
            onValueSubmit={(value: any) => {
              api.put(`/files/${props?.components?.filesPdfViewerModal?.file?._id}`, {
                contract: value,
                company: props?.companies?.company?._id
              }).then((response: any) => {
                //toast.success(response?.data?.response?.message);
                setSaving(true);
              }).catch((err: any) => {
                toast.error(err?.response?.data?.response?.message);
              })
            }}

            defaultOption={{
              value: props?.components?.filesPdfViewerModal?.file?.contract?._id,
              label: props?.components?.filesPdfViewerModal?.file?.contract?.title
            }}
          />

          {(props?.components?.filesPdfViewerModal?.file?.versionCount === 0 || props?.components?.filesPdfViewerModal?.file?.versionCount == null) ? (
            <><label>{t("FILES.VERSION")} </label>
              <div className="d-flex align-items-center justify-content-between gap-1">
                <div className="w-100">
                  <FilesSelect
                    hiddenIds={(props?.components?.filesPdfViewerModal?.file?.uploadedFiles || []).map((item: any) => item?._id)}
                    onValueSubmit={(value: any) => {
                      api.put(`/files/version/${props?.companies?.company?._id}/${props?.components?.filesPdfViewerModal?.file?._id}`, {
                        fileId: value?.value
                      }).then((response: any) => {
                        //toast.success(response.data?.response?.message);
                        setSaving(true)
                      }).catch((error: any) => {
                        const errorMessage = error?.response?.data?.message || error?.message;
                        toast.error(errorMessage)
                      })
                    }}
                  />
                </div>

              </div></>
          ) : null}

        </div>

      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(AddNewStatusModal);
