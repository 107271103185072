import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

import ShreyuDatepicker from '@components/DatePicker';
import { connect } from "react-redux";
import {
  editReminderToContract
} from "@redux/contract/index.actions";

import FormInput from "@components/FormInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReminderAssignedUsers from "@pages/contracts/Overview/contract/ActivityField/Reminder/components/ReminderAssignedUsersFlatList"
import _ from "lodash";
import moment from "moment";
const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
});

const mapActionsToProps = {
  editReminderToContract
};

const StatusAdd = (props: any) => {
  const { t } = useTranslation();

  const [guestsUsers, setGuestsUsers]: any = useState([...props?.editData?.guests?.map((e: any) => {
    return {
      email: e?.email,
      name: e?.name,
      source: e?.source,
      surname: e?.surname,
      user_id: e?.user_id
    }
  })]);
  const [userList, setUserList]: any = useState(false);
  const [newUserForm, setNewUserForm]: any = useState(false);
  const [outsourceGuest, setOutsourceGuest]: any = useState({});
  const [form, setForm]: any = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date(props?.editData?.startDate));




  const onDateChange = (date: any) => {
    setSelectedDate(date)
  };


  const addUserToList = (data: any) => {
    console.log(guestsUsers);
    const newData = {
      source: data?.source ? data?.source : 'IN',
      user_id: data?._id ? data?._id : null,
      name: data?.name,
      surname: data?.surname,
      email: data?.email,
    };
    setGuestsUsers(_.unionWith(guestsUsers, [newData], _.isEqual))
  };

  const handleRemoveItem = (index: number) => {
    guestsUsers.splice(index, 1);
    setGuestsUsers([...guestsUsers]);
  };

  const handleOutsourceGuestInput = (event: any) => {
    setOutsourceGuest({
      ...outsourceGuest,
      [event?.target.name]: event?.target.value
    })
  }

  const handleInput = (event: any) => {
    setForm({
      ...form,
      [event?.target.name]: event?.target.value
    })
  }

  const handleAddOutsourceGuest = (event: any) => {
    event.preventDefault();

    if (!outsourceGuest?.name) {
      toast.error(t("CONTRACTS.OVERVIEW.REMINDER.PROVIDE_GUEST_NAME"))
      return;
    }

    if (!outsourceGuest?.surname) {
      toast.error(t("CONTRACTS.OVERVIEW.REMINDER.PROVIDE_GUEST_SURNAME"))
      return;
    }

    if (!outsourceGuest?.email) {
      toast.error(t("CONTRACTS.OVERVIEW.REMINDER.PROVIDE_GUEST_EMAIL"))
      return;
    }
    outsourceGuest._id = null;
    outsourceGuest.source = 'OUT';

    addUserToList(outsourceGuest);
    setOutsourceGuest({
      user_id: '',
      source: '',
      name: '',
      surname: '',
      email: ''
    });
    setNewUserForm(false);


  }

  const handleSubmit = (event: any) => {
    event.preventDefault();

    console.log(form)
    console.log(guestsUsers)
    console.log(moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'))

    const requestData: {
      title: string
      description: string
      contract: string
      startDate: string
      guests: any[]

    } = {
      title: form?.title,
      description: form?.description,
      contract: props?.contract?.contractOverview?._id,
      startDate: moment(selectedDate).format('YYYY-MM-DD HH:mm:ss'),
      guests: guestsUsers

    }

    props?.editReminderToContract({
      requestData: requestData,
      reminderId: props?.editData?._id,
    }).then((res: any) => {
      props?.handleSetCalendarData(res.reminder)
      props?.modalClose()
      props?.toggleAddToCalendarModal()
      props?.handleRefresh();
    })

  };
  return (
    <>
      <form onSubmit={event => handleSubmit(event)} className="px-2">
        <Row>
          <Col md={6}>

            <Form.Group className="mb-2">
              <Form.Label>{t("CONTRACTS.OVERVIEW.REMINDER.TITLE_LABEL")}</Form.Label>
              <FormInput
                defaultValue={props?.editData?.title}
                name="title"
                onInput={handleInput}
                placeholder={t("CONTRACTS.OVERVIEW.REMINDER.TITLE_PLACEHOLDER")}
                type="text"
                key="title"
              />
            </Form.Group>


          </Col>
          <Col md={6}>
            <Form.Group>
              <label className="form-label">{t("CONTRACTS.OVERVIEW.REMINDER.DATE_LABEL")}</label> <br />
              <ShreyuDatepicker
                hideAddon={true}
                showTimeSelect
                timeFormat="HH:mm"
                tI={15}
                value={selectedDate}
                dateFormat="yyyy-MM-dd HH:mm:ss"
                timeCaption="Time"
                onChange={(date) => {
                  onDateChange(date);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Form.Group>
              <Form.Label>{t("CONTRACTS.OVERVIEW.REMINDER.DESCRIPTION_LABEL")}</Form.Label>
              <FormInput
                defaultValue={props?.editData?.description}
                name="description"
                onInput={handleInput}
                placeholder={t("CONTRACTS.OVERVIEW.REMINDER.DESCRIPTION_PLACEHOLDER")}
                type="textarea"
                key="description"
              />
            </Form.Group>
          </Col>

        </Row>
        <Row>
          <Col md={12} className="my-2">
            <div>
              <h6>{t("CONTRACTS.OVERVIEW.REMINDER.GUESTS_LABEL")}</h6>
              <div className="reminder-guests-list">
                <ul className="list-group">

                  {guestsUsers?.map((e: any, index: number) => {
                    return (
                      <li className="list-group-item d-flex align-items-center justify-content-between">
                        <div>
                          {e?.name + ' ' + e?.surname} <span className="badge bg-info">{e?.email}</span>
                        </div>
                        <div>
                          <Button variant="link" onClick={event => handleRemoveItem(index)}>
                            <i className="uil uil-trash-alt text-secondary"></i>
                          </Button>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-end">
                <Button variant="link" onClick={event => {
                  setUserList((prevstate: any) => !prevstate)
                  setNewUserForm(false);

                }}>{
                    userList
                      ? (<i className="uil uil-angle-down text-info"></i>)
                      : (<i className="uil uil-angle-right text-info"></i>)
                  }
                  {t("CONTRACTS.OVERVIEW.REMINDER.ADD_USER")}
                </Button>
                <Button variant="link" onClick={event => {
                  setNewUserForm((prevstate: any) => !prevstate);
                  setUserList(false)
                }}>
                  {newUserForm
                    ? (<i className="uil uil-angle-down text-info"></i>)
                    : (<i className="uil uil-angle-right text-info"></i>)}
                  {t("CONTRACTS.OVERVIEW.REMINDER.ADD_OUTSOURCE_GUESTS")}
                </Button>
              </div>
              <div>
                <Button
                  variant="secondary"
                  className="me-1"
                  onClick={props?.modalClose}
                >
                  Cancel
                </Button>
                <Button variant="success" type="submit">
                  {t("CONTRACTS.OVERVIEW.REMINDER.CREATE")}
                </Button>
              </div>

            </div>
          </Col>
        </Row>
      </form>

      {userList && <ReminderAssignedUsers
        addUserToList={addUserToList}
      />}
      {newUserForm && (
        <div>
          <form id="guest" name="guest" onSubmit={event => handleAddOutsourceGuest(event)}>
            <Form.Group>
              <Form.Label>{t("CONTRACTS.OVERVIEW.REMINDER.NAME_LABEL")}</Form.Label>
              <FormInput
                name="name"
                value={outsourceGuest?.name}
                onInput={handleOutsourceGuestInput}
                placeholder={t("CONTRACTS.OVERVIEW.REMINDER.NAME_PLACEHOLDER")}
                type="text"
                key="description"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("CONTRACTS.OVERVIEW.REMINDER.SURNAME_LABEL")}</Form.Label>
              <FormInput
                name="surname"
                value={outsourceGuest?.surname}
                onInput={handleOutsourceGuestInput}
                placeholder={t("CONTRACTS.OVERVIEW.REMINDER.SURNAME_PLACEHOLDER")}
                type="text"
                key="description"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("CONTRACTS.OVERVIEW.REMINDER.EMAIL_LABEL")}</Form.Label>
              <FormInput
                name="email"
                value={outsourceGuest?.email}
                onInput={handleOutsourceGuestInput}
                placeholder={t("CONTRACTS.OVERVIEW.REMINDER.EMAIL_PLACEHOLDER")}
                type="email"
                key="description"
              />
            </Form.Group>
            <div className="my-1">
              <Button variant="success" type="submit" form="guest">
                {t("CONTRACTS.OVERVIEW.REMINDER.ADD_GUEST")}
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(StatusAdd);
