import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { redirectToMainApp } from '@utils/redirect';
import { PageLoader } from '@components/Loader/PageLoader';
const mapStateToProps = (state: any) => (
    {
        users: state.users
    }
)
const mapActionsToProps: any = {

};


const Root: React.FunctionComponent<any> = (props) => {
    const history = useHistory();
    const location = useLocation();
    return <Redirect to={`/dashboard/user${props.location.search}`} />
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Root));
