import React, { useEffect, useState } from "react";

import { Col, Modal, Row, Button, Form } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import api from '@clients/api.client';
import Avatar from "react-avatar";
import { toggleSharePointFilePermissionModal } from "@redux/components/index.actions";
import { setFileToSharePointGenericPermissionModal } from "@redux/files/index.actions";
import { } from "@redux/files/index.actions";
import Select from 'react-select';
import './index.scss';
import { toast } from "react-toastify";
import FeatherIcons from "feather-icons-react";
import { TagsInput } from "react-tag-input-component";
import { trim } from "lodash";
import { DefaultLoader } from "@components/Loader";
import _ from "lodash";
import { getCompanyUsers } from '@redux/companies/index.actions'
import apiInstance from "@clients/api.client";

const mapStateToProps = (state: any) => ({
  components: state?.components,
  companies: state?.companies,
  files: state?.files
});
const mapActionsToProps = {
  setFileToSharePointGenericPermissionModal,
  toggleSharePointFilePermissionModal,
  getCompanyUsers
};

const SharePointFilePermissionModal = (props: any) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string[]>([]);
  const [defaultFilePermission, setDefaultFilePermission] = useState<'read' | 'write'>(props?.data?.company?.defaultFilePermissions || 'read');
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);

  const categorizePermissions = (data: any) => {
    const categorized: any = { links: [], groups: [], people: [] };

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      console.log(item)

      if (item?.grantedToIdentitiesV2) {
        for (let j = 0; j < item.grantedToIdentitiesV2.length; j++) {

          const identity = item.grantedToIdentitiesV2[j];

          if (identity.user) {
            console.log('Burada 1')
            categorized.people.push({ ...identity?.user, roles: item?.roles, perm_id: item?.shareId });
          }

          if (identity.siteUser && !identity.user) {
            console.log('Burada 2')
            categorized.groups.push({ ...identity?.siteUser, roles: item?.roles, perm_id: item?.shareId });
          }
        }
      }
      if (item?.grantedToV2) {
        if ((item?.grantedToV2?.siteUser || item?.grantedToV2?.user) && !item?.grantedToV2?.group) {
          categorized.people.push({ ...item?.grantedToV2?.siteUser, roles: item?.roles, perm_id: item?.shareId });
        }
        if (item?.grantedToV2?.siteGroup) {
          categorized.groups.push({ ...item?.grantedToV2?.siteGroup, roles: item?.roles, perm_id: item?.shareId });
        }
        if (item?.grantedToV2?.group) {
          categorized.groups.push({ ...item?.grantedToV2?.group, roles: item?.roles, perm_id: item?.shareId });
        }
      }


    }

    console.log('categorized', categorized)
    return categorized;
  };

  const [file, setFile] = useState<any>(props?.data?.file);

  const defaultFilePermissions: {
    read: any
    write: any
    owner: any
  } = {
    read: {
      title: t('POPUPS.USERS_MANAGEMENT.DEFAULT_FILE_PERMISSIONS.READ'),
      value: 'read'
    },
    write: {
      title: t('POPUPS.USERS_MANAGEMENT.DEFAULT_FILE_PERMISSIONS.EDIT'),
      value: 'write'
    },
    owner: {
      title: t('POPUPS.USERS_MANAGEMENT.DEFAULT_FILE_PERMISSIONS.OWNER'),
      value: 'notAllowed'
    }
  }

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  const getPermissions = () => {
    console.log('ÇALIŞTI getPermissions')
    setLoader(true);
    apiInstance.get(`/files/list-permissions/file/${props?.companies?.company?._id}/${props?.files?.selectedSharePointPermissionModalFile?.file?._id}`)
      .then((response: any) => {
        setData(categorizePermissions(response?.data?.node?.returnData || []))
        setLoader(false);
      }).catch((error: any) => {
        setLoader(false);
        toast.error(error?.response?.data?.response?.message);
      })
  }


  useEffect(() => {
    if (!props?.files?.selectedSharePointPermissionModalFile?.file?._id) {
      return;
    }
    getPermissions();
  }, [props?.files?.selectedSharePointPermissionModalFile?.file?._id]);


  const [sendEmail, setSendEmail] = useState<boolean>(false);


  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [users, setUsers] = useState([])

  useEffect(() => {
    props?.getCompanyUsers().then((res: any) => {
      setUsers(res?.node?.users);
      console.log('res?.node?.users', res?.node?.users)
    })
  }, [])

  return (
    <Modal
      show={props?.components?.sharePointFilePermissionModal}
      size="xl"
      onHide={() => {
        props?.setFileToSharePointGenericPermissionModal({})
      }}
      centered
    >
      <Modal.Header closeButton className="bg-secondary">
        <h4 className="modal-title text-white">
          {t('SharePoint File Permission')}
        </h4>
      </Modal.Header>
      <Modal.Body className="">

        <div>
          <h5 className="fw-normal">{t('POPUPS.FILE_PERMISSON.DRIVE.ADD_USER')}:</h5>
          <div className="d-flex gap-1">

            <div
              style={{
                border: '1px solid #e0e0e0',
                borderRadius: 5,
                padding: 5,
                gap: 10,


              }}
              className="d-flex align-items-start w-100 justify-content-between">
              <div className="w-100">
                <Select
                  value={selectedUser || []}
                  isClearable={false}
                  isMulti={false}
                  options={(users.filter((user: any) => {
                    /* Filter users if exists data?.people?.[]?.email */
                    return !data?.people?.find((permission: any) => {
                      return permission?.email === user?.email
                    })
                  }) || []).map((user: any) => {
                    return {
                      label: user?.email,
                      value: user?._id,
                      email: user?.email
                    }
                  })}
                  onChange={event => setSelectedUser({
                    label: event?.label,
                    value: event?.value,
                    email: event?.email
                  })}
                  className="react-select react-select-container"
                ></Select>

              </div>
              <Form.Group
                className="w-50"
              >

                <Select
                  key={'asdxxx'}
                  isMulti={false}
                  name={'defaultFilePermissions'}
                  value={
                    {
                      label: defaultFilePermissions[defaultFilePermission]?.title,
                      value: defaultFilePermissions[defaultFilePermission]?.value,
                      name: 'defaultFilePermissions'
                    }
                  }
                  options={[
                    {
                      label: defaultFilePermissions['read'].title,
                      value: defaultFilePermissions['read'].value,
                      name: 'defaultFilePermissions'
                    },
                    {
                      label: defaultFilePermissions['write'].title,
                      value: defaultFilePermissions['write'].value,
                      name: 'defaultFilePermissions'
                    }
                  ]}
                  className="react-select react-select-container"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    setDefaultFilePermission(e?.value)
                  }}
                ></Select>
              </Form.Group>
              {/* <div

                className="w-25 h-100 d-flex align-items-center align-items-center">
                <label style={{
                  border: '1px solid #e0e0e0',
                  borderRadius: 5,
                  padding: '5px',
                  whiteSpace: 'nowrap',
                }} className="form-check-label h-100 w-100 d-flex align-items-center align-items-center gap-1">

                  <input type="checkbox" className="m-0" name="sendEmail" id="" onChange={(e: any) => {
                    console.log(e.target.checked)
                    setSendEmail(e.target.checked)
                  }} checked={sendEmail} />
                  {t('POPUPS.FILE_PERMISSON.DRIVE.NOTIFY_USER')}
                </label>
              </div> */}

            </div>
            <Button
              style={{
                width: '15%'
              }}

              disabled={!selectedUser || loader}
              onClick={() => {
                if (!validateEmail(selectedUser?.email)) {
                  toast.error(t('POPUPS.FILE_PERMISSON.DRIVE.INVALID_EMAIL'))

                  return;
                }
                if (selectedUser?.value) {
                  setLoader(true);

                  apiInstance.post(`/files/add-permissions/file/${props?.companies?.company?._id}/${props?.files?.selectedSharePointPermissionModalFile?.file?._id}`, {
                    role: defaultFilePermission,
                    sendEmail: sendEmail,
                    email: selectedUser?.email,
                    fileId: props?.files?.selectedSharePointPermissionModalFile?.file?._id
                  }).then((response: any) => {
                    getPermissions();
                    setSelectedUser(null);
                  }).catch((error: any) => {
                    setLoader(false);
                    toast.error(error?.response?.data?.response?.message);
                  })

                }
              }}
              variant="primary">
              <span className="fw-light">{t('POPUPS.FILE_PERMISSON.DRIVE.ADD')}</span>
            </Button>
          </div>



          {
            loader ? (
              <div style={{ minHeight: '300px' }} className="d-flex align-items-center justify-content-center">
                <DefaultLoader />
              </div>
            ) : (
              <>
                <div>
                  {/* <pre>
                    {JSON.stringify(data, null, 2)}
                  </pre>
 */}

                  <h5>{t('POPUPS.FILE_PERMISSON.DRIVE.PEOPLE_WITH_ACCESS')}</h5>
                  <ul className="list-group w-100 mb-3">
                    {data?.people?.map((permission: any, index: any) => (
                      <li
                        className="w-100 list-group-item d-flex justify-content-between align-items-center"
                        key={index}>


                        <div className="d-flex align-items-center justify-content-start gap-1">
                          <Avatar name={permission?.email || permission?.displayName} size="30" round={true} />
                          <div className="d-flex flex-column align-items-start">
                            <span className="ml-2">{permission?.email || permission?.displayName} </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">

                          <span className="badge bg-secondary">
                            {permission.roles.join(', ')}
                          </span>

                          <Button className="px-1"
                            onClick={(e) => {


                              apiInstance.post(`/files/delete-permissions/file/${props?.companies?.company?._id}/${props?.files?.selectedSharePointPermissionModalFile?.file?._id}`, {
                                permissionId: permission?.perm_id,
                                fileId: props?.files?.selectedSharePointPermissionModalFile?.file?._id
                              }).then((response: any) => {
                                getPermissions();
                                setSelectedUser(null);
                              }).catch((error: any) => {
                                setLoader(false);
                                toast.error(error?.response?.data?.response?.message);
                              })


                            }}
                            variant="link">
                            <FeatherIcons size={12} icon="x" />
                          </Button>

                        </div>


                      </li>
                    ))}
                  </ul>

                  <ul className="list-group w-100">
                    {data?.groups?.map((permission: any, index: any) => (
                      <li
                        className="w-100 list-group-item d-flex justify-content-between align-items-center"
                        key={index}>


                        <div className="d-flex align-items-center justify-content-start gap-1">
                          <Avatar name={permission?.email || permission?.displayName} size="30" round={true} />
                          <div className="d-flex flex-column align-items-start">
                            <span className="ml-2">{permission?.email || permission?.displayName} </span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">

                          <span className="badge bg-secondary">
                            {permission.roles.join(', ')}
                          </span>



                        </div>


                      </li>
                    ))}
                  </ul>
                </div>

              </>

            )
          }



        </div>



      </Modal.Body>
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(SharePointFilePermissionModal);
