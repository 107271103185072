import React, { useEffect, useMemo, useState } from "react";

import QuillEditor from "@components/QuillEditor";
import { Accordion, Button, Card, Col, Form, ListGroup, Modal, Row, Table } from "react-bootstrap";

import { connect } from "react-redux";

import { useTranslation } from "react-i18next";

import { setFileData, editTemplate, createDocumentFromTemplate } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";

import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import ContractOverviewRightPanel from "@popups/ContractOverview/components/ContractOverviewRightPanel";
import { BsLock, BsUnlock, BsShieldLock } from "react-icons/bs";
import { MdLock, MdLockOpen, MdLockOutline } from "react-icons/md";

import {
  getContractById, getContractTypes,
  getCompanyStatuses,
  getCompanyPipelines,
  editContractDetails,
  resetContractOverview
} from "@redux/contract/index.actions";


import './index.scss';
import { DefaultLoader } from "@components/Loader";
import ProcessOverviewScreen from "@components/ProcessOverviewScreen";

const mapStateToProps = (state: any) => ({
  components: state?.components,
  contracts: state?.contracts,
  files: state?.files,
  users: state?.users,
});
const mapActionsToProps = {
  toggleGenericContractOverviewModal,
  getContractById,
  getContractTypes,
  getCompanyStatuses,
  getCompanyPipelines,
  editContractDetails,
  resetContractOverview
};



const ContractOverviewModal = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();


  const [roles, setRoles] = useState({
    seen: false,
    edit: false,
  });

  useEffect(() => {
    // find role in user roles via operationId
    if (props?.users?.roles?.length > 0) {
      const seen = props?.users?.roles?.find((role: any) => role?.operationId === "contracts-management");
      const edit = props?.users?.roles?.find((role: any) => role?.operationId === "edit-process");
      if (seen) {
        setRoles((prev: any) => ({ ...prev, seen: false }));
      } else {
        setRoles((prev: any) => ({ ...prev, seen: true }));
      }

      if (edit) {
        setRoles((prev: any) => ({ ...prev, edit: false }));
      } else {
        setRoles((prev: any) => ({ ...prev, edit: true }));
      }


    }
  }, [props?.users?.roles]);

  /*  if (props?.contracts?.processOverview?._id) {
     let urlParams = new URLSearchParams(window.location.search);
     console.log('URL PARAMS', urlParams)
     console.log('URL PARAMS', urlParams.get("c"))
     console.log('URL PARAMS', urlParams.get('approvalId'))
     if (!urlParams.get("c") && !urlParams.get('approvalId')) {
       history.push(`?c=${props?.contracts?.processOverview?._id}&approvalId=${urlParams.get('approvalId')}`)
     }
   } else {
     history.push(`?`)
   } */

  const [allowClose, setAllowClose] = useState(false);


  const [showing, setShowing] = useState({
    page: "overview",
  });

  useEffect(() => {
    return () => {
      console.log('CLOSED')
      history.push(`?`)
    }
  }, [])



  return (
    <>

      <Modal
        show={props?.components?.contractOverviewModal}
        onHide={() => {
          allowClose !== true ?
            props?.toggleGenericContractOverviewModal({
              contractId: null,
              overview: {},
              show: false,
            }) : console.log("allowClose")

          // remove c param from url when modal closed



        }}
        size="xl"
        centered
        contentClassName="contract-overview-modal"
        dialogClassName="contract-overview-modal-dialog"
        backdropClassName="contract-overview-modal-backdrop"

      >

        <Modal.Body className="p-0">

          {
            roles?.seen ? (<>
              Görüntüleme yetkin yok
            </>) : (
              <>
                {showing?.page === "overview" && (
                  <ProcessOverviewScreen
                    roles={roles}
                    setAllowClose={setAllowClose}
                    allowClose={allowClose}
                    contractId={props?.contracts?.contractOverviewModalContractId}
                  />
                )}
                {showing?.page === "approve" && (
                  <>
                    <a href="javascript:void(0)" onClick={() => setShowing({
                      page: "overview"
                    })}>
                      <FeatherIcons icon="arrow-left" size={16} className="mb-2" />
                    </a>
                    Approve Page

                  </>
                )}



              </>
            )
          }


        </Modal.Body>
      </Modal>




    </>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(ContractOverviewModal);
