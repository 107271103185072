import React, { useCallback, useEffect, useState } from 'react';
import { SingleValueProps } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import _ from 'lodash';
import './index.scss';
/*
 * assuming the API returns something like this:
 *   const json = {
 *     results: [
 *       {
 *         value: 1,
 *         label: 'Audi',
 *       },
 *       {
 *         value: 2,
 *         label: 'Mercedes',
 *       },
 *       {
 *         value: 3,
 *         label: 'BMW',
 *       },
 *     ],
 *     has_more: true,
 *   };
 */

type ValueProps = {
    value: string,
    label: string,
}

const loadOptions = (searchText: string, loadedOptions: any, additional: any): {
    options: ValueProps[],
    hasMore: boolean,
} => {
    //const response = await fetch(`/awesome-api-url/?search=${search}&offset=${loadedOptions.length}`);
    //const responseJSON = await response.json();
    return {
        options: [
            {
                value: "1",
                label: 'Audi',
            },
            {
                value: "2",
                label: 'Mercedes',
            },
            {
                value: "3",
                label: 'BMW',
            },
        ],
        hasMore: true
    }
    /* return {
        options: responseJSON.results,
        hasMore: responseJSON.has_more,
    }; */
}

const randomIdGenerator = () => {
    return Math.random().toString(36).substr(2, 9);
}

const FlatListSelect: React.FunctionComponent<any> = (props) => {
    const [started, setStarted] = useState<boolean>(false);
    const [value, setValue] = useState<ValueProps | null>(null);
    const [page, setPage] = useState<number>(1);
    const [searchText, setSearchText] = useState<string>("");
    const [options, setOptions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const randomId = randomIdGenerator();


    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const onMenuOpen = useCallback(() => {
        setMenuIsOpen(true);
    }, []);

    const onMenuClose = useCallback(() => {
        setMenuIsOpen(false);
    }, []);




    return (
        <AsyncPaginate

            components={props?.components}
            isMulti={props?.isMulti || false}
            onInputChange={(searchText: string) => {
                setSearchText(searchText);
            }}
            menuPortalTarget={document.body}
            styles={{
                input: (provided, state) => ({
                    ...provided,
                    textAlign: "center"
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    position: "relative",
                }),
                menuPortal: provided => ({
                    ...provided,
                    zIndex: 9999
                }),
                menu: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                    scrollbarWidth: "none"
                }),
                option: (provided, state) => ({
                    ...provided,
                    width: "100%",
                    zIndex: 999,
                    textAlign: "center",
                    cursor: "pointer",
                    wordBreak: "break-all",
                    backgroundColor: state.isSelected
                        ? '#255957'
                        : state.isFocused ? '#255957' : 'white',
                    color: state.isSelected ? 'white'
                        : state.isFocused ? 'white' : '#255957'
                }),
            }}
            inputId={randomId}
            debounceTimeout={1500}
            inputValue={searchText}
            placeholder={props?.placeholder}
            value={props?.value || props?.defaultOption || null}
            defaultValue={[props?.defaultOptions] || []}
            className={props?.className}
            loadOptions={async (search, options, additional) => {
                const searchable: HTMLElement | undefined = document.getElementById(randomId) || undefined;
                if (searchable) {
                    const searchMe: string | null = searchable.getAttribute("value");
                    const opts = await props?.onEndReached(searchMe, additional)

                    /* const filteredOption = opts?.options?.filter((option: any) => {
                        return option?.value !== props?.defaultOption?.value
                    }) */

                    return {
                        ...opts,
                        /* options: filteredOption, */
                    }
                }
                /* return props?.onEndReached(searchText, additional).then((opts: any) => {
                    return opts
                }); */
                //setStarted(false)
                //return opts;
            }}
            onChange={(value: any) => {
                //setValue(value);
                props?.onChange(value);
            }}
            additional={{
                page: 1,
            }}
        />
    )
}

export default FlatListSelect;