import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Container, Card, Accordion } from "react-bootstrap";
import FormInput from "@components/FormInput";
import Select from "react-select";
import moment from "moment";
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons/dist/textMaskAddons';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Field, reduxForm } from 'redux-form';
import { divide, trimEnd } from "lodash";
import { useTranslation } from "react-i18next";
type CustomFieldAreaProps = {
    mode?: 'edit' | 'view'
    children?: React.ReactNode
}

const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    allowNegative: false,
    decimalLimit: false,
    allowLeadingZeroes: false,
    thousandsSeparatorSymbol: '.',
    decimalSymbol: ',' // default is '.'

})

export type TCustomComponent = 'Text' | 'TextArea' | 'Number' | 'Date' | 'Time' | 'Phone' | 'Currency' | 'SingleSelect' | 'Formula'

export type CSInputTextProps = {
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
    key?: string | number
    customKey?: string
    label?: string
    require?: boolean
    name: string
    value?: any
    className?: string
    onInput?: React.FormEventHandler<HTMLInputElement> | any
    onChange?: React.FormEventHandler<HTMLInputElement>
    data?: any
    mode?: 'edit' | 'view'
}



const ProcessOverviewCustomFieldAreaAccordion = ({ children }: CustomFieldAreaProps) => {


    return (
        <Accordion defaultActiveKey="0">
            {children}
        </Accordion>
    )
}



const Text = (props: CSInputTextProps) => {

    return (

        <Accordion.Item eventKey={props?.customKey + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body>
                {
                    props?.mode === 'edit' ?
                        (
                            <FormInput
                                className={props?.className}
                                name={props?.name}
                                type="text"
                                defaultValue={props?.value}
                                required={props?.require}
                                onInput={props?.onInput}
                                onChange={props?.onChange}
                            />
                        ) :
                        (
                            <div>{props?.value}</div>
                        )
                }
            </Accordion.Body>
        </Accordion.Item >

    )

}
const Formula = (props: CSInputTextProps) => {


    useEffect(() => {
        props?.onInput({ target: { name: props?.name, value: props?.value } })
    }, [props?.value])


    return (
        <Accordion.Item eventKey={props?.customKey + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body>
                {
                    props?.mode === 'edit' ?
                        (<FormInput
                            key={props?.key + 'formulaX'}
                            className={props?.className}
                            name={props?.name}
                            disabled={true}
                            type="text"
                            defaultValue={props?.value}
                            required={props?.require}
                        /* onInput={props?.onInput}
                        onChange={props?.onChange} */
                        />) :
                        (
                            <div>{props?.value}</div>
                        )}
            </Accordion.Body>
        </Accordion.Item >

    )

}
/* const Formula = (props: CSInputTextProps) => {

    return null

} */
const Currency = (props: CSInputTextProps) => {
    const { t } = useTranslation();
    console.log('props', props?.data?.field?.extraProps?.currency)
    console.log('propscurrency', props)

    return (
        <Accordion.Item eventKey={props?.customKey + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body>
                {
                    props?.mode === 'edit' ?
                        (
                            <Form.Group>
                                <MaskedInput
                                    mask={createNumberMask({
                                        prefix: '',
                                        allowDecimal: true,
                                        allowNegative: false,
                                        decimalLimit: false,
                                        allowLeadingZeroes: false,
                                        thousandsSeparatorSymbol: '.',
                                        decimalSymbol: ',' // default is '.'

                                    })}
                                    showMask={false}
                                    guide={false}
                                    className={'form-control'}
                                    name={props?.name}
                                    type="text"
                                    defaultValue={props?.value?.toString().replace('.', ',')}
                                    required={props?.require}
                                    onInput={props?.onInput}
                                />
                                <Form.Text className="text-muted">
                                    {t('CUSTOM_FIELDS.TOUSHANDS_SEPARATOR')}
                                </Form.Text>
                            </Form.Group>
                        ) :
                        (
                            <div>{props?.value?.toString().replace('.', ',')}</div>
                        )
                }
            </Accordion.Body>
        </Accordion.Item >

    )

}
const Phone = (props: CSInputTextProps) => {

    return (
        <Accordion.Item eventKey={props?.customKey + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body>
                {
                    props?.mode === 'edit' ?
                        (
                            <PhoneInput
                                country={'tr'}
                                value={props?.value}
                                onChange={phone => props?.onInput({ target: { name: props?.name, value: phone } })}
                            />
                        ) :
                        (
                            <div>{props?.value}</div>
                        )
                }
            </Accordion.Body>
        </Accordion.Item >

    )

}

const SingleSelect = (props: CSInputTextProps) => {

    return (
        <Accordion.Item eventKey={props?.customKey
            + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body
            >
                {
                    props?.mode === 'edit' ?
                        (
                            <Select
                                isClearable={true}
                                isMulti={false}
                                defaultValue={props?.value}
                                name={props?.name}
                                options={
                                    props?.data?.field?.OptionValues?.map((opt: any) => {
                                        return {
                                            value: opt?._id,
                                            label: opt?.title,
                                            opt: opt
                                        };
                                    })
                                }
                                className="react-select react-select-container"
                                classNamePrefix="react-select"
                                onChange={props?.onChange}
                            ></Select>
                        ) :
                        (
                            <div>{props?.value?.label}</div>
                        )
                }
            </Accordion.Body>
        </Accordion.Item >
    )

}

const TextArea = (props: CSInputTextProps) => {

    return (
        <Accordion.Item eventKey={props?.customKey + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body>
                {
                    props?.mode === 'edit' ?
                        (
                            <FormInput
                                className={props?.className}
                                name={props?.name}
                                type="textarea"
                                defaultValue={props?.value}
                                required={props?.require}
                                onInput={props?.onInput}

                            />
                        ) :
                        (
                            <div>{props?.value}</div>
                        )
                }
            </Accordion.Body>
        </Accordion.Item >

    )

}

const Number = (props: CSInputTextProps) => {
    const { t } = useTranslation();


    console.log('props', props?.data?.field?.extraProps)


    if (props?.data?.field?.extraProps?.numberType === 'integer') {

        return (
            <Accordion.Item eventKey={props?.customKey + ""}>
                <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
                <Accordion.Body>
                    {
                        props?.mode === 'edit' ?
                            (
                                <FormInput
                                    className={props?.className}
                                    name={props?.name}
                                    type="text"
                                    defaultValue={props?.value}
                                    required={props?.require}
                                    onInput={(e: any) => {
                                        let val = e.target.value.replace(/\D/g, '')
                                        console.log(val)
                                        // Update default value
                                        e.target.value = val
                                        props?.onInput({ target: { name: props?.name, value: val } })

                                    }}
                                />
                            ) :
                            (
                                <div>{props?.value}</div>
                            )
                    }
                </Accordion.Body>
            </Accordion.Item >

        )


    } else {
        return (
            <Accordion.Item eventKey={props?.customKey + ""}>
                <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
                <Accordion.Body>
                    {
                        props?.mode === 'edit' ?
                            (
                                <>
                                    <MaskedInput
                                        mask={currencyMask}
                                        showMask={false}
                                        guide={false}
                                        className={'form-control'}
                                        name={props?.name}
                                        type="text"
                                        defaultValue={props?.value?.toString().replace('.', ',')}
                                        required={props?.require}
                                        onInput={props?.onInput}
                                    />
                                    <Form.Text className="text-muted">
                                        {t('CUSTOM_FIELDS.TOUSHANDS_SEPARATOR')}
                                    </Form.Text>
                                </>
                            ) :
                            (
                                <div>{props?.value}</div>
                            )
                    }
                </Accordion.Body>
            </Accordion.Item >

        )
    }



}

const Date = (props: CSInputTextProps) => {
    console.log(moment(props?.value).format('DD.MM.YYYY').toString())
    return (
        <Accordion.Item eventKey={props?.customKey + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body>
                {
                    props?.mode === 'edit' ?
                        (
                            <FormInput
                                className={props?.className}
                                name={props?.name}
                                type="date"
                                defaultValue={props?.value ? moment(props?.value).format('YYYY-MM-DD').toString() : undefined}
                                required={props?.require}
                                onInput={props?.onInput}
                            />
                        ) :
                        (
                            <div>{props?.value ? moment(props?.value).format('DD.MM.YYYY').toString() : '-'}</div>
                        )
                }
            </Accordion.Body>
        </Accordion.Item >

    )

}

const Email = (props: CSInputTextProps) => {

    return (
        <Accordion.Item eventKey={props?.customKey + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body>
                {
                    props?.mode === 'edit' ?
                        (
                            <FormInput
                                className={props?.className}
                                name={props?.name}
                                type="email"
                                defaultValue={props?.value}
                                required={props?.require}
                                onInput={props?.onInput}
                            />
                        ) :
                        (
                            <div>{props?.value}</div>
                        )
                }
            </Accordion.Body>
        </Accordion.Item >

    )

}

const Time = (props: CSInputTextProps) => {

    return (
        <Accordion.Item eventKey={props?.customKey + ""}>
            <Accordion.Header><span style={{ marginRight: '15px' }}>{parseInt(props?.customKey as string) + 1}</span>{props?.label} {props?.require && (<>(*)</>)}</Accordion.Header>
            <Accordion.Body>
                {
                    props?.mode === 'edit' ?
                        (
                            <Form.Group>
                                {props?.label && (<Form.Label>{props?.label} {props?.require && (<>(*)</>)}</Form.Label>)}
                                <FormInput
                                    className={props?.className}
                                    name={props?.name}
                                    type="time"
                                    defaultValue={props?.value}
                                    required={props?.require}
                                    onInput={props?.onInput}
                                />
                            </Form.Group>
                        ) :
                        (
                            <div>{props?.value}</div>
                        )
                }
            </Accordion.Body>
        </Accordion.Item >

    )

}

ProcessOverviewCustomFieldAreaAccordion.Text = Text;
ProcessOverviewCustomFieldAreaAccordion.TextArea = TextArea;
ProcessOverviewCustomFieldAreaAccordion.Number = Number;
ProcessOverviewCustomFieldAreaAccordion.Date = Date;
ProcessOverviewCustomFieldAreaAccordion.Time = Time;
ProcessOverviewCustomFieldAreaAccordion.Email = Email;
ProcessOverviewCustomFieldAreaAccordion.SingleSelect = SingleSelect;
ProcessOverviewCustomFieldAreaAccordion.Currency = Currency;
ProcessOverviewCustomFieldAreaAccordion.Phone = Phone;
ProcessOverviewCustomFieldAreaAccordion.Formula = Formula;

export default ProcessOverviewCustomFieldAreaAccordion;