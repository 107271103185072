import { TGenericResponse } from "@redux/interfaces/index.interfaces";

const ErrorResponse = (error: {
    response: {
        data: {
            response: TGenericResponse
        }
    }
}): TGenericResponse => {
    const errorObj: TGenericResponse = error?.response?.data?.response;
    return errorObj;
}

export default ErrorResponse;