import React from "react";

function McWordDocx(props: any) {
    return (
        <svg
            width={props?.width || "35px"}
            height={props?.height || "35px"}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 1881.25 1750"
            xmlSpace="preserve"
        >
            <path
                fill="#41A5EE"
                d="M1801.056 0H517.694C473.404 0 437.5 35.904 437.5 80.194V437.5l743.75 218.75 700-218.75V80.194c0-44.29-35.904-80.194-80.194-80.194z"
            ></path>
            <path
                fill="#2B7CD3"
                d="M1881.25 437.5H437.5V875l743.75 131.25 700-131.25V437.5z"
            ></path>
            <path
                fill="#185ABD"
                d="M437.5 875v437.5l700 87.5 743.75-87.5V875H437.5z"
            ></path>
            <path
                fill="#103F91"
                d="M517.694 1750h1283.363c44.29 0 80.194-35.904 80.194-80.194V1312.5H437.5v357.306c0 44.29 35.904 80.194 80.194 80.194z"
            ></path>
            <path
                d="M969.806 350H437.5v1093.75h532.306c44.23-.144 80.05-35.964 80.194-80.194V430.194c-.144-44.23-35.964-80.05-80.194-80.194z"
                opacity="0.1"
            ></path>
            <path
                d="M926.056 393.75H437.5V1487.5h488.556c44.23-.144 80.05-35.964 80.194-80.194V473.944c-.144-44.23-35.964-80.05-80.194-80.194z"
                opacity="0.2"
            ></path>
            <path
                d="M926.056 393.75H437.5V1400h488.556c44.23-.144 80.05-35.964 80.194-80.194V473.944c-.144-44.23-35.964-80.05-80.194-80.194z"
                opacity="0.2"
            ></path>
            <path
                d="M882.306 393.75H437.5V1400h444.806c44.23-.144 80.05-35.964 80.194-80.194V473.944c-.144-44.23-35.964-80.05-80.194-80.194z"
                opacity="0.2"
            ></path>
            <linearGradient
                id="SVGID_1_"
                x1="167.206"
                x2="795.294"
                y1="1420.912"
                y2="333.088"
                gradientTransform="matrix(1 0 0 -1 0 1752)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#2368C4"></stop>
                <stop offset="0.5" stopColor="#1A5DBE"></stop>
                <stop offset="1" stopColor="#1146AC"></stop>
            </linearGradient>
            <path
                fill="url(#SVGID_1_)"
                d="M80.194 393.75h802.112c44.29 0 80.194 35.904 80.194 80.194v802.113c0 44.29-35.904 80.194-80.194 80.194H80.194c-44.29 0-80.194-35.904-80.194-80.194V473.944c0-44.29 35.904-80.194 80.194-80.194z"
            ></path>
            <path
                fill="#FFF"
                d="M329.088 1008.788c1.575 12.381 2.625 23.144 3.106 32.375h1.837c.7-8.75 2.158-19.294 4.375-31.631 2.217-12.338 4.215-22.765 5.994-31.281l84.35-363.913h109.069l87.5 358.444a530.822 530.822 0 0110.894 67.637h1.444a518.5 518.5 0 019.1-65.625l69.781-360.631h99.269l-122.588 521.5H577.238L494.113 790.3c-2.406-9.931-5.162-22.925-8.181-38.894-3.019-15.969-4.9-27.65-5.644-35h-1.444c-.962 8.487-2.844 21.088-5.644 37.8-2.8 16.713-5.046 29.079-6.738 37.1l-78.138 344.269h-117.95L147.131 614.337h101.062l75.994 364.656c1.707 7.482 3.326 17.457 4.901 29.795z"
            ></path>
        </svg>
    );
}

export default McWordDocx;
