import { BlockButton, isBlockActive, toggleBlock } from '@components/SlateEditor/utils/editor.utils';
import React from 'react'
import { Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useSlate } from 'slate-react';
import {
    FONT_COLORS,
} from '@components/SlateEditor/constants/index.constant'
import { BiFontColor } from 'react-icons/bi';

export default function FontColor(props: any) {

    const editor = useSlate()

    return (
        <Dropdown className='p-0 m-0'>
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip>
                    Color
                </Tooltip>
            }>
                <Dropdown.Toggle
                    onMouseDown={(event: any) => {
                        event.preventDefault(); // Editörün odaklanmasını önle
                        event.stopPropagation();
                    }}
                    variant='link'
                    className='toolbar-dd-button'
                >
                    <BiFontColor size={18} />
                    {/* <FaChevronDown style={{ marginLeft: '3px' }} size={10} /> */}
                </Dropdown.Toggle>
            </OverlayTrigger>
            <Dropdown.Menu style={{ width: '252px' }} className='slate-toolbar-dd-menu'
            >
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '4px'
                }}>

                    {
                        (FONT_COLORS || []).map((item: any, index: number) => {
                            return (
                                <span
                                    style={{
                                        padding: '2px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: props?.activeColor === item ? '2px solid #255957' : 'none',
                                        borderRadius: '4px',
                                    }}
                                    key={index} onMouseDown={(event: any) => {



                                        event.preventDefault(); // Editörün odaklanmasını önle
                                        props?.addColor(editor, item);
                                        props?.setActiveColor(item)
                                    }}>
                                    <span style={{
                                        backgroundColor: item,
                                        width: '20px',
                                        height: '20px',
                                        display: 'inline-block',
                                        border: '1px solid #E4E5E8'
                                    }}></span>
                                </span>
                            )
                        })
                    }

                </div>

            </Dropdown.Menu>
        </Dropdown>
    )
}
