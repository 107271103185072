import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { toggleGenericAddContractModal } from "@redux/components/index.actions";

import AddContract from "@pages/contracts/AddContract";
import { useTranslation } from "react-i18next";

import './AddContractModal.scss'

const MapStateToProps = (state: any) => ({
  components: state.components,
});

const MapActionsToProps = {
  toggleGenericAddContractModal: toggleGenericAddContractModal,
};

const AddContractModal: React.FunctionComponent<any> = (props) => {
  const { t } = useTranslation();
  const handleToggle = () => props.toggleGenericAddContractModal();

  const [modalPage, setModalPage] = useState(1)
  const [pageSettings, setPageSettings] = useState<any>({
    1: {
      modalSize: ''
    },
    2: {
      modalSize: 'xl'
    },
  })



  return (
    <>
      <Modal
        show={props?.components?.newContractModal}
        onHide={handleToggle}
        keyboard={false}
        backdrop={true}
        centered
        size={pageSettings[modalPage || 1]['modalSize']}
        contentClassName="shadow new-contract-modal">
        <Modal.Header className="bg-secondary" closeButton>
          <Modal.Title className="text-white">{t("POPUPS.ADD_CONTRACT.NEW_CONTRACT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <AddContract
            modalPage={modalPage}
            pageSettings={pageSettings}
            setModalPage={setModalPage}
            handleToggle={handleToggle} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default connect(MapStateToProps, MapActionsToProps)(AddContractModal);
