import React, { useEffect, useState } from "react";
import { Button, Col, ListGroup, Modal, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Trans, useTranslation } from 'react-i18next';

import { setFileData, editTemplate, createDocumentFromTemplate } from "@redux/files/index.actions";
import { toggleGenericContractOverviewModal } from "@redux/components/index.actions";
import Spinner from "@components/Spinner";
import reactStringReplace from 'react-string-replace';

import './index.scss';
import FormInput from "@components/FormInput";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { FiFilePlus } from "react-icons/fi";
import FeatherIcons from "feather-icons-react";
import { MentionsInput, Mention } from 'react-mentions'
import EmojiPicker from "emoji-picker-react";
import Avatar from "react-avatar";
import apiInstance from "@clients/api.client";
import moment from "moment";
import FlatList from "@components/FlatList";
import { DefaultLoader } from "@components/Loader";
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { BiLayer } from "react-icons/bi";
import { BsLayers } from "react-icons/bs";

const mapStateToProps = (state: any) => ({
    components: state?.components,
    companies: state?.companies,
    files: state?.files,
    users: state?.users,
    contracts: state?.contracts
});
const mapActionsToProps = {
    toggleGenericContractOverviewModal
};


const ProcessApprovalLabel = (props: any) => {


    return (
        <span className="fw-bold"
            style={{
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '5px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                padding: '3px 4px'

            }}
        >
            <BsLayers size="14" />
            {props?.title}
        </span>
    )
};

export default connect(mapStateToProps, mapActionsToProps)(ProcessApprovalLabel);
