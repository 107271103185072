import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Button, Row, Col, ListGroup, Badge } from "react-bootstrap";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { connect } from "react-redux";
import {
  newPipelineRemoveStatus,
  newPipelineOrderStatus,
  newPipelineAddStatus,
  setNewPipelineData,
  createNewPipeline,
  getCompanyStatuses,
} from "@redux/contract/index.actions";
import {
  toggleGenericAddPipelineModal, toggleGenericAddContractTypeModal, toggleGenericAddStatusModal
} from "@redux/components/index.actions";

// components
import Spinner from "@components/Spinner";
import FormInput from "@components/FormInput";
import { useTranslation } from "react-i18next";

import NewPipelineStatusList from "@pages/pipelines/components/NewPipelineStatusList";
import NewPipelineStatusCreationList from "@pages/pipelines/components/NewPipelineStatusCreationList";
import { toast } from "react-toastify";
import FeatherIcons from "feather-icons-react";
import './index.scss';

const mapStateToProps = (state: any) => ({
  users: state.users,
  contracts: state.contracts,
  components: state.components,
});

const mapActionsToProps = {
  newPipelineAddStatus: newPipelineAddStatus,
  newPipelineOrderStatus: newPipelineOrderStatus,
  newPipelineRemoveStatus: newPipelineRemoveStatus,
  setNewPipelineData: setNewPipelineData,
  createNewPipeline: createNewPipeline,
  getCompanyStatuses: getCompanyStatuses,
  toggleGenericAddPipelineModal,
  toggleGenericAddStatusModal
};

const AddNewPipeline = (props: any) => {
  const { t } = useTranslation();

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source?.droppableId == "0" && destination?.droppableId == "0") {
      return;
    }

    if (source?.droppableId == "1" && destination?.droppableId == "0") {
      props.newPipelineRemoveStatus({ source, destination });
    }

    if (source?.droppableId == "0" && destination?.droppableId == "1") {
      props.newPipelineAddStatus({ source, destination });
    }

    if (source?.droppableId == "1" && destination?.droppableId == "1") {
      props.newPipelineOrderStatus({ source, destination });
    }
  };

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({})
    ),
  });

  const handleNewPipeline = (values: any) => {

    if (!props?.contracts?.newPipeline?.data?.title) {
      toast.error(t("CONTRACTS_PIPELINES.NO_TITLE"),);
      return;
    }

    props.createNewPipeline().then((res: any) => {
      if (res === true) {
        props?.toggleGenericAddPipelineModal();
      }
    });
  };

  const handleTitle = (title: any) => {
    props.setNewPipelineData({ title });
  };

  const handleDescription = (description: any) => {
    props.setNewPipelineData({ description });
  };

  return (
    <Modal
      show={props?.components?.newPipelineModal}
      onHide={props?.toggleGenericAddPipelineModal}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <h4 className="modal-title">
          {t("CONTRACTS_PIPELINES.NEW_PIPELINE")}
        </h4>
      </Modal.Header>
      {props?.contracts?.cratingStatuses ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner className="p-3 m-5" color="primary">
            <span className="visually-hidden">
              {t("CONTRACTS_PIPELINES.LOADING")}
            </span>
          </Spinner>
        </div>
      ) : (
        <Modal.Body>
          <DragDropContext onDragEnd={onDragEnd}>
            <form onSubmit={handleSubmit(handleNewPipeline)} className="px-2">
              <div className="add-pipeline-2">
                <div>
                  <Droppable key={0} droppableId={"0"}>
                    {(provided, snapshot) => (
                      <div
                        className="tasks border h-100"
                        ref={provided.innerRef}
                      >
                        <h5 className="mt-0 task-header header-title">
                          {t("CONTRACTS_PIPELINES.STATUSES")}
                        </h5>
                        <NewPipelineStatusList />
                        {provided.placeholder}
                        <Button
                          onClick={props?.toggleGenericAddStatusModal}
                          className="btn btn-sm btn-link text-primary bg-light m-0 p-0 outline-0 border-0 pointer"
                        >
                          <i className="icon">
                            <span>
                              <FeatherIcons icon="plus-square"></FeatherIcons>
                            </span>

                          </i> Add New
                        </Button>
                      </div>
                    )}
                  </Droppable>
                </div>
                <div>
                  <Droppable key={1} droppableId={"1"}>
                    {(provided, snapshot) => (
                      <div
                        className="tasks border  h-100"
                        ref={provided.innerRef}
                      >
                        <h5 className="mt-0 task-header header-title">
                          {t("CONTRACTS_PIPELINES.NEW_PIPELINE")}
                        </h5>
                        <div>
                          <FormInput
                            label={t("CONTRACTS_PIPELINES.TITLE")}
                            name="title"
                            placeholder={t("CONTRACTS_PIPELINES.TITLE_PLACEHOLDER")}
                            type="text"
                            containerClass="mt-2"
                            onChange={(event) =>
                              handleTitle(event?.target?.value)
                            }
                            register={register}
                            key="title"
                            errors={errors}
                            control={control}
                          />
                          <FormInput
                            label={t("CONTRACTS_PIPELINES.DESCRIPTION")}
                            name="description"
                            placeholder={t("CONTRACTS_PIPELINES.DESCRIPTION_PLACEHOLDER")}
                            type="text"
                            containerClass="mt-2"
                            onChange={(event) =>
                              handleDescription(event?.target?.value)
                            }
                            register={register}
                            key="description"
                            errors={errors}
                            control={control}
                          />
                        </div>
                        <h5>{t("CONTRACTS_PIPELINES.STATUSES")}</h5>
                        <NewPipelineStatusCreationList />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              <Row>
                <Col md={12}>
                  <div className="text-end mt-3">
                    <Button
                      variant="light"
                      className="me-1"
                      onClick={props?.toggleGenericAddPipelineModal}>
                      {t("CONTRACTS_PIPELINES.CANCEL")}
                    </Button>
                    <Button variant="primary" type="submit">
                      {t("CONTRACTS_PIPELINES.CREATE")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </form>
          </DragDropContext>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default connect(mapStateToProps, mapActionsToProps)(AddNewPipeline);
